export const supportedLanguages = {
  en: {
    code: 'en',
    label: 'English',
    lcid: 'en_GB',
    flag: `https://flagcdn.com/w80/gb.png`,
    flagIcon: `https://flagcdn.com/w40/gb.png`,
  },
  de: {
    code: 'de',
    label: 'Deutsch',
    lcid: 'de_DE',
    flag: `https://flagcdn.com/w80/de.png`,
    flagIcon: `https://flagcdn.com/w40/de.png`,
  },
  it: {
    code: 'it',
    label: 'Italian',
    lcid: 'it_IT',
    flag: `https://flagcdn.com/w80/it.png`,
    flagIcon: `https://flagcdn.com/w40/it.png`,
  },
  cn: {
    code: 'cn',
    label: 'Chinese',
    lcid: 'zh_CN',
    flag: `https://flagcdn.com/w80/cn.png`,
    flagIcon: `https://flagcdn.com/w40/cn.png`,
  },
  dk: {
    code: 'dk',
    label: 'Danish',
    lcid: 'da_DK',
    flag: `https://flagcdn.com/w80/dk.png`,
    flagIcon: `https://flagcdn.com/w40/dk.png`,
  },
  bl: {
    code: 'bl',
    label: 'Bulgarian',
    lcid: 'bg_BG',
    flag: `https://flagcdn.com/w80/bl.png`,
    flagIcon: `https://flagcdn.com/w40/bl.png`,
  },
  cz: {
    code: 'cz',
    label: 'Czech',
    lcid: 'cs_CZ',
    flag: `https://flagcdn.com/w80/cz.png`,
    flagIcon: `https://flagcdn.com/w40/cz.png`,
  },
  es: {
    code: 'es',
    label: 'Spanish',
    lcid: 'es_ES',
    flag: `https://flagcdn.com/w80/es.png`,
    flagIcon: `https://flagcdn.com/w40/es.png`,
  },
}

export const getLocale = (default_locale) => {
  const locale = localStorage.getItem('lang') ? localStorage.getItem('lang') : default_locale;

  return supportedLanguages[String(locale)] ? locale : 'en';
}

export const setLocale = (lang) => {
  return localStorage.setItem('lang', lang)
}

export const getCurrentFlagIcon = (default_locale) => supportedLanguages[getLocale(default_locale)].flagIcon
export const getCurrentLocalName = (default_locale) => supportedLanguages[getLocale(default_locale)].label
export const getCurrentLocalLcid = (default_locale) => supportedLanguages[getLocale(default_locale)].lcid

export default {
  supportedLanguages,
  getLocale,
  setLocale,
  getCurrentFlagIcon,
}
