import React from 'react'
import { NavLink } from 'react-router-dom'
import LoginRegisterHeader from '../../components/HeaderLoginRegister'
import LoginRegisterHeaderRwd from '../../components/HeaderLoginRegisterRwd'
import LoginRegisterLogo from '../../components/LogoLoginRegister'
import PasswordRestorForm from './PasswordRestorForm/PasswordRestorForm'
import { useTranslation } from 'react-i18next'

const PasswordRestoreConfirm = () => {
  const { t } = useTranslation();

  return (
    <>
      <LoginRegisterHeader>
        <NavLink to="/">{ t('Back to login page') }</NavLink>
      </LoginRegisterHeader>

      <LoginRegisterHeaderRwd>
        <div className="actions__option actions__option_normal d__block">
          <NavLink to="/">{ t('Back to login page') }</NavLink>
        </div>
      </LoginRegisterHeaderRwd>

      <div className="entry entry_loginregister">
        <div className="entry__wrapper entry__wrapper-kyc">
          <LoginRegisterLogo />

          <div className="h2 entry__title">
            { t('Reset password') }
          </div>

          <div className="entry__top">
            <div className="entry__text">{ t('If you forgot your password, well, then we’ll email you instructions to reset your password.') }</div>
          </div>

          <PasswordRestorForm />
        </div>
      </div>
    </>
  );
};

export default PasswordRestoreConfirm;
