import React from 'react'
import cn from 'classnames'
import Icon from '../Icon'
import JoyRide from 'react-joyride'
import { useMe, usePublicSettings } from '../../myHooks'
import { useTranslation } from 'react-i18next'
import { USER_KYC_STATUS_TYPES } from '../../constants/user'
import { TOKEN_TYPES } from '../../constants/settings'
import { NavLink } from 'react-router-dom'

const AdditionalBar = ({
  // from HOCs
  location,
}) => {
  const { t } = useTranslation();
  const {
    data: { me: userData = {} } = {},
    loading,
  } = useMe()
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const currentDirectorProfile = (userData && userData.customerCompanyDirector && userData.customerCompanyDirector[0]) || null;
  const additionalBar = [
    publicSettings && publicSettings.token_type === TOKEN_TYPES.LIQUIDATION ? ({
      url: "/transfer-claim",
      name: t('Transfer claim'),
      icon: <Icon className={cn('add', 'icon-24')} name="add" size="24" />
    }) : currentDirectorProfile ? ({
      url: `/director-profile/${currentDirectorProfile.id}`,
      name: t('KYC Applications'),
      icon: <Icon className={cn('add', 'icon-24')} name="add" size="24" />
    }) : ({
      url: "/application",
      name: t('KYC Applications'),
      icon: <Icon className={cn('add', 'icon-24')} name="add" size="24" />
    })
  ];

  const tourAdAdditionalBar = [
    {
      target: '.button-additional',
      content: t('Here you can easily start your verification.'),
      disableBeacon: true,
      hideCloseButton: true,
      spotlightClicks: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
      }
    }
  ]

  return (
    <>
     {!(
      userData &&
      userData.kyc_status !== USER_KYC_STATUS_TYPES.NOT_SUBMITTED
     )}
      {
        // eslint-disable-next-line react/no-array-index-key
        additionalBar.map((item, i) => (
          <NavLink
            key={i}
            className="button header__button button-additional"
            to={item.url}
          >
            {item.icon}
            <span>
              {item.name}
            </span>
          </NavLink>
        ))
      }

      {userData.rights === 'COMMON' && (
        <>
          {userData.kyc_status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED && (
            publicSettings.enable_product_tour && (
              <JoyRide steps={tourAdAdditionalBar} />
            )
          )}
        </>
      )}
    </>
  );
}

export default AdditionalBar;
