import React from 'react'
import JoyRide from 'react-joyride'
import InputThemeField from '../../../components/InputThemeField'
import { useTranslation } from 'react-i18next'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { useMe, usePublicSettings } from '../../../myHooks'
import { toLocalYmdString } from '../../../utils'
import { USER_KYC_STATUS_TYPES } from '../../../constants/user'

const CardForm = ({
  values,
  setValues,
  goToNextStep,
  goToPrevStep,
}) => {
  const { t } = useTranslation();
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const {
    data: { me: userData } = {},
    loading,
  } = useMe()
  const tourBoardingDetailOfBirth = [
    {
      target: '.easy-onbarding__dateofbirth .easy-onboarding__individual-identificationnumber',
      content: t('To prepare your Liveness Check, we need your identification number from your passport or ID card, please do not use a driver\'s license or any other document as listed here.'),
      disableBeacon: true,
      hideCloseButton: true,
      spotlightClicks: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
    {
      target: '.easy-onbarding__dateofbirth .easy-onboarding__individual-dateofbirth',
      content: t('Your birthday should also be correct, otherwise this can lead to a longer processing time.'),
      disableBeacon: true,
      hideCloseButton: true,
      spotlightClicks: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
    {
      target: '.easy-onbarding__dateofbirth .settings__button .button',
      content: t('When you have filled in everything, please click Next'),
      disableBeacon: true,
      hideCloseButton: true,
      spotlightClicks: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
  ]

  return (
    <div className="card easy-onbarding__dateofbirth">
      <div className="settings__wrapper">
        <div className="card__head card__head-kyc-1">
          <div className="title-green card__title">
            {t('Date of birth, ID Details')}
          </div>
          <div className="refund__content card__head-content">
            <p>{ t('Due to regulation we require more information about your Source of Wealth and Source of Funds. Alongside some more personal information regarding your residential address and Nationality.') }</p>
          </div>
        </div>

        <div className="settings__fieldset">
          <div className="settings__row flex__row flex__wrap">
            <InputThemeField
              classWrapper="field easy-onboarding__individual-identificationnumber"
              classLabel="field__label"
              classInput="field__input"
              required
              label={t('Identification number (Passport, National ID card)')}
              value={values.uniqueId}
              onChange={({ target: { value } }) => setValues({...values, uniqueId: value})}
            />

            <div className="field easy-onboarding__individual-dateofbirth">
              <div className="field__label">
                {t('Date of birth')}
                <span className="text__red"> *</span>
              </div>

              <div className="field__wrap field__wrap-date ">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    views={["year", "month", "date"]}
                    openTo="year"
                    disableFuture
                    variant="outlined"
                    format="dd.MM.yyyy"
                    margin="none"
                    id="date-picker-dialog"
                    value={values.birthDate}
                    onChange={(value) => setValues({...values, birthDate: toLocalYmdString(value)})}
                    fullWidth={true}
                    InputProps={{ className: 'field__input'}}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
        </div>

        <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between easy-onboarding__dateofbirth-buttons">
          <button className="button-stroke" onClick={goToPrevStep}>{t('Previous')}</button>
          <button className="button" onClick={goToNextStep}>{t('Continue')}</button>
        </div>
      </div>

      {userData.rights === 'COMMON' && (
        <>
          {userData.kyc_status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED && (
            publicSettings.enable_product_tour && !values.skip_product_tour && (
              <JoyRide steps={tourBoardingDetailOfBirth} callback={({ status }) => {
                if (status === 'skipped') {
                  setValues({...values, skip_product_tour: true})
                }
              }} />
            )
          )}
        </>
      )}
    </div>
  )
}

export default CardForm;
