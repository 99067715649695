/* eslint-disable import/prefer-default-export */
const ONFIDO_DOCUMENT_BY_COUNTRY = {
  national_identity_card: {
    'Austria': 'AUT',
    'Belgium': 'BEL',
    'Bulgaria': 'BRG',
    'Croatia': 'HRV',
    'Cyprus': 'CYP',
    'Czech Republic': 'CZE',
    'Denmark': 'DNK',
    'Estonia': 'EST',
    'Finland': 'FIN',
    'France': 'FRA',
    'Germany': 'DEU',
    'Greece': 'GRC',
    'Hungary': 'HUN',
    'Iceland': 'ISL',
    'Ireland': 'IRE',
    'Latvia': 'LVA',
    'Liechtenstein': 'LIE',
    'Lithuania': 'LTU',
    'Luxembourg': 'LUX',
    'Malta': 'MLT',
    'Monaco': 'MCO',
    'Netherlands': 'NLD',
    'Norway': 'NOR',
    'Poland': 'POL',
    'Portugal': 'PRT',
    'Romania': 'ROU',
    'Slovakia': 'SVK',
    'Slovenia': 'SVN',
    'Spain': 'ESP',
    'Sweden': 'SWE',
    'Switzerland': 'CHE',
    'United Kingdom': 'GBR',
  },
  driving_licence: {
    'Australia': 'AUS',
    'Austria': 'AUT',
    'Belgium': 'BEL',
    'Bulgaria': 'BRG',
    'Croatia': 'HRV',
    'Cyprus': 'CYP',
    'Czech Republic': 'CZE',
    'Denmark': 'DNK',
    'El Salvador': 'SLV',
    'Estonia': 'EST',
    'Finland': 'FIN',
    'France': 'FRA',
    'Germany': 'DEU',
    'Greece': 'GRC',
    'Hungary': 'HUN',
    'Iceland': 'ISL',
    'Ireland': 'IRE',
    'Italy': 'ITA',
    'Korea (South)': 'KOR',
    'Latvia': 'LVA',
    'Liechtenstein': 'LIE',
    'Lithuania': 'LTU',
    'Luxembourg': 'LUX',
    'Netherlands': 'NLD',
    'Norway': 'NOR',
    'Poland': 'POL',
    'Portugal': 'PRT',
    'Romania': 'ROU',
    'Slovakia': 'SVK',
    'Slovenia': 'SVN',
    'Sweden': 'SWE',
    'Switzerland': 'CHE',
    'United Kingdom': 'GBR',
  },
}

export const onfidoDocumentSupport = (documentType, country) => {
  if (!ONFIDO_DOCUMENT_BY_COUNTRY[documentType] || !ONFIDO_DOCUMENT_BY_COUNTRY[documentType][country]) {
    return false;
  }

  return {
    country: ONFIDO_DOCUMENT_BY_COUNTRY[documentType][country],
  }
}
