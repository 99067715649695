import React, { useState, useEffect, createRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  cameraWindow: {
    display: 'block',
    width: '100%',
    maxWidth: '500px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '10px',
  },
  pictureFrame: {
    opacity: '0',
    position: 'absolute',
    top: '0',
    left: '0',
    pointerEvents: 'none',
  },
  photoPreview: {
    display: 'block',
    width: '100%',
    maxWidth: '500px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '10px',
  }
});

const LivenessCheckTakeDocument = ({
  documentType,
  onTakePhoto,
}) => {
  const classes = useStyles();
  const streamViewer = createRef()
  const canvas = createRef()
  const [photoUrl, setPhotoUrl] = useState('');
  const [frameSize, setFrameSize] = useState(null)
  const [mainStream, setMainStream] = useState(null)
  const [isPhotoTaken, setIsPhotoTaken] = useState(false)
  const [documentSide, setDocumentSide] = useState('front')
  const [data, setData] = useState({
    document_type: documentType,
    photos: {
      front: null,
    }
  })

  const getFrameSize = (stream) => {
    const streamSettings = stream.getVideoTracks().find((track) => track.kind === 'video').getSettings()

    return {
      width: streamSettings.width,
      height: streamSettings.height,
    }
  }

  const dataUrlToUploadFile = (dataUrl) => {
    const binaryData = atob(dataUrl.split(',')[1])
    const arrayData = [];

    for (let i = 0; i < binaryData.length; i += 1) {
      arrayData.push(binaryData.charCodeAt(i));
    }

    return new Blob([new Uint8Array(arrayData)], { type: 'image/png' });
  }

  const stopStream = () => {
    mainStream.getTracks().forEach((track) => {
      track.stop()
    })
    setMainStream(null)
    streamViewer.current.srcObject = null
  }

  const takePhoto = async () => {
    const context = canvas.current.getContext('2d');
    canvas.current.width = frameSize.width;
    canvas.current.height = frameSize.height;
    context.drawImage(streamViewer.current, 0, 0, frameSize.width, frameSize.height);

    const dataUrl = canvas.current.toDataURL('image/png');
    setIsPhotoTaken(true);

    const photoFile = dataUrlToUploadFile(dataUrl)
    const newData = {
      ...data,
      photos: {
        ...data.photos,
        [documentSide]: photoFile,
      }
    }
    stopStream()
    await streamViewer.current.pause()
    setData(newData)
    onTakePhoto(newData);

    setPhotoUrl(URL.createObjectURL(photoFile))
  }
  const retakePhoto = () => {
    const context = canvas.current.getContext('2d')
    context.fillStyle = '#AAA'
    context.fillRect(0, 0, canvas.current.width, canvas.current.height)
    setIsPhotoTaken(false)
    setPhotoUrl('')

    const newData = {
      ...data,
      photos: {
        ...data.photos,
        [documentSide]: null,
      }
    }
    setData(newData)
    onTakePhoto(newData)
  }
  const changeSide = () => {
    const context = canvas.current.getContext('2d')
    context.fillStyle = '#AAA'
    context.fillRect(0, 0, canvas.current.width, canvas.current.height)
    setIsPhotoTaken(false)
    setPhotoUrl('')
    setDocumentSide('back')
  }

  if (!mainStream && !isPhotoTaken) {
    navigator.mediaDevices.getUserMedia({
      video: { facingMode: { exact: 'environment' } },
      // video: true,
    }).then(async (stream) => {
      setMainStream(stream)
      setFrameSize(getFrameSize(stream))
    })
  }

  useEffect(() => {
    if (streamViewer.current && streamViewer.current.srcObject === null && mainStream) {
      streamViewer.current.srcObject = mainStream
      streamViewer.current.play()
    }
  }, [mainStream, streamViewer])

  return (
    <div className="component component-liveness-check-take-document" style={{overflow: 'hidden'}}>
      <canvas className={classes.pictureFrame} ref={canvas} />

      {documentType === 'NATIONAL_ID_CARD' && (
        <p className="text-center">Take a photo of the {documentSide} side of your national ID</p>
      )}

      {documentType === 'PASSPORT' && (
        <p className="text-center">Take a photo of your passport</p>
      )}
      <div className="camera" style={{display: isPhotoTaken ? 'none' : 'block'}}>
        <video playsInline className={classes.cameraWindow} ref={streamViewer}>Video stream not available.</video>
        <div className="text-center">
          <button onClick={takePhoto}>Take photo</button>
        </div>
      </div>

      <div className="output" style={{display: isPhotoTaken ? 'block' : 'none'}}>
        <img className={classes.photoPreview} alt="The screen capture will appear in this box." src={photoUrl} />
        <div className="text-center">
          <button onClick={retakePhoto}>Retake</button>
          <span>&nbsp;</span>
          {documentType === 'NATIONAL_ID_CARD' && documentSide === 'front' && (
            <button onClick={changeSide}>Continue</button>
          )}
        </div>
      </div>
    </div>
  )
}

LivenessCheckTakeDocument.propTypes = {
  documentType: PropTypes.string.isRequired,
  onTakePhoto: PropTypes.func.isRequired,
}

export default LivenessCheckTakeDocument
