import React from "react";
import AppBar from "../components/AppBar";
import Footer from "../components/Footer";
import VideoIdentForm from "../components/VideoIdentForm";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classNames from "classnames";
import { useMe } from "../myHooks";
import { useMutation } from "@apollo/react-hooks";
import { usePublicSettings } from "../myHooks/useSettings";
import {
  MAIN_INFO_SCHEMAS,
  ADDRESS_INFO_SCHEMAS,
  INDIVIDUAL_DOCUMENT_TYPE_ENUM,
  MAIN_INFO_COMPANY_SCHEMAS,
  INDIVIDUAL_FILES_SCHEMAS,
  COMPANY_DOCUMENT_TYPE_ENUM,
} from "../schemas/kycVerification";

import {
  REGISTRATION_INDIVIDUAL_CUSTOMER,
  REGISTRATION_COMPANY_CUSTOMER,
  MANUAL_UPLOAD_DOCUMENT,
} from "../queriesAndMutations";
import WorkIcon from "@material-ui/icons/Work";
import FaceIcon from "@material-ui/icons/Face";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  tabs: {
    marginBottom: "20px",
  },
  successSend: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "150px",
  },
  iconFile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      width: "70%",
      height: "70%",
      opacity: ".4",
    }
  },
  link: {
    color: "#fff",
    "&:hover": {
      color: "#fff",
    },
  },
  buttonBack: {
    marginTop: "30px",
  }
}));

const VideoIDUnAttendedPage = () => {
  const {
    data: { me: userData },
    refetch,
  } = useMe();

  const { data: { publicSettings = {} } = {} } = usePublicSettings();

  const { t } = useTranslation();

  const classes = useStyles();

  const [
    registerIndividualCustomer,
    registerIndividualCustomerData,
  ] = useMutation(REGISTRATION_INDIVIDUAL_CUSTOMER);

  const [registerCompanyCustomer, registerCompanyCustomerData] = useMutation(
    REGISTRATION_COMPANY_CUSTOMER
  );

  const [manualUploadDocument] = useMutation(MANUAL_UPLOAD_DOCUMENT);

  const refetchWhenReceivedVideoIdentId = () => {
    refetch();
  };

  const onSubmitDocumentUpload = async (fileValues, documentType) => {
    const files = Object.keys(fileValues).reduce(
      (prev, current) => ({
        ...prev,
        [current]: fileValues[current].img,
      }),
      {}
    );
    manualUploadDocument({
      variables: {
        id: userData.customer.id,
        input: { ...files, documentType: documentType, amount: "1" },
      },
    });
    refetch();
  };

  const videoIdUnAttendedType =
    userData && userData.customer !== null ? "INDIVIDUAL" : "COMPANY";

  const renderContent = () => {
    if (
      publicSettings &&
      publicSettings.kyc_video_ident_two_steps &&
      userData &&
      userData.customer &&
      !userData.video_ident_id &&
      !userData.customer.proof_of_residence_upload_id &&
      !userData.customer.main_document_upload_id &&
      !userData.customer.proof_of_residence_upload_id
    ) {
      return (
        <>
          <div className={classNames("container", classes.tabs)}>
            <Grid justify="center" alignItems="center" container spacing={0}>
              <Grid item xl={9} lg={10} xs={12}>
                <Paper elevation={0}>
                  <Tabs
                    value={videoIdUnAttendedType}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    centered
                  >
                    {videoIdUnAttendedType === "INDIVIDUAL" ? (
                      <Tab
                        value="INDIVIDUAL"
                        icon={<FaceIcon />}
                        label="Individual"
                      />
                    ) : (
                      <Tab
                        value="COMPANY"
                        icon={<WorkIcon />}
                        label="Company"
                      />
                    )}
                  </Tabs>
                </Paper>
              </Grid>
            </Grid>
          </div>

          {videoIdUnAttendedType === "INDIVIDUAL" ? (
            <VideoIdentForm
              mainInfoSchemas={MAIN_INFO_SCHEMAS}
              addressInfoSchemas={ADDRESS_INFO_SCHEMAS}
              filesSchemas={INDIVIDUAL_FILES_SCHEMAS}
              documentTypeEnum={INDIVIDUAL_DOCUMENT_TYPE_ENUM}
              kycType="INDIVIDUAL"
              registerCustomer={registerIndividualCustomer}
              registerCustomerData={registerIndividualCustomerData}
              publicSettings={publicSettings}
              refetchWhenReceivedVideoIdentId={refetchWhenReceivedVideoIdentId}
              onSubmitDocumentUpload={onSubmitDocumentUpload}
              userData={userData}
            />
          ) : (
            <VideoIdentForm
              mainInfoSchemas={MAIN_INFO_COMPANY_SCHEMAS}
              filesSchemas={MAIN_INFO_COMPANY_SCHEMAS}
              documentTypeEnum={COMPANY_DOCUMENT_TYPE_ENUM}
              kycType="COMPANY"
              registerCustomer={registerCompanyCustomer}
              registerCustomerData={registerCompanyCustomerData}
              publicSettings={publicSettings}
              refetchWhenReceivedVideoIdentId={refetchWhenReceivedVideoIdentId}
              onSubmitDocumentUpload={onSubmitDocumentUpload}
              userData={userData}
            />
          )}
        </>
      );
    } else if (
      publicSettings &&
      publicSettings.kyc_video_ident_two_steps &&
      userData &&
      userData.customer &&
      (userData.video_ident_id ||
        userData.customer.proof_of_residence_upload_id ||
        userData.customer.main_document_upload_id ||
        userData.customer.proof_of_residence_upload_id)
    ) {
      const successfullText = userData.video_ident_id
        ? "You have done the Liveness Check."
        : "Thank you for uploading your files, we will get in touch with you if we need further documentation";
      return (
        <div className="page-header">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-xl-7 text-center">
                <h2>{successfullText}</h2>
                <Button
                  className={classes.buttonBack}
                  variant="contained"
                  color="primary"
                >
                  <NavLink className={classes.link} to="/profile">
                    { t('Back to profile') }
                  </NavLink>
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className="page-user">
      <AppBar />
      {renderContent()}
      <Footer />
    </div>
  );
};

export default VideoIDUnAttendedPage;
