import gql from 'graphql-tag';

export const CASHLINK_DEPLOY_CONTRACT = gql`
  mutation cashlinkDeployContract {
    cashlinkDeployContract
  }
`;

export const CASHLINK_UPDATE_CONTRACT_STATUS = gql`
  mutation updateContractStatus {
    updateContractStatus
  }
`;