import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'

import { MenuItem } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { useTranslation, Trans } from 'react-i18next'
import { remove } from 'lodash'
import JoyRide from 'react-joyride'
import { usePublicSettings, useExchangeRates, useMe } from '../../../myHooks'
import {
  SOURCE_OF_FUNDS_STANDARD_SCHEMAS,
  SOURCE_OF_FUNDS_PUBLIC_SCHEMAS,
  SOURCE_OF_WEALTH_STANDARD_SCHEMAS,
  SOURCE_OF_WEALTH_PUBLIC_SCHEMAS,
} from '../../../schemas/kycVerification'
import { TOKEN_TYPES } from '../../../constants/settings'
import {
  ethToFiat,
  tokenToEth,
  isFiat,
  getExternalFileUrl,
  mandatoryKyc,
} from '../../../utils'
import { USER_KYC_STATUS_TYPES } from '../../../constants/user'
import InputThemeField from '../../../components/InputThemeField'
import DropdownMaterial from '../../../components/DropdownMaterial'
import ImageUpload from '../../../components/ImageUpload'
import SwitchCheckboxTheme from '../../../components/SwitchCheckbox'

import { yearlySalaryOptions } from '../../../utils/yearly_salary'
import styles from './styles.module.scss'
import classNames from 'classnames'
import {
  CUSTOMER_COMPANY_PROOF_OF_RESIDENCE,
  CUSTOMER_COMPANY_PROOF_OF_RESIDENCE_HELPER_TEXT,
} from '../../../constants/customer'
import {
  GET_MY_PERSONAL_DISCOUNT,
} from '../../../queriesAndMutations'

import ReactTooltip from 'react-tooltip'
import InfoIcon from '../../../components/Icon'

const totalAssetsOptions = [
  'Up to 5.000 CHF',
  '5001 CHF - 25.000 CHF',
  '25001 CHF - 100.000 CHF',
  '100.001 CHF - 500.000 CHF',
  '500.001 CHF - 1 Mio CHF',
  '> 1 Mio - 5 Mio',
  '> 5 Mio',
]
const useTokenForOptions = [
  'Investing',
  'Reselling-related activities (broker/dealer)',
]
const useUtilityTokenForOptions = [
  'Staking',
  'Resale',
  'Usage of the Network',
  'Other',
]
const useTokenForOptionsXDCTEQ = [
  'Investing',
  'Other',
]
const useTokenForOptionsQdev = [
  'Blockchain Validation',
]

const CardForm = ({
  values,
  setValues,
  goToNextStep,
  goToPrevStep,
  skipThisStep,
}) => {
  const currentHost = window.location.host;
  const isXDCTEQ = currentHost.includes('xdcteq');
  const isQdev = currentHost === 'kyc.qdev.li' || currentHost === 'qdevelopment-dev.investhub.io' || currentHost === 'localhost:3000';
  const { t } = useTranslation();

  const {
    data: { me: userData } = {},
    loading,
  } = useMe()
  const { data: { publicSettings = {} } = {} } = usePublicSettings()

  let whatWillYouUseOptions = publicSettings.token_type === TOKEN_TYPES.UTILITY ? useUtilityTokenForOptions : useTokenForOptions;

  if (isXDCTEQ) {
    whatWillYouUseOptions = useTokenForOptionsXDCTEQ;
  } else if (isQdev) {
    whatWillYouUseOptions = useTokenForOptionsQdev;
  }

  const { data: { exchangeRates: baseExchangeRate } = {} } = useExchangeRates()
  const { data: { getMyPersonalDiscount: myPersonalDiscount } = {} } = useQuery(GET_MY_PERSONAL_DISCOUNT)

  const exchangeRates = publicSettings && publicSettings.enable_personal_discount && myPersonalDiscount
    ? myPersonalDiscount
    : baseExchangeRate

  const maxLv = publicSettings.kyc_levels[5].enable && publicSettings.kyc_levels[5].enable.enable_lvl ? 5 :
    publicSettings.kyc_levels[4].enable && publicSettings.kyc_levels[4].enable.enable_lvl ? 4 : 3;

  const getDownloadUrl = (path) => `${getExternalFileUrl(path)}`

  const getBaseCurrency = () => {
    if (!publicSettings || !publicSettings.kyc_use_base_currency) {
      return 'chf'
    }

    return publicSettings.base_currency
  }
  const baseCurrency = getBaseCurrency()

  const getIntendedInvestmentOptions = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return []
    }

    return Object.values(publicSettings.kyc_levels).filter((level) => !level.enable || level.enable.enable_lvl).map((level) => {
      if (level.enable && level.enable.enable_open_end) {
        return `> ${level.min_invest_amount} ${baseCurrency.toUpperCase()}`
      }
      return `${level.min_invest_amount} - ${level.max_invest_amount} ${baseCurrency.toUpperCase()}`
    })
  }

  const tokenInBaseCurrency = exchangeRates && ethToFiat(
    baseCurrency,
    tokenToEth(values.amount, exchangeRates),
    exchangeRates
  )

  let currentLevel = publicSettings.kyc_levels ? Object.values(publicSettings.kyc_levels).filter((item) => {
    return !item.enable || item.enable.enable_lvl;
  }).reduce((all, item) => all + (tokenInBaseCurrency >= item.min_invest_amount ? 1 : 0), 0) : 0;

  if (publicSettings.token_type === TOKEN_TYPES.NONE) {
    currentLevel = getIntendedInvestmentOptions().indexOf(values.intended_investment) + 1
  }

  const isDocumentRequired = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return false
    }

    if (publicSettings.token_type === TOKEN_TYPES.NONE) {
      return currentLevel > 1
    }

    if ([TOKEN_TYPES.UTILITY, TOKEN_TYPES.EQUITY, TOKEN_TYPES.BOND].includes(publicSettings.token_type)) {
      return currentLevel > 2
    }

    return false
  }
  const isDescriptionOfFundsRequired = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return false
    }

    if (publicSettings.token_type === TOKEN_TYPES.NONE) {
      return currentLevel > 1
    }

    if ([TOKEN_TYPES.UTILITY, TOKEN_TYPES.EQUITY, TOKEN_TYPES.BOND].includes(publicSettings.token_type)) {
      return currentLevel > 1
    }

    return false
  }

  const isDescriptionOfWealthRequired = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return false
    }

    if (publicSettings.token_type === TOKEN_TYPES.NONE) {
      return currentLevel > 1
    }

    if ([TOKEN_TYPES.UTILITY, TOKEN_TYPES.EQUITY, TOKEN_TYPES.BOND].includes(publicSettings.token_type)) {
      return currentLevel > 1
    }

    return false
  }

  const isShowingYearlySalary =
    (publicSettings && publicSettings.kyc_yearly_salary) || false;
  const isShowingTotalWealth =
    (publicSettings && publicSettings.kyc_net_investable_assets) || false;
  const isShowingWhatWillYouUse =
    (publicSettings && publicSettings.kyc_what_will_you_use) || false;
  const isShowingPrimarySourceOfFunds =
    (publicSettings && (
      (isFiat(values.currency) && publicSettings.kyc_levels[1].acceptedFieldIndividualFiat.descriptionOfFunds) ||
      (!isFiat(values.currency) && publicSettings.kyc_levels[1].acceptedFieldIndividualCrypto.descriptionOfFunds)
    )) || false;

  const isShowingPrimarySourceOfWealth =
    (publicSettings && (
      (isFiat(values.currency) && publicSettings.kyc_levels[1].acceptedFieldIndividualFiat.descriptionOfWealth) ||
      (!isFiat(values.currency) && publicSettings.kyc_levels[1].acceptedFieldIndividualCrypto.descriptionOfWealth)
    )) || false;

  const isShowingProofOfResidenceUpload =
    (publicSettings && (
      (isFiat(values.currency) && publicSettings.kyc_levels[1].acceptedFieldIndividualFiat.proofOfResidenceUpload) ||
      (!isFiat(values.currency) && publicSettings.kyc_levels[1].acceptedFieldIndividualCrypto.proofOfResidenceUpload)
    )) || false;

    const isShowingPostFix =
      (publicSettings && publicSettings.source_of_funds_example_path && (
        <a
          href={getDownloadUrl(
            publicSettings.source_of_funds_example_path
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          {"   "}
          { t('Show Example') }
        </a>
      )) || false

  const tourBoardingDetailSourceOf = [
    {
      target: '.easy-onboarding__sources .easy-onboarding__individual-annualpersonalearnings',
      content: t('Please enter your estimated annual income, e.g. if you don\'t earn CHF, just convert your local currency into CHF.'),
      disableBeacon: true,
      spotlightClicks: true,
      hideCloseButton: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
    {
      target: '.easy-onboarding__sources .easy-onboarding__individual-totalassets',
      content: t('Here we need more information about your total assets, this helps our compliance to plausibilize your investment more easily.'),
      disableBeacon: true,
      spotlightClicks: true,
      hideCloseButton: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
    {
      target: '.easy-onboarding__sources .easy-onboarding__individual-whatwillyouusethetokenfor',
      content: t('Give us a short info here, what you will use the token for.'),
      disableBeacon: true,
      spotlightClicks: true,
      hideCloseButton: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
    {
      target: '.easy-onboarding__sources .easy-onboarding__individual-primarysourceoffunds',
      content: t('Since we have very strict rules regarding money laundering and terrorist financing, we need to know where the money comes from that is used for this investment.'),
      disableBeacon: true,
      spotlightClicks: true,
      hideCloseButton: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
    {
      target: '.easy-onboarding__sources .easy-onboarding__individual-sourceoffunds',
      content: (
        <Trans
          i18nKey={t('If you need examples for this field use this LINK here, important is please don\'t just copy a TEXT but evaluate which one applies to you and adapt it to you.')}
          t={t}
          components={{
            exampleLink: (publicSettings && publicSettings.source_of_funds_example_path && (
              //eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
              <a
                href={getDownloadUrl(
                  publicSettings.source_of_funds_example_path
                )}
                target="_blank"
                rel="noopener noreferrer"
              />
            )),
          }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      hideCloseButton: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
    {
      target: '.easy-onboarding__sources .easy-onboarding__individual-uploadsupportingdocument',
      content: t('After we have received very precise information about your source of funds and assets, this step is about proving this with a document, so that our compliance can plausibilize this. Please upload only documents that are clearly in your name, not older than 3 months and can confirm your information from above. This can be e.g. a bank confirmation, tax return, trading history or other, which proves your funds and asset origin.'),
      disableBeacon: true,
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 99,
        }
      },
      hideCloseButton: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
    {
      target: '.easy-onboarding__sources .easy-onboarding__individual-primarysourceofwealth',
      content: t('How were your assets generated? Are you an entrepreneur, employee? The more information we have, the faster we can complete your verification.'),
      disableBeacon: true,
      spotlightClicks: true,
      hideCloseButton: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
    {
      target: '.easy-onboarding__sources .easy-onboarding__individual-sourceofwealth',
      content: t('We need a detailed description of how you have generated your assets. The more detailed and longer, the faster the verification can be plausibilized and you can start with your investment.'),
      disableBeacon: true,
      spotlightClicks: true,
      hideCloseButton: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
    {
      target: '.easy-onboarding__sources .easy-onboarding__individual-proofofresidence',
      content: t('Please make sure that the documents are not older than 3 months. Please upload only legible documents that are valid and in your name. If you do not do this, your verification will take longer.'),
      disableBeacon: true,
      spotlightClicks: true,
      hideCloseButton: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
  ];

  const sourceOfFundsSchema = publicSettings.public_sale_sof_sow ? SOURCE_OF_FUNDS_PUBLIC_SCHEMAS : SOURCE_OF_FUNDS_STANDARD_SCHEMAS
  const sourceOfWealthSchema = publicSettings.public_sale_sof_sow ? SOURCE_OF_WEALTH_PUBLIC_SCHEMAS : SOURCE_OF_WEALTH_STANDARD_SCHEMAS

  const removeDropSupportedSourceOfFundUploadFiles = (index) => {
    const newSupportedSourceOfFundUploadFiles = [...values.supportedSourceOfFundUploadFiles];
    const removedEle = remove(newSupportedSourceOfFundUploadFiles, (file, indx) => {
      return indx === index;
    });

    if (removedEle) {
      setValues({
        ...values,
        supportedSourceOfFundUploadFiles: newSupportedSourceOfFundUploadFiles
      })
    }
  };

  if (publicSettings) {
    if (
      !isDocumentRequired() &&
      !isDescriptionOfFundsRequired() &&
      !isDescriptionOfWealthRequired() &&
      !isShowingYearlySalary &&
      !isShowingTotalWealth &&
      !isShowingWhatWillYouUse &&
      !isShowingPrimarySourceOfFunds
    ) {
      skipThisStep();

      return null;
    }
  }

  return (
    <div className="card easy-onboarding__sources">
      <div className="settings__wrapper">
        <div className="card__head card__head-kyc-1">
          <div className="title-green card__title">
            {t('Source of Funds, Source of Wealth')}
          </div>
          <div className="refund__content card__head-content">
            <p>{ t('Due to regulation we require more information about your Source of Wealth and Source of Funds. Alongside some more personal information regarding your residential address and Nationality.') }</p>
          </div>
        </div>

        <div className="settings__fieldset">
          <div className="settings__row flex__row flex__wrap have__fields">
            {isShowingYearlySalary && (
              <DropdownMaterial
                classWrapper="easy-onboarding__individual-annualpersonalearnings"
                label={ t('Annual personal earnings') }
                state={values}
                setState={setValues}
                propertyName="yearly_salary"
                isBackgroundWhite
                isRequired
              >
                {yearlySalaryOptions.map((option) => (
                  <MenuItem key={option} value={(option.replaceAll('CHF', baseCurrency.toUpperCase()))}>
                    {t(option.replaceAll('CHF', baseCurrency.toUpperCase()))}
                  </MenuItem>
                ))}
              </DropdownMaterial>
            )}

            {isShowingTotalWealth && (
              <DropdownMaterial
                classWrapper="easy-onboarding__individual-totalassets"
                label={ t('Total Wealth/Assets') }
                state={values}
                setState={setValues}
                propertyName="net_investable_assets"
                isBackgroundWhite
                isRequired
              >
                {totalAssetsOptions.map((option) => (
                  <MenuItem key={option} value={(option.replaceAll('CHF', baseCurrency.toUpperCase()))}>
                    {t(option.replaceAll('CHF', baseCurrency.toUpperCase()))}
                  </MenuItem>
                ))}
              </DropdownMaterial>
            )}
          </div>

          <div className="field">
          {' '}
          </div>

          {isShowingWhatWillYouUse && (
            <>
              <DropdownMaterial
                classWrapper="easy-onboarding__individual-whatwillyouusethetokenfor"
                label={ t('What is the purpose of your token purchase?') }
                state={values}
                setState={setValues}
                propertyName="what_will_you_use"
                isBackgroundWhite
                isRequired
              >
                {whatWillYouUseOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t(option)}
                  </MenuItem>
                ))}
              </DropdownMaterial>

              {values.what_will_you_use === 'Other' && (
                <InputThemeField
                  id="outlined-name"
                  classWrapper="field easy-onboarding__individual-otherreason"
                  classLabel="field__label"
                  classInput="field__input"
                  label={ t('Other reason') }
                  value={values.what_will_you_use_other}
                  onChange={({ target: { value } }) => setValues({ ...values, what_will_you_use_other: value })}
                  type="text"
                  required
                />
              )}
            </>
          )}

          {isShowingPrimarySourceOfFunds && (
            <>
              <DropdownMaterial
                classWrapper="easy-onboarding__individual-primarysourceoffunds"
                label={
                  <>
                    { t('Primary source of funds for this purchase') }
                    <span className="text__red"> *</span>
                    {" "}
                    <span
                      data-tip={t('Where does the funds used for this transaction come from?')}
                      data-for="primary_source_of_funds_for_this_purchase"
                      className="tooltip"
                    >
                      <InfoIcon
                        className={classNames('info')}
                        name='info'
                        size="16"
                      />
                    </span>
                  </>
                }
                isBackgroundWhite
                state={values}
                setState={setValues}
                propertyName="source_of_funds"
              >
                {Object.keys(sourceOfFundsSchema).map((option) => (
                  <MenuItem key={option} value={option.toUpperCase()}>
                    {t(sourceOfFundsSchema[option].label)}
                  </MenuItem>
                ))}
              </DropdownMaterial>
              <ReactTooltip id="primary_source_of_funds_for_this_purchase" place="bottom" padding="24px" className="popover-tooltip"/>

              {values.source_of_funds === 'OTHER' && (
                <InputThemeField
                  id="outlined-name"
                  classWrapper="field easy-onboarding__individual-primarysourceoffundsother"
                  classLabel="field__label"
                  classInput="field__input"
                  label={t('Describe details of Source of Funds')}
                  required
                  value={values.source_of_funds_other}
                  onChange={({ target: { value } }) => setValues({ ...values, source_of_funds_other: value })}
                  type="text"
                />
              )}
            </>
          )}

          {isDescriptionOfFundsRequired() && (
            <>
              <InputThemeField
                classWrapper="field easy-onboarding__individual-sourceoffunds"
                classLabel="field__label"
                classInput="field__input"
                label={
                  <>
                    { t('Source Of Funds') }
                    <span className="text__red"> *</span>
                    {" "}
                    <span
                      className="tooltip"
                      data-tip={t('Where does the funds used for this transaction come from?')}
                      data-for="source_of_funds"
                    >
                      <InfoIcon
                        className={classNames('info')}
                        name='info'
                        size="16"
                      />
                    </span>
                    { isShowingPostFix }
                  </>
                }
                value={values.descriptionOfFunds}
                onChange={({ target: { value } }) => setValues({ ...values, descriptionOfFunds: value })}
              />
              <ReactTooltip id="source_of_funds" place="bottom" padding="24px" className="popover-tooltip"/>
            </>
          )}

          {isDocumentRequired() && (
            <>
              <div className="field easy-onboarding__individual-uploadsupportingdocument">
                <ImageUpload
                  labelText={ t('Upload supporting documents to demonstrate the Source of Funds for this Purchase') }
                  isRequired
                  buttonText={t("Click or drop images")}
                  maxFileSize={10000000}
                  defaultImages={[]}
                  onChange={(files) => {
                    setValues({
                      ...values,
                      supportedSourceOfFundUploadFiles: [
                        ...values.supportedSourceOfFundUploadFiles,
                        ...files,
                      ]
                    })
                  }}
                  isBackgroundWhite
                >
                  <div className="file__wrap_text text__center">
                    { t('Drag and drop the document') }
                    <br />
                    { t('Suport: JPG, JPEG, GIF, PNG, PDF.') }
                    <br />
                    { t('Max: 10MB') }
                  </div>
                </ImageUpload>
                {values.supportedSourceOfFundUploadFiles.map((file, index) => (
                  <React.Fragment key={index}>
                    <div className="space space__32">{' '}</div>
                    <div className="settings__top">
                      <div className="settings__label">
                        {(file && file.name) || 'Cannot load file'}
                      </div>

                      <button
                        className="button-stroke button-small settings__button"
                        onClick={(e) => {
                          removeDropSupportedSourceOfFundUploadFiles(index);
                        }}
                      >
                        { t('Remove') }
                      </button>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </>
          )}

          {isShowingPrimarySourceOfWealth && (
            <>
              <DropdownMaterial
                label={
                  <>
                    { t('Primary source of wealth for this purchase') }
                    <span className="text__red"> *</span>
                    {" "}
                    <span
                      data-tip={t('How did you make your wealth?')}
                      data-for="primary_source_of_wealth_for_this_purchase"
                      className="tooltip"
                    >
                      <InfoIcon
                        className={classNames('info')}
                        name='info'
                        size="16"
                      />
                    </span>
                  </>
                }
                classWrapper="easy-onboarding__individual-primarysourceofwealth"
                isBackgroundWhite
                state={values}
                setState={setValues}
                propertyName="source_of_wealth"
              >
                {Object.keys(sourceOfWealthSchema).map((option) => (
                  <MenuItem key={option} value={option.toUpperCase()}>
                    {t(sourceOfWealthSchema[option].label)}
                  </MenuItem>
                ))}
              </DropdownMaterial>
              <ReactTooltip id="primary_source_of_wealth_for_this_purchase" place="bottom" padding="24px" className="popover-tooltip"/>

              {values.source_of_wealth === 'OTHER' && (
                <InputThemeField
                  id="outlined-name"
                  classWrapper="field easy-onboarding__individual-primarysourceofwealthother"
                  classLabel="field__label"
                  classInput="field__input"
                  label={t('Describe details of Source of Wealth')}
                  required
                  value={values.source_of_wealth_other}
                  onChange={({ target: { value } }) => setValues({ ...values, source_of_wealth_other: value })}
                  type="text"
                />
              )}
            </>
          )}

          {isDescriptionOfWealthRequired() && (
            <>
              <InputThemeField
                classWrapper="field easy-onboarding__individual-sourceofwealth"
                classLabel="field__label"
                classInput="field__input"
                label={
                  <>
                    { t('Source Of Wealth') }
                    <span className="text__red"> *</span>
                    {" "}
                    <span
                      className="tooltip"
                      data-for="source_of_wealth"
                      data-tip={t('How did you make your wealth?')}
                    >
                      <InfoIcon
                        className={classNames('info')}
                        name='info'
                        size="16"
                      />
                    </span>
                  </>
                }
                value={values.descriptionOfWealth}
                onChange={({ target: { value } }) => setValues({ ...values, descriptionOfWealth: value })}
              />
              <ReactTooltip id="source_of_wealth" place="bottom" padding="24px" className="popover-tooltip"/>
            </>
          )}

          {isShowingProofOfResidenceUpload && (
            <>
              <div className="card__head card__head-kyc-1">
                <div className="title-green card__title">
                  {t('Proof of residence')}
                </div>
              </div>

              <div className="field easy-onboarding__individual-proofofresidence">
                <DropdownMaterial
                  classWrapper="easy-onboarding__individual-primarysourceoffunds"
                  label={t('Proof of residence')}
                  isBackgroundWhite
                  state={values}
                  setState={setValues}
                  isRequired={true}
                  propertyName="proof_of_residence_type"
                  helperText={t(CUSTOMER_COMPANY_PROOF_OF_RESIDENCE_HELPER_TEXT[values.proof_of_residence_type] || '')}
                >
                  { Object.entries(CUSTOMER_COMPANY_PROOF_OF_RESIDENCE)
                    .filter(x => (publicSettings.use_registration_confirm_for_proof_of_residence && currentLevel === maxLv ? x[0] === "REGISTRATION_CONFIRMATION" : x[0] !== "REGISTRATION_CONFIRMATION"))
                    .map(type => (
                      <MenuItem value={type[0]} key={type[0]}>
                        {t(type[1])}
                      </MenuItem>
                  ))}
                </DropdownMaterial>
              </div>

              <div className="field easy-onboarding__individual-notolderthan3months">
                {!values.documentProofOfResidence && (
                  <ImageUpload
                    labelText={
                      <span className="text__red">
                        {t('Documents must not older than 3 months.')}
                      </span>
                    }
                    isRequired
                    buttonText={t("Click or drop images")}
                    buttonClassName="button"
                    maxFileSize={10000000}
                    withPreview
                    singleImagePick
                    singleFile
                    onChange={(img, imgURI) => {
                      let newImage = {}

                      if (img.length > 0 && imgURI.length > 0) {
                        newImage = {
                          img: img[0],
                          imgURI: imgURI[0],
                        }
                      }

                      setValues({ ...values, documentProofOfResidence: newImage })
                    }}
                    accept='image/*, application/pdf'
                  >
                    <div className="file__wrap_text text__center">
                      <p>
                        { t('Drag and drop the document') }
                      </p>
                    </div>
                  </ImageUpload>
                )}

                {values.documentProofOfResidence && (
                  <div className={classNames('settings__top', styles.upload__result)}>
                    <div className="settings__label">
                      {values.documentProofOfResidence.img.name}
                    </div>

                    <button
                      className="button-stroke button-small settings__button"
                      onClick={() => {
                        setValues({ ...values, documentProofOfResidence: null })
                      }}
                    >
                      {t('Remove')}
                    </button>
                  </div>
                )}

                {publicSettings.enable_impira && (
                  <div className="settings__fieldset">
                    <SwitchCheckboxTheme
                      switchText={
                        <>
                          <p>{t('I agree to the Terms and Conditions including the Privacy of Impira, Impira is a service to extract Data from your uploaded document. This will ensure a faster onboarding, instead of an additional manual review and check.')}</p>
                          <p><a href='https://www.impira.com/privacy' target='_blank' rel="noreferrer">{t('Privacy Policy')}</a></p>
                          <p><a href='https://www.impira.com/terms' target='_blank' rel="noreferrer">{t('Terms of Service')}</a></p>
                        </>
                      }
                      onChange={({ target: { checked } }) => setValues({ ...values, accept_impira_policy: checked })}
                      isGrayText
                      isLeftSwitch
                      isRemoveLine
                      defaultChecked={values.accept_impira_policy}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
          <button className="button-stroke" onClick={goToPrevStep}>{t('Previous')}</button>
          <button className="button" onClick={goToNextStep}>{t('Continue')}</button>
        </div>
      </div>

      {userData.rights === 'COMMON' && (
        <>
          {userData.kyc_status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED && (
            publicSettings.enable_product_tour && !values.skip_product_tour && (
              <JoyRide steps={tourBoardingDetailSourceOf} callback={({ status }) => {
                if (status === 'skipped') {
                  setValues({...values, skip_product_tour: true})
                }
              }} />
            )
          )}
        </>
      )}
    </div>
  )
}

export default CardForm;
