import React, { useEffect, useState } from 'react'
import Web3 from 'web3'
import { usePublicSettings } from '../myHooks'
import metamaskService from '../services/metamask'

const StatusIsWhitelisted = ({
  address: rawAddress
}) => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  const [isWhitelisted, setIsWhitelisted] = useState(false)
  const address = metamaskService.formatAddress(rawAddress)
  const whitelistContract = publicSettings.smart_contract_whitelist_address || publicSettings.smart_contract_address
  const contractAddress = whitelistContract ? metamaskService.formatAddress(whitelistContract) : ''

  useEffect(() => {
    if (
      metamaskService.isMetamaskAvailable() &&
      publicSettings &&
      publicSettings.enable_smart_contract &&
      contractAddress &&
      Web3.utils.isAddress(address) &&
      Web3.utils.isAddress(contractAddress)
    ) {
      metamaskService.isAddressWhitelisted(contractAddress, address).then(result => {
        setIsWhitelisted(result)
      }).catch(() => {})
    }
  }, [publicSettings, address, contractAddress])

  if (
    !publicSettings ||
    !publicSettings.enable_smart_contract ||
    !contractAddress ||
    !Web3.utils.isAddress(address) ||
    metamaskService.isMetamaskNotAvailable() ||
    !isWhitelisted
  ) {
    return null;
  }

  return (
    <div className="status-purple">
      WHITELISTED
    </div>
  )
}

export default StatusIsWhitelisted
