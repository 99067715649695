import React from 'react'
import { getOriginalFileExtension, getExternalFileUrl } from '../utils/file'
import Icon from './Icon'

const AdminUploadPreview = ({ upload, contentType, ...rest }) => {
  const renderPreview = () => {
    if (upload.preview_storage_key) {
      return (
        <img alt="" src={getExternalFileUrl(upload.preview_storage_key)} {...rest} />
      );
    }

    const uploadUrl = upload.storage_key || upload.storage_name || upload.storage_na

    if (contentType) {
      if (contentType.includes("image")) {
        return <img alt="" src={getExternalFileUrl(uploadUrl)} />;
      }

      if (contentType.includes("video")) {
        return (
          <video controls>
            <source src={getExternalFileUrl(uploadUrl)} type={contentType} />
          </video>
        );
      }

      if (contentType === 'application/pdf') {
        return (
          <embed
            src={getExternalFileUrl(uploadUrl)}
            width="500"
            height="375"
            type="application/pdf"
          />
        );
      }
    }

    if (getOriginalFileExtension(uploadUrl) === "pdf") {
      return (
        <embed
          src={getExternalFileUrl(uploadUrl)}
          width="500"
          height="375"
          type="application/pdf"
          {...rest}
        />
      );
    }

    return <img alt="" src={getExternalFileUrl(uploadUrl)} {...rest} />;
  }

  return (
    <div className="summary summary__document">
      <div className="summary__preview">
        <div className="summary__preview_aspect_ratio">
          {renderPreview()}
        </div>

        <div className="summary__control">
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
          <a className="summary__button" target="_blank" rel="noopener noreferrer" href={`${getExternalFileUrl(upload.storage_key || upload.storage_name)}`}>
            <Icon
              className="link"
              name='link'
              size="24"
            />{" "}
          </a>
        </div>
      </div>

      <div className="summary__line">
        <div className="summary__title packages__content nospace__bottom">
          {upload.storage_name}
        </div>
      </div>
    </div>
  )
}

export default AdminUploadPreview
