import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Web3 from 'web3'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { usePublicSettings } from '../myHooks'
import { toaster, isProduction } from '../utils'
import allLiquidationSetting from '../constants/liquidation'
import metamaskService from '../services/metamask'

const liquidationSetting = allLiquidationSetting[process.env.REACT_APP_ENV]

const SendCompensationButton = ({
  addressTo,
  amount,
  onSuccess,
  forceDisable,
  disableText
}) => {
  const { t } = useTranslation();
  const contractAddress = liquidationSetting.contract_address
  const decimal = liquidationSetting.decimal

  const { data: { publicSettings = {} } = {}, loadingSettings } = usePublicSettings()
  const [account, setAccount] = useState(null)
  const [accountList, setAccountList] = useState(null)
  const [balance, setBalance] = useState(null)

  useEffect(() => {
    if (window.ethereum && publicSettings && account) {
      const web3 = new Web3(window.ethereum)
      const contract = new web3.eth.Contract(liquidationSetting.abi, liquidationSetting.contract_address);
      contract.methods.balanceOf(account).call().then((walletBalance) => {
        setBalance(walletBalance / (10 ** liquidationSetting.decimal))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicSettings, account])

  useEffect(() => {
    if (metamaskService.isMetamaskAvailable()) {
      const onAccountChangeHandle = () => {
        setAccount(metamaskService.getCurrentAddress())
      }

      metamaskService.addOnAccountChangeHandle(onAccountChangeHandle)

      return () => metamaskService.removeOnAccountChangeHandle(onAccountChangeHandle)
    }

    return false;
  }, [])

  if (metamaskService.isMetamaskNotAvailable()) {
    return <p>You need to install Metamask first</p>
  }

  if (!publicSettings || loadingSettings) {
    return <CircularProgress />
  }

  const requestConnectWallet = async () => {
    if (metamaskService.isMetamaskNotAvailable()) {
      toaster.error('Metamask is not available or unable to reach.')

      return
    }

    await metamaskService.requestAccounts();
    setAccount(metamaskService.getCurrentAddress())
  }

  const sendCompensation = async () => {
    if (metamaskService.isMetamaskNotAvailable()) {
      toaster.error('Metamask is not available or unable to reach.')

      return
    }

    await metamaskService.requireNetwork(isProduction ? 'mainnet' : 'sepolia', () => {
      window.location.reload()
    })

    const web3 = new Web3(window.ethereum)
    const contract = new web3.eth.Contract(liquidationSetting.abi, contractAddress, { from: account });
    const transactionData = {
      from: account,
      to: contractAddress,
      value: "0x0",
      data: contract.methods.transfer(addressTo, amount * (10 ** +decimal)).encodeABI(),
      chainId: isProduction ? 1 : 4,
    }

    window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionData],
    }).then(txHash => {
      onSuccess(txHash)
    }).catch((error) => {
      switch (error.code) {
        case 4001:
          toaster.error('You have denied to sign the transaction. Transaction cancelled.')
          break
        default:
          toaster.error(error.message)
          break
      }
    });
  }

  if (!accountList && metamaskService.isMetamaskAvailable()) {
    window.ethereum.request({
      method: "eth_accounts",
    }).then(accounts => {
      setAccountList(accounts)
      setAccount(metamaskService.getCurrentAddress())
    })
  }

  const isDisabled = () => {
    if (forceDisable) {
      return true
    }

    if (account.toLowerCase() === addressTo.toLowerCase()) {
      return true
    }

    if (balance !== null && balance < amount) {
      return true
    }

    return false
  }

  const getDisabledMessage = () => {
    if (forceDisable) {
      return disableText
    }

    if (account.toLowerCase() === addressTo.toLowerCase()) {
      return 'You cannot send compensation to your own wallet'
    }

    if (balance !== null && balance < amount) {
      return 'Your balance is not enough to pay out'
    }

    return ''
  }

  return (
    <div>
      {!account && (
        <button className="button" onClick={requestConnectWallet}>Connect wallet</button>
      )}
      {account && (
        <div className="field">
          <div className="field__label">
            <strong>{t('Payout wallet info')}</strong>
            <br />
            {t('Payout from address')}: {account}
            <br />
            {t('Balance')}: {balance} USDC
          </div>

          <div className="field__wrap">
            <button
              className="button"
              onClick={sendCompensation}
              disabled={isDisabled()}
            >
              {t('Send compensation')}
            </button>
            {isDisabled() && (
              <p className="text__red">{getDisabledMessage()}</p>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

SendCompensationButton.propTypes = {
  addressTo: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  onSuccess: PropTypes.func,
  forceDisable: PropTypes.bool,
  disableText: PropTypes.string,
}

export default SendCompensationButton
