import React, { useEffect, useState } from 'react'

const VideoRecoredPage = ({ match }) => {
  const paramsVideoId = match.params.videoId;
  const [videoFileName, setVideoFileName] = useState(null);
  const getRecordedVideo = async () => {
    try {
      const result = await fetch(`${window.location.origin}/video-id/get-file/${paramsVideoId}`);
      const blob = await result.blob();
      if (blob) {
        setVideoFileName(URL.createObjectURL(blob));
      }
    } catch {}
  }

  useEffect(() => {
    getRecordedVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="transactions card">
        <div className="wrapper-ratio__16-9">
          <video controls src={videoFileName} />
        </div>
      </div>
    </>
  );
};

export default VideoRecoredPage;
