import React from 'react'
import AdminExchangeRatesForm from '../components/AdminExchangeRatesForm'
import { useExchangeRates, useMe } from '../myHooks'
import { hasUserEnoughRights } from '../utils'
import { USER_RIGHT_TYPES } from '../constants/user'
import { useTranslation } from 'react-i18next'

const AdminExchangeRates = () => {
  const { data: { exchangeRates = null } = {}, refetch } = useExchangeRates({
    fetchPolicy: "network-only",
  });
  const { data: { me = {} } = {} } = useMe();

  const editingAllowed = hasUserEnoughRights(
    me.rights,
    USER_RIGHT_TYPES.GENERAL_ADMIN
  );
  const { t } = useTranslation();

  return (
    exchangeRates && (
      <>
        <div className="page__title h3">{ t('Exchange Rates') }</div>

        <AdminExchangeRatesForm
          editingAllowed={editingAllowed}
          exchangeRates={exchangeRates}
          refetch={refetch}
        />
      </>
    )
  );
};

export default AdminExchangeRates;
