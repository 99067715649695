import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import Icon from '../../Icon'

const SidebarNav = ({
  goToStep,
}) => {
  const { t } = useTranslation()

  return (
    <div className="create__preview js-preview">
      <div className="card">
        <div className="card__head">
          <div className="title-primary card__title">{t('Navigation')}</div>
        </div>

        <div className="settings__fieldset onboarding-sidebar__fieldset">
          <button className="header__link color text-left" onClick={() => goToStep(0)}>
            <Icon
              className={classNames('profile-circle')}
              name='profile-circle'
              size="24"
            />{" "}
            { t('Company Details') }
          </button>

          <button className="header__link color text-left" onClick={() => goToStep(1)}>
            <Icon
              className={classNames('promotion')}
              name='promotion'
              size="24"
            />{" "}
            { t('Authorized signatories') }
          </button>

          <button className="header__link color text-left" onClick={() => goToStep(2)}>
            <Icon
              className={classNames('diamond')}
              name='diamond'
              size="24"
            />{" "}
            { t('Proof of residence') }
          </button>

          <button className="header__link color text-left" onClick={() => goToStep(3)}>
            <Icon
              className={classNames('ticket')}
              name='ticket'
              size="24"
            />{" "}
            { t('UBO') }
          </button>

          <button className="header__link color text-left" onClick={() => goToStep(4)}>
            <Icon
              className={classNames('multiselect')}
              name='multiselect'
              size="24"
            />{" "}
            { t('Legal disclaimers') }
          </button>
        </div>
      </div>
    </div>
  )
}

export default SidebarNav
