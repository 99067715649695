import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { useMutation } from '@apollo/react-hooks'
import Input from '../Input'
import InputTheme from '../InputTheme'
import MenuItem from '@material-ui/core/MenuItem'
import { useTranslation } from 'react-i18next'
import ModalInnerMaterialDialog from '../ModalInnerMaterialDialog'

const DialogExportTxtFile = ({ nameDownloadFile, isDialogOpen, closeDialog, mutation }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState({
    stepNumber: 0,
    twoFaToken: '',
    month: "01",
  })

  const [txtFileContractedAction, { loading, error, data }] = useMutation(mutation)
  let mutationBody = '';
  if (data) {
    mutationBody = data[(Object.keys(data))[0]]
  }

  const changeStep = (step) => setValue({
    ...value,
    stepNumber: step,
  })

  const txtData = mutationBody !== '' && URL.createObjectURL(new Blob([mutationBody], {type: 'text/txt'}))

  const onSubmitTwoFAToken = (e) => {
    e.preventDefault();
    changeStep(1);
  }

  const onSubmitMonth = async (e) => {
    e.preventDefault();
    try {
      await txtFileContractedAction({
        variables: { twoFaToken: value.twoFaToken, month: value.month },
      });
      changeStep(2);
    } catch (e) {}
  }

  const renderFirstStep = () => (
    <>
      <div className="refund__content">
        <div className="space space__8">{' '}</div>
        { t('Please enter your 2fa token')}
        <div className="space space__16">{' '}</div>
      </div>

      <form onSubmit={onSubmitTwoFAToken} className="settings__item">
        <div className="settings__fieldset">
          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="twoFaToken"
            label="Token"
            state={value}
            setState={setValue}
            error={error}
            loading={loading}
          />

          <div className="field">
            <button className="button" type="submit">
              { t('Next') }
            </button>
          </div>
        </div>
      </form>
    </>
  );

  const renderSelectMonthStep = () => (
    <>
      <div className="refund__content">
        <div className="space space__8">{' '}</div>
        { t('Please select month for export') }
        <div className="space space__16">{' '}</div>
      </div>

      <form onSubmit={onSubmitMonth} className="settings__item">
        <div className="settings__fieldset">
          <div className="field">
            <div className="field__label">{t('Select month')}</div>
            <div className="field__wrap field__wrap-select">
              <Input
                propertyName="month"
                state={value}
                setState={setValue}
                error={error}
                loading={loading}
                select
              >
                {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => (
                  <MenuItem key={`month_${month}`} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </Input>
            </div>
          </div>

          <div className="field">
            <button className="button" type="submit">
              { t('Next') }
            </button>
          </div>
        </div>
      </form>
    </>
  );

  const renderSuccess = () => (
    <>
      <div className="settings__item">
        <div className="settings__fieldset">
          <div className="field">
            <a
              href={txtData}
              onClick={closeDialog}
              download={`${nameDownloadFile.toLowerCase()}.txt`}
              className="button"
            >
              { t('Click to Download') }
            </a>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <Dialog open={isDialogOpen} onClose={closeDialog}>
      <ModalInnerMaterialDialog
        onClickClose={closeDialog}
        modalTitle={t('Export')}
        modalTitleClassColor="title-primary"
        modalSize="popup__kyc-medium"
      >
        <div className="kyc__stepper kyc__stepper_32">
          <Stepper activeStep={value.stepNumber}>
            <Step key={0}>
              <StepLabel>{ t('2FA token')}</StepLabel>
            </Step>
            <Step key={1}>
              <StepLabel>{ t('Select Month') }</StepLabel>
            </Step>
            <Step key={2}>
              <StepLabel>{ t('Download') }</StepLabel>
            </Step>
          </Stepper>
        </div>

        {value.stepNumber === 0 && renderFirstStep()}
        {value.stepNumber === 1 && renderSelectMonthStep()}
        {value.stepNumber === 2 && renderSuccess()}
      </ModalInnerMaterialDialog>

    </Dialog>
  );
}

DialogExportTxtFile.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired,
  nameDownloadFile: PropTypes.string.isRequired,
}

export default DialogExportTxtFile
