import React, { useState, useEffect, createRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StopIcon from '@material-ui/icons/Stop';
import SkipNextIcon from '@material-ui/icons/SkipNext';

import LivenessCheckChallenge from './LivenessCheckChallenge';

const useStyles = makeStyles({
  cameraWindow: {
    display: 'block',
    width: '100%',
    maxWidth: '500px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '10px',
  },
  pictureFrame: {
    // opacity: '0',
    // position: 'fixed',
    // top: '0',
    // left: '0',
    // pointerEvents: 'none',
  },
  videoPreview: {
    display: 'block',
    width: '100%',
    maxWidth: '500px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '10px',
  }
});

const LivenessCheckRecordVideo = ({
  onRecordVideo,
  onRecording,
  challenge,
}) => {
  const classes = useStyles();
  const streamViewer = createRef()
  const videoPreview = createRef()
  const [recordStatus, setRecordStatus] = useState('idle')
  const [recorder, setRecorder] = useState(null)
  const [mainStream, setMainStream] = useState(null)
  const [challengeStep, setChallengeStep] = useState(0)

  const recordChunksToFile = (chunks) => {
    return new Blob(chunks, {
      type: "video/mp4"
    });
  }

  const stopStream = () => {
    mainStream.getTracks().forEach((track) => {
      track.stop()
    })
    setMainStream(null)
    streamViewer.current.srcObject = null
  }

  const startRecord = () => {
    const mediaRecorder = new MediaRecorder(mainStream, {
      mimeType: 'video/mp4; codecs="avc1.424028, mp4a.40.2"'
    });
    const dom = videoPreview.current;

    mediaRecorder.ondataavailable = function (event) {
      if (event.data.size > 0) {
        const uploadFile = recordChunksToFile([event.data])
        const url = URL.createObjectURL(uploadFile);

        onRecordVideo(uploadFile)
        dom.setAttribute('src', url);
      }
    }

    mediaRecorder.start()
    setRecorder(mediaRecorder)
    setRecordStatus('recording')
  }

  const stopRecord = () => {
    recorder.stop()
    stopStream()
    setRecordStatus('complete')
  }

  const retakeVideo = () => {
    setRecordStatus('idle')
    onRecordVideo(null)
  }

  if (!mainStream && recordStatus === 'idle') {
    navigator.mediaDevices.getUserMedia({
      video: { facingMode: 'user' },
      // video: true,
      audio: true,
    }).then((stream) => {
      setMainStream(stream)
    }).catch((error) => {
      alert(error)
    });
  }

  useEffect(() => {
    if (streamViewer.current && mainStream) {
      streamViewer.current.srcObject = mainStream;
      streamViewer.current.play()
    }
  }, [mainStream, streamViewer])

  return (
    <div className="component component-liveness-check-record-video" style={{overflow: 'hidden'}}>
      <div className="camera" style={{display: recordStatus === 'complete' ? 'none' : 'block'}}>
        {recordStatus === 'recording' && (
          <LivenessCheckChallenge challenge={challenge[challengeStep]} />
        )}
        {recordStatus === 'idle' && (
          <p className="text-center">Press record button below and follow the instruction here</p>
        )}
        <video playsInline className={classes.cameraWindow} ref={streamViewer} muted>Video stream not available.</video>
        <div className="text-center">
          {recordStatus === 'idle' && (
            <button onClick={startRecord}>
              <FiberManualRecordIcon />
            </button>
          )}
          {recordStatus === 'recording' && challengeStep + 1 < challenge.length && (
            <button onClick={() => setChallengeStep(challengeStep + 1)}>
              <SkipNextIcon />
            </button>
          )}
          {recordStatus === 'recording' && challengeStep + 1 === challenge.length && (
            <button onClick={stopRecord}>
              <StopIcon />
            </button>
          )}
        </div>
      </div>
      <div className="output" style={{display: recordStatus === 'complete' ? 'block' : 'none'}}>
        <video playsInline className={classes.videoPreview} ref={videoPreview} controls>
          Preview your video.
        </video>
        <div className="text-center">
          <button onClick={retakeVideo}>Retake</button>
        </div>
      </div>
    </div>
  )
}

LivenessCheckRecordVideo.propTypes = {
  onRecordVideo: PropTypes.func.isRequired,
  onRecording: PropTypes.func,
  challenge: PropTypes.array.isRequired,
}

export default LivenessCheckRecordVideo
