import gql from 'graphql-tag'

export const CHANGE_TRANSACTION_STATUS = gql`
  mutation changeTransactionStatus($id: ID, $status: String) {
    changeTransactionStatus(id: $id, status: $status)
  }
`;

export const CANCEL_TRANSACTION_USER = gql`
  mutation cancelTransactionUser($id: ID) {
    cancelTransactionUser(id: $id)
  }
`;

export const UPDATE_VOLT_TRANSACTION_ID = gql`
  mutation updateVoltTransactionId($id: ID, $voltTransactionId: String) {
    updateVoltTransactionId(id: $id, voltTransactionId: $voltTransactionId)
  }
`;

export const UPDATE_SENDWYRE_TRANSFER_ID = gql`
  mutation updateSendwyreTransferId($id: ID, $sendwyreTransferId: String) {
    updateSendwyreTransferId(id: $id, sendwyreTransferId: $sendwyreTransferId)
  }
`;

export const APPROVED_TRANSACTION = gql`
  mutation approvedTransaction($input: approvedTransactionStatusInput) {
    approvedTransaction(input: $input)
  }
`;

export const UPDATE_ALLOCATE_TRANSACTION_HASH = gql`
  mutation updateAllocateTransactionHash($ids: [ID!]!, $hash: String!, $network: String!) {
    updateAllocateTransactionHash(ids: $ids, hash: $hash, network: $network)
  }
`;

export const UPDATE_CROWDSALE_BUY_TOKEN_TRANSACTION_HASH = gql`
  mutation updateCrowdsaleBuyTokenTransactionHash($id: ID!, $hash: String!, $network: String!) {
    updateCrowdsaleBuyTokenTransactionHash(id: $id, hash: $hash, network: $network)
  }
`;

export const CREATE_INTEREST_PAYMENT_TRANSACTION = gql`
  mutation createInterestPaymentTransaction($ids: [ID!]!) {
    createInterestPaymentTransaction(ids: $ids)
  }
`;


export const CREATE_HINT_GIVER_TRANSACTION = gql`
  mutation createHintGiverTransaction($ids: [ID!]!, $amount: String!) {
    createHintGiverTransaction(ids: $ids, amount: $amount)
  }
`;

export const MARK_CAPITAL_INCREASE = gql`
  mutation markCapitalIncrease($ids: [ID!]!) {
    markCapitalIncrease(ids: $ids)
  }
`;

export const UPDATE_TRANSACTION = gql`
  mutation updateTransaction($id: ID, $bankTransferUpload: Upload) {
    updateTransaction(id: $id, bankTransferUpload: $bankTransferUpload)
  }
`;

export const ADMIN_UPDATE_TRANSACTION = gql`
  mutation adminUpdateTransaction($id: ID, $token_amount: String) {
    adminUpdateTransaction(id: $id, token_amount: $token_amount)
  }
`;
