import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Dialog,
  CircularProgress,
} from '@material-ui/core';

import { toaster } from '../../utils';
import { useMe } from '../../myHooks';
import { CREATE_SENDWYRE_WALLET, UPDATE_SENDWYRE_WALLET_ID } from '../../queriesAndMutations/sendwyreMutation';
import ModalInnerMaterialDialog from "../ModalInnerMaterialDialog"
import '../../styles/legacy/style.scss';

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: '10px 0 26px 0',
    },
  },
  circularProgressWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const RegisterWalletDialog = ({ dialogOpen, onCloseDialog, onSuccess }) => {
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const { data: { me: userData }, refetch } = useMe();
  const classes = useStyles();
  const [values, setValues] = useState({
    sendwyre_wallet_id: null,
  });
  const [createSendwyreWallet] = useMutation(CREATE_SENDWYRE_WALLET);
  const [updateSendwyreWalletId] = useMutation(
    UPDATE_SENDWYRE_WALLET_ID
  )
  const createSendwyreWalletFunction = async (e) => {
    const timestamp = new Date().getTime();
    setFormSubmitLoading(true);
    const bodyData = {
      type: 'ENTERPRISE',
      name: `${userData.first_name} ${userData.last_name} ${timestamp}`,
      callbackUrl: window.location.host.match(/^localhost/) ? 'https://example.com' : window.location.origin,
      notes: 'first create new wallet for user',
    };
    createSendwyreWallet({
      variables: { input: { data: bodyData } },
    }).then((response) => {
      setFormSubmitLoading(false);
      console.log('createSendwyreWallet');
      console.log(response);
      if (response.data.createSendwyreWallet.status === 'success') {
        toaster.success(response.data.createSendwyreWallet.message);
        setValues({
          ...values,
          sendwyre_wallet_id: response.data.createSendwyreWallet.id,
        });
        updateSendwyreWalletId({
          variables: {
            input: response.data.createSendwyreWallet.id,
          },
        }).then((response) => {
          toaster.success("Update sendwyre wallet id for user successfully!");
          refetch();
          onSuccess();
          onCloseDialog();
        }).catch(() => {})
      } else {
        onCloseDialog();
        console.log(response.data)
        toaster.error('Fail to create wallet');
      }
    }).catch(() => {});
  }

  useEffect(() => {
    if (dialogOpen) {
      createSendwyreWalletFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen]);

  return (
    <>
      <Dialog
        className={classes.dialog}
        open={dialogOpen}
        onClose={onCloseDialog}
      >
        <ModalInnerMaterialDialog
          modalSize="popup__kyc-medium"
          onClickClose={onCloseDialog}
          modalTitleClassColor="title-primary"
          modalTitle="Create Sendwyre Wallet"
        >
          <div className="description__row flex__row flex__wrap">
            {formSubmitLoading ? (
              <div className={classes.circularProgressWrapper}>
                <CircularProgress />
              </div>
            ) : (
              `Your new Sendwyre's wallet id: ${values.sendwyre_wallet_id}`
            )}
          </div>
        </ModalInnerMaterialDialog>
      </Dialog>
    </>
  );
};

RegisterWalletDialog.propTypes = {
  dialogOpen: PropTypes.bool,
  onCloseDialog: PropTypes.func.isRequired,
};

export default RegisterWalletDialog;
