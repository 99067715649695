import React, { useState, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useMutation, useQuery } from '@apollo/react-hooks'
import RefreshIcon from '@material-ui/icons/Refresh'
import pick from 'lodash/pick'
import { isEmpty } from 'lodash'
import Dialog from '@material-ui/core/Dialog'
import moment from 'moment'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import gql from 'graphql-tag'
import parser from 'ua-parser-js'
import ReactJson from 'react-json-view'
import ReactTooltip from 'react-tooltip'

import OutsideClickHandler from "react-outside-click-handler"
import MaterialAccordion from '@material-ui/core/Accordion'
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary'
import MaterialAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AdminComment from './AdminComment'
import AdminTransactionsUser from './AdminTransactionsUser'
import AdminHistoryChangeStatusKyc from './AdminHistoryChangeStatusKyc'
import AdminDialogUploadPhoto from './AdminDialogUploadPhoto'
import AdminDialogVideoIdentLog from './AdminDialogVideoIdentLog'
import PopoverResponse from './PopoverResponse'
import { TOKEN_TYPES } from '../constants/settings'
import SIC_CODES from '../constants/sic_code'
import { CUSTOMER_STATUS_TYPES, CUSTOMER_TYPES, DOCUMENT_TYPES } from '../constants/customer'
import { IDENTIFICATION_ERROR } from '../constants/identification'
import { getExternalFileUrl, numberWithCommas, toaster, isFeatureEnabled, isAllRequiredFeaturesEnabled } from '../utils'
import { getOriginalFileExtension } from '../utils/file'
import { USER_VIDEO_IDENT_STATUS, USER_RIGHT_TYPES } from '../constants/user'
import { COMMENT_TYPES } from '../constants/comment'
import { COUNTRY_BY_ALPHA_3_CODE } from '../constants/country'
import { usePublicSettings } from '../myHooks/useSettings'
import { useExchangeRates } from '../myHooks'
import TablePaginationActions from './TablePagination'
import AdminKycAutoApprovalLog from './AdminKycAutoApprovalLog'
import AdminCompensatableTable from './AdminCompensatableTable'
import AdminLiquidationProofViewer from './AdminLiquidationProofViewer'
import AdminLivenessCheckViewer from './AdminLivenessCheckViewer'
import AdminWatchlistCheckViewer from './AdminWatchlistCheckViewer/AdminWatchlistCheckViewer'

import {
  KYC_LVL_HISTORY_USER_ADMIN,
  CHANGE_STATUS_KYC_LEVEL,
  UPLOAD_NEW_PHOTO,
  LAST_CHECK_ETH_ADDRESS,
  LAST_CHECK_ETH_SENDING_ADDRESS,
  UPDATE_DIRECTOR_STATUS,
  TRANSACTION_RISK_EVALUATE,
  CHANGE_CUSTOMER_COMPANY_STATUS,
  CHANGE_CUSTOMER_STATUS,
  GET_AVAILABLE_FEATURES,
  CONFIRM_SPOT_CHECK_COMPLETED,
  UPDATE_CREDITOR_NUMBER,
  MANUAL_FETCH_CHECK,
  CHECK_FIELDS_IS_FILLED,
  TRANSACTIONS_USER,
  TRIGGER_TOKENSWAP_SIGNNOW,
  TRIGGER_TOKENSALE_SIGNNOW,
  RESEND_EMAIL_INVITE,
  GENERATE_COINFIRM_ENHANCED_REPORT,
  ADMIN_ADD_KYC_LEVEL,
  ADMIN_REMOVE_KYC_LEVEL,
} from '../queriesAndMutations'
import {
  SOURCE_OF_FUNDS_STANDARD_SCHEMAS,
  SOURCE_OF_FUNDS_PUBLIC_SCHEMAS,
  SOURCE_OF_WEALTH_STANDARD_SCHEMAS,
  SOURCE_OF_WEALTH_PUBLIC_SCHEMAS,
  INDIVIDUAL_FILES_SCHEMAS,
  COMPANY_FILES_SCHEMAS,
} from '../schemas/kycVerification'
import {
  RISK_PARAMETERS,
  COUNTRY_OF_NATIONALITY,
  COUNTRY_OF_RESIDENCE,
  SOURCE_OF_FUNDS,
  SIZE_OF_TRANSACTION,
} from '../constants/riskAssessmentLookup'

import SupportedSourceOfFundDocuments from './SupportedSourceOfFundDocuments'
import DisclaimersKycIndividual from './DisclaimersKycIndividual'
import DisclaimersKycCompany from './DisclaimersKycCompany'
import AdminKycBlacklistCheck from './AdminKycBlacklistCheck'
import { useTranslation } from 'react-i18next'
import EditIcon from '@material-ui/icons/Edit'
import FeatureNotAvailableMessage from './FeatureNotAvailableMessage'
import Icon from './Icon'
import classNames from 'classnames'
import ThemeTableRow from './TableRow'
import ModalInnerMaterialDialog from './ModalInnerMaterialDialog'
import AdminUploadPreview from './AdminUploadPreview'
import InputThemeField from './InputThemeField'
import { ReportView } from './OnfidoReports'
import { ReportView as ComplycubeReportView} from './ComplycubeReports'
import LocationMap from './LocationMap'
import ShortenText from './ShortenText'
import KybDirectorStatus from './KybDirectorStatus'
import KybUboStatus from './KybUboStatus'
import { hasUserEnoughRights } from '../utils/me'

const VIDEO_ID_RECORDED_EXTRACTED_DATA = gql`
  query videoIDRecoredExtractedData($video_ident_id: String!) {
    videoIDRecoredExtractedData(video_ident_id: $video_ident_id)
  }
`;

const useStyles = makeStyles((theme) => {
  const statusBadge = {
    padding: "6px 12px",
    border: "1px solid #000",
    borderRadius: "8%",
    textTransform: "lowercase",
  };

  return {
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
      "& div": {
        "& span": {
          color: "unset",
        },
      },
    },
    marginTopBtn: {
      marginTop: "22px",
    },
    marginBottomBtn: {
      marginBottom: "22px",
    },
    drawerHeader: {
      padding: "24px",
      borderBottom: "1px solid #d2dde9",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      color: "#253992",
      fontWeight: 500,
      fontSize: "23px",
      margin: 0,
    },
    drawerBody: {
      padding: "24px",
    },
    fullList: {
      width: "auto",
    },
    tableTitle: {
      color: "#758698",
      fontWeight: "bold",
    },
    info: {
      borderRadius: "4px",
      border: "1px solid #d2dde9",
      "&>li": {
        display: "flex",
      },
    },
    infoHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px 30px",
      fontSize: "14px",
      fontWeight: 500,
      color: "#758698",
      width: "500px",
      textAlign: "center",
    },
    infoDes: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "10px 30px",
      fontSize: "14px",
      color: "#495463",
      fontWeight: 400,
      flexGrow: 1,
      borderBottom: "1px solid #d2dde9",
      borderLeft: "1px solid #d2dde9",
      width: "calc(100% - 50vw)",
      textAlign: "center",
    },
    infoDesText: {
      color: "#758698",
      fontSize: "14px",
      fontWeight: "500",
    },
    imgLink: {
      position: "relative",
      "&:hover": {},
    },
    imgLinkMask: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: ".5s",
      "& svg": {
        opacity: 0,
      },
      "&:hover": {
        transition: ".5s",
        backgroundColor: "#000",
        opacity: ".4",
        "& svg": {
          color: "#007bff",
          opacity: 1,
        },
      },
    },
    img: {
      maxHeight: "200px",
    },
    mainKycInfo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "4px",
      border: "1px solid #d2dde9",
      padding: "10px 10px",
      "&>div": {
        paddingLeft: "5px",
        paddingRight: "5px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "&>span": {
          textAlign: "center",
        },
      },
    },
    idleStatusBadge: statusBadge,
    pendingStatusBadge: {
      ...statusBadge,
      borderColor: "#ffc100",
    },
    activeStatusBadge: {
      ...statusBadge,
      borderColor: "#009f65",
    },
    rejectedStatusBadge: {
      ...statusBadge,
      borderColor: "#ea0b0b",
    },
    actionBtnPaper: {
      zIndex: 10,
      position: "absolute",
      right: "25px",
      "&>div": {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        "&>svg": {
          marginRight: "5px",
        },
        padding: "8px 10px",
      },
    },
    errorStatusIdentification: {
      color: "#ea0b0b",
    },
    succeededStatusIdentification: {
      color: "#009f65",
    },
    confirmParagraph: {
      paddingLeft: "20px",
      margin: "0 0 0 30px",
      listStyleType: "square",
      "& li:last-child": {
        paddingBottom: "0",
      },
      "& li": {
        padding: "0 0 25px 0",
      },
      "&>li": {
        listStyleType: "square",
        paddingBottom: "25px",
        "&>ul": {
          padding: "20px 0 0 20px",
          margin: "0 0 0 30px",
          listStyleType: "circle",
          "&>li": {
            listStyleType: "circle",
          },
        },
      },
    },
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
    wordBreak: {
      wordBreak: "wrap",
    },
    high_risk: {
      color: "#ea0b0b",
    },
    medium_risk: {
      color: "#009f65",
    },
    enhanced_risk: {
      color: "#009f65",
    },
    videoIdentRecoredLink: {
      marginLeft: "10px",
      padding: "10px",
      color: "white",
      textTransform: "uppercase",
    },
    tableHeader: {
      "& .MuiTableCell-head": {
        color: "#007bff",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
    stickyWrap: {
      maxHeight: '80vh',
      overflow: 'auto',
    },
    stickyHeader: {
      position: 'sticky',
      top: 0,
      zIndex: 4,
    },
    textGreen: {
      color: "#009f65",
    }
  };
});

const initRiskAssessmentData = () => {
  const result = {};

  RISK_PARAMETERS.forEach((param) => {
    result[param.toLowerCase()] = { level: null, score: null };
  });

  return result;
};

const getDevice = (ua) => {
  const os = ua.os.name.trim()
  const browser = `${ua.browser.name || ''} ${ua.browser.version || ''}`.trim()
  const device = `${ua.device.vendor || ''} ${ua.device.type || ''}`.trim()

  return [os, browser, device].filter(txt => !!txt).join(', ')
}

const AdminKycDrawer = ({
  me,
  refetch,
  user,
  userKyc,
  open,
  onClickDrawerAway,
  mainScheme,
  addressScheme,
  filesScheme,
  onChangeStatus,
  onChangeUserVideoIdent,
  customerType,
}) => {
  const scrollToSectionTransaction = useRef(null);
  const scrollToSectionDirectors = useRef(null);

  const currentHost = window.location.host;
  const isQdev = currentHost === 'kyc.qdev.li' || currentHost === 'qdevelopment-dev.investhub.io' || currentHost === 'localhost:3000';
  const isAwearetech = currentHost === 'kyc.awearetech.li' || currentHost === 'aweare-dev.investhub.io' || currentHost === 'localhost:3000';

  const userAgent = userKyc.lastLoginHistory ? parser(userKyc.lastLoginHistory.userAgent) : null
  const classes = useStyles();
  const { t } = useTranslation();
  const [actionOpen, setActionOpen] = React.useState(false);
  const [isCreditorNumberDialogOpen, setIsCreditorNumberDialogOpen] = React.useState(false);
  const [creditorNumber, setCreditorNumber] = React.useState('');
  const [openUploadNewPhoto, setOpenUploadNewPhoto] = React.useState(false);
  const [openUpVideoIdentLog, setOpenUpVideoIdentLog] = React.useState(false);
  const [pageKycLvlHistory, setPageKycLvlHistory] = React.useState(0);
  const [pageSizeKycLvlHistory, setPageSizeKycLvlHistory] = React.useState(5);
  const { data: { publicSettings = {} } = {}, loading: loadingPublicSetting } =
    usePublicSettings();
  const [changeCustomerCompanyStatus, changeCustomerCompanyStatusData] =
    useMutation(CHANGE_CUSTOMER_COMPANY_STATUS);
  const [changeCustomerStatus, changeCustomerStatusData] =
    useMutation(CHANGE_CUSTOMER_STATUS);
  const { data: { getAvailableFeatures: availableFeatures } = {} } = useQuery(GET_AVAILABLE_FEATURES);
  const [manualFetchCheck, manualFetchCheckStatus] = useMutation(MANUAL_FETCH_CHECK);
  const [adminAddKycLevel, adminAddKycLevelStatus] = useMutation(ADMIN_ADD_KYC_LEVEL);
  const [adminRemoveKycLevel, adminRemoveKycLevelStatus] = useMutation(ADMIN_REMOVE_KYC_LEVEL);

  const triggerManualFetch = (applicantId) => () => {
    manualFetchCheck({
      variables: {
        applicant_id: applicantId
      }
    }).then(({ data: { manualFetchCheck: hasUpdate } }) => {
      if (hasUpdate) {
        refetch();
      }
    }).catch(() => {});
  }
  const updateApplicationStatus = (status) => {
    if (customerType === CUSTOMER_TYPES.INDIVIDUAL) {
      changeCustomerStatus({
        variables: {
          input: {
            id: userKyc.id,
            status: status,
            comment: null,
          }
        }
      }).then(() => {
        refetch();
        toaster.success('Application is '+status.toLowerCase());
      }).catch(() => {})
    } else {
      changeCustomerCompanyStatus({
        variables: {
          input: {
            id: userKyc.id,
            status: status,
            comment: null,
          }
        }
      }).then(() => {
        refetch();
        toaster.success('Application is '+status.toLowerCase());
      }).catch(() => {})
    }
  }

  const { data: { exchangeRates } = {} } = useExchangeRates();
  const [changeStatusKycLevel, changeStatusKycLevelStatus] = useMutation(CHANGE_STATUS_KYC_LEVEL);
  const [uploadNewPhoto] = useMutation(UPLOAD_NEW_PHOTO);
  const [updateDirectorStatus, {loading: loadingUpdateDirectorStatus}] = useMutation(UPDATE_DIRECTOR_STATUS);
  const isExtractEnabled = isFeatureEnabled('LIVENESS_CHECK_EXTRACT_AND_COMPARE', availableFeatures)
  const [openCommentForReopen, setOpenCommentForReopen] = useState(false);
  const [reopenWithVideoIdent, setReopenWithVideoIdent] = useState(false);
  const [selectedDirector, setSelectedDirector] = useState(null);
  const [reopenComment, setReopenComment] = useState('');
  const [confirmSpotCheckCompleted, confirmSpotCheckCompletedStatus] = useMutation(CONFIRM_SPOT_CHECK_COMPLETED);
  const [updateCreditorNumber, updateCreditorNumberStatus] = useMutation(UPDATE_CREDITOR_NUMBER);
  const [triggerTokenSwapSignnow, triggerTokenSwapSignnowStatus] = useMutation(TRIGGER_TOKENSWAP_SIGNNOW);
  const [triggerTokenSaleSignnow, triggerTokenSaleSignnowStatus] = useMutation(TRIGGER_TOKENSALE_SIGNNOW);

  const [resendEmailInvite] = useMutation(RESEND_EMAIL_INVITE);
  const [generateCoinfirmEnhancedReport, generateCoinfirmEnhancedReportStatus] = useMutation(GENERATE_COINFIRM_ENHANCED_REPORT);

  const { data: { transactionRiskEvaluate } = {} } = useQuery(
    TRANSACTION_RISK_EVALUATE,
    {
      variables: {
        input: {
          userId: user.id,
        },
      },
    }
  );
  const baseCurrency = publicSettings.kyc_use_base_currency ? publicSettings.base_currency : 'CHF';
  const [swapTokenSigners, setSwapTokenSigners] = useState({});
  const eDocument = userKyc.documentESignature && userKyc.documentESignature.length
    ? userKyc.documentESignature[0]
    : {}
  let fields = [];

  if (eDocument.document_type === DOCUMENT_TYPES.INDIVIDUAL_FORM) {
    fields = ["Signature_1"]
  } else if (
    [
      DOCUMENT_TYPES.TOKEN_SAFE,
      DOCUMENT_TYPES.KYB_SINGLE_FORM,
      DOCUMENT_TYPES.KYB_COLLECTIVE_FORM,
      DOCUMENT_TYPES.LIQUIDATION_FORM
    ].includes(eDocument.document_type)) {
    fields = ["Signature_1", "Signature_2"]
  } else {
    fields = ["Signature_1", "Signature_2", "Signature_3"]
  }

  const { data: { checkFieldsIsFilled = {} } = {} } = useQuery(
    CHECK_FIELDS_IS_FILLED,
    {
      variables: {
        input: {
          fields,
          filename: eDocument.remote_id,
        },
      },
    },
    {
      enabled: userKyc.documentESignature && userKyc.documentESignature.length > 0
    }
  );

  React.useEffect(() => {
    if (checkFieldsIsFilled && checkFieldsIsFilled.result) {
      setSwapTokenSigners(checkFieldsIsFilled.result);
    }

    // eslint-disable-next-line
  }, [checkFieldsIsFilled]);

  const handleUpdateDirectorStatus = (id, status) => {
    updateDirectorStatus({
      variables: {
        id: id,
        status: status,
        comment: reopenComment,
      }
    }).then(() => {
      refetch()
      setReopenComment('')
      setOpenCommentForReopen(false)
    })
    .catch(e => console.log(e))
  }

  const {
    data: { kycLvlHistoryUserAdmin = {} } = {},
    refetch: refetchKycLvlHistoryUserAdmin,
  } = useQuery(KYC_LVL_HISTORY_USER_ADMIN, {
    variables: {
      input: {
        userId: userKyc.user_id,
        customerId: userKyc.id,
        page: pageKycLvlHistory,
        pageSize: pageSizeKycLvlHistory,
        customerType,
      },
    },
  });

  const { data: { lastCheckEthAddress = {} } = {} } = useQuery(
    LAST_CHECK_ETH_ADDRESS,
    {
      variables: {
        input: {
          type: customerType,
          kycId: userKyc.id,
        },
      },
    }
  );

  const { data: { lastCheckEthSendingAddress = {} } = {} } = useQuery(
    LAST_CHECK_ETH_SENDING_ADDRESS,
    {
      variables: {
        input: {
          type: customerType,
          kycId: userKyc.id,
        },
      },
    }
  );

  const { data: { videoRecoredExtractedData = {} } = {} } = useQuery(
    VIDEO_ID_RECORDED_EXTRACTED_DATA,
    {
      variables: { video_ident_id: user.video_ident_id || "" },
    }
  );

  const { data: { transactionsUser: transactionsUserList } = {} } = useQuery(
    TRANSACTIONS_USER,
    {
      variables: {
        input: {
          page: 0,
          pageSize: 1000,
          userId: user.id,
        },
      },
      fetchPolicy: "network-only",
    }
  );

  const completedChecks =
    (userKyc &&
      userKyc.onfidoApplicant &&
      userKyc.onfidoApplicant.onfidoChecks.filter(
      (check) => check.status === "complete"
    )) ||
    [];

  completedChecks.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  const latestCheck = completedChecks[0];
  const completedCheck = completedChecks.find(check => check.onfidoReports.length >= 2);

  const documentReport =
    completedCheck &&
    completedCheck.onfidoReports &&
    completedCheck.onfidoReports.find((report) => report.name === "document");
  let extractedData = {};

  if (documentReport && !isEmpty(documentReport.properties)) {
    extractedData = {...documentReport.properties}
    extractedData.primaryName = documentReport.properties.firstName;
    extractedData.secondaryName = documentReport.properties.lastName;
    extractedData.passportNumber = `${
      documentReport.properties.documentType
    }: ${
      documentReport.properties.documentNumbers &&
      documentReport.properties.documentNumbers[0].value
    }`;
    extractedData.birthDateTS = documentReport.properties.dateOfBirth;
  } else if (!isEmpty(videoRecoredExtractedData)) {
    extractedData = { ...videoRecoredExtractedData };
    extractedData.birthDateTS = moment(
      videoRecoredExtractedData.birthDateTS
    ).format("YYYY-MM-DD");
  } else if (userKyc.idnowIdent && userKyc.idnowIdent.response) {
    const idnowResponse = userKyc.idnowIdent.response;
    extractedData.primaryName = idnowResponse.userdata && idnowResponse.userdata.firstname && idnowResponse.userdata.firstname.value;
    extractedData.secondaryName = idnowResponse.userdata && idnowResponse.userdata.lastname && idnowResponse.userdata.lastname.value;
    extractedData.birthDateTS = idnowResponse.userdata && idnowResponse.userdata.birthday && idnowResponse.userdata.birthday.value;
    extractedData.pepAndSanctions = idnowResponse.pepAndSanctions && idnowResponse.pepAndSanctions.matchStatus;

    extractedData.passportNumber = idnowResponse.identificationdocument && idnowResponse.identificationdocument.number && idnowResponse.identificationdocument.number.value;
    extractedData.issuingCountry = idnowResponse.identificationdocument && idnowResponse.identificationdocument.country && idnowResponse.identificationdocument.country.value;
  } else if (userKyc.complycubeClient && userKyc.complycubeClient.complycubeChecks.length) {
    const documentCheck = userKyc.complycubeClient.complycubeChecks.find(check => check.check_type === 'document_check');
    const properties = documentCheck ? (((documentCheck.result || {}).breakdown || {}).extractedData || {}) : {}
    extractedData.primaryName = properties.holderDetails && properties.holderDetails.firstName && properties.holderDetails.firstName.join(' ');
    extractedData.secondaryName = properties.holderDetails && properties.holderDetails.lastName && properties.holderDetails.lastName.join(' ');
    extractedData.birthDateTS = properties.holderDetails && properties.holderDetails.dob && `${properties.holderDetails.dob.year}-${properties.holderDetails.dob.month}-${properties.holderDetails.dob.day}`
    extractedData.nationality = properties.holderDetails && properties.holderDetails.nationality;
    const screeningCheck = userKyc.complycubeClient.complycubeChecks.find(check => check.check_type === 'extensive_screening_check');
    extractedData.pepAndSanctions = screeningCheck && screeningCheck.result && screeningCheck.result.outcome;

    extractedData.passportNumber = properties.documentDetails && properties.documentDetails.documentNumber;
    extractedData.issuingCountry = properties.documentDetails && properties.documentDetails.issuingCountry;
  }

  const [calculatedRiskAssessment, setCalculatedRiskAssessment] =
    React.useState(initRiskAssessmentData());

  React.useEffect(() => {
    if (userKyc) {
      const newCalculatedRiskAssessment = { ...calculatedRiskAssessment };
      RISK_PARAMETERS.forEach((riskParam) => {
        let riskData;
        switch (riskParam) {
          case "COUNTRY_OF_NATIONALITY": {
            const userKycNationality = userKyc.nationality || userKyc.companyCountry;
            riskData = COUNTRY_OF_NATIONALITY.find(
              (item) => item.value === userKycNationality
            );
            break;
          }
          case 'BUSINESS_COUNTRIES': {
            const userKycBusinessCountries = (userKyc.business_countries || '').split(',').filter(Boolean).map(x => String(x).trim());
            riskData = COUNTRY_OF_NATIONALITY.filter(
              (item) => userKycBusinessCountries.includes(item.value)
            );
            let level = riskData.find((row) => row.level === 'High') ? 'High' : ''
            const score = riskData.reduce((sum, row) => {
              sum += row.score;
              return sum;
            }, 0)

            if (!level) {
              if (score > 4) {
                level = "High"
              } else if (score > 3) {
                level = "Enhanced"
              } else if (score > 1) {
                level = "Medium"
              } else {
                level = "Low"
              }
            }

            riskData = { level, score }
            break;
          }
          case "COUNTRY_OF_RESIDENCE": {
            const userKycCountryOfResidence = userKyc.countryOfResidence;
            riskData = COUNTRY_OF_RESIDENCE.find(
              (item) => item.value === userKycCountryOfResidence
            );
            break;
          }
          case "BENEFICIAL_OWNER": {
            let watchlistEnhancedReport;

            if (latestCheck && latestCheck.onfidoReports) {
              watchlistEnhancedReport = latestCheck.onfidoReports.find(
                (report) => report.name === "watchlist_enhanced"
              );
            }

            const complycubeChecks = userKyc.complycubeClient && userKyc.complycubeClient.complycubeChecks;
            const watchlistEnhancedCheck = complycubeChecks && complycubeChecks.find(check => check.check_type === 'extensive_screening_check' && check.status === 'complete')

            if (watchlistEnhancedReport) {
              if (watchlistEnhancedReport.result === "clear") {
                riskData = { level: "Low", score: 1 };
              } else {
                riskData = { level: "High", score: 5 };
              }
              break;
            } else if (watchlistEnhancedCheck) {
              if (watchlistEnhancedCheck.result && watchlistEnhancedCheck.result.outcome === "clear") {
                riskData = { level: "Low", score: 1 };
              } else {
                riskData = { level: "High", score: 5 };
              }
              break;
            } else {
              if (userKyc.personType === 'NOT_POLITICAL_PERSON') {
                riskData = { level: "Low", score: 1 };
              } else {
                riskData = { level: "High", score: 5 };
              }
              break;
            }
          }
          case "SIZE_OF_TRANSACTION": {
            const userKycIntendedInvestment = userKyc.intended_investment || "";
            let [,, maxIntended] = userKycIntendedInvestment.match(/([\d,]+) - ([\d,]+)/) || [];

            if (maxIntended) {
              maxIntended = parseInt(maxIntended.replaceAll(',', ''))
              riskData = SIZE_OF_TRANSACTION.find(
                (item) => item.range[0] <= maxIntended && maxIntended <= item.range[1]
              )
            } else {
              riskData = SIZE_OF_TRANSACTION.find(
                (item) => item.intended_text_map.reduce(
                  (all, text_map) => all || userKycIntendedInvestment.includes(text_map),
                  false
                )
              );
            }

            if (riskData) {
              break;
            }

            riskData = { level: "Enhanced", score: 5 };
            break;
          }
          case "CLIENT_REPUTATION_RELATIONSHIP": {
            riskData = { level: "Medium", score: 3 };
            break;
          }
          case "SOURCE_OF_FUNDS": {
            let riskSourceOfFundsId;
            if (
              userKyc.source_of_funds === "Gift" ||
              userKyc.source_of_funds === "Inheritance"
            ) {
              riskSourceOfFundsId = 3;
            } else if (
              userKyc.source_of_funds === "Salary" ||
              userKyc.source_of_funds === "Dividends or Profits from company"
            ) {
              riskSourceOfFundsId = 1;
            }

            if (riskSourceOfFundsId) {
              riskData = SOURCE_OF_FUNDS.find((element) => {
                return element.id === riskSourceOfFundsId;
              });
            } else {
              riskData = { level: "Low", score: 1 };
            }

            break;
          }
          default:
            riskData = { level: null, score: null };
            break;
        }
        newCalculatedRiskAssessment[`${riskParam.toLowerCase()}`] = {
          level: riskData && riskData.level,
          score: riskData && riskData.score,
        };
      });
      newCalculatedRiskAssessment.transaction.score = transactionRiskEvaluate;
      if (transactionRiskEvaluate > 4) {
        newCalculatedRiskAssessment.transaction.level = "High"
      } else if (transactionRiskEvaluate > 3) {
        newCalculatedRiskAssessment.transaction.level = "Enhanced"
      } else if (transactionRiskEvaluate > 1) {
        newCalculatedRiskAssessment.transaction.level = "Medium"
      } else {
        newCalculatedRiskAssessment.transaction.level = "Low"
      }
      setCalculatedRiskAssessment(newCalculatedRiskAssessment);
    }
    // eslint-disable-next-line
  }, [userKyc, completedCheck, transactionRiskEvaluate]);

  const filesSchemas =
    customerType === CUSTOMER_TYPES.INDIVIDUAL
      ? INDIVIDUAL_FILES_SCHEMAS
      : COMPANY_FILES_SCHEMAS;

  const [fileValues, setFileValues] = useState({});
  const [documentTypeValues, setDocumentTypeValues] = useState({
    documentType: userKyc.documentType,
  });

  const isHasVideoIdentId = !!user.video_ident_id;

  const isValidFirstName =
    videoRecoredExtractedData !== undefined &&
    (videoRecoredExtractedData || {}).primaryName === userKyc.first_name;

  const isValidLastName =
    videoRecoredExtractedData !== undefined &&
    (videoRecoredExtractedData || {}).secondaryName === userKyc.last_name;

  const onActionBtnClickAway = () => {
    setActionOpen(false);
  };

  const onCloseDialogUploadPhoto = () => {
    setOpenUploadNewPhoto(false);
  };

  const onCloseDialogVideoIdentLog = () => {
    setOpenUpVideoIdentLog(false);
  };

  const onOpenDialogUploadPhoto = () => {
    setOpenUploadNewPhoto(true);
  };

  const onOpenDialogVideoIdentLog = () => {
    setOpenUpVideoIdentLog(true);
  };

  const onChangePage = (event, newPage) => {
    setPageKycLvlHistory(newPage);
  };

  const onChangePageSize = (event) => {
    if (parseInt(event.target.value, 10)) {
      setPageSizeKycLvlHistory(parseInt(event.target.value, 10));
      setPageKycLvlHistory(0);
    } else {
      setPageSizeKycLvlHistory(1);
      setPageKycLvlHistory(0);
    }
  };

  const renderKycStatus = (status) => {
    const statusStyles = {
      [CUSTOMER_STATUS_TYPES.IDLE]: 'status-idle',
      [CUSTOMER_STATUS_TYPES.WHITELISTED]: 'status-purple-dark',
      [CUSTOMER_STATUS_TYPES.APPROVED]: 'status-green-dark',
      [CUSTOMER_STATUS_TYPES.PENDING]: 'status-pending-dark',
      [CUSTOMER_STATUS_TYPES.REJECTED]: 'status-red-dark',
      [CUSTOMER_STATUS_TYPES.REOPEN]: 'status-pending-dark',
      [CUSTOMER_STATUS_TYPES.CONTRACTED]: 'status-green-dark',
      [CUSTOMER_STATUS_TYPES.KYC_LVL_CHANGE]: 'status-pending-dark',
    };

    return <span className={statusStyles[status]}>{status}</span>;
  };

  const onAddKycLevel = (status) => {
    adminAddKycLevel({
      variables: {
        input: {
          customerId: userKyc.id,
          customerType,
        }
      }
    }).then(() => {
      refetchKycLvlHistoryUserAdmin();
      toaster.success('Success');
    }).catch(() => {})
  }

  const onRemoveKycLevel = (status) => {
    adminRemoveKycLevel({
      variables: {
        input: {
          customerId: userKyc.id,
          customerType,
        }
      }
    }).then(() => {
      refetchKycLvlHistoryUserAdmin();
      toaster.success('Success');
    }).catch(() => {})
  }

  const changeKycLvl = async (newStatus, id) => {
    changeStatusKycLevel({
      variables: {
        input: {
          id,
          customerId: userKyc.id,
          customerType,
          newStatus,
        },
      },
    }).then(() => {
      refetchKycLvlHistoryUserAdmin();
      refetch();
    }).catch(() => {})
  };

  const onUploadPhoto = (kyc_id) => () => {
    const files = Object.keys(fileValues).reduce(
      (prev, current) => ({
        ...prev,
        [current]: fileValues[current].img,
      }),
      {}
    );

    const inputData = {
      ...pick(files, [...Object.keys(filesSchemas)]),
      ...documentTypeValues,
    };
    inputData.customerType = customerType;
    inputData.kycId = kyc_id;

    uploadNewPhoto({ variables: { input: inputData } }).then(() => {
      onCloseDialogUploadPhoto();
    }).catch(() => {});
  };

  const updateCreditorNumberAndInviteToSign = () => {
    updateCreditorNumber({
      variables: {
        customerId: userKyc.id,
        creditorNumber,
      }
    }).then(({ data: { updateCreditorNumber: isSuccess } }) => {
      if (isSuccess) {
        setIsCreditorNumberDialogOpen(false)
        refetch()
      }
    }).catch(() => {})
  }

  const onTriggerTokenSwapSignnow = () => {
    triggerTokenSwapSignnow({
      variables: {
        customerId: userKyc.id,
        customerType
      }
    }).then(({ data: { triggerTokenSwapSignnow: isSuccess } }) => {
      if (isSuccess) {
        toaster.success("Success")
      }
    }).catch(() => {
      toaster.error("Something wrong")
    })
  }

  const onTriggerTokenSaleSignnow = () => {
    triggerTokenSaleSignnow({
      variables: {
        customerId: userKyc.id,
        customerType
      }
    }).then(({ data: { triggerTokenSaleSignnow: isSuccess } }) => {
      if (isSuccess) {
        toaster.success("Success")
      }
    }).catch(() => {
      toaster.error("Something wrong")
    })
  }

  const sendSignnowReminderEmail = (signer) => {
    if (signer && signer.field_request_id) {
      resendEmailInvite({
        variables: {
          input: {
            field_request_id: signer.field_request_id,
          },
        }
      }).then(({ data: { resendEmailInvite: result } }) => {
        if (result.result) {
          toaster.success("Send reminder successfully")
        } else {
          toaster.error("Can't send reminder")
        }
      }).catch(() => {
        toaster.error("Something wrong")
      })
    } else {
      toaster.error("Can't send reminder")
    }
  }

  const onGenerateCoinfirmEnhancedReport = (kyc, customer_type, address) => {
    generateCoinfirmEnhancedReport({
      variables: {
        input: {
          customer_id: kyc.id,
          customer_type,
          address,
        }
      }
    }).then(({ data: { generateCoinfirmEnhancedReport: success } }) => {
      if (success) {
        toaster.success("Generate Coinfirm EnhancedReport successfully")
        refetch()
      } else {
        toaster.error("Can't generate")
      }
    }).catch((error) => {
      console.log(error)
      toaster.error("Something wrong")
    })
  }

  const renderKycLevel = (object) => {
    if (customerType === CUSTOMER_TYPES.COMPANY && typeof object === "object") {
      const customerCompany = object;
      return (
        <div key={customerCompany.id} className="transactions__row">
          <div className="transactions__col products__col-first-clear">
            <div className="transactions__label">{t('Date')}</div>
            {new Date(customerCompany.created_at).toDateString()}
            <br />
            {moment(customerCompany.created_at).format("h:mm:ss a")}
          </div>
          <div className="transactions__col vert__middle">
            <div className="transactions__label">{t('Level')}</div>
            {customerCompany.kyc_level}
          </div>
          <div className="transactions__col vert__middle">
            <div className="transactions__label">{t('Amount in CHF')}</div>
            {
              publicSettings.kyc_levels[customerCompany.kyc_level].enable &&
              publicSettings.kyc_levels[customerCompany.kyc_level].enable.enable_open_end
              ? (userKyc.exactIntendedInvestmentAmount
                ? `${numberWithCommas(publicSettings.kyc_levels[customerCompany.kyc_level].min_invest_amount)} - ${numberWithCommas(userKyc.exactIntendedInvestmentAmount)} ${baseCurrency}`
                : `> ${numberWithCommas(publicSettings.kyc_levels[customerCompany.kyc_level].min_invest_amount)}`
              )
              : (
                <>
                  {numberWithCommas(publicSettings.kyc_levels[customerCompany.kyc_level].min_invest_amount)}
                  {' - '}
                  {numberWithCommas(publicSettings.kyc_levels[customerCompany.kyc_level].max_invest_amount)}
                </>
              )
            }
            {" "}
            {baseCurrency}
          </div>
          <div className="transactions__col vert__middle">
            <div className="transactions__label">{t('Status')}</div>
            {customerCompany.kyc_status === CUSTOMER_STATUS_TYPES.KYC_LVL_CHANGE ? (
              <>
                {changeStatusKycLevelStatus.loading && (
                  <div className="media__btns">
                    <CircularProgress />
                  </div>
                )}
                {!changeStatusKycLevelStatus.loading && me && hasUserEnoughRights(
                    me.rights,
                    USER_RIGHT_TYPES.COMPLIANCE_OFFICER
                  ) && (
                  <div className="media__btns">
                    <div
                      className="status-green-dark clickable"
                      style={{ marginRight: "4px", marginBottom: '4px' }}
                      onClick={() =>
                        changeKycLvl(CUSTOMER_STATUS_TYPES.APPROVED, customerCompany.id)
                      }
                    >
                      { t('To be Approved') }
                    </div>
                    {' '}
                    <div
                      className="status-red-dark clickable"
                      style={{ marginBottom: '4px' }}
                      onClick={() =>
                        changeKycLvl(CUSTOMER_STATUS_TYPES.REJECTED, customerCompany.id)
                      }
                    >
                      { t('To be Denied') }
                    </div>
                  </div>
                )}
              </>
            ) : (
              renderKycStatus(customerCompany.kyc_status)
            )}
          </div>
        </div>
      );
    }
    if (
      customerType === CUSTOMER_TYPES.INDIVIDUAL &&
      typeof object === "object"
    ) {
      const customer = object;
      return (
        <div key={customer.id} className="transactions__row">
          <div className="transactions__col products__col-first-clear">
            <div className="transactions__label">{t('Date')}</div>
            {new Date(customer.created_at).toDateString()}
            <br />
            {moment(customer.created_at).format("h:mm:ss a")}
          </div>

          <div className="transactions__col vert__middle">
            <div className="transactions__label">{t('Level')}</div>
            {customer.kyc_level}
          </div>

          <div className="transactions__col vert__middle">
            <div className="transactions__label">{t('Amount in CHF')}</div>
            {
              publicSettings.kyc_levels[customer.kyc_level].enable &&
              publicSettings.kyc_levels[customer.kyc_level].enable.enable_open_end
                ? (userKyc.exactIntendedInvestmentAmount
                  ? `${numberWithCommas(publicSettings.kyc_levels[customer.kyc_level].min_invest_amount)} - ${numberWithCommas(userKyc.exactIntendedInvestmentAmount)}`
                  : `> ${numberWithCommas(publicSettings.kyc_levels[customer.kyc_level].min_invest_amount)}`
                )
                : (
                  <>
                    {numberWithCommas(publicSettings.kyc_levels[customer.kyc_level].min_invest_amount)}
                    {' - '}
                    {numberWithCommas(publicSettings.kyc_levels[customer.kyc_level].max_invest_amount)}
                  </>
                )
            }
            {' '}
            {baseCurrency}
          </div>

          <div className="transactions__col vert__middle">
            <div className="transactions__label">{t('Status')}</div>
            {customer.kyc_status === CUSTOMER_STATUS_TYPES.KYC_LVL_CHANGE ? (
              <>
                {changeStatusKycLevelStatus.loading && (
                  <div className="media__btns">
                    <CircularProgress />
                  </div>
                )}
                {!changeStatusKycLevelStatus.loading && me && hasUserEnoughRights(
                    me.rights,
                    USER_RIGHT_TYPES.COMPLIANCE_OFFICER
                  ) && (
                  <div className="media__btns">
                    <div
                      className="status-green-dark clickable"
                      style={{ marginRight: "4px", marginBottom: '4px' }}
                      onClick={() =>
                        changeKycLvl(CUSTOMER_STATUS_TYPES.APPROVED, customer.id)
                      }
                    >
                      { t('To be Approved') }
                    </div>
                    {' '}
                    <div
                      className="status-red-dark clickable"
                      style={{ marginBottom: '4px' }}
                      onClick={() =>
                        changeKycLvl(CUSTOMER_STATUS_TYPES.REJECTED, customer.id)
                      }
                    >
                      { t('To be Denied') }
                    </div>
                  </div>
                )}
              </>
            ) : (
              renderKycStatus(customer.kyc_status)
            )}
          </div>
        </div>
      );
    }
    return false;
  };

  const renderKycLevelHistory = () => (
    <>
      <div className="transactions__wrapper">
        <div className="transactions__table kyc__table_transactions kyc__table_transactions_clear-col-3">
          <div className="transactions__row">
            <div className="transactions__col products__col-first-clear">
              { t('Date') }
            </div>

            <div className="transactions__col">
              { t('Level') }
            </div>

            <div className="transactions__col">
              { t('Amount in') } {publicSettings.kyc_use_base_currency ? publicSettings.base_currency : 'CHF'}
            </div>

            <div className="transactions__col">
              { t('Status') }
            </div>
          </div>

          {Object.keys(kycLvlHistoryUserAdmin).length > 0 &&
            kycLvlHistoryUserAdmin.objects.map(renderKycLevel)}
        </div>

        {me && hasUserEnoughRights(
            me.rights,
            USER_RIGHT_TYPES.COMPLIANCE_OFFICER
          ) && (
            <div className="add-kyc-level">
              <button
                className="button button-small clickable"
                onClick={onAddKycLevel}
                style={{marginLeft: '20px', marginTop: '10px'}}
                disabled={adminAddKycLevelStatus.loading}
              >
                {t('Add KYC Level')}
              </button>

              <button
                className="status-red-dark button button-small clickable"
                onClick={onRemoveKycLevel}
                style={{marginLeft: '10px', marginTop: '10px'}}
                disabled={adminRemoveKycLevelStatus.loading}
              >
                {t('Remove Latest KYC Level')}
              </button>
            </div>
          )
        }
      </div>
      <TablePaginationActions
        count={
          Object.keys(kycLvlHistoryUserAdmin).length > 0
            ? kycLvlHistoryUserAdmin.meta.totalPages
            : 0
        }
        page={pageKycLvlHistory}
        rowsPerPage={pageSizeKycLvlHistory}
        onChangePage={onChangePage}
        onChangeRows={onChangePageSize}
        rowsPerPageOptions={[1, 5, 10, 25]}
      />
    </>
  );

  const renderChangeVideoIdentBtn = () => {
    if (
      !user ||
      user.video_ident_status === USER_VIDEO_IDENT_STATUS.NOT_NEEDED
    ) {
      return false;
    }

    if (user.video_ident_status === USER_VIDEO_IDENT_STATUS.PENDING) {
      return (
        <>
          <div
            onClick={onChangeUserVideoIdent(
              user.id,
              USER_VIDEO_IDENT_STATUS.PASSED
            )}
          >
            <DoneIcon /> { t('Liveness check approve') }
          </div>
          <div
            onClick={onChangeUserVideoIdent(
              user.id,
              USER_VIDEO_IDENT_STATUS.DENIED
            )}
          >
            <ClearIcon /> { t('Liveness check deny') }
          </div>
        </>
      );
    }

    if (user.video_ident_status === USER_VIDEO_IDENT_STATUS.DENIED) {
      return (
        <div
          onClick={onChangeUserVideoIdent(
            user.id,
            USER_VIDEO_IDENT_STATUS.PASSED
          )}
        >
          <DoneIcon /> { t('Liveness check approve') }
        </div>
      );
    }

    return (
      user.video_ident_status === USER_VIDEO_IDENT_STATUS.PASSED && (
        <div
          onClick={onChangeUserVideoIdent(
            user.id,
            USER_VIDEO_IDENT_STATUS.DENIED
          )}
        >
          <ClearIcon /> { t('Liveness check deny') }
        </div>
      )
    );
  };

  const riskLabelHelper = (level) => {
    level = level || "";
    return (
      <>
        <br />
        <span className={`status ${level === 'Medium' ? "status-green-dark" : level === 'High' ? "status-red-dark" : level === 'Enhanced' ? "status-orange-dark" : ""}`}>
          {`${level}`} { t('risk') }
        </span>
      </>
    );
  };

  const riskLabelHelperForWallet = (level) => {
    level = level || "";
    return (
      <>
        <br />
        <span className={`status ${level === 'Low' || level === 'Medium' ? "status-green-dark" : level === 'High' ? "status-red-dark" : ""}`}>
          {`${level}`} { t('risk') }
        </span>
      </>
    );
  };

  const calcCoinfirmRiskLevel = (score) => {
    if (score < 33) {
      return 'Low'
    }

    if (score < 66) {
      return 'Medium'
    }

    return 'High'
  }

  const calcBitrankRiskLevel = (score) => {
    if (score > 40) {
      return 'Low'
    }

    if (score > 20) {
      return 'Medium'
    }

    return 'High'
  }

  const renderOnfidoReportsNav = (onfidoCheck, extend = false) => {
    if (
      onfidoCheck &&
      onfidoCheck.onfidoReports &&
      onfidoCheck.onfidoReports.length > 0
    ) {
      return (
        <>
          { extend ? (
            <button className="settings__link">
              { t('Onfido Reports') }
            </button>
          ) : (
            <button className="settings__link">
              { t('Extended Reports') }
            </button>
          )}
        </>
      )
    }
    return <></>;
  };

  const renderPersonalDetails = () => {
    const customeMainScheme = { ...mainScheme };
    customeMainScheme.intended_investment = {
      label: "Intended investment",
    };

    customeMainScheme.amount = {
      label: "Token amount",
    };

    if (publicSettings){
      if (publicSettings.token_type === TOKEN_TYPES.NONE &&
        publicSettings.require_exact_investment_amount_on_none
      ) {
        customeMainScheme.exactIntendedInvestmentAmount = {
          label: "Exact investment amount",
        };
      }

      if (publicSettings.token_type === TOKEN_TYPES.LIQUIDATION) {
        customeMainScheme.amount = {
          label: 'Token amount',
        }

        customeMainScheme.gdpr_opt_in = {
          label: 'GDPR opt-in',
        }
      }
    }

    const customerCompanyFields = {
      legalForm: {label: 'Legal Form'},
      legalFormOther: {label: 'Description of Legal Form'},
      dateOfIncorporation: {label: 'Date of Incorporation'},
      dateOfEntryInCommercialRegister: {label: 'Date of Entry in Commercial Register'},
      placeOfCommercialRegister: {label: 'Place of Commercial Register'},
      companyShareCapital: {label: 'Company Share Capital'},
      companyPlace: {label: 'Company Place'},
      companyCountry: {label: 'Company Country'},
      companyPostalCode: {label: 'Company Postalcode'},
      grossRevenue: {label: 'Gross Revenue'},
      investmentPurpose: {label: 'Investment Purpose'},
      sourceOfIncome: {label: 'Source of Income'},
      notRelatedSourceIncomeDescription: {label: 'Not Related Source Income'},
      descriptionOfFunds: {label: 'Description of Funds'},
      sourceOfFunds: {label: 'Source of Funds'},
      business_countries: {label: 'Are you doing business in any of these Countries?'},
      business_following_industries: {label: 'Is your Business operated in the following industries?'},
    }

    let scoreResponse;
    try {
      scoreResponse = JSON.parse(lastCheckEthAddress[0].response);
      if (!(scoreResponse instanceof Object)) {
        throw new Error("Not an object");
      }
    } catch (err) {
      scoreResponse = {};
    }

    let scoreResponseForOldWallet;
    try {
      scoreResponseForOldWallet = JSON.parse(lastCheckEthAddress[1] ? lastCheckEthAddress[1].response : '');
      if (!(scoreResponseForOldWallet instanceof Object)) {
        throw new Error("Not an object");
      }
    } catch (err) {
      scoreResponseForOldWallet = {};
    }

    let scoreSendingResponse;
    try {
      scoreSendingResponse = JSON.parse(lastCheckEthSendingAddress[0].response);
      if (!(scoreSendingResponse instanceof Object)) {
        throw new Error("Not an object");
      }
    } catch (err) {
      scoreSendingResponse = {};
    }

    let scoreSendingResponseForOldWallet;
    try {
      scoreSendingResponseForOldWallet = JSON.parse(lastCheckEthAddress[1] ? lastCheckEthAddress[1].response : '');
      if (!(scoreSendingResponseForOldWallet instanceof Object)) {
        throw new Error("Not an object");
      }
    } catch (err) {
      scoreSendingResponseForOldWallet = {};
    }

    const renderEditLink = () => {
      if (publicSettings.allow_compliance_update_kyc &&
        me && hasUserEnoughRights(
          me.rights,
          USER_RIGHT_TYPES.COMPLIANCE_OFFICER
        )) {
        return (
          <>
            <br />
            <NavLink
              to={`/admin/kyc-list/individual/${userKyc.id}/edit`}
              className="button-stroke button-small"
            >
              <Icon
                className={classNames('edit')}
                name="edit"
                size={24}
              />
              {t('Update information')}
            </NavLink>
          </>
        );
      }
      return null
    }

    return (
      <div className="card">
        <div className="card__head">
          <div className="title-clear card__title">
            { t('Personal Details') }
          </div>
        </div>

        <div className="earning__table earning__table-rwd">
          <div className="earning__row">
            <div className="earning__col">
              {' '}
            </div>

            <div className="earning__col">
              {t('Input data')}
            </div>

            <div className="earning__col">
              {t('Extracted data')}
            </div>
          </div>

          {customerType === CUSTOMER_TYPES.INDIVIDUAL && (
            <>
              <div
                className="earning__row"
                key={`source_of_funds_${userKyc.id}`}
              >
                <div className="earning__col">
                  { t('Primary source of funds (Dropdown)') }
                </div>

                <div className="earning__col">
                  <div className="earning__label">
                    {t('Input data')}
                  </div>

                  {userKyc.source_of_funds && { ...SOURCE_OF_FUNDS_STANDARD_SCHEMAS, ...SOURCE_OF_FUNDS_PUBLIC_SCHEMAS }[
                    userKyc.source_of_funds.toLowerCase()
                  ] &&
                  { ...SOURCE_OF_FUNDS_STANDARD_SCHEMAS, ...SOURCE_OF_FUNDS_PUBLIC_SCHEMAS }[
                      userKyc.source_of_funds.toLowerCase()
                    ].label
                  }
                  {userKyc.source_of_funds && calculatedRiskAssessment &&
                    calculatedRiskAssessment.source_of_funds &&
                    riskLabelHelper(
                      calculatedRiskAssessment.source_of_funds.level
                    )}
                  {renderEditLink()}
                </div>

                <div className="earning__col">
                  <div className="earning__label">
                    {t('Extracted data')}
                  </div>
                  -
                </div>

              </div>

              {userKyc.source_of_funds_other && (
                <div
                  key={`source_of_funds_other_${userKyc.id}`}
                  className="earning__row"
                >
                  <div className="earning__col">
                    { t('Primary source of funds other') }
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>

                    {userKyc.source_of_funds_other}
                    {userKyc.source_of_funds_other_t && userKyc.source_of_funds_other !== userKyc.source_of_funds_other_t && (
                      <p>EN: {userKyc.source_of_funds_other_t}</p>
                    )}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    -
                  </div>
                </div>
              )}

              <div
                key={`source_of_wealth_${userKyc.id}`}
                className="earning__row"
              >
                <div className="earning__col">
                  { t('Primary source of wealth (Dropdown)') }
                </div>

                <div className="earning__col">
                  <div className="earning__label">
                    {t('Input data')}
                  </div>
                  {userKyc.source_of_wealth && { ...SOURCE_OF_WEALTH_STANDARD_SCHEMAS, ...SOURCE_OF_WEALTH_PUBLIC_SCHEMAS }[
                    userKyc.source_of_wealth.toLowerCase()
                  ] &&
                  { ...SOURCE_OF_WEALTH_STANDARD_SCHEMAS, ...SOURCE_OF_WEALTH_PUBLIC_SCHEMAS }[
                      userKyc.source_of_wealth.toLowerCase()
                    ].label
                  }
                  {userKyc.source_of_wealth && calculatedRiskAssessment &&
                    calculatedRiskAssessment.source_of_wealth &&
                    riskLabelHelper(
                      calculatedRiskAssessment.source_of_wealth.level
                    )}
                  {renderEditLink()}
                </div>

                <div className="earning__col">
                  <div className="earning__label">{t('Extracted data')}</div>
                  -
                </div>
              </div>

              {userKyc.source_of_wealth_other && (
                <div
                  key={`source_of_wealth_other_${userKyc.id}`}
                  className="earning__row"
                >
                  <div className="earning__col">
                    { t('Primary source of wealth other') }
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>

                    {userKyc.source_of_wealth_other}
                    {userKyc.source_of_wealth_other_t && userKyc.source_of_wealth_other !== userKyc.source_of_wealth_other_t && (
                      <p>EN: {userKyc.source_of_wealth_other_t}</p>
                    )}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    -
                  </div>
                </div>
              )}
            </>
          )}

          <div
            key={`eth_address_${userKyc.id}`}
            className="earning__row"
          >
            <div className="earning__col">
              {userKyc && userKyc.wallet} { t('Receiving Address') }
            </div>

            <div className="earning__col">
              <div className="earning__label">
                {t('Input data')}
              </div>
              {
                userKyc.tokenAddress && userKyc.verified_wallet === userKyc.tokenAddress.toLowerCase() ?
                <>
                  <span className={classes.textGreen}>{userKyc.tokenAddress}</span>
                  <p>
                    {t('This wallet has been verified through a')}
                    {' '}
                    <a
                      href="https://docs.metamask.io/guide/signing-data.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('Wallet Signature')}
                    </a>
                  </p>
                </> : <ShortenText text={userKyc.tokenAddress} v2 />
              }

              {lastCheckEthAddress !== null &&
                Object.keys(lastCheckEthAddress).length > 0 && (
                  <>
                    <br />
                    { t('Bitrank Score') }:
                    {' '}
                    {scoreResponse.score}
                    {
                      scoreResponse.score >= 0 && riskLabelHelperForWallet(
                        calcBitrankRiskLevel(scoreResponse.score)
                      )
                    }
                    <br />
                    { t('JSON Response')}:
                    <ReactJson
                      src={scoreResponse}
                      theme='pop'
                      collapsed={true}
                      displayDataTypes={false}
                      displayObjectSize={false}
                      displayArrayKey={false}
                    />
                  </>
                )
              }
              {userKyc.user.receivingCoinfirmReport && (
                <>
                  <br />
                  { t('Coinfirm Score') }: {userKyc.user.receivingCoinfirmReport.score}
                  {
                    userKyc.user.receivingCoinfirmReport.score >= 0 && riskLabelHelperForWallet(
                      calcCoinfirmRiskLevel(userKyc.user.receivingCoinfirmReport.score)
                    )
                  }
                  <br />
                  { userKyc.user.receivingCoinfirmReport.reportType === 'enhanced' ? 'Enhanced ' : ''}
                  { t('JSON Response')}:
                  <ReactJson
                    src={userKyc.user.receivingCoinfirmReport.response}
                    theme='pop'
                    collapsed={true}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    displayArrayKey={false}
                  />
                  { userKyc.user.receivingCoinfirmReport.reportType !== 'enhanced' &&
                    <button
                      className="button button-small"
                      style={{marginLeft: "4px"}}
                      disabled={generateCoinfirmEnhancedReportStatus.loading}
                      onClick={() => onGenerateCoinfirmEnhancedReport(userKyc, customerType, userKyc.tokenAddress)}
                    >
                      {t('Generate Enhanced Report')}
                    </button>
                  }
                </>
              )}
            </div>

            <div className="earning__col">
              <div className="earning__label">{t('Extracted data')}</div>
              -
            </div>
          </div>

          {userKyc.user.old_wallet_address && (
            <div
              key={`old_eth_address_${userKyc.id}`}
              className="earning__row"
            >
              <div className="earning__col">
                { t('Old Receiving Address') }
              </div>

              <div className="earning__col">
                <div className="earning__label">
                  {t('Input data')}
                </div>
                { userKyc.user.old_wallet_address }

                {lastCheckEthAddress !== null &&
                  scoreResponseForOldWallet.score && (
                    <>
                      <br />
                      { t('Bitrank Score') }:
                      {' '}
                      {scoreResponseForOldWallet.score}
                      {
                        scoreResponseForOldWallet.score >= 0 && riskLabelHelperForWallet(
                          calcBitrankRiskLevel(scoreResponseForOldWallet.score)
                        )
                      }
                      <br />
                      { t('JSON Response')}:
                      <ReactJson
                        src={scoreResponseForOldWallet}
                        theme='pop'
                        collapsed={true}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        displayArrayKey={false}
                      />
                    </>
                  )
                }

                {userKyc.user.oldReceivingCoinfirmReport && (
                  <>
                    <br />
                    { t('Coinfirm Score') }: {userKyc.user.oldReceivingCoinfirmReport.score}
                    {
                      userKyc.user.oldReceivingCoinfirmReport.score >= 0 && riskLabelHelperForWallet(
                        calcCoinfirmRiskLevel(userKyc.user.oldReceivingCoinfirmReport.score)
                      )
                    }
                    <br />
                    { userKyc.user.oldReceivingCoinfirmReport.reportType === 'enhanced' ? 'Enhanced ' : ''}
                    { t('JSON Response')}:
                    <ReactJson
                      src={userKyc.user.oldReceivingCoinfirmReport.response}
                      theme='pop'
                      collapsed={true}
                      displayDataTypes={false}
                      displayObjectSize={false}
                      displayArrayKey={false}
                    />
                  </>
                )}
              </div>

              <div className="earning__col">
                <div className="earning__label">{t('Extracted data')}</div>
                -
              </div>
            </div>
          )}

          <div
            key={`eth_sending_address_${userKyc.id}`}
            className="earning__row"
          >
            <div className="earning__col">
              {userKyc && userKyc.sending_wallet} { t('Sending Address') }
            </div>

            <div className="earning__col">
              <div className="earning__label">
                {t('Input data')}
              </div>

              <ShortenText text={userKyc.tokenSendingAddress} v2 />
              {lastCheckEthSendingAddress !== null &&
                Object.keys(lastCheckEthSendingAddress).length > 0 && (
                  <>
                    <br />
                    { t('Bitrank Score') }: {scoreSendingResponse.score}
                    <PopoverResponse jsonResponse={scoreSendingResponse} />
                  </>
                )
              }
              {userKyc.user.sendingCoinfirmReport && (
                <>
                  <br />
                  { t('Coinfirm Score') }: {userKyc.user.sendingCoinfirmReport.score}
                  <PopoverResponse jsonResponse={userKyc.user.sendingCoinfirmReport.response} />
                </>
              )}
            </div>

            <div className="earning__col">
              <div className="earning__label">{t('Extracted data')}</div>
              -
            </div>
          </div>

          {Object.keys(customeMainScheme).map((key) => {
            const { label } = customeMainScheme[key];
            const value = userKyc[key];

            if (key === "firstName") {
              return (
                <div key="firstName" className="earning__row">
                  <div className="earning__col">
                    { t('First name') }
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>
                    {userKyc.first_name}{" "}
                    {isHasVideoIdentId ? (
                      isValidFirstName ? (
                        <CheckIcon />
                      ) : (
                        <CloseIcon />
                      )
                    ) : null}
                    {renderEditLink()}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    {isExtractEnabled && extractedData && extractedData.primaryName}
                  </div>
                </div>
              );
            }

            if (key === "middleName") {
              return (
                <div key="middleName" className="earning__row">
                  <div className="earning__col">
                    { t('Middle name') }
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>
                    {userKyc.middle_name}{" "}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                  </div>
                </div>
              );
            }

            if (key === "lastName") {
              return (
                <div
                  key="lastName"
                  className="earning__row"
                >
                  <div className="earning__col earning__col-sm">
                    {t('Last name')}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>
                    {userKyc.last_name}{" "}
                    {isHasVideoIdentId ? (
                      isValidLastName ? (
                        <CheckIcon />
                      ) : (
                        <CloseIcon />
                      )
                    ) : null}
                    {renderEditLink()}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    {isExtractEnabled && extractedData && extractedData.secondaryName}
                  </div>
                </div>
              );
            }

            if (key === "uniqueId") {
              return (
                <div
                  key="uniqueId"
                  className="earning__row"
                >
                  <div className="earning__col earning__col-sm">
                    {label}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>
                    <ShortenText v2 text={value} />

                    {renderEditLink()}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    {isExtractEnabled && extractedData && extractedData.passportNumber}
                  </div>
                </div>
              );
            }

            if (key === "birthDate") {
              return (
                <div
                  key="birthDate"
                  className="earning__row"
                >
                  <div className="earning__col">
                    {label}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>
                    {value}
                    {renderEditLink()}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    {isExtractEnabled && extractedData && extractedData.birthDateTS}
                  </div>
                </div>
              );
            }

            if (key === "what_will_you_use") {
              return (
                <div
                  key={key}
                  className="earning__row"
                >
                  <div className="earning__col earning__col-sm">
                    {t('What is the purpose of your token purchase?')}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>
                    {value}
                    {renderEditLink()}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    -
                  </div>
                </div>
              );
            }

            if (key === "intended_investment") {
              return (
                <div
                  key={key}
                  className="earning__row"
                >
                  <div className="earning__col">
                    {label}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>
                    {value ? value.replace(/\d+/g, (n) => numberWithCommas(n)) : 'NOT PROVIDED'}
                    {calculatedRiskAssessment &&
                      calculatedRiskAssessment.size_of_transaction &&
                      riskLabelHelper(
                        calculatedRiskAssessment.size_of_transaction.level
                      )
                    }
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    -
                  </div>
                </div>
              );
            }

            if (key === 'phoneNumber') {
              return (
                <div
                  key={key}
                  className="earning__row"
                >
                  <div className="earning__col">
                    {label}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>
                    <ShortenText v2 text={value} />
                    {renderEditLink()}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    -
                  </div>
                </div>
              )
            }

            if (key === 'education_level') {
              return (
                <div
                  key={key}
                  className="earning__row"
                >
                  <div className="earning__col earning__col-sm">
                    {label}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>
                    {value}
                    {renderEditLink()}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    -
                  </div>
                </div>
              )
            }

            if (key === 'yearly_salary') {
              return (
                <div
                  key={key}
                  className="earning__row"
                >
                  <div className="earning__col">
                    {label}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>
                    {value}
                    {renderEditLink()}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    -
                  </div>
                </div>
              )
            }

            if (key === 'occupation') {
              return (
                <div
                  key={key}
                  className="earning__row"
                >
                  <div className="earning__col">
                    {label}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>
                    {value}
                    {renderEditLink()}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    -
                  </div>
                </div>
              )
            }

            if (key === 'industry') {
              if (publicSettings.kyc_require_industry) {
                return (
                  <div
                    key={key}
                    className="earning__row"
                  >
                    <div className="earning__col">
                      {label}
                    </div>

                    <div className="earning__col">
                      <div className="earning__label">
                        {t('Input data')}
                      </div>
                      {value}
                      {renderEditLink()}
                    </div>

                    <div className="earning__col">
                      <div className="earning__label">{t('Extracted data')}</div>
                      -
                    </div>
                  </div>
                )
              } else {
                return null
              }
            }

            if (key === 'industryOther') {
              if (!publicSettings.kyc_require_industry || !value) {
                return null;
              }
            }

            if (key === 'employer_company_name') {
              return (
                <div
                  key={key}
                  className="earning__row"
                >
                  <div className="earning__col">
                    {label}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>
                    {value}
                    {renderEditLink()}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    -
                  </div>
                </div>
              )
            }

            if (key === 'net_investable_assets') {
              return (
                <div
                  key={key}
                  className="earning__row"
                >
                  <div className="earning__col">
                    {label}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>
                    {value}
                    {renderEditLink()}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    -
                  </div>
                </div>
              )
            }

            if (key === 'exactIntendedInvestmentAmount') {
              return (
                <div
                  key={key}
                  className="earning__row"
                >
                  <div className="earning__col">
                    {label}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>
                    {value && (
                      <>
                        {numberWithCommas(value)}
                        {' '}
                        {publicSettings.kyc_use_base_currency ? publicSettings.base_currency.toUpperCase() : 'CHF'}
                      </>
                    )}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    -
                  </div>
                </div>
              )
            }

            if (key === 'gdpr_opt_in') {
              return (
                <div
                  key={key}
                  className="earning__row"
                >
                  <div className="earning__col">
                    {label}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>
                    {userKyc.liquidation_upload_email ? 'Yes' : 'No'}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    -
                  </div>
                </div>
              );
            }

            if (key === 'descriptionOfFunds') {
              return (
                <div
                  key={key}
                  className="earning__row"
                >
                  <div className="earning__col">
                    {label}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>

                    {userKyc.descriptionOfFunds}
                    {userKyc.descriptionOfFunds_t && userKyc.descriptionOfFunds !== userKyc.descriptionOfFunds_t && (
                      <p>EN: {userKyc.descriptionOfFunds_t}</p>
                    )}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    -
                  </div>
                </div>
              );
            }

            if (key === 'descriptionOfWealth') {
              return (
                <div
                  key={key}
                  className="earning__row"
                >
                  <div className="earning__col">
                    {label}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>

                    {userKyc.descriptionOfWealth}
                    {userKyc.descriptionOfWealth_t && userKyc.descriptionOfWealth !== userKyc.descriptionOfWealth_t && (
                      <p>EN: {userKyc.descriptionOfWealth_t}</p>
                    )}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    -
                  </div>
                </div>
              );
            }

            if (key === 'sourceOfFunds') {
              return (
                <div
                  key={key}
                  className="earning__row"
                >
                  <div className="earning__col">
                    {label}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>
                    {userKyc.sourceOfFunds}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    -
                  </div>
                </div>
              );
            }

            if (key === 'amount') {
              return (
                <div
                  key={key}
                  className="earning__row"
                >
                  <div className="earning__col">
                    {label}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>
                    {value && (
                      <>
                        {numberWithCommas(+value)}
                      </>
                    )}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                    -
                  </div>
                </div>
              )
            }

            if (key === 'personType') {
              return (
                <div
                  key={key}
                  className="earning__row"
                >
                  <div className="earning__col">
                    {label}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Input data')}
                    </div>
                    {userKyc.personType}
                  </div>

                  <div className="earning__col">
                    <div className="earning__label">{t('Extracted data')}</div>
                      {isExtractEnabled && extractedData && extractedData.pepAndSanctions}
                  </div>
                </div>
              );
            }

            return (
              <div
                key={key}
                className="earning__row"
              >
                <div className="earning__col">
                  {label}
                </div>

                <div className="earning__col">
                  <div className="earning__label">
                    {t('Input data')}
                  </div>

                  {value}
                  {userKyc[`${key}_t`] && value !== userKyc[`${key}_t`] && (
                    <p>EN: {userKyc[`${key}_t`]}</p>
                  )}
                </div>

                <div className="earning__col">
                  <div className="earning__label">{t('Extracted data')}</div>
                  {userKyc.kompanyData && userKyc.kompanyData[key] ? userKyc.kompanyData[key] : '-'}
                </div>
              </div>
            );
          })}

          {customerType === CUSTOMER_TYPES.COMPANY &&
            Object.keys(customerCompanyFields).map((key) => (
              <div
                key={key}
                className="earning__row"
              >
                <div className="earning__col">
                  {customerCompanyFields[key].label}
                </div>

                <div className="earning__col">
                  <div className="earning__label">
                    {t('Input data')}
                  </div>
                  {userKyc[key]}
                </div>

                <div className="earning__col">
                  <div className="earning__label">{t('Extracted data')}</div>
                  {userKyc.kompanyData && userKyc.kompanyData[key] ? userKyc.kompanyData[key] : '-'}
                </div>
              </div>
            ))
          }

          {customerType === CUSTOMER_TYPES.COMPANY &&
            <div
              key='sic_code'
              className="earning__row"
            >
              <div className="earning__col">
                {t('SIC code')}
              </div>

              <div className="earning__col">
                <div className="earning__label">
                  {t('Input data')}
                </div>
                <ShortenText v2 text={userKyc.sic_code} />
              </div>

              <div className="earning__col">
                <div className="earning__label">{t('Extracted data')}</div>
                -
              </div>
            </div>
          }

          {publicSettings.token_type === TOKEN_TYPES.LIQUIDATION && customerType === CUSTOMER_TYPES.INDIVIDUAL && (
            <div
              className="earning__row"
            >
              <div className="earning__col">
                Creditor Number
              </div>

              <div className="earning__col">
                <div className="earning__label">
                  {t('Input data')}
                </div>

                {userKyc.creditor_number && (
                  <div>{userKyc.creditor_number}</div>
                )}
                {!userKyc.creditor_number && (
                  <button className="button" onClick={() => setIsCreditorNumberDialogOpen(true)}>
                    Add creditor number and send sign invitation
                  </button>
                )}

                <Dialog
                  open={isCreditorNumberDialogOpen}
                  onClose={() => {
                    setIsCreditorNumberDialogOpen(false)
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                >
                  <ModalInnerMaterialDialog
                    onClickClose={() => setIsCreditorNumberDialogOpen(false)}
                    modalTitle={t('Update creditor number')}
                    modalTitleClassColor="title-primary"
                  >
                    <div className="settings__fieldset">
                      <div className="field">
                        <div className="field__label">
                          {t('Creditor Number')}
                          <span className="text__red"> *</span>
                        </div>
                        <div className="field__wrap field__wrap-textarea">
                          <InputThemeField
                            id="outlined-name"
                            classWrapper="field"
                            classLabel="field__label"
                            classInput="field__input"
                            value={creditorNumber}
                            onChange={({ target: { value }}) => setCreditorNumber(value)}
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="field">
                        {updateCreditorNumberStatus.loading && (
                          <CircularProgress />
                        )}
                        {!updateCreditorNumberStatus.loading && (
                          <button
                            className="button"
                            onClick={() => updateCreditorNumberAndInviteToSign()}
                          >
                            {t('Update and send sign invitation')}
                          </button>
                        )}
                      </div>
                    </div>
                  </ModalInnerMaterialDialog>
                </Dialog>
              </div>

              <div className="earning__col">
                <div className="earning__col">{t('Extracted data')}</div>
                -
              </div>
            </div>
          )}
        </div>

        {customerType === CUSTOMER_TYPES.COMPANY && (
          <>
            <a
              className="button"
              href={`/admin/kyc-list/company/${userKyc.id}/compare`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Request data comparison')}
            </a>
          </>
        )}

        {publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION && userKyc.is_token_swap && (
          <>
            <br />
            {triggerTokenSwapSignnowStatus.loading && (
              <CircularProgress />
            )}
            {!triggerTokenSwapSignnowStatus.loading && (
              <button className="button" onClick={onTriggerTokenSwapSignnow}>
                {t('Trigger Signnow Token Swap')}
              </button>
            )}
          </>
        )}

        {isQdev && (
          <>
            <br />
            {triggerTokenSaleSignnowStatus.loading && (
              <CircularProgress />
            )}
            {!triggerTokenSaleSignnowStatus.loading &&
              me && hasUserEnoughRights(
                me.rights,
                USER_RIGHT_TYPES.COMPLIANCE_OFFICER
              ) && (
              <button className="button" onClick={onTriggerTokenSaleSignnow}>
                Trigger Signnow Token Sale Agreement
              </button>
            )}
          </>
        )}

        {userKyc.liquidation_upload_email_file && (
          <>
            <br />
            <div className="settings__fieldset">
              <div className="field__label">
                {t('Liquidation upgrade email')}
              </div>
              <AdminUploadPreview upload={userKyc.liquidation_upload_email_file} />
            </div>
          </>
        )}
      </div>
    );
  };

  const UploadPreview = ({ upload, ...rest }) => {
    if (upload.preview_storage_key) {
      return (
        <div className="summary__preview_aspect_ratio">
          <img alt="" src={getExternalFileUrl(upload.preview_storage_key)} {...rest} />
        </div>
      );
    }

    if (getOriginalFileExtension(upload.storage_key) === "pdf") {
      return (
        <div className="summary__preview_aspect_ratio">
          <embed
            src={getExternalFileUrl(upload.storage_key)}
            width="500"
            height="375"
            type="application/pdf"
            {...rest}
          />
        </div>

      );
    }

    return (
      <div className="summary__preview_aspect_ratio">
        <img alt="" src={getExternalFileUrl(upload.storage_key)} {...rest} />
      </div>
    )
  };

  const getUploadUrl = (upload) =>
    `${getExternalFileUrl(upload.storage_key)}`;

  const calculateOverallRiskAssessment = () => {
    let accumulateScore = 0;
    const totalParam = Object.keys(calculatedRiskAssessment).length;
    let isOneParamReachFive = false;
    Object.keys(calculatedRiskAssessment).forEach((param) => {
      let score = calculatedRiskAssessment[`${param}`].score || 0;
      if (score === 5) {
        isOneParamReachFive = true;
      }
      accumulateScore += score;
    });

    if (isOneParamReachFive) {
      return "High (5)";
    }

    let result = Math.round(accumulateScore / totalParam);

    if (result >= 0 && result <= 1) {
      return `Low (${result})`;
    } else if (result > 1 && result <= 2) {
      return `Medium (${result})`;
    } else if (result > 2 && result <= 3.5) {
      return `Enhanced (${result})`;
    } else if (result > 3.5 && result <= 5) {
      return `High (${result})`;
    }
    return '';
  };

  const companyDocumentFields = {
    proofOfResidenceUpload: {
      label: 'Proof of Residence',
      settingField: 'kyb_require_utility_bill',
    },
    commercialRegisterExtract: {
      label: 'Commercial Register Extract',
    },
    corporationOrganizationChart: {
      label: 'Corporation Organization Chart',
      settingField: 'kyb_require_organization_chart',
    },
    articlesOfAssociation: {
      label: 'Articles of Association',
      settingField: 'kyb_require_association_article',
    },
    lastBalanceSheet: {
      label: 'Last Balance Sheet',
      settingField: 'kyb_require_last_balance_sheet',
    },
    certificateOfGoodStanding: {
      label: 'Certificate of Good Standing',
      settingField: 'kyb_show_good_standing_certificate',
    },
    shareholderList: {
      label: 'Shareholder List',
      settingField: 'kyb_require_shareholder_list',
    },
    supportedSourceOfFundUploads: {
      label: 'Supported Source of Fund',
    },
  };

  const proofOfResidenceDocument =
    userKyc && userKyc.impira_documents ?
      userKyc.impira_documents.find(doc => doc.status === 'COMPLETED' && doc.document_type === 'PROOF_OF_RESIDENCE') :
      null

  const customeGetUploadUrl = (upload) =>
    `${getExternalFileUrl(upload.storage_key)}`;

  const renderDirectorStatus = (status) => {
    switch (status) {
    case 'VIDEO_IDENT':
      return 'LIVENESS_CHECK_AWAITING';
    default:
      return status;
    }
  }

  const sendRequestConfirmSpotCheckCompleted = () => {
    confirmSpotCheckCompleted({
      variables: {
        id: userKyc.spotCheckRequest.id
      }
    }).then(() => {
      refetch()
    }).catch(() => {})
  }

  const isCompany = CUSTOMER_TYPES.COMPANY;

  const drawerNav = [
    {
      icon: (
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3" />
        </svg>
      ),
      title: t('Cockpit')
    },
    {
      icon: (
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
        </svg>
      ),
      title: t('Reports & Data')
    },
    {
      icon: (
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z" />
        </svg>
      ),
      title: t('All Risks Level')
    },
    {
      icon: (
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
        </svg>
      ),
      title: t('Transaction(s)')
    },
    ...(customerType === isCompany ? [
      {
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="icon">
            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
          </svg>
        ),
        title: t('Company document')
      },
      {
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="icon">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
          </svg>
        ),
        title: t('Directors')
      },
      {
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="icon">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
          </svg>
        ),
        title: t('UBOs')
      },
    ] : [
      {
        icon: (
          <svg className="icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
        ),
        title: t('Watchlist Result')
      },
      {
        icon: (
          <svg className="icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
          </svg>
        ),
        title: t('Facematch Result')
      },
    ])
  ];

  const [activeDrawerNav, setActiveDrawerNav] = useState(drawerNav[0].title);
  const [activeDrawerIndex, setActiveDrawerIndex] = useState(0);

  const handleDrawerNavClick = (x, index) => {
    setActiveDrawerIndex(index);
    setActiveDrawerNav(x);
  }

  const [visibleDrawerRWD, setVisibleDrawerRWD] = useState(false)

  const handleDrawerRWDClick = (x, index) => {
    setActiveDrawerIndex(index);
    setActiveDrawerNav(x);
    setVisibleDrawerRWD(false);
  }

  const renderDrawerTabWallet = () => {
    return (
      <>
        {customerType === CUSTOMER_TYPES.INDIVIDUAL && publicSettings && !publicSettings.kyc_skip_legal_disclaimer && (
          <div className="card">
            <div className="card__head">
              <div className="title-clear card__title">
                {t('Declarations relevant to due diligence')}
              </div>
            </div>

            <DisclaimersKycIndividual
              publicSettings={publicSettings}
              classes={classes}
              customer={userKyc}
            />

            { isAwearetech &&
              <>
                <br/>
                <div className="card__head">
                  <div className="title-clear card__title">
                    {t('Declaration of Domicile')}
                  </div>
                </div>

                <div className="settings__fieldset">
                  <ul className="login__list legaldisclaimers__list">
                    <li>
                      Due to United States of America export control and SEC regulations, this offering is not available for those with jurisdiction and/or domicile in the United States, Iran, Russia, North Korea, Belarus, Syria, and any territories thereof. If any prospective investor with jurisdiction and/or domicile in these nations or territories make an incorrect or false answer regarding jurisdiction and/or domicile outside of these nations or territories, that prospective investor understands that making incorrect/false statements may be considered felony offences including but not limited to perjury, wire fraud, and making false statements, depending on their specific local laws and circumstances.
                      <br/><br/>
                      The undersigned hereby declares that they are a bona fide resident of the country of {userKyc.countryOfResidence} and reside in and maintain at least one place of abode in the Domiciled Country which they recognize and intend to maintain as their permanent home. If maintaining one or more other places of abode outside of the Domiciled Country, the undersigned also declares that the Domiciled Country constitutes their predominant and principal home.
                      <br/><br/>
                      The undersigned affirms and attests to the fact that they are not domiciled in the United States of America, Russia, Iran, North Korea, Belarus, Syria, or any territories thereof.
                      <br/><br/>
                      The undersigned hereby declares that they will inform AWEARE Global AG administration through electronic means: admin@awearetech.li if at any time they intend to change their Domiciled Country to the United States, Russia, Iran, North Korea, Belarus, Syria, or any territories thereof for the express purpose of reviewing held securities as subject to repurchase.
                      <br/><br/>
                      I further certify that I understand there is a penalty for perjury; and that perjury is a felony and is punishable by fines and/or incarceration in accordance with national and/or state laws.
                    </li>
                  </ul>
                </div>

                <br/>
                <div className="card__head">
                  <div className="title-clear card__title">
                    {t('Applicable Law')}
                  </div>
                </div>

                <div className="settings__fieldset">
                  <ul className="login__list legaldisclaimers__list">
                    <li>
                      The offer and the agreement for the purchase of AWEARE Global Tokens as well as all rights and obligations arising in connection with or from the offer and the agreement for the purchase of AWEARE Global Tokens shall be governed exclusively by Liechtenstein law, excluding the conflict of law rules and referral provisions.
                    </li>
                  </ul>
                </div>

                <br/>
                <div className="card__head">
                  <div className="title-clear card__title">
                    {t('Place of Jurisdiction')}
                  </div>
                </div>

                <div className="settings__fieldset">
                  <ul className="login__list legaldisclaimers__list">
                    <li>
                      The courts of the Principality of Liechtenstein shall have exclusive jurisdiction for all disputes arising out of or in connection with the offer or the agreement for the purchase of AWEARE Global Tokens, including those concerning the valid conclusion, legal validity, amendment or cancellation.
                    </li>
                  </ul>
                </div>

                <br/>
                <div className="card__head">
                  <div className="title-clear card__title">
                    {t('Severability')}
                  </div>
                </div>

                <div className="settings__fieldset">
                  <ul className="login__list legaldisclaimers__list">
                    <li>
                      If at any time one or more of the provisions of the AWEARE Global Token Offer and the agreement for its purchase is or becomes unlawful, invalid, illegal or unenforceable in any respect, the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired thereby.
                    </li>
                  </ul>
                </div>
              </>
            }
          </div>
        )}

        {customerType === CUSTOMER_TYPES.COMPANY && (
          <>
            <div className="card">
              <MaterialAccordion className="card card-accordion" defaultExpanded={true}>
                <MaterialAccordionSummary
                className="card__head card-accordion__head"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="authorized_signatories-card-content"
                id="authorized_signatories-card-head"
                >
                  <div className="title-primary card__title">
                    { t('Authorized signatories') }
                  </div>
                </MaterialAccordionSummary>

                <MaterialAccordionDetails className="card-accordion__content is-full__content is-fix__space">
                  <KybDirectorStatus directorList={userKyc.customerCompanyDirector} isAdmin={true} />
                </MaterialAccordionDetails>
              </MaterialAccordion>
              <MaterialAccordion className="card card-accordion" defaultExpanded={true}>
                <MaterialAccordionSummary
                  className="card__head card-accordion__head"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="ubo-card-content"
                  id="ubo-card-head"
                >
                  <div className="title-primary card__title">
                    { t('UBOs') }
                  </div>
                </MaterialAccordionSummary>

                <MaterialAccordionDetails className="card-accordion__content is-full__content is-fix__space">
                  <KybUboStatus uboList={userKyc.customerCompanyUbo} />
                </MaterialAccordionDetails>

              </MaterialAccordion>
            </div>

            <div className="card">
              <div className="card__head">
                <div className="title-clear card__title">
                  {t('Declarations relevant to due diligence')}
                </div>
              </div>

              <DisclaimersKycIndividual
                publicSettings={publicSettings}
                classes={classes}
                customer={userKyc}
              />

              { isAwearetech &&
                <>
                  <br/>
                  <div className="card__head">
                    <div className="title-clear card__title">
                      {t('Declaration of Domicile')}
                    </div>
                  </div>

                  <div className="settings__fieldset">
                    <ul className="login__list legaldisclaimers__list">
                      <li>
                        Due to United States of America export control and SEC regulations, this offering is not available for those with jurisdiction and/or domicile in the United States, Iran, Russia, North Korea, Belarus, Syria, and any territories thereof. If any prospective investor with jurisdiction and/or domicile in these nations or territories make an incorrect or false answer regarding jurisdiction and/or domicile outside of these nations or territories, that prospective investor understands that making incorrect/false statements may be considered felony offences including but not limited to perjury, wire fraud, and making false statements, depending on their specific local laws and circumstances.
                        <br/><br/>
                        The undersigned hereby declares that they are a bona fide resident of the country of {userKyc.companyCountry} and reside in and maintain at least one place of abode in the Domiciled Country which they recognize and intend to maintain as their permanent home. If maintaining one or more other places of abode outside of the Domiciled Country, the undersigned also declares that the Domiciled Country constitutes their predominant and principal home.
                        <br/><br/>
                        The undersigned affirms and attests to the fact that they are not domiciled in the United States of America, Russia, Iran, North Korea, Belarus, Syria, or any territories thereof.
                        <br/><br/>
                        The undersigned hereby declares that they will inform AWEARE Global AG administration through electronic means: admin@awearetech.li if at any time they intend to change their Domiciled Country to the United States, Russia, Iran, North Korea, Belarus, Syria, or any territories thereof for the express purpose of reviewing held securities as subject to repurchase.
                        <br/><br/>
                        I further certify that I understand there is a penalty for perjury; and that perjury is a felony and is punishable by fines and/or incarceration in accordance with national and/or state laws.
                      </li>
                    </ul>
                  </div>

                  <br/>
                <div className="card__head">
                  <div className="title-clear card__title">
                    {t('Applicable Law')}
                  </div>
                </div>

                <div className="settings__fieldset">
                  <ul className="login__list legaldisclaimers__list">
                    <li>
                      The offer and the agreement for the purchase of AWEARE Global Tokens as well as all rights and obligations arising in connection with or from the offer and the agreement for the purchase of AWEARE Global Tokens shall be governed exclusively by Liechtenstein law, excluding the conflict of law rules and referral provisions.
                    </li>
                  </ul>
                </div>

                <br/>
                <div className="card__head">
                  <div className="title-clear card__title">
                    {t('Place of Jurisdiction')}
                  </div>
                </div>

                <div className="settings__fieldset">
                  <ul className="login__list legaldisclaimers__list">
                    <li>
                      The courts of the Principality of Liechtenstein shall have exclusive jurisdiction for all disputes arising out of or in connection with the offer or the agreement for the purchase of AWEARE Global Tokens, including those concerning the valid conclusion, legal validity, amendment or cancellation.
                    </li>
                  </ul>
                </div>

                <br/>
                <div className="card__head">
                  <div className="title-clear card__title">
                    {t('Severability')}
                  </div>
                </div>

                <div className="settings__fieldset">
                  <ul className="login__list legaldisclaimers__list">
                    <li>
                      If at any time one or more of the provisions of the AWEARE Global Token Offer and the agreement for its purchase is or becomes unlawful, invalid, illegal or unenforceable in any respect, the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired thereby.
                    </li>
                  </ul>
                </div>
                </>
              }
            </div>
          </>
        )}

        <div className="card">
          <div className="card__head">
            <div className="title-clear card__title">
              { t('KYC Submissions') }
            </div>
          </div>

          <AdminHistoryChangeStatusKyc
            customerType={customerType}
            userKyc={userKyc}
            onChangeStatus={onChangeStatus}
          />

          {
            publicSettings &&
            publicSettings.enable_signnow_individual_form &&
            customerType === CUSTOMER_TYPES.INDIVIDUAL && (
            <>
              {userKyc.documentESignature.length > 0 && userKyc.documentESignature.map((doc) => (doc.status === 'fulfilled' && (
                <div className="settings__fieldset" key={doc.id}>
                  <div className="field__wrap shop shop__products">
                    <div className="summary summary__document summary__fullwidth">
                      <div className="summary__preview">
                        <div className="summary__preview_aspect_ratio">
                          <embed
                            src={getExternalFileUrl(`signnow/${doc.remote_id}`)}
                            width="700"
                            height="700"
                            type="application/pdf"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )))}
            </>
          )}
        </div>

        <div className="card">
          <div className="card__head">
            <div className="title-clear card__title">
              { t('KYC level history') }
            </div>
          </div>

          <div className="settings__fieldset">
            {renderKycLevelHistory()}

            { userKyc.status === 'PENDING' && kycLvlHistoryUserAdmin.objects && kycLvlHistoryUserAdmin.objects.find((lvl) => {
              return lvl.kyc_status === 'APPROVED'
            }) && (
              (
                (customerType === CUSTOMER_TYPES.COMPANY && changeCustomerCompanyStatusData.loading) ||
                (customerType === CUSTOMER_TYPES.INDIVIDUAL && changeCustomerStatusData.loading)
              ) ? (
                <div>
                  <CircularProgress />
                </div>
              ) : (
                <div>
                  <div className="status-green-dark clickable" onClick={() => updateApplicationStatus('APPROVED')} style={{marginRight: '4px', marginBottom: '4px'}}>{t('Approve application')}</div>
                  <div className="status-red-dark clickable" onClick={() => updateApplicationStatus('REJECTED')} style={{marginBottom: '4px'}}>{t('Reject application')}</div>
                </div>
              )
            )}
          </div>
        </div>

        {userKyc &&
          userKyc.customerDiscountTransac &&
          userKyc.customerDiscountTransac.content &&
        (
          <div className="card">
            <div className="card__head">
              <div className="title-clear card__title">
                { t('Discount Level') }
              </div>
            </div>
            <div className="settings__fieldset">
              <span>{userKyc.customerDiscountTransac.content}</span>
            </div>
          </div>
        )}
      </>
    )
  }

  const renderDrawerTabReports = () => {
    return (
      <>
        {publicSettings &&
          publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION && (
            <div className="card">
              <div className="card__head">
                <div className="title-clear card__title">
                  { t('Electric Documents') }
                </div>
              </div>

              {userKyc.documentESignature && userKyc.documentESignature.length > 0
                ? userKyc.documentESignature.map((doc) => (
                  <div className="field__wrap shop shop__products" key={doc.id}>
                    <div className="summary summary__document summary__fullwidth">
                      <div className="summary__preview">
                        <div className="summary__preview_aspect_ratio">
                          <embed
                            src={getExternalFileUrl(`signnow/${doc.remote_id}`)}
                            width="700"
                            height="700"
                            type="application/pdf"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : (
                  <p>{t('No document has been signed.')}</p>)
              }

              { swapTokenSigners && Object.entries(pick(swapTokenSigners, fields)).length > 0 && (
                <ul>
                  { Object.entries(pick(swapTokenSigners, fields)).map((signer, index) => {
                    const firstSigner = Object.entries(pick(swapTokenSigners, fields))[0];

                    return (
                      <li>
                        <span>{signer[1].fulfiller}</span>
                        <span className={classNames(signer[1].fulfilled ? "status-green-dark" : "status-pending-dark", "settings__button")}>{signer[1].fulfilled ? t('Signed') : t('Not Signed')}</span>
                        {
                          ((!signer[1].fulfilled && index === 0) ||
                          (!signer[1].fulfilled && index !== 0 && firstSigner[1].fulfilled)) &&
                          me && hasUserEnoughRights(
                            me.rights,
                            USER_RIGHT_TYPES.COMPLIANCE_OFFICER
                          ) && (
                          <button
                            className="button button-small"
                            style={{marginLeft: "4px"}}
                            onClick={() => sendSignnowReminderEmail(signer[1])}
                          >
                            {t('Send Reminder Email')}
                          </button>
                        )}
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          )
        }

        {customerType === CUSTOMER_TYPES.INDIVIDUAL && publicSettings.identification && (
          <div className="card">
            <div className="card__head">
              <div className="title-clear card__title">
                { t('Files') }
              </div>
            </div>

            <div className="settings__fieldset">
              <>
                {publicSettings.identification.active_ident_service === 'electronic_id' && (
                  <>
                    <div className="field">
                      <button
                        onClick={onOpenDialogUploadPhoto}
                        className="button-stroke button-small w-full text-center"
                      >
                        { t('Upload photo') }
                      </button>
                    </div>

                    <div className="field">
                      <button
                        className="button-stroke button-small w-full text-center"
                        onClick={onOpenDialogVideoIdentLog}
                      >
                        { t('Liveness Check Log') }
                      </button>
                    </div>

                  </>
                )}

                {/* {user.video_ident_status === USER_VIDEO_IDENT_STATUS.PASSED ? (
                  <div className="field">
                    <a
                      href={`/video-id-recored/${user.video_ident_id}`}
                      className="button-stroke button-small w-full text-center"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      { t('Liveness Check Status & Link Video Recored') }:{" "}
                      {user.video_ident_status}
                    </a>
                  </div>
                ) : null} */}

                <div className="shop">
                  <div className="shop__products">
                    {Object.keys(filesScheme).map((key) => {
                      const upload = userKyc[filesScheme[key].propertyPath];

                      if (!upload) {
                        return "";
                      }
                      const uploader = upload.uploader || upload.user;
                      const upload_by = uploader ? `${uploader.first_name} ${uploader.last_name}` : '';

                      return (
                        <div className="summary summary-default summary-fix-height" key={key}>
                          <div className="summary__preview">
                            <UploadPreview
                              className={classes.img}
                              upload={
                                upload || {}
                              }
                              alt={filesScheme[key].label}
                            />
                            <div className="summary__control summary__control-has-text">
                              <div className="summary__buttons">
                                <a
                                  className="summary__button"
                                  href={
                                    getUploadUrl(
                                      upload || {}
                                    ) || ""
                                  }
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  <Icon
                                    className={classNames('link')}
                                    name="link"
                                    size={24}
                                  />
                                </a>
                              </div>

                              <div className="summary__control-texts">
                                <div className="summary__control-text">
                                  {upload_by ? `${t('Upload by')}: ${upload_by}` : ''}
                                </div>
                                <div className="summary__control-text">
                                  {`${t('Upload at')}: ${upload.created_at}`}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="summary__line">
                            <div className="summary__title">
                              {filesScheme[key].label}
                            </div>
                          </div>

                          <div className={classes.infoHead} style={{display: "none"}}>
                            {key === "mainDocumentPhoto" ? (
                              <div>
                                {filesScheme[key].label}
                                {upload
                                  .identification_status && (
                                  <>
                                    <p
                                      className={
                                        upload
                                          .identification_error
                                          ? classes.errorStatusIdentification
                                          : classes.succeededStatusIdentification
                                      }
                                    >
                                      { t('Check is') }{" "}
                                      {
                                        upload
                                          .identification_status
                                      }
                                    </p>
                                    {typeof upload
                                      .identification_json_response !==
                                      "string" && (
                                      <PopoverResponse
                                        jsonResponse={
                                          upload
                                            .identification_json_response
                                        }
                                      />
                                    )}
                                  </>
                                )}
                                {upload
                                  .identification_error && (
                                  <p className={classes.errorStatusIdentification}>
                                    {
                                      IDENTIFICATION_ERROR[
                                        upload
                                          .identification_error
                                      ]
                                    }
                                  </p>
                                )}
                              </div>
                            ) : (
                              filesScheme[key].label
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="space space__24">{' '}</div>

                {userKyc && userKyc.user && userKyc.user.id && (
                  <SupportedSourceOfFundDocuments userId={userKyc.user.id} />
                )}
              </>
            </div>
          </div>
        )}

        {renderPersonalDetails()}

        {addressScheme && (
          <div className="card">
            <div className="card__head">
              <div className="title-clear card__title">
                { t('Address') }
              </div>
            </div>

            <div className="earning__table earning__table-rwd">
              <div className="earning__row">{' '}</div>
              {Object.keys(addressScheme).map((key) => {
                const { label } = addressScheme[key];
                let value = userKyc[key];

                if (
                  addressScheme[key].type &&
                  addressScheme[key].type === "date"
                ) {
                  value = new Date(value).toDateString();
                }

                return (
                  <div
                    key={key}
                    className="earning__row"
                  >
                    <div className="earning__col">
                      {label}
                    </div>

                    <div className="earning__col">
                      {
                        (
                          key === "nationality" && extractedData && extractedData.nationality &&
                          COUNTRY_BY_ALPHA_3_CODE[value] !== extractedData.nationality
                        ) ?
                        <span className="text__red">{value}</span> : value
                      }

                      {key === "nationality" &&
                        calculatedRiskAssessment &&
                        calculatedRiskAssessment.country_of_nationality &&
                        riskLabelHelper(
                          calculatedRiskAssessment.country_of_nationality
                            .level
                        )}
                      {key === "countryOfResidence" &&
                        calculatedRiskAssessment &&
                        calculatedRiskAssessment.country_of_residence &&
                        riskLabelHelper(
                          calculatedRiskAssessment.country_of_residence
                            .level
                        )}
                    </div>

                    <div className="earning__col">
                      {isExtractEnabled && key === "nationality" &&
                        extractedData && extractedData.nationality}

                      {isExtractEnabled && key === "countryOfResidence" &&
                        extractedData && extractedData.issuingCountry}

                      {proofOfResidenceDocument && key === "residentialAddress" &&
                        proofOfResidenceDocument.response && proofOfResidenceDocument.response.data &&
                        proofOfResidenceDocument.response.data["Tax Invoice Address"]}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {userKyc.impira_documents && userKyc.impira_documents.filter(doc => doc.status === 'COMPLETED').length > 0 &&
          <div className="card">
            <div className="card__head">
              <div className="title-clear card__title">
                {t('Impira documents')}
              </div>
            </div>

            {userKyc.impira_documents.filter(doc => doc.status === 'COMPLETED').map(doc => {
              return (
                <>
                  <div className='earning__table earning__table-rwd'>
                    <div className="earning__row">
                      <div className="earning__col">
                        {t('Document type')}
                      </div>

                      <div className="earning__col">
                        {doc.document_type}
                      </div>
                    </div>

                    {doc.response.data && doc.response.data &&
                      Object.entries(doc.response.data).map(([key, value], index) => {
                        if (!value || key === 'File') {
                          return '';
                        }

                        return (
                          <div
                            className="earning__row"
                            key={index}
                          >
                            <div className="earning__col">
                              <div className="earning__label">
                                {t('Document type')}
                              </div>
                              {key}
                            </div>

                            <div className="earning__col">
                              <div className="earning__label">
                                {doc.document_type}
                              </div>
                              {typeof value === 'string' ? value : JSON.stringify(value)}
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <br />
                </>
              )
            })}
          </div>
        }

        { userKyc.user.receivingCoinfirmReport
          && userKyc.user.receivingCoinfirmReport
          && userKyc.user.receivingCoinfirmReport.coinfirmReportPdfs && (
            <div className="card">
              <div className="card__head">
                <div className="title-clear card__title">
                  { t('Coinfirm report PDFs') }
                </div>
              </div>

              {userKyc.user.receivingCoinfirmReport.coinfirmReportPdfs.length > 0
                ? userKyc.user.receivingCoinfirmReport.coinfirmReportPdfs.map((pdf) => (
                  <div className="settings__fieldset" key={pdf.id}>
                    <AdminUploadPreview upload={pdf} />
                  </div>
                ))
                : (
                  <p>{t('No report')}</p>)
              }
            </div>
          )
        }
      </>
    )
  }

  const renderDrawerTabAllRisks = () => {
    return (
      <>
        <div className="card">
          <div className="card__head">
            <div className="title-clear card__title">
              { t('Overall Risk Assessment') }: {calculateOverallRiskAssessment()}{" "}
            </div>
          </div>

          <div className="earning__table">
            <div className="earning__row">{' '}</div>
            {Object.entries(calculatedRiskAssessment).map((ele) => {
              return (
                <div className="earning__row">
                  <div className="earning__col">
                    {ele[0] === 'size_of_transaction' && (
                      <>
                        <div className='field'>
                          {t('Size of Transaction')}

                          <span
                            data-tip
                            data-html={<></>}
                            data-for="size_of_transaction"
                            className="tooltip"
                          >
                          <Icon
                            className={classNames('info')}
                            name='info'
                            size="16"
                          />
                          </span>
                          <ReactTooltip id="size_of_transaction" place="bottom" padding="0" className="popover-tooltip fullwidth-tooltip" html={true}>
                            <div className="earning__table">
                              <div className="earning__row" />
                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Low-value transaction, 0-5000 CHF
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 1
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Low') }
                                </div>
                              </div>
                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Medium-value transaction, 5001-25000 CHF
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 3
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Medium') }
                                </div>
                              </div>
                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  High-value transaction, 25001 CHF or more
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 4
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Enhanced') }
                                </div>
                              </div>
                            </div>
                          </ReactTooltip>
                        </div>
                      </>
                    )}

                    {ele[0] === 'country_of_nationality' && (
                      <>
                        {t('Country of Nationality')}
                      </>
                    )}

                    {ele[0] === 'transaction' && (
                      <>

                        <div className='field'>
                          {t('Transaction')}

                          <span
                            data-tip
                            data-html={<></>}
                            data-for="transaction"
                            className="tooltip"
                          >
                          <Icon
                            className={classNames('info')}
                            name='info'
                            size="16"
                          />
                          </span>
                          <ReactTooltip id="transaction" place="bottom" padding="0" className="popover-tooltip fullwidth-tooltip" html={true}>
                            <div className="earning__table">
                              <div className="earning__row" />
                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Transfer from a bank situated in the EEA
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 1
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Low') }
                                </div>
                              </div>
                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Bank transfer from outside EEA
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 3
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Medium') }
                                </div>
                              </div>
                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Crypto payment, normal risk score, amount below 5000 CHF
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 3
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Medium') }
                                </div>
                              </div>

                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Crypto payment, normal risk score, amount 5000 CHF or more
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 4
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Enhanced') }
                                </div>
                              </div>

                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Crypto payment, high risk score
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 5
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('High') }
                                </div>
                              </div>

                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Transaction carried out in a manner that favours anonymity
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 5
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('High') }
                                </div>
                              </div>

                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Transaction inconsistent with business profile
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 5
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('High') }
                                </div>
                              </div>

                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Indicator of higher risk, ML/TF, organized crime hit
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 5
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('High') }
                                </div>
                              </div>
                            </div>
                          </ReactTooltip>
                        </div>
                      </>
                    )}

                    {ele[0] === 'country_of_residence' && (
                      <>
                        {t('Country of Residence')}
                      </>
                    )}

                    {ele[0] === 'client_reputation_relationship' && (
                      <>
                        <div className='field'>
                          {t('Client Reputation Relationship')}

                          <span
                            data-tip
                            data-html={<></>}
                            data-for="client_reputation_relationship"
                            className="tooltip"
                          >
                          <Icon
                            className={classNames('info')}
                            name='info'
                            size="16"
                          />
                          </span>
                          <ReactTooltip id="client_reputation_relationship" place="bottom" padding="0" className="popover-tooltip fullwidth-tooltip" html={true}>
                            <div className="earning__table">
                              <div className="earning__row" />
                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Publicly known person, no PEP, no sanctions list, no adverse information
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 1
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Low') }
                                </div>
                              </div>

                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Long-standing business relationship, client seen in person, no PEP, no sanctions list, no adverse information
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 1
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Low') }
                                </div>
                              </div>

                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Relatively new business relationship, client recommended by a respected client or partner, no PEP, no sanctions list, no adverse information
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 2
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Medium') }
                                </div>
                              </div>

                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Relatively new business relationship, no PEP, no sanctions list, no adverse information
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 3
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Medium') }
                                </div>
                              </div>

                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Client never seen in person, onboarded by means of secure e-identification
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 3
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Medium') }
                                </div>
                              </div>

                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Business relationship takes place in unusual circumstances
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 4
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Enhanced') }
                                </div>
                              </div>

                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Client applied to a program "residence by investment" in the EU
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 5
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('High') }
                                </div>
                              </div>

                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  PEP/adverse media/sanctions list hit
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 5
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('High') }
                                </div>
                              </div>
                            </div>
                          </ReactTooltip>
                        </div>
                      </>
                    )}

                    {ele[0] === 'beneficial_owner' && (
                      <>
                        <div className='field'>
                          {t('Beneficial Owner')}

                          <span
                            data-tip
                            data-html={<></>}
                            data-for="beneficial_owner"
                            className="tooltip"
                          >
                          <Icon
                            className={classNames('info')}
                            name='info'
                            size="16"
                          />
                          </span>
                          <ReactTooltip id="beneficial_owner" place="bottom" padding="0" className="popover-tooltip fullwidth-tooltip" html={true}>
                            <div className="earning__table">
                              <div className="earning__row" />
                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  N/A
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 1
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Low') }
                                </div>
                              </div>
                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  No PEP, no sanctions list, no adverse information, no high-risk jurisdiction
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 1
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Low') }
                                </div>
                              </div>
                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  PEP
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 5
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('High') }
                                </div>
                              </div>
                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Sanctions list
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 5
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('High') }
                                </div>
                              </div>
                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Adverse media information
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 5
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('High') }
                                </div>
                              </div>
                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  High-risk jurisdiction
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 5
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('High') }
                                </div>
                              </div>
                            </div>
                          </ReactTooltip>
                        </div>
                      </>
                    )}

                    {ele[0] === 'source_of_funds' && (
                      <>
                        <div className='field'>
                          {t('Source of Funds')}

                          <span
                            data-tip
                            data-html={<></>}
                            data-for="source_of_funds"
                            className="tooltip"
                          >
                          <Icon
                            className={classNames('info')}
                            name='info'
                            size="16"
                          />
                          </span>
                          <ReactTooltip id="source_of_funds" place="bottom" padding="0" className="popover-tooltip fullwidth-tooltip" html={true}>
                            <div className="earning__table">
                              <div className="earning__row" />
                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Salary/business income
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 1
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Low') }
                                </div>
                              </div>
                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Income from operations on public stock exchange or other regulated entity
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 1
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Low') }
                                </div>
                              </div>
                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Gift or inheritance
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 3
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Medium') }
                                </div>
                              </div>
                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Income from crypto or other blockchain transactions
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 4
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('Enhanced') }
                                </div>
                              </div>
                              <div className="earning__row">
                                <div className="earning__col wrapped-col">
                                  Indicator of higher risk, ML/TF, organized crime hit
                                </div>
                                <div className="earning__col wrapped-col">
                                  Score: 5
                                </div>
                                <div className="earning__col wrapped-col">
                                  Level: { t('High') }
                                </div>
                              </div>
                            </div>
                          </ReactTooltip>
                        </div>
                      </>
                    )}

                    {ele[0] === 'business_countries' && (
                      <>
                        {t('Business Countries')}
                      </>
                    )}

                  </div>

                  <div className="earning__col">
                    {`${Object.values(ele[1])[1]}`}
                  </div>
                  <div className="earning__col">
                    {`${Object.values(ele[1])[0] || "—"}`}
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className="card">
        <div className="card__head">
            <div className="title-clear card__title">
              { t('Rules') }:
            </div>
          </div>

          <div className="earning__table">
            <div className="earning__row text-center">{ t('Risk level') }</div>
            <div className="earning__row">
              <div className="earning__col">
                0
              </div>
              <div className="earning__col">
                1
              </div>
              <div className="earning__col">
                { t('Low') }
              </div>
            </div>

            <div className="earning__row">
              <div className="earning__col">
                1
              </div>
              <div className="earning__col">
                2
              </div>
              <div className="earning__col">
                { t('Medium') }
              </div>
            </div>

            <div className="earning__row">
              <div className="earning__col">
                2
              </div>
              <div className="earning__col">
                3.5
              </div>
              <div className="earning__col">
                { t('Enhanced') }
              </div>
            </div>

            <div className="earning__row">
              <div className="earning__col">
                3.5
              </div>
              <div className="earning__col">
                5
              </div>
              <div className="earning__col">
                { t('High') }
              </div>
            </div>

            <div className="earning__row">
              <small>
                { t('If one risk level is 5 the global score is automatically 5') }
              </small>
            </div>
          </div>

          <br />
          <div className="earning__table">
            <div className="earning__row">{' '}</div>
            <div className="earning__row">
              <div className="earning__col">
                { t('Risk level') }
              </div>
              <div className="earning__col">
                { t('Period of Reviewing (years)') }
              </div>
            </div>

            <div className="earning__row">
              <div className="earning__col">
                { t('Low') }
              </div>
              <div className="earning__col">
                3
              </div>
            </div>

            <div className="earning__row">
              <div className="earning__col">
                { t('Medium') }
              </div>
              <div className="earning__col">
                3
              </div>
            </div>

            <div className="earning__row">
              <div className="earning__col">
                { t('Enhanced') }
              </div>
              <div className="earning__col">
                3
              </div>
            </div>

            <div className="earning__row">
              <div className="earning__col">
                { t('High') }
              </div>
              <div className="earning__col">
                1
              </div>
            </div>
          </div>
        </div>
      </>

    )
  }

  const renderDrawerTabTransaction = () => {
    return (
      <>
        {publicSettings && publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION && (
          <div className="card">
            <div className="card__head">
              <div className="title-clear card__title">
                { t('Transactions') }
              </div>
            </div>

            <AdminTransactionsUser
              publicSettings={publicSettings || {}}
              user={user}
              userKyc={userKyc}
              exchangeRates={exchangeRates}
              customClasses="products__wrapper-drawer"
            />
          </div>
        )}
      </>
    )
  }

  const renderDrawerTabWatchlist = () => {
    return (
      <>
        {customerType === CUSTOMER_TYPES.INDIVIDUAL && (
          <>
            <AdminWatchlistCheckViewer type="individual" customer={userKyc} refetchCustomer={refetch} transactionsUserList={transactionsUserList} />
          </>
        )}
      </>
    )
  }

  const renderDrawerTabFacematch = () => {
    return (
      <>
        {customerType === CUSTOMER_TYPES.INDIVIDUAL && (
          <>
            <AdminLivenessCheckViewer type="individual" customer={userKyc} refetchCustomer={refetch} transactionsUserList={transactionsUserList} />
          </>
        )}
      </>
    )
  }

  const renderDrawerCompanyTabDocument = () => {
    return (
      <>
        {customerType === CUSTOMER_TYPES.COMPANY && (
          <div className="card">
            <div className="card__head">
              <div className="title-clear card__title">
                { t('Company document') }
              </div>
            </div>

            {Object.keys(companyDocumentFields)
              .reduce((all, field) => {
                return all ||
                        publicSettings[companyDocumentFields[field].settingField] ||
                        (field === 'commercialRegisterExtract' && getExternalFileUrl(userKyc[field].storage_key)) ||
                        (field === 'supportedSourceOfFundUploads' && userKyc[field] && userKyc[field].length)
              }, false)
              ? (
                <div className="settings__fieldset">
                  <div className="shop shop__products shop__products_2_column">
                    {Object.keys(companyDocumentFields).map(field => {
                      return (publicSettings[companyDocumentFields[field].settingField] ||
                        (field === 'commercialRegisterExtract' && getExternalFileUrl(userKyc[field].storage_key)) ||
                        (field === 'supportedSourceOfFundUploads' && userKyc[field] && userKyc[field].length)) ? (
                          Array.isArray(userKyc[field]) ? userKyc[field] : [userKyc[field]]).map((userKycField, index) => {
                            return (
                              <div key={field + index} className="summary summary__document">
                                <div className="summary__preview">
                                  <div className="summary__preview_aspect_ratio">
                                    <embed
                                      src={getExternalFileUrl(userKycField.storage_key)}
                                      height="300px"
                                      width='auto'
                                      type={userKycField.mime_type}
                                    />
                                  </div>

                                  <div className="summary__control">
                                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                                    <a
                                      className="summary__button"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={customeGetUploadUrl(userKycField)}>
                                      <Icon
                                        className={classNames('link')}
                                        name='link'
                                        size={24}
                                      />{" "}
                                    </a>
                                  </div>
                                </div>

                                <div className="summary__line">
                                  <div className="summary__title">
                                    {companyDocumentFields[field].label}
                                  </div>

                                  <div className="hide-full">
                                    {userKycField.storage_key}
                                  </div>
                                </div>
                              </div>
                            )
                          }) : null
                        }
                      )
                    }

                  </div>
                  <div className="space space__32">{' '}</div>
                </div>
              )
              : (
                <p>{t('No file required')}</p>
              )
            }
          </div>
        )}
      </>
    )
  }

  const renderDrawerCompanyTabUBOs = () => {
    return (
      <>
        {customerType === CUSTOMER_TYPES.COMPANY && (
          <>
            {userKyc.customerCompanyUbo.length > 0 && (
              <div className="card">
                <div className="card__head">
                  <div className="title-clear card__title">
                    { t('UBOs')}
                  </div>
                </div>

                {userKyc.customerCompanyUbo.map(ubo => (
                  <div className="settings__list" key={ubo.id} style={{marginBottom: "20px"}}>
                    <div className="settings__line">
                      <div className="settings__label">
                        {t('Email')}
                      </div>

                      <div className="settings__content">
                        {ubo.email}
                      </div>
                    </div>

                    <div className="settings__line">
                      <div className="settings__label">
                        {t('Name')}
                      </div>

                      <div className="settings__content">
                        { ubo.firstName } { ubo.middleName } { ubo.lastName }
                      </div>
                    </div>

                    <div className="settings__line">
                      <div className="settings__label">
                        {t('Date of Birth')}
                      </div>

                      <div className="settings__content">
                        { ubo.birthDate }
                      </div>
                    </div>

                    <div className="settings__line">
                      <div className="settings__label">
                        {t('Politically Exposed Persons')}
                      </div>

                      <div className="settings__content">
                        { t(ubo.personType) }
                      </div>
                    </div>

                    <div className="settings__line">
                      <div className="settings__label">
                        {t('Exact description of the function')}
                      </div>

                      <div className="settings__content">
                        { t(ubo.duties) }
                      </div>
                    </div>

                    <div className="settings__line">
                      <div className="settings__label">
                        {t('Relationship to PEP')}
                      </div>

                      <div className="settings__content">
                        { t(ubo.relationshipToPEP) }
                      </div>
                    </div>

                    <div className="settings__line">
                      <div className="settings__label">
                        {t('Ownership')}
                      </div>

                      <div className="settings__content">
                        { ubo.ownership }%
                      </div>
                    </div>

                    {ubo.trueCertifiedCopy ? (
                      <div className="settings__line">
                        <div className="settings__label">
                          {t('True Certified Copy')}
                        </div>

                        <div className="settings__content">
                          <div className="shop shop__products">
                            <div className="summary summary__document summary-default summary__fullwidth">
                              <div className="summary__preview">
                                <div className="summary__preview_aspect_ratio">
                                  <embed
                                    src={getExternalFileUrl(ubo.trueCertifiedCopy.storage_key)}
                                    height="300px"
                                    width='auto'
                                    type={ubo.trueCertifiedCopy.mime_type}
                                  />
                                </div>

                                <div className="summary__control summary__control-has-text">
                                  <div className="summary__buttons">
                                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                                    <a
                                      rel="noopener noreferrer"
                                      className="summary__button"
                                      href={customeGetUploadUrl(ubo.trueCertifiedCopy)}
                                      target="_blank"
                                    >
                                      <Icon
                                        className={classNames('link')}
                                        name='link'
                                        size="24"
                                      />{" "}
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div className="summary__line">
                                <div className="summary__title">
                                  {ubo.trueCertifiedCopy.storage_key}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) :
                      <div className="settings__line">
                        <div className="settings__label">
                          {t('True Certified Copy')}
                        </div>

                        <div className="settings__content">
                          <span className="status-red">{t('Not provided')}</span>
                        </div>
                      </div>
                    }

                    {!ubo.onfidoApplicant && !ubo.complycubeClient && (
                      <div className="settings__line">
                        <div className="settings__label">
                          {t('Watchlist')}
                        </div>

                        <div className="settings__content">
                          <span className="status-red">{t('Not provided')}</span>
                        </div>
                      </div>
                    )}

                    {ubo.onfidoApplicant && ubo.onfidoApplicant.onfidoChecks.length > 0 && (
                      <>
                        {ubo.onfidoApplicant.onfidoChecks[0].status === 'in_progress' && (
                          <div className="settings__line">
                            <div className="settings__label">
                              {t('Watchlist')}
                            </div>
                            <div className="settings__content">
                              {manualFetchCheckStatus.loading && <CircularProgress />}
                              {!manualFetchCheckStatus.loading && (
                                <button
                                  className="button button-xs"
                                  onClick={triggerManualFetch(ubo.onfidoApplicant.id)}
                                >
                                  {t('Manual fetch check')}
                                </button>
                              )}
                            </div>
                          </div>
                        )}

                        {ubo.onfidoApplicant.onfidoChecks[0].status === 'complete' && (
                          <div className="settings__line settings__line-block">
                            <div className="settings__label">
                              {t('Watchlist')}
                            </div>

                            <div className="settings__content">
                              <div className="field__wrap drafts">
                                <div className="drafts__list">
                                  <ReportView breakdown={ubo.onfidoApplicant.onfidoChecks[0].onfidoReports[0].breakdown} />
                                  {ubo.onfidoApplicant.onfidoChecks.length > 1 && (
                                    <p>and {ubo.onfidoApplicant.onfidoChecks.length - 1} older.</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {ubo.complycubeClient && ubo.complycubeClient.complycubeChecks.length > 0 && (
                      <div className="settings__line settings__line-block">
                        <div className="settings__lab">
                          {t('ComplyCube Watchlist')}
                        </div>
                        <div className="settings__content">
                          <ComplycubeReportView breakdown={ubo.complycubeClient.complycubeChecks[0].result} />
                          {ubo.complycubeClient.complycubeChecks.length > 1 && (
                            <p>and {ubo.complycubeClient.complycubeChecks.length - 1} older.</p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}

              </div>
            )}
          </>
        )}
      </>
    )
  }

  const renderDrawerCompanyTabDirector = () => {
    return (
      <>
        {customerType === CUSTOMER_TYPES.COMPANY && (
          <>
            <div className="card">
              <div className="card__head">
                <div className="title-clear card__title">
                  { t('Directors') }
                </div>
              </div>

              <>
                {userKyc.customerCompanyDirector.map((director, index) => {
                  const onfidoChecks = (director.onfidoApplicant || {}).onfidoChecks;
                  const onfidoReports = onfidoChecks && onfidoChecks.length ? onfidoChecks[onfidoChecks.length - 1].onfidoReports : null;
                  const onfidoReport = onfidoReports && onfidoReports.length ? onfidoReports.find(report => report.name === 'document') : null;
                  const properties = onfidoReport ? onfidoReport.properties : {};

                  return (
                    <React.Fragment key={director.id}>
                      <div className="settings__list settings__list-director">
                        <div className="settings__line settings__line-block" key={index}>
                          <div className="settings__label">
                            {t('Name')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { director.firstName } { director.middleName} { director.lastName }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  { properties.firstName || '' } { properties.lastName || '' }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-table">
                          <div className="earning__table">
                            <div className="earning__row">
                              <div className="earning__col">
                                {t('Name')}
                              </div>

                              <div className="earning__col">
                                {t('Email')}
                              </div>
                            </div>

                            <div className="earning__row" key={index}>
                              <div className="earning__col">
                                <div className="earning__label">
                                  {t('Name')}
                                </div>
                                {index + 1}. { director.firstName } { director.middleName} { director.lastName }
                                <br />
                                <span
                                  className={
                                    classNames(
                                      'status',
                                      director.status === 'REJECTED' ? 'status-red':'',
                                      director.status === 'NOT_SUBMITTED' ? 'status-red':'',
                                      director.status === 'PENDING' ? 'status-pending-dark':'',
                                      director.status === 'REOPEN' ? 'status-pending-dark':'',
                                      director.status === 'PASSED' ? 'status-green-dark':'',
                                    )
                                  }
                                >
                                  {director.status === 'VIDEO_IDENT' && (
                                    <>
                                      {t('LIVENESS CHECK')}
                                    </>
                                  )}

                                  {[director.status]}
                                </span>
                              </div>

                              <div className="earning__col">
                                <div className="earning__label">
                                  {t('Email')}
                                </div>
                                {index + 1}. ({ director.email })
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block" key={index}>
                          <div className="settings__label">
                            {t('Email')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { director.email }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block" key={index}>
                          <div className="settings__label">
                            {t('Phone')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { director.phone }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block" key={index}>
                          <div className="settings__label">
                            {t('DOB')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { director.birthDate }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  { properties.dateOfBirth || '' }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block" key={index}>
                          <div className="settings__label">
                            {t('Place of Birth')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { director.placeOfBirth }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block" key={index}>
                          <div className="settings__label">
                            {t('Tax No')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { director.taxNumber }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {publicSettings && publicSettings.kyc_education_level && (
                          <>
                            <div className="settings__line settings__line-block" key={index}>
                              <div className="settings__label">
                                {t('Education level')}
                              </div>

                              <div className="settings__content">
                                <div className="countries__list">
                                  <div className="countries__item">
                                    <div className="countries__title">
                                      {t('Input Data')}
                                    </div>
                                    <div className="countries__price">
                                      { director.education_level}
                                    </div>
                                  </div>

                                  <div className="countries__item">
                                    <div className="countries__title">
                                      {t('Extracted Data')}
                                    </div>
                                    <div className="countries__price">
                                      {' '}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="settings__line settings__line-block" key={index}>
                              <div className="settings__label">
                                {t('Education level other')}
                              </div>

                              <div className="settings__content">
                                <div className="countries__list">
                                  <div className="countries__item">
                                    <div className="countries__title">
                                      {t('Input Data')}
                                    </div>
                                    <div className="countries__price">
                                      { director.education_level_other}
                                    </div>
                                  </div>

                                  <div className="countries__item">
                                    <div className="countries__title">
                                      {t('Extracted Data')}
                                    </div>
                                    <div className="countries__price">
                                      {' '}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {publicSettings && publicSettings.kyc_occupation && (
                          <>

                            <div className="settings__line settings__line-block">
                              <div className="settings__label">
                                {t('Occupation')}
                              </div>

                              <div className="settings__content">
                                <div className="countries__list">
                                  <div className="countries__item">
                                    <div className="countries__title">
                                      {t('Input Data')}
                                    </div>
                                    <div className="countries__price">
                                      { director.occupation }
                                    </div>
                                  </div>

                                  <div className="countries__item">
                                    <div className="countries__title">
                                      {t('Extracted Data')}
                                    </div>
                                    <div className="countries__price">
                                      {' '}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="settings__line settings__line-block">
                              <div className="settings__label">
                                {t('Occupation other')}
                              </div>

                              <div className="settings__content">
                                <div className="countries__list">
                                  <div className="countries__item">
                                    <div className="countries__title">
                                      {t('Input Data')}
                                    </div>
                                    <div className="countries__price">
                                      { director.occupation_other}
                                    </div>
                                  </div>

                                  <div className="countries__item">
                                    <div className="countries__title">
                                      {t('Extracted Data')}
                                    </div>
                                    <div className="countries__price">
                                      {' '}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {publicSettings && publicSettings.kyc_show_position && (
                          <>
                            <div className="settings__line settings__line-block">
                              <div className="settings__label">
                                {t('Position')}
                              </div>

                              <div className="settings__content">
                                <div className="countries__list">
                                  <div className="countries__item">
                                    <div className="countries__title">
                                      {t('Input Data')}
                                    </div>
                                    <div className="countries__price">
                                      { director.position }
                                    </div>
                                  </div>

                                  <div className="countries__item">
                                    <div className="countries__title">
                                      {t('Extracted Data')}
                                    </div>
                                    <div className="countries__price">
                                      {' '}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="settings__line settings__line-block">
                              <div className="settings__label">
                                {t('Position other')}
                              </div>

                              <div className="settings__content">
                                <div className="countries__list">
                                  <div className="countries__item">
                                    <div className="countries__title">
                                      {t('Input Data')}
                                    </div>
                                    <div className="countries__price">
                                      { director.position_other }
                                    </div>
                                  </div>

                                  <div className="countries__item">
                                    <div className="countries__title">
                                      {t('Extracted Data')}
                                    </div>
                                    <div className="countries__price">
                                      {' '}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {publicSettings && publicSettings.kyc_require_industry && (
                          <div className="settings__line settings__line-block">
                            <div className="settings__label">
                              {t('Industry')}
                            </div>

                            <div className="settings__content">
                              <div className="countries__list">
                                <div className="countries__item">
                                  <div className="countries__title">
                                    {t('Input Data')}
                                  </div>
                                  <div className="countries__price">
                                  { director.industry }
                                  </div>
                                </div>

                                <div className="countries__item">
                                  <div className="countries__title">
                                    {t('Extracted Data')}
                                  </div>
                                  <div className="countries__price">
                                    {' '}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {publicSettings && publicSettings.kyc_require_industry && director.industryOther && (
                          <div className="settings__line settings__line-block">
                            <div className="settings__label">
                              {t('Describe details of industry')}
                            </div>

                            <div className="settings__content">
                              <div className="countries__list">
                                <div className="countries__item">
                                  <div className="countries__title">
                                    {t('Input Data')}
                                  </div>
                                  <div className="countries__price">
                                    { director.industryOther }
                                  </div>
                                </div>

                                <div className="countries__item">
                                  <div className="countries__title">
                                    {t('Extracted Data')}
                                  </div>
                                  <div className="countries__price">
                                    {' '}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="settings__line settings__line-block">
                          <div className="settings__label">
                            {t('Website')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { director.website }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block">
                          <div className="settings__label">
                            {t('Signature Right')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { director.signatureRight }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block">
                          <div className="settings__label">
                            {t('Will sign the form')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { director.willSignForm ? t('Yes') : t('No') }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block">
                          <div className="settings__label">
                            {t('Identification Number')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { director.uniqueId }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  { ((properties.documentNumbers || []).find(doc => doc.type === 'document_number') || {}).value }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block">
                          <div className="settings__label">
                            {t('Nationality')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { director.nationality }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block">
                          <div className="settings__label">
                            {t('Add more Nationality')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { director.nationality_two }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block">
                          <div className="settings__label">
                            {t('Add more Nationality')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { director.nationality_three }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block">
                          <div className="settings__label">
                            {t('Country of Residence')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { director.countryOfResidence }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block">
                          <div className="settings__label">
                            {t('Residential Address')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { director.residentialAddress }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block">
                          <div className="settings__label">
                            {t('City')}
                          </div>
                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { director.city }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block">
                          <div className="settings__label">
                            {t('Postalcode')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { director.postalCode }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block">
                          <div className="settings__label">
                            {t('Source of Funds (Free Text)')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { director.sourceOfFunds }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block">
                          <div className="settings__label">
                            {t('Status')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  <span
                                    className={
                                      classNames(
                                        'status',
                                        director.status === 'REJECTED' ? 'status-red':'',
                                        director.status === 'NOT_SUBMITTED' ? 'status-red':'',
                                        director.status === 'PENDING' ? 'status-pending-dark':'',
                                        director.status === 'REOPEN' ? 'status-pending-dark':'',
                                        director.status === 'PASSED' ? 'status-green-dark':'',
                                      )
                                    }
                                  >
                                    { renderDirectorStatus(director.status) }
                                  </span>
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block">
                          <div className="settings__label">
                            {t('Politically Exposed Persons')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { t(director.personType) }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block">
                          <div className="settings__label">
                            {t('Exact description of the function')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { t(director.duties) }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block">
                          <div className="settings__label">
                            {t('Relationship to PEP')}
                          </div>

                          <div className="settings__content">
                            <div className="countries__list">
                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Input Data')}
                                </div>
                                <div className="countries__price">
                                  { t(director.relationshipToPEP) }
                                </div>
                              </div>

                              <div className="countries__item">
                                <div className="countries__title">
                                  {t('Extracted Data')}
                                </div>
                                <div className="countries__price">
                                  {' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="settings__line settings__line-block">
                          <div className="settings__label">
                            {t('Liveness Check')}
                          </div>

                          <div className="settings__content">
                            <AdminLivenessCheckViewer
                              type="director"
                              customer={director}
                              refetchCustomer={refetch}
                              transactionsUserList={transactionsUserList}
                            />
                          </div>
                        </div>

                        <div className="settings__line settings__line-block">
                          <div className="settings__label">
                            {t('Signature')}
                          </div>

                          <div className="settings__content">
                            { director.documentESignature ? (
                              <div className="summary summary__document summary__fullwidth">
                                <div className="summary__preview">
                                  <div className="summary__preview_aspect_ratio">
                                    <embed
                                      src={getExternalFileUrl(`signnow/${director.documentESignature.remote_id}`)}
                                      width="700"
                                      height="700"
                                      type="application/pdf"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) :
                              <div className="summary summary__document">
                                <div className="summary__line">
                                  <div className="summary__title">
                                    {t('Not provided')}
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>

                      { director.status === 'PENDING' && (
                        <div className="field">
                          <div className="field__wrap">
                            <button
                              className="button-stroke button-small"
                              onClick={() => handleUpdateDirectorStatus(director.id, 'PASSED')}
                              disabled={loadingUpdateDirectorStatus}
                              style={{marginBottom: '4px', marginRight: '4px'}}
                            >
                              <Icon
                                className={classNames('check')}
                                name='check'
                                size="24"
                              />{" "}
                              {t('Mark as passed')}
                            </button>

                            <button
                              className="button-stroke button-small"
                              onClick={() => handleUpdateDirectorStatus(director.id, 'REJECTED')}
                              disabled={loadingUpdateDirectorStatus}
                              style={{marginBottom: '5px', marginRight: '5px'}}
                            >
                              <Icon
                                className={classNames('close-circle')}
                                name='close'
                                size="24"
                              />{" "}
                              {t('Mark as rejected')}
                            </button>

                            <button
                              className="button-stroke button-small"
                              onClick={() => {
                                setOpenCommentForReopen(true);
                                setReopenWithVideoIdent(false);
                                setSelectedDirector(director);
                              }}
                              disabled={loadingUpdateDirectorStatus}
                              style={{marginBottom: '5px', marginRight: '5px'}}
                            >
                              <Icon
                                className={classNames('link')}
                                name='link'
                                size="24"
                              />{" "}
                              {t('Reopen')}
                            </button>

                            <button
                              className="button-stroke button-small"
                              onClick={() => {
                                setOpenCommentForReopen(true);
                                setReopenWithVideoIdent(true)
                                setSelectedDirector(director);
                              }}
                              disabled={loadingUpdateDirectorStatus}
                              style={{marginBottom: '5px', marginRight: '5px'}}
                            >
                              <Icon
                                className={classNames('check')}
                                name='check'
                                size="24"
                              />{" "}
                              {t('Reopen with liveness check')}
                            </button>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  )
                }
              )}
              </>
            </div>

            <Dialog
              open={openCommentForReopen}
              onClose={() => {
                setOpenCommentForReopen(false);
                setSelectedDirector(null)
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <ModalInnerMaterialDialog
                onClickClose={() => {
                  setOpenCommentForReopen(false);
                  setSelectedDirector(null);
                }}
                modalTitle={t('Comment')}
                modalTitleClassColor="title-primary"
              >
                <div className="settings__fieldset">
                  <div className="field">
                    <div className="field__label">
                      {t('Comment field')}
                      <span className="text__red"> *</span>
                    </div>
                    <div className="field__wrap field__wrap-textarea">
                      <TextField
                        fullWidth
                        id="filled-multiline-flexible"
                        multiline
                        minRows="4"
                        maxRows="4"
                        onChange={({ target: { value }}) => setReopenComment(value)}
                        value={reopenComment}
                        required
                      />
                    </div>
                  </div>

                  <div className="field">
                    <button
                      className="button"
                      onClick={() => handleUpdateDirectorStatus(selectedDirector.id, reopenWithVideoIdent ? 'REOPEN_WITH_VIDEOIDENT' : 'REOPEN')}
                    >
                      {t('Send')}
                    </button>
                  </div>
                </div>
              </ModalInnerMaterialDialog>
            </Dialog>

            {userKyc.kompanyData && (
              userKyc.kompanyData.manageDirector ||
              userKyc.kompanyData.director ||
              userKyc.kompanyData.beneficialOwner ||
              userKyc.kompanyData.shareholder) && (
              <div className="card">
                <div className="card__head">
                  <div className="title-clear card__title">
                    { t('Kompany extra data')}
                  </div>
                </div>
                <div className="settings__fieldset">
                  {userKyc.kompanyData.manageDirector && (
                    <>
                      <h3>Managing Directors</h3>
                      <ul>
                        {userKyc.kompanyData.manageDirector.map(director => (
                          <li>{director}</li>
                        ))}
                      </ul>
                      <br />
                    </>
                  )}
                  {userKyc.kompanyData.director && (
                    <>
                      <h3>Directors</h3>
                      <ul>
                        {userKyc.kompanyData.director.map(director => (
                          <li>
                            {director.name},
                            {' '}
                            as {director.role}
                            {' '}
                            from {director.startDate}
                            {director.endDate ? `to ${director.endDate}` : ''}.
                            {' '}
                            Status: {director.status}
                          </li>
                        ))}
                      </ul>
                      <br />
                    </>
                  )}
                  {userKyc.kompanyData.shareholder && (
                    <>
                      <h3>Shareholders</h3>
                      <ul>
                        {userKyc.kompanyData.shareholder.map(holder => (
                          <li>
                            {holder.name} ({holder.percentage}%).
                          </li>
                        ))}
                      </ul>
                      <br />
                    </>
                  )}
                  {userKyc.kompanyData.beneficialOwner && (
                    <>
                      <h3>Beneficial owners</h3>
                      <ul>
                        {userKyc.kompanyData.beneficialOwner.map(bo => (
                          <li>
                            {bo.name} ({bo.percentage}%).
                          </li>
                        ))}
                      </ul>
                      <br />
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </>
    )
  }

  return loadingPublicSetting ? (
    <CircularProgress />
  ) : (
    <>
      <Dialog
        open={open}
        onClose={onClickDrawerAway}
      >
        <div className="popup js-popup popup__fullwidth animation visible">
          <div className="popup__wrap popup__wrap-gray js-popup-wrap popup__wrap-v2 page popup__drawer">
            <div className="sidebar popup__drawer-sidebar">
              <button type="button" className='sidebar__help popup__drawer-close' onClick={onClickDrawerAway}>
                <Icon
                  className={classNames('arrow-left')}
                  name='arrow-left'
                  size='24'
                />{' '}
                {t('Back to list')}
              </button>

              <div className="stock__item popup__drawer-info" style={{ backgroundColor: "#edf8f2" }}>
                <div className="stock__line">
                  <div className="stock__details">
                    <div className="stock__category">
                      { t('Due Diligence Act (Sorgfaltspflicht Akt)') }
                    </div>
                    <div className="stock__indicator">
                      <span>
                        {t('KYC ID')}:
                        {' '}
                        {userKyc.id}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sidebar__menu">
                {customerType === CUSTOMER_TYPES.INDIVIDUAL && (
                  <>
                    {renderOnfidoReportsNav(completedCheck)}
                  </>
                )}
              </div>

              <div className="sidebar__foot popup__drawer-info">
                <div className="help__head">
                  <Icon
                    className={classNames('icon-help')}
                    name="icon-help"
                    size="24"
                  />{" "}
                  { t('Main KYC info') }
                </div>

                <div className="review">
                  <div className="review__list">
                    <div className="review__item">
                      <div className="review__details">
                        <div className="review__line">
                          <div className="review__login">
                            { t('Submitted By') }
                          </div>
                        </div>

                        <div className="review__product">
                          {' '}
                        </div>

                        { customerType === CUSTOMER_TYPES.INDIVIDUAL ? (
                          <div className="review__content">
                            {`${userKyc.first_name} ${userKyc.last_name}`}
                          </div>
                        ) : (
                          <div className="review__content">
                            {`${userKyc.user.first_name} ${userKyc.user.last_name}`}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="review__item">
                      <div className="review__details">
                        <div className="review__line">
                          <div className="review__login">
                            { t('User account email') }
                          </div>
                        </div>

                        <div className="review__product">
                          {' '}
                        </div>

                        <div className="review__content">
                          {userKyc.email}
                        </div>
                      </div>
                    </div>

                    <div className="review__item">
                      <div className="review__details">
                        <div className="review__line">
                          <div className="review__login">
                            { t('Submitted On') }
                          </div>
                        </div>
                        <div className="review__product">
                          {' '}
                        </div>

                        <div className="review__content">
                          {new Date(userKyc.created_at).toDateString()}
                          -
                          {moment(userKyc.created_at).format("h:mm:ss a")}
                        </div>
                      </div>
                    </div>

                    <div className="review__item">
                      <div className="review__details">
                        <div className="review__line">
                          <div className="review__login">
                            { t('Updated On') }
                          </div>
                        </div>
                        <div className="review__product">
                          {' '}
                        </div>

                        <div className="review__content">
                          {new Date(userKyc.updated_at).toDateString()}
                          -
                          {moment(userKyc.updated_at).format("h:mm:ss a")}
                        </div>
                      </div>
                    </div>

                    <div className="review__item">
                      <div className="review__content">
                        {renderKycStatus(userKyc.status)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="page__inner popup__drawer-inner">
              <div className="page__container">
                <div className="page__title h3">
                  {t('Details')}

                  <span className="page__title-helper">
                    <button type="button" className="button-stroke-red button-xs" onClick={onClickDrawerAway}>
                      {t('Back to list')}
                    </button>
                  </span>
                </div>

                <div className="popup__drawer-rwd__control">
                  <MaterialAccordion className="settings__item settings__item-accordion" style={{ border: "none" }}>
                    <MaterialAccordionSummary
                      className="title-primary settings__title"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="popupdrawer-rwd-content"
                      id="popupdrawer-rwd-header"
                    >
                      <span className="status status-green-dark">
                        {t('KYC ID')}
                        {' '}
                        {userKyc.id}
                      </span>
                    </MaterialAccordionSummary>

                    <MaterialAccordionDetails className="settings__fieldset">
                      <div style={{ display: "block", width: "100%"}}>
                        <div className="card">
                          <div className="card__head">
                            <div className="title-clear card__title">
                              { t('Main KYC info') }
                            </div>
                          </div>

                          <div className="earning__table earning__table-fix__col">
                            <div className="earning__row">{' '}</div>

                            <div className="earning__row">
                              <div className="earning__col">
                                { t('Submitted By') }
                              </div>
                              <div className="earning__col">
                                { customerType === CUSTOMER_TYPES.INDIVIDUAL ? (
                                  <>
                                    {`${userKyc.first_name} ${userKyc.last_name}`}
                                  </>
                                ) : (
                                  <>
                                    {`${userKyc.user.first_name} ${userKyc.user.last_name}`}
                                  </>
                                )}
                              </div>
                            </div>

                            <div className="earning__row">
                              <div className="earning__col">
                                { t('User account email') }
                              </div>

                              <div className="earning__col">
                                {userKyc.email}
                              </div>
                            </div>

                            <div className="earning__row">
                              <div className="earning__col">
                                { t('Submitted On') }
                              </div>

                              <div className="earning__col">
                                {new Date(userKyc.created_at).toDateString()}
                                -
                                {moment(userKyc.created_at).format("h:mm:ss a")}
                              </div>
                            </div>

                            <div className="earning__row">
                              <div className="earning__col">
                                { t('Updated On') }
                              </div>

                              <div className="earning__col">
                                {new Date(userKyc.updated_at).toDateString()}
                                -
                                {moment(userKyc.updated_at).format("h:mm:ss a")}
                              </div>
                            </div>
                            <div className="earning__row">
                              <div className="earning__col">
                                {renderKycStatus(userKyc.status)}
                              </div>
                              <div className="earning__col">{' '}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MaterialAccordionDetails>
                  </MaterialAccordion>

                  <div className="space">{' '}</div>
                </div>
                {openUploadNewPhoto && (
                  <AdminDialogUploadPhoto
                    open={openUploadNewPhoto}
                    userKyc={userKyc}
                    filesSchemas={filesSchemas}
                    onClickDialogAway={onCloseDialogUploadPhoto}
                    onUploadPhoto={onUploadPhoto}
                    fileValues={fileValues}
                    setFileValues={setFileValues}
                    documentTypeValues={documentTypeValues}
                    setDocumentTypeValues={setDocumentTypeValues}
                  />
                )}

                {openUpVideoIdentLog && (
                  <AdminDialogVideoIdentLog
                    open={openUpVideoIdentLog}
                    onClickDialogAway={onCloseDialogVideoIdentLog}
                    user={user}
                  />
                )}

                <ClickAwayListener onClickAway={onActionBtnClickAway}>
                  <div>
                    <Grow in={actionOpen}>
                      <Paper className={classes.actionBtnPaper}>
                        <div
                          onClick={onChangeStatus(
                            userKyc.id,
                            CUSTOMER_STATUS_TYPES.APPROVED
                          )}
                        >
                          <CheckOutlinedIcon />
                          { t('Approve') }
                        </div>
                        <div
                          onClick={onChangeStatus(
                            userKyc.id,
                            CUSTOMER_STATUS_TYPES.REJECTED
                          )}
                        >
                          <ClearOutlinedIcon />
                          { t('Decline') }
                        </div>
                        {userKyc.status === CUSTOMER_STATUS_TYPES.PENDING && (
                          <div
                            onClick={onChangeStatus(
                              userKyc.id,
                              CUSTOMER_STATUS_TYPES.REOPEN
                            )}
                          >
                            <RefreshIcon />
                            {CUSTOMER_STATUS_TYPES.REOPEN.toLowerCase()}
                          </div>
                        )}
                        {renderChangeVideoIdentBtn()}
                      </Paper>
                    </Grow>
                  </div>
                </ClickAwayListener>

                <div className="page__row">
                  <div className="page__col">
                    <div className="card">
                      <div className="popup__drawer-nav tablet-hide">
                        {drawerNav.map((x, index) => (
                          <button
                            className={`sidebar__link ${index === activeDrawerIndex ? "active" : ""}`}
                            key={index}
                            onClick={() => handleDrawerNavClick(x.title, index)}
                          >
                            {x.icon}
                            {x.title}
                          </button>
                        ))}
                      </div>

                      <div className={`custom-dropdown popup__drawer-nav__mobile tablet-show ${visibleDrawerRWD ? "dropdown__active" : ""}`}>
                        <OutsideClickHandler onOutsideClick={() => setVisibleDrawerRWD(false)}>
                          <div className="custom-dropdown__wrap">
                            <div
                              className="select select_small custom-dropdown__head"
                              onClick={() => setVisibleDrawerRWD(!visibleDrawerRWD)}
                            >
                              <div className="dropdown__selection current">
                                {activeDrawerNav}
                              </div>

                              <div className="dropdown__body list">
                                {drawerNav.map((x, index) => (
                                  <div
                                    className={`dropdown__option option ${index === activeDrawerIndex ? "selected" : ""}`}
                                    key={index}
                                    onClick={() => handleDrawerRWDClick(x.title, index)}
                                  >
                                    {x.title}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </OutsideClickHandler>
                      </div>
                    </div>

                    {customerType === CUSTOMER_TYPES.INDIVIDUAL && (
                      <AdminKycAutoApprovalLog customerId={userKyc.id} publicSettings={publicSettings} />
                    )}

                    {activeDrawerNav === drawerNav[0].title && renderDrawerTabWallet()}
                    {activeDrawerNav === drawerNav[1].title && renderDrawerTabReports()}
                    {activeDrawerNav === drawerNav[2].title && renderDrawerTabAllRisks()}
                    {activeDrawerNav === drawerNav[3].title && renderDrawerTabTransaction()}

                    {customerType === CUSTOMER_TYPES.INDIVIDUAL && (
                      <>
                        {activeDrawerNav === drawerNav[4].title && renderDrawerTabWatchlist()}
                        {activeDrawerNav === drawerNav[5].title && renderDrawerTabFacematch()}
                      </>
                    )}

                    {customerType === CUSTOMER_TYPES.COMPANY && (
                      <>
                        {activeDrawerNav === drawerNav[4].title && renderDrawerCompanyTabDocument()}
                        {activeDrawerNav === drawerNav[5].title && renderDrawerCompanyTabDirector()}
                        {activeDrawerNav === drawerNav[6].title && renderDrawerCompanyTabUBOs()}
                      </>
                    )}

                    {
                      publicSettings &&
                      publicSettings.token_type === TOKEN_TYPES.LIQUIDATION &&
                      customerType === CUSTOMER_TYPES.INDIVIDUAL && (
                      <div ref={scrollToSectionTransaction}>
                        <AdminLiquidationProofViewer customerId={userKyc.id} />
                        <AdminCompensatableTable user={user} />

                        {userKyc.documentESignature.length > 0 && userKyc.documentESignature.map((doc) => (
                          <div className="card" key={doc.id}>
                            <div className="settings__fieldset">
                              <div className="field__wrap shop shop__products">
                                <div className="summary summary__document summary__fullwidth">
                                  <div className="summary__preview">
                                    <div className="summary__preview_aspect_ratio">
                                      <embed
                                        src={getExternalFileUrl(`signnow/${doc.remote_id}`)}
                                        width="700"
                                        height="700"
                                        type="application/pdf"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        {userKyc.skribbleSignatureRequest && (
                          <div className="card">
                            <div className="settings__fieldset">
                              <div className="field__wrap shop shop__products">
                                <div className="summary summary__document summary__fullwidth">
                                  <div className="summary__preview">
                                    <div className="summary__preview_aspect_ratio">
                                      <embed
                                        src={getExternalFileUrl(`skribble_${userKyc.skribbleSignatureRequest.documentId}`)}
                                        width="700"
                                        height="700"
                                        type="application/pdf"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>{/* end page__col */}

                  <div className="page__col">

                    <div className="card">
                      <div className="card__head">
                        <div className="title-clear card__title">
                          {t('Location(s)')}
                        </div>
                      </div>

                      <>
                        {(!userKyc.lastLoginHistory || !userKyc.lastLoginHistory.lng || !userKyc.lastLoginHistory.lat) && (
                          <div className="packages__content nospace__bottom">
                            {t('No information')}
                          </div>
                        )}
                        {userKyc.lastLoginHistory && userKyc.lastLoginHistory.lng && userKyc.lastLoginHistory.lat && (
                          <div className="field field-iframe">
                            <LocationMap
                              markers={[userKyc.lastLoginHistory]}
                            />
                          </div>
                        )}
                      </>
                    </div>

                    <div className="card">
                      <div className="card__head">
                        <div className="title-clear card__title">
                          {t('Device(s)')}
                        </div>
                      </div>

                      { !userAgent && (
                        <div className="packages__content nospace__bottom">
                          {t('No information')}
                        </div>
                      )}
                      { userAgent && (
                        <div className="field">
                          <div className="tips__list tips__list-clear">
                            <div className="tips__item fullwidth nohover">
                              <div className="tips__icon">
                                <Icon
                                  className={classNames('info-stroke')}
                                  name='info-stroke'
                                  size='24'
                                />
                              </div>
                              <div className="tips__details">
                                <div className="tips__title">
                                  {getDevice(userAgent)}
                                </div>

                                <div className="tips__line">
                                  <div className="review__login" style={{marginLeft: '0'}}>
                                    {moment(userKyc.lastLoginHistory.created_at).format('MMM D, YYYY, h:mmA UTCZZ')}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="card">
                      <div className="card__head">
                        <div className="title-clear card__title">
                          { t('Comments')}
                        </div>
                      </div>

                      <div className="comments__wrapper">
                        <AdminComment
                          user={user}
                          type={COMMENT_TYPES[`KYC_${customerType}`]}
                          id={userKyc.id}
                        />
                        {isAllRequiredFeaturesEnabled(['LIVENESS_CHECK_EXTRACT_AND_COMPARE'], availableFeatures) || <FeatureNotAvailableMessage />}
                      </div>
                    </div>

                    <div className="card">
                      <div className="card__head">
                        <div className="title-clear card__title">
                          { t('Spot Check')}
                        </div>
                      </div>

                      <>
                        {!userKyc.spotCheckRequest && (
                          <span className="status-red">{t('Can\'t find spot request check')}</span>
                        )}
                        {userKyc.spotCheckRequest && userKyc.spotCheckRequest.status === 'CHECKED' && (
                          <div>
                            <strong>{t('Status')}: </strong>
                            <span className="status-green-dark">{t('Spot check completed')}</span>
                          </div>
                        )}
                        {userKyc.spotCheckRequest && userKyc.spotCheckRequest.status === 'NOT_CHECKED' && (
                          <div>
                            <strong>{t('Status')}: </strong>
                            <span className="status-red">{t('Spot check awaiting')}</span>
                          </div>
                        )}
                        <br />
                        {confirmSpotCheckCompletedStatus.loading && <CircularProgress />}
                        {userKyc.spotCheckRequest && userKyc.spotCheckRequest.status === 'NOT_CHECKED' && !confirmSpotCheckCompletedStatus.loading &&
                          me && hasUserEnoughRights(
                            me.rights,
                            USER_RIGHT_TYPES.COMPLIANCE_OFFICER
                          ) && (
                          <button
                            type="button"
                            className="button button-small"
                            onClick={sendRequestConfirmSpotCheckCompleted}
                          >
                            {t('Confirm complete spot check')}
                          </button>
                        )}
                      </>
                    </div>
                  </div>{/* end page__col */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

AdminKycDrawer.propTypes = {
  user: PropTypes.object.isRequired,
  userKyc: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClickDrawerAway: PropTypes.func.isRequired,
  mainScheme: PropTypes.object.isRequired,
  addressScheme: PropTypes.object,
  filesScheme: PropTypes.object,
  onChangeStatus: PropTypes.func.isRequired,
};

export default AdminKycDrawer;
