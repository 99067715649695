import React from 'react'
import { usePublicSettings } from '../myHooks/useSettings'
import { useTranslation } from 'react-i18next'
import SidebarOuter from '../components/SidebarOuter'
import { isIE } from "react-device-detect"
import NotSupportViewer from '../components/NotSupportViewer'

import '../styles/app.min.css'
import '../styles/style.css'

const DeclarationOfConsent = () => {
  const { data: { publicSettings: { company = {} } = {} } = {} } = usePublicSettings();
  const { t } = useTranslation();

  if (isIE) {
    return <NotSupportViewer />;
  }

  return (
    <div className="page pt-0">
      <SidebarOuter />

      <div className="page__inner">
        <div className="page__container">
          <div className="product__wrapper product__wrapper_v2">
            <div className="product__details">
              <div className="produt__overview">
                <div className="product__title h4">
                  { t('Declaration of Consent') }
                </div>
              </div>

              <div className="product__row">
                <div className="product__col pr__0">
                  <div className="product__content">
                    <p>
                      { t('I hereby consent I am aware that the submission of this Declaration of Consent is voluntary and has no impact on the performance of the intended purpose of the contract.') }
                    </p>

                    <p> { t('If you no longer wish to receive advertising in the future, you can use your right to object to the use of your data for advertising purposes and modify or revoke the given Declaration of Consent at any time without stating any reasons with effect for the future. You can submit the withdrawal either by post, by email or by fax. You will incur no other costs than the postage costs or the transmission costs according to the existing base rates. The withdrawal is to be addressed to') }: </p>
                    <p>
                      {(company || {}).name} <br />
                      {(company || {}).address} <br />
                      {(company || {}).email} <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeclarationOfConsent;
