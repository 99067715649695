import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'

import Web3 from 'web3'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
  MenuItem,
} from '@material-ui/core'
import metamaskService from '../../../services/metamask'
import InputTheme from '../../../components/InputTheme'
import RadioTheme from '../../../components/Radio'
import DropdownMaterial from '../../../components/DropdownMaterial'
import InputThemeField from '../../../components/InputThemeField'
import {
  DEPLOY_CONTRACT,
  GRANT_CONTROLLER_ROLE,
  DEPLOY_CONTRACT_RESULT,
} from '../../../queriesAndMutations'
import { toaster } from '../../../utils'

const AdminSettingsSmartContract = ({
  settings,
  loading,
  error,
  onUpdate,
  editingAllowed,
  refetch
}) => {
  const { t } = useTranslation();

  const defaultNewContract = {
    token_name: '',
    token_symbol: '',
    network: '',
    owner_address: '',
    mnemonic: '',
    decimals: '',
  }

  const [newContract, setNewContract] = useState({ ...defaultNewContract })
  const [contractInfo, setContractInfo] = useState(null)
  const [values, setValues] = useState({
    enable_smart_contract: settings.enable_smart_contract || false,
    smart_contract_type: settings.smart_contract_type || '',
    smart_contract_address: settings.smart_contract_address || '',
    smart_contract_network: settings.smart_contract_network || '',
    smart_contract_whitelist_address: settings.smart_contract_whitelist_address || '',
    smart_contract_dai_address: settings.smart_contract_dai_address || '',
    smart_contract_usdc_address: settings.smart_contract_usdc_address || '',
    smart_contract_usdt_address: settings.smart_contract_usdt_address || '',
    smart_contract_eure_address: settings.smart_contract_eure_address || '',
    smart_contract_busd_address: settings.smart_contract_busd_address || '',
    smart_contract_owner_mnemonic: settings.smart_contract_owner_mnemonic || '',
  });
  const [deployContract, deployContractStatus] = useMutation(DEPLOY_CONTRACT)
  const [deployContractResult, deployContractResultStatus] = useMutation(DEPLOY_CONTRACT_RESULT)
  const [grantControllerRole, grantControllerRoleStatus] = useMutation(GRANT_CONTROLLER_ROLE)
  const [deployContractLoading, setDeployContractLoading] = useState(false)

  const isEnabled = values.enable_smart_contract;

  const updateNewContract = (field) => (e) => {
    setNewContract({
      ...newContract,
      [field]: field === 'decimals' ? parseInt(e.target.value, 10) : e.target.value,
    })
  }

  const onUpdateBtnClick = () => {
    onUpdate(values)
  }

  const getContractDetail = async (contractType, rawContractAddress) => {
    let contractAddress = metamaskService.formatAddress(rawContractAddress)
    if (Web3.utils.isAddress(contractAddress) && metamaskService.isMetamaskAvailable()) {
      if (contractType === 'crowdsale_contract') {
        try {
          contractAddress = await metamaskService.getTokenAddressOfCrowldsaleContract(contractAddress)
        } catch (error) {
          console.log(error)
          setContractInfo(null)

          return
        }
      }

      Promise.all([
        metamaskService.getContractDecimal(contractAddress),
        metamaskService.getTokenSymbol(contractAddress),
      ]).then(([decimal, symbol]) => {
        setContractInfo({
          symbol,
          decimal,
        })
      }).catch(() => {
        setContractInfo(null)
      })
    } else {
      setContractInfo(null)
    }
  }

  const onContractAddressChange = (newState) => {
    setValues(newState);
    getContractDetail(newState.smart_contract_type, newState.smart_contract_address)
  }

  useEffect(() => {
    if (settings.smart_contract_address) {
      getContractDetail(settings.smart_contract_type, settings.smart_contract_address)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onGrantControllerRole = () => {
    setDeployContractLoading(false)
    grantControllerRole({
      variables: {
        input: {
          controller_address: newContract.owner_address
        }
      },
    }).then((response) => {
      if (response.data.grantControllerRole.success) {
        refetch()
        toaster.success(t('Grant Controller Role successfully'))
        setNewContract({ ...defaultNewContract })
      } else {
        if (response.data.grantControllerRole.message === 'nonce too low') {
          setDeployContractLoading(true)
          setTimeout(onGrantControllerRole, 30000)
        } else {
          toaster.error(response.data.grantControllerRole.message || t('Grant Controller Role failed'))
          setNewContract({ ...defaultNewContract })
        }
      }
    }).catch((error) => {
      console.log(error)
      toaster.error(t('Grant Controller Role failed'))
      setNewContract({ ...defaultNewContract })
    })
  }

  const onDeployContractResult = () => {
    deployContractResult({
      variables: {
        input: {
          token_name: newContract.token_name,
        },
      },
    }).then((response) => {
      const res = response.data.deployContractResult;
      console.log("res", res);

      if (res.success) {
        if (res.result.status === 'success') {
          refetch()
          toaster.success(t('Contract deploy successfully'))
          setDeployContractLoading(false)
          onGrantControllerRole()
        } else if (res.result.status === 'failed') {
          setDeployContractLoading(false)
          toaster.error(res.message || t('Contract deploy failed'))
        } else {
          setTimeout(onDeployContractResult, 30000)
        }
      } else {
        setDeployContractLoading(false)
        toaster.error(res.message || t('Contract deploy failed'))
      }
    }).catch((error) => {
      console.log(error)
      setDeployContractLoading(false)
      toaster.error(t('Contract deploy failed'))
    })
  }

  const onDeployContract = () => {
    deployContract({
      variables: {
        input: newContract,
      },
    }).then((response) => {
      if (response.data.deployContract.success) {
        setDeployContractLoading(true)
        setTimeout(onDeployContractResult, 120000)
      } else {
        toaster.error(response.data.deployContract.message || t('Contract deploy failed'))
      }
    }).catch((error) => {
      console.log(error)
      toaster.error(t('Contract deploy failed'))
    })
  }

  return (
    <>
      <div className="settings__item">
        <div className="title-purple settings__title">{ t('Smart Contract') }</div>
        <div className="settings__fieldset">
          <div className="field create__line">
            <div className="create__info">{ t('Enable Smart Contract') }</div>

            <label className="switch">
              <input
                type="checkbox"
                className="switch__input"
                checked={values.enable_smart_contract}
                disabled={!editingAllowed}
                onChange={({ target: { checked } }) => setValues({...values, enable_smart_contract: checked})}
                value="enable_smart_contract"
              />

              <div className="switch__inner">
                <div className="switch__box">{' '}</div>
              </div>
            </label>
          </div>

          <DropdownMaterial
            label={t('Network')}
            propertyName="smart_contract_network"
            state={values}
            setState={setValues}
            isGray
          >
            <MenuItem value="polygon">Polygon</MenuItem>
            <MenuItem value="mainnet">Ethereum</MenuItem>
            <MenuItem value="xinfin">Xinfin</MenuItem>
          </DropdownMaterial>

          <div className="field">
            <div className="field__label">{ t('Contract type') }</div>

            <div className="field__wrap checkbox__wrapper field__wrap checkbox__wrapper d__flex flex__row flex__wrap">
              <RadioTheme
                disabled={!editingAllowed || !isEnabled}
                isGrayText
                isRadioGroupHorizontal
                value="token_contract"
                radioText={t('Token Contract')}
                defaultChecked={(values || {}).smart_contract_type === 'token_contract'}
                onChange={({ target: { value } }) => {
                  setValues({ ...values, smart_contract_type: value })
                  getContractDetail(value, values.smart_contract_address)
                }}
              />

              <RadioTheme
                disabled={!editingAllowed || !isEnabled}
                isGrayText
                isRadioGroupHorizontal
                value="crowdsale_contract"
                radioText={t('Crowdsale Contract')}
                defaultChecked={(values || {}).smart_contract_type === 'crowdsale_contract'}
                onChange={({ target: { value } }) => {
                  setValues({ ...values, smart_contract_type: value })
                  getContractDetail(value, values.smart_contract_address)
                }}
              />
            </div>
          </div>

          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="smart_contract_owner_mnemonic"
            label="Contract owner mnemonic"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed || !isEnabled}
            loading={loading}
          />

          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="smart_contract_address"
            label="Contract address"
            state={values}
            setState={onContractAddressChange}
            error={error}
            disabled={!editingAllowed || !isEnabled}
            loading={loading}
            helperText={contractInfo ? `Symbol: ${contractInfo.symbol}. Decimal: ${contractInfo.decimal}.` : ''}
          />

          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="smart_contract_whitelist_address"
            label="Whitelist contract address"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed || !isEnabled}
            loading={loading}
            helperText="The contract is using for adding address to whitelisted"
          />

          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="smart_contract_dai_address"
            label="DAI contract address"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed || !isEnabled}
            loading={loading}
            helperText="Use live DAI contract address for this field on production"
          />

          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="smart_contract_usdc_address"
            label="USDC contract address"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed || !isEnabled}
            loading={loading}
            helperText="Use live USDC contract address for this field on production"
          />

          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="smart_contract_usdt_address"
            label="USDT contract address"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed || !isEnabled}
            loading={loading}
            helperText="Use live USDT contract address for this field on production"
          />

          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="smart_contract_eure_address"
            label="EURe contract address"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed || !isEnabled}
            loading={loading}
            helperText="Use live EURe contract address for this field on production"
          />

          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="smart_contract_busd_address"
            label="BUSD contract address"
            state={values}
            setState={setValues}
            error={error}
            disabled={!editingAllowed || !isEnabled}
            loading={loading}
            helperText="Use live BUSD contract address for this field on production"
          />
        </div>

        <br />

        {loading ? (
          <div className="settings__item">
            <CircularProgress />
          </div>
        ) : (
          <button
            className="button settings__button button-small"
            disabled={!editingAllowed}
            onClick={onUpdateBtnClick}
          >
            { t('Update') }
          </button>
        )}
      </div>

      <div className="settings__item">
        <div className="title-primary settings__title">
          { t('Deploy new contract') }
          {' '}
        </div>

        <div className="settings__fieldset">
          {deployContractStatus.loading || deployContractResultStatus.loading || deployContractLoading || grantControllerRoleStatus.loading ? (
            grantControllerRoleStatus.loading ? (
              <>
                <p> {t('Grant Controller Role processing')} </p>
                <CircularProgress />
              </>
            ) : <CircularProgress />
          ) : (
            <>
              <div className="settings__row flex__row flex__wrap have__fields have__space">
                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  label={ t('Token name') }
                  value={newContract.token_name}
                  onChange={updateNewContract('token_name')}
                />

                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  label={ t('Token symbol') }
                  value={newContract.token_symbol}
                  onChange={updateNewContract('token_symbol')}
                />

                <DropdownMaterial
                  propertyName='network'
                  label={ t('Network') }
                  state={newContract}
                  setState={setNewContract}
                >
                  <MenuItem value="polygon">Polygon</MenuItem>
                  <MenuItem value="mainnet">Ethereum</MenuItem>
                  <MenuItem value="xinfin">Xinfin</MenuItem>
                </DropdownMaterial>

                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  label={ t('Owner Address') }
                  value={newContract.owner_address}
                  onChange={updateNewContract('owner_address')}
                />

                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  label={ t('Mnemonic') }
                  value={newContract.mnemonic}
                  onChange={updateNewContract('mnemonic')}
                />

                <InputThemeField
                  type="number"
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  label={ t('Decimals') }
                  value={newContract.decimals}
                  onChange={updateNewContract('decimals')}
                />
              </div>

              <div className="field">
                <div className="field__wrapper">
                  <button
                    className="button button-stroke settings__button"
                    onClick={onDeployContract}
                  >
                    { t('Deploy') }
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default AdminSettingsSmartContract
