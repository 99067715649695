import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { ADMIN_KYC_COUNT } from '../../../queriesAndMutations'
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";

const CONST_TYPE_TOTAL = {
  USERS: 'USERS',
  REFERRALS: 'REFERRALS',
  KYC: 'KYC',
}

const CONST_TYPE_TOTAL_LABEL = {
  USERS: 'All active organic users',
  REFERRALS: 'All actice referral users',
  KYC: 'Total submitted KYC',
}

const AdminUsersAndKycStatistics = () => {
  const { t } = useTranslation()
  const { data: { kycCount } = {} } = useQuery(ADMIN_KYC_COUNT)

  const dataPie = [
    { name: CONST_TYPE_TOTAL_LABEL.USERS, value: (kycCount && kycCount.organicCount) || 0 },
    { name: CONST_TYPE_TOTAL_LABEL.REFERRALS, value: (kycCount && kycCount.referralCount) || 0 },
    { name: CONST_TYPE_TOTAL_LABEL.KYC, value: (kycCount && kycCount.kycCount) || 0 },
  ];
  const COLORS = ["#2A85FF", "#8E59FF", "#83BF6E"];

  return (
    <div className="card">
      <div className="card__head">
        <div className="title-primary card__title">
          { t('Total users Kyc') }
        </div>
      </div>

      <div className="device">
        <ResponsiveContainer width="99%" height={248}>
          <PieChart width={400} height={400}>
            <Tooltip
              contentStyle={{
                backgroundColor: "#272B30",
                borderColor: "rgba(255, 255, 255, 0.12)",
                borderRadius: 8,
                boxShadow:
                  "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
              }}
              labelStyle={{ fontSize: 12, fontWeight: "500", color: "#fff" }}
              itemStyle={{
                padding: 0,
                textTransform: "capitalize",
                fontSize: 12,
                fontWeight: "600",
                color: "#fff",
              }}
            />
            <Pie
              data={dataPie}
              cx={140}
              cy={110}
              innerRadius={88}
              outerRadius={110}
              fill="#8884d8"
              paddingAngle={1}
              dataKey="value"
            >
              {dataPie.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>

        <div className="card__legend">
          <div className="card__indicator">
            <div className="card__color" style={{backgroundColor: COLORS[0] }}>{' '}</div>
            {CONST_TYPE_TOTAL.USERS}
          </div>

          <div className="card__indicator">
            <div className="card__color" style={{backgroundColor: COLORS[1] }}>{' '}</div>
            {CONST_TYPE_TOTAL.REFERRALS}
          </div>

          <div className="card__indicator">
            <div className="card__color" style={{backgroundColor: COLORS[2] }}>{' '}</div>
            {CONST_TYPE_TOTAL.KYC}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminUsersAndKycStatistics
