import React from 'react'
import moment from 'moment-timezone'
import { useQuery } from '@apollo/react-hooks'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { GET_OWN_KYB_LOG_EVENT } from '../queriesAndMutations'
import { ReactComponent as StatusSuccessSVG } from '../media/icons/status-success.svg';
import { ReactComponent as StatusIncompleteSVG } from '../media/icons/status-incomplete.svg';
import { ReactComponent as StatusFailedSVG } from '../media/icons/status-failed.svg';

const KybStatusLog = () => {
  const { t } = useTranslation()
  const { data: { getOwnKybLogEvent: data } = {}, loading } = useQuery(GET_OWN_KYB_LOG_EVENT)

  if (loading) {
    return (
      <div className="text-center">
        <CircularProgress />
      </div>
    )
  }

  if (!data) {
    return <p className="text-center">{t('No application has been submit')}</p>
  }

  return (
    <div className="entry__text entry__text-onboarding">
      <div className="sidebar__body">
        {data.map((log) => {
          const date = moment(+log.created_at).tz('Europe/Vaduz').format('DD-MM-YYYY HH:mm z')

          if (log.log_type === 'CUSTOMER_COMPANY_STATUS_CHANGE' && log.status_type_before === 'NONE' && log.status_type_after === 'PENDING') {
            return (
              <div className="sidebar__link sidebar__link-normal color" key={log.id}>
                <StatusSuccessSVG />
                <span className="sidebar__link-text">
                  { t('KYB submitted') } ({date})
                </span>
              </div>
            )
          }

          if (log.status_type_after === 'APPROVED') {
            return (
              <div className="sidebar__link sidebar__link-normal color" key={log.id}>
                <StatusSuccessSVG />
                <span className="sidebar__link-text">
                  {t('KYB approved')}
                  {log.comment ? `: ${log.comment} ` : ' '}
                  ({date})
                </span>
              </div>
            )
          }
          if (log.status_type_after === 'KYC_LVL_CHANGE') {
            return (
              <div className="sidebar__link sidebar__link-normal color" key={log.id}>
                <StatusIncompleteSVG />
                <span className="sidebar__link-text">
                  {log.comment}
                  {' '}
                  ({date})
                </span>
              </div>
            )
          }
          if (log.status_type_before === 'REOPEN' && log.status_type_after === 'PENDING') {
            return (
              <div className="sidebar__link sidebar__link-normal color" key={log.id}>
                <StatusIncompleteSVG />
                <span className="sidebar__link-text">
                  {t('KYB resubmitted')} ({date})
                </span>
              </div>
            )
          }
          if (log.status_type_after === 'REOPEN') {
            return (
              <div className="sidebar__link sidebar__link-normal color" key={log.id}>
                <StatusIncompleteSVG />
                <span className="sidebar__link-text">
                  {log.director_id ? t('Director profile of {{email}} reopened', { email: log.customerCompanyDirector.email}) : t('KYB reopened')} ({date})
                </span>
              </div>
            )
          }
          if (log.status_type_after === 'REOPEN_WITH_VIDEOIDENT') {
            return (
              <div className="sidebar__link sidebar__link-normal color" key={log.id}>
                <StatusIncompleteSVG />
                <span className="sidebar__link-text">
                  {log.director_id ? t('Director profile of {{email}} reopened livenes check', { email: log.customerCompanyDirector.email}) : t('KYB reopened livenes check')} ({date})
                </span>
              </div>
            )
          }

          if (log.id.match(/^STATIC_REJECT/)) {
            return (
              <div className="sidebar__link sidebar__link-normal color" key={log.id}>
                <StatusFailedSVG />
                <span className="sidebar__link-text">
                  {log.comment} ({date})
                </span>
              </div>
            )
          }

          if (log.id.match(/^STATIC_PENDING/)) {
            return (
              <div className="sidebar__link sidebar__link-normal color" key={log.id}>
                <StatusIncompleteSVG />
                <span className="sidebar__link-text">
                  {log.comment}
                </span>
              </div>
            )
          }

          if (log.id.match(/^STATIC_SUCCESS/)) {
            return (
              <div className="sidebar__link sidebar__link-normal color" key={log.id}>
                <StatusSuccessSVG />
                <span className="sidebar__link-text">
                  {log.comment} ({date})
                </span>
              </div>
            )
          }

          return (
            <div className="sidebar__link sidebar__link-normal color" key={log.id}>
              <StatusSuccessSVG />
              <span className="sidebar__link-text">
                {log.director_id
                  ? t('Director profile of {{email}} {{status}}', { email: log.customerCompanyDirector.email, status: log.status_type_after.toLowerCase()})
                  : `KYB ${log.status_type_after.toLowerCase()}`} ({date})
              </span>
            </div>
          )
        })}
      </div>

      <div className="space space__24">{' '}</div>
    </div>
  )
}

export default KybStatusLog
