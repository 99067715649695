import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { groupBy, filter } from 'lodash'
// import { NavLink } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import {
  CircularProgress
} from '@material-ui/core'
// import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { isProduction } from '../utils'
import {
  GET_MONERIUM_AUTH_TOKEN,
} from '../queriesAndMutations'
import MoneriumAccount from '../components/MoneriumAccount'
import ShortenText from '../components/ShortenText'

// const useStyles = makeStyles((theme) => {
//   return {
//     cardGroup: {
//       flex: "0 0 calc(100% - 32px)",
//       maxWidth: "calc(100% - 32px)",
//       margin: "32px 16px 0",
//     },
//     cards: {
//       display: 'flex',
//       flexWrap: 'wrap',
//     },
//     card: {
//       flex: "0 0 calc(50% - 32px)",
//       maxWidth: "calc(50% - 32px)",
//       minHeight: "100px",
//       marginRight: "32px",
//       marginBottom: "10px",
//       background: "#3a4759",
//       cursor: 'pointer',
//     },
//     right: {
//       float: "right",
//     },
//   }
// })

const API_URL = isProduction ? 'https://api.monerium.app' : 'https://api.monerium.dev'

const AdminWeb3Iban = () => {
  const { t } = useTranslation()
  const { data, loading: getAuthTokenLoading, refetch } = useQuery(GET_MONERIUM_AUTH_TOKEN)
  const [authToken, setAuthToken] = useState(null)
  const [defaultProfile, setDefaultProfile] = useState(null)
  const [balances, setBalances] = useState(null)
  const [accounts, setAccounts] = useState(null)
  const [currentAccount, setCurrentAccount] = useState(null)
  const [loading, setLoading] = useState(null)

  // const styles = useStyles()

  const getAuthContext = async (token) => {
    try {
      const response = await axios.get(
        `${API_URL}/auth/context`,
        {
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        },
      )

      setDefaultProfile(response.data.defaultProfile)

      return response.data.defaultProfile
    } catch (error) {
      console.log(error)

      return null
    }
  }

  const getProfileBalances = async (profile, token) => {
    try {
      const response = await axios.get(
        `${API_URL}/profiles/${profile}/balances`,
        {
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        },
      )

      const balancesByAddress = groupBy(response.data, 'address')
      setBalances(balancesByAddress)
    } catch (error) {
      console.log(error)
    }
  }

  const getProfileAccounts = async (profile, token) => {
    try {
      const response = await axios.get(
        `${API_URL}/profiles/${profile}`,
        {
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        },
      )

      setAccounts(response.data.accounts)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const getAppData = async (token) => {
      const profile = await getAuthContext(token)

      if (profile) {
        await getProfileBalances(profile, token)
        getProfileAccounts(profile, token)
      }
    }

    if (data && data.getMoneriumAuthToken && data.getMoneriumAuthToken.success) {
      setLoading(true)
      setAuthToken(data.getMoneriumAuthToken.data.access_token)
      getAppData(data.getMoneriumAuthToken.data.access_token)
    }

    // eslint-disable-next-line
  }, [data])

  const onSetCurrentAccount = (account, balance) => {
    console.log('account', { ...account, ...balance })
    setCurrentAccount({ ...account, ...balance })
  }

  if (currentAccount) {
    return <MoneriumAccount authToken={authToken} currentAccount={currentAccount} setCurrentAccount={setCurrentAccount} />
  }

  return (
    <>
      <div className="page__title h3">
        {t('My accounts')}
      </div>

      {(loading || getAuthTokenLoading) && (
        <CircularProgress />
      )}

      {balances && (
        Object.keys(balances).map((address) => {
          const balancesItem = balances[address]
          const accountsByAddress = filter(accounts, (account) => account.address === address)

          return (
            <div className="card" key={address}>
              <div className="card__head">
                <div className="title-clear card__title mobile-hide">
                  {address}
                </div>
                <div className="title-clear card__title mobile-show">
                  <ShortenText text={address} v2 />
                </div>
              </div>

              <div className="earning__table-card__wrapper">
                {accountsByAddress && accountsByAddress.map((account) => {
                  const balancesByAccount = balancesItem.find((b) => b.chain === account.chain && b.network === account.network)

                  if (!balancesByAccount) {
                    return ''
                  }

                  const balance = balancesByAccount.balances.find((b) => b.currency === account.currency)

                  if (!balance) {
                    return ''
                  }

                  return (
                    <div className="earning__table earning__table-card" key={address} onClick={() => onSetCurrentAccount(account, balance)}>
                      <div className="earning__row">{' '}</div>
                      <div className="earning__row">
                        <div className="earning__col">
                          {balance.currency}
                        </div>
                        <div className="earning__col">
                          <div className="earning__label">
                            {balance.currency}
                          </div>
                          {balance.amount}
                        </div>
                      </div>

                      { account.iban && (
                        <div className="earning__row">
                          <div className="earning__col">
                            IBAN
                          </div>
                          <div className="earning__col">
                            <div className="earning__label">
                              IBAN
                            </div>
                            {account.iban}
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })
      )}
    </>
  )
}

export default AdminWeb3Iban
