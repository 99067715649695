import React, { useEffect } from 'react'
import { Redirect, NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next';
import { usePublicSettings } from '../myHooks'

const GET_COMPLYCUBE_INITIAL_DATA = gql`
  query getComplycubeToken($id: ID) {
    getComplycubeToken(id: $id) {
      sdk_token
      user {
        id
        email
        name
        birth_date
        phone
        is_active
        customer {
          id
          nationality
        }
        customerCompany {
          id
        }
      }
      allow_new_check
    }
  }
`;

const SUBMIT_CHECK = gql`
  mutation submitComplycubeCheck($director_id: ID, $report_names: [String]!) {
    submitComplycubeCheck(director_id: $director_id, report_names: $report_names)
  }
`;

const PULL_COMPLYCUBE_DOCUMENT_AND_FACE = gql`
  mutation pullComplycubeDocumentAndFace($director_id: ID, $input: ComplycubeDocumentAndFace) {
    pullComplycubeDocumentAndFace(director_id: $director_id, input: $input)
  }
`;

const KycCustomerVideoIdentComplycube = ({
  object,
  onComplete,
  objectType,
  forceCrossDevice,
}) => {
  const { t } = useTranslation();
  const { data: { publicSettings = {} } = {} } = usePublicSettings();

  if (!object) {
    window.location.href = window.location.origin;
  }

  const { loading, error, data } = useQuery(GET_COMPLYCUBE_INITIAL_DATA, {
    variables: {
      id: objectType === 'director' ? +(object.id) : undefined,
    }
  });
  const [submitCheck] = useMutation(SUBMIT_CHECK);
  const [pullOnfidoDocumentAndFace] = useMutation(
    PULL_COMPLYCUBE_DOCUMENT_AND_FACE
  );

  useEffect(() => {
    if (!publicSettings || !data) {
      return
    }

    const initComplyCube = async (sdk_token) => {
      console.log("initComplyCube")

      window.ComplyCube.mount({
        token: sdk_token,
        stages: [
          'intro',
          {
            name: 'documentCapture',
            options: {
              crossDeviceOnly: forceCrossDevice,
              documentTypes: {
                passport: true,
                national_identity_card: true,
                driving_license: false,
                residence_permit: false,
              }
            },
          },
          {
            name: 'faceCapture',
            options: {
              mode: "video"
            },
          },
          publicSettings.enable_proof_of_residence_checking ? {
            name: "poaCapture",
            options: {
              documentTypes: {
                bank_statement: true,
                utility_bill: true,
                tax_document: true,
              },
            },
          } : {},
          {
            name: 'completion',
            options: {
              message: publicSettings.force_user_till_first_transaction && objectType !== 'director'
                ? [t('Verification Complete. Stay still, you will be redirected to the KYC Status page.')]
                : [t('Verification complete. Stay still, you will be redirected to the dashboard when the process complete.')],
            },
          },
        ],
        onComplete: async function (data) {
          console.log("Capture complete", data)
          try {
            await pullOnfidoDocumentAndFace({
              variables: {
                director_id: objectType === 'director' ? +(object.id) : undefined,
                input: data
              },
            })

            const reportNames = [
              "document_check",
              "enhanced_identity_check",
            ]

            if (publicSettings.enable_proof_of_residence_checking) {
              reportNames.push('proof_of_address_check')
            }

            await submitCheck({
              variables: {
                director_id: objectType === 'director' ? +(object.id) : undefined,
                report_names: reportNames
              }
            })

            if (onComplete) {
              onComplete()
            }
          } catch (error) {
            console.log(error)
          }
        },
        onModalClose: function () {
          window.location.href = window.location.origin;
        },
      });
    };

    if (
      data &&
      data.getComplycubeToken &&
      data.getComplycubeToken.sdk_token &&
      data.getComplycubeToken.allow_new_check
    ) {
      initComplyCube(data.getComplycubeToken.sdk_token);
    }

    // eslint-disable-next-line
  }, [data, publicSettings]);

  if (loading) return <></>;
  if (error) return <Redirect to="/" />;
  if (!data || !data.getComplycubeToken.allow_new_check) return <></>;

  return (
    <div className="card card__onfido">
      <div id="complycube-mount" className="onfido-mount" />
      <br/>
    </div>
  )
}

KycCustomerVideoIdentComplycube.propTypes = {
  object: PropTypes.object.isRequired,
  onComplete: PropTypes.func,
  objectType: PropTypes.string,
  forceCrossDevice: PropTypes.bool,
}

export default KycCustomerVideoIdentComplycube
