import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'

import {
  MAIN_INFO_SCHEMAS,
  ADDRESS_INFO_SCHEMAS,
  INDIVIDUAL_FILES_SCHEMAS,
  INDIVIDUAL_DOCUMENT_TYPE_ENUM,
} from '../../../schemas/kycVerification'
import {
  REGISTRATION_INDIVIDUAL_CUSTOMER,
  HAS_LIQUIDATION_PROOF_UPLOADED,
  ADD_LIQUIDATION_PROOF,
} from '../../../queriesAndMutations'
import ImageUpload from '../../../components/ImageUpload'
import KycVerificationForm from '../../KycVerification/KycVerificationForm'

const TransferClaimCreditCard = () => {
  const { t } = useTranslation()
  const [step, setStep] = useState(0)
  const [proofOfPayment, setProofOfPayment] = useState([])

  const { data: { hasLiquidationProofUploaded } = {}, loading, refetch } = useQuery(HAS_LIQUIDATION_PROOF_UPLOADED, {
    variables: { type: 'file' }
  })
  const [registerIndividualCustomer, registerIndividualCustomerData] = useMutation(REGISTRATION_INDIVIDUAL_CUSTOMER)
  const [addLiquidationProof, addLiquidationProofStatus] = useMutation(ADD_LIQUIDATION_PROOF)

  if (loading) {
    return <CircularProgress />
  }

  const uploadProof = () => {
    addLiquidationProof({
      variables: {
        input: {
          type: 'file',
          files: proofOfPayment.map(proof => proof.img)
        }
      }
    }).then(() => {
      refetch()
    }).catch(() => {})
  }

  if (!hasLiquidationProofUploaded) {
    return (
      <>
        <div className="h3 nospace__bottom">{ t('Proof of payment') }</div>
        <div className="page__title">{' '}</div>
        <div className="card">
          <div className="field">
            <div className="field__label">
              Please upload supporting documents which could be the bank statement where the charge of “Envion AG BA AR”
              <span className="text__red"> *</span>
            </div>
            <ImageUpload
              buttonText="Choose Document"
              buttonClassName="btn btn-primary"
              onChange={(img, imgURI) => {
                const newImage = {
                  img: img.length > 0 ? img[0] : null,
                  imgURI: imgURI.length > 0 ? imgURI[0] : "",
                };
                setProofOfPayment([...proofOfPayment, newImage]);
              }}
              maxFileSize={50000000}
              withPreview
              singleFile
              defaultImages={[]}
            >
              <div className="summary__title">
                <p>{ t('Drag and drop the document') }</p>
              </div>
            </ImageUpload>
          </div>
          {proofOfPayment.map((file, index) => (
            <React.Fragment key={index}>
              <div className="space space__32">{' '}</div>
              <div className="settings__top">
                <div className="settings__label">
                  {(file && file.img && file.img.name) || 'Cannot load file'}
                </div>

                <button
                  className="button-stroke button-small settings__button"
                  onClick={(e) => {
                    const newProof = [...proofOfPayment];

                    newProof.splice(index, 1);
                    setProofOfPayment(newProof)
                  }}
                >
                  { t('Remove') }
                </button>
              </div>
            </React.Fragment>
          ))}

          {addLiquidationProofStatus.loading && <CircularProgress />}
          {!addLiquidationProofStatus.loading && (
            <div className="field">
              <button className="button" onClick={uploadProof}>Upload</button>
            </div>
          )}
        </div>
      </>
    )
  }

  return (
    <KycVerificationForm
      mainInfoSchemas={MAIN_INFO_SCHEMAS}
      addressInfoSchemas={ADDRESS_INFO_SCHEMAS}
      filesSchemas={INDIVIDUAL_FILES_SCHEMAS}
      documentTypeEnum={INDIVIDUAL_DOCUMENT_TYPE_ENUM}
      kycType="INDIVIDUAL"
      registerCustomer={registerIndividualCustomer}
      registerCustomerData={registerIndividualCustomerData}
      kycFormStepNumber={step}
      setKycFormStepNumber={setStep}
    />
  )
}

export default TransferClaimCreditCard
