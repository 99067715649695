// eslint-disable-next-line import/prefer-default-export
export const VIDEO_ID_TYPES = [
  { "id":-1, "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":-2, "type":"IdCard", "name":"Identity Card", "icao":"TD2", "scanWidth":640 },
  { "id":-3, "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":1, "country":"AFG", "countryName":"Afghanistan", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":2, "country":"AGO", "countryName":"Angola", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":4, "country":"ALB", "countryName":"Albania", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":5, "country":"AND", "countryName":"Andorra", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":6, "country":"ARE", "countryName":"United Arab Emirates", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":8, "country":"ARG", "countryName":"Argentina", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":9, "country":"ARM", "countryName":"Armenia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":10, "country":"AUS", "countryName":"Australia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":11, "country":"AUT", "countryName":"Austria", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":12, "country":"AUT", "countryName":"Austria", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":13, "country":"AZE", "countryName":"Azerbaijan", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":14, "country":"BDI", "countryName":"Burundi", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":15, "country":"BEL", "countryName":"Belgium", "type":"IdCard", "name":"Identity Card - TD1", "icao":"TD1", "scanWidth":480 },
  { "id":16, "country":"BEL", "countryName":"Belgium", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":17, "country":"BEN", "countryName":"Benin", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":18, "country":"BGD", "countryName":"Bangladesh", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":19, "country":"BGR", "countryName":"Bulgaria", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":20, "country":"BGR", "countryName":"Bulgaria", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":21, "country":"BHR", "countryName":"Bahrain", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":22, "country":"BHR", "countryName":"Bahrain", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":24, "country":"BIH", "countryName":"Bosnia and Herzegovina", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":25, "country":"BLR", "countryName":"Belarus", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":26, "country":"BLZ", "countryName":"Belize", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":27, "country":"BMU", "countryName":"Bermuda", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":28, "country":"BRA", "countryName":"Brazil", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":29, "country":"BRN", "countryName":"Brunei Darussalam", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":30, "country":"BTN", "countryName":"Bhutan", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":31, "country":"BWA", "countryName":"Botswana", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":32, "country":"CAN", "countryName":"Canada", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":33, "country":"CHE", "countryName":"Switzerland", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":34, "country":"CHE", "countryName":"Switzerland", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":640 },
  { "id":35, "country":"CHL", "countryName":"Chile", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":36, "country":"CHL", "countryName":"Chile", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":37, "country":"CHN", "countryName":"China", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":40, "country":"COD", "countryName":"Congo", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":42, "country":"COL", "countryName":"Colombia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":43, "country":"COM", "countryName":"Comoros", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":44, "country":"CPV", "countryName":"Cabo Verde", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":45, "country":"CRI", "countryName":"Costa Rica", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":46, "country":"CUB", "countryName":"Cuba", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":47, "country":"CYP", "countryName":"Cyprus", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":48, "country":"CYP", "countryName":"Cyprus", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":49, "country":"CZE", "countryName":"Czechia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":50, "country":"CZE", "countryName":"Czechia", "type":"IdCard", "name":"Identity Card - TD1", "icao":"TD1", "scanWidth":480 },
  { "id":51, "country":"DEU", "countryName":"Germany", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":52, "country":"DEU", "countryName":"Germany", "type":"IdCard", "name":"Identity Card - TD2", "icao":"TD2", "scanWidth":640 },
  { "id":53, "country":"DEU", "countryName":"Germany", "type":"IdCard", "name":"Identity Card - TD1", "icao":"TD1", "scanWidth":480 },
  { "id":54, "country":"DMA", "countryName":"Dominica", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":55, "country":"DNK", "countryName":"Denmark", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":56, "country":"DOM", "countryName":"Dominican Republic", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":57, "country":"DZA", "countryName":"Algeria", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":58, "country":"ECU", "countryName":"Ecuador", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":59, "country":"EGY", "countryName":"Egypt", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":60, "country":"ERI", "countryName":"Eritrea", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":61, "country":"ESP", "countryName":"Spain", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":62, "country":"ESP", "countryName":"Spain", "type":"IdCard", "name":"Identity Card", "kind":"DNI", "icao":"TD1", "scanWidth":480 },
  { "id":63, "country":"EST", "countryName":"Estonia", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":640 },
  { "id":64, "country":"EST", "countryName":"Estonia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":65, "country":"ETH", "countryName":"Ethiopia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":66, "country":"FIN", "countryName":"Finland", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":67, "country":"FIN", "countryName":"Finland", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":68, "country":"FRA", "countryName":"France", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":69, "country":"FRA", "countryName":"France", "type":"IdCard", "name":"Identity Card", "icao":"TD2", "scanWidth":640 },
  { "id":70, "country":"FRA", "countryName":"France", "type":"DriverLicense", "name":"Driver License", "icao":"EULICENSE", "scanWidth":640 },
  { "id":71, "country":"GBR", "countryName":"United Kingdom", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":72, "country":"GEO", "countryName":"Georgia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":74, "country":"GHA", "countryName":"Ghana", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":75, "country":"GMB", "countryName":"Gambia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":76, "country":"GRC", "countryName":"Greece", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":77, "country":"GRD", "countryName":"Grenada", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":78, "country":"GUY", "countryName":"Guyana", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":79, "country":"HND", "countryName":"Honduras", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":80, "country":"HRV", "countryName":"Croatia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":81, "country":"HTI", "countryName":"Haiti", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":82, "country":"HUN", "countryName":"Hungary", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":83, "country":"HUN", "countryName":"Hungary", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":84, "country":"IND", "countryName":"India", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":85, "country":"IRL", "countryName":"Ireland", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":86, "country":"IRN", "countryName":"Iran", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":87, "country":"IRQ", "countryName":"Iraq", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":88, "country":"ISL", "countryName":"Iceland", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":89, "country":"ISR", "countryName":"Israel", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":90, "country":"ITA", "countryName":"Italy", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":92, "country":"JAM", "countryName":"Jamaica", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":93, "country":"JOR", "countryName":"Jordan", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":94, "country":"JPN", "countryName":"Japan", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":95, "country":"KAZ", "countryName":"Kazakhstan", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":97, "country":"KEN", "countryName":"Kenya", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":98, "country":"KGZ", "countryName":"Kyrgyzstan", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":99, "country":"KNA", "countryName":"Saint Kitts and Nevis", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":100, "country":"KOR", "countryName":"Korea", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":101, "country":"LBN", "countryName":"Lebanon", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":102, "country":"LBR", "countryName":"Liberia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":103, "country":"LBY", "countryName":"Libya", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":105, "country":"LIE", "countryName":"Liechtenstein", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":106, "country":"LSO", "countryName":"Lesotho", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":107, "country":"LTU", "countryName":"Lithuania", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":108, "country":"LTU", "countryName":"Lithuania", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":109, "country":"LUX", "countryName":"Luxembourg", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":110, "country":"LUX", "countryName":"Luxembourg", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":111, "country":"LVA", "countryName":"Latvia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":112, "country":"LVA", "countryName":"Latvia", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":113, "country":"MAR", "countryName":"Morocco", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":114, "country":"MCO", "countryName":"Monaco", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":116, "country":"MDA", "countryName":"Moldova", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":119, "country":"MDV", "countryName":"Maldives", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":120, "country":"MEX", "countryName":"Mexico", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":121, "country":"MKD", "countryName":"Macedonia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":123, "country":"MLI", "countryName":"Mali", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":124, "country":"MLT", "countryName":"Malta", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":125, "country":"MLT", "countryName":"Malta", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":126, "country":"MNE", "countryName":"Montenegro", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":128, "country":"MOZ", "countryName":"Mozambique", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":129, "country":"MRT", "countryName":"Mauritania", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":130, "country":"MSR", "countryName":"Montserrat", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":131, "country":"MWI", "countryName":"Malawi", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":132, "country":"MYS", "countryName":"Malaysia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":133, "country":"NAM", "countryName":"Namibia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":134, "country":"NGA", "countryName":"Nigeria", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":135, "country":"NIC", "countryName":"Nicaragua", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":136, "country":"NLD", "countryName":"Netherlands", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":137, "country":"NLD", "countryName":"Netherlands", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":138, "country":"NOR", "countryName":"Norway", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":139, "country":"NPL", "countryName":"Nepal", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":140, "country":"NZL", "countryName":"New Zealand", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":141, "country":"OMN", "countryName":"Oman", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":142, "country":"PAK", "countryName":"Pakistan", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":143, "country":"PAN", "countryName":"Panama", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":144, "country":"PER", "countryName":"Peru", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":145, "country":"POL", "countryName":"Poland", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":146, "country":"POL", "countryName":"Poland", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":147, "country":"PRT", "countryName":"Portugal", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":148, "country":"PRT", "countryName":"Portugal", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":150, "country":"PSE", "countryName":"Palestine", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":151, "country":"QAT", "countryName":"Qatar", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":152, "country":"RKS", "countryName":"Kosovo", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":154, "country":"ROU", "countryName":"Romania", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":155, "country":"RUS", "countryName":"Russia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":156, "country":"SGP", "countryName":"Singapore", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":158, "country":"SLV", "countryName":"El Salvador", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":159, "country":"SMR", "countryName":"San Marino", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":160, "country":"SOM", "countryName":"Somalia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":161, "country":"SRB", "countryName":"Serbia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":163, "country":"SVK", "countryName":"Slovakia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":165, "country":"SVK", "countryName":"Slovakia", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":166, "country":"SVN", "countryName":"Slovenia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":167, "country":"SVN", "countryName":"Slovenia", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":168, "country":"SWE", "countryName":"Sweden", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":169, "country":"SWE", "countryName":"Sweden", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":170, "country":"SYR", "countryName":"Syria", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":172, "country":"TKM", "countryName":"Turkmenistan", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":173, "country":"TLS", "countryName":"Timor-Leste", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":174, "country":"TUN", "countryName":"Tunisia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":175, "country":"TUR", "countryName":"Turkey", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":176, "country":"TWN", "countryName":"Taiwan", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":177, "country":"UKR", "countryName":"Ukraine", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":178, "country":"URY", "countryName":"Uruguay", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":179, "country":"USA", "countryName":"United States of America", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":180, "country":"UZB", "countryName":"Uzbekistan", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":181, "country":"VAT", "countryName":"Holy See", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":182, "country":"VGB", "countryName":"Virgin Islands", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":183, "country":"VNM", "countryName":"Vietnam", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":184, "country":"YEM", "countryName":"Yemen", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":185, "country":"ZAF", "countryName":"South Africa", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":186, "country":"ZMB", "countryName":"Zambia", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":187, "country":"ZWE", "countryName":"Zimbabwe", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":188, "country":"ESP", "countryName":"Spain", "type":"IdCard", "name":"Resident Permit", "kind":"NIE", "icao":"TD1", "scanWidth":480 },
  { "id":189, "country":"MEX", "countryName":"Mexico", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":640 },
  { "id":190, "country":"ARG", "countryName":"Argentina", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":192, "country":"IRL", "countryName":"Ireland", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":193, "country":"HRV", "countryName":"Croatia", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":194, "country":"PHL", "countryName":"Philippines", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":195, "country":"ESP", "countryName":"Spain", "type":"DriverLicense", "name":"Driver License", "icao":"EULICENSE", "scanWidth":640 },
  { "id":197, "country":"USA", "countryName":"United States of America", "type":"DriverLicense", "name":"Driver License", "icao":"TD1", "scanWidth":1024 },
  { "id":198, "country":"HKG", "countryName":"Hong Kong", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":640 },
  { "id":200, "country":"ITA", "countryName":"Italy", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":201, "country":"PER", "countryName":"Peru", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":202, "country":"PER", "countryName":"Peru", "type":"IdCard", "name":"Residence Permit", "icao":"TD1", "scanWidth":480 },
  { "id":203, "country":"ARE", "countryName":"United Arab Emirates", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":204, "country":"AND", "countryName":"Andorra", "type":"IdCard", "name":"Residence Permit", "icao":"TD1", "scanWidth":480 },
  { "id":205, "country":"PRY", "countryName":"Paraguay", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":206, "country":"GBR", "countryName":"United Kingdom", "type":"DriverLicense", "name":"Driver License", "icao":"EULICENSE", "scanWidth":640 },
  { "id":207, "country":"ARE", "countryName":"United Arab Emirates", "type":"IdCard", "name":"Resident Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":208, "country":"ARM", "countryName":"Armenia", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":209, "country":"JPN", "countryName":"Japan", "type":"DriverLicense", "name":"Driver License", "icao":"EULICENSE", "scanWidth":640 },
  { "id":210, "country":"ROU", "countryName":"Romania", "type":"IdCard", "name":"Identity Card", "icao":"TD2", "scanWidth":640 },
  { "id":211, "country":"ROU", "countryName":"Romania", "type":"IdCard", "name":"Resident Permit - TD2", "icao":"TD2", "scanWidth":640 },
  { "id":212, "country":"ROU", "countryName":"Romania", "type":"IdCard", "name":"Resident Permit - TD1", "icao":"TD1", "scanWidth":480 },
  { "id":213, "country":"CHN", "countryName":"China", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":214, "country":"COL", "countryName":"Colombia", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":1024 },
  { "id":215, "country":"GRC", "countryName":"Greece", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":218, "country":"LTU", "countryName":"Lithuania", "type":"DriverLicense", "name":"Driver License", "icao":"EULICENSE", "scanWidth":640 },
  { "id":221, "country":"LUX", "countryName":"Luxembourg", "type":"DriverLicense", "name":"Driver License", "icao":"TD1", "scanWidth":480 },
  { "id":222, "country":"NLD", "countryName":"Netherlands", "type":"DriverLicense", "name":"Driver License", "icao":"TD1", "scanWidth":480 },
  { "id":223, "country":"PHL", "countryName":"Philippines", "type":"DriverLicense", "name":"Driver License", "icao":"TD1", "scanWidth":640 },
  { "id":224, "country":"ECU", "countryName":"Ecuador", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":640 },
  { "id":225, "country":"MAR", "countryName":"Morocco", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":640 },
  { "id":226, "country":"LVA", "countryName":"Latvia", "type":"Passport", "name":"Alien Passport", "icao":"TD3", "scanWidth":640 },
  { "id":227, "country":"AUT", "countryName":"Austria", "type":"DriverLicense", "name":"Driver License", "icao":"EULICENSE", "scanWidth":640 },
  { "id":228, "country":"CZE", "countryName":"Czechia", "type":"IdCard", "name":"Identity Card - TD2", "icao":"TD2", "scanWidth":640 },
  { "id":229, "country":"ESP", "countryName":"Spain", "type":"IdCard", "name":"EU Resident Permit", "icao":"TD1", "scanWidth":480 },
  { "id":230, "country":"AND", "countryName":"Andorra", "type":"DriverLicense", "name":"Driver license", "icao":"TD1", "scanWidth":480 },
  { "id":231, "country":"LIE", "countryName":"Liechtenstein", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":232, "country":"TUR", "countryName":"Turkey", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":233, "country":"HKG", "countryName":"Hong Kong", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":235, "country":"BIH", "countryName":"Bosnia and Herzegovina", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":236, "country":"EGY", "countryName":"Egypt", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":237, "country":"MKD", "countryName":"Macedonia", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":238, "country":"GEO", "countryName":"Georgia", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":640 },
  { "id":239, "country":"PRT", "countryName":"Portugal", "type":"IdCard", "name":"Residence Permit", "icao":"TD1", "scanWidth":480 },
  { "id":240, "country":"IRL", "countryName":"Ireland", "type":"IdCard", "name":"Residence Permit", "icao":"TD1", "scanWidth":480 },
  { "id":241, "country":"SWE", "countryName":"Sweden", "type":"IdCard", "name":"Residence Permit", "icao":"TD1", "scanWidth":480 },
  { "id":242, "country":"THA", "countryName":"Thailand", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":243, "country":"THA", "countryName":"Thailand", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":244, "country":"SGP", "countryName":"Singapore", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":245, "country":"DNK", "countryName":"Denmark", "type":"DriverLicense", "name":"Driver License", "icao":"EULICENSE", "scanWidth":640 },
  { "id":246, "country":"MEX", "countryName":"Mexico", "type":"IdCard", "name":"Residence Permit", "icao":"TD1", "scanWidth":640 },
  { "id":247, "country":"VNM", "countryName":"Vietnam", "type":"DriverLicense", "name":"Driver License", "icao":"TD1", "scanWidth":480 },
  { "id":248, "country":"VNM", "countryName":"Vietnam", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":249, "country":"KWT", "countryName":"Kuwait", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":250, "country":"CHE", "countryName":"Switzerland", "type":"DriverLicense", "name":"Driver License", "icao":"EULICENSE", "scanWidth":640 },
  { "id":251, "country":"DEU", "countryName":"Germany", "type":"IdCard", "name":"Residence Permit", "icao":"TD1", "scanWidth":480 },
  { "id":252, "country":"SGP", "countryName":"Singapore", "type":"IdCard", "name":"Work Permit", "icao":"TD1", "scanWidth":480 },
  { "id":253, "country":"GTM", "countryName":"Guatemala", "type":"Passport", "name":"Passport", "icao":"TD3", "scanWidth":640 },
  { "id":254, "country":"GTM", "countryName":"Guatemala", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":255, "country":"HKG", "countryName":"Hong Kong", "type":"IdCard", "name":"Residence Permit", "icao":"TD1", "scanWidth":480 },
  { "id":256, "country":"CHE", "countryName":"Switzerland", "type":"IdCard", "name":"Residence Permit", "icao":"TD1", "scanWidth":480 },
  { "id":257, "country":"COL", "countryName":"Colombia", "type":"DriverLicense", "name":"Driver License", "icao":"TD1", "scanWidth":480 },
  { "id":258, "country":"PER", "countryName":"Peru", "type":"DriverLicense", "name":"Driver License", "icao":"TD1", "scanWidth":480 },
  { "id":259, "country":"PAN", "countryName":"Panama", "type":"IdCard", "name":"Identity Card", "icao":"TD1", "scanWidth":480 },
  { "id":260, "country":"PAN", "countryName":"Panama", "type":"DriverLicense", "name":"Driver License", "icao":"TD1", "scanWidth":480 },
  { "id":261, "country":"PAN", "countryName":"Panama", "type":"IdCard", "name":"Residence Permit", "icao":"TD1", "scanWidth":480 }
]
