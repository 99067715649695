import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/react-hooks'
import { CircularProgress } from '@material-ui/core'
import { GET_LIQUIDATION_PROOF } from '../queriesAndMutations'
import AdminUploadPreview from './AdminUploadPreview'

const AdminLiquidationProofViewer = ({
  customerId
}) => {
  const { t } = useTranslation();
  const { data: { getLiquidationProof } = {}, loading } = useQuery(GET_LIQUIDATION_PROOF, {
    variables: {
      customerId
    }
  })

  return (
    <>
      <div className="settings__item">
        <div className="settings__anchor">{' '}</div>

        <div className="title-primary settings__title">
          { t('Proof of Purchase') }
        </div>
        <div className="settings__fieldset">
          {loading && (
            <CircularProgress />
          )}

          {getLiquidationProof && (
            <>
              {
                getLiquidationProof.txHashes &&
                getLiquidationProof.txHashes.length > 0 && (
                  <>
                    <p>Transaction hashes:</p>
                    <ul>
                      {getLiquidationProof.txHashes.map(hash => (
                        <li key={hash}>- {hash}</li>
                      ))}
                    </ul>
                  </>
                )
              }
              {
                getLiquidationProof.uploads &&
                getLiquidationProof.uploads.length > 0 &&
                getLiquidationProof.uploads.map(upload => (
                  <AdminUploadPreview upload={upload} />
                ))
              }
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default AdminLiquidationProofViewer
