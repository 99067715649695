import React from 'react'
import PropTypes from 'prop-types'
import InputThemeField from './InputThemeField'
import { SOURCE_OF_WEALTH_STANDARD_SCHEMAS, SOURCE_OF_WEALTH_PUBLIC_SCHEMAS } from '../schemas/kycVerification'
import { SOURCE_OF_WEALTH } from '../constants/customer'
import { useTranslation } from 'react-i18next'
import DropdownMaterial from './DropdownMaterial'
import { MenuItem } from '@material-ui/core'
import InfoIcon from "./Icon"
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'

const KycVerificationFormSourceOfWealth = ({
  values,
  setValues,
  disabled = false,
  loading,
  handleChange,
  publicSettings,
}) => {
  const { t } = useTranslation();

  const defaultSourceOfWealthSchema = publicSettings && publicSettings.public_sale_sof_sow ? SOURCE_OF_WEALTH_PUBLIC_SCHEMAS : SOURCE_OF_WEALTH_STANDARD_SCHEMAS;
  let sourceOfWealthSchema = SOURCE_OF_WEALTH_STANDARD_SCHEMAS;
  if (values.sourceOfWealth && Object.prototype.hasOwnProperty.call(defaultSourceOfWealthSchema, values.sourceOfWealth.toLocaleLowerCase())) {
    sourceOfWealthSchema = defaultSourceOfWealthSchema;
  } else {
    sourceOfWealthSchema = publicSettings && publicSettings.public_sale_sof_sow ? SOURCE_OF_WEALTH_STANDARD_SCHEMAS : SOURCE_OF_WEALTH_PUBLIC_SCHEMAS;
  }

  return (
    <>
      <DropdownMaterial
        label={
          <>
            {t('Primary source of wealth for this purchase') }
            <span className="text__red"> *</span>
            {" "}
            <span
              data-tip={t('How did you make your wealth?')}
              data-for="source_of_wealth"
              className="tooltip"
            >
            <InfoIcon
              className={classNames('info')}
              name='info'
              size="16"
            />
            </span>
            <ReactTooltip id="source_of_wealth" place="bottom" padding="24px" className="popover-tooltip"/>
          </>
        }
        propertyName="sourceOfWealth"
        state={values}
        setState={setValues}
      >
        {Object.keys(sourceOfWealthSchema).map((key) => (
          <MenuItem
            key={key}
            value={key.toUpperCase()}
          >
            {t(sourceOfWealthSchema[key].label)}
          </MenuItem>
        ))}

      </DropdownMaterial>

      <br />

      {(values || {}).sourceOfWealth === SOURCE_OF_WEALTH.OTHER && (
        <InputThemeField
          id="outlined-name"
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"
          label={t('Describe details of Source of Wealth')}
          required
          value={values.sourceOfWealthOther || ""}
          onChange={handleChange("sourceOfWealthOther", values, setValues)}
          type="text"
          disabled={disabled}
        />
      )}
    </>
  );
};

KycVerificationFormSourceOfWealth.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default KycVerificationFormSourceOfWealth;
