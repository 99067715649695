import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RegisterSuccess from "./pages/RegisterSuccess";
import RegisterConfirm from "./pages/RegisterConfirm";
import PasswordRestore from "./pages/PasswordRestore";
import PasswordRestoreConfirm from "./pages/PasswordRetoreConfirm";
import AdminActivation from "./pages/AdminActivation";
import Terms from "./pages/Terms";
import Policy from "./pages/Policy";
import TanganyTerms from "./pages/TanganyTerms";
import DeclarationOfConsent from "./pages/DeclarationOfConsent";
import RightOfWithdrawals from "./pages/RightOfWithdrawals";
import PurchaseAgreement from "./pages/PurchaseAgreement"
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import KycVerification from "./pages/KycVerification";
import UpdateKycVerification from "./pages/UpdateKycVerification";
import Profile from "./pages/Profile";
import Contribute from "./pages/Contribute";
import Transaction from "./pages/Transaction";
import Transactions from "./pages/Transactions";
import DataCenter from "./pages/DataCenter";
import KycStatusCustomer from "./pages/KycStatusCustomer";
import KycStatusCustomerCompany from "./pages/KycStatusCustomerCompany";
import AdminPanel from "./pages/AdminPanel";
import AdminUsers from "./pages/AdminUsers";
import Theme from "./components/Theme";
import ProtectedZone from "./components/ProtectedZone";
import AuthRoute from "./components/AuthRoute";
import AdminIndividualKyc from "./pages/AdminIndividualKyc";
import AdminCompanyKyc from "./pages/AdminCompanyKyc";
import AdminTransactions from "./pages/AdminTransactions";
import AdminTransaction from "./pages/AdminTransaction";
import AdminSettings from "./pages/AdminSettings";
import AdminExchangeRates from "./pages/AdminExchangeRates";
import FirstLogin from "./pages/FirstLogin";
import AuthToken from "./pages/AuthToken";
import SalesAuthTokenLogin from "./pages/SalesAuthTokenLogin";
import AdminVideoIdentLog from "./pages/AdminVideoIdentLog";
import VideoIDAttendedPage from "./pages/VideoIDAttendedPage";
import VideoRecoredPage from "./pages/VideoRecoredPage";
import VideoIDUnAttendedPage from "./pages/VideoIDUnAttendedPage";
import VoltSuccessPage from "./pages/VoltSuccessPage";
import VoltFailurePage from "./pages/VoltFailurePage";
import VoltPendingPage from "./pages/VoltPendingPage";
import OnfidoIdent from "./pages/OnfidoIdent";
import ComplycubeIdent from "./pages/ComplycubeIdent";
import KycDirectorProfile from "./pages/KycDirectorProfile";
import KycDirectorVideoIdent from "./pages/KycDirectorVideoIdent";
import KycUboProfile from "./pages/KycUboProfile";
import { USER_RIGHT_TYPES } from "./constants/user";
import UpdateKycCompanyVerification from "./pages/UpdateKycCompanyVerification";
import ForwardableProtectedZone from "./components/ForwardableProtectedZone";
import AdminTokenRegistry from "./pages/AdminTokenRegistry";
import AdminLogEvent from "./pages/AdminLogEvent";
import AdminIndividualEditKyc from "./pages/AdminIndividualEditKyc";
import AdminSecurity from "./pages/AdminSecurity";
import SsoAuth from "./pages/SsoAuth";
import InternalLivenessCheckQrCodePage from "./pages/InternalLivenessCheckQrCodePage";
import InternalLivenessCheckForm from "./pages/InternalLivenessCheckForm";
import EasyOnBoarding2fa from "./pages/EasyOnBoarding2fa";
import EasyOnBoardingRestorePasswordNumber from "./pages/EasyOnBoardingRestorePasswordNumber";

import EasyOnBoardingVerifyIdentity from "./pages/EasyOnBoardingVerifyIdentity";
import EasyOnBoardingOptions from "./pages/EasyOnBoardingOptions";
import EasyOnBoardingCompany from "./pages/EasyOnBoardingCompany";
import PageDashboard from "./components/PageDashboard";
import PageLoginRegister from "./components/PageLoginRegister";
import PageEasyOnBoarding from "./components/PageEasyOnboarding";

import EasyOnBoarding from "./pages/EasyOnBoarding";
import AdminMonitoring from "./pages/AdminMonitoring";
import SpotCheck from "./pages/SpotCheck";
import WalletValidate from "./pages/WalletValidate";
import MessagePage from "./pages/MessagePage";
import AdminMessagePage from "./pages/AdminMessagePage";
import IdnowIdentPage from "./pages/IdnowIdentPage";
import EasyOnBoardingDetailThankyouOne from "./pages/EasyOnBoardingDetailThankyouOne";
import AdminTokenSwap from "./pages/AdminTokenSwap";
import AdminPersonalDiscount from "./pages/AdminPersonalDiscount";
import IdnowIdentComplete from "./pages/IdnowIdentComplete";
import UserActivity from "./pages/UserActivity";
import LoadingLogo from "./pages/LoadingLogo"
import ForwardingPage from "./pages/ForwardingPage"
import SuccessPaymentPage from "./pages/PaymentSuccess"
import TransferClaim from "./pages/TransferClaim";
import TransferClaimGuide from "./pages/TransferClaim/TransferClaimGuide";
import TransferClaimLiquidator from "./pages/TransferClaim/TransferClaimLiquidator";
import TransferClaimCreditCard from "./pages/TransferClaim/TransferClaimCreditCard";
import TransferClaimBitcoin from "./pages/TransferClaim/TransferClaimBitcoin";
import LivenessCheckPortal from "./pages/LivenessCheckPortal";
import AdminCompanyKycCompare from "./pages/AdminCompanyKycCompare";
import AdminCapitalIncrease from "./pages/AdminCapitalIncrease";
import AdminWeb3Iban from "./pages/AdminWeb3Iban";

export default (apolloClient) => (
  <Theme>
    <Switch>
      <Route
        exact
        path="/dummy-loading-logo"
        component={LoadingLogo}
      />
      <Route
        exact
        path="/dummy-forwarding-page"
        component={ForwardingPage}
      />
      <Route
        exact
        path="/terms"
        render={() => (
          <Terms />
        )}
      />
      <Route
        exact
        path="/privacy-policy"
        render={() => (
          <Policy />
        )}
      />
      <Route
        exact
        path="/tangany-terms-and-conditions"
        render={() => (
          <TanganyTerms />
        )}
      />
      <Route
        exact
        path="/declaration-of-consent"
        render={() => (
          <DeclarationOfConsent />
        )}
      />
      <Route
        exact
        path="/right-of-withdrawal"
        render={() => (
          <RightOfWithdrawals />
        )}
      />
      <Route
        exact
        path="/purchase-agreement"
        render={() => (
          <PurchaseAgreement />
        )}
      />
      <Route
        exact
        path="/sso/auth"
        render={() => (
          <PageLoginRegister>
            <SsoAuth />
          </PageLoginRegister>
        )}
      />

      <Route
        exact
        path="/messages"
        render={() => (
          <PageDashboard>
            <MessagePage />
          </PageDashboard>
        )}
      />

      <AuthRoute
        exact
        path="/login"
        render={() => (
          <PageLoginRegister>
            <Login/>
          </PageLoginRegister>
        )}
      />
      <AuthRoute
        exact
        path="/register"
        render={() => (
          <PageLoginRegister>
            <Register />
          </PageLoginRegister>
        )}
      />
      <AuthRoute
        exact
        path="/register-success/:userId"
        render={() => (
          <PageLoginRegister>
            <RegisterSuccess />
          </PageLoginRegister>
        )}
      />
      <AuthRoute
        exact
        path="/register/confirm"
        render={() => (
          <PageLoginRegister>
            <RegisterConfirm />
          </PageLoginRegister>
        )}
      />
      <AuthRoute
        exact path="/salesLogin"
        render={() => (
          <PageLoginRegister>
            <FirstLogin />
          </PageLoginRegister>
        )}

      />
      <AuthRoute
        exact
        path="/salesAuthTokenLogin"
        render={() => (
          <PageLoginRegister>
            <SalesAuthTokenLogin />
          </PageLoginRegister>
        )}
      />
      <AuthRoute
        exact
        path="/password/restore"
        render={() => (
          <PageLoginRegister>
            <PasswordRestore />
          </PageLoginRegister>
        )}
      />
      <AuthRoute
        exact
        path="/password/restore/confirm"
        render={() => (
          <PasswordRestoreConfirm />
        )}
      />
      <AuthRoute
        exact
        path="/admin-account/activate"
        render={() => (
          <AdminActivation />
        )}
      />
      <Route
        exact
        path="/auth"
        render={() => (
          <AuthToken />
        )}
      />

      {/* this internal-liveness-check-form doesn't setup for not support viewer at the moment */}
      <Route
        exact
        path="/internal-liveness-check-form/:accessToken"
        component={InternalLivenessCheckForm}
      />

      <Route
        exact
        path="/idnow-ident/completed"
        render={() => (
          <IdnowIdentComplete />
        )}
      />

      <Route
        exact
        path="/second-layer-security"
        render={() => (
          <PageDashboard>
            <AdminSecurity />
          </PageDashboard>
        )}
      />

      <ProtectedZone minRights={USER_RIGHT_TYPES.COMMON}>
        <Route
          exact
          path="/contribute/payment-success"
          component={SuccessPaymentPage}
        />
        <Route
          exact
          path="/easy-onboarding-2fa"
          render={() => (
            <PageEasyOnBoarding>
              <EasyOnBoarding2fa />
            </PageEasyOnBoarding>
          )}
        />
        <Route
          exact
          path="/easy-onboarding-verify-phone-number"
          render={() => (
            <PageEasyOnBoarding>
              <EasyOnBoardingRestorePasswordNumber />
            </PageEasyOnBoarding>
          )}
        />

        <Route
          exact
          path="/easy-onboarding-verify-identity"
          render={() => (
            <PageEasyOnBoarding>
              <EasyOnBoardingVerifyIdentity />
            </PageEasyOnBoarding>
          )}
        />

        <Route
          exact
          path="/easy-onboarding"
          render={() => (
            <PageEasyOnBoarding>
              <EasyOnBoarding />
            </PageEasyOnBoarding>
          )}
        />

        <Route
          exact
          path="/easy-onboarding/thank-you"
          render={() => (
            <PageEasyOnBoarding>
              <EasyOnBoardingDetailThankyouOne />
            </PageEasyOnBoarding>
          )}
        />

        <Route
          exact
          path="/easy-onboarding-options"
          render={() => (
            <PageEasyOnBoarding>
              <EasyOnBoardingOptions />
            </PageEasyOnBoarding>
          )}
        />

        <Route
          exact
          path="/easy-onboarding-company"
          render={() => (
            <PageEasyOnBoarding>
              <EasyOnBoardingCompany />
            </PageEasyOnBoarding>
          )}
        />

        <Route
          exact
          path="/"
          render={() => (
            <PageDashboard>
              <Dashboard />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/transfer-claim"
          render={() => (
            <PageDashboard>
              <TransferClaim />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/transfer-claim/guide"
          render={() => (
            <PageDashboard>
              <TransferClaimGuide />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/transfer-claim/claim-with-liquidator"
          render={() => (
            <PageDashboard>
              <TransferClaimLiquidator />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/transfer-claim/claim-with-credit-card"
          render={() => (
            <PageDashboard>
              <TransferClaimCreditCard />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/transfer-claim/claim-with-bitcoin"
          render={() => (
            <PageDashboard>
              <TransferClaimBitcoin />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/application/:referalCode?"
          render={({ match }) => (
            <PageDashboard>
              <KycVerification match={match} />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/director-profile/:directorId"
          render={({ match }) => (
            <PageDashboard>
              <KycDirectorProfile match={match}/>
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/director-profile/:directorId/video-ident"
          render={({ match }) => (
            <PageDashboard>
              <KycDirectorVideoIdent match={match}/>
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/ubo-profile/:uboId"
          render={({ match }) => (
            <PageDashboard>
              <KycUboProfile match={match} />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/application-success/company/:customerId"
          render={({ match }) => (
            <PageDashboard>
              <KycStatusCustomerCompany match={match} />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/application-success/:customerName/:customerId"
          render={({ match }) => (
            <PageDashboard>
              <KycStatusCustomer match={match} />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/application-lvl-increase/:customerName/:customerId"
          component={KycStatusCustomer}
        />
        <Route
          exact
          path="/application-update/customerCompany/:customerId/:referalCode?"
          render={({ match }) => (
            <PageDashboard>
              <UpdateKycCompanyVerification match={match} />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/application-update/:customerName/:customerId/:referalCode?"
          render={({ match }) => (
            <PageDashboard>
              <UpdateKycVerification match={match} />
            </PageDashboard>
          )}
        />

        <Route
          exact
          path="/transactions"
          render={() => (
            <PageDashboard>
              <Transactions />
            </PageDashboard>
          )}

        />
        <Route
          exact
          path="/transactions/:transactionId"
          render={({ match }) => (
            <PageDashboard>
              <Transaction match={match} />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/profile"
          render={() => (
            <PageDashboard>
              <Profile />
            </PageDashboard>
          )}
        />

        <Route
          exact
          path="/user-activity"
          render={() => (
            <PageDashboard>
              <UserActivity />
            </PageDashboard>
          )}
        />

        <Route
          exact
          path="/contribute"
          render={() => (
            <PageDashboard>
              <Contribute />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/data-center"
          render={() => (
            <PageDashboard>
              <DataCenter />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/wallet-validate"
          render={() => (
            <PageDashboard>
              <WalletValidate />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/video-id-attended"
          render={() => (
            <PageDashboard>
              <VideoIDAttendedPage />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/video-id-unattended"
          component={VideoIDUnAttendedPage}
        />
        <Route
          exact
          path="/liveness-check-portal"
          render={() => (
            <PageDashboard>
              <LivenessCheckPortal />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/onfido-ident"
          render={() => (
            <PageDashboard>
              <OnfidoIdent />
            </PageDashboard>
          )}
        />

        <Route
          exact
          path="/complycube-ident"
          render={() => (
            <PageDashboard>
              <ComplycubeIdent />
            </PageDashboard>
          )}
        />

        <Route
          exact
          path="/video-id-recored/:videoId"
          render={({ match }) => (
            <PageDashboard>
              <VideoRecoredPage match={match} />
            </PageDashboard>
          )}
        />

        <Route
          exact
          path="/volt-success"
          render={() => (
            <PageDashboard>
              <VoltSuccessPage />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/volt-failure"
          render={() => (
            <PageDashboard>
              <VoltFailurePage />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/volt-pending"
          render={() => (
            <PageDashboard>
              <VoltPendingPage />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/idnow-ident"
          render={({ match }) => (
            <PageDashboard>
              <IdnowIdentPage />
            </PageDashboard>
          )}
        />
        <Route exact path="/internal-liveness-check/:applicantId" component={InternalLivenessCheckQrCodePage} />

        <ForwardableProtectedZone
          exactRights={[USER_RIGHT_TYPES.AUDITOR, USER_RIGHT_TYPES.CUSTODIAN, USER_RIGHT_TYPES.ADMIN, USER_RIGHT_TYPES.GENERAL_ADMIN, USER_RIGHT_TYPES.ACCOUNTING]}
          matchRoutes={(
            <>
              <Route
                exact
                path="/admin/token-registry"
                render={() => (
                  <PageDashboard>
                    <AdminTokenRegistry />
                  </PageDashboard>
                )}
              />
            </>
          )}
          elseRoutes={(
            <>
              <ProtectedZone
                minRights={USER_RIGHT_TYPES.AUDITOR}
                urlToRedirect="/"
              >
                <Route
                  exact
                  path="/admin/kyc-list/individual"
                  render={() => (
                    <PageDashboard isContainerFull>
                      <AdminIndividualKyc />
                    </PageDashboard>
                  )}
                />

                <Route
                  exact
                  path="/admin/kyc-list/individual/:kycId/edit"
                  render={({ match }) => (
                    <PageDashboard>
                      <AdminIndividualEditKyc match={match}/>
                    </PageDashboard>
                  )}
                />

                <ProtectedZone
                  minRights={USER_RIGHT_TYPES.AUDITOR}
                  urlToRedirect="/"
                >
                  <Route
                    exact
                    path="/admin"
                    render={() => (
                      <PageDashboard>
                        <AdminPanel />
                      </PageDashboard>
                    )}
                  />
                  <Route
                    exact
                    path="/admin/settings"
                    render={() => (
                      <PageDashboard>
                        <AdminSettings />
                      </PageDashboard>
                    )}
                  />
                  <Route
                    exact
                    path="/admin/users"
                    render={() => (
                      <PageDashboard>
                        <AdminUsers />
                      </PageDashboard>
                    )}
                  />
                  <Route
                    exact
                    path="/admin/transactions"
                    render={() => (
                      <PageDashboard>
                        <AdminTransactions />
                      </PageDashboard>
                    )}
                  />
                  <Route
                    exact
                    path="/admin/capital-increase"
                    render={() => (
                      <PageDashboard>
                        <AdminCapitalIncrease />
                      </PageDashboard>
                    )}
                  />

                  <Route
                    exact
                    path="/admin/transactions/:transactionId"
                    component={AdminTransaction}
                  />
                  <Route
                    exact
                    path="/admin/kyc-list/company"
                    render={() => (
                      <PageDashboard isContainerFull>
                        <AdminCompanyKyc />
                      </PageDashboard>
                    )}
                  />
                  <Route
                    exact
                    path="/admin/kyc-list/company/:kycId/compare"
                    render={({ match }) => (
                      <PageDashboard>
                        <AdminCompanyKycCompare match={match}/>
                      </PageDashboard>
                    )}
                  />
                  <Route
                    exact
                    path="/admin/video-ident-log"
                    render={() => (
                      <PageDashboard>
                        <AdminVideoIdentLog />
                      </PageDashboard>
                    )}
                  />

                  <Route
                    exact
                    path="/admin/monitoring"
                    render={() => (
                      <PageDashboard>
                        <AdminMonitoring />
                      </PageDashboard>
                    )}
                  />
                  <Route
                    exact
                    path="/admin/spotcheck"
                    component={SpotCheck}
                  />

                  <Route
                    exact
                    path="/admin/messages"
                    render={() => (
                      <PageDashboard>
                        <AdminMessagePage />
                      </PageDashboard>
                    )}
                  />

                  <ProtectedZone minRights={USER_RIGHT_TYPES.ADMIN} urlToRedirect="/">
                    <Route
                      exact
                      path="/admin/log-event"
                      render={() => (
                        <PageDashboard>
                          <AdminLogEvent />
                        </PageDashboard>
                      )}
                    />

                    <Route
                      exact
                      path="/admin/swap-token"
                      render={() => (
                        <PageDashboard>
                          <AdminTokenSwap />
                        </PageDashboard>
                      )}
                    />

                    <Route
                      exact
                      path="/admin/personal-discount"
                      render={() => (
                        <PageDashboard>
                          <AdminPersonalDiscount />
                        </PageDashboard>
                      )}
                    />

                    <Route
                      exact
                      path="/admin/web3-iban"
                      render={() => (
                        <PageDashboard>
                          <AdminWeb3Iban />
                        </PageDashboard>
                      )}
                    />

                    <Route
                      exact
                      path="/admin/exchange-rates"
                      render={() => (
                        <PageDashboard>
                          <AdminExchangeRates />
                        </PageDashboard>
                      )}
                    />
                  </ProtectedZone>
                </ProtectedZone>
              </ProtectedZone>

              <Route exact path="*" component={NotFound} />
            </>
          )}
        />

        <Route exact path="*" component={NotFound} />
      </ProtectedZone>
    </Switch>
  </Theme>
);
