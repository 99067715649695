import React from 'react'

const SwitchCheckbox = ({
  // eslint-disable-next-line react/prop-types
  keyIndex,
  value,
  onChange,
  switchText,
  isGrayText,
  isSmallSpace,
  isRemoveLine,
  defaultChecked,
  isLeftSwitch,
  isDisabled
}) => {
  return (
    <div className={`create__line create__line-kyc ${isLeftSwitch ? "create__line_left" : ""} ${isRemoveLine ? "remove__line" : ""} ${isGrayText ? "create__line-gray" : ""} ${isSmallSpace ? "create__line-small-space" : ""} ${isDisabled ? "create__line-disabled" : ""}`}>
      <label className="switch">
        <input
          type="checkbox"
          className="switch__input"
          checked={defaultChecked}
          onChange={onChange}
          value={value}
        />

        <div className="switch__inner">
          <div className="switch__box">{' '}</div>
        </div>

        {switchText && (
          typeof switchText === 'string' ? (
            // eslint-disable-next-line react/no-danger
            <div className="create__info" dangerouslySetInnerHTML={{__html: switchText}} />
          ) : <div className="create__info">{switchText}</div>
        )}
      </label>
    </div>
  )
}

export default SwitchCheckbox;
