import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import AdminUserList from './Lists'
import AdminExportUsers from '../../components/AdminCsvDialogs/AdminExportUsers'
import { useTranslation } from 'react-i18next'

const AdminUsers = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <DialogContent>
          <AdminExportUsers
            onClose={() => {
              setDialogOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>

      <div className="page__title h3">{ t('Users List') }</div>

      <AdminUserList />

    </>
  );
};

export default AdminUsers;
