import React, { useEffect } from "react"
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import SwitchCheckboxTheme from '../SwitchCheckbox'

const CompanyStep5DisclaimerUtility = ({
  publicSettings,
  checkboxValues,
  setCheckboxValues,
  prospectusOrIssuing,
  prospectusOrIssuingDate,
  setEnableSubmit
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const enable = [
      'clientStatement',
      'declarationOfConsent1',
      'declarationOfConsent2',
    ].reduce((all, key) => all && checkboxValues[key], true);

    setEnableSubmit(enable);
    // eslint-disable-next-line
  }, [checkboxValues]);

  const changeCheckbox = (field) => ({target: {checked}}) => {
    setCheckboxValues({...checkboxValues, [field]: checked})
  }

  return (
    <>
      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Client Statement')}
        </div>

        <div className="field">
          <div className="field__wrap">
            <SwitchCheckboxTheme
              value="clientStatement"
              defaultChecked={checkboxValues.clientStatement}
              onChange={changeCheckbox('clientStatement')}
              isRemoveLine
              isGrayText
              isLeftSwitch
              switchText={`
              The Legal Entity confirms that It will be the beneficial owner of
              the tokens purchased from ${
                publicSettings &&
                publicSettings.company &&
                publicSettings.company.name
              }.
            `}
            />
          </div>
        </div>
      </div>

      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Declaration of Consent')}
        </div>

        <div className="field">
          <div className="field__wrap">
            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              value="declarationOfConsent1"
              defaultChecked={checkboxValues.declarationOfConsent1}
              onChange={changeCheckbox('declarationOfConsent1')}
              switchText={`
              Under the terms of the Company's Privacy Policy, The Legal Entity
              hereby Consent to my personal data being processed by ixo.world
              and its affiliated companies or agents (such as Banks, Tax
              Advisor, Auditors and Compliance services)
            `}
            />
          </div>
        </div>

        <div className="field">
          <div className="field__wrap">
            <SwitchCheckboxTheme
              isRemoveLine
              isGrayText
              isLeftSwitch
              value="declarationOfConsent2"
              defaultChecked={checkboxValues.declarationOfConsent2}
              onChange={changeCheckbox('declarationOfConsent2')}
              switchText={
              <>
                {`
                  The Legal Entity Hereby declare that we have been provided
                  with the
                `}
                <NavLink to="/privacy-policy" target="_blank">
                  Privacy Policy
                </NavLink>
                {`
                  of the Seller and that we have been informed about how our
                  Data will be processed and our Data Protection Rights.
                `}
              </>
            }
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CompanyStep5DisclaimerUtility
