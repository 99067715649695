import React from 'react'
import PropTypes from 'prop-types'
import InputThemeField from './InputThemeField'
import { SOURCE_OF_FUNDS_STANDARD_SCHEMAS, SOURCE_OF_FUNDS_PUBLIC_SCHEMAS } from '../schemas/kycVerification'
import { SOURCE_OF_FUNDS } from '../constants/customer'
import { useTranslation } from 'react-i18next'
import DropdownMaterial from './DropdownMaterial'
import { MenuItem } from '@material-ui/core'
import InfoIcon from "./Icon"
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'

const KycVerificationFormSourceOfFunds = ({
  values,
  setValues,
  disabled = false,
  loading,
  handleChange,
  publicSettings,
}) => {
  const { t } = useTranslation();

  const defaultSourceOfFundsSchema = publicSettings && publicSettings.public_sale_sof_sow ? SOURCE_OF_FUNDS_PUBLIC_SCHEMAS : SOURCE_OF_FUNDS_STANDARD_SCHEMAS;
  let sourceOfFundsSchema = SOURCE_OF_FUNDS_STANDARD_SCHEMAS;
  if (values.sourceOfFunds && Object.prototype.hasOwnProperty.call(defaultSourceOfFundsSchema, values.sourceOfFunds.toLocaleLowerCase())) {
    sourceOfFundsSchema = defaultSourceOfFundsSchema;
  } else {
    sourceOfFundsSchema = publicSettings && publicSettings.public_sale_sof_sow ? SOURCE_OF_FUNDS_STANDARD_SCHEMAS : SOURCE_OF_FUNDS_PUBLIC_SCHEMAS;
  }

  return (
    <>
      <DropdownMaterial
        label={
          <>
            { t('Primary source of funds for this purchase') }
            <span className="text__red"> *</span>
            {" "}
            <span
              data-tip={t('Where does the funds used for this transaction come from?')}
              data-for="primary_source_of_funds_for_this_purchase"
              className="tooltip"
            >
              <InfoIcon
                className={classNames('info')}
                name='info'
                size="16"
              />
            </span>
            <ReactTooltip id="primary_source_of_funds_for_this_purchase" place="bottom" padding="24px" className="popover-tooltip" />
          </>
        }
        propertyName="sourceOfFunds"
        state={values}
        setState={setValues}
      >
        {Object.keys(sourceOfFundsSchema).map((key) => (
          <MenuItem
            key={key}
            value={key.toUpperCase()}
          >
            {t(sourceOfFundsSchema[key].label)}
          </MenuItem>
        ))}

      </DropdownMaterial>

      <br />

      {(values || {}).sourceOfFunds === SOURCE_OF_FUNDS.OTHER && (
        <InputThemeField
          id="outlined-name"
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"
          label={t('Describe details of Source of Funds')}
          required
          value={values.sourceOfFundsOther || ""}
          onChange={handleChange("sourceOfFundsOther", values, setValues)}
          type="text"
          disabled={disabled}
        />
      )}
    </>
  );
};

KycVerificationFormSourceOfFunds.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default KycVerificationFormSourceOfFunds;
