import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Dialog,
} from '@material-ui/core'

import classNames from 'classnames';
import LockedFeatureIcon from './LockedFeatureIcon';
import Icon from './Icon';
import { useTranslation } from 'react-i18next';

const FeatureNotAvailableMessage = () => {
  const [showDialog, setShowDialog] = useState(false)
  const { t } = useTranslation();

  return (
    <div className="refund__item refund__item-middle_content">
      <div className="refund__icon">
        <LockedFeatureIcon hideTitle={true} />
      </div>
      <div className="refund__content refund__content-link">
        { t('This feature is not available for your current subscription.') }
        {' '}
        <NavLink to="#" onClick={() => setShowDialog(true)}>{ t('Upgrade')}</NavLink>
      </div>

      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <div className="popup js-popup animation visible">
          <div onClick={() => setShowDialog(false)} className="popup__overlay js-popup-overlay">{' '}</div>

          <div className="popup__wrap js-popup-wrap">
            <div className="description">
              <div className="title-red description__title">{ t('Upgrade your tenant') }</div>

              <div className="description__row flex__row flex__wrap">
                <div className="refund__content">
                  <p>{ t('In order to upgrade your tenant, following these steps:') }</p>
                  <ol>
                    <li style={{listStyle: 'auto'}}>{ t('Go to Investhub.') }</li>
                    <li style={{listStyle: 'auto'}}>{ t('Log in with the account owning this tenant.') }</li>
                    <li style={{listStyle: 'auto'}}>{ t('Go to tenant detail.') }</li>
                    <li style={{listStyle: 'auto'}}>{ t('Click upgrade tenant button.') }</li>
                    <li style={{listStyle: 'auto'}}>{ t('Select appropriate plan and upgrade.')}</li>
                  </ol>
                </div>
                <div className="space space__32">{' '}</div>

                <button
                  className="button button-stroke"
                  href={`${process.env.REACT_APP_DAO_HOST}/dashboard`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  { t('Go to Investhub') }
                </button>
              </div>
            </div>

            <button
              className="popup__close js-popup-close"
              onClick={() => setShowDialog(false)}
            >
              <Icon
                className={classNames('close', 'icon-24')}
                name="close"
                size="24"
              />
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default FeatureNotAvailableMessage
