import React from 'react'
import OnfidoApplicantReportCheck from '../OnfidoApplicantReportCheck'
import OnfidoApplicantAllReportCheck from '../OnfidoApplicantAllReportCheck'
import ComplycubeClientReportCheck from '../ComplycubeClientReportCheck'
// import AdminIdnowViewer from '../AdminIdnowViewer'
// import AdminKycInternalLivenessCheckViewer from '../AdminKycInternalLivenessCheckViewer'

const AdminWatchlistCheckViewer = ({
  type,
  customer,
  transactionsUserList
}) => {
  return (
    <>
      {
        (
          customer.onfidoApplicant || (
            customer.allOnfidoApplicant &&
            customer.allOnfidoApplicant.length > 0
          )
        ) && (
          <>
            {customer.onfidoApplicant && (
              <>
                <OnfidoApplicantReportCheck applicant={customer.onfidoApplicant} transactionsUserList={transactionsUserList} watchlistView={true} />
              </>
            )}

            {customer.allOnfidoApplicant && customer.allOnfidoApplicant.length > 0 && (
              <>
                {customer.allOnfidoApplicant.map(applicant => (
                  <React.Fragment key={applicant.id}>
                    <OnfidoApplicantAllReportCheck applicant={applicant} transactionsUserList={transactionsUserList} watchlistView={true} />
                  </React.Fragment>
                ))}
              </>
            )}
          </>
        )
      }

      {
        customer.complycubeClient && (
          <>
            {customer.complycubeClient && (
              <>
                <ComplycubeClientReportCheck client={customer.complycubeClient} transactionsUserList={transactionsUserList} watchlistView={true} />
              </>
            )}
          </>
        )
      }
    </>
  )
}

export default AdminWatchlistCheckViewer
