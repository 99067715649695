import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import Input from "./Input";
import { RESEND_CONFIRMATION_EMAIL } from '../queriesAndMutations'
import CircularProgress from '@material-ui/core/CircularProgress'
import { toaster } from '../utils'

const ResentActivationForm = ({
  onExit,
}) => {
  const { t } = useTranslation()
  const [values, setValues] = useState({
    email: localStorage.getItem('email') || '',
  });
  const [resendConfirmationEmail, { loading, error }] = useMutation(RESEND_CONFIRMATION_EMAIL)
  const triggerResent = () => {
    resendConfirmationEmail({
      variables: {
        input: {
          email: values.email,
        }
      }
    }).then((response) => {
      if (response.data && response.data.resendConfirmationEmail) {
        toaster.success('Confirmation email is sent. Please check your email for activation link.');
        onExit();
      }
    }).catch((e) => console.log(e));
  }

  return (
    <div>
      <p className="text-danger">
        Your email address has not been confirmed.
        Please check your mailbox for activation link or request to resend it at the form below.
      </p>
      <form onSubmit={(e) => { e.preventDefault(); triggerResent(); }}>
        <div className="input-field">
          <Input
            propertyName="email"
            label="Your Email"
            state={values}
            setState={setValues}
            error={error}
            loading={loading}
          />
        </div>
        <br />

        {loading ? (
          <div>
            <CircularProgress />
          </div>
        ) : (
          <div className="input-submit">
            <button
              onClick={triggerResent}
              type="button"
              className="button button__full"
            >
              { t('Resent activation email') }
            </button>
            <button
              onClick={onExit}
              type="button"
              className="button-stroke button__full"
              style={{marginTop: '20px'}}
            >
              { t('Back to login') }
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default ResentActivationForm
