import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress
} from '@material-ui/core'
import JoyRide from 'react-joyride'
import { useMe, usePublicSettings } from '../../myHooks'
import {
  SAVE_AND_SEND_VERIFY_PHONE_NUMBER_SMS
} from '../../queriesAndMutations'
import { USER_KYC_STATUS_TYPES } from '../../constants/user'
import LoginHead from './LoginHead'

const EasyOnBoardingRestorePassword = () => {
  const { t } = useTranslation()
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { me } = {} } = useMe()
  const {
    data: { me: userData } = {},
    loading,
  } = useMe()
  const [phoneNumber, setPhoneNumber] = useState('423')
  const query = new URLSearchParams(window.location.search)
  const company = query.get('company')
  const [saveAndSendVerifyPhoneNumberSms, saveAndSendVerifyPhoneNumberSmsStatus] = useMutation(SAVE_AND_SEND_VERIFY_PHONE_NUMBER_SMS)
  const sendSaveAndSendVerifyPhoneNumberSmsRequest = () => {
    saveAndSendVerifyPhoneNumberSms({
      variables: {
        phoneNumber: `+${phoneNumber}`,
      }
    }).then(() => {
      if (userData.ip_country !== 'CN') {
        window.location.href = `${window.location.origin}/easy-onboarding-verify-phone-number?company=${company}`
      } else {
        if (company === 'true') {
          window.location.href = `${window.location.origin}/easy-onboarding-company?country=${userData.ip_country}`
        } else {
          window.location.href = `${window.location.origin}/easy-onboarding?country=${userData.ip_country}`
        }
      }
    }).catch(() => {})
  }

  if (me && me.phone_verified) {
    return <Redirect to="/easy-onboarding" />
  }

  const tourVerifyPhone = [
    {
      target: '.easy-onboarding__verifyphonenumber',
      content: t('Please enter your Telephone number, you will receive a SMS in the next step. Please make sure your number is correct.'),
      disableBeacon: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
      }
    }
  ];

  return (
    <>
      <LoginHead />

      <div className="login__wrapper entry__wrapper-kyc easy-onboarding__verifyphonenumber">
        <div className="h2 entry__title">
          { t('Verify Phone Number') }
        </div>

        <div className="entry__top entry__top-clear">
          <div className="entry__text">
            { t('Please enter your mobile number') }
          </div>

          <div className="field">
            <div className="field__label">{' '}</div>
            <div className="field__wrap field__wrap-phone">
              <PhoneInput
                defaultCountry="li"
                value={phoneNumber}
                onChange={setPhoneNumber}
                preferredCountries={["li", "de"]}
                // excludeCountries={["us", "ca"]}
              />
            </div>
          </div>

          <div className="field">
            {saveAndSendVerifyPhoneNumberSmsStatus.loading && (
              <CircularProgress />
            )}
            {!saveAndSendVerifyPhoneNumberSmsStatus.loading && userData.ip_country !== 'CN' && (
              <button
                type="button"
                className="button entry__button w-full"
                onClick={sendSaveAndSendVerifyPhoneNumberSmsRequest}
              >
                { t('Send now') }
              </button>
            )}

            {!saveAndSendVerifyPhoneNumberSmsStatus.loading && userData.ip_country === 'CN' && (
              <button
                type="button"
                className="button entry__button w-full"
                onClick={sendSaveAndSendVerifyPhoneNumberSmsRequest}
              >
                { t('Continue') }
              </button>
            )}
          </div>
        </div>
      </div>

      {userData.rights === 'COMMON' && (
        <>
          {userData.kyc_status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED && (
            publicSettings.enable_product_tour && (
              <JoyRide steps={tourVerifyPhone} />
            )
          )}
        </>
      )}
    </>
  );
}

export default EasyOnBoardingRestorePassword;
