import gql from "graphql-tag";

export const GET_KOMPANY_DATA = gql`
  query getKompanyData($companyId: ID!) {
    getKompanyData(companyId: $companyId) {
      companyId
      requestType
      response
      created_at
      user {
        email
      }
    }
  }
`;
