import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import {
  useQuery,
  useMutation,
} from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { remove } from 'lodash'

import {
  CircularProgress,
  MenuItem,
} from '@material-ui/core'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import DateFnsUtils from '@date-io/date-fns'
import { toaster, getCountryList, getNationalityList } from '../utils'
import { yearlySalaryOptions } from '../utils/yearly_salary'
import { SOURCE_OF_FUNDS_STANDARD_SCHEMAS, SOURCE_OF_FUNDS_PUBLIC_SCHEMAS } from "../schemas/kycVerification";
import {
  INDUSTRY_OPTIONS,
  EDUCATION_OPTIONS,
  OCCUPATION_OPTIONS,
  COUNTRY_LIST,
  RESTRICTED_COUNTRY_LIST,
  RESTRICTED_NATIONALITY,
} from '../constants/customer'
import ImageUpload from '../components/ImageUpload'
import { TOKEN_TYPES } from '../constants/settings'

import {
  CUSTOMER_INDIVIDUAL,
  ADMIN_UPDATE_CUSTOMER_INDIVIDUAL,
  PUBLIC_SETTINGS,
} from '../queriesAndMutations'
import InputTheme from '../components/InputTheme'
import DropdownMaterial from '../components/DropdownMaterial'

const WHAT_WILL_YOU_USE_OPTIONS = [
  'Investing',
  'Reselling-related activities (broker/dealer)',
];

const WHAT_WILL_YOU_USE_OPTIONS_UTILITY_TOKEN = [
  'Staking',
  'Resale',
  'Usage of the Network',
  'Other',
];

const NET_INVESTABLE_ASSETS_OPTIONS = [
  'Up to 5.000 CHF',
  '5001 CHF - 25.000 CHF',
  '25001 CHF - 100.000 CHF',
  '100.001 CHF - 500.000 CHF',
  '500.001 CHF - 1 Mio CHF',
  '> 1 Mio - 5 Mio',
  '> 5 Mio',
];

const useTokenForOptionsXDCTEQ = [
  'Investing',
  'Other',
]
const useTokenForOptionsQdev = [
  'Blockchain Validation',
]

const AdminIndividualEditKyc = ({ match }) => {
  const { t } = useTranslation()
  const { data: { publicSettings = {} } = {} } = useQuery(PUBLIC_SETTINGS)
  const minBirthDate = moment().subtract(18, 'years');
  const currentHost = window.location.host;
  const isCryptoadvisory = currentHost === 'kyc.cryptoadvisory.li' || currentHost === 'dev-kyc.cryptoadvisory.li';
  const countryList = getCountryList(publicSettings, isCryptoadvisory);
  const nationalityList = getNationalityList(publicSettings, isCryptoadvisory);
  const isXDCTEQ = currentHost.includes('xdcteq');
  const isQdev = currentHost === 'kyc.qdev.li' || currentHost === 'qdevelopment-dev.investhub.io' || currentHost === 'localhost:3000';

  let whatWillYouUseOptions = publicSettings.token_type === TOKEN_TYPES.UTILITY ? WHAT_WILL_YOU_USE_OPTIONS_UTILITY_TOKEN : WHAT_WILL_YOU_USE_OPTIONS;

  if (isXDCTEQ) {
    whatWillYouUseOptions = useTokenForOptionsXDCTEQ;
  } else if (isQdev) {
    whatWillYouUseOptions = useTokenForOptionsQdev;
  }

  const getBaseCurrency = () => {
    if (!publicSettings || !publicSettings.kyc_use_base_currency) {
      return 'chf'
    }

    return publicSettings.base_currency
  }
  const baseCurrency = getBaseCurrency()

  const [updateData, setUpdateData] = useState({
    first_name: '',
    last_name: '',
    middle_name: '',
    phoneNumber: '',
    birthDate: '',
    uniqueId: '',

    placeOfBirth: '',
    nationality: '',
    countryOfResidence: '',
    residentialAddress: '',
    city: '',
    postalCode: '',

    website: '',
    education_level: '',
    employer_company_name: '',
    yearly_salary: '',
    occupation: '',
    net_investable_assets: '',
    what_will_you_use: '',
    source_of_funds: '',
    industry: '',
    industryOther: '',
    education_level_other: '',
    occupation_other: '',
    documentProofOfResidence: null,
    supportedSourceOfFundUploadFiles: [],
  })
  const kycId = +match.params.kycId
  const { data: {customerIndividual: data} = {}, loading } = useQuery(CUSTOMER_INDIVIDUAL, {
    variables: {
      id: kycId,
    },
  })

  const [adminUpdateCustomerIndividual, adminUpdateCustomerIndividualStatus] = useMutation(ADMIN_UPDATE_CUSTOMER_INDIVIDUAL);

  const removeDropSupportedSourceOfFundUploadFiles = (index) => {
    const newSupportedSourceOfFundUploadFiles = [...updateData.supportedSourceOfFundUploadFiles];
    const removedEle = remove(newSupportedSourceOfFundUploadFiles, (file, indx) => {
      return indx === index;
    });

    if (removedEle) {
      setUpdateData({
        ...updateData,
        supportedSourceOfFundUploadFiles: newSupportedSourceOfFundUploadFiles
      })
    }
  };

  const submitUpdate = () => {
    adminUpdateCustomerIndividual({
      variables: {
        id: kycId,
        input: updateData,
      }
    }).then(() => {
      toaster.success('KYC saved.')
    }).catch(() => {
      //
    })
  }

  const defaultSourceOfFundsSchema = publicSettings && publicSettings.public_sale_sof_sow ? SOURCE_OF_FUNDS_PUBLIC_SCHEMAS : SOURCE_OF_FUNDS_STANDARD_SCHEMAS;
  let sourceOfFundsSchema = SOURCE_OF_FUNDS_STANDARD_SCHEMAS;
  if (Object.prototype.hasOwnProperty.call(defaultSourceOfFundsSchema, updateData.source_of_funds.toLocaleLowerCase())) {
    sourceOfFundsSchema = defaultSourceOfFundsSchema;
  } else {
    sourceOfFundsSchema = publicSettings && publicSettings.public_sale_sof_sow ? SOURCE_OF_FUNDS_STANDARD_SCHEMAS : SOURCE_OF_FUNDS_PUBLIC_SCHEMAS;
  }

  const getDateValue = (date) => moment(date).format('YYYY-MM-DD')

  useEffect(() => {
    if (data) {
      const fetchedData = Object.keys(updateData).reduce((all, key) => ({
        ...all,
        [key]: data[key] || updateData[key],
      }), {})

      setUpdateData(fetchedData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (publicSettings && !publicSettings.allow_compliance_update_kyc) {
    toaster.error('Action is not allowed')
    return <Redirect to="/admin/kyc-list/individual" />
  }

  return (
    <>
      <div className="page__title h3">
        Edit KYC
        {' '}
        {data && data.email}
      </div>
      <div className="card">
        <div className="dashboard-application">
          {loading && (
            <CircularProgress />
          )}

          {!loading && (
            <div className="settings__fieldset">
              <div className="settings__row flex__row flex__wrap">
                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  propertyName="first_name"
                  label={t('First Name')}
                  state={updateData}
                  setState={setUpdateData}
                />

                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  propertyName="last_name"
                  label={t('Last Name')}
                  state={updateData}
                  setState={setUpdateData}
                />

                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  propertyName="middle_name"
                  label={t('Middle Name')}
                  state={updateData}
                  setState={setUpdateData}
                />

                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  propertyName="phoneNumber"
                  label={t('Phone Number')}
                  state={updateData}
                  setState={setUpdateData}
                />

                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  propertyName="uniqueId"
                  label={t('Identification Number')}
                  state={updateData}
                  setState={setUpdateData}
                />

                <div className="field" key="birthDate">
                  <div className="field__label">
                    {t('Birth Day')}
                    <span className="text__red"> *</span>
                  </div>

                  <div className="field__wrap field__wrap-date">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        maxDate={minBirthDate.toDate()}
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy"
                        required
                        margin="normal"
                        value={updateData.birthDate}
                        onChange={value => setUpdateData({...updateData, birthDate: getDateValue(value)})}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        openTo="year"
                        views={["year", "month", "date"]}
                        id="date-picker-date-of-birth"
                        invalidDateMessage={t('Invalid Date Format')}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>

                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  propertyName="placeOfBirth"
                  label={t('Place Of Birth')}
                  state={updateData}
                  setState={setUpdateData}
                />

                <DropdownMaterial
                  label={t('Nationality')}
                  isRequired
                  state={updateData}
                  setState={setUpdateData}
                  propertyName="nationality"
                  isBackgroundWhite
                >
                  {nationalityList.map((value) => (
                    <MenuItem value={value} key={value}>{value}</MenuItem>
                  ))}
                </DropdownMaterial>

                <DropdownMaterial
                  label={t('Country of Residence')}
                  isRequired
                  state={updateData}
                  setState={setUpdateData}
                  propertyName="countryOfResidence"
                  isBackgroundWhite
                >
                  {countryList.map((value) => (
                    <MenuItem value={value} key={value}>{value}</MenuItem>
                  ))}
                </DropdownMaterial>

                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  propertyName="city"
                  label={t('City')}
                  state={updateData}
                  setState={setUpdateData}
                />

                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  propertyName="residentialAddress"
                  label={t('Residential Address')}
                  state={updateData}
                  setState={setUpdateData}
                />

                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  propertyName="postalCode"
                  label={t('Postal Code')}
                  state={updateData}
                  setState={setUpdateData}
                />

                <DropdownMaterial
                  label={t('Educational Qualifications')}
                  isRequired
                  state={updateData}
                  setState={setUpdateData}
                  propertyName="education_level"
                  isBackgroundWhite
                >
                  {EDUCATION_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option}>
                      {t(option)}
                    </MenuItem>
                  ))}
                </DropdownMaterial>

                {updateData.education_level === 'Other' && (
                  <InputTheme
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={t('Describe details of educational qualifications')}
                    propertyName="education_level_other"
                    state={updateData}
                    setState={setUpdateData}
                  />
                )}

                <DropdownMaterial
                  label={t('Occupation')}
                  isRequired
                  state={updateData}
                  setState={setUpdateData}
                  propertyName="occupation"
                  isBackgroundWhite
                >
                  {OCCUPATION_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option}>
                      {t(option)}
                    </MenuItem>
                  ))}
                </DropdownMaterial>

                {updateData.occupation === 'Other' && (
                  <InputTheme
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={t('Describe details of occupation')}
                    propertyName="occupation_other"
                    state={updateData}
                    setState={setUpdateData}
                  />
                )}

                {publicSettings.kyc_require_industry && (
                  <>
                    <DropdownMaterial
                      label={t('Industry')}
                      propertyName="industry"
                      state={updateData}
                      setState={setUpdateData}
                      isGray
                    >
                      {
                        INDUSTRY_OPTIONS.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                            {option === 'Other' && ' (please describe)'}
                          </MenuItem>
                        ))
                      }
                    </DropdownMaterial>

                    {updateData.industry === 'Other' && (
                      <InputTheme
                        classWrapper="field"
                        classLabel="field__label"
                        classInput="field__input"
                        required
                        propertyName="industryOther"
                        label={t('Describe details of industry')}
                        state={updateData}
                        setState={setUpdateData}
                      />
                    )}
                  </>
                )}

                <InputTheme
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  propertyName="employer_company_name"
                  label={t('Companies Name')}
                  state={updateData}
                  setState={setUpdateData}
                />

                <DropdownMaterial
                  label={t('Annual personal earnings')}
                  propertyName="yearly_salary"
                  state={updateData}
                  setState={setUpdateData}
                  isGray
                >
                  {
                    yearlySalaryOptions.map((option) => (
                      <MenuItem key={option} value={option.replaceAll('CHF', baseCurrency.toUpperCase())}>
                        {option.replaceAll('CHF', baseCurrency.toUpperCase())}
                      </MenuItem>
                    ))
                  }
                </DropdownMaterial>

                <DropdownMaterial
                  label={t('Total Wealth / Assets')}
                  propertyName="net_investable_assets"
                  state={updateData}
                  setState={setUpdateData}
                  isGray
                >
                  {
                    NET_INVESTABLE_ASSETS_OPTIONS.map((option) => (
                      <MenuItem key={option} value={option.replaceAll('CHF', baseCurrency.toUpperCase())}>
                        {option.replaceAll('CHF', baseCurrency.toUpperCase())}
                      </MenuItem>
                    ))
                  }
                </DropdownMaterial>

                <DropdownMaterial
                  label={t('What is the purpose of your token purchase?')}
                  propertyName="what_will_you_use"
                  state={updateData}
                  setState={setUpdateData}
                  isGray
                >
                  {whatWillYouUseOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </DropdownMaterial>

                <DropdownMaterial
                  label={t('Primary source of funds for this purpose')}
                  propertyName="source_of_funds"
                  state={updateData}
                  setState={setUpdateData}
                  isGray
                >
                  {Object.keys(sourceOfFundsSchema).map((key) => (
                    <MenuItem key={key} value={key.toUpperCase()}>
                      {t(sourceOfFundsSchema[key].label)}
                    </MenuItem>
                  ))}
                </DropdownMaterial>
              </div>

              <div className="settings__row flex__row flex__wrap">
                <div className="field">
                  {!updateData.documentProofOfResidence && (
                    <div className="field__wrap field__wrap-imageupload">
                      <ImageUpload
                        buttonText={t('Click or drop images')}
                        labelText={
                          <span className="field__label">
                            {`${t('Proof of residence')} (${t('Documents must not older than 3 months.')})`}
                          </span>
                         }
                        buttonClassName="button"
                        onChange={(files) => {
                          setUpdateData({
                            ...updateData,
                            documentProofOfResidence: files[0]
                          })
                        }}
                        maxFileSize={10485760}
                        withPreview
                        singleImagePick
                        singleFile
                        defaultImages={[]}
                        accept='image/*, application/pdf'
                      />
                    </div>
                  )}
                  {updateData.documentProofOfResidence && (
                    <>
                      <span className="field__label">
                        {`${t('Proof of residence')} (${t('Documents must not older than 3 months.')})`}
                      </span>
                      <span>{" "}</span>
                      <div className="settings__top">
                        <div className="settings__label">
                          {updateData.documentProofOfResidence.name}
                        </div>

                        <button
                          onClick={() => { setUpdateData({ ...updateData, documentProofOfResidence: null }) }}
                          className="button-stroke button-small settings__button"
                        >
                          {t('Remove')}
                        </button>
                      </div>
                    </>
                  )}
                </div>

                <div className="field">
                  <div className="field__wrap field__wrap-imageupload">
                    <ImageUpload
                      labelText={ t('Upload supporting documents to demonstrate the Source of Funds for this Purchase.') }
                      buttonText={t('Click or drop images')}
                      buttonClassName="button"
                      onChange={(files) => {
                        setUpdateData({
                          ...updateData,
                          supportedSourceOfFundUploadFiles: [
                            ...updateData.supportedSourceOfFundUploadFiles,
                            ...files,
                          ]
                        })
                      }}
                      maxFileSize={10485760}
                      withPreview
                      singleImagePick
                      defaultImages={[]}
                      accept='image/*, application/pdf'
                    />
                  </div>

                  {!!updateData.supportedSourceOfFundUploadFiles.length && updateData.supportedSourceOfFundUploadFiles.map((file, index) => (
                    <React.Fragment key={index}>
                      <div className="space space__32">{' '}</div>
                      <div className="settings__top">
                        <div className="settings__label">
                          {(file && file.name) || 'Cannot load file'}
                        </div>

                        <button
                          className="button-stroke button-small settings__button"
                          onClick={(e) => {
                            removeDropSupportedSourceOfFundUploadFiles(index);
                          }}
                        >
                          { t('Remove') }
                        </button>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>

              <div>
                {adminUpdateCustomerIndividualStatus.loading ? (
                  <CircularProgress />
                ) : (
                  <button className="button" onClick={submitUpdate}>{t('Update')}</button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default AdminIndividualEditKyc
