import React, { useState } from 'react'
import { hasUserEnoughRights } from '../utils/me'
import { USER_RIGHT_TYPES } from '../constants/user'
import { useMe } from '../myHooks'
import classNames from 'classnames'
import { VIDEO_IDENT_LOG_LIST } from '../queriesAndMutations'
import { useQuery } from '@apollo/react-hooks'
import TablePaginationActions from '../components/TablePagination'
import { useTranslation } from 'react-i18next'
import Icon from '../components/Icon'

const AdminVideoIdentLog = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [reqOptions, setReqOptions] = useState({
    user_email: "",
  });
  const { data: { me } = {} } = useMe();
  const { data, error } = useQuery(VIDEO_IDENT_LOG_LIST, {
    variables: {
      input: {
        ...reqOptions,
        page,
        pageSize,
      },
    },
  });

  const onChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onChangePageSize = (event) => {
    if (parseInt(event.target.value, 10)) {
      setPageSize(parseInt(event.target.value, 10));
      setPage(0);
    } else {
      setPageSize(1);
      setPage(0);
    }
  };

  const onChangeReqOptions = (name) => (event) => {
    switch (name) {
      case "user_email":
        if (event.target.value.length >= 50) {
          return false;
        }
        break;
      default:
    }
    setPage(0);
    return setReqOptions({ ...reqOptions, [name]: event.target.value });
  };

  if (error) {
    return <div>{ t('Some error') }</div>;
  }

    return (
      <>
        <div className="page__title h3">{ t('Liveness Check Failed Log') }</div>

        {hasUserEnoughRights(me.rights, USER_RIGHT_TYPES.ADMIN) && (
          <div className="transactions card">
            <div className="products__head flex__row flex__wrap flex__justify_between">
              <div className="title-primary products__title">{ t('Overview') }</div>
              <form className="form">
                <input
                  type="search"
                  className="form__input"
                  placeholder={t('Type email in search')}
                  value={reqOptions.user_email}
                  onChange={onChangeReqOptions(
                    "user_email"
                  )}
                />
                <button className="form__button">
                  <Icon
                    className={classNames('searchh')}
                    name='searchh'
                    size="24"
                  />{" "}
                </button>
              </form>
            </div>

            <div className="products__wrapper">
              <div className="products__table products__table-adminvideoident">
                <div className="products__row">
                  <div className="products__col products__col-first-clear">
                    { t('User Email') }
                  </div>

                  <div className="products__col">
                    { t('Error') }
                  </div>

                  <div className="products__col">
                    { t('Message') }
                  </div>
                </div>

                {data &&
                  data.videoIdentLogList &&
                  data.videoIdentLogList.objects.map(
                    (log) => (
                      <div
                        key={log.id}
                        className="products__row"
                      >
                        <div className="products__col products__col-first-clear">
                          <div className="products__label">{t('User Email')}</div>
                          <div className="products__box">
                            <div className="products__item products__item-normal">
                              {log.user_email}
                            </div>
                          </div>
                        </div>

                        <div className="products__col">
                          <div className="products__label">{t('Error')}</div>
                          <div className="products__box">
                            <div className="products__item products__item-normal">
                              {log.error}
                            </div>
                          </div>
                        </div>

                        <div className="products__col">
                          <div className="products__label">{t('Message')}</div>
                          <div className="products__box">
                            <div className="products__item products__item-normal">
                              {log.message}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>

            {data && data.videoIdentLogList && (
              <TablePaginationActions
                count={
                  data.videoIdentLogList.meta
                    .total
                }
                page={page}
                rowsPerPage={pageSize}
                onChangePage={onChangePage}
                onChangeRows={onChangePageSize}
                rowsPerPageOptions={[1, 5, 10, 25]}
              />
            )}
          </div>
        )}
      </>
    );
};

export default AdminVideoIdentLog;
