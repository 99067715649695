import React, { useState } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const HeaderLoginRegisterRwd = ({children}) => {
  const [popOverValues, setPopOverValues] = useState({
    isPopoverActive: false,
  });
  const onPopoverActive = () => {
    setPopOverValues({...popOverValues, isPopoverActive: !popOverValues.isPopoverActive});
  }
  const onPopoverAwayActive = () => {
    setPopOverValues({...popOverValues, isPopoverActive: false});
  }

  return (
    <div className="login__head login__head-kyc">
      <div className="login__info d__flex flex__row flex__nowrap flex__justify_end flex__align_center">
        <ClickAwayListener onClickAway={onPopoverAwayActive}>
          <div className="login__info-rwd">
            <div className={`actions actions__kyc-2 ${popOverValues.isPopoverActive ? "active" : ""}`}>
              <button className="actions__button header__burger" onClick={onPopoverActive}>{' '}</button>

              <div className="actions__body">

                {children}

              </div>
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </div>
  )
}

export default HeaderLoginRegisterRwd;
