import React from 'react'
// import { NavLink } from 'react-router-dom'
// import { TOKEN_TYPES } from '../constants/settings'
import { useTranslation, Trans } from 'react-i18next'

const DisclaimersKycIndividual = ({ publicSettings, classes, customer }) => {
  const { t } = useTranslation();

  if (publicSettings.token_type === "UTILITY") {
    return (
      <>
        <div className="settings__fieldset">
          <ul className="login__list legaldisclaimers__list">
            {publicSettings.kyc_client_statement && (
              <li>
                {t('I hereby confirm that I myself am exclusively beneficially entitled to the assets for the acquisition of the tokens and that I am acting for my own account, in my own economic interest and not on behalf of others.')}
              </li>
            )}
            {/* <li>
              { t('Under the terms of the Commpany\'s Privacy Policy, I hereby Consent to my personal data being processed by the Company and its affiliated companies or agents (such as Banks, Tax Advisor, Auditors and Compliance services)') }{" "}
              <NavLink to="/declaration-of-consent" target="_blank">
                { t('See The Declaration Of Consent') }
              </NavLink>
            </li>
            <li>
              { t('I Hereby Declare That I Have Been Provided With The') }{" "}
              <NavLink to="/privacy-policy" target="_blank">{ t('Privacy Policy') }</NavLink> { t('Of The Seller And That I Have Been Informed About The Details Of The Data Processing As Well As About My Data Protection Rights.') }
            </li> */}
          </ul>
        </div>
      </>
    );
  } else if (publicSettings.token_type === "NONE") {
    return (
      <>
        <div className="settings__fieldset">
          <ul className="login__list legaldisclaimers__list">
            {/* {publicSettings.kyc_acknowledgement_of_receipt && (
              <li>
                { t('I Confirm That I Act On My Own Account, In My Own Economic Interests And Not On Behalf Of Others.') }
              </li>
            )} */}

            {publicSettings.kyc_client_statement && (
              <li>
                { t('I confirm that I am the beneficial owner of the invested assets.') }
              </li>
            )}

            {/* {publicSettings.kyc_confirmations && (
              <li>
                { t('I Hereby Confirm That, Before Subscribing, I Have Received And Read The Token Sale Agreement Of The Issuer Promptly And In Full And In Particular That') }
                <ul className={classes.confirmParagraph}>
                  <li>{ t('I Accept The Subscription Applications') }</li>
                  <li>
                    { t('Especially I Certify That') }
                    <ul>
                      { (publicSettings.enable_blacklist_citizen && publicSettings.blacklist_citizen_choosen) && (
                        <li>
                          {t('Am Not A Citizen Of The CITIZEN', {CITIZEN: publicSettings.blacklist_citizen_choosen})}
                        </li>
                      )}
                      <li>
                        { t('Do Not Hold A Permanent Residence And Work Permit For The US (Green Card), Canada Or Australia') }
                      </li>
                      <li>
                        { t('Have No Residence Or Principal Place Of Business In The USA, Canada Or Australia Or Their Respective Territories') }
                      </li>
                      <li>
                        { t('Am Not A Corporation Or Any Other Asset Organized Under The Laws Of The United States, Canada Or Australia, The Income Of Which Is Governed By The Laws Of Canada Or Australia') }
                      </li>
                      <li>
                        { t('Am Not On Any Of The Sanction Lists Of The European Union, The United States, Canada Or Australia') }
                      </li>
                    </ul>
                  </li>
                  <li>
                    { t('I Have Duly Noted The Risks And Their Potential Implications Described In The Token Sale Agreement And Hereby Accept Them') }
                  </li>
                </ul>
              </li>
            )}

            <li>
              { t('I Hereby Consent To My Personal Data Being Processed By The Issuer And All Companies Affiliated With It, As Well As The Persons And Entities Involved In The Management And Supervision Of My Investment (In Particular The Issuer’s Business, Banks, Tax Advisors, Auditors) Being Stored In Computer Equipment And Be Used.') }
            </li>

            <li>
              { t('I Hereby Confirm That The Declarations Made To The Best Of My Knowledge And Belief Are Correct And Complete. Any Changes To The Aforementioned Circumstances Will Be Communicated To The Issuer Without Delay In Written Form And Must Be Forwarded An Updated Self-Assessment Within 30 Days Upon Request.') }
            </li>

            <li>
              { t('I Have Read The Aforementioned “Warnings” And Especially The “Risk Information”') }
            </li> */}
          </ul>
        </div>
      </>
    );
  } else if (publicSettings.token_type === "LIQUIDATION") {
    return (
      <>
        <div className="settings__fieldset">
          <ul className="login__list legaldisclaimers__list">
            {publicSettings.kyc_client_statement && (
              <li>
                { t('liquidationClientStatement', {
                  TOKEN_NAME: publicSettings.token_name
                }) }
              </li>
            )}
            {/* <li>
              { t(`I Hereby agree that I will receive the compensation, once the Lawyer approved the change of claim from you as ${customer.first_name} ${customer.middle_name} ${customer.last_name} to ${publicSettings.company.name}`) }
            </li>
            <li>
              { t(`I Hereby Consent To My Personal Data Being Processed By ${publicSettings.company.name} And All Companies Affiliated With It, As Well As The Persons And Entities Involved In The Management And Supervision Of My Investment (In Particular ${publicSettings.company.name}’s Business, Banks, Tax Advisors, Auditors) Being Stored In Computer Equipment And Be Used. The Data Collected May Also Be Used For The Purposes Of Advertising By Email And Post, Regardless Of A Contractual Relationship.`) }
            </li>
            <li>
              { t(`I Hereby Declare That I Have Been Provided With The Privacy Policy Of ${publicSettings.company.name}`) }
            </li>
            <li>
              { t(`I Hereby Confirm That The Declarations Made To The Best Of My Knowledge And Belief Are Correct And Complete. Any Changes To The Aforementioned Circumstances Will Be Communicated To ${publicSettings.company.name} Without Delay In Written Form And Must Be Forwarded An Updated Self-Assessment Within 30 Days Upon Request.`) }
            </li> */}
          </ul>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="settings__fieldset">
        <ul className="login__list legaldisclaimers__list">
          {/* {publicSettings.kyc_acknowledgement_of_receipt && (
            <li>
              { t('I Confirm That I Act On My Own Account, In My Own Economic Interests And Not On Behalf Of Others. Or The Beneficial Owner Is, If Different From The Subscriber') }
            </li>
          )} */}
          {publicSettings.kyc_client_statement && (
            <li>
              {t('I hereby confirm that I myself am exclusively beneficially entitled to the assets for the acquisition of the tokens and that I am acting for my own account, in my own economic interest and not on behalf of others.')}
            </li>
          )}

          {customer.referal_code === 'BlackMantaCapital' &&
            <li>
              <Trans
                i18nKey="equityDeclarationOfConsent4_BlackMantaCapital"
                t={t}
                components={{
                  FULL_NAME: `${customer.firstName} ${customer.middleName} ${customer.lastName}`,
                  COMPANY: publicSettings.company.name,
                  consentLink: <></>,
                  blackMantaLink: <></>,
                }}
              />
            </li>
          }

          {/* <li>
            { t('Hereby Confirm The Receipt Of The Prospectus Of The Issuer Dated') }{" "}
            {publicSettings.prospectusDate} { t('Regarding The') }
            {publicSettings.token_type === TOKEN_TYPES.BOND ? "Bond" : "Shares"}
            { t('Which Among Other Things Contain The Information According To Art. 5 Liechtenstein Act On Distance Marketing Of Consumer Financial Services.') }
          </li>

          <li>
            <Trans
              i18nKey="I Hereby Confirm To Have Been Informed About The Right Of Withdrawal."
              t={t}
              components={{ rightOfWithdrawalLink: <></>}}
            />
          </li>

          <li>
            { t('I Hereby Declare And Confirm That, At The Same Time As The Subscription Is Accepted, I Am Requested To Transfer The Subscription Amount To The Following Special Account Of The Issuer Within The Duration Of The Offer. Please Refer To Further Instructions In Your My Account (This Account Will Be Created Once The KYC Form Has Been Successfully Submitted)')}
          </li>

          {publicSettings.kyc_confirmations && (
            <li>
              { t('I Hereby Confirm That, Before Subscribing, I Have Received And Read The Prospectus Of The Issuer Dated') } {publicSettings.prospectusDate}{" "}
              { t('Regarding The Shares, In Particular The Risk Information, Promptly And In Full And I Agree With The Content Of The Prospectus, And In Particular That') }
              <ul className={classes.confirmParagraph}>
                <li>{ t('I Accept The Subscription Applications') }</li>
                <li>
                  { t('I Have Duly Noted The Sales Restrictions Stipulated In The Prospectus And Hereby Confirm That Those Restrictions Are Observed, Especially I Certify That I As A Single Natural Person Or Legal Entity') }
                  <ul>
                    <li>{ t('Am Not A Citizen Of The USA, Canada And Australia') }</li>
                    <li>
                      { t('Do Not Hold A Permanent Residence And Work Permit For The US (Green Card), Canada Or Australia') }
                    </li>
                    <li>
                      { t('Have No Residence Or Principal Place Of Business In The USA, Canada Or Australia Or Their Respective Territories') }
                    </li>
                    <li>
                      { t('Am Not A Corporation Or Any Other Asset Organized Under The Laws Of The United States, Canada Or Australia, The Income Of Which Is Governed By The Laws Of Canada Or Australia') }
                    </li>
                    <li>
                      { t('Am Not On Any Of The Sanction Lists Of The European Union, The United States, Canada Or Australia') }
                    </li>
                  </ul>
                </li>
                <li>
                  <Trans
                    i18nKey="I Have Duly Noted The Risks And Their Potential Implications Described In The Prospectus And Hereby Accept Them"
                    t={t}
                    components={{
                      prospectusLink: <></>
                    }}
                  />
                </li>
              </ul>
            </li>
          )}

          <li>
            <Trans
              i18nKey="equityDeclarationOfConsent5"
              t={t}
              components={{
                policyLink: <></>
              }}
            />
          </li>
          <li>
            { t('I Hereby Confirm That The Declarations Made To The Best Of My Knowledge And Belief Are Correct And Complete. Any Changes To The Aforementioned Circumstances Will Be Communicated To The Issuer Without Delay In Written Form And Must Be Forwarded An Updated Self-Assessment Within 30 Days Upon Request.') }
          </li>

          <li>
            { t('equityDeclarationOfConsent7') }
          </li> */}
        </ul>
      </div>
    </>
  );
};

export default DisclaimersKycIndividual;
