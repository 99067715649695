import gql from 'graphql-tag'

export const EXCHANGE_RATES = gql`
    query exchangeRates {
      exchangeRates {
        token_to_eth
        eth_to_usd
        eth_to_eur
        eth_to_chf
        eth_to_usdt
        eth_to_xdc
        eth_to_polygon
        eth_to_dai
      }
    }
  `;
