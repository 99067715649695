import React, { useState } from 'react'
import AdminUsersAndKycStatistics from './AdminUsersAndKycStatistics'
import ContributionCountries from './ContributionCountries'
import AdminAmountStatistics from './AdminAmountStatistics'
import Amounts from './Amounts'
import TransactionList from '../../components/TransactionList'
import { useTranslation } from 'react-i18next'
import { TRANSACTIONS_ADMIN } from '../../queriesAndMutations'

const AdminPanel = () => {
  const { t } = useTranslation();
  const [setFilteredStatus] = useState(null);

  return (
    <>
      <div className="page__title h3">{ t('Admin') }</div>

      <div className="page__row">
        <div className="page__col">
          <AdminAmountStatistics />

          <Amounts />
        </div>

        <div className="page__col">
          <AdminUsersAndKycStatistics />

          <ContributionCountries />
        </div>
      </div>

      <div className="transactions customer card">
        <TransactionList
          isAdmin
          query={TRANSACTIONS_ADMIN}
          filteredStatus={(newStatuses) =>
            setFilteredStatus(newStatuses)
          }
        />
      </div>
    </>
  )
}

export default AdminPanel
