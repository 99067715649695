import React, { useState } from 'react'
import moment from 'moment-timezone'
import { useQuery } from '@apollo/react-hooks'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

import { GET_DASHBOARD_CONTRIBUTION_STATISTIC_BY_DATE } from '../../../../queriesAndMutations'

const InvestmentAmount = () => {
  const [range] = useState('LAST_12_MONTHS'); // LAST_7_DAYS | LAST_30_DAYS | LAST_12_MONTHS
  const { data: { getDashboardContributionStatisticByDate: data } = {} } = useQuery(GET_DASHBOARD_CONTRIBUTION_STATISTIC_BY_DATE, {
    variables: {
      range,
      type: 'INVESTMENT',
    }
  })
  const dateFormat = range === 'LAST_12_MONTHS' ? 'MMM YYYY' : 'MMM D'

  return (
    <div className="products__tab js-tabs-item active">
      <div className="card__chart card__chart_product-sales relative">
        {data && (
          <ResponsiveContainer
            width="100%"
            height="100%"
            className="apexcharts-canvas apexcharts-theme-light kyc-barchart"
          >
            <BarChart
              width={500}
              height={335}
              data={data.map(bar => ({ ...bar, date: moment(bar.date).tz('UTC').format(dateFormat) }))}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              barSize={40}
              barGap={8}
            >
              <CartesianGrid
                strokeDasharray="none"
                stroke="#EFEFEF"
                vertical={false}
              />
              <XAxis
                dataKey="date"
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12, fontWeight: "500", fill: "#9A9FA5" }}
                padding={{ left: 10 }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12, fontWeight: "500", fill: "#9A9FA5" }}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: "#272B30",
                  borderColor: "rgba(255, 255, 255, 0.12)",
                  borderRadius: 8,
                  boxShadow:
                    "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
                }}
                labelStyle={{ fontSize: 12, fontWeight: "500", color: "#fff" }}
                itemStyle={{
                  padding: 0,
                  textTransform: "capitalize",
                  fontSize: 12,
                  fontWeight: "600",
                  color: "#fff",
                }}
                cursor={{ fill: "rgb(243 242 243 / 0%)" }}
              />
              <Bar dataKey="totalContribution" fill="#B5E4CA" />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  )
};

export default InvestmentAmount;
