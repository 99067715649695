import React from 'react'

const Radio = ({
  // eslint-disable-next-line react/prop-types
  keyIndex,
  value,
  onChange,
  nameGroup,
  defaultChecked,
  radioText,
  isGrayText,
  isSmallSpace,
  isRadioGroupHorizontal,
  isRadioGroupVertical,
  disabled
}) => {
  let radioTypes;

  if (isRadioGroupHorizontal) {
    radioTypes = <div className={`field__radio-group_horizontal ${isGrayText ? "field__text-gray" : ""} ${isSmallSpace ? "field__small-space" : ""} ${disabled ? "field__radio-disabled" : ""}`} key={keyIndex}>
      <label className="radio">
        <input
          type="radio"
          className="radio__input"
          value={value}
          onChange={onChange}
          name={nameGroup}
          checked={defaultChecked}
          disabled={disabled}
        />

        <div className="radio__inner">
          <div className="radio__tick">{' '}</div>

          <div className="radio__text">
            {radioText}
          </div>
        </div>
      </label>
    </div>
  } else if (isRadioGroupVertical) {
    radioTypes = <div className={`field__radio-group_vertical ${isGrayText ? "field__text-gray" : ""} ${isSmallSpace ? "field__small-space" : ""} ${disabled ? "field__radio-disabled" : ""}`} key={keyIndex}>
      <label className="radio">
        <input
          type="radio"
          className="radio__input"
          value={value}
          onChange={onChange}
          name={nameGroup}
          checked={defaultChecked}
          disabled={disabled}
        />

        <div className="radio__inner">
          <div className="radio__tick">{' '}</div>

          <div className="radio__text">
            {radioText}
          </div>
        </div>
      </label>
    </div>
  } else {
    radioTypes = <div className={`field ${isGrayText ? "field__text-gray" : ""} ${isSmallSpace ? "field__small-space" : ""} ${disabled ? "field__radio-disabled" : ""}`} key={keyIndex}>
      <label className="radio">
        <input
          type="radio"
          className="radio__input"
          value={value}
          onChange={onChange}
          name={nameGroup}
          checked={defaultChecked}
          disabled={disabled}
        />

        <div className="radio__inner">
          <div className="radio__tick">{' '}</div>

          <div className="radio__text">
            {radioText}
          </div>
        </div>
      </label>
    </div>
  }
  return (
    <>
      {radioTypes}
    </>
  )
}

export default Radio;
