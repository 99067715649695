import React from 'react'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import AppSidebar from '../Sidebar'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { isIE } from "react-device-detect"
import NotSupportViewer from '../NotSupportViewer'

const PageDashboard = ({
  children,
  isContainerFull,
  // from HOCs
  location,
}) => {
  if (isIE) {
    return <NotSupportViewer />;
  }

  return (
    <div className={`page ${location.pathname.indexOf("/admin") === 0 ? "page-admin" : ""}`}>
      <AppSidebar />

      <div className="page__inner">
        <div className={classNames('page__container', {'wide' : isContainerFull})}>
          {children}
        </div>
      </div>
    </div>
  );
}

PageDashboard.propTypes = {
  // from HOCs
  location: PropTypes.object,
};

export default compose(withRouter)(PageDashboard);
