import React from 'react'
import { useQuery } from '@apollo/react-hooks'

import { useTranslation } from 'react-i18next'
import JoyRide from 'react-joyride'
import MenuItem from '@material-ui/core/MenuItem'
import { usePublicSettings, useExchangeRates, useMe } from '../../../myHooks'
import { INDUSTRY_OPTIONS, EDUCATION_OPTIONS, OCCUPATION_OPTIONS, POSITION_OPTIONS } from '../../../constants/customer'
import { USER_KYC_STATUS_TYPES } from '../../../constants/user'
import InputThemeField from '../../../components/InputThemeField'
import DropdownMaterial from '../../../components/DropdownMaterial'
import { ethToFiat, tokenToEth } from '../../../utils/rate'
import { TOKEN_TYPES } from '../../../constants/settings'
import {
  GET_MY_PERSONAL_DISCOUNT,
} from '../../../queriesAndMutations'

const CardForm = ({
  values,
  setValues,
  goToNextStep,
  goToPrevStep,
  skipThisStep,
}) => {
  const { t } = useTranslation();

  const {
    data: { me: userData } = {},
    loading,
  } = useMe()
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const tourBoardingDetailEducation = [
    {
      target: '.card-easyonboarding__education .settings__fieldset',
      content: t('In order to comply with local laws regarding money laundering and terrorist financing, we need some additional information regarding your educational qualifications, occupation, industry, your employer, your position and your employer\'s website.'),
      disableBeacon: true,
      hideCloseButton: true,
      spotlightClicks: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
    {
      target: '.card-easyonboarding__education .settings__button .button',
      content: t('Here it goes further to the asset origin'),
      disableBeacon: true,
      hideCloseButton: true,
      spotlightClicks: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    }
  ]
  const isShowingIndustry = (publicSettings && publicSettings.kyc_require_industry) || false;
  const isShowingEducationLevel = (publicSettings && publicSettings.kyc_education_level) || false;
  const isShowingOccupation = (publicSettings && publicSettings.kyc_occupation) || false;
  const isShowingEmployerCompanyName = (publicSettings && publicSettings.kyc_employer_company_name) || false;
  const { data: { exchangeRates: baseExchangeRate } = {} } = useExchangeRates()
  const { data: { getMyPersonalDiscount: myPersonalDiscount } = {} } = useQuery(GET_MY_PERSONAL_DISCOUNT)

  const exchangeRates = publicSettings && publicSettings.enable_personal_discount && myPersonalDiscount
    ? myPersonalDiscount
    : baseExchangeRate

  const baseCurrency =
    (publicSettings && publicSettings.kyc_use_base_currency && publicSettings.base_currency) || "chf";

  const tokenToBaseCurrency =
    exchangeRates &&
    ethToFiat(
      baseCurrency,
      tokenToEth(values.amount, exchangeRates),
      exchangeRates
    );

  const getIntendedInvestmentOptions = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return []
    }

    return Object.values(publicSettings.kyc_levels).filter((level) => !level.enable || level.enable.enable_lvl).map((level) => {
      if (level.enable && level.enable.enable_open_end) {
        return `> ${level.min_invest_amount} ${baseCurrency.toUpperCase()}`
      }
      return `${level.min_invest_amount} - ${level.max_invest_amount} ${baseCurrency.toUpperCase()}`
    })
  }

  let currentLevel = publicSettings.kyc_levels ? Object.values(publicSettings.kyc_levels).filter((item) => {
    return !item.enable || item.enable.enable_lvl;
  }).reduce((all, item) => all + (tokenToBaseCurrency >= item.min_invest_amount ? 1 : 0), 0) : 0;

  if (publicSettings.token_type === TOKEN_TYPES.NONE) {
    currentLevel = getIntendedInvestmentOptions().indexOf(values.intended_investment) + 1
  }

  if (publicSettings) {
    if (
      !isShowingEducationLevel &&
      !isShowingOccupation &&
      !isShowingIndustry &&
      !isShowingEmployerCompanyName
    ) {
      skipThisStep();

      return null;
    }
  }
  return (
    <div className="card card-easyonboarding__education">
      <div className="settings__wrapper">
        <div className="card__head card__head-kyc-1">
          <div className="title-green card__title">
            {t('Education')}
          </div>
          <div className="refund__content card__head-content">
            <p>{ t('Due to regulation we require more information about your Source of Wealth and Source of Funds. Alongside some more personal information regarding your residential address and Nationality.') }</p>
          </div>
        </div>

        <div className="settings__fieldset">
          {isShowingEducationLevel && (
            <>
              <DropdownMaterial
                classWrapper="easy-onboarding__individual-educationalqualification"
                label={t('Educational Qualifications')}
                isRequired
                state={values}
                setState={setValues}
                propertyName="education_level"
                isBackgroundWhite
              >
                {EDUCATION_OPTIONS.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t(option)}
                  </MenuItem>
                ))}
              </DropdownMaterial>

              {values.education_level === 'Other' && (
                <InputThemeField
                  classWrapper="field easy-onboarding__individual-educationqualificationdetails"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  label={t('Describe details of educational qualifications')}
                  value={values.education_level_other}
                  onChange={({ target: { value } }) => setValues({ ...values, education_level_other: value})}
                />
              )}
            </>
          )}

          {isShowingOccupation && (
            <>
              <DropdownMaterial
                label={t('Occupation')}
                classWrapper="easy-onboarding__individual-occupation"
                isRequired
                state={values}
                setState={setValues}
                propertyName="occupation"
                isBackgroundWhite
              >
                {OCCUPATION_OPTIONS.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t(option)}
                  </MenuItem>
                ))}
              </DropdownMaterial>

              {values.occupation === 'Other' && (
                <InputThemeField
                  classWrapper="field easy-onboarding__individual-occupationdetails"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  label={t('Describe details of occupation')}
                  value={values.occupation_other}
                  onChange={({ target: { value } }) => setValues({ ...values, occupation_other: value})}
                />
              )}
            </>
          )}

          {isShowingIndustry && (
            <>
              <DropdownMaterial
                label={t('Industry')}
                classWrapper="easy-onboarding__individual-industry"
                isRequired
                state={values}
                setState={setValues}
                propertyName="industry"
                isBackgroundWhite
              >
                {INDUSTRY_OPTIONS.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t(option)}
                    {option === 'Other' && t(' (please describe)')}
                  </MenuItem>
                ))}
              </DropdownMaterial>

              {values.industry === 'Other' && (
                <InputThemeField
                  classWrapper="field easy-onboarding__individual-industrydetails"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  label={t('Describe details of industry')}
                  value={values.industryOther}
                  onChange={({ target: { value } }) => setValues({ ...values, industryOther: value})}
                />
              )}
            </>
          )}

          {isShowingEmployerCompanyName && (
            <InputThemeField
              classWrapper="field easy-onboarding__individual-companyname"
              classLabel="field__label"
              classInput="field__input"
              required
              label={t('Company name')}
              value={values.employer_company_name}
              onChange={({ target: { value } }) => setValues({ ...values, employer_company_name: value})}
            />
          )}

          {!!publicSettings && publicSettings.kyc_show_position && (
            <>
              <DropdownMaterial
                label={t('Position')}
                classWrapper="easy-onboarding__individual-position"
                isRequired
                state={values}
                setState={setValues}
                propertyName="position"
                isBackgroundWhite
              >
                {POSITION_OPTIONS.map((option) => (
                  <MenuItem key={option} value={option}>
                    {t(option)}
                  </MenuItem>
                ))}
              </DropdownMaterial>

              {values.position === 'Other' && (
                <InputThemeField
                  classWrapper="field easy-onboarding__individual-positiondetails"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  label={t('Describe details of position')}
                  value={values.position_other}
                  onChange={({ target: { value } }) => setValues({ ...values, position_other: value})}
                />
              )}
            </>
          )}

          {!!publicSettings && publicSettings.kyc_show_website && (
            <InputThemeField
              classWrapper="field easy-onboarding__individual-website"
              classLabel="field__label"
              classInput="field__input"
              required={currentLevel && currentLevel > 2}
              label={t('Website')}
              value={values.website}
              onChange={({ target: { value } }) => setValues({ ...values, website: value})}
            />
          )}
        </div>

        <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between easy-onboarding__education-buttons">
          <button className="button-stroke" onClick={goToPrevStep}>{t('Previous')}</button>
          <button className="button" onClick={goToNextStep}>{t('Continue')}</button>
        </div>
      </div>

      {userData.rights === 'COMMON' && (
        <>
          {userData.kyc_status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED && (
            publicSettings.enable_product_tour && !values.skip_product_tour && (
              <JoyRide steps={tourBoardingDetailEducation} callback={({ status }) => {
                if (status === 'skipped') {
                  setValues({...values, skip_product_tour: true})
                }
              }} />
            )
          )}
        </>
      )}
    </div>
  )
}

export default CardForm;
