import React, { useState } from "react";
import KycVerificationFormLevelTwo from "./KycVerificationFormLevels/KycVerificationFormLevelTwo";
import { mapSchema } from "../utils";
import { useExchangeRates } from "../myHooks";
import { ethToFiat, tokenToEth } from "../utils/rate";
import reduce from "lodash/reduce";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from 'react-i18next';

const KYC_WALLET_ENUM = {
  ethereum: {
    value: "ETHEREUM",
    label: "Ethereum",
    currencies: {
      usd: 165,
    },
  },
};

const MAX_AMOUNT_USD_WITHOUT_PROOF_IDENTITY = 20625;

const VideoIdentForm = ({
  mainInfoSchemas,
  addressInfoSchemas,
  filesSchemas,
  documentTypeEnum,
  kycType,
  registerCustomer,
  registerCustomerData,
  onSuccess,
  publicSettings,
  refetchWhenReceivedVideoIdentId,
  onSubmitDocumentUpload,
  userData,
}) => {
  const { t } = useTranslation();
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const { data: { exchangeRates } = {} } = useExchangeRates();
  const { error } = registerCustomerData;
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [values, setValues] = useState({
    documentType: documentTypeEnum[Object.keys(documentTypeEnum)[0]].value,
    currency: (publicSettings || {}).base_currency || "eth",
    videoIdentVerifiedTime: 0,
    amount: String(userData.customer.amount),
  });
  const [fileValues, setFileValues] = useState(
    reduce(filesSchemas, mapSchema, {})
  );

  const addressValues = reduce(addressInfoSchemas, mapSchema, {})

  const [disabledBtn, setDisabledBtn] = useState(false)

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const accepted_field_application_individual_fiat =
    (publicSettings &&
      publicSettings.kyc_levels &&
      publicSettings.kyc_levels[1].acceptedFieldIndividualFiat) ||
    {};
  const accepted_field_application_individual_crypto =
    (publicSettings &&
      publicSettings.kyc_levels &&
      publicSettings.kyc_levels[1].acceptedFieldIndividualCrypto) ||
    {};
  const baseCurrency =
    (publicSettings && publicSettings.base_currency) || "chf";
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  if (publicSettings && exchangeRates) {
    const isAmountEquallyTwoLVLCurrency =
      exchangeRates &&
      ethToFiat(
        values.currency === "eth" ? baseCurrency : values.currency,
        tokenToEth(values.amount, exchangeRates),
        exchangeRates
      ) >= +publicSettings.kyc_levels[1].min_invest_amount;

    const isInvestingKycLevel1 =
      exchangeRates &&
      ethToFiat(
        values.currency === "eth" ? baseCurrency : values.currency,
        tokenToEth(values.amount, exchangeRates),
        exchangeRates
      ) >= +publicSettings.kyc_levels[1].min_invest_amount;

    const isInvestingKycLevel2 =
      exchangeRates &&
      ethToFiat(
        values.currency === "eth" ? baseCurrency : values.currency,
        tokenToEth(values.amount, exchangeRates),
        exchangeRates
      ) >= +publicSettings.kyc_levels[2].min_invest_amount;

    const isInvestingKycLevel3 =
      exchangeRates &&
      ethToFiat(
        values.currency === "eth" ? baseCurrency : values.currency,
        tokenToEth(values.amount, exchangeRates),
        exchangeRates
      ) >= +publicSettings.kyc_levels[3].min_invest_amount;

    const onChangeTab = (name, state, setState, value = "") => () => {
      if (value) {
        setState({ ...state, [name]: value });
      }
    };

    const onDropFile = (name) => (img, imgURI) => {
      const newImage = {
        img: img.length > 0 ? img[0] : null,
        imgURI: imgURI.length > 0 ? imgURI[0] : "",
      };
      setFileValues({ ...fileValues, [name]: newImage });
    };

    const handleProcessDocumentUpload = () => {
      setDisabledBtn(true)
      onSubmitDocumentUpload(fileValues, values.documentType)
      setDisabledBtn(false)
    }

    return (
      <div className="page-content">
        <div className="container">
          <Grid justify="center" alignItems="center" container spacing={0}>
            <Grid item xl={9} lg={10}>
              <div className="kyc-form-steps card mx-lg-4">
                <KycVerificationFormLevelTwo
                  documentTypeEnum={documentTypeEnum}
                  onChangeTab={onChangeTab}
                  setValues={setValues}
                  KYC_WALLET_ENUM={KYC_WALLET_ENUM}
                  kycType={kycType}
                  filesSchemas={filesSchemas}
                  onDropFile={onDropFile}
                  fileValues={fileValues}
                  values={values}
                  error={error}
                  settings={publicSettings}
                  MAX_AMOUNT_USD_WITHOUT_PROOF_IDENTITY={
                    MAX_AMOUNT_USD_WITHOUT_PROOF_IDENTITY
                  }
                  isAmountEquallyTwoLVLCurrency={isAmountEquallyTwoLVLCurrency}
                  accepted_field_application_individual_fiat={
                    accepted_field_application_individual_fiat
                  }
                  accepted_field_application_individual_crypto={
                    accepted_field_application_individual_crypto
                  }
                  addressValues={addressValues}
                  isInvestingKycLevel1={isInvestingKycLevel1}
                  isInvestingKycLevel2={isInvestingKycLevel2}
                  isInvestingKycLevel3={isInvestingKycLevel3}
                  refetchWhenReceivedVideoIdentId={
                    refetchWhenReceivedVideoIdentId
                  }
                />
                <div className="form-step-fields">
                  {values.videoIdentVerifiedTime >= 2 && (
                    <button
                      disabled={disabledBtn}
                      onClick={handleProcessDocumentUpload}
                      type="button"
                      className="button"
                    >
                      { t('Process for Verify') }
                    </button>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};

VideoIdentForm.propTypes = {
  mainInfoSchemas: PropTypes.object.isRequired,
  addressInfoSchemas: PropTypes.object,
  filesSchemas: PropTypes.object.isRequired,
  documentTypeEnum: PropTypes.object.isRequired,
  kycType: PropTypes.string.isRequired,
  registerCustomer: PropTypes.func.isRequired,
  registerCustomerData: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  publicSettings: PropTypes.object.isRequired,
  refetchWhenReceivedVideoIdentId: PropTypes.func.isRequired,
  onSubmitDocumentUpload: PropTypes.func.isRequired,
};

export default VideoIdentForm;
