import React from 'react'
import { NavLink } from 'react-router-dom'
import LoginForm from './LoginForm'
import LoginRegisterHeader from '../../components/HeaderLoginRegister'
import LoginRegisterHeaderRwd from '../../components/HeaderLoginRegisterRwd'
import LoginRegisterLogo from '../../components/LogoLoginRegister'
import { usePublicSettings } from '../../myHooks/useSettings'
import { useTranslation } from 'react-i18next'
// import iconGoogle from "../../media/icons/google.svg";
// import iconApple from "../../media/icons/apple.svg";

const Login = () => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { t } = useTranslation();
  return (
    <>
      <LoginRegisterHeader>
        {
          publicSettings && publicSettings.show_investhub_dao_login_btn && (
            <a href={process.env.REACT_APP_DAO_HOST}>{ t('Back to Investhub') }</a>
          )
        }

        <NavLink to="/password/restore">{ t('Forgot your password') }</NavLink>
        {
          publicSettings && publicSettings.show_investhub_dao_login_btn && (
            <a href={`${process.env.REACT_APP_DAO_HOST}/sso/request?redirect=${decodeURIComponent(`${window.location.origin}/sso/auth`)}`}>
              { t('Sign In with Investhub DAO account') }
            </a>
          )
        }
      </LoginRegisterHeader>

      <LoginRegisterHeaderRwd>
        {
          publicSettings && publicSettings.disable_registration && publicSettings.show_investhub_dao_login_btn && (
            <div className="actions__option actions__option_normal d__block">
              <a href={process.env.REACT_APP_DAO_HOST}>{ t('Back to Investhub') }</a>
            </div>
          )
        }

        <div className="actions__option actions__option_normal d__block">
          <NavLink to="/password/restore">{ t('Forgot your password') }</NavLink>
        </div>

        {
          publicSettings && publicSettings.show_investhub_dao_login_btn && (
            <div className="actions__option actions__option_normal d__block">
              <a
                href={`${process.env.REACT_APP_DAO_HOST}/sso/request?redirect=${decodeURIComponent(`${window.location.origin}/sso/auth`)}`}>
                { t('Sign In with Investhub DAO account') }
              </a>
            </div>
          )
        }
      </LoginRegisterHeaderRwd>

      <div className="entry entry_loginregister">
        <div className="entry__wrapper entry__wrapper-kyc">
          <LoginRegisterLogo />

          <div className="h2 entry__title">
            { t('Sign In') }
          </div>

          {/*
            <div className="entry__top">
              <div className="entry__text">{ t('Sign up with Open Account') }</div>

              <div className="entry__btns">
                <button className="button-stroke entry__button">
                  <img src={iconGoogle} alt="Google" />
                  { t('Google') }
                </button>

                <div className="button-stroke entry__button">
                  <img src={iconApple} alt="Apple" />
                  { t('Apple ID') }
                </div>
              </div>
            </div>

            <div className="entry__text">{ t('Or continue with email address') }</div>
          */}
          <div className="entry__text">{ t('Sign in with email address') }</div>

          <LoginForm />

          <div>
            {(publicSettings || {}).login_youtube_video && (
              <div className="entry__youtube_video">
                <iframe
                  title="video"
                  width="426"
                  height="240"
                  type="text/html"
                  src={`${publicSettings.login_youtube_video}?autoplay=1&controls=0&disablekb=1&fs=0&iv_load_policy=3&loop=1&modestbranding=1&showinfo=0&autohide=1`}
                  frameBorder="0"
                />
              </div>
            )}

            <br />

            {(publicSettings || {}).first_link_login && (
              <p>
                <a className="button button__full" href={(publicSettings || {}).first_link_login} target="_blank" rel="noopener noreferrer">
                  {(publicSettings || {}).first_text_login || 'See more'}
                </a>
              </p>
            )}

            <br />

            {(publicSettings || {}).second_link_login && (
              <p>
                <a className="button button__full" href={(publicSettings || {}).second_link_login} target="_blank" rel="noopener noreferrer">
                  {(publicSettings || {}).second_text_login || 'Explore'}
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
