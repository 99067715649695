import React, { useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Icon from '../../Icon'
import ModalInnerMaterialDialog from '../../ModalInnerMaterialDialog'

const ListContentActions = () => {
  const { t } = useTranslation()

  const [buttonDetailActivated, setButtonDetailActivated] = useState({
    isButtonDetailActivated: false,
  });

  const handleButtonDetailActive = () => {
    setButtonDetailActivated({...buttonDetailActivated, isButtonDetailActivated: !buttonDetailActivated.isButtonDetailActivated})
  }

  const handleButtonDetailActiveAway = () => {
    setButtonDetailActivated({...buttonDetailActivated, isButtonDetailActivated: false})
  }

  const [dialogDetailOpen, setDialogDetailOpen] = useState(false)

  return (
    <>
      <ClickAwayListener onClickAway={handleButtonDetailActiveAway}>
        <div className={`actions actions__kyc ${buttonDetailActivated.isButtonDetailActivated ? 'active' : ''}`}>
          <button className="actions__button" onClick={handleButtonDetailActive}>
            <Icon
              className={classNames('more-horizontal')}
              name='more-horizontal'
              size="24"
            />
          </button>

          <div className="actions__body">
            <button
              className="actions__option"
              onClick={() => setDialogDetailOpen(true)}
            >
              <Icon
                className={classNames('link')}
                name='link'
                size="24"
              />
              {t('Detail')}
            </button>
            <button className="actions__option">
              <Icon
                className={classNames('trash')}
                name='trash'
                size="24"
              />
              {t('Delete')}
            </button>
          </div>
        </div>
      </ClickAwayListener>

      <Dialog
        open={dialogDetailOpen}
        onClose={() => setDialogDetailOpen(false)}
        classes={{
          root: 'kyc-dialog'
        }}
      >
        <ModalInnerMaterialDialog
          modalSize="popup__kyc-medium"
          onClickClose={() => setDialogDetailOpen(false)}
          modalTitle={t('Table Detail')}
          modalTitleClassColor="title-primary"
        >
          <div className="activity__table kyc-activity__table">
            <div className="activity__row kyc-activity__row">
              <div className="activity__col kyc-activity__col">
                {t('Made KYC')}
              </div>

              <div className="activity__col kyc-activity__col">
                {t('KYC Reopend')}
              </div>

              <div className="activity__col kyc-activity__col">
                {t('KYC Approved')}
              </div>
            </div>

            <div className="activity__row kyc-activity__row">
              <div className="activity__col vert__middle kyc-activity__col">
                <div className="activity__label">
                  {t('Made KYC')}
                </div>
                <div className="status-pending-dark product__status">
                  {t('Not started')}
                </div>
              </div>
              <div className="activity__col vert__middle kyc-activity__col">
                <div className="activity__label">
                  {t('KYC Reopend')}
                </div>
                <div className="status-green-dark product__status">
                  {t('Approved')}
                </div>
              </div>
              <div className="activity__col vert__middle kyc-activity__col">
                <div className="activity__label">
                  {t('KYC Approved')}
                </div>
                <div className="status-green-dark product__status">
                  {t('Approved')}
                </div>
              </div>
            </div>
          </div>
        </ModalInnerMaterialDialog>
      </Dialog>
    </>
  )
}

const ListContent = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="activity__row kyc-activity__row">
        <div className="activity__col vert__middle kyc-activity__col">
          <div className="activity__label">
            {t('Logged in (IP + Country)')}
          </div>
          31 Augs, 2019 04:32 PM
        </div>
        <div className="activity__col vert__middle kyc-activity__col">
          <div className="activity__label">
            {t('Purchased Tokens')}
          </div>
          <div className="shorten__text">
            0xb122eF3357Ba1acF71688505f5BF82E255b928c0
          </div>
        </div>
        <div className="activity__col vert__middle kyc-activity__col">
          <div className="activity__label">
            {t('2FA Activated')}
          </div>
          <div className="status-green-dark product__status">
            {t('Approved')}
          </div>
        </div>
        <div className="activity__col vert__middle kyc-activity__col">
          <div className="activity__label">
            {t('2FA SMS Completed')}
          </div>
          <div className="status-pending-dark product__status">
            {t('Pending')}
          </div>
        </div>
        <div className="activity__col transcations__col-action vert__middle kyc-activity__col">
          <div className="actions actions__kyc">
            <button className="actions__button">
              <Icon
                className={classNames('more-horizontal')}
                name='more-horizontal'
                size="24"
              />
            </button>
          </div>
        </div>
      </div>

      <div className="activity__row kyc-activity__row">
        <div className="activity__col vert__middle kyc-activity__col">
          <div className="activity__label">
            {t('Logged in (IP + Country)')}
          </div>
          31 Augs, 2019 04:32 PM
        </div>
        <div className="activity__col vert__middle  kyc-activity__col">
          <div className="activity__label">
            {t('Purchased Tokens')}
          </div>
          <div className="shorten__text">
            0xb122eF3357Ba1acF71688505f5BF82E255b928c0
          </div>
        </div>
        <div className="activity__col vert__middle kyc-activity__col">
          <div className="activity__label">
            {t('2FA Activated')}
          </div>
          <div className="status-green-dark product__status">
            {t('Approved')}
          </div>
        </div>
        <div className="activity__col vert__middle kyc-activity__col">
          <div className="activity__label">
            {t('2FA SMS Completed')}
          </div>
          <div className="status-pending-dark product__status">
            {t('Pending')}
          </div>
        </div>
        <div className="activity__col transcations__col-action vert__middle  kyc-activity__col">
          <ListContentActions />
        </div>
      </div>
    </>
  )
}

export default ListContent
