import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const GET_CHAT_DIALOG = gql`
  query getChatDialog {
    getChatDialog
  }
`
export const GET_ADMIN_CHAT_HISTORY = gql`
  query getAdminChatHistory {
    getAdminChatHistory
  }
`

export const GET_CHAT_CONTACT_LIST = gql`
  query getChatContactList {
    getChatContactList
  }
`
