import React from 'react'
import JoyRide from 'react-joyride'
import { useTranslation } from 'react-i18next'
import { usePublicSettings, useMe } from '../../../myHooks'
import { USER_KYC_STATUS_TYPES } from '../../../constants/user'
import InputThemeField from '../../../components/InputThemeField'

const CardForm = ({
  values,
  setValues,
  goToNextStep,
  goToPrevStep,
}) => {
  const {
    data: { me: userData } = {},
    loading,
  } = useMe()
  const { data: { publicSettings = {} } = {} } = usePublicSettings()

  const { t } = useTranslation();
  const tourBoardingPersonalDetail = [
    {
      target: '.easy-onboarding__personaldetails .easy-onboarding__individual-name',
      content: t('Please make sure that your first name is spelled exactly as it appears on your identification document.'),
      disableBeacon: true,
      hideCloseButton: true,
      spotlightClicks: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
    {
      target: '.easy-onboarding__personaldetails .easy-onboarding__individual-surname',
      content: t('Again, please make sure that the last name is exactly as it appears on your identification document.'),
      disableBeacon: true,
      hideCloseButton: true,
      spotlightClicks: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
    {
      target: '.easy-onboarding__personaldetails .easy-onboarding__individual-middlename',
      content: t('If you have a middle name, please add it here.'),
      disableBeacon: true,
      hideCloseButton: true,
      spotlightClicks: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
    {
      target: '.easy-onboarding__personaldetails .settings__button .button',
      content: t('Continue here'),
      disableBeacon: true,
      hideCloseButton: true,
      spotlightClicks: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
  ]

  return (
    <div className="card easy-onboarding__personaldetails">
      <div className="settings__wrapper">
        <div className="card__head card__head-kyc-1">
          <div className="title-green card__title">
            {t('What is your name?')}
          </div>
          <div className="refund__content card__head-content">
            <p>{ t('We will use these details for the liveness check on the next step, please make sure that your name is matching your Identification document.') }</p>
          </div>
        </div>

        <div className="settings__fieldset">
          <InputThemeField
            classWrapper="field easy-onboarding__individual-name"
            classLabel="field__label"
            classInput="field__input"
            required
            label={t('Your name')}
            value={values.firstName}
            onChange={({ target: { value } }) => setValues({...values, firstName: value})}
          />

          <InputThemeField
            classWrapper="field easy-onboarding__individual-surname"
            classLabel="field__label"
            classInput="field__input"
            required
            label={t('Your surname')}
            value={values.lastName}
            onChange={({ target: { value } }) => setValues({...values, lastName: value})}
          />

          <InputThemeField
            classWrapper="field easy-onboarding__individual-middlename"
            classLabel="field__label"
            classInput="field__input"
            label={t('Your middle name')}
            value={values.middleName}
            onChange={({ target: { value } }) => setValues({...values, middleName: value})}
          />
        </div>

        <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between easy-onboarding__personaldetails-buttons">
          <button className="button" onClick={goToNextStep}>{t('Continue')}</button>
        </div>
      </div>

      {userData.rights === 'COMMON' && (
        <>
          {userData.kyc_status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED && (
            publicSettings.enable_product_tour && !values.skip_product_tour && (
              <JoyRide steps={tourBoardingPersonalDetail} callback={({ status }) => {
                if (status === 'skipped') {
                  setValues({...values, skip_product_tour: true})
                }
              }} />
            )
          )}
        </>
      )}
    </div>
  )
}

export default CardForm;
