import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import ImageUpload from "./ImageUpload";
import { INDIVIDUAL_DOCUMENT_TYPE_ENUM } from "../schemas/kycVerification";
import Input from "./Input";
import { useTranslation } from 'react-i18next';
import Icon from "./Icon";
import classNames from "classnames";

const AdminDialogUploadPhoto = ({
  userKyc,
  open,
  onClickDialogAway,
  onUploadPhoto,
  filesSchemas,
  fileValues,
  setFileValues,
  documentTypeValues,
  setDocumentTypeValues,
}) => {
  const { t } = useTranslation();

  const onDropFile = (name) => (img, imgURI) => {
    const newImage = {
      img: img.length > 0 ? img[0] : null,
      imgURI: imgURI.length > 0 ? imgURI[0] : "",
    };

    setFileValues({ ...fileValues, [name]: newImage });
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onBackdropClick={onClickDialogAway}
      onClose={onClickDialogAway}
    >
      <div className="popup js-popup animation visible">
        <div onClick={onClickDialogAway} className="popup__overlay js-popup-overlay">{' '}</div>

        <div className="popup__wrap js-popup-wrap">
          <div className="description">
            <div className="title-purple description__title">{ t('Upload photo') }</div>

            <div className="settings settings__clear flex__row flex__wrap">
              <div className="field">
                <div className="field__label">{ t('Document typ') }</div>

                <div className="field__wrap field__wrap-select">
                  <Input
                    propertyName="documentType"
                    state={documentTypeValues}
                    setState={setDocumentTypeValues}
                    select
                  >
                    {Object.values(INDIVIDUAL_DOCUMENT_TYPE_ENUM).map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Input>
                </div>
              </div>

              {Object.values(filesSchemas).map((fileSchema) => (
                <div className="settings__row flex__row flex__wrap have__filds" key={fileSchema.schemaTitle}>
                  <div className="field">
                    <div className="field__label">{fileSchema.label || ""}</div>

                    <div className="field__wrap">
                      <ImageUpload
                        buttonText="Choose Image"
                        buttonClassName="button"
                        onChange={onDropFile(fileSchema.schemaTitle)}
                        maxFileSize={5242880}
                        withPreview
                        singleImagePick
                        singleFile
                        defaultImages={
                          (fileValues[fileSchema.schemaTitle] || {}).imgURI
                            ? [fileValues[fileSchema.schemaTitle]]
                            : []
                        }
                      >
                        <div className="summary__title">
                          { t('Drag and drop image') }
                        </div>
                      </ImageUpload>
                    </div>
                  </div>
                </div>
              ))}

              <div className="space space__32">{' '}</div>
              <button
                className="button-stroke"
                onClick={onUploadPhoto(userKyc.id)}
              >
                { t('Upload') }
              </button>

            </div>
          </div>

          <button
            className="popup__close js-popup-close"
            onClick={onClickDialogAway}
          >
            <Icon
              className={classNames('close', 'icon-24')}
              name="close"
              size="24"
            />
          </button>
        </div>
      </div>
    </Dialog>
  );
};

AdminDialogUploadPhoto.propTypes = {
  userKyc: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClickDialogAway: PropTypes.func.isRequired,
  onUploadPhoto: PropTypes.func.isRequired,
  documentTypeValues: PropTypes.func.isRequired,
  setDocumentTypeValues: PropTypes.func.isRequired,
};

export default AdminDialogUploadPhoto;
