import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Switch } from 'react-router-dom'

import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { useMe } from '../myHooks'
import { USER_RIGHT_TYPES } from "../constants/user";

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}))

const ForwardableProtectedZone = ({ children, urlToRedirect, exactRights, matchRoutes, elseRoutes }) => {
  const classes = useStyles()
  const { error, loading, data: { me: user } = {} } = useMe()

  if (loading) {
    return <div className={classes.circularProgressWrapper}><CircularProgress /></div>
  }

  if (error || !user) {
    return <Redirect to={urlToRedirect || '/login'} />
  }

  if (user && [USER_RIGHT_TYPES.ADMIN, USER_RIGHT_TYPES.GENERAL_ADMIN].includes(user.rights) && !user.is_2fa_enabled) {
    return <Redirect to={urlToRedirect || '/second-layer-security'} />
  }

  if (exactRights && exactRights.includes(user.rights)) {
    return <Switch>
      {matchRoutes.props.children}
      {elseRoutes.props.children}
    </Switch>
  }

  return <Switch>{elseRoutes.props.children}</Switch>
}

ForwardableProtectedZone.propTypes = {
  // eslint-disable-next-line react/require-default-props
  urlToRedirect: PropTypes.string,
  exactRights: PropTypes.array,
  matchRoutes: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  elseRoutes: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default ForwardableProtectedZone
