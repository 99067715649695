// eslint-disable-next-line import/prefer-default-export
export const numberWithCommas = (x, precise = 2) => {
  if (Number.isNaN(x) || x === 'NaN') {
    return '0';
  }

  const [decimal, fractal] = x.toString().split('.');
  const trailing = Array(precise).fill(0).join('');

  return `${decimal.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${(fractal || precise > 0) ? '.' : ''}${fractal || trailing}`; // eslint-disable-line
}
// eslint-enable-next-line import/prefer-default-export
