import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useMe } from '../../myHooks'
import KycStatusLog from '../../components/KycStatusLog'
import LoginHead from './LoginHead';
import styles from './styles.module.scss'

const EasyOnBoardingDetailThankyouOne = () => {
  const { t } = useTranslation()
  const { data: { me } = {} } = useMe({ fetchPolicy: 'network-only' })
  const isCompleteLivenessCheck = me && me.customer && (
    (me.customer.onfidoApplicant && me.customer.onfidoApplicant.onfidoChecks.length > 0) ||
    (me.customer.livenessCheckApplicant && me.customer.livenessCheckApplicant.status === 'SUBMITTED') ||
    (me.customer.idnowIdent && me.customer.idnowIdent.status === 'COMPLETED') ||
    (me.customer.complycubeClient && me.customer.complycubeClient.complycubeChecks.length > 0)
  )

  return (
    <>
      <LoginHead />
      <div className="login__wrapper entry__wrapper-kyc_2">
        <div className="login__wrapper-img">
          <div className={classNames('page-success__status', styles.module_page_status)}>
            {/* Credit to https://codepen.io/Hellwinkel/pen/KKaNNKb */}
            <div className={classNames('check-container', styles.module_check_container)}>
              <div className={classNames('check-background', styles.module_check_background)}>
                <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <div className={classNames('check-shadow', styles.module_check_shadow)}>{' '}</div>
            </div>
          </div>
        </div>
        <div className="space space__24">{' '}</div>
        <div className="h4 entry__title">
          { t('Thank you for your Submission')}
        </div>

        <div className="entry__top bb__0 mb__0">
          <div className="entry__text">
            { t('The next steps are following') }
            :
            {' '}
            { t('You will receive 2 emails one if the liveness check is completed and another email, once your KYC has been approved.') }
          </div>

          <KycStatusLog />
        </div>

        {me && me.customer && !isCompleteLivenessCheck && (
          <div className="entry__fieldset">
            <div className="space space__24">{' '}</div>
            <a type="button" className="button entry__button" href="/easy-onboarding-verify-identity">
              { t('Continue to Liveness Check') }
            </a>
          </div>
        )}
      </div>
    </>
  );
}

export default EasyOnBoardingDetailThankyouOne;
