import React from 'react'
import { useTranslation } from 'react-i18next'
import MaterialAccordion from '@material-ui/core/Accordion'
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary'
import MaterialAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import ImportPersonalDiscountForm from './ImportPersonalDiscountForm'
import AddPersonalDiscountForm from './AddPersonalDiscountForm'
import AdminPersonalDiscountList from './AdminPersonalDiscountList'
import AdminPersonalDiscountImportLogList from './AdminPersonalDiscountImportLogList'

const AdminPersonalDiscount = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page__title h3">{ t('Personal Discount List')}</div>

      <div className="card">
        <MaterialAccordion className="accordion__kyc">
          <MaterialAccordionSummary
            className="accordion__kyc_head"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="attributesbh-content"
            id="attributes-header"
          >
            <div className="title-primary card__title">{ t('CSV Import') }</div>
          </MaterialAccordionSummary>
          <MaterialAccordionDetails className="accordion__kyc_body">
            <div className="w-full">
              <ImportPersonalDiscountForm onComplete={() => { window.location.reload() }} />
            </div>
          </MaterialAccordionDetails>
        </MaterialAccordion>
      </div>

      <div className="card">
        <MaterialAccordion className="accordion__kyc">
          <MaterialAccordionSummary
            className="accordion__kyc_head"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="attributesbh-content"
            id="attributes-header"
          >
            <div className="title-primary card__title">{ t('Add manual') }</div>
          </MaterialAccordionSummary>
          <MaterialAccordionDetails className="accordion__kyc_body">
            <div className="w-full">
              <AddPersonalDiscountForm onComplete={() => { window.location.reload() }} />
            </div>
          </MaterialAccordionDetails>
        </MaterialAccordion>
      </div>

      <div className="card">
        <div className="products__head">
          <div className="title-primary card__title">{ t('Personal Discount List') }</div>
        </div>
        <AdminPersonalDiscountList />
      </div>

      <div className="card">
        <div className="products__head">
          <div className="title-primary card__title">{ t('Import Log') }</div>
        </div>
        <AdminPersonalDiscountImportLogList />
      </div>

    </>
  )
}

export default AdminPersonalDiscount
