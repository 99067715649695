import React from 'react'

const HeaderLoginRegister = ({children}) => {
  return (
    <div className="login entry__signin login__kyc">
      <div className="login__head">
        <div className="login__info login__info-kyc">

          {children}

        </div>
      </div>
    </div>
  )
}

export default HeaderLoginRegister;
