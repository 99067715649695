import gql from 'graphql-tag'

export const GET_LATEST_AUTO_APPROVAL_LOG = gql`
  query getLatestAutoApprovalLog($customerId: Int!) {
    getLatestAutoApprovalLog(customerId: $customerId) {
      id
      customerId
      result
      rejectReasons
      isCheckPassed
      isWatchlistPassed
      isDataPassed
      isRiskPassed
      created_at
    }
  }
`;

export const RECHECK_AUTO_APPROVAL_LOG = gql`
    mutation recheckAutoApprovalLog($customerId: Int!) {
        recheckAutoApprovalLog(customerId: $customerId)
    }
`
