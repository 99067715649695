import gql from 'graphql-tag';

export const CHECK_WALLET_COMPENSATION = gql`
  query checkWalletCompensation($walletAddress: String!) {
    checkWalletCompensation(walletAddress: $walletAddress)
  }
`

export const GET_USER_COMPENSATION_TRACKING = gql`
  query getUserCompensationTracking($userId: ID!) {
    getUserCompensationTracking(userId: $userId) {
      totalCompensation
      totalToken
      transactions {
        id
        userId
        payoutId
        transactionHash
        amount
        decimal
        compensationRateAtConfirmation
        status
        transferedAt
      }
    }
  }
`

export const GET_USER_COMPENSATION_PAYOUT = gql`
  query getUserCompensationPayout($userId: ID!) {
    getUserCompensationPayout(userId: $userId) {
      id
      paidUserId
      receiveUserId
      receiveWallet
      amount
      decimal
      status
      transactionHash
      createdAt
      paidUser {
        id
        email 
      }
      compensationTracking {
        transactionHash
      }
    }
  }
`

export const CHECK_LIQUIDATION_DOCUMENT_HAS_NEEDED_SIGNATURES = gql`
  query checkLiquidationDocumentHasNeededSignatures($userId: ID!) {
    checkLiquidationDocumentHasNeededSignatures(userId: $userId)
  }
`

export const CHECK_BATCH_COMPENSATE = gql`
  query checkBatchCompensate($customerIds: [ID!]) {
    checkBatchCompensate(customerIds: $customerIds)
  }
`

export const GET_USER_COMPENSATION_RATE = gql`
  query getUserCompensationRate {
    getUserCompensationRate
  }
`

export const GET_SPECIFIC_COMPENSATION_RATE_MAP = gql`
  query getSpecificCompensationRateMap {
    getSpecificCompensationRateMap
  }
`

export const GET_LIQUIDATION_PROOF = gql`
  query getLiquidationProof($customerId: ID!) {
    getLiquidationProof(customerId: $customerId) {
      userId
      customerId
      txHashes
      uploads {
        id
        storage_key
        preview_storage_key
        mime_type
      }
    }
  }
`

export const HAS_LIQUIDATION_PROOF_UPLOADED = gql`
  query hasLiquidationProofUploaded($type: String) {
    hasLiquidationProofUploaded(type: $type)
  }
`
