import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ModalInnerMaterialDialog from './ModalInnerMaterialDialog'
import InputThemeField from './InputThemeField'
import {
  CircularProgress,
  Dialog,
  MenuItem,
  TextField,
} from '@material-ui/core'
import {
  ME_INVEST_IN_BASE_CURRENCY,
  PUBLIC_SETTINGS,
  UPGRADE_KYC_LEVEL,
} from '../queriesAndMutations'
import {
  toaster,
  getExternalFileUrl,
  numberWithCommas,
} from '../utils'
import { TOKEN_TYPES } from '../constants/settings'
import InfoIcon from './Icon'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'
import ImageUpload from './ImageUpload'
import { remove } from 'lodash'

const UpgradeLevelDialog = ({
  open,
  onClose
}) => {
  const { t } = useTranslation();
  const {data: { meInvestInBaseCurrency: investStatus = null } = {}, refetch: refetchMeInvest } = useQuery(ME_INVEST_IN_BASE_CURRENCY)
  const {data: { publicSettings } = {} } = useQuery(PUBLIC_SETTINGS)
  const [upgradeKycLevel, upgradeKycLevelStatus] = useMutation(UPGRADE_KYC_LEVEL);
  const currentLevel = investStatus ? investStatus.current_lvl.level : 0
  const investCurrency = publicSettings && publicSettings.kyc_use_base_currency ? publicSettings.base_currency.toUpperCase() : 'CHF'
  const levelOptions = publicSettings
    ? Object.entries(publicSettings.kyc_levels).reduce(
      (all, level) => (
        parseInt(level[0]) > currentLevel && (level[1].enable === undefined || level[1].enable.enable_lvl === undefined || level[1].enable.enable_lvl)
          ? [
            ...all,
            {
              value: parseInt(level[0]),
              label: level[1].enable && level[1].enable.enable_open_end
                ? `> ${level[1].min_invest_amount} ${investCurrency}`
                : `${level[1].min_invest_amount} - ${level[1].max_invest_amount} ${investCurrency}`
            },
          ]
          : all
        ),
      [])
    : []

  const [values, setValues] = useState({
    level: '',
    descriptionOfFunds: '',
    descriptionOfWealth: '',
    supportedSourceOfFundUploadFiles: [],
  })

  const isShowingPostFix =
    (publicSettings && publicSettings.source_of_funds_example_path && (
      <a
        href={getExternalFileUrl(
          publicSettings.source_of_funds_example_path
        )}
        target="_blank"
        rel="noopener noreferrer"
      >
        {"   "}
        { t('Show Example') }
      </a>
    )) || false

  const removeDropSupportedSourceOfFundUploadFiles = (index) => {
    const newSupportedSourceOfFundUploadFiles = [...values.supportedSourceOfFundUploadFiles];
    const removedEle = remove(newSupportedSourceOfFundUploadFiles, (file, indx) => {
      return indx === index;
    });

    console.log('newSupportedSourceOfFundUploadFiles', newSupportedSourceOfFundUploadFiles)

    if (removedEle) {
      setValues({
        ...values,
        supportedSourceOfFundUploadFiles: newSupportedSourceOfFundUploadFiles
      })
    }
  };

  const isDocumentRequired = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return false
    }

    if (publicSettings.token_type === TOKEN_TYPES.NONE) {
      return values.level > 1
    }

    if ([TOKEN_TYPES.UTILITY, TOKEN_TYPES.EQUITY, TOKEN_TYPES.BOND].includes(publicSettings.token_type)) {
      return values.level > 2
    }

    return false
  }
  const isDescriptionOfFundsRequired = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return false
    }

    if (publicSettings.token_type === TOKEN_TYPES.NONE) {
      return values.level > 1
    }

    if ([TOKEN_TYPES.UTILITY, TOKEN_TYPES.EQUITY, TOKEN_TYPES.BOND].includes(publicSettings.token_type)) {
      return values.level > 1
    }

    return false
  }

  const isDescriptionOfWealthRequired = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return false
    }

    if (publicSettings.token_type === TOKEN_TYPES.NONE) {
      return values.level > 1
    }

    if ([TOKEN_TYPES.UTILITY, TOKEN_TYPES.EQUITY, TOKEN_TYPES.BOND].includes(publicSettings.token_type)) {
      return values.level > 1
    }

    return false
  }

  const submitUpgradeRequest = () => {
    upgradeKycLevel({
      variables: {
        input: {
          level: +values.level || undefined,
          descriptionOfFunds: values.descriptionOfFunds,
          descriptionOfWealth: values.descriptionOfWealth,
          supportedSourceOfFundUploadFiles: values.supportedSourceOfFundUploadFiles,
        }
      }
    }).then(() => {
      refetchMeInvest()
      toaster.success('Upgrade request has been recorded')
      onClose()
    }).catch(() => {})
  }
  const formatIntendedInvestment = (numberStr) => {
    return numberStr.replace(/\d+/g, (str) => numberWithCommas(str, 0));
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <ModalInnerMaterialDialog
        onClickClose={onClose}
        modalTitle={t('Request for Level Upgrading')}
        modalTitleClassColor="title-primary"
      >
        <div className="settings__item">
          <div className="settings__fieldset">
            <div className="field">
              <div className="field__label">
                {t('Intended investment')}
                <span className="text__red"> *</span>
              </div>

              <div className="field__wrap field__wrap-select">
                <TextField
                  select
                  required
                  variant="outlined"
                  value={values.level}
                  onChange={({ target: { value } }) => setValues({ ...values, level: value })}
                >
                  {levelOptions.map((level) => (
                    <MenuItem key={level.value} value={level.value}>
                      {formatIntendedInvestment(level.label)}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>

            {isDescriptionOfFundsRequired() && (
              <>
                <InputThemeField
                  classWrapper="field easy-onboarding__individual-sourceoffunds"
                  classLabel="field__label"
                  classInput="field__input"
                  label={
                    <>
                      { t('Source Of Funds') }
                      <span className="text__red"> *</span>
                      {" "}
                      <span
                        className="tooltip"
                        data-tip={t('Where does the funds used for this transaction come from?')}
                        data-for="source_of_funds"
                      >
                        <InfoIcon
                          className={classNames('info')}
                          name='info'
                          size="16"
                        />
                      </span>
                      { isShowingPostFix }
                    </>
                  }
                  value={values.descriptionOfFunds}
                  onChange={({ target: { value } }) => setValues({ ...values, descriptionOfFunds: value })}
                />
                <ReactTooltip id="source_of_funds" place="bottom" padding="24px" className="popover-tooltip"/>
              </>
            )}

            {isDocumentRequired() && (
              <>
                <div className="field easy-onboarding__individual-uploadsupportingdocument">
                  <ImageUpload
                    labelText={ t('Upload supporting documents to demonstrate the Source of Funds for this Purchase') }
                    isRequired
                    buttonText={t("Click or drop images")}
                    maxFileSize={10000000}
                    defaultImages={[]}
                    onChange={(files) => {
                      setValues({
                        ...values,
                        supportedSourceOfFundUploadFiles: [
                          ...values.supportedSourceOfFundUploadFiles,
                          ...files,
                        ]
                      })
                    }}
                    isBackgroundWhite
                  >
                    <div className="file__wrap_text text__center">
                      { t('Drag and drop the document') }
                      <br />
                      { t('Suport: JPG, JPEG, GIF, PNG, PDF.') }
                      <br />
                      { t('Max: 10MB') }
                    </div>
                  </ImageUpload>
                  {values.supportedSourceOfFundUploadFiles.map((file, index) => (
                    <React.Fragment key={index}>
                      <div className="space space__32">{' '}</div>
                      <div className="settings__top">
                        <div className="settings__label">
                          {(file && file.name) || 'Cannot load file'}
                        </div>

                        <button
                          className="button-stroke button-small settings__button"
                          onClick={(e) => {
                            removeDropSupportedSourceOfFundUploadFiles(index);
                          }}
                        >
                          { t('Remove') }
                        </button>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </>
            )}

            {isDescriptionOfWealthRequired() && (
              <>
                <InputThemeField
                  classWrapper="field easy-onboarding__individual-sourceofwealth"
                  classLabel="field__label"
                  classInput="field__input"
                  label={
                    <>
                      { t('Source Of Wealth') }
                      <span className="text__red"> *</span>
                      {" "}
                      <span
                        className="tooltip"
                        data-for="source_of_wealth"
                        data-tip={t('How did you make your wealth?')}
                      >
                        <InfoIcon
                          className={classNames('info')}
                          name='info'
                          size="16"
                        />
                      </span>
                    </>
                  }
                  value={values.descriptionOfWealth}
                  onChange={({ target: { value } }) => setValues({ ...values, descriptionOfWealth: value })}
                />
                <ReactTooltip id="source_of_wealth" place="bottom" padding="24px" className="popover-tooltip"/>
              </>
            )}
          </div>
        </div>

        <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
          {upgradeKycLevelStatus.loading ? (
            <CircularProgress />
          ) : (
            <>
              <button className="button-stroke" onClick={onClose}>{t('Cancel')}</button>
              <button className="button" onClick={submitUpgradeRequest}>{t('Increase Level')}</button>
            </>
          )}
        </div>

      </ModalInnerMaterialDialog>
    </Dialog>
  )
}

UpgradeLevelDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default UpgradeLevelDialog
