import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as StatusSuccessSVG } from '../../media/icons/status-success.svg';
import { ReactComponent as StatusFailedSVG } from '../../media/icons/status-failed.svg';
import { ReactComponent as StatusIncompleteSVG } from '../../media/icons/status-incomplete.svg';
import ThanksSubmission from "../../media/images/thanks-submission.png";

const EasyOnBoardingDetailThankyouTwo = () => {
  const { t } = useTranslation();

  return (
    <div className="login__wrapper entry__wrapper-kyc_2">
      <img
        src={ThanksSubmission}
        alt="Thank you for your Submission"
      />
      <div className="space space__24">{' '}</div>
      <div className="h3 entry__title">
        { t('Thank you for your Submission')}
      </div>

      <div className="entry__top">
        <div className="entry__text">
          { t('The next steps are following')}
          :
          {' '}
          { t('You will receive 2 emails one if the liveness check is completed and another email, once your KYC has been approved.') }
        </div>

        <div className="entry__text entry__text-onboarding">
          <div className="sidebar__body">
            <div className="sidebar__link sidebar__link-normal color">
              <StatusSuccessSVG />
              <span className="sidebar__link-text">
                { t('Liveness Check Email Sent 12-12-2021 11-03-22 CEST (please check your Inbox))') }
              </span>
            </div>

            <div className="sidebar__link sidebar__link-normal color">
              <StatusFailedSVG />
              <span className="sidebar__link-text">
                { t('KYC Auto Approval 12.12.2021 11-05-22 CEST (Our system was ))) ') }
                <strong>{ t('not') }</strong>
                { t(' able to automatically approve you') }
              </span>
            </div>

            <div className="sidebar__link sidebar__link-normal color">
              <StatusIncompleteSVG/>
              <span className="sidebar__link-text">
                { t('KYC Approval 12-12-2021 11-05-23 CEST (Please wait, until one of our Compliance officer check your submission)') }
              </span>
            </div>

            <div className="sidebar__link sidebar__link-normal color">
              <StatusSuccessSVG className="mr-3"/>
              <span className="sidebar__link-text">
                { t('KYC Approval 12-12-2021 16-01-10 CEST (Compliance officer approved your KYC Submission)') }
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="entry__fieldset">
        <div className="space space__24">{' '}</div>
        <button type="button" className="button entry__button">
          { t('Continue to Dashboard') }
        </button>
      </div>
    </div>
  );
}

export default EasyOnBoardingDetailThankyouTwo;
