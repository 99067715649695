import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import PhoneInput from 'react-phone-input-2'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { extractMsgFromErrByPropertyName } from '../../../utils'
import { CHANGE_BANK_INFO } from '../../../queriesAndMutations'
import { MAIN_INFO_SCHEMA } from '../../../schemas/banking'
import { useTranslation } from 'react-i18next'
import InformationCard from '../../../components/InformationCard'
import InformationLine from '../../../components/InformationLine'

const Banking = ({ userData }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState({
    bankName: userData.bankName,
    iban: userData.iban,
    bankAddress: userData.bankAddress,
    sendingWithTransferwise: userData.sendingWithTransferwise,
    bankNameTransferwise: userData.bankNameTransferwise,
    bankAddressTransferwise: userData.bankAddressTransferwise,
    memberShipNumberTransferwise: userData.memberShipNumberTransferwise,
  });
  const [changeBankInfo, { error, loading }] = useMutation(CHANGE_BANK_INFO);
  const [showSubmitInfo, setShowSubmitInfo] = useState(false)

  const submit = async (e) => {
    e.preventDefault();
    await changeBankInfo({ variables: { input: value } }).then(() => {
      // toaster.success("Success!");
      setShowSubmitInfo(true)
      if (value.sendingWithTransferwise === false) {
        setValue({
          ...value,
          bankNameTransferwise: null,
          bankAddressTransferwise: null,
          memberShipNumberTransferwise: null,
        });
      }
    }).catch(() => {});
  };
  const handleChange = (name, state, setState) => (event) => {
    setState({ ...state, [name]: event.target.value });
  };

  const handleChangeCheckBox = (propertyName) => (event) => {
    setValue({ ...value, [propertyName]: event.target.checked });
  };

  const renderInputField = ({
    // eslint-disable-next-line react/prop-types
    propertyName,
    label = "",
    type = "text",
    isRequired,
    helperText,
    state,
    setState,
    disabled = false,
    setter = null,
    labelPostfix = null,
    options = [],
  } = {}) => {
    const errorsTexts = extractMsgFromErrByPropertyName(error, propertyName);

    let input = null;

    switch (type) {
      case "phone":
        input = (
          <>
            <PhoneInput
              defaultCountry="us"
              onChange={
                setter ||
                ((value) => {
                  setState({
                    ...state,
                    [propertyName]: value,
                  });
                })
              }
              variant="outlined"
              disabled={disabled || loading}
              fullWidth
              value={state[propertyName] || ""}
            />
            <FormHelperText error={errorsTexts.length !== 0}>
              {helperText || (errorsTexts && errorsTexts.join(". ")) || ""}
            </FormHelperText>
          </>
        );
        break;
      case "select":
        input = (
          <TextField
            className="input-bordered"
            select
            value={state[propertyName] || ""}
            onChange={setter || handleChange(propertyName, state, setState)}
            margin="none"
            variant="outlined"
            fullWidth
            error={errorsTexts.length !== 0}
            helperText={
              helperText || (errorsTexts && errorsTexts.join(". ")) || ""
            }
            disabled={disabled || loading}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        );
        break;
      case "checkbox":
        input = (
          <label className="checkbox">
            <input
              type="checkbox"
              className="checkbox__input"
              checked={state[propertyName]}
              onChange={setter || handleChangeCheckBox(propertyName)}
              color="default"
              value="rememberMe"
            />

            <div className="checkbox__inner">
              <div className="checkbox__tick">{' '}</div>
            </div>
          </label>
        );
        break;
      default:
        input = (
          <>
            <input
              className="field__input"
              value={state[propertyName] || ""}
              onChange={setter || handleChange(propertyName, state, setState)}
              type={type}
              disabled={disabled || loading}
            />
            { helperText && (
              <div className="field__helper">
                { helperText || (errorsTexts && errorsTexts.join(". ")) || ""}
              </div>
            )}
          </>
        );
    }

    return (
      <div className="field" key={propertyName}>
        <div className="field__label" key={`label-${propertyName}`}>
          {label}
          {isRequired && <span className="text__red"> *</span>}
          {labelPostfix}
        </div>
        <div className="field__wrap" key={propertyName}>
          {input}
        </div>
      </div>
    );
  };

  return (
    <div className="settings__item">
      <div className="title-primary settings__title">{ t('Your Address page') }</div>

      <form onSubmit={submit} className="settings__fieldset">
        {MAIN_INFO_SCHEMA &&
          Object.keys(MAIN_INFO_SCHEMA).map((key) => (
            <React.Fragment key={key}>
              {key === "sendingWithTransferwise"
                ? <div className="create__line" key={MAIN_INFO_SCHEMA[key]}>
                    <div className="create__info create__info_2">
                      {t(MAIN_INFO_SCHEMA[key].label || "")}
                    </div>
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="switch__input"
                        checked={value.sendingWithTransferwise}
                        onChange={handleChangeCheckBox(key)}
                        value={value}
                      />

                      <div className="switch__inner">
                        <div className="switch__box">{' '}</div>
                      </div>
                    </label>
                  </div>
                : renderInputField({
                    propertyName: key,
                    type: MAIN_INFO_SCHEMA[key].type || "text",
                    label: t(MAIN_INFO_SCHEMA[key].label || ""),
                    isRequired: MAIN_INFO_SCHEMA[key].isRequired || false,
                    state: value,
                    setState: setValue,
                  })}

              {MAIN_INFO_SCHEMA[key].subItem && value[key] === true && (
                <div className="settings__row flex__row flex__wrap">
                  {Object.keys(MAIN_INFO_SCHEMA[key].subItem).map((subKey) => (
                    <div className="field" key={subKey}>
                      {renderInputField({
                        propertyName: subKey,
                        type: MAIN_INFO_SCHEMA[key].subItem[subKey].label || "",
                        label: t(MAIN_INFO_SCHEMA[key].subItem[subKey].label || ""),
                        isRequired:
                          MAIN_INFO_SCHEMA[key].subItem[subKey].isRequired ||
                          false,
                        state: value,
                        setState: setValue,
                      })}
                    </div>
                  ))}
                </div>
              )}
            </React.Fragment>
          ))
        }

        <div className="field">
          <button type="submit" className="button settings__button">
            { t('Update') }
          </button>
        </div>
        {showSubmitInfo && (
          <div className="field">
            <InformationLine
              informationColor="box__information-green"
              icon="check"
              text={ t('All Changes are saved') }
            />
          </div>
        )}
        <div className="field">
          <InformationCard color="card__information-red" title={t('Warning')}>
            <p>
              { t('Please note that Wise has different Banking partners in each Jurisdiction, copy this data directly from the Wise app.') }
            </p>
          </InformationCard>
        </div>
      </form>
    </div>
  );
};

export default Banking;
