import React from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import Icon from '../../../Icon'
import { NavLink, Link } from 'react-router-dom'
import WalletAddress from '../../../profile/WalletAddress'
import { usePublicSettings, useMe } from '../../../../myHooks'
import Dialog from '@material-ui/core/Dialog'
import ModalInnerMaterialDialog from '../../../ModalInnerMaterialDialog'
import { USER_KYC_STATUS_TYPES } from '../../../../constants/user'
import { TOKEN_TYPES } from '../../../../constants/settings'

const NeedToDo = ({
  kyc,
  updateProfile,
  enable2FA,
  walletAddress,
  onHelpSidebarActive,
  signnow,
  signnowLinkInvite,
  // from HOCs
  location,
}) => {
  const { t } = useTranslation();
  const [openAddWalletDialog, setOpenAddWalletDialog] = React.useState(false);
  const {
    data: { me: userData = {} } = {},
    loading,
  } = useMe()
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const currentDirectorProfile = (userData && userData.customerCompanyDirector && userData.customerCompanyDirector[0]) || null;

  return (
    <div className="help__list">
      {!(
        userData &&
        userData.kyc_status !== USER_KYC_STATUS_TYPES.NOT_SUBMITTED
      ) &&
        // eslint-disable-next-line react/no-array-index-key
        <NavLink
          className="help__item"
          to={currentDirectorProfile ? `/director-profile/${currentDirectorProfile.id}` :
            publicSettings && publicSettings.token_type === TOKEN_TYPES.LIQUIDATION ? '/transfer-claim' : '/application'}
          onClick={onHelpSidebarActive}
        >
          <Icon
            className={classNames('plus-circle', 'icon-24 mr__8')}
            name="plus-circle"
            size="24"
          />
          {t(publicSettings && publicSettings.token_type === TOKEN_TYPES.LIQUIDATION
            ? 'Transfer claim'
            : 'Submit for KYC Application'
          )}
        </NavLink>
      }

      {(updateProfile || enable2FA) && (
        <NavLink to="/profile" className="help__item" onClick={onHelpSidebarActive}>
          <Icon
            className={classNames('plus-circle', 'icon-24 mr__8')}
            name="plus-circle"
            size="24"
          />
          {updateProfile && (
            <>{ t('Complete Profile') }</>
          )}
          {updateProfile && enable2FA && ' / '}
          {enable2FA && (
            <>{ t('Activate 2FA') }</>
          )}
        </NavLink>
      )}

      {walletAddress && (
        <div
          className="help__item"
          onClick={() => setOpenAddWalletDialog(true)}
        >
          <Icon
            className={classNames('plus-circle', 'icon-24 mr__8')}
            name="plus-circle"
            size="24"
          />
          { t('Add receiving address') }
        </div>
      )}

      {signnow && signnowLinkInvite && (
        <div className="help__item" target="_blank" rel="noopener noreferrer">
          <Icon
            className={classNames('plus-circle', 'icon-24 mr__8')}
            name="plus-circle"
            size="24"
          />
           { t('Sign Signnow Form') }
        </div>
      )}

      <Dialog
        open={openAddWalletDialog}
        onClose={() => {setOpenAddWalletDialog(false)}}
      >
        <ModalInnerMaterialDialog
          onClickClose={() => {setOpenAddWalletDialog(false)}}
          modalTitle={t('Wallet Address')}
          modalTitleClassColor="title-primary"
          modalSize="popup__kyc-medium"
        >
          <div className="description__row flex__row flex__wrap">
            <WalletAddress />
          </div>
        </ModalInnerMaterialDialog>
      </Dialog>
    </div>
  );
}

NeedToDo.defaultProps = {
  kyc: true,
  updateProfile: true,
  enable2FA: true,
  walletAddress: true,
}

export default NeedToDo;
