import React, { useState, useEffect } from 'react'
import { Link, NavLink, Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { useCookies } from 'react-cookie'
import {
  CircularProgress,
} from '@material-ui/core'
import { SSO_AUTH } from '../queriesAndMutations'
import LoginRegisterHeader from '../components/HeaderLoginRegister'
import LoginRegisterHeaderRwd from '../components/HeaderLoginRegisterRwd'
import LoginRegisterLogo from '../components/LogoLoginRegister'
import { usePublicSettings } from '../myHooks/useSettings'
import LineInformation from '../components/InformationLine'
import { useTranslation } from 'react-i18next'
import InputThemeField from '../components/InputThemeField'

const SsoAuth = () => {
  const [, setCookie] = useCookies();
  const query = new URLSearchParams(window.location.search)
  const token = query.get('sso_token')
  const allowSyncData = query.get('allowSyncData')
  const [twoFaTokenValue, setTwoFaTokenValue] = useState('')
  const [twoFaToken, setTwoFaToken] = useState('')
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  const { t } = useTranslation()
  const { data: { ssoAuth: data } = {}, loading, refetch } = useQuery(SSO_AUTH, {
    variables: {
      token,
      two_fa_token: twoFaToken,
      allow_sync_data: allowSyncData,
    },
  })

  useEffect(() => {
    if (data){
      if (data.jwt) {
        setCookie('jwt', data.jwt.accessToken, { path: '/' })
        localStorage.setItem('jwt', data.jwt.accessToken)
        window.location.href = `${window.location.origin}`
      } else if (data.two_fa_required) {

      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (!token) {
    return <Redirect to="/login" />
  }

  return (
    <>
      <LoginRegisterHeader>
        <NavLink to="/">{ t('Back to Investhub') }</NavLink>
        <NavLink to="/password/restore">{ t('Forgot your password') }</NavLink>
        {
          publicSettings && publicSettings.show_investhub_dao_login_btn && (
            <Link
              href={`${process.env.REACT_APP_DAO_HOST}/sso/request?redirect=${decodeURIComponent(`${window.location.origin}/sso/auth`)}`}>
              { t('Sign In with Investhub DAO account') }
            </Link>
          )
        }
      </LoginRegisterHeader>

      <LoginRegisterHeaderRwd>
        <div className="actions__option actions__option_normal d__block">
          <NavLink to="/">{ t('Back to Investhub') }</NavLink>
        </div>

        <div className="actions__option actions__option_normal d__block">
          <NavLink to="/password/restore">{ t('Forgot your password') }</NavLink>
        </div>

        {
          publicSettings && publicSettings.show_investhub_dao_login_btn && (
            <div className="actions__option actions__option_normal d__block">
              <Link
                href={`${process.env.REACT_APP_DAO_HOST}/sso/request?redirect=${decodeURIComponent(`${window.location.origin}/sso/auth`)}`}>
                { t('Sign In with Investhub DAO account') }
              </Link>
            </div>
          )
        }
      </LoginRegisterHeaderRwd>

      <div className="entry entry_loginregister">
        <div className="entry__wrapper entry__wrapper-kyc">
          <LoginRegisterLogo />

          <div className="h2 entry__title">
            { t('Sign In') }
          </div>
          <div className="entry__text">
            { t('with your') } Investhub DAO { t('Account')}
          </div>

          <div className="entry__fieldset">
            {loading && (
              <CircularProgress />
            )}

            {!loading && data && data.jwt && (
              <LineInformation
                informationColor="box__information-green"
                text={t('Login successfully')}
                icon="check"
              />
            )}

            {!loading && data && data.two_fa_required && (
              <>
                <InputThemeField
                  classWrapper="field w-full"
                  classLabel="field__label"
                  classInput="field__input"
                  label="2FA code"
                  value={twoFaTokenValue}
                  onChange={({ target: { value } }) => {
                    setTwoFaTokenValue(value)
                  }}
                />

                <button
                  className="button w-full"
                  onClick={() => {
                    if (twoFaToken === twoFaTokenValue) {
                      refetch()
                    } else {
                      setTwoFaToken(twoFaTokenValue)
                    }
                  }}
                >
                  Submit
                </button>
              </>
            )}

            {!loading && !data && (
              <>
                <LineInformation
                  informationColor="box__information-red"
                  icon="close"
                  text={
                    <>
                      {t('Fail to authorize.')}
                      {' '}
                      <NavLink to="/login">{t('Back to login')}</NavLink>
                    </>
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SsoAuth
