import BigNumber from 'bignumber.js'
import { FIAT_CURRENCIES, CRYPTO_CURRENCIES } from '../constants/settings'

const bignumberify = (value) => new BigNumber(value)

// eslint-disable-next-line camelcase
export const ethToToken = (ethAmount, { token_to_eth }) => bignumberify(ethAmount).dividedBy(token_to_eth).toString()

// eslint-disable-next-line camelcase
export const tokenToEth = (tokenAmount, { token_to_eth }) => {
  return bignumberify(tokenAmount).multipliedBy(token_to_eth).toString()
}

// eslint-disable-next-line camelcase
export const tokenToUsdc = (tokenAmount, { token_to_eth, eth_to_usd }, keepOriginal) => {
  const result = ((bignumberify(tokenAmount).multipliedBy(token_to_eth).multipliedBy(eth_to_usd)) * 100) / 100;

  if (keepOriginal) {
    return result.toString();
  }

  return Math.round(result).toString();
}

export const isFiat = (name) => FIAT_CURRENCIES.find((currency) => currency === name) !== undefined
export const isCrypto = (name) => CRYPTO_CURRENCIES.find((currency) => currency === name) !== undefined

export const fiatToEth = (name, amount, exchangeRates) => {
  let lcName = name.toLowerCase()
  lcName = lcName === 'usdc' ? 'usd' : lcName
  const ethToFiat = exchangeRates[`eth_to_${lcName}`]

  if (!ethToFiat) {
    return NaN
  }

  switch (lcName) {
    case 'usd':
    case 'eur':
    case 'chf':
      return bignumberify(amount).dividedBy(ethToFiat).toString()
    default:
      return NaN
  }
}

export const fiatToToken = (name, amount, exchangeRates) => {
  const lcName = name.toLowerCase()
  switch (lcName) {
    case 'usd':
    case 'eur':
    case 'chf':
      return ethToToken(fiatToEth(lcName, amount, exchangeRates), exchangeRates).toString()
    default:
      return NaN
  }
}

export const ethToFiat = (fiatName, amount, exchangeRates, keepOriginal) => {
  if (!fiatName) {
    return 'undefined'
  }
  const lcFiatName = fiatName.toLowerCase()

  if (lcFiatName === 'eth') return amount

  let rate

  if (lcFiatName === 'usdc') {
    rate = exchangeRates.eth_to_usd
  } else {
    rate = exchangeRates[`eth_to_${lcFiatName}`]
  }

  if (typeof rate === 'undefined') {
    throw new Error(`Exchange rate for this FIAT is not provided: ${fiatName}`)
  }

  const result = bignumberify(amount).multipliedBy(rate).decimalPlaces(2)

  if (keepOriginal) {
    return result.toString();
  }

  return Math.round(result).toString()
}

export const sendwyreEthToFiat = (fiatName, amount, sendwyreRates) => {
  if (!fiatName) {
    return 'undefined'
  }

  const upcaseFiatName = fiatName.toUpperCase();
  const rate = sendwyreRates[`${upcaseFiatName}ETH`];

  if (typeof rate === 'undefined') {
    throw new Error(`Exchange rate for this FIAT is not provided: ${fiatName}`)
  }

  return Math.round(bignumberify(amount).multipliedBy(rate).decimalPlaces(2)).toString()
}

export const sendwyreUsdcToFiat = (fiatName, amount, sendwyreRates) => {
  if (!fiatName) {
    return 'undefined'
  }

  const upcaseFiatName = fiatName.toUpperCase();
  const rate = sendwyreRates[`${upcaseFiatName}USDC`];

  if (typeof rate === 'undefined') {
    throw new Error(`Exchange rate for this FIAT is not provided: ${fiatName}`)
  }

  return Math.round(bignumberify(amount).multipliedBy(rate).decimalPlaces(2)).toString()
}

export const sendwyreDaiToFiat = (fiatName, amount, sendwyreRates) => {
  if (!fiatName) {
    return 'undefined'
  }

  const upcaseFiatName = fiatName.toUpperCase();
  const rate = sendwyreRates[`${upcaseFiatName}DAI`];

  if (typeof rate === 'undefined') {
    throw new Error(`Exchange rate for this FIAT is not provided: ${fiatName}`)
  }

  return Math.round(bignumberify(amount).multipliedBy(rate).decimalPlaces(2)).toString()
}

export const sendwyreFiatToEth = (fiatName, amount, sendwyreRates) => {
  if (!fiatName) {
    return 'undefined'
  }

  const upcaseFiatName = fiatName.toUpperCase();
  const rate = sendwyreRates[`ETH${upcaseFiatName}`];
  console.log('--------- detected rate fiat to eth rate: ', rate)

  if (typeof rate === 'undefined') {
    throw new Error(`Exchange rate for this FIAT is not provided: ${fiatName}`)
  }

  return bignumberify(amount).multipliedBy(rate).toString()
}

export const roundUp = (value, decimals) => bignumberify(value).decimalPlaces(decimals).toString()

export const tokenToUsdt = (tokenAmount, { token_to_eth, eth_to_usdt }, keepOriginal) => {
  const result = ((bignumberify(tokenAmount).multipliedBy(token_to_eth).multipliedBy(eth_to_usdt)) * 100) / 100;

  if (keepOriginal) {
    return result.toString();
  }

  return Math.round(result).toString();
}

export const tokenToXdc = (tokenAmount, { token_to_eth, eth_to_xdc }, keepOriginal) => {
  const result = ((bignumberify(tokenAmount).multipliedBy(token_to_eth).multipliedBy(eth_to_xdc)) * 100) / 100;

  if (keepOriginal) {
    return result.toString();
  }

  return Math.round(result).toString();
}

export const tokenToPolygon = (tokenAmount, { token_to_eth, eth_to_polygon }, keepOriginal) => {
  const result = ((bignumberify(tokenAmount).multipliedBy(token_to_eth).multipliedBy(eth_to_polygon)) * 100) / 100;

  if (keepOriginal) {
    return result.toString();
  }

  return Math.round(result).toString();
}

export const tokenToDai = (tokenAmount, { token_to_eth, eth_to_dai }, keepOriginal) => {
  const result = ((bignumberify(tokenAmount).multipliedBy(token_to_eth).multipliedBy(eth_to_dai)) * 100) / 100;

  if (keepOriginal) {
    return result.toString();
  }

  return Math.round(result).toString();
}

export const tokenToAnything = (currency, value = 1, exchangeRates, keepOriginal = false) => {
  if (FIAT_CURRENCIES.indexOf(currency) !== -1) {
    return ethToFiat(
      currency,
      tokenToEth(value, exchangeRates),
      exchangeRates,
      keepOriginal,
    );
  }

  switch (currency) {
    case "eth":
      return tokenToEth(value, exchangeRates, keepOriginal);
    case "usdc":
      return tokenToUsdc(value, exchangeRates, keepOriginal);
    case "usdt":
      return tokenToUsdt(value, exchangeRates, keepOriginal);
    case "xdc":
      return tokenToXdc(value, exchangeRates, keepOriginal);
    case "polygon":
      return tokenToUsdt(value, exchangeRates, keepOriginal);
    case "dai":
      return tokenToUsdt(value, exchangeRates, keepOriginal);
    case "busd":
      return ethToFiat(
        'usd',
        tokenToEth(value, exchangeRates),
        exchangeRates,
        keepOriginal,
      );
    case "eure":
      return ethToFiat(
        'eur',
        tokenToEth(value, exchangeRates),
        exchangeRates,
        keepOriginal,
      );
    default:
      throw new Error(`Undefined currency: ${currency}`);
  }
};
