import React from 'react'
import TransactionList from '../../components/TransactionList'
import { TRANSACTIONS } from '../../queriesAndMutations'

import { useTranslation } from 'react-i18next'

const Transactions = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page__title h3">{ t('Transactions')}</div>

      <div className="transactions customer card">
        <TransactionList
          isAdmin={false}
          query={TRANSACTIONS}
        />
      </div>
    </>

  );
};

export default Transactions;
