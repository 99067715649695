import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const GET_BLACKLIST = gql`
    query getBlacklist {
        getBlacklist {
            id
            first_name
            last_name
            date_of_birth
            nationality
            passport_number
            company_name
            comment
            created_at
            updated_at
        }
    }
`;

export const GET_STATIC_BLACKLIST = gql`
    query getStaticBlacklist {
        getStaticBlacklist {
            id
            first_name
            last_name
            date_of_birth
            nationality
            passport_number
            company_name
            comment
            created_at
            updated_at
        }
    }
`;

export const CHECK_MATCH_BLACKLIST = gql`
    query checkMatchBlacklist($input: CheckMatchBlacklistInput) {
        checkMatchBlacklist(input: $input) {
            matched
            is_global
            record {
                id
                comment
            }
        }
    }
`;
