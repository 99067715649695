import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import Web3 from 'web3'
import {
  CircularProgress,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'

import metamaskService from '../services/metamask'
import { toaster, isProduction } from '../utils'
import {
  CHECK_BATCH_COMPENSATE,
  SAVE_PAYOUT_FOR_KYC,
} from '../queriesAndMutations'
import ModalInnerMaterialDialog from './ModalInnerMaterialDialog'
import allLiquidationSetting from '../constants/liquidation'

const liquidationSetting = allLiquidationSetting[process.env.REACT_APP_ENV]

const BatchCompensateDialog = ({
  open,
  onClose,
  customers,
}) => {
  const { t } = useTranslation()
  const missingMetamask = metamaskService.isMetamaskNotAvailable()
  const [account, setAccount] = useState(null)
  const [checkBatchCompensate, checkBatchCompensateStatus] = useLazyQuery(CHECK_BATCH_COMPENSATE)
  const [savePayoutForKyc, savePayoutForKycStatus] = useMutation(SAVE_PAYOUT_FOR_KYC)

  const requestConnectWallet = async () => {
    await metamaskService.requestAccounts();
    setAccount(metamaskService.getCurrentAddress())
  }
  const kycWithSameWallet = customers.find((customer) => (
    customer.tokenAddress &&
    account &&
    customer.tokenAddress.toLowerCase() === account.toLowerCase()
  ))

  const updateCompensationForKyc = (kycId, payoutTxHash) => {
    savePayoutForKyc({
      variables: {
        kycId,
        payoutTxHash,
      }
    }).then(({ data: { savePayoutForKyc : result } }) => {
      if (!result) {
        toaster.warning('Transactions changed. It will take a few minutes to confirm the transaction')
      }
      checkBatchCompensateStatus.refetch()
    }).catch(() => {})
  }

  const shortFormatEthAddress = (ethAddress) =>
    `${ethAddress.slice(0, 8)}....${ethAddress.slice(
      ethAddress.length - 8,
      ethAddress.length
    )}`;

  const sendCompensation = (kycId, addressTo, amount) => (async () => {
    await metamaskService.requireNetwork(isProduction ? 'mainnet' : 'sepolia', () => {
      window.location.reload()
    })

    const contractAddress = liquidationSetting.contract_address
    const decimal = liquidationSetting.decimal
    const web3 = new Web3(window.ethereum)
    const contract = new web3.eth.Contract(liquidationSetting.abi, contractAddress, { from: account });
    const transactionData = {
      from: account,
      to: contractAddress,
      value: "0x0",
      data: contract.methods.transfer(addressTo, amount * (10 ** +decimal)).encodeABI(),
      chainId: isProduction ? 1 : 4,
    }

    window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionData],
    }).then(txHash => {
      updateCompensationForKyc(kycId, txHash)
    }).catch((error) => {
      switch (error.code) {
        case 4001:
          toaster.error('You have denied to sign the transaction. Transaction cancelled.')
          break
        default:
          toaster.error(error.message)
          break
      }
    });
  })

  useEffect(() => {
    if (metamaskService.isMetamaskAvailable()) {
      window.ethereum.request({
        method: "eth_accounts",
      }).then(accounts => {
        setAccount(metamaskService.getCurrentAddress())
      })

      const onAccountChangeHandle = () => {
        setAccount(metamaskService.getCurrentAddress())
      }

      metamaskService.addOnAccountChangeHandle(onAccountChangeHandle)

      return () => metamaskService.removeOnAccountChangeHandle(onAccountChangeHandle)
    }

    return false;
  }, [])

  useEffect(() => {
    if (open && account && customers && customers.length > 0) {
      checkBatchCompensate({
        variables: {
          customerIds: customers.map(customer => customer.id)
        }
      })
    }
  }, [account, customers, open, checkBatchCompensate])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ModalInnerMaterialDialog
        modalSize="popup__kyc-medium"
        onClickClose={onClose}
        modalTitle={ t('Batch Compensating') }
        modalTitleClassColor="title-primary"
      >
        <div>

          {missingMetamask && (
            <p>You need to install metamask to continue this operation</p>
          )}

          {!account && (
            <button className="button" onClick={requestConnectWallet}>Connect wallet</button>
          )}

          {account && (
            <>
              <p>Payout from address: {account}</p>
              {kycWithSameWallet && (
                <p>
                  KYC {kycWithSameWallet.id} has the same wallet with your current Metamask account.
                </p>
              )}
              {checkBatchCompensateStatus.loading && (
                <CircularProgress />
              )}
              {checkBatchCompensateStatus.data && (
                <div className="batch-compensate-summary">
                  {checkBatchCompensateStatus.data.checkBatchCompensate.valid.length > 0 && (
                    <div className="valid-compensate">
                      <p className="text__green">Valid compensation</p>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>KYC ID</TableCell>
                            <TableCell>Wallet</TableCell>
                            <TableCell>Token Amount</TableCell>
                            <TableCell>Payout Amount</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {checkBatchCompensateStatus.data.checkBatchCompensate.valid.map(validRecord => (
                            <TableRow key={validRecord.id}>
                              <TableCell>{validRecord.id}</TableCell>
                              <TableCell>{shortFormatEthAddress(validRecord.wallet)}</TableCell>
                              <TableCell>{validRecord.token}</TableCell>
                              <TableCell>{validRecord.payout}</TableCell>
                              <TableCell>
                                {savePayoutForKycStatus.loading && (
                                  <CircularProgress />
                                )}
                                {!savePayoutForKycStatus.loading && (
                                  <button
                                    className="button"
                                    onClick={sendCompensation(validRecord.id, validRecord.wallet, validRecord.payout)}
                                  >
                                    Send compensation
                                  </button>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  )}

                  {checkBatchCompensateStatus.data.checkBatchCompensate.invalid.length > 0 && (
                    <div className="invalid-compensate">
                      <p className="text__red">Invalid compensation</p>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>KYC ID</TableCell>
                            <TableCell>Reason</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {checkBatchCompensateStatus.data.checkBatchCompensate.invalid.map(invalidRecord => (
                            <TableRow key={invalidRecord.id}>
                              <TableCell>{invalidRecord.id}</TableCell>
                              <TableCell>{invalidRecord.reason}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  )}

                </div>
              )}
            </>
          )}
        </div>
      </ModalInnerMaterialDialog>
    </Dialog>
  )
}

export default BatchCompensateDialog
