import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { omit } from 'lodash';
import moment from 'moment-timezone';
import {
  REGISTRATION_INDIVIDUAL_CUSTOMER,
  UPDATE_INDIVIDUAL_CUSTOMER,
  CUSTOMER_INDIVIDUAL,
} from '../../queriesAndMutations'
import { useMe, usePublicSettings } from '../../myHooks';
import { isFiat, getKycData, setKycData } from '../../utils';
import { TOKEN_TYPES } from '../../constants/settings'

import LoginHead from './LoginHead';
import EasyOnBoardingPersonalDetail from '../EasyOnBoardingPersonalDetail';
import EasyOnBoardingVerifyIdentity from '../EasyOnBoardingVerifyIdentity';
import EasyOnBoardingDetailofBirth from '../EasyOnBoardingDetailofBirth';
import EasyOnBoardingDetailWallet from '../EasyOnBoardingDetailWallet';
import EasyOnBoardingDetailEducation from '../EasyOnBoardingDetailEducation';
import EasyOnBoardingDetailSourcesOf from '../EasyOnBoardingDetailSourcesOf';
import EasyOnBoardingDetailAddresses from '../EasyOnBoardingDetailAddresses';
import EasyOnBoardingDetailDisclaimer from '../EasyOnBoardingDetailDisclaimer';
import EasyOnBoardingDetailThankyouTwo from '../EasyOnBoardingDetailThankyouTwo';

const EasyOnBoarding = () => {
  const [registerIndividualCustomer, registerIndividualCustomerStatus] = useMutation(REGISTRATION_INDIVIDUAL_CUSTOMER);
  const [updateIndividualCustomer, updateIndividualCustomerStatus] = useMutation(UPDATE_INDIVIDUAL_CUSTOMER);
  const [getCustomer, { data: { customerIndividual: customer } = {} }] = useLazyQuery(CUSTOMER_INDIVIDUAL)
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { me } = {} } = useMe();
  const history = useHistory();
  const query = new URLSearchParams(window.location.search)
  const country = query.get('country')

  const [values, setValues] = useState(getKycData() || {
    email: '',
    firstName: '',
    lastName: '',
    middleName: '',

    uniqueId: '',
    birthDate: '2000-01-01',

    amount: '0',
    exactIntendedInvestmentAmount: 0,
    intended_investment: '',
    currency: (publicSettings || {}).base_currency,
    wallet: '',
    sending_wallet: '',
    isAddEtherWalletLater: false,
    tokenAddress: '',
    generatedWallet: '',
    tokenSendingAddress: '',
    referal_code: localStorage.getItem('referalCode') || '',
    has_legal_protection_insurance: false,
    legal_protection_insurance: '',
    policy_number: '',

    education_level: '',
    occupation: '',
    industry: '',
    industryOther: '',
    employer_company_name: '',
    education_level_other: '',
    occupation_other: '',
    position: '',
    position_other: '',
    website: '',

    yearly_salary: '',
    net_investable_assets: '',
    what_will_you_use: '',
    what_will_you_use_other: '',
    descriptionOfFunds: '',
    source_of_funds: '',
    source_of_funds_other: '',
    supportedSourceOfFundUploadFiles: [],
    documentProofOfResidence: null,
    proof_of_residence_type: '',
    accept_impira_policy: false,

    descriptionOfWealth: '',
    source_of_wealth: '',
    source_of_wealth_other: '',

    placeOfBirth: '',
    nationality: '',
    nationality_two: '',
    nationality_three: '',
    countryOfResidence: '',
    residentialAddress: '',
    city: '',
    postalCode: '',

    personType: 'NOT_POLITICAL_PERSON',
    relationshipToPEP: '',
    duties: '',
    skip_product_tour: false,
  });

  const [disclaimer, setDisclaimer] = useState({
    // acknowledgementOfReceipt: false, // EQUITY|BOND|NONE
    clientStatement: false, // EQUITY|BOND|NONE|UTILITY
    // confirmation: false, // EQUITY|BOND|NONE

    // declarationOfConsent1: false, // EQUITY|BOND|NONE|UTILITY
    // declarationOfConsent2: false, // EQUITY|BOND|NONE|UTILITY
    // declarationOfConsent3: false, // EQUITY|BOND|NONE
    declarationOfConsent4: values.referal_code !== 'BlackMantaCapital', // EQUITY|BOND
    // declarationOfConsent5: false, // EQUITY|BOND
    // declarationOfConsent6: false, // EQUITY|BOND
    // declarationOfConsent7: false, // EQUITY|BOND
    usExportControl: false,
    applicableLaw: false,
    placeOfJurisdiction: false,
    severability: false,
  })

  useEffect(() => {
    if (publicSettings) {
      if (me) {
        if (me.ip_country !== 'CN' && country !== 'CN' && !me.phone_verified && me.verify_sms_send_at && (new Date()) - (new Date(parseInt(me.verify_sms_send_at, 10))) < 60000) {
          history.push('/easy-onboarding-verify-phone-number')

          return
        }

        if (country !== 'CN' && !me.phone_verified) {
          history.push('/easy-onboarding-2fa')

          return
        }

        if (!customer) {
          if (me.customer) {
            getCustomer({
              variables: {
                id: me.customer.id,
              },
            })
          } else {
            setValues({
              ...values,
              email: values.email || me.email,
              firstName: values.firstName || me.first_name || '',
              lastName: values.lastName || me.last_name || '',
              middleName: values.middleName || me.middle_name || '',
              phoneNumber: values.phoneNumber || me.phone,
              birthDate: moment(values.birthDate || me.birth_date || '2000-01-01').format('YYYY-MM-DD'),
              tokenAddress: values.tokenAddress || me.wallet_address || '',
              ...(me.referral_code ? { referal_code: me.referral_code } : {}),
              wallet: values.wallet || (publicSettings.kyc_accepted_wallets ? Object.keys(publicSettings.kyc_accepted_wallets).find((wallet) => publicSettings.kyc_accepted_wallets[wallet]) : 'eth'),
              amount: parseInt(values.amount, 10)
                ? String(values.amount)
                : publicSettings.enforce_token_minimum_at_kyc && publicSettings.minimum_investment_amount && publicSettings.token_type !== TOKEN_TYPES.NONE
                  ? String(publicSettings.minimum_investment_amount) : '0'
            })
          }
        } else {
          if (customer && customer.status !== 'REOPEN' && customer.status !== 'REOPEN_WITH_VIDEOIDENT') {
            if (!registerIndividualCustomerStatus.data) {
              window.location.href = `${window.location.origin}/easy-onboarding-verify-identity`
            }
          } else {
            setValues({
              ...values,
              email: me.email,
              firstName: me.first_name || '',
              lastName: me.last_name || '',
              middleName: me.middle_name || '',
              phoneNumber: me.phone,
              birthDate: moment(me.birth_date || '2000-01-01').format('YYYY-MM-DD'),
              wallet: Object.keys(publicSettings.kyc_accepted_wallets).find((wallet) => publicSettings.kyc_accepted_wallets[wallet]) || 'eth',
              ...(Object.keys(values).reduce((all, key) => {
                if (customer[key] === null || customer[key] === undefined) {
                  return all
                }

                return {
                  ...all,
                  [key]: customer[key]
                }
              }, {}))
            });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, customer, publicSettings])

  const [step, setStep] = useState(0)
  const [lastStep, setLastStep] = useState(0)

  const cacheKycData = () => {
    setKycData({
      ...values,
      supportedSourceOfFundUploadFiles: [],
      documentProofOfResidence: '',
      skip_product_tour: false,
    })
  }

  const goToNextStep = () => {
    setLastStep(step)
    setStep(step + 1)
    cacheKycData()
  }
  const goToPrevStep = () => {
    setLastStep(step)
    setStep(step - 1)
  }
  const goToStep = (newStep) => {
    setLastStep(step)
    setStep(newStep)
    cacheKycData()
  }
  const skipStep = () => {
    if (lastStep - step > 0) {
      goToPrevStep()
    }
    if (lastStep - step < 0) {
      goToNextStep()
    }
  }

  const submitKyc = () => {
    if (customer) {
      updateIndividualCustomer({
        variables: {
          id: customer.id,
          input: {
            ...omit(values, [
              'source_of_funds',
              'source_of_funds_other',
              'source_of_wealth',
              'source_of_wealth_other',
              'referal_code',
              'generatedWallet',
            ]),
            isAddEtherWalletLater: values.isAddEtherWalletLater || isFiat(values.currency),
            exactIntendedInvestmentAmount: +values.exactIntendedInvestmentAmount,
            sourceOfFunds: values.source_of_funds,
            sourceOfFundsOther: values.source_of_funds_other,
            sourceOfWealth: values.source_of_wealth,
            sourceOfWealthOther: values.source_of_wealth_other,
            supportedSourceOfFundUploadFiles: values.supportedSourceOfFundUploadFiles.map((fileObject) => fileObject.file),
            documentProofOfResidence: (values.documentProofOfResidence && values.documentProofOfResidence.img) || null,
            amount: String(values.amount),
          }
        }
      }).then(() => {
        setStep(7);
      }).catch(() => {})
    } else {
      cacheKycData()

      registerIndividualCustomer({
        variables: {
          input: {
            ...omit(values, [
              'source_of_funds',
              'source_of_funds_other',
              'source_of_wealth',
              'source_of_wealth_other',
              'generatedWallet',
              'skip_product_tour',
            ]),
            isAddEtherWalletLater: values.isAddEtherWalletLater || isFiat(values.currency),
            exactIntendedInvestmentAmount: +values.exactIntendedInvestmentAmount,
            sourceOfFunds: values.source_of_funds,
            sourceOfFundsOther: values.source_of_funds_other,
            sourceOfWealth: values.source_of_wealth,
            sourceOfWealthOther: values.source_of_wealth_other,
            documentProofOfResidence: (values.documentProofOfResidence && values.documentProofOfResidence.img) || null,
            amount: String(values.amount),
          }
        }
      }).then(() => {
        setStep(7);
      }).catch(() => {})
    }
  }

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <EasyOnBoardingPersonalDetail
            values={values}
            setValues={setValues}
            goToNextStep={goToNextStep}
            goToStep={goToStep}

          />
        )
      case 1:
        return (
          <EasyOnBoardingDetailofBirth
            values={values}
            setValues={setValues}
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
            goToStep={goToStep}
          />
        )
      case 2:
        return (
          <EasyOnBoardingDetailWallet
            values={values}
            setValues={setValues}
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
            goToStep={goToStep}
            skipThisStep={skipStep}
          />
        )
      case 3:
        return (
          <EasyOnBoardingDetailEducation
            values={values}
            setValues={setValues}
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
            goToStep={goToStep}
            skipThisStep={skipStep}
          />
        )
      case 4:
        return (
          <EasyOnBoardingDetailSourcesOf
            values={values}
            setValues={setValues}
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
            goToStep={goToStep}
            skipThisStep={skipStep}
          />
        )
      case 5:
        return (
          <EasyOnBoardingDetailAddresses
            values={values}
            setValues={setValues}
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
            goToStep={goToStep}
            submitKyc={submitKyc}
            loading={registerIndividualCustomerStatus.loading || updateIndividualCustomerStatus.loading}
          />
        )
      case 6:
        return (
          <EasyOnBoardingDetailDisclaimer
            values={values}
            setValues={setValues}
            disclaimer={disclaimer}
            setDisclaimer={setDisclaimer}
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
            submitKyc={submitKyc}
            loading={registerIndividualCustomerStatus.loading || updateIndividualCustomerStatus.loading}
            goToStep={goToStep}
          />
        )
      case 7:
        return (
          <Redirect to="/easy-onboarding-verify-identity" />
        )
      case 8:
        return (
          <EasyOnBoardingVerifyIdentity
            values={values}
            setValues={setValues}
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
            goToStep={goToStep}
          />
        )
      case 9:
        return (
          <EasyOnBoardingDetailThankyouTwo
            values={values}
            setValues={setValues}
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
            goToStep={goToStep}
          />
        )
      default:
      return null
    }
  }

  if (me && !me.phone_verified && country !== 'CN') {
    return <Redirect to="/easy-onboarding-2fa" />
  }

  return (
    <>
      <LoginHead />

      {renderStep()}
    </>
  )
}

export default EasyOnBoarding;
