import gql from 'graphql-tag';

export const DEPLOY_CONTRACT = gql`
  mutation deployContract($input: DeployContractInput) {
    deployContract(input: $input)
  }
`;

export const DEPLOY_CONTRACT_RESULT = gql`
  mutation deployContractResult($input: DeployContractResultInput) {
    deployContractResult(input: $input)
  }
`;

export const GRANT_CONTROLLER_ROLE = gql`
  mutation grantControllerRole($input: GrantControllerRoleInput) {
    grantControllerRole(input: $input)
  }
`;
