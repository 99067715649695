import React from 'react'
import PropTypes from 'prop-types'
import TransactionComponent from '../../components/Transaction'
import { useTranslation } from 'react-i18next'

const Transaction = ({ match }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="page__inner">
        <div className="page__container">

          <div className="page__title h3">{ t('Transaction detail') }</div>

          <TransactionComponent id={match.params.transactionId} />
        </div>
      </div>
    </>
  )
}

Transaction.propTypes = {
  match: PropTypes.object.isRequired,
}

export default Transaction
