import React from "react";
import Menu from "./Menu";
import NeedToDo from "./NeedToDo";

const Help = ({
  todoKyc,
  todoUpdateProfile,
  todoEnable2FA,
  todoWalletAddress,
  onHelpSidebarActive,
  signnow,
  signnowLinkInvite,
}) => {
  return (
    <>
      <NeedToDo
        kyc={todoKyc}
        updateProfile={todoUpdateProfile}
        enable2FA={todoEnable2FA}
        walletAddress={todoWalletAddress}
        onHelpSidebarActive={onHelpSidebarActive}
        signnow={signnow}
        signnowLinkInvite={signnowLinkInvite}
      />

      <Menu />
    </>
  )
}

export default Help;
