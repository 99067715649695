import gql from 'graphql-tag'

export const GET_PERSONAL_DISCOUNT_LIST = gql`
  query getPersonalDiscountList($input: GetPersonalDiscountListInput!) {
    getPersonalDiscountList(input: $input) {
      meta {
        page
        pageSize
        totalPages
        total
      }
      results {
        id
        email
        price
        currency
      }
    }
  }
`;

export const GET_PERSONAL_DISCOUNT_IMPORT_LOG_LIST = gql`
  query getPersonalDiscountImportLogList($input: GetPersonalDiscountImportLogListInput!) {
    getPersonalDiscountImportLogList(input: $input) {
      meta {
        page
        pageSize
        totalPages
        total
      }
      results {
        id
        source
        status
        detail
        created_at
        user {
          id
          email
        }
      }
    }
  }
`;

export const GET_MY_PERSONAL_DISCOUNT = gql`
  query getMyPersonalDiscount {
    getMyPersonalDiscount {
      token_to_eth
      eth_to_usd
      eth_to_eur
      eth_to_chf
      eth_to_usdt
    }
  }
`;
