import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { use100vh } from 'react-div-100vh'
import PasswordRestoreConfirmForm from './ConfirmForm'
import { usePublicSettings } from '../../myHooks/useSettings'
import { getFileUrl } from '../../utils'
import { useTranslation } from 'react-i18next'
import Footer from '../../components/Footer'
import { isIE } from "react-device-detect"
import NotSupportViewer from '../../components/NotSupportViewer'

import '../../styles/app.min.css'
import '../../styles/style.css'

const PasswordReset = () => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { t } = useTranslation();

  const heightWindow = use100vh();

  if (isIE) {
    return <NotSupportViewer />;
  }

  return (
    <>
      <div style={{ minHeight: heightWindow }} className="avoid_minheight__rwd">
        <div className="login entry__signin">
          <div className="login__head">
            <div className="login__info login__info-kyc">
              <NavLink to="/">{ t('Back to login page') }</NavLink>
            </div>
          </div>
        </div>

        <div className="entry entry_loginregister">
          <div className="entry__wrapper entry__wrapper-kyc">
            <Link className="entry__logo entry__logo-kyc" to="/">
              <img
                src={publicSettings && getFileUrl(publicSettings.logo_path)}
                alt="logo"
                className="some-icon"
              />
            </Link>

            <PasswordRestoreConfirmForm />

          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default PasswordReset;
