import config from '../config'

const getJwt = () => {
  let jwt = localStorage.getItem('jwt');

  if (!jwt) {
    const cookie = document.cookie.split('; ').find((c) => c.match(/^jwt=/));

    if (cookie) {
      jwt = (new URLSearchParams(cookie)).get('jwt');
    }
  }

  return jwt || '';
}
// eslint-disable-next-line import/prefer-default-export
export const getFileUrl = (filename) => {
  if (window.location.origin !== 'http://localhost:3000' && window.location.origin !== 'http://localhost:3001') {
    return `${window.location.origin}/file/${filename}`
  }

  return `${config.fileEndpoint}/${filename}?jwt=${getJwt()}`
}

export const getExternalFileUrl = (filename) => {
  if (window.location.origin !== 'http://localhost:3000' && window.location.origin !== 'http://localhost:3001') {
    return `${getFileUrl(filename)}?origin=${window.location.origin}`
  }

  return `${getFileUrl(filename)}&origin=${window.location.origin}`
};

export const getOriginalFileExtension = (filename) => {
  const regexp = /.+\.([a-zA-Z]{3,4})$/i
  const result = regexp.exec(filename)
  return (result || [])[1]
}
