import React, { useEffect } from "react"
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from 'react-i18next';
import SwitchCheckboxTheme from '../SwitchCheckbox'

const useStyles = makeStyles(() => ({
  declarationWarning: {
    border: "1px solid",
    padding: "10px",
    margin: " 20px 0 50px 30px",
  },
}))

const CompanyStep5DisclaimerNone = ({
  publicSettings,
  checkboxValues,
  setCheckboxValues,
  prospectusOrIssuing,
  setEnableSubmit
}) => {
  const { t } = useTranslation();
  const classes = useStyles()

  useEffect(() => {
    const enable = [
      'IActOnMyOwnAccount',
      'clientStatement',
      'confirmationTerms1',
      'declarationOfConsent1',
      'declarationOfConsent2',
      'declarationOfConsent3',
    ].reduce((all, key) => all && checkboxValues[key], true);

    setEnableSubmit(enable);
    // eslint-disable-next-line
  }, [checkboxValues]);

  const changeCheckbox = (field) => ({target: {checked}}) => {
    setCheckboxValues({...checkboxValues, [field]: checked})
  }

  return (
    <>
      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Acknowledgement of Receipt')}
        </div>

        <div className="settings__fieldset">
          <div className="field">
            <div className="field__wrap">
              <SwitchCheckboxTheme
                onChange={changeCheckbox('IActOnMyOwnAccount')}
                defaultChecked={checkboxValues.IActOnMyOwnAccount}
                value="IActOnMyOwnAccount"
                switchText={`
                  I Confirm That I Act On My Own Account, In My Own Economic
                  Interests And Not On Behalf Of Others.
                `}
                isRemoveLine
                isGrayText
                isLeftSwitch
              />
            </div>
          </div>
        </div>
      </div>

      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Client Statement')}
        </div>

        <div className="field">
          <div className="field__wrap">
            <SwitchCheckboxTheme
              onChange={changeCheckbox('clientStatement')}
              defaultChecked={checkboxValues.clientStatement}
              value="clientStatement"
              switchText={`
                The Legal Entity confirms that it is the beneficial owner
                of all assets held with ${
                  publicSettings &&
                  publicSettings.company &&
                  publicSettings.company.name
                }.
              `}
              isRemoveLine
              isGrayText
              isLeftSwitch
            />
          </div>
        </div>
      </div>

      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Confirmations')}
        </div>

        <div className="field">
          <div className="field__wrap">
            <SwitchCheckboxTheme
              value="confirmationTerms1"
              defaultChecked={checkboxValues.confirmationTerms1}
              onChange={changeCheckbox('confirmationTerms1')}
              isRemoveLine
              isGrayText
              isLeftSwitch
              switchText={
              <div>
                <span>
                  {`
                    I Hereby Confirm That, Before Subscribing, I Have Received
                    And Read The ${prospectusOrIssuing} Of The Issuer
                    Promptly And In Full And In Particular That
                    I Accept The Subscription Applications.
                  `}
                </span>
                {publicSettings.token_symbol !== 'CAAG' && (
                  <>
                    <span>Especially I Certify That</span>
                    <ul className="circle-bullet">
                        { (publicSettings.enable_blacklist_citizen && publicSettings.blacklist_citizen_choosen) && (
                          <li>
                            <small>
                              {t('Am Not A Citizen Of The CITIZEN', {CITIZEN: publicSettings.blacklist_citizen_choosen})}
                            </small>
                          </li>
                        )}
                      <li>
                        <small>
                          Do Not Hold A Permanent Residence And Work Permit For
                          The US (Green Card), Canada Or Australia
                        </small>
                      </li>
                      <li>
                        <small>
                          Have No Residence Or Principal Place Of Business In The
                          USA, Canada Or Australia Or Their Respective Territories
                        </small>
                      </li>
                      <li>
                        <small>
                          Am Not A Corporation Or Any Other Asset Organized Under
                          The Laws Of The United States, Canada Or Australia, The
                          Income Of Which Is Governed By The Laws Of Canada Or
                          Australia
                        </small>
                      </li>
                      <li>
                        <small>
                          Am Not On Any Of The Sanction Lists Of The European
                          Union, The United States, Canada Or Australia
                        </small>
                      </li>
                    </ul>
                    <small>
                      I Have Duly Noted The Risks And Their Potential Implications
                      Described In The Prospectus And Hereby Accept Them
                    </small>
                  </>
                )}
              </div>
            }
            />
          </div>
        </div>
      </div>

      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Declaration of Consent')}
        </div>

        <div className="field">
          <div className="field__wrap">
            <SwitchCheckboxTheme
              onChange={changeCheckbox('declarationOfConsent1')}
              defaultChecked={checkboxValues.declarationOfConsent1}
              value="declarationOfConsent1"
              isRemoveLine
              isGrayText
              isLeftSwitch
              switchText={
              <div>
                <span>
                  {`
                    I Hereby Consent To My Personal Data Being Processed By The
                    Issuer And All Companies Affiliated With It, As Well As The
                    Persons And Entities Involved In The Management And
                    Supervision Of My Investment (In Particular The Issuer’s
                    Business, Banks, Tax Advisors, Auditors) Being Stored In
                    Computer Equipment And Be Used.
                  `}
                </span>

                <div className={classes.declarationWarning}>
                  {t('Warnings')}:
                  {' '}
                  {t('The Acquisition Of The TOKENS_OR_SHARES Involves Considerable Risks And May Result In The Complete Loss Of The Invested Assets.', {
                    TOKENS_OR_SHARES: t(publicSettings.rename_share_to_token ? 'Tokens': 'Shares'),
                  })}
                  {' '}
                  {t('The Issuer Indicates That It Does Not Assess Whether (1) The TOKENS_OR_SHARES Comply With The Investment Objectives Of The Subscriber, (2) The Resulting Investment Risk Is Financially Viable For The Subscriber Of Its Investment Objectives And (3) The Subscriber With His / Her / Its Knowledge And Experiences Can Understand The Resulting Investment Risks.', {
                    TOKENS_OR_SHARES: t(publicSettings.rename_share_to_token ? 'Tokens': 'Shares'),
                  })}
                </div>
              </div>
            }
            />

            <SwitchCheckboxTheme
              isRemoveLine
              isGrayText
              isLeftSwitch
              onChange={changeCheckbox('declarationOfConsent2')}
              defaultChecked={checkboxValues.declarationOfConsent2}
              value="declarationOfConsent2"
              switchText={`
                I Hereby Confirm That The Declarations Made To The Best Of My
                Knowledge And Belief Are Correct And Complete. Any Changes To The
                Aforementioned Circumstances Will Be Communicated To The Issuer
                Without Delay In Written Form And Must Be Forwarded An Updated
                Self-Assessment Within 30 Days Upon Request.
              `}
            />

            <SwitchCheckboxTheme
              isRemoveLine
              isGrayText
              isLeftSwitch
              onChange={changeCheckbox('declarationOfConsent3')}
              defaultChecked={checkboxValues.declarationOfConsent3}
              value="declarationOfConsent3"
              switchText={`
                I Have Read The Aforementioned “Warnings” And Especially
                The “Risk Information”
              `}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CompanyStep5DisclaimerNone
