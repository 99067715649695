import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import qrcode from 'qrcode'
import {
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core'
import { NavLink } from "react-router-dom"

import InputThemeField from '../components/InputThemeField'
import { NEW_2FA_SECRET, SET_2FA_SECRET } from '../queriesAndMutations'
import { useMe, usePublicSettings } from '../myHooks'

import { useTranslation } from 'react-i18next'

const AdminSecurity = () => {
  const { t } = useTranslation();
  const stepListAdminSecurity = [
    'Intro',
    'Setting up 2FA',
    'Confirm 2FA',
    'Success'
  ];

  const [value, setValue] = useState({
    qrCodeUrl: null,
    token: '',
  })

  const { data: { me: userData = {} } = {}, refetch: refetchMe, loading: meLoading } = useMe()
  const { data: { new2FASecret } = {} } = useQuery(NEW_2FA_SECRET)
  const [set2FASecret, { loading, error }] = useMutation(SET_2FA_SECRET)
  const { data: { publicSettings: { company } = {} } = {} } = usePublicSettings()

  useEffect(() => {
    if (userData && new2FASecret && value.qrCodeUrl === null && company) {
      const secretToUrl = (secret) => `otpauth://totp/${encodeURIComponent((company || {}).name)}:${encodeURIComponent(userData.email)}?secret=${secret}`

      qrcode.toDataURL(secretToUrl(new2FASecret)).then((qrCode) => setValue({
        ...value,
        qrCodeUrl: qrCode,
      }))
    }
    // eslint-disable-next-line
  }, [new2FASecret, userData, company])

  const [theStepAdminSecurity, setTheStepAdminSecurity] = useState(1);

  useEffect(() => {
    if (userData && !meLoading) {
      if (!userData.email) {
        window.location.href = '/login'
      } else if (userData.is_2fa_enabled) {
        window.location.href = '/admin'
      }
    }

    // eslint-disable-next-line
  }, [userData, meLoading]);

  const prevStepAdminSecurity = () => {
    if (theStepAdminSecurity > 1) {
      setTheStepAdminSecurity(theStepAdminSecurity - 1);
    }
  }

  const nextStepAdminSecurity = () => {
    if (theStepAdminSecurity < 4) {
      setTheStepAdminSecurity(theStepAdminSecurity + 1)
    } else {
      refetchMe()
    }
  }

  const onSubmitToken = async (e) => {
    e.preventDefault()
    try {
      await set2FASecret({ variables: { secret: new2FASecret, token: value.token } })
      nextStepAdminSecurity()
    } catch (e) {}
  }

  const renderFirstStep = () => {
    return (
      <>
        <div className="h2 login__title text-center second-security__title">{ t('Add a second layer of security') }</div>

        <div className="login__entry second-security__entry">
          <div className="login__top text-center second-security__subtitle">
            { t('To set up multi-factor authentication, you will need an') }
            {' '}
            <strong>{ t('authenticator app on your phone') }</strong>
          </div>

          <div className="creators__item second-security__item">
            <div className="creators__line second-security__item-title">
              <div className="creators__man">
                { t('Why  multi-factor authentication is important') }
              </div>

              <div className="creators__number" style={{backgroundColor: '#B5E4CA'}}>
                #1
              </div>
            </div>

            <div className="login__note second-security__item-note">
              { t('Multi-factor authentication protects you against hackers. Even if someone steals your password, your account and financial data stays secure') }
            </div>
          </div>

          <div className="creators__item second-security__item">
            <div className="creators__line second-security__item-title">
              <div className="creators__man">
                { t('How it protects you') }
              </div>

              <div className="creators__number" style={{backgroundColor: '#FFD88D'}}>
                #2
              </div>
            </div>

            <div className="login__note second-security__item-note">
              <p>{ t('Multi-factor authentication adds a second layer of security to your account.') }</p>
              <p>{ t('Once you are set up, you will need your password and an authenticator app to log in. The app confirms it is you logging in and not someone else trying to access your data.') }</p>
            </div>
          </div>

          <div className="creators__item second-security__item-">
            <div className="creators__line second-security__item-title">
              <div className="creators__man">
                { t('What is need for setup') }
              </div>

              <div className="creators__number" style={{backgroundColor: '#CABDFF'}}>
                #3
              </div>
            </div>

            <div className="login__note second-security__item-note">
              <p>
                { t('You will step through a setup process to sync your account to an authenticator app.') }
              </p>

              <p>{ t('To do this, you will need download or use an existing authenticator app on your phone.') }</p>
            </div>
          </div>
        </div>

        <div className="creators__foot second-security__foot">
          <button type="button" onClick={nextStepAdminSecurity} className="button button-small creators__button second-security__button">
            { t('Set up multi-factor authentication') }
          </button>
        </div>
      </>
    )
  }

  const renderSecondStep = () => {
    return (
      <>
        <div className="h4 login__title text-center second-security__title">{ t('Scan QR code') }</div>
        <div className="login__entry second-security__entry">
          <div className="settings__wrapper">
            <div className="card__head">
              <div className="refund__content card__head-content">
                <p>
                  { t('Please scan the QR Code below with any authenticator app on your phone that supports the standard QR Code security, eg Google authenticator, Microsoft authenticator, Authy') }
                </p>
              </div>
            </div>

            <div className="settings__fieldset">
              <div className="field d__flex flex__align_center flex__justify_center">
                <img src={value.qrCodeUrl} alt="" />
              </div>
            </div>
            <br />
            <div className="creators__foot second-security__foot">
              <button type="button" onClick={nextStepAdminSecurity} className="button button-small creators__button second-security__button">
                { t('Next step') }
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderThirdStep = () => {
    return (
      <>
        <div className="h4 login__title text-center second-security__title">{ t('Authentication code') }</div>

        <form onSubmit={onSubmitToken}>
          <div className="settings__wrapper second-security__entry">
            <div className="settings__list">
              <div className="settings__item creators__item second-security__item">

                <div className="settings__fieldset">
                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    value={value.token}
                    onChange={({ target: { value: inputValue }}) => setValue({
                      ...value,
                      token: inputValue,
                    })}

                    label={
                      <>
                        { t('Enter the 6-digit code found in your authenticator app') }
                      </>
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
            <button
              className="button-stroke"
              onClick={prevStepAdminSecurity}
            >
              { t('Previous step') }
            </button>
            <button type="submit" className="button">{ t('Next step') }</button>
          </div>
        </form>
      </>
    )
  }

  const renderFourthStep = () => {
    return (
      <>
        <div className="h2 login__title text-center second-security__title">{ t('Setup Complete') }</div>

        <div className="login__entry second-security__entry">
          <div className="login__top text-center second-security__subtitle">
            { t('Next time you log in, you will need to open your authenticator app and use the 6-digit code to authenticate your login') }
          </div>
        </div>

        <div className="creators__foot second-security__foot">
          <button type="button" onClick={nextStepAdminSecurity} className="button button-small creators__button second-security__button">
            { t('Continue') }
          </button>
        </div>
      </>
    )
  }

  return (
    <div className="creators second-security">
      <div className="creators__head second-security__head">
        <div className="kyc__stepper kyc__stepper_32 second-security__stepper">
          <Stepper
            activeStep={theStepAdminSecurity - 1}
          >
            {stepListAdminSecurity.map((x) => (
              <Step key={x}>
                <StepLabel>{x}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      </div>

      <div className="creators__list second-security__list">
        {theStepAdminSecurity === 1 && renderFirstStep()}

        {theStepAdminSecurity === 2 && renderSecondStep()}

        {theStepAdminSecurity === 3 && renderThirdStep()}

        {theStepAdminSecurity === 4 && renderFourthStep()}

      </div>
    </div>
  )
}

export default AdminSecurity
