import React, { useState } from 'react'
import '../styles/legacy/style.scss'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useQuery } from '@apollo/react-hooks'
import { usePublicSettings } from '../myHooks/useSettings'
import { useMe } from "../myHooks";

import KycCompanyVerificationForm from '../components/KycCompanyVerificationForm'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  CUSTOMER_COMPANY,
} from '../queriesAndMutations'

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  tabs: {
    marginBottom: '20px',
  },
  successSend: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '150px',
  },
  iconFile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: '70%',
      height: '70%',
      opacity: '.4',
    },
  },
}))

const UpdateKycCompanyVerification = ({ match }) => {
  const classes = useStyles()
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { me: userData = {} } = {} } = useMe();

  const INTENDED_INVESTMENT =
    publicSettings.kyc_levels ?
    Object.values(publicSettings.kyc_levels).filter((item) => {
      return !item.enable || item.enable.enable_lvl;
    })
    .map((item, index) => {
      const unit = publicSettings.kyc_use_base_currency ? (publicSettings.base_currency.toUpperCase() || 'ETH') : 'CHF';

      if (item.enable && item.enable.enable_open_end) {
        return `> ${item.min_invest_amount} ${unit}`;
      }

      return `${item.min_invest_amount} - ${item.max_invest_amount} ${unit}`;
    }) : [];

  const { loading, data, error } = useQuery(
    CUSTOMER_COMPANY, {
    variables: {
      id: match.params.customerId,
    },
    fetchPolicy: 'network-only',
  })
  const customer = (data || {}).customerCompany
  const [kycFormStepNumber, setKycFormStepNumber] = useState(0);

  const renderKycVerifyForm = () => {
    if (customer && customer.status !== 'REOPEN') {
      return (
        <p>Done</p>
      )
    } else {
      return (
        <div>
          <KycCompanyVerificationForm
            publicSettings={publicSettings}
            userData={userData}
            kycFormStepNumber={kycFormStepNumber}
            setKycFormStepNumber={setKycFormStepNumber}
            intendedInvestmentOptions={INTENDED_INVESTMENT}
            oldData={customer}
          />
        </div>
      )
    }
  }

  return (
    <>
      <div className="page-dashboard__content">
        <div className="dashboard-application">
          {error
          ? <div className={classes.circularProgressWrapper}>Some error</div>
          : (
            <>
              { !loading ? renderKycVerifyForm() : <div className={classes.circularProgressWrapper}><CircularProgress /></div> }
            </>
          )}
        </div>
      </div>

    </>
  )
}

export default UpdateKycCompanyVerification
