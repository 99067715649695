import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FormControlLabel } from '@material-ui/core'
import { usePublicSettings } from '../../../myHooks'
import { PROSPECTUS_OR_ISSUING, TOKEN_TYPES } from '../../../constants/settings'
import SwitchCheckboxTheme from '../../SwitchCheckbox'
import CompanyStep5DisclaimerCaag from '../../KycCompanyVerificationForm/CompanyStep5DisclaimerCaag'
import CompanyStep5DisclaimerNone from '../../KycCompanyVerificationForm/CompanyStep5DisclaimerNone'
import CompanyStep5DisclaimerLiquidation from '../../KycCompanyVerificationForm/CompanyStep5DisclaimerLiquidation'
import CompanyStep5DisclaimerEquityBond from '../../KycCompanyVerificationForm/CompanyStep5DisclaimerEquityBond'
import CompanyStep5DisclaimerUtility from '../../KycCompanyVerificationForm/CompanyStep5DisclaimerUtility'
import InputThemeField from '../../InputThemeField'
import Icon from '../../Icon'
import styles from './styles.module.scss'

const CardForm = ({
  values,
  setValues,
  goToNextStep,
  goToPrevStep,
  checkboxValues,
  setCheckboxValues,
  submitForm,
  loading,
}) => {
  const { t } = useTranslation()
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  const [enableSubmit, setEnableSubmit] = useState(false);
  let prospectusOrIssuingDate, prospectusOrIssuing;
  const currentHost = window.location.host;
  const isAwearetech = currentHost === 'kyc.awearetech.li' || currentHost === 'aweare-dev.investhub.io' || currentHost === 'localhost:3000';

  const awearetechDisclaimerText = {
    usExportControl:
    `Due to United States of America export control and SEC regulations, this offering is not available for those with jurisdiction and/or domicile in the United States, Iran, Russia, North Korea, Belarus, Syria, and any territories thereof. If any prospective investor with jurisdiction and/or domicile in these nations or territories make an incorrect or false answer regarding jurisdiction and/or domicile outside of these nations or territories, that prospective investor understands that making incorrect/false statements may be considered felony offences including but not limited to perjury, wire fraud, and making false statements, depending on their specific local laws and circumstances.
    <br><br>
    The undersigned hereby declares that they are a bona fide resident of the country of ${values.companyCountry} and reside in and maintain at least one place of abode in the Domiciled Country which they recognize and intend to maintain as their permanent home. If maintaining one or more other places of abode outside of the Domiciled Country, the undersigned also declares that the Domiciled Country constitutes their predominant and principal home.
    <br><br>
    The undersigned affirms and attests to the fact that they are not domiciled in the United States of America, Russia, Iran, North Korea, Belarus, Syria, or any territories thereof.
    <br><br>
    The undersigned hereby declares that they will inform AWEARE Global AG administration through electronic means: admin@awearetech.li if at any time they intend to change their Domiciled Country to the United States, Russia, Iran, North Korea, Belarus, Syria, or any territories thereof for the express purpose of reviewing held securities as subject to repurchase.
    <br><br>
    I further certify that I understand there is a penalty for perjury; and that perjury is a felony and is punishable by fines and/or incarceration in accordance with national and/or state laws.`,
    applicableLaw: `The offer and the agreement for the purchase of AWEARE Global Tokens as well as all rights and obligations arising in connection with or from the offer and the agreement for the purchase of AWEARE Global Tokens shall be governed exclusively by Liechtenstein law, excluding the conflict of law rules and referral provisions.`,
    placeOfJurisdiction: `The courts of the Principality of Liechtenstein shall have exclusive jurisdiction for all disputes arising out of or in connection with the offer or the agreement for the purchase of AWEARE Global Tokens, including those concerning the valid conclusion, legal validity, amendment or cancellation.`,
    severability: `If at any time one or more of the provisions of the AWEARE Global Token Offer and the agreement for its purchase is or becomes unlawful, invalid, illegal or unenforceable in any respect, the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired thereby.`,
  }

  const changeCheckbox = (field) => ({target: {checked}}) => {
    setCheckboxValues({...checkboxValues, [field]: checked});
  }

  useEffect(() => {
    let listConfirm = [
      'clientStatement',
      'pep_beneficial_owner',
    ];

    if (isAwearetech) {
      listConfirm.push('usExportControl')
      listConfirm.push('applicableLaw')
      listConfirm.push('placeOfJurisdiction')
      listConfirm.push('severability')
    }

    const enable = listConfirm.reduce((all, key) => all && checkboxValues[key], true);

    setEnableSubmit(enable);
    // eslint-disable-next-line
  }, [checkboxValues]);

  switch ((publicSettings || {}).prospectus_or_issuing) {
    case PROSPECTUS_OR_ISSUING.ISSUING:
      prospectusOrIssuing = "Issuing Guidelines";
      prospectusOrIssuingDate = moment(
        publicSettings.issuing_guidelines
      ).format("YYYY-MM-DD");
      break;
    case PROSPECTUS_OR_ISSUING.PROSPECTUS:
      prospectusOrIssuing = "Prospectus";
      prospectusOrIssuingDate = moment(publicSettings.prospectus_date).format(
        "YYYY-MM-DD"
      );
      break;
    default:
      prospectusOrIssuing = "Token Sale Agreement";
      prospectusOrIssuingDate = moment(
        publicSettings.token_sale_agreement_date
      ).format("YYYY-MM-DD");
      break;
  }

  if (!publicSettings) {
    return (
      <CircularProgress />
    )
  }

  return (
    <div className="card">
      <div className="settings__wrapper">
        <div className="settings__item">
          <div className="card__head card__head-kyc-1">
            <div className="title-primary card__title">
              {t('Legal disclaimers')}
            </div>
          </div>

          <div className="settings__fieldset">
            <SwitchCheckboxTheme
              switchText={t('The beneficial owner (plural inclusive) of the company is neither a politically exposed person (PEP), nor an immediate family member or a person known to be close to him.')}
              value="pep_beneficial_owner"
              isGrayText
              isLeftSwitch
              isRemoveLine
              defaultChecked={checkboxValues.pep_beneficial_owner}
              onChange={changeCheckbox('pep_beneficial_owner')}
            />
            <div className="space space__24">{' '}</div>
          </div>
        </div>

        <>
          <div style={{ display: "none"}}>
            <div className="field field-radio__material">
              <div className="field__label">
                {t('Politically Exposed Persons')}
              </div>

              <div className={classNames('field__wrap field__wrap-radio field__wrap-radio_text_gray', styles.field__vert)}>
                <RadioGroup
                  row
                  defaultValue="exposed-1"
                  value={values.personType}
                  onChange={({ target: { value } }) => {
                    setValues({
                      ...values,
                      personType: value,
                    });
                  }}
                >
                  <FormControlLabel
                    value="NOT_POLITICAL_PERSON"
                    className={styles.field__vert_label}
                    label={t('The Legal Entity is not a Politically Exposed Person, nor a family member of a Politically Exposed Person, and The Legal Entity is not known to a Politically Exposed Person.')}
                    control={<Radio color="primary" />}
                  />

                  <FormControlLabel
                    value="POLITICAL_PERSON"
                    className={styles.field__vert_label}
                    label={t('The Legal Entity is a politically exposed person who exercises or has exercised a high-level public office at international, European or national level, or who has a public office below the national level whose political significance is comparable, or has exercised, a family member of such politically exposed Person or person known to be related to such politically exposed person.')}
                    control={<Radio color="primary" />}
                  />

                  <FormControlLabel
                    value="ANOTHER_POLITICAL_PERSON"
                    className={styles.field__vert_label}
                    label={t('The Legal Entity is another politically exposed person, a family member of such a politically exposed person or someone known to such a politically exposed person.')}
                    control={<Radio color="primary" />}
                  />
                </RadioGroup>
              </div>
            </div>

            {values.personType !== 'NOT_POLITICAL_PERSON' && (
              <div className="settings__row flex__row flex__wrap">
                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  label={t("Exact description of the function")}
                  value={values.duties}
                  onChange={({target: {value}}) => setValues({...values, duties: value})}
                />
                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  label={t("Relationship to PEP")}
                  value={values.relationshipToPEP}
                  onChange={({target: {value}}) => setValues({...values, relationshipToPEP: value})}
                />
              </div>
            )}
          </div>

          {
            (window.location.host === 'kyc.cryptoadvisory.li' || window.location.host === 'dev-kyc.cryptoadvisory.li')
            ? (
              <CompanyStep5DisclaimerCaag
                publicSettings={publicSettings}
                prospectusOrIssuing={prospectusOrIssuing}
                checkboxValues={checkboxValues}
                setCheckboxValues={setCheckboxValues}
                setEnableSubmit={setEnableSubmit}
              />
            )
            : (
              <>
                <div style={{display: "none"}}>
                  {publicSettings.token_type === TOKEN_TYPES.NONE && (
                    <CompanyStep5DisclaimerNone
                      publicSettings={publicSettings}
                      prospectusOrIssuing={prospectusOrIssuing}
                      checkboxValues={checkboxValues}
                      setCheckboxValues={setCheckboxValues}
                      setEnableSubmit={setEnableSubmit}
                    />
                  )}

                  {publicSettings.token_type === TOKEN_TYPES.LIQUIDATION && (
                    <CompanyStep5DisclaimerLiquidation
                      publicSettings={publicSettings}
                      prospectusOrIssuing={prospectusOrIssuing}
                      checkboxValues={checkboxValues}
                      setCheckboxValues={setCheckboxValues}
                      setEnableSubmit={setEnableSubmit}
                      values={values}
                    />
                  )}

                  {(
                    publicSettings.token_type === TOKEN_TYPES.EQUITY
                    || publicSettings.token_type === TOKEN_TYPES.BOND
                  ) && (
                    <CompanyStep5DisclaimerEquityBond
                      publicSettings={publicSettings}
                      prospectusOrIssuing={prospectusOrIssuing}
                      prospectusOrIssuingDate={prospectusOrIssuingDate}
                      checkboxValues={checkboxValues}
                      setCheckboxValues={setCheckboxValues}
                      setEnableSubmit={setEnableSubmit}
                    />
                  )}

                  {publicSettings.token_type === TOKEN_TYPES.UTILITY && (
                    <CompanyStep5DisclaimerUtility
                      publicSettings={publicSettings}
                      prospectusOrIssuing={prospectusOrIssuing}
                      checkboxValues={checkboxValues}
                      setCheckboxValues={setCheckboxValues}
                      setEnableSubmit={setEnableSubmit}
                    />
                  )}
                </div>

                <div className="settings__item">
                  <div className="card__head">
                    <div className="title-primary card__title card__title-has_icon">
                      {t('Declarations relevant to due diligence') }
                      {" "}

                      <span
                        data-tip={t('The intentional provision of false information is a punishable offence under the Liechtenstein Criminal Code. Any changes must be notified immediately.')}
                        data-for="info-due-diligence"
                      >
                        <Icon
                          className={classNames('info')}
                          name='info'
                          size="16"
                        />
                      </span>

                      <ReactTooltip id="info-due-diligence" place="bottom" padding="24px" className="popover-tooltip" />
                    </div>
                  </div>

                  <div className="settings__fieldset">
                    <div className="field">
                      <div className="field__wrap">
                        <SwitchCheckboxTheme
                          value="clientStatement"
                          defaultChecked={checkboxValues.clientStatement}
                          onChange={changeCheckbox('clientStatement')}
                          isRemoveLine
                          isGrayText
                          isLeftSwitch
                          switchText={t('We hereby confirm that the company itself is exclusively beneficially entitled to the assets for the acquisition of the tokens and is acting for its own account, in its own economic interest and not on behalf of others.')}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                { isAwearetech &&
                  <>
                    <div className="settings__item">
                      <div className="card__head">
                        <div className="title-primary card__title card__title-has_icon">
                          {t('Declaration of Domicile')}
                        </div>
                      </div>

                      <div className="settings__fieldset">
                        <div className="field">
                          <div className="field__wrap">
                            <SwitchCheckboxTheme
                              value="usExportControl"
                              defaultChecked={checkboxValues.usExportControl}
                              onChange={changeCheckbox('usExportControl')}
                              isRemoveLine
                              isGrayText
                              isLeftSwitch
                              switchText={awearetechDisclaimerText['usExportControl']}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="settings__item">
                      <div className="card__head">
                        <div className="title-primary card__title card__title-has_icon">
                          {t('Applicable Law')}
                        </div>
                      </div>

                      <div className="settings__fieldset">
                        <div className="field">
                          <div className="field__wrap">
                            <SwitchCheckboxTheme
                              value="applicableLaw"
                              defaultChecked={checkboxValues.applicableLaw}
                              onChange={changeCheckbox('applicableLaw')}
                              isRemoveLine
                              isGrayText
                              isLeftSwitch
                              switchText={awearetechDisclaimerText['applicableLaw']}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="settings__item">
                      <div className="card__head">
                        <div className="title-primary card__title card__title-has_icon">
                          {t('Place of Jurisdiction')}
                        </div>
                      </div>

                      <div className="settings__fieldset">
                        <div className="field">
                          <div className="field__wrap">
                            <SwitchCheckboxTheme
                              value="placeOfJurisdiction"
                              defaultChecked={checkboxValues.placeOfJurisdiction}
                              onChange={changeCheckbox('placeOfJurisdiction')}
                              isRemoveLine
                              isGrayText
                              isLeftSwitch
                              switchText={awearetechDisclaimerText['placeOfJurisdiction']}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="settings__item">
                      <div className="card__head">
                        <div className="title-primary card__title card__title-has_icon">
                          {t('Severability')}
                        </div>
                      </div>

                      <div className="settings__fieldset">
                        <div className="field">
                          <div className="field__wrap">
                            <SwitchCheckboxTheme
                              value="severability"
                              defaultChecked={checkboxValues.severability}
                              onChange={changeCheckbox('severability')}
                              isRemoveLine
                              isGrayText
                              isLeftSwitch
                              switchText={awearetechDisclaimerText['severability']}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }
              </>
            )
          }

        </>
        <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
          <button className="button-stroke" onClick={goToPrevStep}>{t('Previous')}</button>
          {loading && (
            <CircularProgress />
          )}
          {!loading && (
            <button className="button" disabled={!enableSubmit} onClick={() => submitForm()}>{t('Submit')}</button>
          )}
        </div>
      </div>
    </div>
  )
}

export default CardForm
