import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { isEmpty } from "lodash";
import { useTranslation } from 'react-i18next';

const PopoverResponse = ({ jsonResponse, domID }) => {
  const { t } = useTranslation();

  if (isEmpty(jsonResponse)) {
    return null
  }

  return (
    <>
      <button
        className="comments__reply"
        data-tip={ JSON.stringify(jsonResponse, null, 2) }
        data-for={domID}
      >
        { t('JSON response') }
      </button>

      <ReactTooltip
        id={domID}
        className="tooltip-custom"
      />
    </>
  )
}

PopoverResponse.propTypes = {
  jsonResponse: PropTypes.object
}

export default PopoverResponse
