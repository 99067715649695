import React, { useEffect, useState } from 'react'
import TransactionList from '../components/TransactionList'
import { TRANSACTIONS_ADMIN } from '../queriesAndMutations'
import TagManager from 'react-gtm-module'
import { usePublicSettings } from '../myHooks/useSettings'
import { useTranslation } from 'react-i18next'

const AdminTransactions = () => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  const [filteredStatus, setFilteredStatus] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (publicSettings.google_tag_manager_id !== null && publicSettings.google_tag_manager_id !== undefined) {
      TagManager.initialize({ gtmId: publicSettings.google_tag_manager_id });
    }
  });

  return (
    <>
      <div className="page__title h3">{ t('Transaction List')}</div>

      <div className="transactions customer card">
        <TransactionList
          isAdmin
          query={TRANSACTIONS_ADMIN}
          filteredStatus={(newStatuses) =>
            setFilteredStatus(newStatuses)
          }
        />
      </div>
    </>
  );
}

export default AdminTransactions
