import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import {
  CUSTOMER_COMPANY_PROOF_OF_RESIDENCE,
  CUSTOMER_COMPANY_PROOF_OF_RESIDENCE_HELPER_TEXT,
} from '../../../constants/customer'
import { ethToFiat, tokenToEth } from '../../../utils/rate'
import { useExchangeRates } from '../../../myHooks'
import { TOKEN_TYPES } from '../../../constants/settings'

import ImageUpload from '../../ImageUpload'
import styles from './styles.module.scss'

const CardForm = ({
  values,
  setValues,
  goToNextStep,
  goToPrevStep,
  publicSettings,
}) => {
  const { data: { exchangeRates } = {} } = useExchangeRates();
  const [currentLevel, setCurrentLevel] = useState(0);
  const baseCurrency = publicSettings.kyc_use_base_currency ? publicSettings.base_currency : 'CHF'

  const getIntendedInvestmentOptions = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return []
    }

    return Object.values(publicSettings.kyc_levels).filter((level) => !level.enable || level.enable.enable_lvl).map((level) => {
      if (level.enable && level.enable.enable_open_end) {
        return `> ${level.min_invest_amount} ${baseCurrency.toUpperCase()}`
      }
      return `${level.min_invest_amount} - ${level.max_invest_amount} ${baseCurrency.toUpperCase()}`
    })
  }

  useEffect(() => {
    if (exchangeRates && publicSettings.kyc_levels) {
      const tokenInBaseCurrency = exchangeRates && ethToFiat(
        baseCurrency,
        tokenToEth(values.amount, exchangeRates),
        exchangeRates
      );

      let lv = Object.values(publicSettings.kyc_levels).filter((item) => {
        return !item.enable || item.enable.enable_lvl;
      }).reduce((all, item) => all + (tokenInBaseCurrency >= item.min_invest_amount ? 1 : 0), 0);

      if (publicSettings.token_type === TOKEN_TYPES.NONE) {
        lv = getIntendedInvestmentOptions().indexOf(values.intended_investment) + 1
      }

      setCurrentLevel(lv);
    }
    // eslint-disable-next-line
  }, [exchangeRates]);

  const maxLv = publicSettings.kyc_levels[5].enable && publicSettings.kyc_levels[5].enable.enable_lvl ? 5 :
    publicSettings.kyc_levels[4].enable && publicSettings.kyc_levels[4].enable.enable_lvl ? 4 : 3;
  const { t } = useTranslation()

  return (
    <div className="card easy-onboarding__company-proofofresidence">
      <div className="settings__wrapper">
        <div className="card__head card__head-kyc-1">
          <div className="title-primary card__title">
            {t('Proof of residence')}
          </div>
        </div>

        <div className="settings__fieldset">
          <div className="field easy-onboarding__proofofresidence">
            <div className="field__label">
              {t('Proof of residence')}
              <span className="text__red"> *</span>
            </div>

            <div className={classNames('field__wrap field__wrap-select', styles.select)}>
              <TextField
                select
                margin="none"
                variant="outlined"
                fullWidth
                value={values.proofOfResidenceType}
                onChange={({ target: { value }}) => { setValues({ ...values, proofOfResidenceType: value }) }}
                helperText={t(CUSTOMER_COMPANY_PROOF_OF_RESIDENCE_HELPER_TEXT[values.proofOfResidenceType]) || ''}
              >
                { Object.entries(CUSTOMER_COMPANY_PROOF_OF_RESIDENCE)
                  .filter(x => (publicSettings.use_registration_confirm_for_proof_of_residence && currentLevel === maxLv ? x[0] === "REGISTRATION_CONFIRMATION" : x[0] !== "REGISTRATION_CONFIRMATION"))
                  .map(type => (
                    <MenuItem value={type[0]} key={type[0]}>
                      {t(type[1])}
                    </MenuItem>
                ))}
              </TextField>
            </div>
          </div>

          <div className="field easy-onboarding__documentofthecorporationmustnotolderthan3months">
            {!values.proofOfResidenceUploadFile && (
              <ImageUpload
                labelText={
                  <span className="text__red">
                    {t('Documents of the corporation must not older than 3 months')}.
                  </span>
                }
                isRequired
                buttonText={t('Click or drop images')}
                buttonClassName="button"
                maxFileSize={10000000}
                withPreview
                singleImagePick
                singleFile
                onChange={(img, imgURI) => {
                  let newImage = {}

                  if (img.length > 0 && imgURI.length > 0) {
                    newImage = {
                      img: img[0],
                      imgURI: imgURI[0],
                    }
                  }

                  setValues({ ...values, proofOfResidenceUploadFile: newImage })
                }}
                accept='image/*, application/pdf'
              >
                <div className="file__wrap_text text__center">
                  <p>
                    { t('Drag and drop the document') }
                  </p>
                </div>
              </ImageUpload>
            )}

            {values.proofOfResidenceUploadFile && (
              <div className={classNames('settings__top', styles.upload__result)}>
                <div className="settings__label">
                  {values.proofOfResidenceUploadFile.img.name}
                </div>

                <button
                  className="button-stroke button-small settings__button"
                  onClick={() => {
                    setValues({ ...values, proofOfResidenceUploadFile: null })
                  }}
                >
                  {t('Remove')}
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
          <button className="button-stroke" onClick={goToPrevStep}>{t('Previous')}</button>
          <button className="button" onClick={goToNextStep}>{t('Next')}</button>
        </div>

      </div>
    </div>
  )
}

export default CardForm
