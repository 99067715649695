import gql from 'graphql-tag';

export const UPDATE_IDNOW_IDENT_TO_PENDING = gql`
  mutation updateIdnowIdentToPending($input: JSON) {
    updateIdnowIdentToPending(input: $input)
  }
`;

export const MANUAL_FETCH_IDNOW_CHECK = gql`
  mutation manualFetchIdnowCheck($identId: ID!) {
    manualFetchIdnowCheck(identId: $identId)
  }
`