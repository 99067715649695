import React from 'react'

import AppBar from '../components/AppBar'
import Footer from '../components/Footer'
import LivenessCheckQrCode from '../components/LivenessCheck/LivenessCheckQrCode'

const InternalLivenessCheckQrCodePage = ({ match }) => {
  const applicantId = match.params.applicantId

  return (
    <div className="page-user">
      <AppBar />

      <div className="page-dashboard__content page-dashboard__content-four">
        <div className="dashboard-application style-3 text-center">
          <h1>Liveness Check</h1>
          <LivenessCheckQrCode applicantUuid={applicantId}/>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default InternalLivenessCheckQrCodePage
