import React from 'react'
import PropTypes from 'prop-types'
import AppHeader from "../components/Header";
import AppSidebar from "../components/Sidebar";
import Transaction from '../components/Transaction'
import { useTranslation } from 'react-i18next';

const AdminTransaction = ({
  match,
  location,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`page ${location.pathname.indexOf("/admin") === 0 ? "page-admin" : ""}`}>
      <AppHeader />
      <AppSidebar />

      <div className="page__inner">
        <div className="page__container">
          <div className="page__title h3">{ t('Transaction detail') }</div>

          <Transaction isAdmin id={match.params.transactionId} />

        </div>
      </div>
    </div>
  )
}

AdminTransaction.propTypes = {
  match: PropTypes.object.isRequired,
}

export default AdminTransaction
