import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { NavLink, withRouter } from "react-router-dom"
import { useMutation } from "@apollo/react-hooks"
import { compose } from "recompose"
import classNames from "classnames"
// import '../styles/legacy/style.scss'
import makeStyles from "@material-ui/core/styles/makeStyles"
import CircularProgress from "@material-ui/core/CircularProgress"
import Icon from "./Icon"
import queryString from "query-string"
import CardInformation from "./InformationCard"
import { extractMsgFromErrByPropertyName } from "../utils"
import { RESTORE_CHECK, CONFIRM_ACTIVE_ADMIN } from "../queriesAndMutations"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center"
  },
  itemBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  linkToLoginPage: {
    width: "100%"
  }
}))

const AdminActivationForm = ({ history }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [restoreCheck, restoreCheckData] = useMutation(RESTORE_CHECK)
  const [confirmActiveAdmin, confirmActiveAdminData] = useMutation(CONFIRM_ACTIVE_ADMIN)
  const [values, setValues] = useState({
    password: "",
    password_confirm: "",
    token: ""
  })

  const query = queryString.parse(history.location.search)
  const token = query && query.token

  useEffect(() => {
    restoreCheck({ variables: { input: { token } } })
  }, [restoreCheck, token])

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value })
  }
  const onClick = () => {
    setValues({ ...values, password_confirm: "" })
    confirmActiveAdmin({ variables: { input: { ...values, token } } })
  }
  const renderInputField = ({
    // eslint-disable-next-line react/prop-types
    propertyName,
    label = "",
    index,
    type,
    icon
  } = {}) => {
    const errorsTexts = extractMsgFromErrByPropertyName(confirmActiveAdminData.error, propertyName)

    return (
      <div className="field field_icon" key={index}>
        <div className="field__wrap">
          <input type={type} placeholder={label} className="field__input" value={values[propertyName] || ""} onChange={handleChange(propertyName)} />

          {icon && (
            <div className="field__icon">
              <Icon className={icon} name={icon} size="24" />{" "}
            </div>
          )}
        </div>

        {errorsTexts && <div className="field__helper">{(errorsTexts && errorsTexts.join(". ")) || ""}</div>}
      </div>
    )
  }

  if (confirmActiveAdminData.data) {
    return (
      <>
        <div className="h2 entry__title">{t("Admin Activation")}</div>

        <div className="entry__fieldset">
          <CardInformation color="card__information-green" title={t("Information")}>
            <>
              {t("Your password has been successfully changed.")}
              <div className="space space__32"> </div>
              <NavLink to="/login" className={classNames("button-stroke button-stroke__white", classes.linkToLoginPage)}>
                {t("Sign in")}
              </NavLink>
            </>
          </CardInformation>
        </div>
      </>
    )
  }

  if (restoreCheckData.data) {
    return (
      <>
        <div className="h2 entry__title">{t("Create new password")}</div>

        <div className="entry__fieldset">
          {renderInputField({
            propertyName: "password",
            label: "Password",
            type: "password",
            icon: "lock"
          })}
          {renderInputField({
            propertyName: "password_confirm",
            label: "Repeat Password",
            type: "password",
            icon: "lock"
          })}
          {confirmActiveAdminData.loading ? (
            <div className={classes.circularProgressWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <button type="button" onClick={onClick} className="button entry__button">
              {t("Create Password")}
            </button>
          )}
        </div>
      </>
    )
  }

  return (
    <>
      <div className="h2 entry__title">{t("Reset password")}</div>
      {restoreCheckData.loading ? (
        <div className={classes.circularProgressWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <div className="entry__fieldset">
          <CardInformation color="card__information-red" title={t("Warning")}>
            <>
              {t("Invalid password recovery link. Go to the password to recovery page")} <div className="space space__32"> </div>
              <NavLink to="/password/restore" className="button-stroke button-stroke__white">
                {t("Restore password")}
              </NavLink>
            </>
          </CardInformation>
        </div>
      )}
    </>
  )
}

AdminActivationForm.propTypes = {
  // from HOCs
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

export default compose(withRouter)(AdminActivationForm)
