import React from 'react'
import classNames from 'classnames'

const ShortenText = ({
  text,
  v2,
}) => {
  return (
    <div className="shorten__text-wrap">
      <div className={classNames('shorten__text', v2 ? 'v2' : '')}>
        {text}
      </div>
      <div className="full__text">
        {' '}
        {text}
      </div>
    </div>
  )
}

export default ShortenText
