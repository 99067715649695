import React from 'react'
import classNames from 'classnames'
import Icon from '../Icon'

const ModalInnerMaterialDialog = ({
  // eslint-disable-next-line react/prop-types
  modalSize,
  modalTitleClassColor,
  modalTitle,
  onClickClose,
  children
}) => {
  return (
    <div className={`popup js-popup animation visible ${modalSize}`}>
      <div className="popup__overlay js-popup-overlay" onClick={onClickClose}>{' '}</div>

      <div className="popup__wrap js-popup-wrap">
        <div className="description">
          {modalTitle && (
            <div className={classNames(modalTitleClassColor, 'description__title')}>
              {modalTitle}
            </div>
          )}

          {children}

        </div>

        {onClickClose && (
          <button
            className="popup__close js-popup-close"
            onClick={onClickClose}
          >
            <Icon
              className={classNames('close', 'icon-24')}
              name="close"
              size="24"
            />
          </button>
        )}
      </div>
    </div>
  );
}

export default ModalInnerMaterialDialog;
