// eslint-disable-next-line import/prefer-default-export
export const TRANSACTIONS_STATUS_TYPES = {
  REJECTED: 'REJECTED',
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  APPROVED: 'APPROVED',
  CONTRACTED: 'CONTRACTED',
}

export const TRANSACTIONS_TYPES = {
  REDEMPTION: 'REDEMPTION',
  PURCHASE: 'PURCHASE',
  INTEREST_PAYMENT: 'INTEREST PAYMENT',
}

export const PAYMENT_TYPES = {
  BANK_TRANSFER: 'BANK_TRANSFER',
  PAYPAL: 'PAYPAL',
  REDEMPTION: 'REDEMPTION',
  CRYPTO: 'CRYPTO',
  WALLET: 'WALLET',
  VOLT: 'VOLT',
  SENDWYRE: 'SENDWYRE',
  CRYPTODOTCOM: 'CRYPTO.COM',
  STRIPE: 'STRIPE',
}

export const SENDWYRE_PAYMENT_TYPES = {
  WALLET: 'Wallet',
  DIRECT_PURCHASE: 'Direct Purchase',
  NFT_SMART_ONRAMPS: 'NFT Smart Onramps',
}

export const NETWORK = {
  eth: 'Ethereum',
  usdc: 'Ethereum',
  usdt: 'Ethereum',
  polygon: 'Polygon',
  dai: 'Ethereum',
  btc: 'Bitcoin',
  xdc: 'XinFin',
}
