import React, { useState } from 'react'
import { usePublicSettings } from '../../../myHooks/useSettings'
import { getFileUrl} from '../../../utils'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import HeaderTranslation from '../../../components/PageEasyOnboarding/HeaderTranslation'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

const LoginHead = () => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { t } = useTranslation();
  const [popOverValues, setPopOverValues] = useState({
    isPopoverActive: false,
  });
  const onPopoverActive = () => {
    setPopOverValues({...popOverValues, isPopoverActive: !popOverValues.isPopoverActive});
  }
  const onPopoverAwayActive = () => {
    setPopOverValues({...popOverValues, isPopoverActive: false});
  }

  return (
    <div className="login__head login__head-kyc">
      <NavLink to="/" className="login__logo entry__logo-kyc login__logo_2 desktop-hide">
        <img
          src={publicSettings && getFileUrl(publicSettings.logo_path)}
          alt="logo"
        />
      </NavLink>

      <div className="login__info d__flex flex__row flex__nowrap flex__justify_end flex__align_center">
        <ClickAwayListener onClickAway={onPopoverAwayActive}>
          <div className="login__info-rwd">
            <div className={`actions actions__kyc-2 ${popOverValues.isPopoverActive ? "active" : ""}`}>
              <button className="actions__button header__burger" onClick={onPopoverActive}>{' '}</button>

              <div className="actions__body">
                <div className="actions__option actions__option_normal d__block">
                  <NavLink to="/">
                    { t('Back to dashboard')}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </ClickAwayListener>
        <div className="login__info-item login__info-item-desk">
          <NavLink to="/">
            { t('Back to dashboard')}
          </NavLink>
        </div>

        <div className="login__info-item">
          <HeaderTranslation />
        </div>
      </div>
    </div>
  )
}

export default LoginHead;
