import React, {useState} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import DialogExportTxtFile from './DialogExportTxtFile'

const ButtonExportTxtFile = ({ children, mutation, nameDownloadFile, buttonClass }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => () => {
    setDialogOpen(true)
  }
  const closeDialog = () => () => {
    setDialogOpen(false)
  }

  return (
    <>
      {isDialogOpen && (
        <DialogExportTxtFile
          mutation={mutation}
          isDialogOpen={isDialogOpen}
          openDialog={openDialog()}
          closeDialog={closeDialog()}
          nameDownloadFile={nameDownloadFile}
        />
      )}
      <button
        onClick={openDialog()}
        className={classNames('button-stroke', buttonClass)}
      >
        { children }
      </button>
    </>
  )
}

ButtonExportTxtFile.propTypes = {
  children: PropTypes.any.isRequired,
  nameDownloadFile: PropTypes.string.isRequired,
}

export default ButtonExportTxtFile
