import React from "react";
import ClassNames from "classnames";
import Icon from "./Icon";
// import Tooltip from "./Tooltip";

const CustomInput = ({
  // eslint-disable-next-line react/prop-types
  propertyName,
  classWrapper,
  classLabel,
  classInput,
  icon,
  label = "",
  type = "text",
  name = "",
  placeholder,
  isRequired,
  helperText,
  state,
  setState,
  disabled,
  error = [],
  loading = false,
  formatData,
} = {}) => {
  const handleChange = (event) => {
    let newValue = event.target.value

    if (formatData) {
      newValue = formatData(newValue)
    }

    setState({ ...state, [propertyName]: newValue });
  };

  return (
    <div className={ClassNames(
      classWrapper,
      { 'field_icon': icon}
      )}
      key={propertyName}
    >
      {label && (
        <div className={ClassNames(
          classLabel
        )}>
          {label}{" "}
          {isRequired && (
            <span className="text__red"> *</span>
          )}
        </div>
      )}

      <div className="field__wrap">
        {type === 'textarea' ? (
          <textarea
            className={ClassNames(classInput)}
            value={state[propertyName] || ""}
            onChange={handleChange}
            name={name}
            placeholder={placeholder}
            disabled={disabled || loading}
            rows={4}
          />
        ) : (
          <input
            className={ClassNames(classInput)}
            value={state[propertyName] || ""}
            onChange={handleChange}
            type={type || "text"}
            name={name}
            placeholder={placeholder}
            disabled={disabled || loading}
            autoComplete="off"
          />
        )}

        {icon && (
          <div className="field__icon">
            <Icon className={icon} name={icon} size="24" />{" "}
          </div>
        )}
      </div>

      {helperText && (
        <div className="field__helper">
          {helperText}
        </div>
      )}
    </div>
  );
};

export { CustomInput as default };
