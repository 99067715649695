import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
// import "../styles/legacy/style.scss";
import Dialog from '@material-ui/core/Dialog'
import WalletAddress from './profile/WalletAddress'
import { useTranslation } from 'react-i18next'
import ModalInnerMaterialDialog from './ModalInnerMaterialDialog'

const useStyles = makeStyles(() => ({
  editWallet: {
    color: "#1c65c9",
    margin: 0,
    cursor: "pointer",
  },
  dialog: {
    "& .MuiDialog-paper": {
      padding: "10px 0 26px 0",
    },
  },
  paper: {
    minWidth: "200px",
  },
}));

const ReceiveWallet = ({
  userData,
  publicSettings,
  dialogOpen,
  onOpenDialog,
  onCloseDialog,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const shortFormatEthAddress = (ethAddress) =>
    `${ethAddress.slice(0, 8)}....${ethAddress.slice(
      ethAddress.length - 8,
      ethAddress.length
    )}`;

  const walletAddress =
    userData && (userData.wallet_address || userData.eth_receiving_wallet);

  return (
    <div className="card">
      <div className="card__head">
        <div className="title-primary card__title">{ t('Receiving address') }</div>
      </div>

      <div className="popular">
        <div className="popular__list">
          {walletAddress ? (
            <div className="popular__item popular__item-normal">
              <div className="popular__title">
                {shortFormatEthAddress(walletAddress)}
              </div>
            </div>
          ) : (
            <div className="popular__item popular__item-normal pt__0">
              <div className="tips__action">{ t('You don\'t have any wallet yet') }</div>
            </div>
          )}
        </div>
        {walletAddress ? (
          <button
            onClick={onOpenDialog}
            className="button-stroke popular__button"
          >
            { t('Edit') }
          </button>
        ) : (
          <button
            onClick={onOpenDialog}
            className="button-stroke popular__button"
          >
            { t('Add') }
          </button>
        )}
      </div>

      <Dialog
        className={classes.dialog}
        open={dialogOpen}
        onClose={onCloseDialog}
      >
        <ModalInnerMaterialDialog
          modalSize="popup__kyc-medium"
          onClickClose={onCloseDialog}
          modalTitleClassColor="title-primary"
          modalTitle={ t('Wallet Address') }
        >
          <div className="description__row flex__row flex__wrap">
            <WalletAddress />
          </div>

        </ModalInnerMaterialDialog>
      </Dialog>
    </div>
  );
};

ReceiveWallet.propTypes = {
  dialogOpen: PropTypes.bool,
  userData: PropTypes.object.isRequired,
  publicSettings: PropTypes.object,
  onOpenDialog: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
};

export default ReceiveWallet;
