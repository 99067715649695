import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from "react-outside-click-handler"
import {
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import AdminKycFileViewer from './AdminKycFileViewer';

const useStyles = makeStyles({
  cardHeader: {
    fontSize: '1rem'
  },
  cardSubheader: {
    fontSize: '0.8rem'
  },
  resourceItem: {
    marginTop: '1em',
  },
})

const AdminKycInternalLivenessCheckViewer = ({
  applicant,
  collapsed,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [collapse, setCollapse] = useState(collapsed || false)

  const livenessCheckViewerNav = ['Document', 'Video'];
  const [activeLivenessCheckViewerTab, setActiveLivenessCheckViewerTab] = useState(livenessCheckViewerNav[0])

  const [visibleActiveLivenessCheckViewerRWD, setVisibleActiveLivenessCheckViewerRWD] = useState(false)
  const handleLivenessCheckViewerRWDClick = (x) => {
    setActiveLivenessCheckViewerTab(x)
    setVisibleActiveLivenessCheckViewerRWD(false)
  }

  return (
    <>
      <div className="card">
        <div className="card__head">
          <div className="title-clear card__title">
            [
            {applicant.status}
            ]
            {t('Applicant')}
            {' '}
            {applicant.uuid}
          </div>
        </div>

        <div className="packages__content nospace__bottom">
          <span>
            {moment(applicant.created_at).tz('Europe/Vaduz').format('MMM DD, YYYY, HH:mm z')}
          </span>
          <div className="space space__16">{' '}</div>

          <div className="card__nav tablet-hide">
            {livenessCheckViewerNav.map((x, index) => (
              <button
                key={index}
                className={`card__link ${x === activeLivenessCheckViewerTab ? "active" : ""}`}
                onClick={() => setActiveLivenessCheckViewerTab(x)}
              >
                {t(x)}
              </button>
            ))}
          </div>

          <div className={`custom-dropdown popup__card-nav__mobile tablet-show ${visibleActiveLivenessCheckViewerRWD ? "dropdown__active" : ""}`}>
            <OutsideClickHandler onOutsideClick={() => setVisibleActiveLivenessCheckViewerRWD(false)}>
              <div className="custom-dropdown__wrap">
                <div
                  className="select select__small custom-dropdown__head"
                  onClick={() => setVisibleActiveLivenessCheckViewerRWD(!visibleActiveLivenessCheckViewerRWD)}
                >
                  <div className="dropdown__selection current">
                    {activeLivenessCheckViewerTab}
                  </div>

                  <div className="dropdown__body list">
                    {livenessCheckViewerNav.map((x, index) => (
                      <div
                        key={index}
                        className={`dropdown__option option ${x === activeLivenessCheckViewerTab ? "selected" : ""}`}
                        onClick={() => handleLivenessCheckViewerRWDClick(x)}
                      >
                        {t(x)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </OutsideClickHandler>
          </div>

          <div className="space space__24">{' '}</div>
          <div className="space space__24">{' '}</div>
          <div className="card__content">
            {activeLivenessCheckViewerTab === livenessCheckViewerNav[0] && (
              <>
                {!collapse && applicant.status !== 'NOT_STARTED' && (
                  <>
                    <strong>{t('Document')}</strong>
                    {applicant.livenessCheckDocument && applicant.livenessCheckDocument.map((doc) => (
                      <div key={doc.id} className={classes.resourceItem}>
                        <AdminKycFileViewer file={doc}/>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}

            {activeLivenessCheckViewerTab === livenessCheckViewerNav[0] && (
              <>
                {!collapse && applicant.status !== 'NOT_STARTED' && (
                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={7}>
                        <strong>{t('Video')}</strong>
                        {applicant.livenessCheckVideo && applicant.livenessCheckVideo.map((video) => (
                          <div key={video.id} className={classes.resourceItem}>
                            {video.challenge && (
                              <div>
                                {t('Challenge')}:
                                <ol>
                                  {video.challenge.map(challenge => (
                                    <li key={challenge.action}>
                                      {((challenge) => {
                                        switch (challenge.action) {
                                          case 'turn':
                                            return `Turn: ${challenge.arguments.direction}`
                                          case 'speak':
                                            return `Speak: ${challenge.arguments.number.join(' - ')}`
                                          default:
                                            return 'Undefined'
                                        }
                                      })(challenge)}
                                    </li>
                                  ))}
                                </ol>
                              </div>
                            )}
                            <AdminKycFileViewer file={video}/>
                          </div>
                        ))}
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

AdminKycInternalLivenessCheckViewer.propTypes = {
  applicant: PropTypes.object.isRequired,
  collapsed: PropTypes.bool,
}

export default AdminKycInternalLivenessCheckViewer
