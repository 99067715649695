import React, { useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import JoyRide from 'react-joyride'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'
import { usePublicSettings, useMe } from '../../myHooks'
import { USER_KYC_STATUS_TYPES } from '../../constants/user'
import LoginHead from './LoginHead'
import Icon from '../../components/Icon'
import styles from './styles.module.scss'

const EasyOnBoardingOptions = () => {
  const { t } = useTranslation()
  const history = useHistory();
  const {
    data: { me: userData } = {},
    loading,
  } = useMe()
  const { data: { publicSettings = {} } = {} } = usePublicSettings()

  const tourBoardingOptions = [
    {
      target: '.easy-onboarding__landing .easy-onboarding__individual',
      content: t('This is where you apply as individual'),
      disableBeacon: true,
      hideCloseButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
      }
    },
    {
      target: '.easy-onboarding__landing .easy-onboarding__company',
      content: t('This is where you apply as company'),
      disableBeacon: true,
      hideCloseButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
      }
    }
  ]

  useEffect(() => {
    if (history && publicSettings && !publicSettings.kyc_enable_kyb) {
      history.push('/easy-onboarding')
    }
  }, [publicSettings, history])

  if (!publicSettings) {
    return (
      <CircularProgress />
    )
  }

  return (
    <>
      <LoginHead />

      <div className="login__wrapper entry__wrapper-kyc">
        <div className="h2 entry__title">
          { t('KYC Application') }
        </div>

        <div className={classNames('entry__top entry__top-clear easy-onboarding__landing', styles.entry__top_onboarding)}>
          <div className="entry__text">{t('Start your KYC process, it only takes up to 5 minutes.')}</div>

          <div className={classNames('entry__btns', styles.entry__btns_onboarding)}>
            <NavLink
              className={classNames('button-stroke entry__button easy-onboarding__individual', styles.entry__button_onboarding)}
              to="/easy-onboarding"
            >
              <Icon className="user" name="user" size="24" />{" "}
              <span>
                {t('Continue as an individual')}
              </span>
            </NavLink>

            {publicSettings.kyc_enable_kyb && (
              <NavLink
                className={classNames('button-stroke entry__button easy-onboarding__company', styles.entry__button_onboarding)}
                to="/easy-onboarding-company"
              >
                <Icon className="multiselect" name="multiselect" size="24" />{" "}
                <div className="span">
                  {t('Continue as an entity')}
                </div>
              </NavLink>
            )}
          </div>
        </div>
      </div>

      {userData.rights === 'COMMON' && (
        <>
          {userData.kyc_status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED && (
            publicSettings.enable_product_tour && (
              <JoyRide steps={tourBoardingOptions} />
            )
          )}
        </>
      )}
    </>
  )
}

export default EasyOnBoardingOptions
