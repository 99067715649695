import gql from "graphql-tag";

export const GENERATE_INTERNAL_LIVENESS_CHECK_REMOTE_LINK = gql`
  mutation generateInternalLivenessCheckRemoteLink($applicant_uuid: String!) {
    generateInternalLivenessCheckRemoteLink(applicant_uuid: $applicant_uuid)
  }
`;

export const SUBMIT_LIVENESS_CHECK_RESOURCES = gql`
  mutation submitLivenessCheckResources($input: LivenessCheckResourceInput!) {
    submitLivenessCheckResources(input: $input)
  }
`;
