import gql from "graphql-tag";

export const VOLT_GET_SUPPORTED_COUNTRIES = gql`
  mutation voltGetSupportedCountries {
    voltGetSupportedCountries
  }
`;

export const VOLT_GET_SUPPORTED_BANKS = gql`
  mutation voltGetSupportedBanks {
    voltGetSupportedBanks
  }
`;

export const VOLT_PAYMENT_INIT_REQUEST = gql`
  mutation voltPaymentInitRequest($input: VoltPaymentInput) {
    voltPaymentInitRequest(input: $input)
  }
`;

export const VOLT_GET_PAYMENT_DETAILS = gql`
  mutation voltGetPaymentDetails($input: VoltPaymentDetailsInput) {
    voltGetPaymentDetails(input: $input)
  }
`;