import React from 'react'
import { use100vh } from 'react-div-100vh'
import LoginRegisterLogo from '../components/LogoLoginRegister'
import AuthTokenComponent from '../components/AuthTokenComponent'
import { useTranslation } from 'react-i18next'
import { isIE } from "react-device-detect"
import NotSupportViewer from '../components/NotSupportViewer'

const AuthToken = () => {
  const { t } = useTranslation();
  const heightWindow = use100vh();

  if (isIE) {
    return <NotSupportViewer />;
  }

  return (
    <>
      <div className="entry entry_loginregister" style={{ minHeight: heightWindow }}>
        <div className="entry__wrapper entry__wrapper-kyc">
          <LoginRegisterLogo />

          <div className="h2 entry__title">
            {t('Auth Token')}
          </div>

          <div className="entry__fieldset">
            <AuthTokenComponent />
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthToken;
