import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { usePublicSettings } from "../myHooks/useSettings";
import { useExchangeRates } from "../myHooks";
import { getFileUrl } from "../utils";
import { tokenToEth } from "../utils/rate";
import { ME_INVEST_IN_BASE_CURRENCY } from "../queriesAndMutations";
import { useTranslation } from 'react-i18next';

const TokenBalanceContribute = () => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { t } = useTranslation();
  const {
    data: { meInvestInBaseCurrency = {} } = {},
    loading: loadingMeInvestInBaseCurrency,
  } = useQuery(ME_INVEST_IN_BASE_CURRENCY);

  const { data: { exchangeRates } = {}, loading: loadingExchangeRates } =
    useExchangeRates();

  const tokenBalanceMock = {
    value: !loadingMeInvestInBaseCurrency
      ? meInvestInBaseCurrency.approved_invest
      : "0",
    currency: "TWZ",
    contribution: [
      {
        value:
          !loadingExchangeRates && !loadingMeInvestInBaseCurrency
            ? tokenToEth(meInvestInBaseCurrency.approved_invest, exchangeRates)
            : "~",
        currency: "ETH",
      },
    ],
  };

  const renderImageOrSymbol = () => {
    if (publicSettings && publicSettings.brief_logo_path === null) {
      return (
        <img
          src={
            publicSettings && getFileUrl(publicSettings.brief_logo_path)
          }
          alt="Logo"
        />
      );
    } else {
      return (
        <small>
          {publicSettings && publicSettings.token_symbol}
        </small>
      );
    }
  }

  return (
    <>
      <div className="card">
        <div className="card__head">
          <div className="title-primary card__title">{ t('Token Balance') }</div>
        </div>

        <div className="review">
          <div className="review__list">
            <div className="review__item review__item-tokenbalance item-content__middle">
              <div className="review__avatar">
                {renderImageOrSymbol()}
              </div>

              <div className="review__details">
                <div className="review__line">
                  <span className="review__title">
                    {tokenBalanceMock.value}
                  </span>

                  <div className="review__login">
                    ({publicSettings && publicSettings.token_symbol})
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card__head">
          <div className="title-primary card__title">{ t('My Contribution') }</div>
        </div>

        <div className="review">
          <div className="review__list">
            {meInvestInBaseCurrency &&
              meInvestInBaseCurrency.currencies_contribution &&
              meInvestInBaseCurrency.currencies_contribution.map((v) => (

                <div key={v.currency} className="review__item review__item-tokenbalance item-content__middle">
                  <div className="review__avatar">
                    <small>{v.currency}</small>
                  </div>

                  <div className="review__details">
                    <div className="review__line">
                      <div className="review__title">{v.sum}</div>
                      <div className="review__login">{v.currency}</div>
                    </div>
                  </div>
                </div>
            ))}

          </div>
        </div>
      </div>

      <div className="tokenattribute-card__inner this-hidden-area">
        <div className="token-balance__card">
          <span className="token-balance__card-title">{ t('Token Balance') }</span>

          <div className="token-balance__card-content">
            <div className="token-balance__card-img">
              {renderImageOrSymbol()}
            </div>

            <div className="token-balance__card-desc">
              <span className="big">
                <span className="lead">{tokenBalanceMock.value} </span>
              </span>
              <p>({publicSettings && publicSettings.token_symbol})</p>
            </div>
          </div>
        </div>

        <div className="token-balance__card">
          <span className="token-balance__card-title">{ t('My Contribution') }</span>

          {meInvestInBaseCurrency &&
            meInvestInBaseCurrency.currencies_contribution &&
            meInvestInBaseCurrency.currencies_contribution.map((v) => (
              <div key={v.currency} className="token-balance__card-content">
                <div className="token-balance__card-symbol">
                  <span className="lead">{v.currency}</span>
                </div>

                <div className="token-balance__card-desc">
                  <span className="big">
                    <span className="lead">{v.sum} </span>
                  </span>
                  <p>({v.currency.toUpperCase()})</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default TokenBalanceContribute;
