import React from 'react'
import { NavLink } from 'react-router-dom'
import { TOKEN_TYPES } from '../constants/settings'
import Link from '@material-ui/core/Link'
import ReactTooltip from 'react-tooltip'
import { extractMsgFromErrByPropertyName } from '../utils'
import InputThemeLite from './InputThemeLite'
import RadioTheme from './Radio'
import SwitchCheckboxTheme from './SwitchCheckbox'
import { useTranslation, Trans } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Icon from './Icon'
import classNames from 'classnames'
import CardInformation from './InformationCard'

const useStyles = makeStyles(() => ({
  formControl: {
    marginBottom: "30px",
    "& label": {
      margin: "0 0 10px 0",
      "&>.MuiTypography-body1": {
        fontSize: "14px",
        color: "rgba(0, 0, 0, 0.65)",
      },
    },
  },
  confirmParagraph: {
    paddingLeft: "20px",
    margin: "0 0 0 30px",
    listStyleType: "square",
    "& li:last-child": {
      paddingBottom: "0",
    },
    "& li": {
      padding: "0 0 25px 0",
    },
    "&>li": {
      listStyleType: "square",
      paddingBottom: "25px",
      "&>ul": {
        padding: "20px 0 0 20px",
        margin: "0 0 0 30px",
        listStyleType: "circle",
        "&>li": {
          listStyleType: "circle",
        },
      },
    },
  },
  declarationWarning: {
    border: "1px solid",
    padding: "10px",
    margin: " 20px 0 50px 30px",
  },
  note: {
    color: "#758698",
    fontSize: "12px",
  },
}));

const KYC_USER_TYPE_ENUM = {
  notPoliticalPerson: {
    value: 'NOT_POLITICAL_PERSON',
    label: 'notPoliticalPerson'
  },
  politicalPerson: {
    value: 'POLITICAL_PERSON',
    label: 'politicalPerson',
  },
  anotherPoliticalPerson: {
    value: 'ANOTHER_POLITICAL_PERSON',
    label: 'anotherPoliticalPerson',
  },
};

const KYC_CALIDRIS_USER_TYPE_ENUM = {
  notPoliticalPerson: {
    value: 'NOT_POLITICAL_PERSON',
    label: 'notPoliticalPerson'
  },
  'Are you a PEP or have you held one of the following important public office positions': {
    value: 'Are you a PEP or have you held one of the following important public office positions',
    label: 'Are you a PEP or have you held one of the following important public office positions',
  },
  'Are you an immediate family member': {
    value: 'Are you an immediate family member',
    label: 'Are you an immediate family member',
  },
  'Are you known to be a joint beneficial owner of legal entities': {
    value: 'Are you known to be a joint beneficial owner of legal entities',
    label: 'Are you known to be a joint beneficial owner of legal entities',
  },
};

const KycVerificationFormDisclaimer = ({
  publicSettings,
  kycType,
  textConfig,
  checkboxValues,
  onChangeCheckbox,
  setCheckboxValues,
  loading,
  values,
  addressValues,
  setValues,
  customer,
  prospectusOrIssuingDate,
  prospectusOrIssuing,
  paperLink,
  handleChange,
  error,
  onSend,
}) => {
  const classes = useStyles();
  const currentHost = window.location.host;
  const isCalidrisfintech = currentHost === 'kyc.calidrisfintech.com' || currentHost === 'dev-kyc.calidrisfintech.com' || currentHost === 'localhost:3000';
  const isAwearetech = currentHost === 'kyc.awearetech.li' || currentHost === 'aweare-dev.investhub.io' || currentHost === 'localhost:3000';

  const awearetechDisclaimerText = {
    usExportControl:
    `Due to United States of America export control and SEC regulations, this offering is not available for those with jurisdiction and/or domicile in the United States, Iran, Russia, North Korea, Belarus, Syria, and any territories thereof. If any prospective investor with jurisdiction and/or domicile in these nations or territories make an incorrect or false answer regarding jurisdiction and/or domicile outside of these nations or territories, that prospective investor understands that making incorrect/false statements may be considered felony offences including but not limited to perjury, wire fraud, and making false statements, depending on their specific local laws and circumstances.
    <br><br>
    The undersigned hereby declares that they are a bona fide resident of the country of ${addressValues.countryOfResidence} and reside in and maintain at least one place of abode in the Domiciled Country which they recognize and intend to maintain as their permanent home. If maintaining one or more other places of abode outside of the Domiciled Country, the undersigned also declares that the Domiciled Country constitutes their predominant and principal home.
    <br><br>
    The undersigned affirms and attests to the fact that they are not domiciled in the United States of America, Russia, Iran, North Korea, Belarus, Syria, or any territories thereof.
    <br><br>
    The undersigned hereby declares that they will inform AWEARE Global AG administration through electronic means: admin@awearetech.li if at any time they intend to change their Domiciled Country to the United States, Russia, Iran, North Korea, Belarus, Syria, or any territories thereof for the express purpose of reviewing held securities as subject to repurchase.
    <br><br>
    I further certify that I understand there is a penalty for perjury; and that perjury is a felony and is punishable by fines and/or incarceration in accordance with national and/or state laws.`,
    applicableLaw: `The offer and the agreement for the purchase of AWEARE Global Tokens as well as all rights and obligations arising in connection with or from the offer and the agreement for the purchase of AWEARE Global Tokens shall be governed exclusively by Liechtenstein law, excluding the conflict of law rules and referral provisions.`,
    placeOfJurisdiction: `The courts of the Principality of Liechtenstein shall have exclusive jurisdiction for all disputes arising out of or in connection with the offer or the agreement for the purchase of AWEARE Global Tokens, including those concerning the valid conclusion, legal validity, amendment or cancellation.`,
    severability: `If at any time one or more of the provisions of the AWEARE Global Token Offer and the agreement for its purchase is or becomes unlawful, invalid, illegal or unenforceable in any respect, the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired thereby.`,
  }

  const renderInputField = ({
    // eslint-disable-next-line react/prop-types
    propertyName,
    label = "",
    type = "text",
    isRequired,
    helperText,
    state,
    setState,
    disabled = false,
    setter = null,
    labelPostfix = null,
    classWrapper = "",
    options = [],
  } = {}) => {
    const errorsTexts = extractMsgFromErrByPropertyName(error, propertyName);

    let input = null;

    switch (type) {
      default:
        input = (
          <InputThemeLite
            value={state[propertyName] || ""}
            onChange={setter || handleChange(propertyName, state, setState)}
            type={type}
            helperText={
              helperText || (errorsTexts && errorsTexts.join(". ")) || ""
            }
            disabled={disabled || loading}
          />
        );
    }

    return (
      <div className="field">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <div className="field__label" key={`label-${propertyName}`}>
          {label}
          {isRequired && <span className="text__red"> *</span>}
          {labelPostfix}
        </div>
        <div className={classNames('field__wrap', classWrapper)} key={propertyName}>
          {input}
        </div>
      </div>
    );
  };
  const { t } = useTranslation();

  const renderPotiticallyExposedPersons = () => (
    <div className="settings__item">
      <div className="title-primary settings__title">{t('Politically Exposed Persons')}:</div>

      <div className="settings__fieldset">
        <div className="field">
          <div className="field__wrap d__flex flex__row flex__wrap field__wrap-radio-500 field__wrap-radio-cleartop">
              {Object.values(isCalidrisfintech ? KYC_CALIDRIS_USER_TYPE_ENUM : KYC_USER_TYPE_ENUM).map(
                (item, index) =>
                  <RadioTheme
                    key={index}
                    keyIndex={index}
                    isGrayText
                    isRadioGroupVertical
                    value={item.value}
                    onChange={handleChange("personType", values, setValues)}
                    nameGroup="personType"
                    defaultChecked={item.value === values.personType}
                    radioText={
                      kycType === "INDIVIDUAL"
                        ? t(item.label) || ""
                        : t(item.label || "")
                            .replaceAll("I am", "The Legal Entity is")
                            .replaceAll("I", "we")
                    }
                  />
                )
              }
          </div>
        </div>

        {values.personType !==
          KYC_USER_TYPE_ENUM.notPoliticalPerson.value && (
          <>
            <div className="settings__row flex__row flex__wrap">
              {renderInputField({
                propertyName: "duties",
                type: "text",
                label: t('Exact description of the function'),
                isRequired: true,
                state: values,
                setState: setValues,
              })}
              {renderInputField({
                propertyName: "relationshipToPEP",
                type: "text",
                label: t('Relationship to PEP'),
                isRequired: true,
                state: values,
                setState: setValues,
              })}
            </div>
          </>
        )}
      </div>
    </div>
  )
  const renderUtilityDisclaimer = () => {
    return (
      <>
        {publicSettings.kyc_client_statement && (
          <div className="settings__item">
            <div className="title-primary settings__title">
              { t('Declarations relevant to due diligence') }
              {" "}
              <span
                data-tip={t('The intentional provision of false information is a punishable offence under the Liechtenstein Criminal Code. Any changes must be notified immediately.')}
                data-for="info-due-diligence"
              >
                <Icon
                  className={classNames('info')}
                  name='info'
                  size="16"
                />
              </span>
              <ReactTooltip id="info-due-diligence" place="bottom" padding="24px" className="popover-tooltip" />
            </div>

            <div className="settings__fieldset">
              <SwitchCheckboxTheme
                isGrayText
                isLeftSwitch
                isRemoveLine
                onChange={onChangeCheckbox(
                  "clientStatement",
                  checkboxValues,
                  setCheckboxValues
                )}
                defaultChecked={checkboxValues.clientStatement}
                value="IActOnMyOwnAccount"
                switchText={t('I hereby confirm that I myself am exclusively beneficially entitled to the assets for the acquisition of the tokens and that I am acting for my own account, in my own economic interest and not on behalf of others.')}
              />
            </div>
          </div>
        )}

        <div className="settings__item" style={{ display: "none" }}>
          <div className="title-red settings__title">
            { t('Declaration of Consent') }
          </div>

          <div className="settings__fieldset">
            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              onChange={onChangeCheckbox(
                "declarationOfConsent5",
                checkboxValues,
                setCheckboxValues
              )}
              defaultChecked={checkboxValues.declarationOfConsent5}
              value="declarationOfConsent5"
              switchText={(
                <>
                  { t('Under the terms of the Company\'s Privacy Policy,') }{" "}
                  { t('I hereby Consent to my personal data being processed by the Company and its affiliated companies or agents (such as Banks, Tax Advisor, Auditors and Compliance services)') }
                </>
              )}
            />

            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              onChange={onChangeCheckbox(
                "declarationOfConsent6",
                checkboxValues,
                setCheckboxValues
              )}
              defaultChecked={checkboxValues.declarationOfConsent6}
              value="declarationOfConsent6"
              switchText={(
                <Trans
                  i18nKey="I hereby declare that I have been provided with the Privacy Policy of the Seller and that I have been informed about how my data will be processed and My Data Protection Rights"
                  t={t}
                  components={{policyLink: <Link href="/privacy-policy" target="_blank" rel="noopener noreferrer" />}}
                />
              )}
            />

          </div>
        </div>
      </>
    );
  }
  const renderNoneDisclaimer = () => {
    return (
      <>
        {publicSettings.kyc_acknowledgement_of_receipt && (
          <div className="settings__item" style={{ display: "none" }}>
            <div className="title-red settings__title">{t('Acknowledgement of Receipt')}</div>

            <div className="settings__fieldset">
              <SwitchCheckboxTheme
                defaultChecked={checkboxValues.IActOnMyOwnAccount}
                onChange={onChangeCheckbox(
                  "IActOnMyOwnAccount",
                  checkboxValues,
                  setCheckboxValues
                )}
                value="IActOnMyOwnAccount"
                switchText={t('I Confirm That I Act On My Own Account, In My Own Economic Interests And Not On Behalf Of Others.')}
                isRemoveLine
                isGrayText
                isLeftSwitch
              />
            </div>
          </div>
        )}

        {publicSettings.kyc_client_statement && (
          <div className="settings__item">
            <div className="title-purple settings__title">{t('Client Statement')}</div>

            <div className="settings__fieldset">
              <SwitchCheckboxTheme
                defaultChecked={checkboxValues.clientStatement}
                onChange={onChangeCheckbox(
                  "clientStatement",
                  checkboxValues,
                  setCheckboxValues
                )}
                value="clientStatement"
                switchText={t('I confirm that I am the beneficial owner of the invested assets.')}
                isRemoveLine
                isGrayText
                isLeftSwitch
              />
            </div>
          </div>
        )}

        {publicSettings.kyc_confirmations && (
          <div className="settings__item" style={{ display: "none" }}>
            <div className="title-purple settings__title">{t('Confirmations')}</div>

            <div className="settings__fieldset">
              <SwitchCheckboxTheme
                isGrayText
                isLeftSwitch
                isRemoveLine
                onChange={onChangeCheckbox(
                  "confirmationTerms1",
                  checkboxValues,
                  setCheckboxValues
                )}
                defaultChecked={checkboxValues.confirmationTerms1}
                value="confirmationTerms1"
                switchText={(
                  <Trans
                    i18nKey={t('I Hereby Confirm That, Before Subscribing, I Have Received And Read The PROSPECTUS_OR_ISSUING Of The ISSUER Promptly And In Full And In Particular That')}
                    t={t}
                    components={{paperLink: paperLink ? <Link href={paperLink} target="_blank" rel="noopener noreferrer" /> : <></>}}
                    values={{
                      PROSPECTUS_OR_ISSUING: t(prospectusOrIssuing),
                      ISSUER: t(textConfig.issuer)
                    }}
                  />
                )}
              />

              <div className="settings__content">
                <ul className={classes.confirmParagraph}>
                  <li>{t('I Accept The SUBSCRIPTION Applications', {SUBSCRIPTION: t(textConfig.subscription)})}</li>
                  <li>
                    {t('Especially I Certify That')}
                    <ul>
                      <li>{t('Am Not A Citizen Of The USA, Canada And Australia')}</li>
                      <li>
                        {t('Do Not Hold A Permanent Residence And Work Permit For The US (Green Card), Canada Or Australia')}
                      </li>
                      <li>
                        {t('Have No Residence Or Principal Place Of Business In The USA, Canada Or Australia Or Their Respective Territories')}
                      </li>
                      <li>
                        {t('Am Not A Corporation Or Any Other Asset Organized Under The Laws Of The United States, Canada Or Australia, The Income Of Which Is Governed By The Laws Of Canada Or Australia')}
                      </li>
                      <li>
                        {t('Am Not On Any Of The Sanction Lists Of The European Union, The United States, Canada Or Australia')}
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Trans
                      i18nKey={t('I Have Duly Noted The Risks And Their Potential Implications Described In The PROSPECTUS_OR_ISSUING And Hereby Accept Them')}
                      t={t}
                      components={{paperLink: paperLink ? <Link href={paperLink} target="_blank" rel="noopener noreferrer" /> : <></>}}
                      values={{
                        PROSPECTUS_OR_ISSUING: t(prospectusOrIssuing),
                      }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}

        <div className="settings__item" style={{ display: "none" }}>
          <div className="title-green settings__title">{t('Declaration of Consent')}</div>

          <div className="settings__fieldset">
            <SwitchCheckboxTheme
              defaultChecked={checkboxValues.declarationOfConsent1}
              onChange={onChangeCheckbox(
                "declarationOfConsent1",
                checkboxValues,
                setCheckboxValues
              )}
              value="declarationOfConsent1"
              switchText={t('I Hereby Consent To My Personal Data Being Processed By The Issuer And All Companies Affiliated With It, As Well As The Persons And Entities Involved In The Management And Supervision Of My Investment (In Particular The Issuer’s Business, Banks, Tax Advisors, Auditors) Being Stored In Computer Equipment And Be Used.')}
              isRemoveLine
              isGrayText
              isLeftSwitch
            />

            <div className="field">
              <div className="stock__item w-full d__flex flex__row flex__wrap box__information_2" style={{ backgroundColor: "#ecf9fe" }}>
                <div className="stock__icon">
                  <Icon
                    className={classNames('icon-help')}
                    name='icon-help'
                    size="24"
                  />{" "}
                </div>

                <div className="stock__line item__flex_1">
                  <div className="stock__details">
                    <div className="refund__content refund__content-link">
                      {t('Warnings')}:
                      {' '}
                      {t('The Acquisition Of The TOKENS_OR_SHARES Involves Considerable Risks And May Result In The Complete Loss Of The Invested Assets.', {
                        TOKENS_OR_SHARES: t(publicSettings.rename_share_to_token ? 'Tokens' : 'Shares'),
                      })}
                      {' '}
                      {t('The Issuer Indicates That It Does Not Assess Whether (1) The TOKENS_OR_SHARES Comply With The Investment Objectives Of The Subscriber, (2) The Resulting Investment Risk Is Financially Viable For The Subscriber Of Its Investment Objectives And (3) The Subscriber With His / Her / Its Knowledge And Experiences Can Understand The Resulting Investment Risks.', {
                        TOKENS_OR_SHARES: t(publicSettings.rename_share_to_token ? 'Tokens' : 'Shares'),
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <SwitchCheckboxTheme
              defaultChecked={checkboxValues.declarationOfConsent3}
              onChange={onChangeCheckbox(
                "declarationOfConsent3",
                checkboxValues,
                setCheckboxValues
              )}
              value="declarationOfConsent3"
              switchText={t('I Hereby Confirm That The Declarations Made To The Best Of My Knowledge And Belief Are Correct And Complete. Any Changes To The Aforementioned Circumstances Will Be Communicated To The ISSUER Without Delay In Written Form And Must Be Forwarded An Updated Self-Assessment Within 30 Days Upon Request.', {
                  ISSUER: textConfig.issuer
                })}
              isRemoveLine
              isGrayText
              isLeftSwitch
            />

            <SwitchCheckboxTheme
              defaultChecked={checkboxValues.declarationOfConsent4}
              onChange={onChangeCheckbox(
                "declarationOfConsent4",
                checkboxValues,
                setCheckboxValues
              )}
              value="declarationOfConsent4"
              switchText={t('I Have Read The Aforementioned “Warnings” And Especially The “Risk Information”')}
              isRemoveLine
              isGrayText
              isLeftSwitch
            />
          </div>
        </div>

      </>
    );
  }
  const renderLiquidationDisclaimer = () => {
    return (
      <>
        {publicSettings.kyc_client_statement && (
          <div className="settings__item">
            <div className="title-primary settings__title">{t('Client Statement')}</div>

            <div className="settings__fieldset">
              <SwitchCheckboxTheme
                isGrayText
                isLeftSwitch
                isRemoveLine
                onChange={onChangeCheckbox(
                  "clientStatement",
                  checkboxValues,
                  setCheckboxValues
                )}
                defaultChecked={checkboxValues.clientStatement}
                value="clientStatement"
                switchText={t('liquidationClientStatement', {
                  TOKEN_NAME: publicSettings.token_name
                }) }
              />
            </div>
          </div>
        )}

        <div className="settings__item" style={{ display: "none" }}>
          <div className="title-primary settings__title">
            { t('Declaration of consent')}
          </div>

          <div className="settings__fieldset">
            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              onChange={onChangeCheckbox(
                "declarationOfConsent1",
                checkboxValues,
                setCheckboxValues
              )}
              defaultChecked={checkboxValues.declarationOfConsent1}
              value
              switchText={(
                <>
                  {t('I Hereby agree that I will receive the compensation, once the Lawyer approved the change of claim from you as CUSTOMERFIRSTNAME CUSTOMERMIDDLENAME CUSTOMERLASTNAME to COMPANYNAME',
                  {
                    CUSTOMERFIRSTNAME: customer.first_name,
                    CUSTOMERMIDDLENAME: customer.middle_name,
                    CUSTOMERLASTNAME: customer.last_name,
                    COMPANYNAME: publicSettings.company.name
                  })}
                </>
              )}
            />

            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              onChange={onChangeCheckbox(
                "declarationOfConsent2",
                checkboxValues,
                setCheckboxValues
              )}
              defaultChecked={checkboxValues.declarationOfConsent2}
              value
              switchText={(
                <>
                  {t('I Hereby Consent To My Personal Data Being Processed By COMPANYNAME And All Companies Affiliated With It, As Well As The Persons And Entities Involved In The Management And Supervision Of My Investment (In Particular COMPANYNAME’s Business, Banks, Tax Advisors, Auditors) Being Stored In Computer Equipment And Be Used. The Data Collected May Also Be Used For The Purposes Of Advertising By Email And Post, Regardless Of A Contractual Relationship. Please See The Declaration Of Consent.', {COMPANYNAME: publicSettings.company.name})}
                </>
              )}
            />

            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              onChange={onChangeCheckbox(
                "declarationOfConsent3",
                checkboxValues,
                setCheckboxValues
              )}
              defaultChecked={checkboxValues.declarationOfConsent3}
              value
              switchText={(
                <>
                  {t('I Hereby Declare That I Have Been Provided With The Privacy Policy Of COMPANYNAME', {COMPANY: publicSettings.company.name})}
                </>
              )}
            />

            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              onChange={onChangeCheckbox(
                "declarationOfConsent4",
                checkboxValues,
                setCheckboxValues
              )}
              defaultChecked={checkboxValues.declarationOfConsent4}
              value
              switchText={(
                <>
                  {t('I Hereby Confirm That The Declarations Made To The Best Of My Knowledge And Belief Are Correct And Complete. Any Changes To The Aforementioned Circumstances Will Be Communicated To COMPANYNAME Without Delay In Written Form And Must Be Forwarded An Updated Self-Assessment Within 30 Days Upon Request.', {COMPANY: publicSettings.company.name})}
                </>
              )}
            />
          </div>
        </div>
      </>
    );
  }
  const renderEquityBondDisclaimer = () => {
    return (
      <>
        {publicSettings.kyc_acknowledgement_of_receipt && (
          <div className="settings__item" style={{ display: "none" }}>
            <div className="title-primary settings__title">{t('Acknowledgement of Receipt')}</div>

            <div className="settings__fieldset">
              <SwitchCheckboxTheme
                defaultChecked={checkboxValues.IActOnMyOwnAccount}
                onChange={onChangeCheckbox(
                  "IActOnMyOwnAccount",
                  checkboxValues,
                  setCheckboxValues
                )}
                value="IActOnMyOwnAccount"
                switchText={t('I Confirm That I Act On My Own Account, In My Own Economic Interests And Not On Behalf Of Others. Or the Beneficial Owner Is, If Different From The SUBSCRIBER', {SUBSCRIBER: textConfig.subscriber})}
                isRemoveLine
                isGrayText
                isLeftSwitch
              />

              <div className="field">
                <div className="space space__32">{' '}</div>
                <CardInformation
                  color="card__information-primary"
                  title={t('Note')}
                >
                  <>
                    <p>
                      {t('A Beneficial Owner In Accordance With Art. 2 Para. 1 Let. E) DDA Is A Natural Person On Whose Behalf Or In Whose Interest A Transaction Or Activity Is Carried Out Or A Business Relationship Is Ultimately Established. In The Case Of Legal Entities, It Is Also The Natural Person In Whose Property Or Under Its Control The Legal Entity Ultimately Stands.')}
                    </p>
                    <p>
                      {t('According to Art. 3 of the Liechtenstein Due Diligence Ordinance (DDO) in particular')}:
                    </p>
                    <p>
                      {t('in the case of corporations, including corporately structured establishments or trust companies, and non-personality corporations')}:
                      {t('those individuals who, in the end, directly or indirectly hold or control share or voting rights of 25% or more in such entities, or otherwise control these entities exercise')};
                    </p>

                    <p>
                      {t('in the case of foundations, trusts and foundation-like structured establishments or trust enterprises')}: {t('those natural persons who are effective, non-trustee founders, founders or trustors, regardless of whether they exercise control over them after the legal entity has been established')}; {t('those natural or legal persons who are members of the foundation council or of the board of trustees')}; {t('any natural persons who are protectors or persons with similar or equivalent functions')}; {t('those natural persons who are beneficiaries')}; {t('if no beneficiaries are yet to be designated, the group of persons in whose interest the legal entity is primarily established or operated')}; {t('in addition, those individuals who ultimately control the entity through direct or indirect ownership or otherwise.')}
                    </p>
                  </>
                </CardInformation>
              </div>
            </div>
          </div>
        )}

        {publicSettings.kyc_client_statement && (
          <div className="settings__item">
            <div className="title-primary settings__title">
              { t('Client Statement') }
            </div>

            <div className="settings__fieldset">
              <SwitchCheckboxTheme
                isGrayText
                isLeftSwitch
                isRemoveLine
                onChange={onChangeCheckbox(
                  "clientStatement",
                  checkboxValues,
                  setCheckboxValues
                )}
                defaultChecked={checkboxValues.clientStatement}
                value="clientStatement"
                switchText={t('I hereby confirm that I myself am exclusively beneficially entitled to the assets for the acquisition of the tokens and that I am acting for my own account, in my own economic interest and not on behalf of others.')}
              />
            </div>
          </div>
        )}

        {isAwearetech && (
          <>
            <div className="settings__item">
              <div className="title-primary settings__title">
                { t('Declaration of Domicile') }
              </div>

              <div className="settings__fieldset">
                <SwitchCheckboxTheme
                  isGrayText
                  isLeftSwitch
                  isRemoveLine
                  onChange={onChangeCheckbox(
                    "usExportControl",
                    checkboxValues,
                    setCheckboxValues
                  )}
                  defaultChecked={checkboxValues.usExportControl}
                  value="usExportControl"
                  switchText={awearetechDisclaimerText['usExportControl']}
                />
              </div>
            </div>

            <div className="settings__item">
              <div className="title-primary settings__title">
                { t('Applicable Law') }
              </div>

              <div className="settings__fieldset">
                <SwitchCheckboxTheme
                  isGrayText
                  isLeftSwitch
                  isRemoveLine
                  onChange={onChangeCheckbox(
                    "applicableLaw",
                    checkboxValues,
                    setCheckboxValues
                  )}
                  defaultChecked={checkboxValues.applicableLaw}
                  value="applicableLaw"
                  switchText={awearetechDisclaimerText['applicableLaw']}
                />
              </div>
            </div>

            <div className="settings__item">
              <div className="title-primary settings__title">
                { t('Place of Jurisdiction') }
              </div>

              <div className="settings__fieldset">
                <SwitchCheckboxTheme
                  isGrayText
                  isLeftSwitch
                  isRemoveLine
                  onChange={onChangeCheckbox(
                    "placeOfJurisdiction",
                    checkboxValues,
                    setCheckboxValues
                  )}
                  defaultChecked={checkboxValues.placeOfJurisdiction}
                  value="placeOfJurisdiction"
                  switchText={awearetechDisclaimerText['placeOfJurisdiction']}
                />
              </div>
            </div>

            <div className="settings__item">
              <div className="title-primary settings__title">
                { t('Severability') }
              </div>

              <div className="settings__fieldset">
                <SwitchCheckboxTheme
                  isGrayText
                  isLeftSwitch
                  isRemoveLine
                  onChange={onChangeCheckbox(
                    "severability",
                    checkboxValues,
                    setCheckboxValues
                  )}
                  defaultChecked={checkboxValues.severability}
                  value="severability"
                  switchText={awearetechDisclaimerText['severability']}
                />
              </div>
            </div>
          </>
        )}

        { values.referal_code === 'BlackMantaCapital' &&
          <SwitchCheckboxTheme
            defaultChecked={checkboxValues.declarationOfConsent1}
            onChange={onChangeCheckbox(
              "declarationOfConsent1",
              checkboxValues,
              setCheckboxValues
            )}
            value="declarationOfConsent1"
            switchText={
              <Trans
                i18nKey="equityDeclarationOfConsent4_BlackMantaCapital"
                t={t}
                components={{
                  FULL_NAME: `${customer.firstName} ${customer.middleName} ${customer.lastName}`,
                  COMPANY: publicSettings.company.name,
                  //eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                  consentLink: <a href="/declaration-of-consent" target="_blank" rel="noopener noreferrer"> {t('Declaration of Consent')} </a>,
                  //eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                  blackMantaLink: <a href="https://blackmanta.capital/" target="_blank" rel="noopener noreferrer"> {t('and BMCP GmbH and its Affiliated Companies As The Issuers Investment Broker')} </a>,
                }}
              />
            }
            isRemoveLine
            isGrayText
            isLeftSwitch
          />
        }

        <div className="settings__item" style={{ display: "none" }}>
          <div className="title-primary settings__title">
            { t('Declaration of consent')}
          </div>

          <div className="settings__fieldset">
            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              onChange={onChangeCheckbox(
                "acknowledgementOfReceiptTerm1",
                checkboxValues,
                setCheckboxValues
              )}
              defaultChecked={checkboxValues.acknowledgementOfReceiptTerm1}
              value
              switchText={
                <Trans
                  i18nKey="I Hereby Confirm The Receipt Of The PROSPECTUS_OR_ISSUING Of The ISSUER Dated PROSPECTUS_OR_ISSUING_DATE Regarding The TOKEN_TYPE, Which Among Other Things Contain The Information According To Art."
                  t={t}
                  components={{paperLink: paperLink ? <Link href={paperLink} target="_blank" rel="noopener noreferrer" /> : <></>}}
                  values={{
                    PROSPECTUS_OR_ISSUING: t(prospectusOrIssuing),
                    PROSPECTUS_OR_ISSUING_DATE: prospectusOrIssuingDate,
                    ISSUER: t(textConfig.issuer),
                    TOKEN_TYPE: publicSettings.token_type === TOKEN_TYPES.BOND
                      ? t('Bond')
                      : t('Shares'),
                  }}
                />
              }
            />

            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              onChange={onChangeCheckbox(
                "acknowledgementOfReceiptTerm2",
                checkboxValues,
                setCheckboxValues
              )}
              defaultChecked={checkboxValues.acknowledgementOfReceiptTerm2}
              value="acknowledgementOfReceiptTerm2"
              switchText={
                <>
                  { t('I Hereby Confirm To Have Been Informed') }
                  <NavLink to="/right-of-withdrawal" target="_blank">
                    {" "}
                    { t('About The Right Of Withdrawal.') }
                  </NavLink>
                </>
              }
            />

            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              onChange={onChangeCheckbox(
                "acknowledgementOfReceiptTerm3",
                checkboxValues,
                setCheckboxValues
              )}
              defaultChecked={checkboxValues.acknowledgementOfReceiptTerm3}
              value="acknowledgementOfReceiptTerm3"
              switchText={
                <>
                  {
                    `I Hereby Declare And Confirm That, At The Same Time As The ${textConfig.subscription} Is Accepted,` +
                    ` I Am Requested To Transfer The ${textConfig.subscription} Amount To The Following Special` +
                    ` Account Of The ${textConfig.issuer} Within The Duration Of The Offer. Please Refer To Further` +
                    " Instructions In Your My Account (This Account Will Be Created Once The KYC Form" +
                    " Has Been Successfully Submitted))"
                  }
                </>
              }
            />
          </div>
        </div>

        {publicSettings.kyc_confirmations && (
          <div className="settings__item" style={{ display: "none" }}>
            <div className="title-primary settings__title">
              {t('Confirmations')}
            </div>

            <div className="settings__fieldset">
              <SwitchCheckboxTheme
                isGrayText
                isLeftSwitch
                isRemoveLine
                onChange={onChangeCheckbox(
                  "confirmationTerms1",
                  checkboxValues,
                  setCheckboxValues
                )}
                defaultChecked={checkboxValues.confirmationTerms1}
                value="confirmationTerms1"
                switchText={(
                  <Trans
                    i18nKey="I Hereby Confirm That, Before Subscribing, I Have Received And Read The PROSPECTUS_OR_ISSUING Of The ISSUER Dated PROSPECTUS_OR_ISSUING_DATE Regarding The TOKEN_TYPES , In Particular The Risk Information, Promptly And In Full And I Agree With The Content Of The PROSPECTUS_OR_ISSUING, And In Particular That"
                    t={t}
                    components={{paperLink: paperLink ? <Link href={paperLink} target="_blank" rel="noopener noreferrer" /> : <></>}}
                    values={{
                      PROSPECTUS_OR_ISSUING: t(prospectusOrIssuing),
                      PROSPECTUS_OR_ISSUING_DATE: prospectusOrIssuingDate,
                      ISSUER: t(textConfig.issuer),
                      TOKEN_TYPE: publicSettings.token_type === TOKEN_TYPES.BOND
                        ? t('Bond')
                        : t('Shares'),
                    }}
                  />
                )}
              />

              <div className="field">
                <div className="space space__32">{' '}</div>
                <CardInformation
                  color="card__information-primary"
                >
                  <ul>
                    <li>{t('I Accept The SUBSCRIPTION Applications', {SUBSCRIPTION: textConfig.subscription})}</li>
                    <li>
                      <Trans
                        i18nKey={t('I Have Duly Noted The Sales Restrictions Stipulated In The PROSPECTUS_OR_ISSUING And Hereby Confirm That Those Restrictions Are Observed, Especially I Certify That I As A Single Natural Person Or Legal Entity')}
                        t={t}
                        components={{paperLink: paperLink ? <Link href={paperLink} target="_blank" rel="noopener noreferrer" /> : <></>}}
                        values={{
                          PROSPECTUS_OR_ISSUING: t(prospectusOrIssuing),
                        }}
                      />
                      <ul>
                        { (publicSettings.enable_blacklist_citizen && publicSettings.blacklist_citizen_choosen) && (
                          <li>
                            {t('Am Not A Citizen Of The CITIZEN', {CITIZEN: publicSettings.blacklist_citizen_choosen})}
                          </li>
                        )}

                        <li>
                          {t('Do Not Hold A Permanent Residence And Work Permit For The US (Green Card), Canada Or Australia')}
                        </li>
                        <li>
                          {t('Have No Residence Or Principal Place Of Business In The USA, Canada Or Australia Or Their Respective Territories')}
                        </li>
                        <li>
                          {t('Am Not A Corporation Or Any Other Asset Organized Under The Laws Of The United States, Canada Or Australia, The Income Of Which Is Governed By The Laws Of Canada Or Australia')}
                        </li>
                        <li>
                          {t('Am Not On Any Of The Sanction Lists Of The European Union, The United States, Canada Or Australia')}
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Trans
                        i18nKey={t('I Have Duly Noted The Risks And Their Potential Implications Described In The PROSPECTUS_OR_ISSUING And Hereby Accept Them')}
                        t={t}
                        components={{paperLink: paperLink ? <Link href={paperLink} target="_blank" rel="noopener noreferrer" /> : <></>}}
                        values={{
                          PROSPECTUS_OR_ISSUING: t(prospectusOrIssuing),
                        }}
                      />

                    </li>
                  </ul>
                </CardInformation>
              </div>
            </div>
          </div>
        )}

        <div className="settings__item" style={{ display: "none" }}>
          <div className="title-primary settings__title">
            { t('Declaration of Consent') }
          </div>

          <div className="settings__fieldset">
            <SwitchCheckboxTheme
              defaultChecked={checkboxValues.declarationOfConsent1}
              onChange={onChangeCheckbox(
                "declarationOfConsent1",
                checkboxValues,
                setCheckboxValues
              )}
              value="declarationOfConsent1"
              switchText={
                <Trans
                  i18nKey={values.referal_code === 'BlackMantaCapital'
                    ? 'equityDeclarationOfConsent4_BlackMantaCapital'
                    : 'equityDeclarationOfConsent4'
                  }
                  t={t}
                  components={{
                    FULL_NAME: `${customer.firstName} ${customer.middleName} ${customer.lastName}`,
                    COMPANY: publicSettings.company.name,
                    //eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                    consentLink: <a href="/declaration-of-consent" target="_blank" rel="noopener noreferrer"> {t('Declaration of Consent')} </a>,
                    //eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                    blackMantaLink: <a href="https://blackmanta.capital/" target="_blank" rel="noopener noreferrer"> {t('and BMCP GmbH and its Affiliated Companies As The Issuers Investment Broker')} </a>,
                  }}
                />
              }
              isRemoveLine
              isGrayText
              isLeftSwitch
            />

            <SwitchCheckboxTheme
              isRemoveLine
              isGrayText
              isLeftSwitch
              value="declarationOfConsent2"
              onChange={onChangeCheckbox(
                "declarationOfConsent2",
                checkboxValues,
                setCheckboxValues
              )}
              defaultChecked={checkboxValues.declarationOfConsent2}
              switchText={
                <>
                  {kycType === "INDIVIDUAL" ? "I" : "The Legal Entity"} hereby
                  declare that {kycType === "INDIVIDUAL" ? "I" : "we"} have been
                  provided with the{" "}
                  <NavLink to="/privacy-policy" target="_blank">Privacy Policy</NavLink> of the{" "}
                  {textConfig.issuer} and that{" "}
                  {kycType === "INDIVIDUAL" ? "I" : "we"} have been informed about
                  how my Data will be processed and{" "}
                  {kycType === "INDIVIDUAL" ? "I" : "our"} Data Protection Rights.
                </>
              }
            />

            <div className="field">
              <div className="space space__32">{' '}</div>
              <CardInformation
                color="card__information-red"
                title={t('Warnings')}
              >
                Warnings: The Acquisition Of The{" "}
                {publicSettings.token_type === TOKEN_TYPES.BOND ? "Bond" : "Shares"}{" "}
                Involves Considerable Risks And May Result In The Complete Loss Of
                The Invested Assets. The {textConfig.issuer} Indicates That It Does
                Not Assess Whether (1) The{" "}
                {publicSettings.token_type === TOKEN_TYPES.BOND ? "Bond" : "Shares"}{" "}
                Comply With The Investment Objectives Of The {textConfig.subscriber}{" "}
                , (2) The Resulting Investment Risk Is Financially Viable For The{" "}
                {textConfig.subscriber} Of Its Investment Objectives And (3) The{" "}
                {textConfig.subscriber} With His / Her / Its Knowledge And
                Experiences Can Understand The Resulting Investment Risks.
              </CardInformation>
            </div>

            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              defaultChecked={checkboxValues.declarationOfConsent3}
              onChange={onChangeCheckbox(
                "declarationOfConsent3",
                checkboxValues,
                setCheckboxValues
              )}
              value="declarationOfConsent3"
              switchText={
                "I Hereby Confirm That The Declarations Made To The Best Of My Knowledge And Belief" +
                " Are Correct And Complete. Any Changes To The Aforementioned Circumstances Will" +
                ` Be Communicated To The ${textConfig.issuer} Without Delay In Written Form And Must Be Forwarded` +
                " An Updated Self-Assessment Within 30 Days Upon Request."
              }
            />

            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              defaultChecked={checkboxValues.declarationOfConsent4}
              onChange={onChangeCheckbox(
                "declarationOfConsent4",
                checkboxValues,
                setCheckboxValues
              )}
              value="declarationOfConsent4"
              switchText={
                <>
                  <Trans
                    i18nKey="I Have Read The Aforementioned “Warnings” And Especially The “Risk Information” In The PROSPECTUS_OR_ISSUING."
                    t={t}
                    components={{paperLink: paperLink ? <Link href={paperLink} target="_blank" rel="noopener noreferrer" /> : <></>}}
                    values={{
                      PROSPECTUS_OR_ISSUING: t(prospectusOrIssuing),
                    }}
                  />
                  {' '}
                  {t('I Am Aware Of The Risk Of The Acquisition Of The TOKEN_TYPE.', {
                    TOKEN_TYPE: publicSettings.token_type === TOKEN_TYPES.BOND
                      ? "Bond"
                      : "Shares"

                  })}
                </>
              }
            />
          </div>

          <div className="space space__48">{' '}</div>
        </div>
      </>
    );
  }

  const renderDisclaimer = () => {
    if (publicSettings.token_type === TOKEN_TYPES.UTILITY) {
      return renderUtilityDisclaimer()
    }
    if (publicSettings.token_type === TOKEN_TYPES.NONE) {
      return renderNoneDisclaimer()
    }
    if (publicSettings.token_type === TOKEN_TYPES.LIQUIDATION) {
      return renderLiquidationDisclaimer()
    }
    return renderEquityBondDisclaimer()
  }

  return (
    <>
      {publicSettings.kyc_politically_exposed_persons && renderPotiticallyExposedPersons()}
      {renderDisclaimer()}
    </>
  )
};

export default KycVerificationFormDisclaimer;
