import React, { useState } from 'react'
import reduce from 'lodash/reduce'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import ImageUpload from '../ImageUpload'
import { getFileUrl } from '../../utils'
import { getOriginalFileExtension } from '../../utils/file'
import { useTranslation } from 'react-i18next'

const SETS = {
  logo: {
    label: 'Logo',
  },
  brief_logo: {
    label: 'Brief logo',
  },
}

const AdminSettingsUploads = ({
  settings, loading, onUpdate, editingAllowed,
}) => {
  const { t } = useTranslation()
  const [values, setValues] = useState({
    ...reduce(SETS, (memo, value, key) => {
      // eslint-disable-next-line no-param-reassign
      memo[key] = (settings && settings[key]) || {}
      return memo
    }, {}),
  })

  const onUpdateBtnClick = () => {
    const files = Object.keys(values).reduce((prev, current) => ({
      ...prev,
      [current]: values[current].img,
    }), {})

    onUpdate(files)
  }

  const onDropFile = (name) => (img, imgURI) => {
    let newImage = {}
    if (img.length > 0 && imgURI.length > 0) {
      newImage = {
        img: img[0],
        imgURI: imgURI[0],
      }
    }

    setValues({ ...values, [name]: newImage })
  }

  const UploadPreview = ({ path, ...rest }) => {
    if (getOriginalFileExtension(path) === 'pdf') {
      return <embed src={getFileUrl(path)} width="500" height="375" type="application/pdf" {...rest} />
    }

    return <img alt="" src={getFileUrl(path)} {...rest} />
  }

  const removeFile = (name) => {
    setValues({ ...values, [name]: {img: '_destroy', imgURI: null} })
  }

  return (
    <>
      <div className="settings__item">
        <div className="settings__fieldset">
          <div className="settings__row flex__row flex__wrap">
            <div className="field">
              <div className="field__label">
                { t('Logo') }
                {' '}
                {settings.logo_path && values.logo?.img !== '_destroy' && (
                  <>
                  <span
                      className="help__item help__item_red help__item-nospace_x help__item-nospace_y"
                      onClick={(e) => {
                        removeFile('logo');
                      }}
                    >
                      {t('Remove')}
                    </span>
                  </>
                )}
              </div>

              <div className="field__wrap">
                {settings.logo_path && values.logo?.img !== '_destroy' && (
                  <>
                    <div className="summary summary__document">
                      <div className="summary__preview">
                        <div className="summary__preview_aspect_ratio">
                          <UploadPreview path={settings.logo_path} alt="logo" />
                        </div>
                      </div>

                      <div className="summary__line">
                        <div className="summary__title packages__content nospace__bottom">
                          { t('Current logo') }
                        </div>
                      </div>
                    </div>

                    <div className="space space__24">{' '}</div>
                  </>
                )}

                {editingAllowed && (
                  <ImageUpload
                    buttonText={t('Choose Logo')}
                    buttonClassName="btn btn-primary"
                    onChange={onDropFile('logo')}
                    maxFileSize={1048576}
                    withPreview
                    singleImagePick
                    singleFile
                    defaultImages={
                      (values.logo || {}).imgURI
                        ? [values.logo]
                        : []
                    }
                    imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.svg']}
                  >
                    <div className="summary__title">
                      <p>{ t('Drag and drop new image logo') }</p>
                    </div>
                  </ImageUpload>
                )}
              </div>
            </div>

            <div className="field">
              <div className="field__label">
                { t('Logo for dark BG') }
                {' '}
                {settings.logo_for_dark_bg_path && values.logo_for_dark_bg?.img !== '_destroy' && (
                  <>
                    <span
                      className="help__item help__item_red help__item-nospace_x help__item-nospace_y"
                      onClick={(e) => {
                        removeFile('logo_for_dark_bg');
                      }}
                    >
                      {t('Remove')}
                    </span>
                  </>
                )}
              </div>

              <div className="field__wrap">
                {settings.logo_for_dark_bg_path && values.logo_for_dark_bg?.img !== '_destroy' && (
                  <>
                    <div className="summary summary__document">
                      <div className="summary__preview">
                        <div className="summary__preview_aspect_ratio">
                          <UploadPreview path={settings.logo_for_dark_bg_path} alt="logo" />
                        </div>
                      </div>

                      <div className="summary__line">
                        <div className="summary__title packages__content nospace__bottom">
                          { t('Current logo') }
                        </div>
                      </div>
                    </div>

                    <div className="space space__24">{' '}</div>
                  </>
                )}

                {editingAllowed && (
                  <ImageUpload
                    buttonText={t('Choose Logo')}
                    buttonClassName="button"
                    onChange={onDropFile('logo_for_dark_bg')}
                    maxFileSize={1048576}
                    withPreview
                    singleImagePick
                    singleFile
                    defaultImages={
                      (values.logo_for_dark_bg || {}).imgURI
                        ? [values.logo_for_dark_bg]
                        : []
                    }
                    imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.svg']}
                  >
                    <div className="summary__title">
                      <p>
                        { t('Drag and drop new logo for Dark BG') }
                      </p>
                    </div>
                  </ImageUpload>
                )}
              </div>
            </div>

            <div className="field">
              <div className="field__label">
                { t('Brief Logo') }
                {' '}
                {settings.brief_logo_path && values.brief_logo?.img !== '_destroy' && (
                  <>
                    <span
                      className="help__item help__item_red help__item-nospace_x help__item-nospace_y"
                      onClick={(e) => {
                        removeFile('brief_logo');
                      }}
                    >
                      {t('Remove')}
                    </span>
                  </>
                )}
              </div>

              <div className="field__wrap">
                {settings.brief_logo_path && values.brief_logo?.img !== '_destroy' && (
                  <>
                    <div className="summary summar__document">
                      <div className="summary__preview">
                        <div className="summary__preview_aspect_ratio">
                          <UploadPreview path={settings.brief_logo_path} alt="logo" />
                        </div>
                      </div>

                      <div className="summary__line">
                        <div className="summary__title packages__content nospace__bottom">
                          { t('Current brief logo') }
                        </div>
                      </div>
                    </div>

                    <div className="space space__24">{' '}</div>
                  </>
                )}

                {editingAllowed && (
                  <ImageUpload
                    buttonText={t('Choose Logo')}
                    buttonClassName="button"
                    onChange={onDropFile('brief_logo')}
                    maxFileSize={1048576}
                    withPreview
                    singleImagePick
                    singleFile
                    defaultImages={
                      (values.brief_logo || {}).imgURI
                        ? [values.brief_logo]
                        : []
                    }
                    imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.svg']}
                  >
                    <div className="summary__title">
                      <p>
                        { t('Drag and drop new brief logo image') }
                      </p>
                    </div>
                  </ImageUpload>
                )}
              </div>
            </div>

            <div className="field">
              <div className="field__label">
                { t('Dataroom logo') }
                {' '}
                {settings.dataroom_logo_path && values.dataroom_logo?.img !== '_destroy' && (
                  <>
                    <span
                      className="help__item help__item_red help__item-nospace_x help__item-nospace_y"
                      onClick={(e) => {
                        removeFile('dataroom_logo');
                      }}
                    >
                      {t('Remove')}
                    </span>
                  </>
                )}
              </div>

              <div className="field__wrap">
                {settings.dataroom_logo_path && values.dataroom_logo?.img !== '_destroy' && (
                  <>
                    <div className="summary summary__document">
                      <div className="summary__preview">
                        <div className="summary__preview_aspect_ratio">
                          <UploadPreview path={settings.dataroom_logo_path} alt="logo" />
                        </div>
                      </div>

                      <div className="summary__line">
                        <div className="summary__title packages__content nospace__bottom">
                          { t('Current dataroom logo') }
                        </div>
                      </div>
                    </div>
                    <div className="space space__24">{' '}</div>
                  </>
                )}

                {editingAllowed && (
                  <ImageUpload
                    buttonText={t('Choose dataroom logo')}
                    buttonClassName="button"
                    onChange={onDropFile('dataroom_logo')}
                    maxFileSize={1048576}
                    withPreview
                    singleImagePick
                    singleFile
                    defaultImages={
                      (values.dataroom_logo || {}).imgURI
                        ? [values.dataroom_logo]
                        : []
                    }
                    imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.svg']}
                  >
                    <div className="summary__title">
                      <p>
                        { t('Drag and drop new Dataroom logo image') }
                      </p>
                    </div>
                  </ImageUpload>
                )}
              </div>
            </div>

            <div className="field">
              <div className="field__label">
                { t('Example photo proof') }
                {' '}
                {settings.example_photo_proof_path && values.example_photo_proof?.img !== '_destroy' && (
                  <>
                    <span
                      className="help__item help__item_red help__item-nospace_x help__item-nospace_y"
                      onClick={(e) => {
                        removeFile('example_photo_proof');
                      }}
                    >
                      {t('Remove')}
                    </span>
                  </>
                )}
              </div>

              <div className="field__wrap">
                {settings.example_photo_proof_path && values.example_photo_proof?.img !== '_destroy' && (
                  <>
                    <div className="summary summary__document">
                      <div className="summary__preview">
                        <div className="summary__preview_aspect_ratio">
                          <UploadPreview path={settings.example_photo_proof_path} alt="logo" />
                        </div>
                      </div>

                      <div className="summary__line">
                        <div className="summary__title packages__content nospace__bottom">
                          { t('Current example photo proof') }
                        </div>
                      </div>
                    </div>
                    <div className="space space__24">{' '}</div>
                  </>
                )}

                {editingAllowed && (
                  <ImageUpload
                    buttonText={t('Choose example photo proof')}
                    buttonClassName="button"
                    onChange={onDropFile('example_photo_proof')}
                    maxFileSize={1048576}
                    withPreview
                    singleImagePick
                    singleFile
                    defaultImages={
                      (values.example_photo_proof || {}).imgURI
                        ? [values.example_photo_proof]
                        : []
                    }
                  >
                    <div className="summary__title">
                      <p>
                        { t('Drag and drop new example photo proof image') }
                      </p>
                    </div>
                  </ImageUpload>
                )}
              </div>
            </div>

            <div className="field">
              <div className="field__label">
                { t('Source of funds example') }
                {' '}
                {settings.source_of_funds_example_path && values.source_of_funds_example?.img !== '_destroy' && (
                  <>
                    <span
                      className="help__item help__item_red help__item-nospace_x help__item-nospace_y"
                      onClick={(e) => {
                        removeFile('source_of_funds_example');
                      }}
                    >
                      {t('Remove')}
                    </span>
                  </>
                )}
              </div>

              <div className="field__wrap">
                {settings.source_of_funds_example_path && values.source_of_funds_example?.img !== '_destroy' && (
                  <>
                    <div className="summary summary__document">
                      <div className="summary__preview">
                        <div className="summary__preview_aspect_ratio">
                          <UploadPreview path={settings.source_of_funds_example_path} alt="logo" />
                        </div>
                      </div>

                      <div className="summary__line">
                        <div className="summary__title packages__content nospace__bottom">
                          { t('Current source of funds example') }
                        </div>
                      </div>
                    </div>

                    <div className="space space__24">{' '}</div>
                  </>
                )}

                {editingAllowed && (
                  <ImageUpload
                    buttonText={t('Choose source of funds example')}
                    buttonClassName="button"
                    onChange={onDropFile('source_of_funds_example')}
                    maxFileSize={10485760}
                    withPreview
                    singleImagePick
                    singleFile
                    defaultImages={
                      (values.source_of_funds_example || {}).imgURI
                        ? [values.source_of_funds_example]
                        : []
                    }
                  >
                    <div className="summary__title">
                      <p>
                        { t('Drag and drop new source of funds example image') }
                      </p>
                    </div>
                  </ImageUpload>
                )}
              </div>
            </div>

            <div className="field">
              <div className="field__label">
                { t('Rights of Withdrawal') }
                {' '}
                {settings.rights_of_withdrawal_path && values.rights_of_withdrawal?.img !== '_destroy' && (
                  <>
                    <span
                      className="help__item help__item_red help__item-nospace_x help__item-nospace_y"
                      onClick={(e) => {
                        removeFile('rights_of_withdrawal');
                      }}
                    >
                      {t('Remove')}
                    </span>
                  </>
                )}
              </div>

              <div className="field__wrap">
                {settings.rights_of_withdrawal_path && values.rights_of_withdrawal?.img !== '_destroy' && (
                  <>
                    <div className="summary summary__docuument">
                      <div className="summary__preview">
                        <div className="summary__preview_aspect_ratio">
                          <UploadPreview path={settings.rights_of_withdrawal_path} alt="logo" />
                        </div>
                      </div>

                      <div className="summary__line">
                        <div className="summary__title">
                          { t('Current Rights of Withdrawal') }
                        </div>
                      </div>
                    </div>

                    <div className="space space__24">{' '}</div>
                  </>
                )}

                {editingAllowed && (
                  <ImageUpload
                    buttonText={t('Choose source of funds example')}
                    buttonClassName="button"
                    onChange={onDropFile('rights_of_withdrawal')}
                    maxFileSize={10485760}
                    withPreview
                    singleImagePick
                    singleFile
                    defaultImages={
                      (values.rights_of_withdrawal || {}).imgURI
                        ? [values.rights_of_withdrawal]
                        : []
                    }
                  >
                    <div className="summary__title">
                      <p>
                        { t('Drag and drop a New Right of Withdrawal PDF') }
                      </p>
                    </div>
                  </ImageUpload>
                )}
              </div>
            </div>

            <div className="field" key="source_of_address_for_tokens_example">
              <div className="field__label">
                { t('Source of address for tokens example') }
                {' '}
                {settings.source_of_address_for_tokens_example_path && values.source_of_address_for_tokens_example?.img !== '_destroy' && (
                  <>
                    <span
                      className="help__item help__item_red help__item-nospace_x help__item-nospace_y"
                      onClick={(e) => {
                        removeFile('source_of_address_for_tokens_example');
                      }}
                    >
                      {t('Remove')}
                    </span>
                  </>
                )}
              </div>

              <div className="field__wrap">
                {settings.source_of_address_for_tokens_example_path && values.source_of_address_for_tokens_example?.img !== '_destroy' && (
                  <>
                    <div className="summary summary__document">
                      <div className="summary__preview">
                        <div className="summary__preview_aspect_ratio">
                          <UploadPreview path={settings.source_of_address_for_tokens_example_path} alt="logo" />
                        </div>
                      </div>

                      <div className="summary__line">
                        <div className="summary__title">
                          { t('Current address for tokens example') }
                        </div>
                      </div>
                    </div>

                    <div className="space space__24">{' '}</div>
                  </>
                )}

                {editingAllowed && (
                  <ImageUpload
                    buttonText={t('Choose source of address for tokens example')}
                    buttonClassName="btn btn-primary"
                    onChange={onDropFile('source_of_address_for_tokens_example')}
                    maxFileSize={10485760}
                    withPreview
                    singleImagePick
                    singleFile
                    defaultImages={
                      (values.source_of_address_for_tokens_example || {}).imgURI
                        ? [values.source_of_address_for_tokens_example]
                        : []
                    }
                  >
                    <div className="summary__title">
                      <p>
                        { t('Drag and drop new source of address for tokens example image') }
                      </p>
                    </div>
                  </ImageUpload>
                )}
              </div>
            </div>

            <div className="field" key="source_of_address_for_sending_tokens_example">
              <div className="field__label">
                { t('Source of address for sending tokens example') }
                {' '}
                {settings.source_of_address_for_sending_tokens_example_path && values.source_of_address_for_sending_tokens_example?.img !== '_destroy' && (
                  <>
                    <span
                      className="help__item help__item_red help__item-nospace_x help__item-nospace_y"
                      onClick={(e) => {
                        removeFile('source_of_address_for_sending_tokens_example');
                      }}
                    >
                      {t('Remove')}
                    </span>
                  </>
                )}
              </div>

              <div className="field__wrap">
                {settings.source_of_address_for_sending_tokens_example_path && values.source_of_address_for_sending_tokens_example?.img !== '_destroy' && (
                  <>
                    <div className="summary summary__document">
                      <div className="summary__preview">
                        <div className="summary__preview_aspect_ratio">
                          <UploadPreview path={settings.source_of_address_for_sending_tokens_example_path} alt="logo" />
                        </div>
                      </div>
                      <div className="summary__line">
                        <div className="summary__title packages__content nospace__bottom">
                          { t('Current address for tokens example') }
                        </div>
                      </div>
                    </div>
                    <div className="space space__24">{' '}</div>
                  </>
                )}

                {editingAllowed && (
                  <ImageUpload
                    buttonText={t('Choose source of address for sending tokens example')}
                    buttonClassName="button"
                    onChange={onDropFile('source_of_address_for_sending_tokens_example')}
                    maxFileSize={10485760}
                    withPreview
                    singleImagePick
                    singleFile
                    defaultImages={
                      (values.source_of_address_for_sending_tokens_example || {}).imgURI
                        ? [values.source_of_address_for_sending_tokens_example]
                        : []
                    }
                  >
                    <div className="summary__title">
                      <p>
                        { t('Drag and drop new source of address for sending tokens example image') }
                      </p>
                    </div>
                  </ImageUpload>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        loading
          ? <div className="settings__item"><CircularProgress /></div>
          : (
            <button
              disabled={!editingAllowed}
              onClick={onUpdateBtnClick}
              className="button settings__button"
            >
              { t('Update') }
            </button>
          )
      }
    </>
  )
}

AdminSettingsUploads.propTypes = {
  settings: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default AdminSettingsUploads
