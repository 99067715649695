import React, { useState, useEffect } from 'react'
import axios from 'axios'
import classNames from 'classnames'
import moment from 'moment'
import {
  CircularProgress,
  MenuItem,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'

import { toaster, isProduction } from '../../utils'
import { COUNTRY_BY_ALPHA_2_CODE } from '../../constants/country'
import metamaskService from '../../services/metamask'
import InputThemeField from '../InputThemeField'
import Icon from '../Icon'
import RadioTheme from '../Radio'

const API_URL = isProduction ? 'https://api.monerium.app' : 'https://api.monerium.dev'

const ConfirmApprovedTransactionModal = ({
  currentAccount,
  isOpenModal = false,
  setIsOpenModal,
  fetchTransactionHistory,
  authToken,
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    amount: '',
    memo: '',
    iban: '',
    country: '',
    firstName: '',
    lastName: '',
    sendType: 'individual',
  })
  const [loading, setLoading] = useState(false)

  const handleSignMessage = async (message) => {
    if (!metamaskService.getCurrentAddress()) {
      await metamaskService.requestAccounts()
    }

    const account = metamaskService.getCurrentAddress()

    if (account !== currentAccount.address.toLowerCase()) {
      toaster.error('Please open your blockchain wallet and connect the correct address')
      return null
    }

    let network = currentAccount.network;

    if (network === 'mainnet' && currentAccount.chain === 'polygon') {
      network = 'polygon'
    }

    await metamaskService.switchNetwork(network)

    const signature = await metamaskService.signMessage(account, message)
    const signerAddress = await metamaskService.getSigner(signature, message)

    if (signerAddress === currentAccount.address.toLowerCase()) {
      return signature
    } else {
      toaster.error('The wallet you provided does not match with the current wallet on Metamask.')
      return null
    }
  }

  const placeOrder = async () => {
    try {
      const message = `Send EUR ${values.amount} to ${values.iban} at ${moment().format()}`
      const signature = await handleSignMessage(message)
      setLoading(true)

      const data = {
        kind: 'redeem',
        amount: `${values.amount}`,
        signature,
        accountId: currentAccount.id,
        message,
        memo: values.memo,
        counterpart: {
          identifier: {
            standard: "iban",
            iban: values.iban
          },
          details: values.sendType === 'individual'
            ? {
              firstName: values.firstName,
              lastName: values.lastName,
              country: values.country,
            }
            : {
              companyName: values.companyName,
              country: values.country,
            }
        },
      }

      await axios.post(
        `${API_URL}/orders`,
        data,
        {
          headers: {
            "Authorization": `Bearer ${authToken}`,
          },
        },
      )

      toaster.success(t('Success'))
      setLoading(false)
      fetchTransactionHistory()
      setIsOpenModal(false)
    } catch (error) {
      console.log(error)

      if (error.response && error.response.data) {
        toaster.error(error.response.data.message)
      }
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={isOpenModal}
      onClose={() => {
        setIsOpenModal(false);
      }}
      aria-labelledby="form-dialog-title"
    >
      <div className="popup js-popup animation visible popup__kyc-medium">
        <div onClick={() => setIsOpenModal(false)} className="popup__overlay js-popup-overlay">{' '}</div>

        <div className="popup__wrap js-popup-wrap">
          <div className="description">
            <div className="title-red description__title">{t('Send money')}</div>

            <div className="settings__fieldset description__row flex__row flex__wrap">
              <div className="w-full">
                <div className="field__label">
                  {currentAccount.currency.toUpperCase()}
                  {": "}
                  {currentAccount.amount}
                </div>
              </div>

              <div className="field w-full">
                <div className="field__wrap field__wrap-select">
                  <InputThemeField
                    classWrapper="field w-full"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={t('Amount')}
                    value={values.amount}
                    onChange={(e) => {
                      setValues({...values, amount: e.target.value});
                    }}
                  />
                </div>
              </div>

              <div className="field w-full">
                <div className="field__wrap field__wrap-select">
                  <InputThemeField
                    classWrapper="field w-full"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={t('Reference / Memo')}
                    value={values.memo}
                    onChange={(e) => {
                      setValues({...values, memo: e.target.value});
                    }}
                  />
                </div>
              </div>

              <div className="field w-full">
                <div className="field__label">
                  {t('RECIPIENT DETAILS')}
                </div>

                <div className="field__wrap d__flex flex__row flex__wrap">
                  <RadioTheme
                    isGrayText
                    isRadioGroupHorizontal
                    value="individual"
                    radioText={t('Individual')}
                    defaultChecked={values.sendType === 'individual'}
                    onChange={({ target: { value } }) =>
                      setValues({ ...values, sendType: value })
                    }
                  />

                  <RadioTheme
                    isGrayText
                    isRadioGroupHorizontal
                    value="company"
                    radioText={t('Company')}
                    defaultChecked={values.sendType === 'company'}
                    onChange={({ target: { value } }) =>
                      setValues({ ...values, sendType: value })
                    }
                  />
                </div>

                <br />

                <div className="field__wrap field__wrap-select">
                  <InputThemeField
                    classWrapper="field w-full"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={t('IBAN')}
                    value={values.iban}
                    onChange={(e) => {
                      setValues({...values, iban: e.target.value});
                    }}
                  />
                </div>
              </div>

              <div className="field w-full">
                <div className="field__label">
                  {t('Country')}
                  <span className="text__red"> *</span>
                </div>

                <div className="field__wrap field__wrap-select">
                  <TextField
                    select
                    value={values.country}
                    onChange={(e) => {
                      setValues({...values, country: e.target.value});
                    }}
                    fullWidth
                  >
                    {Object.keys(COUNTRY_BY_ALPHA_2_CODE).map((country) => (
                      <MenuItem key={country} value={COUNTRY_BY_ALPHA_2_CODE[country]}>
                        {country}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>

              { values.sendType === 'individual' && (
                <>
                  <div className="field w-full">
                    <div className="field__wrap field__wrap-select">
                      <InputThemeField
                        classWrapper="field w-full"
                        classLabel="field__label"
                        classInput="field__input"
                        required
                        label={t('First name')}
                        value={values.firstName}
                        onChange={(e) => {
                          setValues({...values, firstName: e.target.value});
                        }}
                      />
                    </div>
                  </div>

                  <div className="field w-full">
                    <div className="field__wrap field__wrap-select">
                      <InputThemeField
                        classWrapper="field w-full"
                        classLabel="field__label"
                        classInput="field__input"
                        required
                        label={t('Last name')}
                        value={values.lastName}
                        onChange={(e) => {
                          setValues({...values, lastName: e.target.value});
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              { values.sendType === 'company' && (
                <>
                  <div className="field w-full">
                    <div className="field__wrap field__wrap-select">
                      <InputThemeField
                        classWrapper="field w-full"
                        classLabel="field__label"
                        classInput="field__input"
                        required
                        label={t('Company name')}
                        value={values.companyName}
                        onChange={(e) => {
                          setValues({...values, companyName: e.target.value});
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="field">
                <button
                  className="button-stroke"
                  onClick={placeOrder}
                  disabled={loading}
                >
                  {t('Confirm')}
                </button>
              </div>

              {loading && (
                <CircularProgress />
              )}
            </div>
          </div>

          <button
            className="popup__close js-popup-close"
            onClick={() => setIsOpenModal(false)}
          >
            <Icon
              className={classNames('close', 'icon-24')}
              name="close"
              size="24"
            />
          </button>
        </div>
      </div>
    </Dialog>
  );
};

const MoneriumAccount = ({authToken, currentAccount, setCurrentAccount}) => {
  const { t } = useTranslation()
  const [transactionHistory, setTransactionHistory] = useState(null)
  const [loading, setLoading] = useState(null)
  const [isOpenModal, setIsOpenModal] = useState(false)
  // const styles = useStyles()

  const renderExplorerLink = (network, hash) => {
    const shortHash = `${hash.slice(0, 6)}...${hash.slice(-4, hash.length)}`

    if (hash.includes('https')) {
      return (
        <a href={hash} target="_blank" rel="noopener noreferrer">
          {hash}
        </a>
      )
    }

    switch (network) {
    case 'mainnet':
      return (
        <a href={`https://etherscan.io/tx/${hash}`} target="_blank" rel="noopener noreferrer">
          {shortHash}
        </a>
      )

    case 'sepolia':
      return (
        <a href={`https://sepolia.etherscan.io/tx/${hash}`} target="_blank" rel="noopener noreferrer">
          {shortHash}
        </a>
      )

    case 'polygon':
      return (
        <a href={`https://polygonscan.com/tx/${hash}`} target="_blank" rel="noopener noreferrer">
          {shortHash}
        </a>
      )

    case 'mumbai':
      return (
        <a href={`https://mumbai.polygonscan.com/tx/${hash}`} target="_blank" rel="noopener noreferrer">
          {shortHash}
        </a>
      )

    case 'xinfin':
      return (
        <a href={`https://explorer.xinfin.network/txs/${hash}`} target="_blank" rel="noopener noreferrer">
          {shortHash}
        </a>
      )

    case 'apothem':
      return (
        <a href={`https://explorer.apothem.network/txs/${hash}`} target="_blank" rel="noopener noreferrer">
          {shortHash}
        </a>
      )

    default:
      return `${hash} (${network})`
    }
  }

  const fetchTransactionHistory = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/orders?accountId=${currentAccount.id}`,
        {
          headers: {
            "Authorization": `Bearer ${authToken}`,
          },
        },
      )

      setTransactionHistory(response.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (currentAccount && currentAccount.id) {
      setLoading(true)
      fetchTransactionHistory()
    }
    // eslint-disable-next-line
  }, [currentAccount])

  return (
    <>
      <ConfirmApprovedTransactionModal
        currentAccount={currentAccount}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        fetchTransactionHistory={fetchTransactionHistory}
        authToken={authToken}
      />

      <div className="page__title page__title-grid h3">
        {currentAccount.currency.toUpperCase()}
        {": "}
        {currentAccount.amount}

        <div className="page__title-helper">
          <button type="button" className='button button-xs' onClick={() => setCurrentAccount(null)}>
            <Icon
              className={classNames('arrow-left')}
              name='arrow-left'
              size='24'
            />{' '}
            {t('Back')}
          </button>
        </div>
      </div>

      {loading && (
        <CircularProgress />
      )}

      <div className="card">
        <div className="card__head">
          <div className="title-clear card__title">
            {t('Transaction history')}
          </div>

          <button type="button" className='button-small button-stroke' onClick={() => setIsOpenModal(true)}>
            {t('Send')}
          </button>
        </div>

        <div className="earning__table-card__wrapper">
          {transactionHistory && transactionHistory.map((transaction) => {
            const { details, identifier } = transaction.counterpart
            const isIssued = transaction.kind === 'issue'

            return (
              <div className="earning__table earning__table-card earning__table-card_nohover" key={transaction.id}>
                <div className="earning__row">{' '}</div>
                <div className="earning__row">
                  <div className="earning__col">
                    {identifier.standard}
                  </div>
                  <div className="earning__col">
                    <div className="earning__label">
                      {identifier.standard}
                    </div>
                    {identifier.iban}
                  </div>
                </div>
                <div className="earning__row">
                  <div className="earning__col">
                    {t('Name')}
                  </div>
                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Name')}
                    </div>
                    {details.name}
                  </div>
                </div>
                <div className="earning__row">
                  <div className="earning__col">
                    {t('Reference / Memo')}
                  </div>
                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Reference / Memo')}
                    </div>
                    {transaction.memo}
                  </div>
                </div>
                <div className="earning__row">
                  <div className="earning__col">
                    {t('Transaction')}
                  </div>
                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Transaction')}
                    </div>
                    {transaction.txHashes[0] && renderExplorerLink(transaction.network, transaction.txHashes[0])}
                  </div>
                </div>
                <div className="earning__row">
                  <div className="earning__col">
                    {t('Date and Time')}
                  </div>
                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Date and Time')}
                    </div>
                    {transaction.meta.placedAt}
                  </div>
                </div>
                <div className="earning__row">
                  <div className="earning__col">
                    {isIssued ? t('Amount received') : t('Amount sent from wallet')}
                  </div>
                  <div className="earning__col">
                    <div className="earning__label">
                      {isIssued ? t('Amount received') : t('Amount sent from wallet')}
                    </div>
                    {transaction.amount}
                  </div>
                </div>
                <div className="earning__row">
                  <div className="earning__col">
                    {t('Monerium fee')}
                  </div>
                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Monerium fee')}
                    </div>
                    {transaction.totalFee}
                  </div>
                </div>
                <div className="earning__row">
                  <div className="earning__col">
                    {isIssued ? t('You get') : t('Sent to IBAN')}
                  </div>
                  <div className="earning__col">
                    <div className="earning__label">
                      {isIssued ? t('You get') : t('Sent to IBAN')}
                    </div>
                    {transaction.amount - +(isIssued ? transaction.totalFee : -transaction.totalFee)}
                  </div>
                </div>
                <div className="earning__row">
                  <div className="earning__col">
                    {t('Status')}
                  </div>
                  <div className="earning__col">
                    <div className="earning__label">
                      {t('Status')}
                    </div>
                    {transaction.meta.state === 'processed' ? t('Completed') : transaction.meta.state }
                  </div>
                </div>
              </div>
              )
          })}
        </div>
      </div>
    </>
  )
}

export default MoneriumAccount
