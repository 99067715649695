import React, { useState } from 'react'
import cn from 'classnames'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { useTranslation } from 'react-i18next'
import Icon from '../Icon'

const NotificationBar = () => {
  const { t } = useTranslation();

  const [values, setValues] = useState({
    isPopupNotificationActive: false,
  });

  const onPopupNotificationActive = () => {
    setValues({...values, isPopupNotificationActive: !values.isPopupNotificationActive });
  }

  const onPopupNotificationAwayActive = () => {
    setValues({...values, isPopupNotificationActive: false });
  }

  return (
    <>
      <ClickAwayListener onClickAway={onPopupNotificationAwayActive}>
        <div className={`header__item header__item_notifications ${values.isPopupNotificationActive ? "active" : ""}`}>
          <button
            className="header__head"
            onClick={onPopupNotificationActive}
          >
            <Icon className={cn('notification', 'icon-24')} name="notification" size="24" />
          </button>

          <div className="header__body">
            <div className="header__top">
              <div className="header__title">{ t('Notification') }</div>

              <div className="actions actions__small" style={{display: "none"}}>
                <button className="actions__button">
                  <Icon className={cn('more-horizontal', 'icon-24')} name="more-horizontal" size="24" />
                </button>
              </div>
            </div>

            <div className="header__list">{' '}</div>
            <button className="button header__button disabled">
              { t('See all notifications (coming soon)')}
            </button>
          </div>
        </div>
      </ClickAwayListener>
    </>
  );
}

export default NotificationBar;
