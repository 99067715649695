import gql from 'graphql-tag';

export const UPDATE_SENDWYRE_WALLET_ID  = gql`
  mutation sendwyreWalletId($input: String) {
    sendwyreWalletId(input: $input)
  }
`;

export const GET_SENDWYRE_ACCOUNT = gql`
  mutation getSendwyreAccount($input: AccountIdInput) {
    getSendwyreAccount(input: $input)
  }
`;

export const CREATE_SENDWYRE_WALLET = gql`
  mutation createSendwyreWallet($input: SendWyreWalletInput) {
    createSendwyreWallet(input: $input)
  }
`;

export const LOOKUP_SENDWYRE_WALLET = gql`
  mutation lookupSendwyreWallet($input: WalletIdInput) {
    lookupSendwyreWallet(input: $input)
  }
`;

export const CREATE_SENDWYRE_WALLET_ORDER_RESERVATION = gql`
  mutation createWalletOrderReservation($input: WalletOrderReservationInput) {
    createWalletOrderReservation(input: $input)
  }
`;

export const CREATE_SENDWYRE_TRANSFER = gql`
  mutation createSendwyreTransfer($input: TransferInput) {
    createSendwyreTransfer(input: $input)
  }
`;

export const LOOKUP_TRANSFER_ID = gql`
  mutation lookupTransferId($input: TransferIdInput) {
    lookupTransferId(input: $input)
  }
`;