import gql from "graphql-tag";

export const GET_INTERNAL_LIVENESS_CHECK_APPLICANT = gql`
  query getInternalLivenessCheckApplicant($applicant_uuid: String!) {
    getInternalLivenessCheckApplicant(applicant_uuid: $applicant_uuid) {
      id
      uuid
      status
    }
  }
`;

export const GET_INTERNAL_LIVENESS_CHECK_APPLICANT_FOR_DIRECTOR = gql`
  query getInternalLivenessCheckApplicantForDirector($director_id: Int!) {
    getInternalLivenessCheckApplicantForDirector(director_id: $director_id) {
      id
      uuid
      status
    }
  }
`;

export const GET_INTERNAL_LIVENESS_CHECK_APPLICANT_BY_ACCESS_TOKEN = gql`
  query getInternalLivenessCheckApplicantByAccessToken($access_token: String!) {
    getInternalLivenessCheckApplicantByAccessToken(access_token: $access_token) {
      livenessCheckApplicant {
        id
        uuid
        status
      }
      challenge {
        action
        arguments
      }
    }
  }
`;
