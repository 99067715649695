import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { useMe } from '../myHooks'
import KycCustomerVideoIdentIdnow from '../components/KycCustomerVideoIdentIdnow'

const IdnowIdentPage = () => {
  const { data: { me } = {}, loading } = useMe({ fetchPolicy: 'network-only'})

  return (
    <>
      <h1>Liveness Check with IDnow AutoIdent</h1>

      <div className="card">
        {loading && (
          <CircularProgress />
        )}
        {me && me.customer && (
          <KycCustomerVideoIdentIdnow customer={me.customer} />
        )}
      </div>
    </>
  )
}

export default IdnowIdentPage
