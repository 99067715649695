import gql from 'graphql-tag';

export const SSO_AUTH = gql`
  query ssoAuth ($token: String!, $two_fa_token: String, $allow_sync_data: String) {
    ssoAuth (token: $token, two_fa_token: $two_fa_token, allow_sync_data: $allow_sync_data) {
      jwt {
        accessToken
        refreshToken
      }
      two_fa_required
    }
  }
`;

export const GET_AUTH_TENANT_LIST = gql`
  query getAuthTenantList {
    getAuthTenantList
  }
`;


export const GET_PREFILL_KYC_INFO = gql`
  query getPrefillKycInfo {
    getPrefillKycInfo
  }
`;
