import React, { useState } from 'react'
import reduce from 'lodash/reduce'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputTheme from "../InputTheme";
import { useTranslation } from 'react-i18next';

const SETS = {
  name: {
    label: 'Company name',
  },
  email: {
    label: 'Company email',
  },
  address: {
    label: 'Company address',
  },
  phoneNumber: {
    label: 'Company phone number',
  },
}

const AdminSettingsCompany = ({
  companySettings,
  loading,
  error,
  onUpdate,
  editingAllowed,
}) => {
  const { t } = useTranslation()

  const [values, setValues] = useState({
    ...reduce(
      SETS,
      (memo, value, key) => {
        // eslint-disable-next-line no-param-reassign
        memo[key] = (companySettings && companySettings[key]) || "";
        return memo;
      },
      {}
    ),
  })

  const onUpdateBtnClick = () => {
    onUpdate({ company: values })
  }

  return (
    <>
      <div className="settings__item">
        <div className="title-primary settings__title">
          { t('Company Settings') }
        </div>
        <div className="settings__fieldset">
          <div className="settings__row flex__row flex__wrap have__fields">
            {Object.keys(values).map((item) => {
              return (
                <InputTheme
                  key={item}
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName={item}
                  label={SETS[item].label}
                  state={values}
                  setState={setValues}
                  disabled={!editingAllowed}
                  error={error}
                  loading={loading}
                />
              );
            })}
          </div>
        </div>
      </div>
      {loading ? (
        <div className="settings__item">
          <CircularProgress />
        </div>
      ) : (
        <button
          className="button settings__button"
          disabled={!editingAllowed}
          onClick={onUpdateBtnClick}
        >
          { t('Update') }
        </button>
      )}
    </>
  )
}

AdminSettingsCompany.propTypes = {
  companySettings: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
}

export default AdminSettingsCompany
