import React from 'react'
// import "../styles/legacy/style.scss";
import { use100vh } from 'react-div-100vh'
import LoginRegisterLogo from '../components/LogoLoginRegister'
import AdminActivationForm from '../components/AdminActivationForm'
import { isIE } from "react-device-detect"
import NotSupportViewer from '../components/NotSupportViewer'

const AdminActivation = () => {
  const heightWindow = use100vh();

  if (isIE) {
    return <NotSupportViewer />;
  }

  return (
    <div style={{ minHeight: heightWindow }} className="entry entry_loginregister">
      <div className="entry__wrapper entry__wrapper-kyc">
        <LoginRegisterLogo />

        <AdminActivationForm />
      </div>
    </div>
  );
};

export default AdminActivation;
