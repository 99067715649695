import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const ADMIN_SEND_MESSAGE = gql`
  mutation adminSendMessage($message: String!, $to: [String!]!) {
    adminSendMessage(message: $message, to: $to)
  }
`

export const REQUEST_ACCESS_TOKEN = gql`
  mutation requestAccessToken($wallet: String!, $message: String!, $signature: String!) {
    requestAccessToken(wallet: $wallet, message: $message, signature: $signature)
  }
`
