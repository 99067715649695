import React from 'react'
import { getFileUrl } from '../../../utils'
import { useTranslation } from 'react-i18next'
import { usePublicSettings } from '../../../myHooks/useSettings'

const SidebarPanel = () => {
  const { t } = useTranslation();
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { company = {} } = publicSettings || {};
  const currentHost = window.location.host;
  const isQdev = currentHost === 'kyc.qdev.li' || currentHost === 'qdevelopment-dev.investhub.io' || currentHost === 'localhost:3000';

  return (
    <div className="login__wrap">
      <div className="login__preview">
        <img
          src={publicSettings && getFileUrl(publicSettings.logo_path)}
          alt="Easy Onboarding"
        />
      </div>
      <div className="h4 login__subtitle">{ isQdev ? t('Validator Onboarding') : t('Easy Onboarding') }</div>
      <ul className="login__list">
        <li>
          { t('Easy authentication with SMS') }
        </li>
        <li>
          { t('Chat button') }
        </li>
        <li>
          { t('Liveness check')}
        </li>
        <li>
          { t('Easy user interface') }
        </li>
      </ul>

      <div className="login__copyright">
        <span>&copy; {new Date().getFullYear()} {(company || {}).name}</span>
      </div>
    </div>
  )
}

export default SidebarPanel;
