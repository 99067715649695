import React from 'react'
import { usePublicSettings } from '../myHooks/useSettings'
import { getExternalFileUrl } from '../utils'
import { useTranslation } from 'react-i18next'
import SidebarOuter from '../components/SidebarOuter'
import { isIE } from "react-device-detect"
import NotSupportViewer from '../components/NotSupportViewer'

import '../styles/app.min.css'
import '../styles/style.css'

const RightOfWithdrawals = () => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { company = {} } = publicSettings;
  const { t } = useTranslation();

  if (isIE) {
    return <NotSupportViewer />;
  }

  return (
    <div className="page pt-0">
      <SidebarOuter />

      <div className="page__inner">
        <div className="page__container">
          <div className="product__wrapper product__wrapper_v2">
            <div className="product__details">
              <div className="product__overview">
                <div className="product__title h4">
                { t('Right of Withdrawals') }
                </div>

                <div className="product__row">
                  <div className="product__col pr__0">
                    <div className="product__content">
                      <p>{ t('You can withdraw from this contract declaration within 14 days without giving a reason by means of a clear statement. The period begins on receipt of this instruction on a durable medium, but not before the conclusion of the contract and also not before fulfilment of our information obligations pursuant to Art. 5 DMFSA. In order to safeguard the withdrawal period, the timely dispatch of the withdrawal declaration is sufficient if the declaration is made on a durable medium (e.g. letter, fax, email). The withdrawal is to be addressed to') }:</p>
                      <p>
                        {(company || {}).name} <br />
                        {(company || {}).address} <br />
                        {(company || {}).email} <br />
                      </p>

                      <p>
                        { t('Please refer to to the') }
                        {' '}
                        {publicSettings.rights_of_withdrawal_path
                          ? (<a href={getExternalFileUrl(publicSettings.rights_of_withdrawal_path)} rel="noopener noreferrer" target="_blank">{ t('Sample Withdrawal Form') }</a>)
                          : ('Sample Withdrawal Form')}
                        {" "}
                        { t('that can be used for the declaration of withdrawal.') }
                      </p>
                    </div>

                    <div className="title-primary product__subtitle">
                      { t('Withdrawal Consequences') }
                    </div>

                    <div className="product__content">
                      <p> { t('In the case of an effective withdrawal, the mutually received benefits must be returned. You are obliged to pay compensation for the service provided until the cancellation, if you were informed of this legal consequence prior to the submission of your contract declaration and if you expressly agreed that we will commence execution of the counter-performance before the end of the withdrawal period. If there is an obligation to pay compensation, this may mean that you still have to fulfil the contractual payment obligations for the period up to the withdrawal. Your right of withdrawal expires prematurely if the contract is completely fulfilled by both parties on your express wish, before you have exercised your right of withdrawal. Obligations to reimburse payments must be fulfilled within 30 days. The period begins for you with the sending of your resignation, for us with their receipt.') }</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightOfWithdrawals;
