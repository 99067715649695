import React from "react";
import KycLevelBox from "./KycLevelBox";
import { useTranslation } from 'react-i18next';
import { useMe} from '../../../myHooks';

const KycAccountLevel = () => {
  const { t } = useTranslation();
  const {
    data: { me: userData } = {},
  } = useMe();

  return (
    <div className="card">
      <div className="card__head">
        <div className="title-primary card__title">
          {userData.customer && (
            <>
              {t('Kyc Account Level')}
            </>
          )}
          {userData.customerCompany && (
            <>
              {t('KYB Account Level')}
            </>
          )}
        </div>
      </div>

      <KycLevelBox />
    </div>
  )
};

export default KycAccountLevel;
