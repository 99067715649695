import React, { useEffect } from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import ApplicationIndividual from '../../media/images/individual.png'
import ApplicationCompany from '../../media/images/company.png'
import PackageItem from '../KycVerification/PackageItem'
import KycStatusLog from '../../components/KycStatusLog'

import { USER_KYC_STATUS_TYPES } from '../../constants/user'
import { useMe } from '../../myHooks'
import { usePublicSettings } from '../../myHooks/useSettings'
import { useTranslation } from 'react-i18next'

const KycVerification = () => {
  const { t } = useTranslation()
  const { data } = useMe()
  const history = useHistory()

  const { data: { publicSettings = {} } = {} } = usePublicSettings()

  useEffect(() => {
    if (publicSettings) {
      if (!publicSettings.kyc_enable_kyb) {
        if (publicSettings.onboarding_type === 'easy') {
          if (data.me.phone_verified) {
            history.push('/easy-onboarding')
          } else {
            history.push('/easy-onboarding-2fa')
          }
        }
      }
    }

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicSettings])

  if (data && data.me && data.me.kyc_status !== USER_KYC_STATUS_TYPES.NOT_SUBMITTED) {
    const isCompleteLivenessCheck = data.me.customer &&
      data.me.customer.onfidoApplicant &&
      data.me.customer.onfidoApplicant.onfidoChecks.length > 0 &&
      data.me.customer.onfidoApplicant.onfidoChecks.filter(checks => checks.onfidoReports.length > 1).length > 0

    return (
      <div className="card">
        <div className="h3 entry__title">
          { t('Thank you for your Submission')}
        </div>

        {data.me.customer && (
          <KycStatusLog />
        )}

        <NavLink to="/" className="button-stroke" activeClassName="">Back to Dashboard</NavLink>
        {' '}
        {data.me.customer && !isCompleteLivenessCheck && (
          <a
            href="/liveness-check-portal"
            target="_blank"
            rel="noopener noreferrer"
            className="button text__center"
          >
            {t('Liveness check not started')}
          </a>
        )}
      </div>
    )
  }

  return (
    <>
      {!(
          data &&
          data.me &&
          data.me.kyc_status !== USER_KYC_STATUS_TYPES.NOT_SUBMITTED
        ) && (
          <>
            <div className="h3 nospace__bottom">{ t('KYC Application') }</div>
            <div className="shop__info">{t('Start your KYC process, it only takes up to 5 minutes.')}</div>
            <div className="page__title">{' '}</div>

            <div className="packages">
              <div className="packages__group">

                <PackageItem
                  onClick={() => {
                    history.push('/transfer-claim/guide')
                  }}
                  classTitle="title-primary"
                  title={t('I still own Envion Tokens')}
                  content={ t('We will explain you, how you can still get compensated for them.') }
                  imgPath={ApplicationIndividual}
                  buttonPackage={ t('Continue') }
                />

                <PackageItem
                  onClick={() => {
                    history.push('/transfer-claim/claim-with-liquidator')
                  }}
                  classTitle="title-primary"
                  title={t('I sent the Envion Tokens to the liquidator')}
                  content={ t('You can transfer your claim to us and get compensated.') }
                  imgPath={ApplicationCompany}
                  buttonPackage={ t('Transfer claim') }
                />

                <PackageItem
                  onClick={() => {
                    history.push('/transfer-claim/claim-with-credit-card')
                  }}
                  classTitle="title-primary"
                  title={t('I paid with Creditcard')}
                  content={ t('Please upload the supporting documents in your next step.') }
                  imgPath={ApplicationIndividual}
                  buttonPackage={ t('Transfer claim') }
                />

                <PackageItem
                  onClick={() => {
                    history.push('/transfer-claim/claim-with-bitcoin')
                  }}
                  classTitle="title-primary"
                  title={t('I paid with Bitcoin')}
                  content={ t('Make sure you have your transaction hash ready. As proof of payment') }
                  imgPath={ApplicationCompany}
                  buttonPackage={ t('Transfer claim') }
                />
              </div>
            </div>
          </>
        )
      }
    </>
  )
}

export default KycVerification
