import React, { useState } from "react";
import reduce from "lodash/reduce";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputTheme from "../InputTheme";
import { useTranslation } from 'react-i18next';

const SETS = {
  eth_address: {
    label: 'Eth address',
  },
  usdc_address: {
    label: 'USDC address',
  },
  usdt_address: {
    label: 'USDT address',
  },
  xdc_address: {
    label: 'XDC address',
  },
  polygon_address: {
    label: 'Polygon address',
  },
  dai_address: {
    label: 'DAI address',
  },
};

const KYC_ACCEPTED_WALLETS_SETS = {
  eth: {
    default: false,
  },
  ixo_id: {
    default: false,
  },
  cosmos: {
    default: false,
  },
  btc: {
    default: false,
  },
  usdt: {
    default: false,
  },
  xdc: {
    default: false,
  },
  polygon: {
    default: false,
  },
  dai: {
    default: false,
  },
  evm_compatible_wallet: {
    default: false,
  },
  other: {
    default: false,
  },
};

const KYC_VALIDATE_WALLETS_SETS = {
  ixo_id: {
    default: '',
  },
};

const AdminSettingsCrypto = ({
  cryptoSettings,
  kycAcceptedWallets,
  kycValidateWallets,
  loading,
  error,
  onUpdate,
  editingAllowed,
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    ...reduce(
      SETS,
      (memo, value, key) => {
        // eslint-disable-next-line no-param-reassign
        memo[key] = (cryptoSettings && cryptoSettings[key]) || "";
        return memo;
      },
      {}
    ),
  });

  const [kycAccpetedWalletValues, setKycAccpetedWalletValues] = useState({
    ...reduce(
      KYC_ACCEPTED_WALLETS_SETS,
      (memo, value, key) => {
        // eslint-disable-next-line no-param-reassign
        memo[key] =
          (kycAcceptedWallets && kycAcceptedWallets[key]) ||
          KYC_ACCEPTED_WALLETS_SETS[key].default;
        return memo;
      },
      {}
    ),
  });

  const [kycValidateWalletValues, setKycValidateWalletValues] = useState({
    ...reduce(
      KYC_VALIDATE_WALLETS_SETS,
      (memo, value, key) => {
        // eslint-disable-next-line no-param-reassign
        memo[key] =
          (kycValidateWallets && kycValidateWallets[key]) ||
          KYC_VALIDATE_WALLETS_SETS[key].default;
        return memo;
      },
      {}
    ),
  });

  const onChangeCheckbox = (name, state, setState) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const onUpdateBtnClick = () => {
    onUpdate({
      crypto: values,
      kyc_accepted_wallets: kycAccpetedWalletValues,
      kyc_validate_wallets: kycValidateWalletValues,
    });
  };

  return (
    <>
      <div className="settings__item">
        <div className="title-primary settings__title">{ t('Crypto Settings') }</div>
        <div className="settings__fieldset">
          <div className="settings__row flex__row flex__wrap">
            {Object.keys(values).map((item) => {
              return (
                <InputTheme
                  key={item}
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName={item}
                  label={SETS[item].label}
                  state={values}
                  setState={setValues}
                  error={error}
                  disabled={!editingAllowed}
                  loading={loading}
                />
              );
            })}
          </div>

          <div className="field">
            <div className="field__wrap checkbox__wrapper field__wrap checkbox__wrapper d__flex flex__row flex__wrap">
              {Object.keys(kycAccpetedWalletValues).map((wallet) => (
                <label key={wallet} className="checkbox">
                  <input
                    type="checkbox"
                    className="checkbox__input"
                    checked={kycAccpetedWalletValues[wallet]}
                    value={wallet}
                    onChange={onChangeCheckbox(
                      wallet,
                      kycAccpetedWalletValues,
                      setKycAccpetedWalletValues
                    )}
                  />

                  <div className="checkbox__inner">
                    <div className="checkbox__tick">{' '}</div>
                    <div className="checkbox__text">{`Accept ${wallet} wallet`}</div>
                  </div>
                </label>
              ))}
            </div>
          </div>

          {Object.keys(kycValidateWalletValues).map((item) => (
            kycAccpetedWalletValues[item] ?
            <InputTheme
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              propertyName={item}
              label={`${item} validate URL`}
              state={kycValidateWalletValues}
              setState={setKycValidateWalletValues}
              key={item}
            />
            : null
          ))}
        </div>
      </div>

      {loading ? (
        <div className="settings__item">
          <div className="settings__row flex__row flex__wrap">
            <CircularProgress />
          </div>
        </div>
      ) : (
        <button
          className="button settings__button"
          disabled={!editingAllowed}
          onClick={onUpdateBtnClick}
        >
          { t('Update') }
        </button>
      )}
    </>
  );
};

AdminSettingsCrypto.propTypes = {
  cryptoSettings: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

export default AdminSettingsCrypto;
