import React from 'react'
import Uploads from './Uploads'
import { useTranslation } from 'react-i18next'
import imgHero from '../../media/images/bg-shop.jpeg'

const DataCenter = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="shop shop__products_2_column">
        <div className="shop__background">
          <img
            src={imgHero}
            alt={ t("Data Center") }
          />
        </div>

        <div className="shop__tabs card">
          <div className="shop__container">
            <Uploads />
          </div>
        </div>
      </div>
    </>
  );
}

export default DataCenter;
