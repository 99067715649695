import gql from 'graphql-tag'

export const PUBLIC_UPLOADS = gql`
  query publicUploads {
    publicUploads {
      id
      storage_key
      preview_storage_key
      mime_type
      original_name
      is_public
    }
  }
`;

export const SUPPORTED_SOURCE_OF_FUND_DOCUMENTS = gql`
  query supportedSourceOfFundDocuments($userId: Int!) {
    supportedSourceOfFundDocuments(userId: $userId) {
      id
      storage_key
      preview_storage_key
      mime_type
      original_name
      is_public
      user
      uploader
      created_at
    }
  }
`
