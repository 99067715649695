import React from 'react'
import moment from 'moment-timezone'
import { useQuery, useMutation } from '@apollo/react-hooks'

import {
  Card,
  CardContent,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next'
import {
  GET_LATEST_AUTO_APPROVAL_LOG,
  RECHECK_AUTO_APPROVAL_LOG,
} from '../queriesAndMutations'

const AdminKycAutoApprovalLog = ({
  publicSettings,
  customerId,
}) => {
  const { t } = useTranslation()
  const { data: { getLatestAutoApprovalLog: data } = {}, loading, refetch } = useQuery(GET_LATEST_AUTO_APPROVAL_LOG, {
    variables: {
      customerId,
    },
  })
  const [recheckAutoApprovalLog, recheckAutoApprovalLogStatus] = useMutation(RECHECK_AUTO_APPROVAL_LOG);

  const renderFailedStatus = () => {
    if (publicSettings && publicSettings.enable_auto_approve_kyc) {
      return <span className="status-red">{t('Failed')}</span>
    }

    return <span className="status-orange-dark">{t('Consider')}</span>
  }

  const onRecheckAutoApprovalLog = () => {
    recheckAutoApprovalLog({
      variables: {
        customerId,
      }
    }).then(() => {
      // toaster.success("Success")
      refetch();
    }).catch(() => {})
  }

  if (loading) {
    return <CircularProgress />
  }

  if (!data) {
    return null;
  }

  return (
    <div className="card">
      <div className="card__head">
        <div className="title-clear card__title">
          {publicSettings && publicSettings.enable_auto_approve_kyc ? t('Latest Auto Approval Log') : t('Checklist Log')}
        </div>
      </div>
      <div className="earning__table earning__table-rwd">
        <div className="earning__row">
          <div className="earning__col">
            {t('Created A')}
          </div>
          <div className="earning__col">
            {t('Liveness Check')}
          </div>
          <div className="earning__col">
            {t('Watchlist')}
          </div>
          <div className="earning__col">
            {t('Data Comparison')}
          </div>
          <div className="earning__col">
            {t('Risk Evaluation')}
          </div>
          <div className="earning__col">
            {t('Result')}
          </div>
        </div>

        <div className="earning__row">
          <div className="earning__col">
            <div className="earning__label">
              {t('Created A')}
            </div>
            {moment().tz('Europe/Vaduz').format('YYYY-MM-DD H:m z')}
          </div>

          <div className="earning__col">
            <div className="earning__label">
              {t('Liveness Check')}
            </div>
            { data.isCheckPassed ? <span className="status-green-dark">{t('Passed')}</span> : renderFailedStatus()}
          </div>

          <div className="earning__col">
            <div className="earning__label">
              {t('Watchlist')}
            </div>
            { data.isWatchlistPassed ? <span className="status-green-dark">{t('Passed')}</span> : renderFailedStatus()}
          </div>

          <div className="earning__col">
            <div className="earning__label">
              {t('Data Comparison')}
            </div>
            { data.isDataPassed ? <span className="status-green-dark">{t('Passed')}</span> : renderFailedStatus()}
          </div>

          <div className="earning__col">
            <div className="earning__label">
              {t('Risk Evaluation')}
            </div>
            { data.isRiskPassed ? <span className="status-green-dark">{t('Passed')}</span> : renderFailedStatus()}
          </div>

          <div className="earning__col">
            <div className="earning__label">
              {t('Result')}
            </div>

            {publicSettings && publicSettings.enable_auto_approve_kyc && (
              data.result === 'clear'
                ? <span className="status-green-dark">{t('Accept to Auto Approved')}</span>
                : <span className="status-red">{t('Rejected to Auto Approved')}</span>
            )}
            <div style={{ display: "block" }}>
              <div className="refunds__category refunds__category-important">
                {t('Reasons')}
              </div>
              { data.result === 'reject' && (
                <>
                  <ul style={{ listStyle: "circle", paddingLeft: "16px", marginTop: "8px" }}>
                    {data.rejectReasons.map(reason => <li key={reason}>- {reason}</li>)}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Card>
        <CardContent>
          <div className='field'>
            <button
              className="button"
              onClick={onRecheckAutoApprovalLog}
              disabled={recheckAutoApprovalLogStatus.loading}
            >
              { t('Recheck Log') }
            </button>
        </div>
        </CardContent>
      </Card>

    </div>
  )
}

export default AdminKycAutoApprovalLog
