import React from 'react'
import { useTranslation } from 'react-i18next'
import SidebarOuter from '../components/SidebarOuter'
import { usePublicSettings } from '../myHooks/useSettings'
import { isIE } from "react-device-detect"
import NotSupportViewer from '../components/NotSupportViewer'

import '../styles/app.min.css'
import '../styles/style.css'

const Policy = () => {
  const { t } = useTranslation();
  const { data: { publicSettings: { company = {} } = {} } = {} } =
    usePublicSettings();

  if (isIE) {
    return <NotSupportViewer />;
  }

  return (
    <div className="page pt-0">
      <SidebarOuter />

      <div className="page__inner">
        <div className="page__container">
          <div className="product__wrapper product__wrapper_v2">
            <div className="product__details">
              <div className="product__overview">
                <div className="product__title h4">
                  { t('Privacy Policy') }
                </div>

                <div className="product__row">
                  <div className="product__colr pr__0">
                    <div className="product__content">
                    <>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The protection of your personal data is important to us. Therefore, we
                          collect, process and use personal data only in accordance with the
                          applicable data protection and data security laws. The following
                          information is provided for your information.&nbsp;
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Please note that third party services are used for the operation of the
                          Token Issuer's platform and their data protection provisions apply
                          separately.
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>1. Data protection officer</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>{company.name}</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>{company.address}</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>{company.phoneNuber}</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          <a href="mailto:help@litigation-financing.io">
                            {company.email}
                          </a>
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          <a href="http://www.litigation-financing.io">
                            {window.location.host}
                          </a>
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>2. Personal data</strong>
                      </p>
                      <p>
                        We collect, process and use your personal data only with your consent or in
                        the context of contract performance or if there is another legal basis in
                        accordance with the provisions of data protection law.
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Only such personal data is collected that is necessary for the
                          implementation and processing or that you have voluntarily provided to us.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Personal data is any data that contains individual information about
                          personal or factual circumstances, for example, name, address, telephone
                          number, e-mail address, log-in information, wallet address, identification
                          number, tax number, citizenship, date of birth, place of birth, company
                          registration number, date of incorporation,{" "}
                        </span>
                        <span style={{ fontWeight: 400 }}>
                          registered office address, country of domicile.
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>3. Your rights</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          As a platform user and subsequently as a token acquirer, you have the
                          right at any time to obtain information about your stored personal data,
                          its origin and recipients, as well as the purpose of data processing, as
                          well as the right to rectification, data transfer, objection, restriction
                          of processing and blocking or deletion of incorrect or inadmissibly
                          processed data.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          If there are any changes to your personal data, please inform us
                          accordingly.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          You have the right at any time to revoke any consent given for the use of
                          your personal data. Your input for information, deletion, correction,
                          objection and/or data transfer, in the latter case, provided that this
                          does not cause a disproportionate effort, can be directed to us.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          If you are of the opinion that the processing of your personal data by us
                          violates applicable data protection law or that your data protection
                          rights have been violated in some other way, you have the option of
                          complaining to the competent supervisory authority. In Liechtenstein, the
                          data protection authority (www.datenschutzstelle.li) is responsible for
                          this.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>&nbsp;</span>
                      </p>
                      <p>
                        <strong>4. Data security</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Your personal data is protected by appropriate organizational and
                          technical measures. These measures relate in particular to protection
                          against unauthorized, unlawful or even accidental access, processing,
                          loss, use and manipulation.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Notwithstanding efforts to maintain a reasonably high level of due
                          diligence at all times, it cannot be excluded that information you
                          disclose to us via the Internet may be viewed and used by others.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Please note that we therefore do not assume any liability whatsoever for
                          the disclosure of information due to errors in data transmission not
                          caused by us and/or unauthorized access by third parties (e.g. hack attack
                          on email account or telephone, interception of faxes).
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>5. Use of the data</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          We will not process the data provided to us for purposes other than those
                          covered by the User Agreement or subsequent Token Purchase Agreement or by
                          your consent or otherwise by a provision in accordance with the Privacy
                          Policy.&nbsp;
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          In particular, the collection of your personal data takes place,&nbsp;
                        </span>
                      </p>
                      <ul>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            to be able to identify and verify you as our user,
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            to conclude the Platform Usage Agreement,
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            to conclude the token purchase agreement,
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            to provide all other necessary services in connection with the access
                            and use of the platform,
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>for correspondence with you,</span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>for invoicing,</span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            in order to provide the services you have requested via our Internet
                            presence,
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            to ensure that our platform is presented to you in the most effective
                            and interesting way possible,
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            to be able to process your requests,
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          for the optimization and economic operation of our online offer, our web
                          presence, and e-mail communication.
                        </li>
                      </ul>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            <br />
                            <strong>6. Transmission of data to third parties</strong>
                          </span>
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          In order to operate the platform and to fulfill legal obligations, it may
                          be necessary or legally obligatory to forward your data to third parties
                          (e.g. service providers we use and to whom we make data available, etc.),
                          courts or authorities. Your data will only be forwarded on the basis of
                          data protection law.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Some of the recipients of your personal data mentioned above are located
                          outside your country or process your personal data there. The{" "}
                        </span>
                        <span style={{ fontWeight: 400 }}>
                          level of data protection in other countries may not correspond to
                          Liechtenstein's. However, we only transfer your personal data to countries
                          for which the EU Commission has decided that they have an adequate level
                          of data protection or we take steps to ensure that all recipients have an
                          adequate level of data protection, for which we enter into or seek to
                          enter into standard contractual clauses (2010/87/EC and/or 2004/915/EC).
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>&nbsp;</span>
                      </p>
                      <p>
                        <strong>7. Data breach disclosure</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          We strive to ensure that data breaches are identified at an early stage
                          and, if necessary, reported to you or the relevant supervisory authority
                          without delay, including the respective categories of data affected.
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>8. Data retention</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          We will not retain data longer than is necessary to fulfill our
                          contractual or legal obligations and to defend against any liability
                          claims.
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>9. Server data</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          For technical reasons, in particular to ensure a secure and stable
                          Internet presence, data is transmitted by your Internet browser to us or
                          to our web space provider. These so-called server log files include the
                          type and version of your internet browser, the operating system, the
                          website from which you accessed our website{" "}
                        </span>
                        <span style={{ fontWeight: 400 }}>
                          (referrer URL), the website(s) of our website that you visit, the date and
                          time of the respective access, and the IP address of the internet
                          connection from which our website is used.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The data collected in this way is stored temporarily, but not together
                          with other data from you.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          This storage takes place on the legal basis of Art 6 para 1 lit f DSGVO.
                          Our legitimate interest lies in the improvement, stability, functionality
                          and security of our website.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The data will be deleted again after seven days at the latest, unless
                          further storage is required for evidentiary purposes. Otherwise, the data
                          is exempt from deletion in whole or in part until final clarification of
                          an incident.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>&nbsp;</span>
                      </p>
                      <p>
                        <strong>10. Cookies</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          We use cookies with our website to make our offer more user-friendly,
                          effective and secure.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Cookies are small text files that we transfer via our web server to the
                          cookie file of the browser on the hard drive of your computer. This
                          enables our website to recognize you as a user when a connection is
                          established between our web server and your browser. Cookies help us to
                          determine the frequency of use and the number of users of our Internet
                          pages. The content of the cookies we use is limited to an identification
                          number that no longer allows any persona
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          reference to the user. The main purpose of a cookie is to recognize
                          visitors to the platform.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>Two types of cookies are used:</span>
                      </p>
                      <ul>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Session cookies: These are temporary cookies that remain in the cookie
                            file of your browser until you leave the platform and are automatically
                            deleted at the end of your visit.
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Persistent cookies: For a better user experience, cookies remain stored
                            on your terminal device and allow us to recognize your browser on your
                            next visit.
                          </span>
                        </li>
                      </ul>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          You can set your browser so that you are informed about the setting of
                          cookies and only allow cookies in individual cases, exclude the acceptance
                          of cookies for certain cases or in general and activate the automatic
                          deletion of cookies when closing the browser. When deactivating cookies,
                          the functionality of the platform may be limited.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Where applicable, cookies from partner companies with which we cooperate
                          for the purpose of advertising, analysis or the functionalities of our
                          website are also used with our website.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>&nbsp;</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          For the details of this, in particular the purposes and legal bases of the
                          processing of such third-party cookies, please refer to the Cookie Policy.
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>11. KYC Compliance Software</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The IT infrastructure of the platform including the KYC compliance
                          software is provided to us by Equanimity AG, Herrengasse 30, 9490 Vaduz,
                          Liechtenstein, hereinafter referred to as "
                        </span>
                        <strong>Equanimity</strong>
                        <span style={{ fontWeight: 400 }}>".</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          For this purpose, we have concluded a Software as a Service contract with
                          Equanimity.&nbsp;
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The legal basis is Art 6 para 1 lit a, b and c DSGVO.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Equanimity provides more information about the collection and use of data,
                          as well as your rights and options for protecting your privacy, at{" "}
                        </span>
                        <span style={{ fontWeight: 400 }}>
                          <a href="https://equanimity.li/terms-of-service/">
                            https://equanimity.li/terms-of-service/
                          </a>
                          <a href="https://equanimity.li/terms-of-service/">.</a>
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          <strong>12. Payment processing</strong>
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          For payment processing of token purchases, we use the payment service of
                          Stripe Inc, 510 Townsend Street, San Francisco, CA 94103, USA, hereinafter
                          referred to as "
                        </span>
                        <strong>Stripe</strong>
                        <span style={{ fontWeight: 400 }}>".</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          To this end, Equanimity has integrated Stripe's API code into the
                          platform.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The legal basis is Art 6 para 1 lit a and b DSGVO.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Stripe provides more information about the collection and use of data, as
                          well as your rights and options to protect your privacy, at{" "}
                          <a href="https://stripe.com/de-us/privacy">
                            https://stripe.com/de-us/privacy
                          </a>
                          .
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>13. Identification IDnow</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The provider IDnow GmbH Headquarters Auenstraße 100 80469 Munich, Germany,
                          hereinafter referred to as "
                        </span>
                        <strong>IDnow</strong>
                        <span style={{ fontWeight: 400 }}>
                          ", is used for the identification of the investors.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          For this purpose, Equanimity has integrated IDnow's API code into the
                          platform.
                        </span>
                        <span style={{ fontWeight: 400 }}>
                          The legal basis is Art 6 para 1 lit a and b DSGVO.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          IDnow offers further information on the collection and use of data as well
                          as your rights and options for protecting your privacy at
                          <a href="https://www.idnow.io/de/datenschutzerklaerung">https://www.idnow.io/de/datenschutzerklaerung</a>
                          .{" "}
                        </span>
                        <span style={{ fontWeight: 400 }}>
                          <br />
                          <br />
                        </span>
                      </p>
                      <p>
                        <strong>14. Identification Onfido </strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          For the identification of investors, the provider Onfido Ltd, 77
                          Farringdon Rd London EC1M 3JU, UK; hereinafter referred to as "
                        </span>
                        <strong>Onfido</strong>
                        <span style={{ fontWeight: 400 }}>" is used.</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          For this purpose, Equanimity has integrated Onfido's API code into the
                          platform.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The legal basis is Art 6 para 1 lit a and b DSGVO.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Onfido offers further information on the collection and use of data as
                          well as on your rights and options for protecting your privacy at{" "}
                        </span>
                        <span style={{ fontWeight: 400 }}>
                          <a href="https://onfido.com/privacy/">https://onfido.com/privacy/</a>
                          <a href="https://onfido.com/privacy/">.</a>
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>15. Wallet Screening </strong>
                        <strong>
                          <br />
                        </strong>
                        <span style={{ fontWeight: 400 }}>
                          The provider used for wallet screening is Bitrank: Blockchain Technology
                          Group Inc, 1199 Pender Street W Suite 410 Vancouver, BC, V6E 2R1 Canada;
                          hereinafter referred to as{" "}
                        </span>
                        <strong>"BitRank".</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          For this purpose, Equanimity has integrated BitRank's API code into the
                          platform.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The legal basis is Art 6 para 1 lit a and b DSGVO.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Bitrank offers further information on the collection and use of data as
                          well as your rights and options for protecting your privacy at{" "}
                        </span>
                        <a href="https://bitrankverified.com/privacy">
                          <span style={{ fontWeight: 400 }}>
                            https://bitrankverified.com/privacy.
                          </span>
                          <strong>
                            <br />
                          </strong>
                        </a>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>16. Wallet</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          For the provision of cryptowallets, we use the service of Tangany GmbH,
                          Brienner Str. 53, 80333 Munich, Germany, hereinafter referred to as "
                        </span>
                        <strong>Tangany</strong>
                        <span style={{ fontWeight: 400 }}>".</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          To this end, Equanimity has integrated Tangany's API code into the
                          platform.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The legal basis is Art 6 para 1 lit a and b DSGVO.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Tangany provides more information about the collection and use of data, as
                          well as your rights and options for protecting your privacy, at{" "}
                        </span>
                        <a href="https://tangany.com/privacy-policy/">
                          <span style={{ fontWeight: 400 }}>
                            https://tangany.com/privacy-policy/.
                          </span>
                        </a>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>17. Cloud services</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          We use software services accessible via the Internet and running on the
                          servers of their providers (so-called "cloud services", also referred to
                          as "software as a service") for the following purposes: document storage
                          and management, database, sending e-mails, exchanging documents, content
                          and information with specific recipients or publishing web pages, forms or
                          other content and information.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          In this context, personal data may be processed and stored on the servers
                          of the providers, insofar as these are part of communication processes
                          with us or are otherwise processed by us as set out in the context of this
                          privacy policy. This data may include, in particular, master data and
                          contact data of users, data on transactions, contracts, other processes
                          and their contents. The cloud service providers also process usage data
                          and metadata used by them for security purposes and service optimization.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          If we use cloud services to provide forms or other documents and content
                          to other users or publicly accessible websites, the providers may store
                          cookies on users' devices for purposes of web analytics or to remember
                          users' settings (e.g., in the case of media control).
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Notes on legal bases: If we ask for consent to use the cloud services, the
                          legal basis of the processing is consent. Furthermore, their use may be a
                          component of our (pre)contractual services, provided
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          that the use of the cloud services has been agreed within this framework.
                          Otherwise, user data is processed on the basis of our legitimate interests
                          (i.e., interest in efficient and secure management and collaboration
                          processes). Legal bases are Art 6 para 1 lit a, b, f DSGVO.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>&nbsp;</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Services used and service providers:
                        </span>
                      </p>
                      <ul>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Google Cloud Services: Google Drive and Gmail; Service Provider: Google
                            Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland, a
                            subsidiary of Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
                            94043, USA; privacy policy:{" "}
                          </span>
                          <a href="https://policies.google.com/privacy">
                            <span style={{ fontWeight: 400 }}>https://policies.google.com/privacy&nbsp;&nbsp;</span>
                          </a>

                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Microsoft Cloud Services: Microsoft 365 Email; Service Provider:
                            Microsoft Corpo-ration, One Microsoft Way, Redmond, WA 98052-6399, USA;
                            privacy statement:{" "}
                          </span>
                          <a href="https://privacy.microsoft.com/de-de">
                            <span style={{ fontWeight: 400 }}>https://privacy.microsoft.com/de-de</span>
                          </a>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          Amazon Web Services: Database; Service provider: Amazon Web Services, Inc.
                          410 Terry Avenue North, Seattle, WA 98109, USA; Privacy Policy:{" "}
                          <a href="https://aws.amazon.com/de/compliance/eu-data-protection/">
                            https://aws.amazon.com/de/compliance/eu-data-protection/&nbsp;
                          </a>
                        </li>
                      </ul>
                      <ul>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Twilio: SMS login for client account; Service provider: Twilio Inc, 375
                            Beale St #300, San Francisco, CA 94105, USA; privacy policy:{" "}
                          </span>
                          <a href="https://www.twilio.com/legal/privacy">
                            <span style={{ fontWeight: 400 }}>https://www.twilio.com/legal/privacy&nbsp;{" "}</span>
                          </a>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          Mailgun: automated e-mailing; service provider: Mailgun Technologies, Inc,
                          112 E Pecan St #1135, San Antonio, TX 78205, USA; privacy policy: https:
                          <a href="https://www.mailgun.com/privacy-policy/">
                            <span>https://www.mailgun.com/legal/privacy-policy/</span>
                          </a>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          Acronis: Data storage; Service provider: Acronis International GmbH,
                          Rheinweg 9 Schaffhausen, 8200 Switzerland; Privacy policy:{" "}
                          <a href="https://www.acronis.com/en-eu/company/privacy.html">
                            <span>https://www.acronis.com/en-eu/company/privacy.html&nbsp;</span>
                          </a>
                        </li>
                      </ul>
                      <p>
                        <span style={{ fontWeight: 400 }}>&nbsp;</span>
                      </p>
                      <p>
                        <strong>18. Profiling</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          We do not use automated decision-making or profiling in the sense of Art
                          22 DSGVO.
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>19. Amendment of this privacy policy</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          We reserve the right to change this privacy policy at any time with effect
                          for the future. A current version is available via the platform. Please
                          visit the platform regularly and inform
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          yourself about the applicable privacy policy.
                        </span>
                      </p>
                    </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Policy;
