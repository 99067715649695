import React from "react";
import { Link } from "react-router-dom";
import { getFileUrl } from "../../utils";
import { usePublicSettings } from "../../myHooks/useSettings";

const RenderSidebarLogo = ({
  // from HOCs
  location,
}) => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();

  return (
    <>
      <Link className="sidebar__logo sidebar__logo-kyc" to="/">
        <img
          src={getFileUrl((publicSettings || {}).logo_path)}
          alt="logo"
          className="some-icon"
        />

        <img
          src={getFileUrl((publicSettings || {}).logo_for_dark_bg_path || (publicSettings || {}).logo_path)}
          alt="logo"
          className="some-icon-dark"
        />
      </Link>
    </>
  );
}

export default RenderSidebarLogo;
