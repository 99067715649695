import React, { useState, useEffect } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { omit } from 'lodash'
import { NET_INVESTABLE_ASSETS_OPTIONS } from '../../constants/customer'
import { COMPANY_DOCUMENT_TYPE_ENUM } from '../../schemas/kycVerification'
import { useMe, usePublicSettings } from '../../myHooks'
import { isFiat, toaster, getKybData, setKybData } from '../../utils'
import { REGISTRATION_COMPANY_CUSTOMER } from '../../queriesAndMutations'
import { TOKEN_TYPES } from '../../constants/settings'
import LoginHead from './LoginHead'
import CompanyDetails from '../../components/CompanyDetails'
import CompanyDirectors from '../../components/CompanyDirectors'
import CompanyProofResidence from '../../components/CompanyProofResidence'
import CompanyUBOs from '../../components/CompanyUBOs'
import CompanyDisclaimers from '../../components/CompanyDisclaimers'

const EasyOnBoardingCompany = () => {
  const [step, setStep] = useState(0)
  const query = new URLSearchParams(window.location.search)
  const country = query.get('country')

  const { data: { me: userData } = {} } = useMe()
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  const [registerCompanyCustomer, registerCompanyCustomerData] = useMutation(REGISTRATION_COMPANY_CUSTOMER)
  const history = useHistory();

  const [checkboxValues, setCheckboxValues] = useState({
    IActOnMyOwnAccount: false,
    clientStatement: false,
    confirmationTerms1: false,
    declarationOfConsent1: false,
    declarationOfConsent2: false,
    declarationOfConsent3: false,

    declarationOfConsent4: false,
    declarationOfConsent5: false,
    declarationOfConsent6: false,
    declarationOfConsent7: false,
    declarationOfConsent8: false,

    authorizedRepresentative: false,
    beneficiaOwner: false,
  })

  const [values, setValues] = useState(getKybData() || {
    // deprecated
    documentType: COMPANY_DOCUMENT_TYPE_ENUM[Object.keys(COMPANY_DOCUMENT_TYPE_ENUM)[0]].value,
    currency: 'eth',

    // Step 1
    email: '',
    companyName: '',
    directorFormType: '',
    companyRegisterNumber: '',
    legalForm: '',
    legalFormOther: '',
    dateOfIncorporation: '2014-08-18',
    dateOfEntryInCommercialRegister: '2014-08-18',
    placeOfCommercialRegister: '',
    companyShareCapital: '',
    companyPlace: '',
    companyAddress: '',
    companyCountry: '',
    companyPostalCode: '',
    descriptionRegisterExtractBusinessActivity: '',
    sourceOfIncome: '',
    hasNotRelatedSourceIncome: false,
    notRelatedSourceIncomeDescription: '',
    investmentPurpose: '',
    grossRevenue: NET_INVESTABLE_ASSETS_OPTIONS[0],
    descriptionOfFunds: '',
    referral_code: query.get('referalCode') || localStorage.getItem("referalCode") || userData.referral_code || '',
    business_countries: '',
    business_following_industries: '',
    sic_code: '',

    corporationOrganizationChartFile: null,
    commercialRegisterExtract: null,
    articlesOfAssociationFile: null,
    lastBalanceSheetFile: null,
    certificateOfGoodStandingFile: null,
    shareholderListFile: null,

    amount: '0',
    exactIntendedInvestmentAmount: 0,
    intended_investment: '',
    sourceOfFunds: '',
    supportedSourceOfFundUploadFiles: [],
    wallet: 'eth',
    isAddEtherWalletLater: false,
    tokenAddress: '',
    tokenSendingAddress: '',
    verified_wallet: '',

    // Step 2
    directors: [],

    // Step 3
    proofOfResidenceType: 'BANK_STATEMENT',
    proofOfResidenceUploadFile: null,

    // Step 4
    uboList: [],

    // Step 5
    personType: 'NOT_POLITICAL_PERSON',
    duties: '',
    relationshipToPEP: '',

    // will be omitted
    directorAsUbo: null,
  })

  const cacheKybData = () => {
    setKybData({
      ...values,
      corporationOrganizationChartFile: null,
      commercialRegisterExtract: null,
      articlesOfAssociationFile: null,
      lastBalanceSheetFile: null,
      certificateOfGoodStandingFile: null,
      shareholderListFile: null,
      proofOfResidenceUploadFile: null,
      supportedSourceOfFundUploadFiles: [],
      uboList: values.uboList.map(ubo => ({
        ...ubo,
        trueCertifiedCopyFile: '',
      })),
      directorAsUbo: values.directorAsUbo ?
        { ...values.directorAsUbo, trueCertifiedCopyFile: "" } : null
    })
  }

  const goToNextStep = () => {
    setStep(step + 1)
    cacheKybData()
  }
  const goToPrevStep = () => {
    setStep(step - 1)
  }
  const goToStep = (newStep) => {
    setStep(newStep)
    cacheKybData()
  }

  const submitForm = () => {
    if (!values.directors.length) {
      toaster.error('Director list must not be empty')
      return
    }

    if (!values.directorAsUbo && publicSettings.kyb_require_ubo) {
      toaster.error('You must select a director for UBO.')
      return
    }

    const getSubmitFile = data => (data ? data.img : null)
    const sendInput = {
      ...omit(values, ['directorAsUbo', 'generatedWallet']),
      corporationOrganizationChartFile: getSubmitFile(values.corporationOrganizationChartFile),
      commercialRegisterExtract: getSubmitFile(values.commercialRegisterExtract),
      articlesOfAssociationFile: getSubmitFile(values.articlesOfAssociationFile),
      lastBalanceSheetFile: getSubmitFile(values.lastBalanceSheetFile),
      certificateOfGoodStandingFile: getSubmitFile(values.certificateOfGoodStandingFile),
      shareholderListFile: getSubmitFile(values.shareholderListFile),
      proofOfResidenceUploadFile: getSubmitFile(values.proofOfResidenceUploadFile),
      supportedSourceOfFundUploadFiles: values.supportedSourceOfFundUploadFiles.map(getSubmitFile),
      isAddEtherWalletLater: values.isAddEtherWalletLater || isFiat(values.currency),

      exactIntendedInvestmentAmount: +values.exactIntendedInvestmentAmount,
      amount: String(values.amount),

      directors: values.directors.map(director => ({
        firstName: director.firstName,
        lastName: director.lastName,
        email: director.email,
        signatureRight: director.signatureRight,
        personType: director.personType,
        duties: director.duties,
        relationshipToPEP: director.relationshipToPEP,
        willSignForm: director.willSignForm,
      })),
      uboList: [
        ...(publicSettings.kyb_require_ubo ? [{
          firstName: values.directorAsUbo.firstName,
          lastName: values.directorAsUbo.lastName,
          email: values.directorAsUbo.email,
          trueCertifiedCopyType: values.directorAsUbo.trueCertifiedCopyType,
          trueCertifiedCopyFile: getSubmitFile(values.directorAsUbo.trueCertifiedCopyFile),
          ownership: +values.directorAsUbo.ownership,
          birthDate: values.directorAsUbo.birthDate,
          personType: values.directorAsUbo.personType,
          duties: values.directorAsUbo.duties,
          relationshipToPEP: values.directorAsUbo.relationshipToPEP,
        }] : []),
        ...values.uboList.map(ubo => ({
          firstName: ubo.firstName,
          lastName: ubo.lastName,
          email: ubo.email,
          trueCertifiedCopyType: ubo.trueCertifiedCopyType,
          trueCertifiedCopyFile: getSubmitFile(ubo.trueCertifiedCopyFile),
          ownership: +ubo.ownership,
          birthDate: ubo.birthDate,
          personType: ubo.personType,
          duties: ubo.duties,
          relationshipToPEP: ubo.relationshipToPEP,
        }))
      ]
    };

    cacheKybData()

    registerCompanyCustomer({ variables: { input: sendInput } }).then(({ data }) => {
      window.location.href = `/application-success/company/${data.registrationCompanyCustomer.id}`
    }).catch((error) => {
      console.log(error)
    })
  }

  const renderStep = () => {
    if (step === 0) {
      return (
        <CompanyDetails
          values={values}
          setValues={setValues}
          goToNextStep={goToNextStep}
          goToStep={goToStep}
        />
      )
    }

    if (step === 1) {
      return (
        <CompanyDirectors
          values={values}
          setValues={setValues}
          goToPrevStep={goToPrevStep}
          goToNextStep={goToNextStep}
          goToStep={goToStep}
        />
      )
    }

    if (step === 2 && publicSettings.kyb_require_utility_bill) {
      return (
        <CompanyProofResidence
          values={values}
          setValues={setValues}
          goToPrevStep={goToPrevStep}
          goToNextStep={goToNextStep}
          goToStep={goToStep}
          publicSettings={publicSettings}
        />
      )
    }

    if (step === (3 - (!publicSettings.kyb_require_utility_bill)) && publicSettings.kyb_require_ubo) {
      return (
        <CompanyUBOs
          values={values}
          setValues={setValues}
          goToPrevStep={() => { goToStep(2 - (!publicSettings.kyb_require_utility_bill)) }}
          goToNextStep={() => { goToStep(4 - (!publicSettings.kyb_require_utility_bill)) }}
          goToStep={goToStep}
        />
      )
    }

    if (step === (4 - (!publicSettings.kyb_require_ubo) - (!publicSettings.kyb_require_utility_bill))) {
      return (
        <CompanyDisclaimers
          values={values}
          setValues={setValues}
          checkboxValues={checkboxValues}
          setCheckboxValues={setCheckboxValues}
          goToPrevStep={() => { goToStep(3 - (!publicSettings.kyb_require_ubo) - (!publicSettings.kyb_require_utility_bill)) }}
          submitForm={submitForm}
          loading={registerCompanyCustomerData.loading}
          goToStep={goToStep}
        />
      )
    }

    return null
  }

  useEffect(() => {
    if (publicSettings && userData) {
      if (userData.ip_country !== 'CN' && country !== 'CN' && !userData.phone_verified && userData.verify_sms_send_at && (new Date()) - (new Date(parseInt(userData.verify_sms_send_at, 10))) < 60000) {
        history.push('/easy-onboarding-verify-phone-number?company=true')

        return
      }

      if (country !== 'CN' && !userData.phone_verified) {
        history.push('/easy-onboarding-2fa?company=true')

        return
      }

      const INTENDED_INVESTMENT =
        publicSettings.kyc_levels ?
        Object.values(publicSettings.kyc_levels).filter((item) => {
          return !item.enable || item.enable.enable_lvl;
        })
        .map((item, index) => {
          const unit = publicSettings.kyc_use_base_currency ? (publicSettings.base_currency.toUpperCase() || 'ETH') : 'CHF';

          if (item.enable && item.enable.enable_open_end) {
            return `> ${item.min_invest_amount} ${unit}`;
          }

          return `${item.min_invest_amount} - ${item.max_invest_amount} ${unit}`;
        }) : [];

      const amount = parseInt(values.amount, 10) ? values.amount :
        userData.is_gto_sales ? String(userData.token_invest_amount || 0) : '0'

      setValues({
        ...values,
        currency: values.currency || (publicSettings || {}).base_currency || 'eth',
        email: values.email || userData.email,
        intended_investment: values.intended_investment || INTENDED_INVESTMENT[0],
        directors: values.directors.length ? values.directors : [{
          firstName: userData.first_name,
          lastName: userData.last_name,
          email: userData.email,
          signatureRight: 'SINGLE',
          personType: '',
          status: 'pending',
          userStatus: ['EMAIL_VERIFIED', 'KYC_NOT_SUBMITTED', 'VIDEO_IDENT_NOT_STARTED'],
          willSignForm: false,
        }],
        tokenAddress: values.tokenAddress || userData.wallet_address || '',
        tokenSendingAddress: values.tokenSendingAddress || userData.wallet_sending_address || '',
        amount: parseInt(amount, 10)
          ? String(amount) :
          publicSettings.enforce_token_minimum_at_kyc && publicSettings.minimum_investment_amount && publicSettings.token_type !== TOKEN_TYPES.NONE
            ? String(publicSettings.minimum_investment_amount) : '0'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicSettings, userData])

  return (
    <>
      <LoginHead />

      {renderStep()}
    </>
  );
}

export default EasyOnBoardingCompany;
