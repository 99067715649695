import React from 'react'
import PropTypes from 'prop-types'
import { withGoogleMap, withScriptjs, GoogleMap, Marker } from 'react-google-maps'

const Map = withScriptjs(withGoogleMap(({ center, children }) => (
  <GoogleMap
    defaultZoom={14}
    defaultCenter={center}
  >
    {children}
  </GoogleMap>
)))

const LocationMap = ({
  markers
}) => {
  window.mapCallback = () => {
    console.log('trigger callback')
  }

  return (
    <Map
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&callback=window.mapCallback`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div className="map-container"/>}
      mapElement={<div style={{ height: `100%` }} />}
      center={{ lat: +(markers[0].lat), lng: +(markers[0].lng) }}
    >
      {markers.map(marker => (
        <Marker position={{ lat: +(marker.lat), lng: +(marker.lng) }} key={JSON.stringify(marker)} />
      ))}
    </Map>
  )
}

LocationMap.propTypes = {
  markers: PropTypes.array,
}

export default LocationMap;
