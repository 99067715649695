import gql from 'graphql-tag';

export const REDEMPTION_TRANSACTION = gql`
    mutation createRedemptionTransaction($input: CreateTransactionInput!) {
        createRedemptionTransaction(input: $input) {
        id
        payment_ref
        to
        type
        token_amount
        currency_amount
        currency
        usd_amount
        status
        payment_type
        details
        tx_hash
        deposit_from
        deposit_to
        approved_note
        is_contribution_gto
        mandatory_kyc
        stripe_id
        reservation
        url
      }
    }
  `;