import React from 'react'

const TableColumn = ({
  // eslint-disable-next-line react/prop-types
  children,
  typeColumn = "products__col",
  isFirstColumn,
  isVertMiddle,
  isWrapping,
}) => {
  return (
    <>
      {typeColumn === 'transactions__col' ? (
        <div className={`transactions__col ${isFirstColumn ? "products__col-first-clear" : ""} ${isVertMiddle ? "vert__middle" : ""}`}>
          {isWrapping === true ? (
            <div className="products__item products__item-normal">
              <div className="products__details">
                <div className="products__subtitle">
                  {children}
                </div>
              </div>
            </div>
          ) : (
            <>
              {children}
            </>
          )}
        </div>
      ) : (
        <div className={`products__col ${isFirstColumn ? "products__col-first-clear" : ""} ${isVertMiddle ? "vert__middle" : ""}`}>
          {isWrapping === true ? (
            <div className="produts__item products__item-normal">
              <div className="products__details">
                <div className="produts__subtitle">
                  {children}
                </div>
              </div>
            </div>
          ) : (
            <>
              {children}
            </>
          )}
        </div>
      )}
    </>
  )
}

export default TableColumn;
