import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { ME_INVEST_IN_BASE_CURRENCY } from "../../../queriesAndMutations";
import { useTranslation } from 'react-i18next';

const Contributions = () => {
  const { t } = useTranslation();
  const {
    data: { meInvestInBaseCurrency = {} } = {},
  } = useQuery(ME_INVEST_IN_BASE_CURRENCY);

  return (
    <>
      {
        meInvestInBaseCurrency && meInvestInBaseCurrency.currencies_contribution && (
          <div className="card">
            <div className="card__head">
              <div className="title-primary card__title">
                { t('My Contribution') }
              </div>
            </div>

            <div className="tips">
              <div className="tips__list tips__list-clear mt__0 flex__align_center">
                {
                  meInvestInBaseCurrency.currencies_contribution.map((v) => (
                    <div className="tips__item tips__contribute mt__0" key={v.currency}>
                      <div className="tips__icon">
                        {v.currency.toUpperCase()}
                      </div>

                      <div className="tips__details">
                        <div className="tips__line">
                          <div className="review__user d__flex flex__row flex__justify_start flex__align_center pb__0">
                            <div className="review__title">
                              {v.sum}
                            </div>

                            <div className="review__login">
                              ({v.currency})
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default Contributions;
