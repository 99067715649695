import React from 'react'
import { usePublicSettings } from '../myHooks/useSettings'
import { useTranslation } from 'react-i18next'
import SidebarOuter from '../components/SidebarOuter'
import Icon from '../components/Icon'
import classNames from 'classnames'
import { isIE } from "react-device-detect"
import NotSupportViewer from '../components/NotSupportViewer'

import '../styles/app.min.css'
import '../styles/style.css'

const PurchaseAgreement = () => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { t } = useTranslation();

  if (isIE) {
    return <NotSupportViewer />;
  }

  return (
    <div className="page pt-0">
      <SidebarOuter />

      <div className="page__inner">
        <div className="page__container">
          <div className="product__wrapper product__wrapper_v2">
            <div className="product__details">
              <div className="product__overview">
                <div className="product__title h4">
                  { t('Purchase Agreement') }
                </div>

                <div className="product__row">
                  <div className="product__col pr__0">
                    <div className="shop shop__products">
                      <div className="summary summary__document summary__fullwidth">
                        <div className="summary__preview">
                          <div className="summary__preview_aspect_ratio">
                            <embed
                              src={publicSettings && publicSettings.purchase_agreement_link}
                              width="700"
                              height="700"
                              type="application/pdf"
                            />
                          </div>

                          <div className="summary__control">
                            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                            <a
                              className="summary__button"
                              rel="noopener noreferrer"
                              href={
                                publicSettings &&
                                publicSettings.purchase_agreement_link &&
                                `${publicSettings.purchase_agreement_link}?origin=${window.location.origin}`
                              }
                              target="_blank"
                            >
                              <Icon
                                className={classNames('link', 'icon-24')}
                                name="link"
                                size="24"
                              />
                            </a>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseAgreement;
