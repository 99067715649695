import gql from "graphql-tag";

export const SEARCH_KOMPANY_MAPPING = gql`
  mutation searchKompanyMapping($companyId: ID!, $type: String!) {
    searchKompanyMapping(companyId: $companyId, type: $type)
  }
`;

export const SAVE_KOMPANY_MAPPING = gql`
  mutation saveKompanyMapping($companyId: ID!, $kompanyId: String, $data: JSON) {
    saveKompanyMapping(companyId: $companyId, kompanyId: $kompanyId, data: $data)
  }
`;

export const REQUEST_KOMPANY_DATASET = gql`
  mutation requestKompanyDataset($companyId: ID!, $type: String!) {
    requestKompanyDataset(companyId: $companyId, type: $type)
  }
`;
