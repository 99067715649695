import gql from 'graphql-tag'

export const LIST_SPOT_CHECK = gql`
  query listSpotCheck($input: ListSpotCheckInput) {
    listSpotCheck(input: $input) {
      page
      pageSize
      totalPages
      total
      data {
        id
        customerId
        status
        approvedBy
        approvedAt
        approver {
          id
          email
        }
        customer {
          id
          email
        }
      }
    }
  }
`;

export const GET_SPOT_CHECK_STATISTIC = gql`
  query getSpotCheckStatistic {
    getSpotCheckStatistic {
      total
      checked
      pending
      totalKyc
    }
  }
`;
