import React from 'react';
import { useTranslation } from 'react-i18next'
import SidebarNav from './SidebarNav'
import CardDisclaimer from './CardDisclaimer'

const EasyOnBoardingDetailDisclaimer = ({
  values,
  setValues,
  goToPrevStep,
  disclaimer,
  setDisclaimer,
  submitKyc,
  goToStep,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <div className="page__inner page__inner-onboarding">
      <div className="page__container">
        <div className="page__title h3">{t('Disclaimers')}</div>

        <div className="page__row">
          <div className="page__col">
            <CardDisclaimer
              values={values}
              setValues={setValues}
              goToPrevStep={goToPrevStep}
              disclaimer={disclaimer}
              setDisclaimer={setDisclaimer}
              submitKyc={submitKyc}
              loading={loading}
            />
          </div>

          <div className="page__col">
            <SidebarNav goToStep={goToStep} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EasyOnBoardingDetailDisclaimer;
