import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  MenuItem,
  InputAdornment,
} from '@material-ui/core'

import { toLocalYmdString, toaster } from '../../../utils'
import { CUSTOMER_DOCUMENT_NAME_TYPES } from '../../../constants/customer'
import Icon from '../../Icon'
import InputThemeField from '../../InputThemeField'
import CardInformation from '../../InformationCard'
import ImageUpload from '../../ImageUpload'
import styles from './styles.module.scss'
import { PEP_OPTIONS } from '../../CompanyDirectors/CardForm'

const CardForm = ({
  values,
  setValues,
  goToNextStep,
  goToPrevStep,
}) => {
  const { t } = useTranslation()
  const [selectedDirector, setSelectedDirector] = useState(values.directorAsUbo ? values.directorAsUbo.email : '');
  const [selectedAddDirector, setSelectedAddDirector] = useState('');

  const defaultNewUbo = {
    firstName: '',
    lastName: '',
    email: '',
    trueCertifiedCopyType: 'PASSPORT',
    birthDate: '2000-01-01',
    personType: '',
    duties: '',
    relationshipToPEP: '',
  }
  const [newUbo, setNewUbo] = useState(defaultNewUbo)
  const [showAddForm, setShowAddForm] = useState(false)

  const [uboError, setUboError] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    email: '',
  })
  const validateNewUbo = () => {
    const error = {
      firstName: (!newUbo.firstName) ? 'First name is required' : '',
      lastName: (!newUbo.lastName) ? 'Last name is required' : '',
      email: (!newUbo.email)
        ? 'Email is required'
        : (newUbo.email.indexOf('@') < 0 || newUbo.email.indexOf('@') === newUbo.email.length + 1)
          ? 'Invalid email'
          : '',

    }
    setUboError(error);
    return Object.values(error).filter(e => e).length === 0;
  }

  const addNewUbo = () => {
    if (validateNewUbo()) {
      setValues({
        ...values,
        uboList: [
          ...values.uboList,
          { ...newUbo, status: 'pending', ownership: 0 }
        ]
      })

      setNewUbo(defaultNewUbo)
      setShowAddForm(false);
    }
  }

  useEffect(() => {
    if (selectedDirector !== '') {
      const selected = values.directors.find((director) => director.email === selectedDirector);

      setValues({
        ...values,
        directorAsUbo: {
          firstName: selected.firstName,
          lastName: selected.lastName,
          email: selected.email,
          trueCertifiedCopyType: (values.directorAsUbo && values.directorAsUbo.trueCertifiedCopyType) || 'PASSPORT',
          trueCertifiedCopyFile: (values.directorAsUbo && values.directorAsUbo.trueCertifiedCopyFile) || null,
          status: 'verified',
          ownership: (values.directorAsUbo && values.directorAsUbo.ownership) || 100,
          birthDate: (values.directorAsUbo && values.directorAsUbo.birthDate) || '2000-01-01',
          personType: (values.directorAsUbo && values.directorAsUbo.personType) || '',
          duties: (values.directorAsUbo && values.directorAsUbo.duties) || '',
          relationshipToPEP: (values.directorAsUbo && values.directorAsUbo.relationshipToPEP) || '',
        }
      });
    }
    // eslint-disable-next-line
  }, [selectedDirector])

  return (
    <div className="card easy-onboarding__company-ubos">
      <div className="settings__wrapper">
        <div className="card__head card__head-kyc-1">
          <div className="title-primary card__title">
            UBOs
          </div>
        </div>

        {!values.directorAsUbo && (
          <div className="settings__fieldset">
            <div className="field easy-onboarding__selectdirectorforubo">
              <div className="field__label">
                {t('Select a director for UBO')}
              </div>

              <div className={classNames('field__wrap field__wrap-select', styles.select)}>
                <TextField
                  select
                  margin="none"
                  variant="outlined"
                  fullWidth
                  value={selectedDirector}
                  onChange={({target: {value}}) => setSelectedDirector(value)}
                >
                  { values.directors.filter(director => {
                      return !values.uboList.map(ubo => ubo.email).includes(director.email) && director.email !== (values.directorAsUbo || {}).email
                    }).map((director, index) => (
                    <MenuItem value={director.email} key={index}>
                      {director.firstName} {director.lastName}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          </div>
        )}

        {values.directorAsUbo && (
          <div className="settings__fieldset">
            <div className="field easy-onboarding__directorasubostatus">
              <div className="refund__item">
                {values.directorAsUbo.status === 'verified' ? (
                  <div className="refund__icon verified">
                    <Icon
                      className={classNames('check')}
                      name='check'
                      size="24"
                    />
                  </div>
                ) : (
                  <div className="refund__icon">
                    <Icon
                      className={classNames('clock')}
                      name='clock'
                      size="24"
                    />
                  </div>
                )}

                <div className="refund__content">
                  <strong>
                    {values.directorAsUbo.firstName} {values.directorAsUbo.lastName}
                  </strong>
                  <div className="d__block">
                    <strong>
                      {values.directorAsUbo.email}
                    </strong>
                  </div>
                  <div className="d__block">
                    <div className={ t(values.directorAsUbo.status === 'verified' ? 'status-green-dark' : 'status-red-dark')}>
                      { t(values.directorAsUbo.status === 'verified' ? 'Verified' : 'Not finished')}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="field field-has__endadorment">
              <div className="field__label">
                {t('Ownership')}
              </div>

              <div className="field__wrap field__wrap-white">
                <TextField
                  margin="normal"
                  value={values.directorAsUbo.ownership}
                  onChange={({ target: { value }}) => {
                    setValues({
                      ...values,
                      directorAsUbo: {
                        ...values.directorAsUbo,
                        ownership: +value,
                      }
                    })
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        %
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>

            <div className="field easy-onboarding__dateofbirth">
              <div className="field__label">
                {t('Date of birth', {keySeparator: '>', nsSeparator: '|'})}
              </div>

              <div className={classNames('field__wrap field__wrap-date', styles.field__date)}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    views={["year", "month", "date"]}
                    openTo="year"
                    disableFuture
                    variant="outlined"
                    format="dd.MM.yyyy"
                    invalidDateMessage={t('Invalid Date Format')}
                    margin="none"
                    value={values.directorAsUbo.birthDate}
                    onChange={(date) => {
                      setValues({
                        ...values,
                        directorAsUbo: {
                          ...values.directorAsUbo,
                          birthDate: toLocalYmdString(date),
                        }
                      })
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>

            <div className="field easy-onboarding__trucertifiedcopy">
              <div className="field__label">
                {t('True certified copy')}
                <span className="text__red"> *</span>
              </div>

              <div className={classNames('field__wrap field__wrap-select', styles.select)}>
                <TextField
                  select
                  margin="none"
                  variant="outlined"
                  fullWidth
                  value={values.directorAsUbo.trueCertifiedCopyType}
                  onChange={({ target: { value }}) => {
                    setValues({
                      ...values,
                      directorAsUbo: {
                        ...values.directorAsUbo,
                        trueCertifiedCopyType: value,
                      }
                    })
                  }}

                >
                  { Object.entries(CUSTOMER_DOCUMENT_NAME_TYPES).map(type => (
                    <MenuItem value={type[0]} key={type[0]}>
                      {t(type[1])}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>

            <div className="field easy-onboarding__companyupload">
              {!values.directorAsUbo.trueCertifiedCopyFile && (
                <ImageUpload
                  labelText={ t('Upload') }
                  buttonText={t('Click or drop images')}
                  buttonClassName="button"
                  maxFileSize={10000000}
                  withPreview
                  singleImagePick
                  singleFile
                  onChange={(img, imgURI) => {
                    let newImage = {}

                    if (img.length > 0 && imgURI.length > 0) {
                      newImage = {
                        img: img[0],
                        imgURI: imgURI[0],
                      }
                    }

                    setValues({
                      ...values,
                      directorAsUbo: {
                        ...values.directorAsUbo,
                        trueCertifiedCopyFile: newImage,
                      }
                    })
                  }}
                >
                  <div className="file__wrap_text text__center">
                    <p>
                      { t('Drag and drop the document') }
                    </p>
                  </div>
                </ImageUpload>
              )}

              {values.directorAsUbo.trueCertifiedCopyFile && (
                <div className={classNames('settings__top', styles.upload__result)}>
                  <div className="settings__label">
                    {values.directorAsUbo.trueCertifiedCopyFile.img.name}
                  </div>

                  <button
                    className="button-stroke button-small settings__button"
                    onChange={(img, imgURI) => {
                      setValues({
                        ...values,
                        directorAsUbo: {
                          ...values.directorAsUbo,
                          trueCertifiedCopyFile: null,
                        }
                      })
                    }}
                  >
                    {t('Remove')}
                  </button>
                </div>
              )}
            </div>

            <div className="field">
              <button
                className="button-stroke w-full text__center"
                type="button"
                onClick={() => {
                  setValues({
                    ...values,
                    directorAsUbo: null,
                  })
                  setSelectedDirector('')
                  toaster.success(t('Remove UBO successfully'));
                }}
              >
                <Icon
                  className={classNames('close fill__red')}
                  name='close'
                  size="24"
                />{" "}
                {t('Remove UBO')}
              </button>
            </div>

            <div className="field easy-onboarding__company-politicallyexposedpersons">
              <div className="field__label">
                {t('Politically Exposed Persons')}
              </div>

              <div className={
                classNames(
                  'field__wrap field__wrap-radio field__wrap-radio_vertical field__wrap-radio_text_gray',
                  styles.field__wrap
                )
              }>
                <RadioGroup
                  value={values.directorAsUbo.personType}
                  onChange={({ target: { value }}) => {
                    setValues({
                      ...values,
                      directorAsUbo: {
                        ...values.directorAsUbo,
                        personType: value,
                      }
                    })
                  }}
                >
                  { PEP_OPTIONS.map((name) => (
                      <FormControlLabel
                        key={name}
                        label={t(name)}
                        value={name}
                        control={<Radio color="primary" />}
                      />
                    ))}
                </RadioGroup>
              </div>
            </div>

            { values.directorAsUbo.personType && values.directorAsUbo.personType !== 'notPoliticalPerson' &&
              <div className="settings__row flex__row flex__wrap have__fields">
                <InputThemeField
                  classWrapper="field easy-onboarding__exactdescriptionofthefunction"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  label={t('Exact description of the function')}
                  value={values.directorAsUbo.duties}
                  onChange={({ target: { value }}) => {
                    setValues({
                      ...values,
                      directorAsUbo: {
                        ...values.directorAsUbo,
                        duties: value,
                      }
                    })
                  }}
                />

                <InputThemeField
                  classWrapper="field easy-onboarding__relationshiptopep"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  label={t('Relationship to PEP')}
                  value={values.directorAsUbo.relationshipToPEP}
                  onChange={({ target: { value }}) => {
                    setValues({
                      ...values,
                      directorAsUbo: {
                        ...values.directorAsUbo,
                        relationshipToPEP: value,
                      }
                    })
                  }}
                />
              </div>
            }

            <div className="space space__32">{' '}</div>
          </div>
        )}
        {values.uboList.map((ubo, index) => (
          <div className="settings__fieldset easy-onboarding__ubodetails" key={ubo.email}>
            <div className="field">
              <div className="refund__item">
                {ubo.status === 'verified' ? (
                  <div className="refund__icon verified">
                    <Icon
                      className={classNames('check')}
                      name='check'
                      size="24"
                    />{" "}
                  </div>
                ) : (
                  <div className="refund__icon">
                    <Icon
                      className={classNames('clock')}
                      name='clock'
                      size="24"
                    />
                  </div>
                )}

                <div className="refund__content">
                  <strong>
                    {ubo.firstName} {ubo.lastName}
                  </strong>
                  <div className="d__block">
                    <strong>
                      {ubo.email}
                    </strong>
                  </div>
                  <div className="d__block">
                    <div className={ t(ubo.status === 'verified' ? 'status-green-dark' : 'status-red-dark')}>
                      { t(ubo.status === 'verified' ? 'Verified' : 'Not finished')}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="field field-has__endadorment">
              <div className="field__label">
                {t('Ownership')}
              </div>

              <div className="field__wrap field__wrap-white">
                <TextField
                  margin="normal"
                  value={ubo.ownership}
                  onChange={({ target: { value }}) => {
                    setValues({
                      ...values,
                      uboList: values.uboList.map((oldUbo, i) => (
                        i === index ? { ...ubo, ownership: +value } : oldUbo
                      )),
                    })
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        %
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>

            <div className="field">
              <div className="field__label">
                {t('Date of birth')}
              </div>

              <div className={classNames('field__wrap field__wrap-date', styles.field__date)}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    views={["year", "month", "date"]}
                    openTo="year"
                    disableFuture
                    variant="outlined"
                    format="dd.MM.yyyy"
                    invalidDateMessage={t('Invalid Date Format')}
                    margin="none"
                    value={ubo.birthDate}
                    onChange={(date) => {
                      setValues({
                        ...values,
                        uboList: values.uboList.map((oldUbo, i) => (i === index ? { ...ubo, birthDate: toLocalYmdString(date) } : oldUbo)),
                      })
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>

            <div className="field">
              <div className="field__label">
                {t('True certified copy')}
                <span className="text__red"> *</span>
              </div>

              <div className={classNames('field__wrap field__wrap-select', styles.select)}>
                <TextField
                  select
                  margin="none"
                  variant="outlined"
                  fullWidth
                  value={ubo.trueCertifiedCopyType}
                  onChange={({ target: { value }}) => {
                    setValues({
                      ...values,
                      uboList: values.uboList.map((oldUbo, i) => (i === index ? { ...ubo, trueCertifiedCopyType: value } : oldUbo)),
                    })
                  }}
                >
                  { Object.entries(CUSTOMER_DOCUMENT_NAME_TYPES).map(type => (
                    <MenuItem value={type[0]} key={type[0]}>
                      {t(type[1])}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>

            <div className="field">
              {!ubo.trueCertifiedCopyFile && (
                <ImageUpload
                  labelText={ t('Upload') }
                  buttonText="Click or drop images"
                  buttonClassName="button"
                  maxFileSize={10000000}
                  withPreview
                  singleImagePick
                  singleFile
                  onChange={(img, imgURI) => {
                    let newImage = {}

                    if (img.length > 0 && imgURI.length > 0) {
                      newImage = {
                        img: img[0],
                        imgURI: imgURI[0],
                      }
                    }

                    setValues({
                      ...values,
                      uboList: values.uboList.map((oldUbo, i) => (i === index ? { ...ubo, trueCertifiedCopyFile: newImage } : oldUbo)),
                    })
                  }}
                >
                  <div className="file__wrap_text text__center">
                    <p>
                      { t('Drag and drop the document') }
                    </p>
                  </div>
                </ImageUpload>
              )}

              {ubo.trueCertifiedCopyFile && (
                <div className={classNames('settings__top', styles.upload__result)}>
                  <div className="settings__label">
                    {ubo.trueCertifiedCopyFile.img.name}
                  </div>

                  <button
                    className="button-stroke button-small settings__button"
                    onChange={() => {
                      setValues({
                        ...values,
                        uboList: values.uboList.map((oldUbo, i) => (i === index ? { ...ubo, trueCertifiedCopyFile: null } : oldUbo)),
                      })
                    }}
                  >
                    {t('Remove')}
                  </button>
                </div>
              )}
            </div>

            <div className="field">
              <button
                className="button-stroke w-full text__center"
                type="button"
                onClick={() => {
                  const ubolist = values.uboList;
                  ubolist.splice(index, 1);
                  setValues({
                    ...values,
                    uboList: ubolist,
                  })
                  toaster.success(t('Remove UBO successfully'));
                }}
              >
                <Icon
                  className={classNames('close fill__red')}
                  name='close'
                  size="24"
                />{" "}
                {t('Remove UBO')}
              </button>
            </div>

            <div className="field">
              <div className="field__label">
                {t('Politically Exposed Persons')}
              </div>

              <div className={
                classNames(
                  'field__wrap field__wrap-radio field__wrap-radio_vertical field__wrap-radio_text_gray',
                  styles.field__wrap
                )
              }>
                <RadioGroup
                  value={ubo.personType}
                  onChange={({ target: { value }}) => {
                    setValues({
                      ...values,
                      uboList: values.uboList.map((oldUbo, i) => (i === index ? { ...ubo, personType: value } : oldUbo)),
                    })
                  }}
                >
                  { PEP_OPTIONS.map((name) => (
                      <FormControlLabel
                        key={name}
                        label={t(name)}
                        value={name}
                        control={<Radio color="primary" />}
                      />
                    ))}
                </RadioGroup>
              </div>
            </div>

            { ubo.personType && ubo.personType !== 'notPoliticalPerson' &&
              <div className="settings__row flex__row flex__wrap have__fields">
                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  label={t('Exact description of the function')}
                  value={ubo.duties}
                  onChange={({ target: { value }}) => {
                    setValues({
                      ...values,
                      uboList: values.uboList.map((oldUbo, i) => (i === index ? { ...ubo, duties: value } : oldUbo)),
                    })
                  }}
                />

                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  label={t('Relationship to PEP')}
                  value={ubo.relationshipToPEP}
                  onChange={({ target: { value }}) => {
                    setValues({
                      ...values,
                      uboList: values.uboList.map((oldUbo, i) => (i === index ? { ...ubo, relationshipToPEP: value } : oldUbo)),
                    })
                  }}
                />
              </div>
            }

            <div className="space space__32">{' '}</div>
          </div>
        ))}

        <div className="settings__fieldset">
          <div className="field easy-onboarding__addnewubo">
            <div className="field__wrap">
              <button className="button-stroke w-full text__center" type="button" onClick={() => setShowAddForm(true)}>
                <Icon
                  className={classNames('check')}
                  name='check'
                  size="24"
                />{" "}
                {t('Add new UBO')}
              </button>

              <div className="space space__16">{' '}</div>
            </div>
          </div>
        </div>

        {showAddForm && (
          <div className="settings__fieldset">
            <div className="field__label">
              {t('Select a director for UBO')}
            </div>

            <div className={classNames('field__wrap field__wrap-select', styles.select)}>
              <TextField
                select
                margin="none"
                variant="outlined"
                fullWidth
                value={selectedAddDirector}
                onChange={({target: {value}}) => {
                  setSelectedAddDirector(value);
                  const director = values.directors.filter(director => {
                    return !values.uboList.map(ubo => ubo.email).includes(director.email) && director.email !== (values.directorAsUbo || {}).email
                  })[value];

                  const data = {
                    firstName: director.firstName,
                    lastName: director.lastName,
                    email: director.email,
                  }

                  setValues({
                    ...values,
                    uboList: [
                      ...values.uboList,
                      { ...data, status: 'pending', ownership: 0 }
                    ]
                  })
                  setNewUbo(defaultNewUbo)
                  setShowAddForm(false);
                }}
              >
                { values.directors.filter(director => {
                    return !values.uboList.map(ubo => ubo.email).includes(director.email) && director.email !== (values.directorAsUbo || {}).email
                  }).map((director, index) => (
                  <MenuItem value={index} key={index}>
                    {director.firstName} {director.lastName}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <InputThemeField
              classWrapper="field easy-onboarding__firstname"
              classLabel="field__label"
              classInput="field__input"
              required
              label={t('First name')}
              value={newUbo.firstName}
              onChange={({ target: { value }}) => {
 setNewUbo({
                ...newUbo,
                firstName: value.trim(),
              })
}}
              error={uboError.firstName ? [uboError.firstName] : undefined}
            />

            <InputThemeField
              classWrapper="field easy-onboarding__surname"
              classLabel="field__label"
              classInput="field__input"
              required
              label={t('Surname')}
              value={newUbo.lastName}
              onChange={({ target: { value }}) => {
 setNewUbo({
                ...newUbo,
                lastName: value.trim(),
              })
}}
              error={uboError.lastName ? [uboError.lastName] : undefined}
            />

            <InputThemeField
              classWrapper="field easy-onboarding__emailaddress"
              classLabel="field__label"
              classInput="field__input"
              required
              label={t('Email address')}
              value={newUbo.email}
              onChange={({ target: { value }}) => {
 setNewUbo({
                ...newUbo,
                email: value.trim(),
              })
}}
              error={uboError.email ? [uboError.email] : undefined}
            />

            <div className="field easy-onboarding__addubo">
              <div className="field__wrap">
                <button className="button" type="button" onClick={addNewUbo}>
                  {t('Add UBO')}
                </button>
              </div>
            </div>

          </div>
        )}
        <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
          <div className="field easy-onboarding__uboterms">
            <CardInformation
              color="card__information-primary"
              >
              <div className={styles.card__information_custom}>
                <p>{t('Definition of a beneficial owner according to Liechtenstein legislation')}</p>
                <p>{t('Foundations, trusteeships and establishments (with a structure similar to a foundation)')}</p>
                <p>
                  <strong>
                    {t('Art. 3 (1) lit. b DDO')}
                  </strong>
                </p>
                <ul>
                  <li>
                    {t('Founder or settlor')}
                  </li>
                  <li>
                    {t('Member of the foundation board or board of directors of the trustee')}
                  </li>
                  <li>
                    {t('Protectors')}
                  </li>
                  <li>
                    {t('Beneficiaries')}
                  </li>
                  <li>
                    {t('Natural persons, who ultimately control the legal entity')}
                    {' '}
                    {t('Corporations and establishments (with a structure similar to a corporation)')}
                  </li>
                </ul>
                <p>
                  <strong>
                    {t('Art. 3 (1) lit. a DDO')}
                  </strong>
                </p>
                <ul>
                  <li>
                    {t('25 % or more of the company')}
                  </li>
                  <li>
                    {t('Exercise control')}
                  </li>
                </ul>
                <p>{t('Fall Back Clause: Member of the executive body', {keySeparator: '>', nsSeparator: '|'})}</p>
              </div>
            </CardInformation>
          </div>
        </div>

        <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
          <button className="button-stroke" onClick={goToPrevStep}>{t('Previous')}</button>
          <button className="button" onClick={goToNextStep}>{t('Next')}</button>
        </div>
      </div>
    </div>
  )
}

export default CardForm
