import React from 'react'

const MessageViewer = ({
  message,
}) => {
  const paragraphs = message.split('\n').map(line => {
    const links = line.match(/\bhttps?:\/\/[^ ]+/gm)

    if (!links) {
      return line;
    }

    return line.replace(/\bhttps?:\/\/[^ ]+/gm, '\n[[link]]\n').split('\n').map((part, index) => {
      if (part === '[[link]]') {
        const url = links.pop();

        return <a key={index} href={url} target="_blank" rel="noopener noreferrer">{url}</a>
      }

      return part
    })
  })

  return (
    <div>
      {paragraphs.map((paragraph, index) => (
        <React.Fragment key={index}>
          {paragraph}
          <br />
        </React.Fragment>
      ))}
    </div>
  )
}

export default MessageViewer
