import gql from 'graphql-tag';

export const ADD_TO_BLACKLIST = gql`
  mutation addToBlacklist($input: BlacklistItemInput) {
    addToBlacklist(input: $input)
  }
`;

export const REMOVE_FROM_BLACKLIST = gql`
  mutation removeFromBlacklist($id: ID!) {
    removeFromBlacklist(id: $id)
  }
`;