import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
} from '@material-ui/core'

import {
  getFileUrl,
  getExternalFileUrl,
} from '../utils/file'

const CustomeUploadPreview = ({ upload, contentType }) => {
  if (contentType && contentType.includes('image')) {
    return <img alt={upload.storage_key} src={getFileUrl(upload.storage_key)} />
  }
  if (contentType && contentType.includes('video')) {
    return (
      <video width="320" height="240" controls>
        <source src={getFileUrl(upload.storage_key)} type={contentType} />
      </video>
    )
  }
  if (contentType && contentType === 'application/pdf') {
    return (
      <embed
        src={getFileUrl(upload.storage_key)}
        width="700"
        height="700"
        type="application/pdf"
      />
    )
  }
  return <></>
}

const AdminKycFileViewer = ({
  file,
}) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CustomeUploadPreview
        upload={file}
        contentType={file.mime_type}
      />
      <CardContent>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
        >
          {file.storage_key}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          href={`${getExternalFileUrl(file.storage_key)}`}
          target="_blank"
        >
          { t('Open') }
        </Button>
      </CardActions>
    </Card>
  )
}

AdminKycFileViewer.propTypes = {
  file: PropTypes.object.isRequired,
}

export default AdminKycFileViewer
