import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Dialog,
  CircularProgress,
} from '@material-ui/core';

import { CREATE_SENDWYRE_WALLET_ORDER_RESERVATION } from '../../queriesAndMutations/sendwyreMutation';
import { toaster } from '../../utils';
import { useMe, usePublicSettings } from '../../myHooks';
import ModalInnerMaterialDialog from '../ModalInnerMaterialDialog'
import { COUNTRY_BY_ALPHA_2_CODE } from '../../constants/country'

import '../../styles/legacy/style.scss';

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: '10px 0 26px 0',
    },
  },
}));

const TopUpAmountDialog = ({
  dialogOpen,
  onCloseDialog,
  onTopUpSuccess,
  sendWyreAddress,
  currency,
  amount,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    data: { me: userData },
    refetch,
  } = useMe();
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const [values, setValues] = useState({
    is_submit_topup_success: false,
    sendwyre_wallet_payment_url: '',
    ...userData,
  });

  const [createWalletOrderReservation, createWalletOrderReservationStatus] = useMutation(
    CREATE_SENDWYRE_WALLET_ORDER_RESERVATION
  );

  const submitCreateWalletOrderReservation = async () => {
    const bodyData = {
      amount: amount,
      sourceCurrency: 'USD',
      destCurrency: currency,
      referrerAccountId: publicSettings && publicSettings.sendwyre_account_id,
      email: userData.email,
      dest: sendWyreAddress,
      firstName: userData.first_name,
      lastName: userData.last_name,
      phone: userData.phone,
      country: userData.customer && userData.customer.countryOfResidence && COUNTRY_BY_ALPHA_2_CODE[userData.customer.countryOfResidence] ?
        COUNTRY_BY_ALPHA_2_CODE[userData.customer.countryOfResidence] :
        null,
      postalCode: userData.customer && userData.customer.postalCode ? userData.customer.postalCode : null,
      street1: userData.customer && userData.customer.residentalAddress ? userData.customer.residentalAddress : null,
      city: userData.customer && userData.customer.city ? userData.customer.city : null,
      paymentMethod: 'debit-card',
      redirectUrl: window.location.origin,
      failureRedirectUrl: window.location.origin,
      lockFields: ["destCurrency", "dest"],
    };

    await createWalletOrderReservation({
      variables: { input: { data: bodyData } },
    }).then((response) => {
      console.log("createWalletOrderReservation")
      console.log(response);
      if (response.data.createWalletOrderReservation.status === 'success') {
        toaster.success(response.data.createWalletOrderReservation.message);
        setValues({
          ...values,
          is_submit_topup_success: true,
          sendwyre_wallet_payment_url:
            response.data.createWalletOrderReservation.url,
        });
        onTopUpSuccess(true);
      } else {
        toaster.error("TopUp failed!");
      }
    }).catch(() => {});
    await refetch();
  };

  useEffect(() => {
    submitCreateWalletOrderReservation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paymentLink = () => (
    <div>
      <p>
        { t('Open the link below on new tab and following up to complete top up to Sendwyre wallet')}:
      </p>
      <a
        href={values.sendwyre_wallet_payment_url}
        target='_blank'
        rel='noopener noreferrer'
      >
        { t('PAYMENT LINK') }
      </a>
    </div>
  );

  const renderTopUpDialog = () => (
    <Dialog
      className={classes.dialog}
      open={dialogOpen}
      onClose={onCloseDialog}
    >
      <ModalInnerMaterialDialog
        modalSize="popup__kyc-medium"
        onClickClose={onCloseDialog}
        modalTitleClassColor="title-primary"
        modalTitle={ t('Top-up information') }
      >
        <div className="description__row flex__row flex__wrap">
          {createWalletOrderReservationStatus.loading && <CircularProgress />}
          {!createWalletOrderReservationStatus.loading && (
            <>
              {values.is_submit_topup_success ? paymentLink() : (
                <p className="text__red">Failed to create top-up request</p>
              )}
            </>
          )}
        </div>
      </ModalInnerMaterialDialog>
    </Dialog>
  );

  return <div>{renderTopUpDialog()}</div>;
};

TopUpAmountDialog.propTypes = {
  dialogOpen: PropTypes.bool,
  onCloseDialog: PropTypes.func.isRequired,
};

export default TopUpAmountDialog;
