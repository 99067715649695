import gql from 'graphql-tag'

export const ADD_TOKEN_SWAP = gql`
  mutation addTokenSwap($input: AddTokenSwapInput!) {
    addTokenSwap(input: $input)
  }
`;

export const UPDATE_TOKEN_SWAP = gql`
  mutation updateTokenSwap($input: UpdateTokenSwapInput!) {
    updateTokenSwap(input: $input)
  }
`;

export const IMPORT_TOKEN_SWAP_CSV = gql`
  mutation importTokenSwapCsv($input: ImportTokenSwapCsvInput!) {
    importTokenSwapCsv(input: $input)
  }
`;

export const DELETE_TOKEN_SWAP = gql`
  mutation deleteTokenSwap($ids: [Int!]!) {
    deleteTokenSwap(ids: $ids)
  }
`;
