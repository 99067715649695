import React from "react";

const CustomInput = ({
  // eslint-disable-next-line react/prop-types
  propertyName,
  value,
  onChange,
  type = "text",
  name = "",
  placeholder,
  isRequired,
  disabled,
  error = [],
  loading = false,
  helperText,
} = {}) => {
  return (
    <>
      <input
        className="field__input"
        value={value}
        onChange={onChange}
        type={type || "text"}
        name={name}
        placeholder={placeholder}
        disabled={disabled || loading}
      />
      {helperText && (
        <div className="field__helper">
          {helperText}
        </div>
      )}
    </>
  );
};

export { CustomInput as default };
