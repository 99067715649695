import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from "react-outside-click-handler"
import AdminUploadPreview from './AdminUploadPreview'
import OnfidoReports from './OnfidoReports'

const OnfidoApplicant = ({
  applicant,
}) => {
  const { t } = useTranslation()

  const completedChecks = (
    applicant
    && applicant.onfidoChecks
    && applicant.onfidoChecks.sort((a, b) => a.id - b.id).filter(check => check.status === 'complete')
    ) || []

  const applicantNav = ['Onfido Document', 'Onfido Live Photos', 'Onfido Live Videos', 'Document PDF'];
  const [activeApplicantTab, setActiveApplicantTab] = useState(applicantNav[0]);

  const [visibleApplicantRWD, setApplicantRWD] = useState(false)

  const handleApplicantRWDClick = (x) => {
    setActiveApplicantTab(x)
    setApplicantRWD(false)
  }

  const livenessCompletedCheck = completedChecks.filter(completedCheck => {
    const reports = completedCheck.onfidoReports.filter(report => report.name !== 'watchlist_enhanced')

    if (reports.length) {
      return true
    } else {
      return false
    }
  });

  if (!livenessCompletedCheck.length) {
    return ""
  }

  return (
    <>
      <div className="card">
        <div className="card__head">
          <div className="title-clear card__title">
            {t('ApplicantID')}: {' '}
          </div>
        </div>

        <div className="packages__content nospace__bottom">
          <span>
            {applicant.applicant_id}
          </span>
          <div className="space space__16">{' '}</div>

          <div className="card__nav tablet-hide">
            {applicantNav.map((x, index) => (
              <button
                key={index}
                className={`card__link ${x === activeApplicantTab ? "active" : ""}`}
                onClick={() => setActiveApplicantTab(x)}
              >
                {t(x)}
              </button>
            ))}
          </div>

          <div className={`custom-dropdown popup__card-nav__mobile tablet-show ${visibleApplicantRWD ? "dropdown__active" : ""}`}>
            <OutsideClickHandler onOutsideClick={() => setApplicantRWD(false)}>
              <div className="custom-dropdown__wrap">
                <div
                  className="select select__small custom-dropdown__head"
                  onClick={() => setApplicantRWD(!visibleApplicantRWD)}
                >
                  <div className="dropdown__selection current">
                    {activeApplicantTab}
                  </div>

                  <div className="dropdown__body list">
                    {applicantNav.map((x, index) => (
                      <div
                        key={index}
                        className={`dropdown__option option ${x === activeApplicantTab ? "selected" : ""}`}
                        onClick={() => handleApplicantRWDClick(x)}
                      >
                        {t(x)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </OutsideClickHandler>
          </div>

          <div className="space space__24">{' '}</div>
          <div className="space space__24">{' '}</div>
          <div className="card__content">
            {activeApplicantTab === applicantNav[0] && (
              <>
                {applicant.onfidoDocuments.length > 0 ?
                  applicant.onfidoDocuments.map((upload, index) => (
                    <AdminUploadPreview
                      key={`${upload.storage_name}_${index}`}
                      upload={upload}
                      contentType={upload.mime_type}
                    />
                  )
                  ) : t('Not provided')
                }
              </>
            )}

            {activeApplicantTab === applicantNav[1] && (
              <>
                {applicant.onfidoLivePhotos &&
                  applicant.onfidoLivePhotos.length > 0 ?
                  applicant.onfidoLivePhotos.map((upload, index) => (
                    <AdminUploadPreview
                      key={`${upload.storage_name}_${index}`}
                      upload={upload}
                      contentType={upload.mime_type}
                    />
                  )
                ) : t('Not provided') }
              </>
            )}

            {activeApplicantTab === applicantNav[2] && (
              <>
                {applicant.onfidoLiveVideos &&
                  applicant.onfidoLiveVideos.length > 0 ?
                  applicant.onfidoLiveVideos.map((upload, index) => (
                    <AdminUploadPreview
                      key={`${upload.storage_name}_${index}`}
                      upload={upload}
                      contentType={upload.mime_type}
                    />
                )) : t('Not provided') }
              </>
            )}

            {activeApplicantTab === applicantNav[3] && (
              <>
                {livenessCompletedCheck.length > 0 && livenessCompletedCheck.map((completedCheck) => {
                  return (
                    <div key={completedCheck.id} className="product__overview">
                      <div className="product__line">
                        <div className="product__author">
                          {t('CheckID')}:
                        </div>
                      </div>

                      <div className="product__gallery" style={{ marginTop: "16px" }}>
                        {completedCheck.onfidoCheckPdf && (
                          <AdminUploadPreview
                            upload={completedCheck.onfidoCheckPdf}
                            contentType="application/pdf"
                          />
                        )}
                      </div>
                    </div>
                  )
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default OnfidoApplicant
