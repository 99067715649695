// eslint-disable-next-line import/prefer-default-export

export const TOKEN_TYPES = {
  BOND: 'BOND',
  EQUITY: 'EQUITY',
  UTILITY: 'UTILITY',
  NONE: 'NONE',
  LIQUIDATION: 'LIQUIDATION',
}

export const PROSPECTUS_OR_ISSUING = {
  PROSPECTUS: 'PROSPECTUS',
  ISSUING: 'ISSUING',
  TOKEN_SALE_AGREEMENT: 'TOKEN_SALE_AGREEMENT',
  TOKEN_OFFERING_MEMORANDUM: 'TOKEN_OFFERING_MEMORANDUM',
  SAFE: 'SAFE',
}

export const DISCOUNT_PHASE = [
  {
    id: 1,
    label: 'Phase 1: Private Sale',
  },
  {
    id: 2,
    label: 'Phase 2: Private Sale',
  },
  {
    id: 3,
    label: 'Phase 3: Public Sale',
  },
]

export const CRYPTO_CURRENCIES = ['eth', 'usdc', 'usdt', 'xdc', 'polygon', 'dai']
export const FIAT_CURRENCIES = ['usd', 'eur', 'chf']
