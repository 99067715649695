import React from 'react'
import '../../styles/app.min.css'
import '../../styles/style.css'
import SidebarPanel from './SidebarPanel'
import { isIE } from "react-device-detect"
import NotSupportViewer from '../NotSupportViewer'

const PageEasyOnBoarding = ({children}) => {
  if (isIE) {
    return <NotSupportViewer />;
  }

  return (
    <div className="page page_simple">
      <div className="login">
        <div className="login__col">
          <SidebarPanel />
        </div>

        <div className="login__col">
          {children}
        </div>
      </div>
    </div>
  );
}

export default PageEasyOnBoarding;
