import gql from 'graphql-tag';

export const MANUAL_FETCH_CHECK = gql`
    mutation manualFetchCheck($applicant_id: ID!) {
        manualFetchCheck(applicant_id: $applicant_id)
    }
`;

export const RECHECK_WATCHLIST = gql`
    mutation recheckWatchlist($input: RecheckWatchlistInput!) {
        recheckWatchlist(input: $input)
    }
`;
