import {
  COUNTRY_LIST,
  RESTRICTED_COUNTRY_LIST,
  RESTRICTED_NATIONALITY,
} from '../constants/customer'

export const getCountryList = (publicSettings, isCryptoadvisory) => {
  return publicSettings && publicSettings.enable_allow_list_countries
    ? String(publicSettings.allow_list_countries_choosen).split(',')
        .filter(Boolean).map(x => String(x).trim())
        .sort((a, b) => a.localeCompare(b))
    : COUNTRY_LIST.filter(c => !(isCryptoadvisory ? [] : RESTRICTED_COUNTRY_LIST).includes(c));
}

export const getNationalityList = (publicSettings, isCryptoadvisory) => {
  return publicSettings && publicSettings.enable_allow_list_nationality
    ? String(publicSettings.allow_list_nationality_choosen).split(',')
        .filter(Boolean).map(x => String(x).trim())
        .sort((a, b) => a.localeCompare(b))
    : COUNTRY_LIST.filter(c => !(isCryptoadvisory ? [] : RESTRICTED_NATIONALITY).includes(c));
}
