import React from 'react'
import PropTypes from 'prop-types'
import { omit } from 'lodash'
import Icon from './Icon'
import cn from 'classnames'
import Box from '@material-ui/core/Box'

const LockedFeatureIcon = (props) => (
  <Box
    display="inline-block"
    className="locked-feature-icon"
    title={props.hideTitle ? '' : 'This feature is not available for your current subscription'}
    style={{verticalAlign: 'text-bottom'}}
    {...omit(props, ['fontSize', 'hideTitle'])}
  >
    <Icon
      className={cn('lock')}
      name='lock'
      size="24"
    />{" "}
  </Box>
)

LockedFeatureIcon.propTypes = {
  hideTitle: PropTypes.bool
}

export default LockedFeatureIcon
