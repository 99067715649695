import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Icon from './Icon'

const useStyles = makeStyles({
  high_risk: {
    color: "#ea0b0b",
  },
  medium_risk: {
    color: "#009f65",
  },
  enhanced_risk: {
    color: "#009f65",
  },
})

const ReportView = ({ breakdown, breakdownCompare }) => {
  return (
    <>
      {breakdown &&
        breakdown !== null &&
        Object.keys(breakdown).map((item, index) => {
          if (item === 'extractedImages' || item === 'actualImageData' || item === 'expectedImageData') {
            return '';
          }

          return (
            <React.Fragment key={item}>
              <div className="summary" key={index}>
                <ul>
                  <li className="comments__favorite summary__onfido">
                    {typeof breakdown[`${item}`] !== 'object' ? (
                      breakdown[`${item}`] === "attention" ? (
                        <>
                          <Icon
                            className={classNames("close-circle")}
                            name="close-circle"
                            size={32}
                          />
                          {' '}
                          {breakdown[`${item}`]}
                        </>
                      ) : (
                        <>
                          <Icon
                            className={classNames('check-circle')}
                            name='check-circle'
                            size={32}
                          />
                          {breakdown[`${item}`]}
                        </>
                      )
                    ) : ''}
                    {item}
                  </li>
                </ul>
              </div>
              {typeof breakdown[`${item}`] === 'object' && (
                <>
                  <ReportView
                    breakdown={breakdown[`${item}`]}
                    breakdownCompare={
                      breakdownCompare &&
                      breakdownCompare[`${item}`]
                    }
                  />
                </>
              )}
            </React.Fragment>
          );
        })}
    </>
  );
};

const ComplycubeReports = ({
  reports,
  calculatedRiskAssessment,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const riskLabelHelper = (level) => {
    level = level || "";
    return (
      <p className={classes[`${level.toLowerCase()}_risk`]}>
        {`${level}`} { t('risk') }
      </p>
    );
  };

  return (
    <div className="field__wrap drafts">
      <div className="drafts__list">
        {reports.length > 0 &&
          reports.map((report, index) => {
            return (
              <div key={index} className="summary">
                <ul>
                  <li className="comments__favorite summary__onfido">
                    {report.outcome !== "clear" ? (
                      <>
                        <Icon
                          className={classNames("close-circle")}
                          name="close-circle"
                          size={32}
                        />
                        <span className="status-red-dark">
                          {report.result && report.result.toUpperCase()}
                        </span>
                        <span>{", "}</span>
                        <span className="status-red-dark">
                          {report.sub_result && report.sub_result.toUpperCase()}
                        </span>
                      </>
                    ) : (
                      <>
                        {report.result && (
                          <Icon
                            className={classNames('check-circle')}
                            name='check-circle'
                            size={32}
                          />
                        )}
                      </>
                    )}

                    {report.name === "watchlist_enhanced" &&
                      calculatedRiskAssessment &&
                      calculatedRiskAssessment.benificial_owner &&
                      riskLabelHelper(
                        calculatedRiskAssessment.benificial_owner.level
                      )}
                    {report.name}
                  </li>
                </ul>
              </div>
            );
          })
        }

        {reports.length > 0 &&
          reports.map((report, index) => {
            return (
              <ReportView breakdown={report.breakdown} />
            )
          })
        }
      </div>
    </div>
  );
};

export default ComplycubeReports;
export { ReportView };
