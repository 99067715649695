import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import moment from 'moment'
import {
  TextField,
  CircularProgress,
  Dialog,
  MenuItem,
} from '@material-ui/core'
import DropdownMaterial from '../../../components/DropdownMaterial'
import ModalInnerMaterialDialog from '../../../components/ModalInnerMaterialDialog'
import InputThemeField from '../../../components/InputThemeField'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import {
  GET_BLACKLIST,
  ADD_TO_BLACKLIST,
  REMOVE_FROM_BLACKLIST,
  GET_STATIC_BLACKLIST,
  GET_AVAILABLE_FEATURES,
} from '../../../queriesAndMutations'
import { toaster, isFeatureEnabled, isAllRequiredFeaturesEnabled } from '../../../utils'
import { COUNTRY_LIST } from '../../../constants/customer'
import LockedFeatureIcon from '../../../components/LockedFeatureIcon'
import FeatureNotAvailableMessage from '../../../components/FeatureNotAvailableMessage'
import { useTranslation } from 'react-i18next'

const AdminSettingsBlacklist = () => {
  const { data: { getBlacklist: data } = {}, loading, refetch } = useQuery(GET_BLACKLIST)
  const { data: { getStaticBlacklist: staticBlacklist } = {}, loading: staticBlacklistLoading } = useQuery(GET_STATIC_BLACKLIST)
  const [addToBlacklist, addToBlacklistStatus] = useMutation(ADD_TO_BLACKLIST)
  const { data: { getAvailableFeatures: availableFeatures } = {} } = useQuery(GET_AVAILABLE_FEATURES);
  const [removeFromBlacklist] = useMutation(REMOVE_FROM_BLACKLIST)
  const defaultNewRecord = {
    first_name: '',
    last_name: '',
    date_of_birth: null,
    nationality: '',
    passport_number: '',
    company_name: '',
    comment: '',
  }
  const { t } = useTranslation()
  const [newRecord, setNewRecord] = useState({ ...defaultNewRecord })
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false)
  const updateNewRecord = (field) => (e) => {
    if (field === 'date_of_birth') {
      setNewRecord({
        ...newRecord,
        date_of_birth: e && !!e.getTime() ? moment(e).format('YYYY-MM-DD') : null,
      })
    } else if (field === 'nationality') {
      setNewRecord({
        ...newRecord,
        [field]: e,
      })
    } else {
      setNewRecord({
        ...newRecord,
        [field]: e.target.value,
      })
    }
  }

  const addRecord = () => {
    addToBlacklist({
      variables: {
        input: newRecord,
      },
    }).then((response) => {
      refetch()
      toaster.success('New record has been added')
      setNewRecord({ ...defaultNewRecord })
    }).catch(() => {})
  }
  const deleteSelectedRecord = () => {
    removeFromBlacklist({
      variables: {
        id: selectedRecord.id,
      },
    }).then((response) => {
      setIsConfirmDeleteDialogOpen(false)
      setSelectedRecord(null)
      toaster.success('Record has been deleted')
      refetch()
    }).catch(() => {})
  }
  const confirmDeleteRecord = (record) => () => {
    setSelectedRecord(record)
    setIsConfirmDeleteDialogOpen(true)
  }
  const renderRecordInfo = (record) => (
    <>
      {record.first_name && (
        <div>
          <strong>First Name:</strong>
          {' '}
          {record.first_name}
        </div>
      )}
      {record.last_name && (
        <div>
          <strong>Last Name:</strong>
          {' '}
          {record.last_name}
        </div>
      )}
      {record.date_of_birth && (
        <div>
          <strong>Date of Birth:</strong>
          {' '}
          {record.date_of_birth}
        </div>
      )}
      {record.nationality && (
        <div>
          <strong>Nationality:</strong>
          {' '}
          {record.nationality}
        </div>
      )}
      {record.passport_number && (
        <div>
          <strong>Passport Number:</strong>
          {' '}
          {record.passport_number}
        </div>
      )}
      {record.company_name && (
        <div>
          <strong>Company Name:</strong>
          {' '}
          {record.company_name}
        </div>
      )}
    </>
  )

  return (
    <>
      <div className="settings__item">
        <div className="title-primary settings__title">{ t('Blacklist') }</div>

        <div className="settings__fieldset">
          <div className="field">
            <div className="field__label">{ t('Global Blacklist') }</div>

            { staticBlacklistLoading && (
              <div className="field__wrapper">
                <CircularProgress />
              </div>
            )}

            { staticBlacklist && (
              <div className="field__wrapper products__wrapper">
                <div className="products__table products__table-blacklist">
                  <div className="products__row">
                    <div className="products__col products__col-first-clear">
                      { t('ID') }
                    </div>

                    <div className="products__col">
                      { t('Info') }
                    </div>

                    <div className="products__col">
                      { t('Comment') }
                    </div>

                    <div className="products__col">
                      { t('Create At') }
                    </div>
                  </div>

                  {staticBlacklist.length
                    ? staticBlacklist.map((row) => (
                      <div className="products__row" key={row.id}>
                        <div className="products__col products__col-first-clear">
                          <div className="products__item products__item-normal">
                            {row.id}
                          </div>
                        </div>

                        <div className="products__col">
                          <div className="products__item products__item-normal">
                            {renderRecordInfo(row)}
                          </div>
                        </div>

                        <div className="products__col">
                          <div className="products__item products__item-normal">
                            {row.comment}
                          </div>
                        </div>

                        <div className="products__col">
                          <div className="products__item products__item-normal">
                            {row.created_at}
                          </div>
                        </div>
                      </div>
                    )
                  ) :
                  (
                    <div className="products__row">
                      <div className="products__col products__col-first-clear">
                        {' '}
                      </div>
                      <div className="products__col">
                        {' '}
                      </div>
                      <div className="products__col">
                        <div className="products__item prodcts__item-normal">
                          { t('No record found') }
                        </div>
                      </div>
                      <div className="products__col">
                        {' '}
                      </div>
                      <div className="products__col">
                        {' '}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="field">
            <div className="field__label">{ t('Self-defined Blacklist')}</div>

            { loading && (
              <div className="field__wrapper">
                <CircularProgress />
              </div>
            )}

            { data && (
              <div className="field__wrapper products__wrapper">
                <div className="products__table products__table-blacklist">
                  <div className="products__row">
                    <div className="products__col products__col-first-clear">
                      { t('ID') }
                    </div>

                    <div className="products__col">
                      { t('Info') }
                    </div>

                    <div className="products__col">
                      { t('Comment') }
                    </div>

                    <div className="products__col">
                      { t('Create At') }
                    </div>

                    <div className="products__col">
                      { t('Action') }
                    </div>
                  </div>

                  {data.length
                    ? data.map((row) => (
                      <div
                        className="products__row"
                        key={row.id}
                      >
                        <div className="products__col products__col-first-clear">
                          <div className="products__item products__item-normal">
                            {row.id}
                          </div>
                        </div>

                        <div className="products__col">
                          <div className="products__item products__item-normal">
                            {renderRecordInfo(row)}
                          </div>
                        </div>

                        <div className="products__col">
                          <div className="products__item products__item-normal">
                            {row.comment}
                          </div>
                        </div>

                        <div className="products__col">
                          <div className="products__item products__item-normal">
                            {row.created_at}
                          </div>
                        </div>

                        <div className="products__col products__col-have_button">
                          <div className="products__item products__item-normal">
                            <button className="button button-small" onClick={confirmDeleteRecord(row)}>
                              <DeleteForeverIcon />
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  ) :
                  (
                    <div className="products__row">
                      <div className="products__col products__col-first-clear">
                        {' '}
                      </div>
                      <div className="products__col">
                        {' '}
                      </div>
                      <div className="products__col  products__col-full">
                        <div className="products__item prodcts__item-normal">
                          { t('No record found') }
                        </div>
                      </div>
                      <div className="products__col">
                        {' '}
                      </div>
                      <div className="products__col">
                        {' '}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

      </div>

      <div className="settings__item">
        <div className="title-primary settings__title">
          { t('Add new record') }
          {' '}
          {isFeatureEnabled('INTERNAL_BLACKLIST', availableFeatures) || <LockedFeatureIcon />}
        </div>

        <div className="settings__fieldset">
          {addToBlacklistStatus.loading ? (
            <CircularProgress />
          ) : (isFeatureEnabled('INTERNAL_BLACKLIST', availableFeatures) && (
            <>
              <div className="settings__row flex__row flex__wrap have__fields have__space">
                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  label={ t('First name') }
                  value={newRecord.first_name}
                  onChange={updateNewRecord('first_name')}
                />

                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  label={ t('Last name') }
                  value={newRecord.last_name}
                  onChange={updateNewRecord('last_name')}
                />

                <div className="field">
                  <div className="field__label">
                    { t('Date of Birth') }
                  </div>
                  <div className="field__wrap field__wrap-date">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        openTo="year"
                        views={["year", "month", "date"]}
                        value={newRecord.date_of_birth}
                        onChange={updateNewRecord('date_of_birth')}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>

                <DropdownMaterial
                  label={ t('Nationality') }
                  state={newRecord.nationality}
                  setState={updateNewRecord('nationality')}
                >
                  {COUNTRY_LIST.map((country) => (
                    <MenuItem key={country} value={(country || "")}>
                      {country}
                    </MenuItem>
                  ))}
                </DropdownMaterial>

                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  label={ t('Passport Number') }
                  value={newRecord.passport_number}
                  onChange={updateNewRecord('passport_number')}
                />

                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  label={ t('Company Name') }
                  value={newRecord.company_name}
                  onChange={updateNewRecord('company_name')}
                />
              </div>

              <div className="field">
                <div className="field__label">
                  { t('Comment') }
                </div>

                <div className="field__wrap field__wrap-textarea">
                  <TextField
                    multiline
                    rows="4"
                    rowsMax="4"
                    value={newRecord.comment}
                    onChange={updateNewRecord('comment')}
                  />
                </div>
              </div>

              <div className="field">
                <div className="field__wrapper">
                  <button
                    className="button button-stroke settings__button"
                    onClick={addRecord}
                  >
                    { t('Add to Blacklist') }
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>

        {isAllRequiredFeaturesEnabled(['INTERNAL_BLACKLIST'], availableFeatures) || <FeatureNotAvailableMessage />}
      </div>

      <Dialog
        open={isConfirmDeleteDialogOpen}
        onClose={() => {setIsConfirmDeleteDialogOpen(false)}}
      >
        <ModalInnerMaterialDialog
          modalSize="popup__kyc-medium"
          onClickClose={() => {setIsConfirmDeleteDialogOpen(false)}}
          modalTitleClassColor="title-primary"
          modalTitle={ t('Delete confirmation')}
        >
          <div className="description__row flex__row felx__wrap">
            <div className="refund__content">
              {selectedRecord && renderRecordInfo(selectedRecord)}

              <p>{ t('Do you really want to delete this record?') }</p>
            </div>
            {selectedRecord && (
              <>
                <span className="space space__32">{' '}</span>
                <button
                  className="button-stroke"
                  onClick={deleteSelectedRecord}
                >
                  { t('Delete') }
                </button>
              </>
            )}
          </div>
        </ModalInnerMaterialDialog>
      </Dialog>
    </>

  )
}

export default AdminSettingsBlacklist
