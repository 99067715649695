import React, { useEffect, useState } from 'react'
import Web3 from 'web3'
import { uniq } from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import {
  GET_TOKEN_REGISTRY
} from '../queriesAndMutations'
import contractAbi from '../constants/contractAbi'
import metamaskService from '../services/metamask'
import { usePublicSettings } from '../myHooks'

const AdminTokenRegistry = () => {
  const { data } = useQuery(GET_TOKEN_REGISTRY)
  const { t } = useTranslation()
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const [onChainTokens, setOnChainTokens] = useState([])

  const getOnChainToken = async (address) => {
    try {
      await metamaskService.requestAccounts()

      const account = metamaskService.getCurrentAddress()
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(contractAbi.crowdsale, metamaskService.formatAddress(publicSettings.smart_contract_address));

      const tokenAmount = await contract.methods.balanceOf(address).call()

      return { address, onChainAmount: tokenAmount };
    } catch (error) {
      console.log(error)
      return {}
    }
  }

  useEffect(() => {
    const getOnChainTokens = async (transactions) => {
      const promiseList = []

      const walletAddressList = uniq(transactions.map(transaction => transaction.wallet_address))

      walletAddressList.forEach(address => {
        promiseList.push(getOnChainToken(address))
      });

      const onChainList = await Promise.all(promiseList);

      setOnChainTokens(onChainList)
    }

    if (publicSettings.smart_contract_address && data && data.getTokenRegistry.length) {
      getOnChainTokens(data.getTokenRegistry)
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [publicSettings, data])

  const onChainTokensList = [...onChainTokens]

  return (
    <>
      <div className="page__title h3">{ t('Token Register') }</div>

      <div className="transactions card">
        <div className="products__head">
          <div className="title-primary products__title">{ t('Overview') }</div>
        </div>

        <div className="products__wrapper">
          <div className="products__table products__table-tokenregistry products__table-fix_label_rwd">

            <div className="products__row">
              <div className="products__col products__col-first-clear">
                { t('Owner') }
              </div>

              <div className="products__col">
                { t('DoB') }
              </div>

              <div className="products__col">
                { t('Address') }
              </div>

              <div className="products__col">
                { t('Passport Number') }
              </div>

              <div className="products__col">
                { t('Nationality') }
              </div>

              <div className="products__col">
                { t('Bank Info') }
              </div>

              <div className="products__col">
                { t('Wallet Address') }
              </div>

              <div className="products__col">
                { t('Share certificate number') }
              </div>

              <div className="products__col">
                { t('Number of Tokens (Off-Chain)') }
              </div>

              <div className="products__col">
                { t('Number of Tokens (On-Chain)') }
              </div>
            </div>

            {data && data.getTokenRegistry && data.getTokenRegistry.map((transaction) => {
              const index = onChainTokensList.findIndex(token => token.address === transaction.wallet_address)
              let onChain = {}
              let numberTrans = 0;

              if (index >= 0) {
                onChain = onChainTokensList.splice(index, 1)[0];
                numberTrans = data.getTokenRegistry
                  .filter(transaction =>
                    transaction.wallet_address === onChain.address &&
                    transaction.allocate_tx_hash
                  ).length
              }

              return (
                <div
                  className="products__row"
                  key={transaction.transaction_id}
                >
                  <div className="products__col products__col-first-clear">
                    <div className="products__label">{t('Owner')}</div>
                    <div className="products__box">
                      <div className="products__item products__item-normal">
                        {transaction.user_name}
                      </div>
                    </div>
                  </div>

                  <div className="products__col">
                    <div className="products__label">{t('DoB')}</div>
                    <div className="products__box">
                      <div className="products__item products__item-normal">
                        {transaction.birth_date || '-'}
                      </div>
                    </div>
                  </div>

                  <div className="products__col">
                    <div className="products__label">{t('Address')}</div>
                    <div className="products__box">
                      <div className="products__item products__item-normal">
                        {transaction.address}
                      </div>
                    </div>
                  </div>

                  <div className="products__col">
                    <div className="products__label">{t('Passport Number')}</div>
                    <div className="products__box">
                      <div className="products__item products__item-normal">
                        {transaction.passport_number || '-'}
                      </div>
                    </div>
                  </div>

                  <div className="products__col">
                    <div className="products__label">{t('Nationality')}</div>
                    <div className="products__box">
                      <div className="products__item prodcts__item-normal">
                        {transaction.nationality || '-'}
                      </div>
                    </div>
                  </div>

                  <div className="products__col">
                    <div className="products__label">{t('Bank Info')}</div>
                    <div className="products__box">
                      <div className="products__item prodcts__item-normal">
                        <div>
                          {transaction.iban && (
                            <p>IBAN: {transaction.iban}</p>
                          )}
                          {transaction.bank_name && (
                            <p>{transaction.bank_name}</p>
                          )}
                          {transaction.bank_address && (
                            <p>{transaction.bank_address}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="products__col">
                    <div className="products__label">{t('Wallet')}</div>
                    <div className="products__box">
                      <div className="products__item products__item-normal">
                        {transaction.wallet_address}
                      </div>
                    </div>
                  </div>

                  <div className="products__col">
                    <div className="products__label">{t('Share certificate number')}</div>
                    <div className="products__box">
                      <div className="products__item products__item-normal">
                        {transaction.share_certificate_number}
                      </div>
                    </div>
                  </div>

                  <div className="products__col">
                    <div className="products__label">{t('Number of Tokens (Off-Chain)')}</div>
                    <div className="products__box">
                      <div className="products__item products__item-normal">
                        {transaction.token_amount}
                      </div>
                    </div>
                  </div>

                  <div className="products__col">
                    <div className="products__label">{t('Number of Tokens (On-Chain)')}</div>
                    <div className="products__box">
                      <div className="products__item products__item-normal">
                        { onChain.onChainAmount || '' }
                        { numberTrans !== 0 && ` (in ${numberTrans} transactions)`}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}

          </div>
        </div>
      </div>
    </>
  )
}

export default AdminTokenRegistry
