import React, { useState } from 'react'
// import "../styles/legacy/style.scss";
import { useMutation, useQuery } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { useMe } from '../myHooks'
import { SAVE_COMMENT, COMMENTS } from '../queriesAndMutations'
import ImageUpload from './ImageUpload'
import { remove } from 'lodash'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import SwitchCheckbox from './SwitchCheckbox'
import AdminUploadPreview from './AdminUploadPreview'
import { USER_RIGHT_TYPES } from '../constants/user'
import { hasUserEnoughRights } from '../utils/me'

import {
  makeStyles,
  CircularProgress,
  TextField,
  Avatar,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  textComment: {
    wordWrap: "break-word",
  },
  comment: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "4px",
    border: "1px solid #d2dde9",
    padding: "10px 30px",
    marginBottom: "5px",
    "&>div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  inputComment: {
    marginTop: "10px",
  },
  paper: {
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  boxComment: {
    maxHeight: "300px",
    overflow: "auto",
    padding: "10px",
  },
  buttonSend: {
    marginTop: "10px",
  },
  img: {
    maxHeight: "200px",
  },
}));

const AdminComment = ({ type, id, user }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data, refetch, loading } = useQuery(COMMENTS, {
    variables: {
      type,
      id,
    },
  });

  const {
    data: { me: userData },
  } = useMe();

  const [inputComment, setInputComment] = useState('');
  const [isExternal, setIsExternal] = useState(false);

  const [commentAttachedDocs, setCommentAttachedDocs] = useState([]);
  const [disabledSendCommentBtn, setDisableSendCommentBtn] = useState(false);

  const handleChangeComment = (event) => {
    setInputComment(event.target.value);
  };

  const changeCheckbox = (event) => {
    setIsExternal(event.target.checked);
  };

  const [saveComment] = useMutation(SAVE_COMMENT);

  const onChangeAttachFilesToComment = (document, documentURI) => {
    const newDocument = {
      document: document.length > 0 ? document[0] : null,
      documentURI: documentURI.length > 0 ? documentURI[0] : "",
    };

    if (newDocument.document) {
      setCommentAttachedDocs([...commentAttachedDocs, newDocument]);
    }
  };

  const handleRemoveCommentDocument = (index) => {
    const newCommentAttachedDocs = [...commentAttachedDocs];
    const removedEle = remove(newCommentAttachedDocs, (file, indx) => {
      return indx === index;
    });

    if (removedEle) {
      setCommentAttachedDocs(newCommentAttachedDocs);
    }
  };

  const onClickSaveComment = () => {
    setDisableSendCommentBtn(true);
    saveComment({
      variables: {
        input: {
          id,
          type,
          comment: inputComment,
          isExternal,
          userId: +userData.id,
          documents: commentAttachedDocs.map((uploadObj) => uploadObj.document),
        },
      },
    })
      .then(() => {
        setInputComment("");
        setIsExternal(false);
        setCommentAttachedDocs([]);
        refetch();
        setDisableSendCommentBtn(false);
      })
      .catch((error) => {
        setDisableSendCommentBtn(false);
      });
  };

  return (
    <>
      {loading ? (
        <div className={classes.circularProgressWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="comments__list nospace__top">
            {data.comments.objects === null && (
              <div className="comments__item">
                <div className="comments__line">
                  { t('Not comment') }
                </div>
              </div>
            )}
            {data.comments.objects !== null &&
              data.comments.objects.map((comment) => (
                <div key={comment.id} className="comments__item">
                  <div className="comments__avatar">
                    <Avatar>{comment.user.first_name[0]}</Avatar>
                  </div>

                  <div className="comments__details">
                    <div className="comments__line">
                      <div className="comments__author">
                        {comment.user.first_name}{" "}
                        {comment.user.last_name}
                      </div>

                      <div className="comments__time">
                        {comment.is_external ? "External" : "Internal"}
                      </div>
                    </div>

                    <div className="comments__login">
                      {moment(parseInt(comment.created_at)).tz('Europe/Vaduz').format('MMM DD, YYYY HH:mm z')}
                    </div>

                    <div className="comments__content">
                      {comment.comment}

                      {comment.uploads &&
                        comment.uploads.length > 0 &&
                        comment.uploads.map((upload, index) => {
                          return (
                            <AdminUploadPreview upload={upload} />
                          );
                        })}
                    </div>

                    <div className="comments__control">{' '}</div>
                  </div>
                </div>
              ))}
          </div>

          {userData && hasUserEnoughRights(
            userData.rights,
            USER_RIGHT_TYPES.COMPLIANCE_OFFICER
          ) && (
            <>
              <div className="editor">
                <div className="field">
                  <div className="field__label">
                    {t('Comment')}
                  </div>

                  <div className="field__wrap field__wrap-textarea">
                    <TextField
                      fullWidth
                      id="filled-multiline-flexible"
                      multiline
                      minRows={6}
                      maxRows={8}
                      onChange={handleChangeComment}
                      value={inputComment}
                    />
                  </div>
                </div>

                <div className="space space__32">{' '}</div>

                <div className="field">
                  <ImageUpload
                    buttonText="Choose Document"
                    buttonClassName="btn btn-primary"
                    onChange={onChangeAttachFilesToComment}
                    maxFileSize={50000000}
                    withPreview
                    singleImagePick
                    singleFile
                    defaultImages={[]}
                  >
                    <div className="summary__title">
                      <p>{ t('Drag and drop the document') }</p>
                    </div>
                  </ImageUpload>
                </div>

                {commentAttachedDocs && (
                  <>
                    <div className="space space__32">{' '}</div>
                    {commentAttachedDocs.map((file, index) => (
                      <div className="settings__top" key={index}>
                        <div className="settings__label">
                          {file.document && file.document.name}
                        </div>
                        <button
                          className="button-stroke button-small settings__button"
                          onClick={(e) => {
                            handleRemoveCommentDocument(index);
                          }}
                        >
                        { t('Remove') }
                        </button>
                      </div>
                    ))}
                  </>
                )}

                <div className="space space__32">{' '}</div>
                <SwitchCheckbox
                  defaultChecked={isExternal}
                  onChange={changeCheckbox}
                  value="secondary"
                  switchText={ t('Send on email') }
                />
              </div>

              <div className="space space__32">{ }</div>
              <button
                className="button"
                onClick={onClickSaveComment}
                disabled={disabledSendCommentBtn}
              >
                { t('Send') }
              </button>
            </>
          )}
        </>
      )}
    </>
  );
};

AdminComment.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default AdminComment;
