import React from "react"
import { useTranslation } from 'react-i18next'

const NotSupportViewer = () => {
  const { t } = useTranslation();

  return (
    <div className="page page_simple">
      <div className="entry">
        <div className="entry__wrapper" style={{maxWidth: "578px"}}>
          <div className="h2 entry__title" style={{ lineHeight: "1.25"}}>
            {t('Your browser is not currently supported.')}
          </div>
          <div className="entry__top" style={{ borderBottom: "none", marginBottom: "none", paddingBottom: "none"}}>
            <div className="shop__info">{t('Please use the latest version of Chrome, Firefox, Opera, or Safari')}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotSupportViewer
