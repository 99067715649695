// eslint-disable-next-line import/prefer-default-export
export const walletLabelTextMapping = {
  'eth': 'ETH',
  'ixo_id': 'ixo Account DID',
  'cosmos': 'ixo Account Address',
  'btc': 'BTC',
  'other': 'Others',
  'xdc': 'XDC',
  'polygon': 'Polygon',
  'dai': 'DAI',
  'usdt': 'USDT',
  'evm_compatible_wallet': 'EVM Compatible Wallet',
}

export const getSendingWalletTypeOptions = function (currency) {
  let options = []
  switch (currency) {
    case 'eth':
    case 'usdt':
      options = [['eth', 'ETH']];
      break;
    case 'usdc':
      options = [['eth', 'ETH']];
      break;
    case 'btc':
      options = [['btc', 'BTC']];
      break;
    case 'xdc':
      options = [['xdc', 'XDC']];
      break;
    case 'dai':
      options = [['polygon', 'Polygon']];
      break;
    default:
      break;
  }
  return options;
}
