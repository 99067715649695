import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import { useMe } from '../myHooks/index'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  TRANSACTIONS_USER,
  CHANGE_TRANSACTION_STATUS,
} from '../queriesAndMutations'
import { makeStyles } from '@material-ui/core'
import { toaster } from '../utils/toaster'
import { TRANSACTIONS_STATUS_TYPES } from '../constants/transaction'
import { useTranslation } from 'react-i18next'
import LineInformation from '../components/InformationLine'

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
  },
}));

const VoltSuccessPage = () => {
  const classes = useStyles();
  const { data: { me } = {} } = useMe()
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation();
  const { data: { transactionsUser: transactionsUserList } = {} } = useQuery(
    TRANSACTIONS_USER,
    {
      variables: {
        input: {
          page: 0,
          pageSize: 1,
          userId: me.id,
        },
      }
    }
  );
  const [changeTransactionStatus, { error: errorChangeStatus }] = useMutation(
    CHANGE_TRANSACTION_STATUS
  );

  function updateStatusTransactionApproved() {
    console.log("updateStatusTransactionApproved");
    changeTransactionStatus({
      variables: {
        id: transactionsUserList.objects[0].id,
        status: TRANSACTIONS_STATUS_TYPES.APPROVED,
      },
    }).then((response) => {
      if (response && response.data && response.data.changeTransactionStatus) {
        setLoading(false);
        toaster.success('Update status of transaction success!');
      }
    }).catch(() => {});
  }

  if (errorChangeStatus) {
    toaster.error(`${errorChangeStatus.message}`);
  }

  useEffect(() => {
    if (transactionsUserList && transactionsUserList.objects.length > 0) {
      const transaction = transactionsUserList.objects[0];

      if (transaction.status !== TRANSACTIONS_STATUS_TYPES.APPROVED) {
        updateStatusTransactionApproved();
      } else {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsUserList]);

  return (
    <>
      <div className="card">
          {loading ? (
            <div className={classes.circularProgressWrapper}>
              <CircularProgress />
            </div>
          ) : (
            ""
          )}
          <LineInformation
            informationColor="box__information-green"
            icon="check-all"
            isJustifyCenter
            text={
              <>
                { t('Payment success! Please go to transactions dashboard to check status of this payment!') }
                {' '}
                <NavLink to="/transactions">
                  { t('View Transaction') }
                </NavLink>
              </>
            }
          />
          <span className="space space__16">{' '}</span>
      </div>
    </>
  );
};

export default VoltSuccessPage;
