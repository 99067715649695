import React from 'react'
import classNames from 'classnames'
import useDarkMode from 'use-dark-mode'
import Icon from '../Icon'
import styles from './styles.module.scss'

const ThemeToggle = () => {
  const darkMode = useDarkMode(false);

  return (
    <label className={classNames('theme js-theme', styles.label__toggle_theme)}>
      <input
        type="checkbox"
        className="theme__input"
        checked={darkMode.value}
        onChange={darkMode.toggle}
      />
      <span className="theme__inner">
        <span className="theme__box">
          <Icon
            className={classNames('sun', 'icon-24')}
            name="sun"
            size="24"
          />
        </span>

        <span className="theme__box">
          <Icon
            className={classNames('moon', 'icon-24')}
            name="moon"
            size="24"
          />
        </span>
      </span>
    </label>
  )
}

export default ThemeToggle
