import React from 'react'
import { Box } from '@material-ui/core'

const Maintain = (email) => (
  <Box display="flex" justifyContent="center" alignItems="center" style={{minHeight: '100vh'}}>
    <div style={{maxWidth: '800px', textAlign: 'center'}}>
      <h1>The site is under construction.</h1>
      {email && (
        <h3>
        Contact:
        {' '}
        <a href={`mailto:${email}`}>{email}</a>
        </h3>
      )}
    </div>

  </Box>
)

export default Maintain
