import gql from 'graphql-tag';

export const GET_ONFIDO_WATCHLIST = gql`
    query getOnfidoWatchlist($input: GetOnfidoWatchlistInput!) {
        getOnfidoWatchlist(input: $input)
    }
`;

export const GET_ONFIDO_WATCHLIST_COST = gql`
    query getOnfidoWatchlistCost($type: String!, $ownerIds: [Int!]) {
        getOnfidoWatchlistCost(type: $type, ownerIds: $ownerIds)
    }
`;
