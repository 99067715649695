import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress
} from '@material-ui/core'

import {
  IMPORT_TOKEN_SWAP_CSV
} from '../../queriesAndMutations'
import ImageUpload from '../../components/ImageUpload'

const ImportTokenSwapForm = ({
  onComplete
}) => {
  const { t } = useTranslation();
  const [uploadFile, setUploadFile] = useState(null)
  const [importTokenSwapCsv, importTokenSwapCsvStatus] = useMutation(IMPORT_TOKEN_SWAP_CSV)

  const onFileChange = ([file]) => {
    setUploadFile(file)
  }

  const submitImportTokenSwapCsv = () => {
    importTokenSwapCsv({
      variables: {
        input: {
          file: uploadFile
        }
      }
    }).then(() =>
      onComplete && onComplete()
    ).catch(() => {})
  }

  return (
    <div>
      {!uploadFile && (
        <ImageUpload
          buttonText="Choose CSV"
          buttonClassName="button"
          onChange={onFileChange}
          maxFileSize={50000000}
          singleImagePick
          singleFile
          defaultImages={[]}
          imgExtension={['.csv']}
        >
          <div className="summary__title">
            <p>{ t('Drag and drop the CSV') }</p>
          </div>
        </ImageUpload>
      )}

      {uploadFile && (
        <div>
          <br />
          {uploadFile.name}
          {' '}
          <span className="text__red" style={{ cursor: 'pointer'}} onClick={() => { setUploadFile(null) }}>Remove</span>
        </div>
      )}

      <br />

      {importTokenSwapCsvStatus.loading && (
        <CircularProgress />
      )}
      {!importTokenSwapCsvStatus.loading && (
      <div>
        <button className="button" onClick={submitImportTokenSwapCsv}>Import</button>
      </div>
      )}
    </div>
  )
}

export default ImportTokenSwapForm
