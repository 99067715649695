import React from 'react'
import LoginRegisterLogo from '../components/LogoLoginRegister'
import FirstLoginComponent from '../components/FirstLoginComponent'
// import "../styles/legacy/style.scss";
import { useTranslation } from 'react-i18next'

const FirstLogin = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="entry entry_loginregister">
        <div className="entry__wrapper entry__wrapper-kyc">
          <LoginRegisterLogo />

          <div className="h2 entry__title">
            {t('Sign In')}
          </div>

          <div className="entry__text">
            {t('Sign in with email address')}
          </div>

          <FirstLoginComponent />
        </div>
      </div>
    </>
  );
};

export default FirstLogin;
