import gql from 'graphql-tag'

export const CONFIRM_SPOT_CHECK_COMPLETED = gql`
  mutation confirmSpotCheckCompleted($id: ID!) {
    confirmSpotCheckCompleted(id: $id)
  }
`;

export const RESCAN_SPOT_CHECK_FOR_ALL_KYC = gql`
  mutation rescanSpotCheckForAllKyc {
    rescanSpotCheckForAllKyc
  }
`;
