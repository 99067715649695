import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Web3 from 'web3'
import { CircularProgress, makeStyles } from '@material-ui/core'

import metamaskService from '../services/metamask'
import { toaster } from '../utils'
import { usePublicSettings } from '../myHooks'

const useStyles = makeStyles(() => ({
  text_action: {
    cursor: "pointer",
    color: "#3772ff",
  }
}))

const COIN_DECIMALS = {
  dai: 18,
  usdc: 6,
  eure: 18,
  usdt: 6,
  busd: 18,
}

const CrowdsaleSpendAllowance = ({
  minAllowance,
  currency,
  setIsApproveContract,
  isApproveContract,
}) => {
  const [allowance, setAllowance] = useState(0)
  const [isApproving, setIsApproving] = useState(false)
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  const classes = useStyles()

  let coinAddress

  switch (currency) {
    case 'dai':
      coinAddress = publicSettings.smart_contract_dai_address
      break;
    case 'usdc':
      coinAddress = publicSettings.smart_contract_usdc_address
      break;
    case 'usdt':
      coinAddress = publicSettings.smart_contract_usdt_address
      break;
    case 'eure':
      coinAddress = publicSettings.smart_contract_eure_address
      break;
    case 'busd':
      coinAddress = publicSettings.smart_contract_busd_address
      break;
    default:
      coinAddress = null
  }

  const fetchAllowance = useCallback(async () => {
    await metamaskService.requestAccounts()

    const account = metamaskService.getCurrentAddress()
    const web3 = new Web3(window.ethereum)

    const contract = new web3.eth.Contract([{
      constant: true,
      inputs: [
        { name: 'owner', type: 'address' },
        { name: 'spender', type: 'address' }
      ],
      name: 'allowance',
      outputs: [
        { name: '', type: 'uint256' }
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    }], coinAddress)

    try {
      const allowance = await contract.methods.allowance(account, metamaskService.formatAddress(publicSettings.smart_contract_address)).call()
      if (allowance / (10 ** COIN_DECIMALS[currency]) > 0 && !isApproveContract) {
        setIsApproveContract(true)
      }
      setAllowance(allowance / (10 ** COIN_DECIMALS[currency]))
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicSettings])

  const allowContract = async () => {
    if (metamaskService.isMetamaskNotAvailable()) {
      toaster.error('You need to install Metamask to use this feature')

      return
    }

    setIsApproving(true)
    try {
      const account = metamaskService.getCurrentAddress()
      const web3 = new Web3(window.ethereum)

      const contract = new web3.eth.Contract([{
        constant: false,
        inputs: [
          { name: 'spender', type: 'address' },
          { name: 'value', type: 'uint256' }
        ],
        name: 'approve',
        outputs: [
          { name: '', type: 'bool' }
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      }], coinAddress)

      const approveAmount = Math.ceil(minAllowance * (10 ** COIN_DECIMALS[currency]))

      await contract.methods.approve(metamaskService.formatAddress(publicSettings.smart_contract_address), BigInt(approveAmount).toString()).send({
        from: account
      });
      await fetchAllowance()
    } catch (error) {
      toaster.error(error.message)

      console.log(error)
    }

    setIsApproving(false)
  }

  useEffect(() => {
    if (
      !publicSettings ||
      !publicSettings.enable_smart_contract ||
      publicSettings.smart_contract_type !== 'crowdsale_contract' ||
      metamaskService.isMetamaskNotAvailable()
    ) {
      return
    }

    fetchAllowance()
  }, [publicSettings, fetchAllowance])

  if (
    !publicSettings ||
    !publicSettings.enable_smart_contract ||
    publicSettings.smart_contract_type !== 'crowdsale_contract' ||
    metamaskService.isMetamaskNotAvailable()
  ) {
    return null
  }

  if (isApproving) {
    return <CircularProgress />
  }

  return (
    <>
      Contract's {currency.toUpperCase()} allowance: {allowance} {currency.toUpperCase()}.
      {' '}
      {allowance < minAllowance && (
        <span
          className={classes.text_action}
          onClick={allowContract}
        >
          Allow contract to spend {+minAllowance} {currency.toUpperCase()} for transaction.
        </span>
      )}
    </>
  )
}

CrowdsaleSpendAllowance.propTypes = {
  minAllowance: PropTypes.number.isRequired,
  currency: PropTypes.string,
  isApproveContract: PropTypes.bool,
  setIsApproveContract: PropTypes.func,
}

export default CrowdsaleSpendAllowance
