import React from 'react'
import TextField from '@material-ui/core/TextField'
import { extractMsgFromErrByPropertyName } from '../../utils'
import classNames from 'classnames'

const DropdownMaterial = ({
  // eslint-disable-next-line react/prop-types
  propertyName,
  classWrapper,
  label = "",
  suffixLabel = "",
  isRequired,
  helperText,
  state,
  setState,
  isBackgroundWhite,
  disabled,
  error=[],
  loading=false,
  children,
  SelectProps
} = {}) => {
  const errorsTexts = extractMsgFromErrByPropertyName(error, propertyName);

  const handleChange = (event) => {
    setState({ ...state, [propertyName]: event.target.value });
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <div className={classNames('field', classWrapper)} key={propertyName}>
        {label && (
          <div className="field__label" key={`label-${propertyName}`}>
            {label}{" "}
            {isRequired && (
              <span className="text__red"> *</span>
            )}
            {suffixLabel && (
              <>
                {" "}
                {suffixLabel}
              </>
            )}
          </div>
        )}

        <div className={`field__wrap field__wrap-select ${isBackgroundWhite ? "field__wrap-select-white" : ""}`} key={propertyName}>
          <TextField
            select
            value={state[propertyName] || ""}
            onChange={handleChange}
            error={errorsTexts.length !== 0}
            helperText={
              helperText || (errorsTexts && errorsTexts.join(". ")) || ""
            }
            disabled={disabled || loading}
            fullWidth
            SelectProps={SelectProps}
          >
            {children}
          </TextField>
        </div>

        {/* {helperText && (
          <div className="field__helper">
            {helperText}
          </div>
        )} */}
      </div>
    </>
  );
};

export { DropdownMaterial as default };
