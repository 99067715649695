import React from "react";

const UploadPreview = ({name, children}) => {
  return (
    <div className="summary summary__document">
      <div className="summary__preview">
        {children}

      </div>

      {name && (
        <div className="summary__line">
          <div className="summary__title">
            {name}
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadPreview;
