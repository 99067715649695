import React from 'react'
import { use100vh } from 'react-div-100vh'
import Footer from './Footer';
import "../../styles/app.min.css";
import "../../styles/style.css";
import { isIE } from "react-device-detect";
import NotSupportViewer from '../NotSupportViewer';

const PageLoginRegister = ({ children }) => {
  const heightWindow = use100vh();

  if (isIE) {
    return <NotSupportViewer />;
  }

  return (
  <div style={{ minHeight: heightWindow }} className="avoid_minheight__rwd">
    {children}

    <Footer />
  </div>
  );
}

export default PageLoginRegister;
