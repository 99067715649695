import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const ME = gql`
    query me {
        me {
            id
            email
            name
            birth_date
            phone
            is_active
            eth_receiving_wallet
            kyc_status
            video_ident_status
            video_ident_id
            wallet_address
            wallet_sending_address
            sendwyre_wallet_id
            wallet_type
            sending_wallet
            currency
            created_at
            sso_token
            is_sync_master_tenant
            verify_sms_send_at
            origin_source
            ip_country

            customer {
                id
                email
                phoneNumber
                uniqueId
                descriptionOfFunds
                descriptionOfWealth
                taxNumber
                taxOffice
                birthDate
                placeOfBirth
                nationality
                nationality_two
                nationality_three
                countryOfResidence
                residentialAddress
                city
                postalCode
                wallet
                tokenAddress
                personType
                duties
                relationshipToPEP
                documentType
                main_document_upload_id
                photo_with_me_upload_id
                back_side_document_upload_id
                proof_of_residence_upload_id
                amount
                status
                source_of_funds
                source_of_funds_other
                source_of_wealth
                source_of_wealth_other
                created_at
                updated_at
                currency
                onfidoApplicant {
                    id
                    applicant_id
                    onfidoChecks {
                        checkId
                        status
                        result
                        onfidoReports {
                            report_id
                            name
                            result
                            status
                        }
                    }
                }
                complycubeClient {
                    id
                    client_id
                    complycubeChecks {
                        check_id
                        status
                        result
                    }
                }
                discountLevelTransac
                documentESignature
                ethWalletBitRanked {
                  id
                  url
                  response
                  error
                  send_data
                  user_id
                  kyc_individual_id
                  kyc_company_id
                }
                ethSendingWalletBitRanked {
                  id
                  url
                  response
                  error
                  send_data
                  user_id
                  kyc_individual_id
                  kyc_company_id
                }
                allowChangeReceivingWallet
                allowChangeSendingWallet
                livenessCheckApplicant {
                    id
                    uuid
                    customer_id
                    director_id
                    ubo_id
                    old_customer_id
                    old_director_id
                    old_ubo_id
                    status
                    created_at
                    updated_at
                }
                is_auto_approved
                idnowIdent {
                    id
                    transactionNumber
                    identCode
                    customerId
                    oldCustomerId
                    directorId
                    oldDirectorId
                    status
                    result
                    reason
                    response
                }
            }
            customerCompany {
                id
                email
                companyName
                companyAddress
                companyRegisterNumber
                taxNumber
                taxOffice
                descriptionRegisterExtractBusinessActivity
                authorizedPersonals
                documentType
                commercialRegisterExtractImgPath
                proofOfOwnerImgPath
                proofOfUboEpresentativesImgPath
                wallet
                tokenAddress
                personType
                duties
                relationshipToPEP
                amount
                status
                created_at
                updated_at
                currency
                directorFormType
                customerCompanyDirector {
                    id
                    firstName
                    lastName
                    email
                    status
                    personType
                }
                customerCompanyUbo {
                    id
                    firstName
                    lastName
                    email
                    status
                    personType
                }
                onfidoApplicant {
                    id
                    applicant_id
                    onfidoChecks {
                        checkId
                        status
                        result
                    }
                }
                ethWalletBitRanked {
                  id
                  url
                  response
                  error
                  send_data
                  user_id
                  kyc_individual_id
                  kyc_company_id
                }
                ethSendingWalletBitRanked {
                  id
                  url
                  response
                  error
                  send_data
                  user_id
                  kyc_individual_id
                  kyc_company_id
                }
                allowChangeReceivingWallet
                allowChangeSendingWallet
            }
            customerCompanyDirector {
                id
                customerCompanyId
                user_id
                email
                firstName
                lastName
                middleName
                signatureRight
                willSignForm
                personType
                status
                phone
                taxNumber
                uniqueId
                birthDate
                placeOfBirth
                nationality
                nationality_two
                nationality_three
                countryOfResidence
                residentialAddress
                postalCode
                city
                sourceOfFunds
                reopenComment
            }
            customerCompanyUbo {
                id
                firstName
                lastName
                email
                ownership
                status
                personType
                customerCompany {
                    companyName
                }
            }
            is_2fa_enabled
            rights
            is_gtoswiss
            is_internal_sales
            token_invest_amount
            amount_contribution_gto
            first_name
            last_name
            middle_name
            bankName
            iban
            bankAddress
            sendingWithTransferwise
            bankNameTransferwise
            bankAddressTransferwise
            memberShipNumberTransferwise
            wallet_engine_token
            referral_code
            phone_verified
            verify_sms_send_time
            is_valid_liquidation_wallet
        }
    }
`;

export const ME_INVEST_IN_BASE_CURRENCY = gql`
    query meInvestInBaseCurrency {
        meInvestInBaseCurrency {
            invest_amount,
            approved_invest,
            allocated_invest
            invest_token_without_kyc,
            invest_token,
            kyc_lvl_change,
            currency,
            current_lvl,
            currencies_contribution {
                currency
                sum
            }
        }
    }
`;

export const NEW_2FA_SECRET = gql`
    query new2FASecret {
      new2FASecret
    }
  `;

export const VIDEO_ID_ATTENDED_AUTHORIZATION = gql`
   query videoIDAttendAuthorization {
       videoIDAttendAuthorization {
           status
           id
           authorization
           scriptSrc
       }
    }
`;

export const VIDEO_SCAN_AUTHORIZATION = gql`
   query videoScanAuthorization {
       videoScanAuthorization
    }
`;

export const VIDEO_ID_UNATTENDED_AUTHORIZATION = gql`
   query videoIDUnAttendAuthorization {
       videoIDUnAttendAuthorization
    }
`;

export const VIDEO_ID_RECORDED_EXTRACTED_DATA = gql`
   query videoIDRecoredExtractedData($video_ident_id: String) {
       videoIDRecoredExtractedData(video_ident_id: $video_ident_id)
    }
`;

export const SEARCH_GENERAL = gql`
   query searchGeneral($search: String!) {
       searchGeneral(search: $search)
    }
`;
