import React from "react";
import PropTypes from "prop-types";
import { Router } from "react-router-dom";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import { ApolloProvider } from "@apollo/react-hooks";
import { CookiesProvider } from "react-cookie";
import Root from "./Root";
import ErrorBoundary from "./ErrorBoundary";
import history from "./historyStore";

import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import dictionary from './lang'
import { getLocale } from './utils/lang'
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

LogRocket.init('zbqicx/kyc-saas');
setupLogRocketReact(LogRocket);

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: dictionary.en
      },
      de: {
        translation: dictionary.de
      },
      it: {
        translation: dictionary.it
      },
      bl: {
        translation: dictionary.bl
      },
      cn: {
        translation: dictionary.cn
      },
      cz: {
        translation: dictionary.cz
      },
      dk: {
        translation: dictionary.dk
      },
      es: {
        translation: dictionary.es
      },
    },
    lng: getLocale(),
    fallbackLng: 'en'
  })

const App = ({ apolloClient }) => {
  return (
    <>
      <CookiesProvider>
        <ErrorBoundary>
          <Router history={history}>
            <ApolloProvider client={apolloClient}>
              <Root />
            </ApolloProvider>
          </Router>
          <ToastsContainer
            position={ToastsContainerPosition.TOP_RIGHT}
            store={ToastsStore}
          />
        </ErrorBoundary>
      </CookiesProvider>
    </>
  );
};

App.propTypes = {
  apolloClient: PropTypes.object.isRequired,
};

export default App;
