import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/react-hooks'
import { CircularProgress } from '@material-ui/core'
import { GET_DASHBOARD_CONTRIBUTION_STATISTIC_BY_COUNTRIES } from '../../../queriesAndMutations'
import { numberWithCommas } from '../../../utils'

const flagMap = {
  'Afghanistan': '🇦🇫',
  'Aland Islands': '🇦🇽',
  'Albania': '🇦🇱',
  'Algeria': '🇩🇿',
  'American Samoa': '🇦🇸',
  'Andorra': '🇦🇩',
  'Angola': '🇦🇴',
  'Anguilla': '🇦🇮',
  'Antarctica': '🇦🇶',
  'Antigua and Barbuda': '🇦🇬',
  'Argentina': '🇦🇷',
  'Armenia': '🇦🇲',
  'Aruba': '🇦🇼',
  'Australia': '🇦🇺',
  'Austria': '🇦🇹',
  'Azerbaijan': '🇦🇿',
  'Bahamas': '🇧🇸',
  'Bahrain': '🇧🇭',
  'Bangladesh': '🇧🇩',
  'Barbados': '🇧🇧',
  'Belarus': '🇧🇾',
  'Belgium': '🇧🇪',
  'Belize': '🇧🇿',
  'Benin': '🇧🇯',
  'Bermuda': '🇧🇲',
  'Bhutan': '🇧🇹',
  'Bolivia': '🇧🇴',
  'Bonaire Sint Eustatius and Saba': '🇧🇶',
  'Bosnia and Herzegovina': '🇧🇦',
  'Botswana': '🇧🇼',
  'Bouvet Island': '🇧🇻',
  'Brazil': '🇧🇷',
  'British Indian Ocean Territory': '🇮🇴',
  'Brunei Darussalam': '🇧🇳',
  'Bulgaria': '🇧🇬',
  'Burkina Faso': '🇧🇫',
  'Burundi': '🇧🇮',
  'Cambodia': '🇰🇭',
  'Cameroon': '🇨🇲',
  'Canada': '🇨🇦',
  'Cape Verde': '🇨🇻',
  'Cayman Islands': '🇰🇾',
  'Central African Republic': '🇨🇫',
  'Chad': '🇹🇩',
  'Chile': '🇨🇱',
  'China': '🇨🇳',
  'Christmas Island': '🇨🇽',
  'Cocos Keeling Islands': '🇨🇨',
  'Colombia': '🇨🇴',
  'Comoros': '🇰🇲',
  'Congo': '🇨🇬',
  'Congo The Democratic Republic of': '🇨🇩',
  'Cook Islands': '🇨🇰',
  'Costa Rica': '🇨🇷',
  'Cote d\'Ivoire': '🇨🇮',
  'Croatia': '🇭🇷',
  'Cuba': '🇨🇺',
  'Curacao': '🇨🇼',
  'Cyprus': '🇨🇾',
  'Czech Republic': '🇨🇿',
  'Denmark': '🇩🇰',
  'Djibouti': '🇩🇯',
  'Dominica': '🇩🇲',
  'Dominican Republic': '🇩🇴',
  'Ecuador': '🇪🇨',
  'Egypt': '🇪🇬',
  'El Salvador': '🇸🇻',
  'Equatorial Guinea': '🇬🇶',
  'Eritrea': '🇪🇷',
  'Estonia': '🇪🇪',
  'Ethiopia': '🇪🇹',
  'Falkland Islands Malvinas': '🇫🇰',
  'Faroe Islands': '🇫🇴',
  'Fiji': '🇫🇯',
  'Finland': '🇫🇮',
  'France': '🇫🇷',
  'French Guiana': '🇬🇫',
  'French Polynesia': '🇵🇫',
  'French Southern Territories': '🇹🇫',
  'Gabon': '🇬🇦',
  'Gambia': '🇬🇲',
  'Georgia': '🇬🇪',
  'Germany': '🇩🇪',
  'Ghana': '🇬🇭',
  'Gibraltar': '🇬🇮',
  'Greece': '🇬🇷',
  'Greenland': '🇬🇱',
  'Grenada': '🇬🇩',
  'Guadeloupe': '🇬🇵',
  'Guam': '🇬🇺',
  'Guatemala': '🇬🇹',
  'Guernsey': '🇬🇬',
  'Guinea': '🇬🇳',
  'Guinea Bissau': '🇬🇼',
  'Guyana': '🇬🇾',
  'Haiti': '🇭🇹',
  'Heard Island and McDonald Islands': '🇭🇲',
  'Holy See Vatican City State': '🇻🇦',
  'Honduras': '🇭🇳',
  'Hong Kong': '🇭🇰',
  'Hungary': '🇭🇺',
  'Iceland': '🇮🇸',
  'India': '🇮🇳',
  'Indonesia': '🇮🇩',
  'Iran Islamic Republic of': '🇮🇷',
  'Iraq': '🇮🇶',
  'Ireland': '🇮🇪',
  'Isle of Man': '🇮🇲',
  'Israel': '🇮🇱',
  'Italy': '🇮🇹',
  'Jamaica': '🇯🇲',
  'Japan': '🇯🇵',
  'Jersey': '🇯🇪',
  'Jordan': '🇯🇴',
  'Kazakhstan': '🇰🇿',
  'Kenya': '🇰🇪',
  'Korea (South)': '🇰🇷',
  'Kosovo': '🇽🇰',
  'Kuwait': '🇰🇼',
  'Kyrgyzstan': '🇰🇬',
  'Lao Peoples Democratic Republic of': '🇱🇦',
  'Latvia': '🇱🇻',
  'Lebanon': '🇱🇧',
  'Lesotho': '🇱🇸',
  'Liberia': '🇱🇷',
  'Libyan Arab Jamahiriya': '🇱🇾',
  'Liechtenstein': '🇱🇮',
  'Lithuania': '🇱🇹',
  'Luxembourg': '🇱🇺',
  'Macao': '🇲🇴',
  'Macedonia The former Yugoslav Republic of': '🇲🇰',
  'Madagascar': '🇲🇬',
  'Malawi': '🇲🇼',
  'Malaysia': '🇲🇾',
  'Maldives': '🇲🇻',
  'Mali': '🇲🇱',
  'Malta': '🇲🇹',
  'Marshall Islands': '🇲🇭',
  'Martinique': '🇲🇶',
  'Mauritania': '🇲🇷',
  'Mauritius': '🇲🇺',
  'Mayotte': '🇾🇹',
  'Mexico': '🇲🇽',
  'Micronesia Federated States of': '🇫🇲',
  'Moldova Republic of': '🇲🇩',
  'Monaco': '🇲🇨',
  'Mongolia': '🇲🇳',
  'Montenegro': '🇲🇪',
  'Montserrat': '🇲🇸',
  'Morocco': '🇲🇦',
  'Mozambique': '🇲🇿',
  'Myanmar': '🇲🇲',
  'Namibia': '🇳🇦',
  'Nepal': '🇳🇵',
  'Netherlands': '🇳🇱',
  'New Caledonia': '🇳🇨',
  'New Zealand': '🇳🇿',
  'Nicaragua': '🇳🇮',
  'Niger': '🇳🇪',
  'Nigeria': '🇳🇬',
  'Niue': '🇳🇺',
  'Norfolk Island': '🇳🇫',
  'Norway': '🇳🇴',
  'Oman': '🇴🇲',
  'Pakistan': '🇵🇰',
  'Palau': '🇵🇼',
  'Palestinian Occupied Territory': '🇵🇸',
  'Panama': '🇵🇦',
  'Papua New Guinea': '🇵🇬',
  'Paraguay': '🇵🇾',
  'Peru': '🇵🇪',
  'Philippines': '🇵🇭',
  'Pitcairn': '🇵🇳',
  'Poland': '🇵🇱',
  'Portugal': '🇵🇹',
  'Puerto Rico': '🇵🇷',
  'Qatar': '🇶🇦',
  'Reunion': '🇷🇪',
  'Romania': '🇷🇴',
  'Russian Federation': '🇷🇺',
  'Rwanda': '🇷🇼',
  'Saint Helena Ascension and Tristan da Cunha': '🇸🇭',
  'Saint Kitts and Nevis': '🇰🇳',
  'Saint Lucia': '🇱🇨',
  'Saint Martin': '🇲🇫',
  'Saint Pierre and Miquelon': '🇵🇲',
  'Saint Vincent and the Grenadines': '🇻🇨',
  'San Marino': '🇸🇲',
  'Sao Tome and Principe': '🇸🇹',
  'Saudi Arabia': '🇸🇦',
  'Senegal': '🇸🇳',
  'Serbia': '🇷🇸',
  'Seychelles': '🇸🇨',
  'Sierra Leone': '🇸🇱',
  'Singapore': '🇸🇬',
  'Sint Maartin': '🇸🇽',
  'Slovakia': '🇸🇰',
  'Slovenia': '🇸🇮',
  'South Africa': '🇿🇦',
  'South Georgia and the South Sandwich Islands': '🇬🇸',
  'South Sudan': '🇸🇸',
  'Spain': '🇪🇸',
  'Sri Lanka': '🇱🇰',
  'Sudan': '🇸🇩',
  'Suriname': '🇸🇷',
  'Swaziland': '🇸🇿',
  'Sweden': '🇸🇪',
  'Switzerland': '🇨🇭',
  'Syrian Arab Republic': '🇸🇾',
  'Taiwan': '🇹🇼',
  'Tajikistan': '🇹🇯',
  'Tanzania United Republic of': '🇹🇿',
  'Thailand': '🇹🇭',
  'Timor Leste': '🇹🇱',
  'Togo': '🇹🇬',
  'Tokelau': '🇹🇰',
  'Tonga': '🇹🇴',
  'Trinidad and Tobago': '🇹🇹',
  'Tunisia': '🇹🇳',
  'Turkey': '🇹🇷',
  'Turkmenistan': '🇹🇲',
  'Turks and Caicos Islands': '🇹🇨',
  'Uganda': '🇺🇬',
  'Ukraine': '🇺🇦',
  'United Arab Emirates': '🇦🇪',
  'United Kingdom': '🇬🇧',
  'United States': '🇺🇸',
  'United States Minor Outlying Islands': '🇺🇲',
  'Uruguay': '🇺🇾',
  'Uzbekistan': '🇺🇿',
  'Vanuatu': '🇻🇺',
  'Venezuela Bolivarian Republic of': '🇻🇪',
  'Vietnam': '🇻🇳',
  'Virgin Islands British': '🇻🇬',
  'Virgin Islands US': '🇻🇮',
  'Wallis and Futuna': '🇼🇫',
  'Western Sahara': '🇪🇭',
  'Yemen': '🇾🇪',
  'Zambia': '🇿🇲',
  'Zimbabwe': '🇿🇼',
}

const ContributionCountries = () => {
  const { t } = useTranslation()
  const { data: { getDashboardContributionStatisticByCountries } = {} } = useQuery(GET_DASHBOARD_CONTRIBUTION_STATISTIC_BY_COUNTRIES)

  return (
    <div className="card">
      <div className="card__head">
        <div className="title-primary card__title">{t('Contribution Countries')}</div>
      </div>

      <div className="countries">
        {!getDashboardContributionStatisticByCountries && (
          <CircularProgress />
        )}
        {getDashboardContributionStatisticByCountries && getDashboardContributionStatisticByCountries.map((item) => (
          <div className="countries__item" key={item.country}>
            <div className="countries__flag">
              {flagMap[item.country]}
            </div>
            <div className="countries__title">
              {item.country}
            </div>
            <div className="countries__price">
              {numberWithCommas(item.totalContribution, 0)}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
};

export default ContributionCountries;
