import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { remove } from 'lodash'
import {
  RadioGroup,
  Radio,
  TextField,
  MenuItem,
  CircularProgress,
  FormControlLabel,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import InfoIcon from '../../Icon'
import metamaskService from '../../../services/metamask'

import {
  COUNTRY_LIST,
  NET_INVESTABLE_ASSETS_OPTIONS,
  COMPANY_LEGAL_FORMS,
  HIGH_AND_PROHIBIT_RISK_COUNTRIES,
  BUSINESS_FOLLOWING_INDUSTRIES,
} from '../../../constants/customer'
import SIC_CODES from '../../../constants/sic_code'

import {
  walletLabelTextMapping,
  getSendingWalletTypeOptions
} from '../../../constants/walletLabelTextMapping'
import {
  TOKEN_TYPES,
  CRYPTO_CURRENCIES,
  FIAT_CURRENCIES,
  PROSPECTUS_OR_ISSUING,
} from '../../../constants/settings'
import { GET_MY_TOKEN_SWAP } from '../../../queriesAndMutations'
import {
  usePublicSettings,
  useExchangeRates,
} from '../../../myHooks'
import {
  ethToFiat,
  tokenToEth,
  tokenToAnything,
  numberWithCommas,
  isCrypto,
  getExternalFileUrl,
  toLocalYmdString,
  toaster,
  getCountryList,
} from '../../../utils'

import InputThemeField from '../../InputThemeField'
import ImageUpload from '../../ImageUpload'
import KycFormSupportedSourceOfFund from '../../KycFormSupportedSourceOfFund'
import styles from './styles.module.scss'
import InputThemeLite from '../../InputThemeLite'
import WalletRequest from '../../WalletRequest'
import moment from 'moment'

const helperTextForWalletCompliant = (wallet) => {
  switch (wallet) {
    case "eth":
      return "Note: Address should be ERC20-compliant.";
    case "ixo_id":
      return "Note: Address should be IXO-ID-compliant.";
    case "cosmos":
      return "Note: Address should be Cosmos-compliant.";
    default:
      return '';
  }
}

const CardForm =({
  values,
  setValues,
  goToNextStep,
  goToPrevStep,
}) => {
  const { t } = useTranslation()
  const { data: { publicSettings = {} } = {}} = usePublicSettings()
  const { data: { exchangeRates } = {} } = useExchangeRates();
  const { data: { getMyTokenSwap: tokenSwap } = {} } = useQuery(GET_MY_TOKEN_SWAP)
  const [exactIntendedInvestmentAmountValue, setExactIntendedInvestmentAmountValue] = useState(values.exactIntendedInvestmentAmount)
  const [amountValue, setAmountValue] = useState(values.amount)
  const isCryptoadvisory = window.location.host === 'kyc.cryptoadvisory.li' || window.location.host === 'dev-kyc.cryptoadvisory.li';

  const countryList = getCountryList(publicSettings, isCryptoadvisory);
  const [checkedBusinessCountries, setCheckedBusinessCountries] = useState((values.business_countries || '').split(',').filter(Boolean).map(x => String(x).trim()))
  const [disableSubmitVerify, setDisableSubmitVerify] = useState(false)
  const [invertTokenForm, setInvertTokenForm] = useState({
    invert: false,
    amount: 0,
  })
  const currentHost = window.location.host;
  const isQdev = currentHost === 'kyc.qdev.li' || currentHost === 'qdevelopment-dev.investhub.io' || currentHost === 'localhost:3000';

  const intendedInvestmentOptions = (publicSettings.kyc_levels &&
    Object.values(publicSettings.kyc_levels).filter((level) => {
      if (isQdev && level.min_invest_amount > 25000) {
        return false;
      }
      return !level.enable || level.enable.enable_lvl;
    })
    .map((item, index) => {
      const unit = publicSettings.kyc_use_base_currency ? (publicSettings.base_currency.toUpperCase() || 'ETH') : 'CHF';

      if (item.enable && item.enable.enable_open_end) {
        return `> ${item.min_invest_amount} ${unit}`;
      }

      return `${item.min_invest_amount} - ${item.max_invest_amount} ${unit}`;
    })) || [];

  const baseCurrency = (publicSettings && publicSettings.base_currency) || "chf";
  const kycAcceptedWallets =
    (publicSettings.kyc_accepted_wallets &&
      Object.entries(publicSettings.kyc_accepted_wallets)
        .filter((ele) => ele[1])
        .map((ele) => ele[0])) ||
    [];

  const tokenToBaseCurrency =
    exchangeRates &&
    ethToFiat(
      baseCurrency,
      tokenToEth(values.amount, exchangeRates),
      exchangeRates
    );

  useEffect(() => {
    if (publicSettings && publicSettings.token_type === TOKEN_TYPES.NONE && publicSettings.require_exact_investment_amount_on_none) {
      const autoSelect = intendedInvestmentOptions.find(option => {
        const range = option.match(/\d+/g).map(n => +n)

        return range.length === 2
          ? range[1] >= exactIntendedInvestmentAmountValue
          : range[0] <= exactIntendedInvestmentAmountValue
      })

      if (autoSelect && autoSelect !== values.intended_investment) {
        setValues({
          ...values,
          intended_investment: autoSelect
        });
      }
    }
    // eslint-disable-next-line
  }, [publicSettings, exactIntendedInvestmentAmountValue, intendedInvestmentOptions])

  useEffect(() => {
    if (publicSettings && publicSettings.token_type !== TOKEN_TYPES.NONE && exchangeRates) {
      const tokenToCurrency = ethToFiat(baseCurrency, tokenToEth(amountValue, exchangeRates), exchangeRates)
      const autoSelect = intendedInvestmentOptions.find(option => {
        const range = option.match(/\d+/g).map(n => +n)

        return range.length === 2
          ? range[1] >= tokenToCurrency
          : range[0] <= tokenToCurrency
      })

      if (autoSelect && autoSelect !== values.intended_investment) {
        setValues({
          ...values,
          intended_investment: autoSelect
        });
      }

      if (!invertTokenForm.invert && invertTokenForm.amount === 0) {
        setInvertTokenForm({...invertTokenForm, amount: tokenToAnything(values.currency || baseCurrency, parseInt(amountValue, 10) ? amountValue : values.amount, exchangeRates)});
      }
    }
    // eslint-disable-next-line
  }, [publicSettings, amountValue, exchangeRates, intendedInvestmentOptions])

  useEffect(() => {
    if (publicSettings && publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION && publicSettings.enable_swap_token && tokenSwap) {
      setValues({
        ...values,
        amount: `${tokenSwap.newTokenAmount}`,
      })

      setAmountValue(tokenSwap.newTokenAmount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicSettings, tokenSwap])

  const handleCheckAllowlistCountries = (event) => {
    let updateList = [...checkedBusinessCountries]
    if (event.target.checked){
      updateList = [...checkedBusinessCountries, event.target.value]
    } else {
      updateList.splice(checkedBusinessCountries.indexOf(event.target.value), 1)
    }

    setCheckedBusinessCountries(updateList);
    setValues({
      ...values,
      business_countries: updateList.join(', ')
    });
  }

  const setTextField = (field) => ({ target: { value }}) => {
    setValues({
      ...values,
      [field]: value
    })
  }
  const setDateField = (field) => (dateValue) => {
    setValues({
      ...values,
      [field]: toLocalYmdString(dateValue)
    })
  }
  const setBooleanRadioField = (field) => ({ target: { value }}) => {
    setValues({
      ...values,
      [field]: value === 'true'
    })
  }

  const setFileField = (name) => (img, imgURI) => {
    let newImage = {}

    if (img.length > 0 && imgURI.length > 0) {
      newImage = {
        img: img[0],
        imgURI: imgURI[0],
      }
    }

    setValues({ ...values, [name]: newImage })
  }

  const removeFileFieldValue = (name) => () => {
    setValues({ ...values, [name]: null })
  }

  const setAmountField = ({ target: { value }}) => {
    setValues({
      ...values,
      amount: `${+value}`
    })
    setAmountValue(+value)
    setInvertTokenForm({...invertTokenForm, amount: tokenToAnything(values.currency || baseCurrency, +value, exchangeRates)});
  }

  const setExactInvestAmountField = ({ target: { value }}) => {
    setValues({
      ...values,
      exactIntendedInvestmentAmount: +value
    })
    setExactIntendedInvestmentAmountValue(+value)
  }

  const getIntendedInvestmentOptions = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return []
    }

    return Object.values(publicSettings.kyc_levels).filter((level) => !level.enable || level.enable.enable_lvl).map((level) => {
      if (level.enable && level.enable.enable_open_end) {
        return `> ${level.min_invest_amount} ${baseCurrency.toUpperCase()}`
      }
      return `${level.min_invest_amount} - ${level.max_invest_amount} ${baseCurrency.toUpperCase()}`
    })
  }

  const isRenderSourceOfFunds = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return false
    }

    if (publicSettings.token_type === TOKEN_TYPES.NONE) {
      return getIntendedInvestmentOptions().indexOf(values.intended_investment) > 0
    }

    if ([TOKEN_TYPES.UTILITY, TOKEN_TYPES.EQUITY, TOKEN_TYPES.BOND].includes(publicSettings.token_type)) {
      const tokenInBaseCurrency = exchangeRates && ethToFiat(
        baseCurrency,
        tokenToEth(values.amount, exchangeRates),
        exchangeRates
      )

      const currentLevel = Object.values(publicSettings.kyc_levels).filter((item) => {
        return !item.enable || item.enable.enable_lvl;
      }).reduce((all, item) => all + (tokenInBaseCurrency >= item.min_invest_amount ? 1 : 0), 0)

      return currentLevel > 1
    }

    return false
  };

  const setGeneratedWallet = (generatedWallet) => {
    setValues({
      ...values,
      generatedWallet,
      tokenAddress: generatedWallet,
      wallet: !values.wallet && kycAcceptedWallets().includes('eth') ? 'eth' : values.wallet,
    })
  }

  const confirmWalletAndSend = async () => {
    setDisableSubmitVerify(true);
    const message = 'Please sign this message to confirm the ownership of provided wallet '

    try {
      await metamaskService.requestAccounts()

      const signature = await metamaskService.signMessage(
        metamaskService.getCurrentAddress(),
        message
      )
      const signerAddress = await metamaskService.getSigner(signature, message)

      if (signerAddress === values.tokenAddress.toLowerCase()) {
        setValues({...values, verified_wallet: signerAddress})
        toaster.success('The wallet have been verified.')
      } else {
        toaster.error('The wallet you provided does not match with the current wallet on Metamask.')
      }

      setDisableSubmitVerify(false);
    } catch (error) {
      switch (error.code) {
        case 4001:
          toaster.error('You need to select at least the account with the provided address to connect')
          break
        default:
          toaster.error(error.message)
          break
      }
      setDisableSubmitVerify(false);
    }
  }

  const renderEquivalentValue = (amount, currency) => {
    if (!(+amount) || !exchangeRates) {
      return ''
    }

    return `=${numberWithCommas(invertTokenForm.amount)} ${currency.toUpperCase()}`
  }

  const textConfig = publicSettings &&
    publicSettings.token_type === "UTILITY"
      ? {
          issuer: t('Seller'),
          subscription: t('Token Purchase'),
          subscriber: t('purchaser'),
        }
      : {
          issuer: t('Issuer'),
          subscription: t('subscription'),
          subscriber: t('subscriber'),
        };

    const acceptedCurrencies =
        (publicSettings && publicSettings.accepted_currencies) || {};

  const renderHelperMinimumText = () => {
    if (!exchangeRates) {
      return "";
    }

    const minimumInvestAmount =
      (publicSettings && publicSettings.minimum_investment_amount) || 15000;

    let text = 'The minimum CONFIG_SUBSCRIPTION amount per investor is BASE_CURRENCY MINIMUMINVESTAMOUNT or the equivalent in ';

    if (publicSettings && publicSettings.maximum_investment_amount) {
      text = 'The minimum CONFIG_SUBSCRIPTION amount per investor is BASE_CURRENCY MINIMUMINVESTAMOUNT and the maximum is BASE_CURRENCY MAXIMUMINVESTAMOUNT or the equivalent in ';
    }

    let prospectusOrIssuingDate, prospectusOrIssuing, paperLink;

    switch ((publicSettings || {}).prospectus_or_issuing) {
      case PROSPECTUS_OR_ISSUING.ISSUING:
        prospectusOrIssuing = "Issuing Guidelines";
        prospectusOrIssuingDate = moment(
          (publicSettings || {}).issuing_guidelines_date
        ).format("YYYY-MM-DD");
        paperLink = publicSettings.issuing_guidelines;
        break;
      case PROSPECTUS_OR_ISSUING.PROSPECTUS:
        prospectusOrIssuing = "Prospectus label";
        prospectusOrIssuingDate = moment((publicSettings || {}).prospectus_date).format(
          "YYYY-MM-DD"
        );
        paperLink = publicSettings.prospectus_url;
        break;
      case PROSPECTUS_OR_ISSUING.TOKEN_OFFERING_MEMORANDUM:
        prospectusOrIssuing = "Token Sale Agreement";
        prospectusOrIssuingDate = moment(
          (publicSettings || {}).memorandum
        ).format("YYYY-MM-DD");
        paperLink = publicSettings.memorandum_url;
        break;
      case PROSPECTUS_OR_ISSUING.SAFE:
        prospectusOrIssuing = "Token Safe";
        prospectusOrIssuingDate = moment(
          (publicSettings || {}).token_safe_date
        ).format("YYYY-MM-DD");
        paperLink = publicSettings.token_safe_url;
        break;
      default:
        prospectusOrIssuing = "Token Sale Agreement";
        prospectusOrIssuingDate = moment(
          (publicSettings || {}).token_sale_agreement_date
        ).format("YYYY-MM-DD");
        paperLink = publicSettings.token_sale_agreement_url;
        break;
    }

    return <>
      {t(text, {
        CONFIG_SUBSCRIPTION: textConfig.subscription,
        BASE_CURRENCY: baseCurrency.toUpperCase(),
        MINIMUMINVESTAMOUNT: tokenToAnything(publicSettings.base_currency || 'chf', +minimumInvestAmount, exchangeRates),
        MAXIMUMINVESTAMOUNT: tokenToAnything(publicSettings.base_currency || 'chf', +publicSettings.maximum_investment_amount, exchangeRates),
        })}
      {' '}
      {[...CRYPTO_CURRENCIES, ...FIAT_CURRENCIES]
        .filter((name) => acceptedCurrencies[name])
        .map((name) => name.toUpperCase())
        .join("/")
      }
      {' '}
      {publicSettings && publicSettings.token_type !== "UTILITY"
        ? <>
            {t('according to the')}
            {' '}
            {paperLink ? <a href={paperLink} target="_blank" rel="noopener noreferrer" className="text__primary">{t(prospectusOrIssuing)}</a> : prospectusOrIssuing}
            {' '}
            {t('dated')}
            {' '}
            {prospectusOrIssuingDate}
          </>
        : t('according. All purchases are subject to the specific terms of the Token Purchase Agreement.')
      }
      <br />
    </>
  }

  if (!publicSettings) {
    return <CircularProgress />
  }

  return (
    <div className="card easy-onboarding__company-identification">
      <div className="settings__wrapper">
        <div className="card__head card__head-kyc-1">
          <div className="title-primary card__title">
            {t('Your personal information required for identification')}
          </div>
        </div>

        <div className="settings__fieldset">
          <InputThemeField
            classWrapper="field easy-onboarding__company-businessemail"
            classLabel="field__label"
            classInput="field__input"
            required
            label={t('Business email address')}
            value={values.email}
            onChange={setTextField('email')}
          />

          <InputThemeField
            classWrapper="field easy-onboarding__company-companyname"
            classLabel="field__label"
            classInput="field__input"
            required
            label={t('Company name')}
            value={values.companyName}
            onChange={setTextField('companyName')}
          />

          {publicSettings.kyb_require_director_form && (
            <div className="field field-radio__material easy-onboarding__company-identificationofbenficialowner">
              <div className="field__label">
                {t('Identification of the beneficial owner according to Liechtenstein legislation')}
              </div>

              <div className={
                classNames(
                  'field__wrap field__wrap-radio field__wrap-radio_text_gray',
                  styles.field__vert
                )
              }>
                <RadioGroup
                  row
                  value={values.directorFormType}
                  onChange={setTextField('directorFormType')}
                >
                  <FormControlLabel
                    value="FORM_T"
                    className={styles.field__vert_label}
                    label={
                      <>
                        <span className="field-radio__material-title">
                          { t('Foundations, trusteeships and establishments (with a structure similar to a foundation)') }
                          {' '}
                          {t('Art. 3 (1) lit. b DDO')}
                        </span>

                        <ul>
                          <li>
                            {t('Founder or settlor')}
                          </li>
                          <li>
                            {t('Member of the foundation board or board of directors of the trustee')}
                          </li>
                          <li>
                            {t('Protectors')}
                          </li>
                          <li>
                            {t('Beneficiaries')}
                          </li>
                          <li>
                            {t('Natural persons, who ultimately control the legal entity')}
                          </li>
                        </ul>
                      </>
                    }
                    control={<Radio color="primary" />}
                  />

                  <FormControlLabel
                    value="FORM_C"
                    className={styles.field__vert_label}
                    label={
                      <>
                        <span className="field-radio__material-title">
                          {t('Corporations and establishments (with a structure similar to a corporation)')}
                          {' '}
                          {t('Art. 3 (1) lit. a DDO')}
                        </span>

                        <ul>
                          <li>
                            {t('25 % or more of the company')}
                          </li>
                          <li>
                            {t('Exercise control')}
                          </li>
                          <li>
                            {t('Fall Back Clause Member of the executive body', {keySeparator: '>', nsSeparator: '|'})}
                          </li>
                        </ul>
                      </>
                    }
                    control={<Radio color="primary" />}
                  />
                </RadioGroup>
              </div>
            </div>
          )}

          <InputThemeField
            classWrapper="field easy-onboarding__company-companyregisternumber"
            classLabel="field__label"
            classInput="field__input"
            required
            label={t('Company Register Number')}
            value={values.companyRegisterNumber}
            onChange={setTextField('companyRegisterNumber')}
          />

          <div className="field easy-onboarding__company-legalform">
            <div className="field__label">
              {t('Legal form')}
              <span className="text__red"> *</span>
            </div>

            <div className={classNames('field__wrap field__wrap-select', styles.select)}>
              <TextField
                select
                margin="none"
                variant="outlined"
                fullWidth
                value={values.legalForm}
                onChange={setTextField('legalForm')}
              >
                {COMPANY_LEGAL_FORMS.map(option => (
                  <MenuItem key={option} value={option}>{t(option)}</MenuItem>
                ))}
              </TextField>
            </div>
          </div>

          { values.legalForm === 'Other' && (
            <InputThemeField
              classWrapper="field easy-onboarding__company-descriptionlegalform"
              classLabel="field__label"
              classInput="field__input"
              required
              label={t('Description of Legal form')}
              value={values.legalFormOther}
              onChange={setTextField('legalFormOther')}
            />
          )}

          <div className="field easy-onboarding__company-dateofincorporation">
            <div className="field__label">
              {t('Date of incorporation')}
            </div>

            <div className={classNames('field__wrap field__wrap-date', styles.field__date)}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  views={["year", "month", "date"]}
                  openTo="year"
                  disableFuture
                  variant="outlined"
                  format="dd.MM.yyyy"
                  margin="none"
                  value={new Date(values.dateOfIncorporation)}
                  onChange={setDateField('dateOfIncorporation')}
                  invalidDateMessage={t('Invalid Date Format')}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>

          <div className="field easy-onboarding__company-dateofentryinthecommercialregister">
            <div className="field__label">
              {t('Date of entry in the Commercial Register')}
            </div>
            <div className={classNames('field__wrap field__wrap-date', styles.field__date)}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  views={["year", "month", "date"]}
                  openTo="year"
                  disableFuture
                  variant="outlined"
                  format="dd.MM.yyyy"
                  margin="none"
                  value={new Date(values.dateOfEntryInCommercialRegister)}
                  onChange={setDateField('dateOfEntryInCommercialRegister')}
                  invalidDateMessage={t('Invalid Date Format')}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>

          <InputThemeField
            classWrapper="field easy-onboarding__company-placeofcommercialregister"
            classLabel="field__label"
            classInput="field__input"
            required
            label={t('Place of Commercial Register')}
            value={values.placeOfCommercialRegister}
            onChange={setTextField('placeOfCommercialRegister')}
          />

          <InputThemeField
            classWrapper="field easy-onboarding__company-companysharecapital"
            classLabel="field__label"
            classInput="field__input"
            required
            label={t('Company Share Capital')}
            value={values.companyShareCapital}
            onChange={setTextField('companyShareCapital')}
          />

          <InputThemeField
            classWrapper="field easy-onboarding__company-companyplace"
            classLabel="field__label"
            classInput="field__input"
            required
            label={t('Company Place')}
            value={values.companyPlace}
            onChange={setTextField('companyPlace')}
          />

          <InputThemeField
            classWrapper="field easy-onboarding__company-companyaddress"
            classLabel="field__label"
            classInput="field__input"
            required
            label={t('Company address')}
            value={values.companyAddress}
            onChange={setTextField('companyAddress')}
          />

          <div className="field easy-onboarding__company-companycountry">
            <div className="field__label">
              {t('Company Country')}
              <span className="text__red"> *</span>
            </div>

            <div className={classNames('field__wrap field__wrap-select', styles.select)}>
              <TextField
                select
                margin="none"
                variant="outlined"
                fullWidth
                value={values.companyCountry}
                onChange={setTextField('companyCountry')}
              >
                {countryList.map(country => (
                  <MenuItem key={country} value={country}>{t(country)}</MenuItem>
                ))}
              </TextField>
            </div>
          </div>

          <InputThemeField
            classWrapper="field easy-onboarding__company-companypostalcode"
            classLabel="field__label"
            classInput="field__input"
            required
            label={t('Company Postalcode')}
            value={values.companyPostalCode}
            onChange={setTextField('companyPostalCode')}
          />

          {publicSettings.kyb_require_activity_description && (
            <div className="field easy-onboarding__company-descriptionregisterextractofbusinessactivity">
              <div className="field__label">
                {t('Description register extract of business activity')}

                <span className="text__red"> *</span>
              </div>

              <div className={classNames('field__wrap field__wrap-textarea', styles.textarea)}>
                <TextField
                  fullWidth
                  multiline
                  minRows="4"
                  maxRows="5"
                  required
                  value={values.descriptionRegisterExtractBusinessActivity}
                  onChange={setTextField('descriptionRegisterExtractBusinessActivity')}
                />
              </div>
            </div>
          )}

          {publicSettings.kyb_require_main_income && (
            <div className="field easy-onboarding__company-mainsourceofincomeofthecompany">
              <div className="field__label">
                {t('What is the main source of income of the company?')}
                <span className="text__red"> *</span>
              </div>

              <div className={classNames('field__wrap field__wrap-textarea', styles.textarea)}>
                <TextField
                  fullWidth
                  multiline
                  minRows="4"
                  maxRows="5"
                  required
                  value={values.sourceOfIncome}
                  onChange={setTextField('sourceOfIncome')}
                />
              </div>
            </div>
          )}

          {publicSettings.kyb_require_other_income && (
            <div className="field field-radio__material easy-onboarding__company-doesthecompanyhaveanysource">
              <div className="field__label">
                {t('Does the company have any source of incoming arising from any activity that is not related to the company’s main activity?')}
                <span className="text__red"> *</span>
              </div>

              <div className={
                classNames(
                  'field__wrap field__wrap-radio field__wrap-radio_text_gray',
                  styles.field__wrap
                )
              }>
                <RadioGroup
                  row
                  defaultValue="yes-have-source"
                  value={values.hasNotRelatedSourceIncome}
                  onChange={setBooleanRadioField('hasNotRelatedSourceIncome')}
                >
                  <FormControlLabel
                    value={true}
                    label={t('Yes')}
                    control={<Radio color="primary" />}
                  />
                  <FormControlLabel
                    value={false}
                    label={t('No')}
                    control={<Radio color="primary" />}
                  />
                </RadioGroup>
              </div>
            </div>
          )}

          {publicSettings.kyb_require_other_income && values.hasNotRelatedSourceIncome && (
            <div className="field easy-onboarding__company-companyotherincome">
              <div className={classNames('field__wrap field__wrap-textarea', styles.textarea)}>
                <TextField
                  fullWidth
                  multiline
                  minRows="4"
                  maxRows="5"
                  required
                  value={values.notRelatedSourceIncomeDescription}
                  onChange={setTextField('notRelatedSourceIncomeDescription')}
                />
              </div>
            </div>
          )}

          {publicSettings.kyb_require_gross_revenue && (
            <div className="field easy-onboarding__company-averagegrossrevenueofthelast12months">
              <div className="field__label">
                {t('Average gross revenue of the last 12 months?')}
                <span className="text__red"> *</span>
              </div>

              <div className={classNames('field__wrap field__wrap-select', styles.select)}>
                <TextField
                  select
                  margin="none"
                  variant="outlined"
                  fullWidth
                  value={values.grossRevenue}
                  onChange={setTextField('grossRevenue')}
                >
                  {NET_INVESTABLE_ASSETS_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option.replaceAll('CHF', baseCurrency.toUpperCase())}>
                      {t(option.replaceAll('CHF', baseCurrency.toUpperCase()))}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          )}

          {publicSettings.kyc_what_will_you_use && (
            <InputThemeField
              classWrapper="field easy-onboarding__company-informationregardingcompanyinvestmentpurpose"
              classLabel="field__label"
              classInput="field__input"
              required
              label={t('Information regarding company’s investment purpose')}
              value={values.investmentPurpose}
              onChange={setTextField('investmentPurpose')}
            />
          )}

          {publicSettings.kyb_show_business_countries && (
            <>
              <div className="field easy-onboarding__company-areyoudoingbusinessinanyofthesecountries">
                <div className="field__label">{t('Are you doing business in any of these Countries?')}</div>
              </div>

              <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1">
                <div className="d__flex flex__wrap flex__row field field__radio-kyc-style-1 field__radio-kyc__style-1_2">
                  {HIGH_AND_PROHIBIT_RISK_COUNTRIES.map((country) => (
                    <label className="checkbox" key={country}>
                      <input
                        type="checkbox"
                        className="checkbox__input"
                        onChange={handleCheckAllowlistCountries}
                        checked={checkedBusinessCountries && checkedBusinessCountries.includes(country)}
                        value={country}
                      />
                      <div className="checkbox__inner">
                        <div className="checkbox__tick">{' '}</div>
                        <div className="checkbox__text">{t(country)}</div>
                      </div>
                    </label>
                  ))}
                </div>
              </div>
            </>
          )}

          { publicSettings.kyb_show_business_following_industries && (
            <>
              <div className="field easy-onboarding__company-isyourbusinessoperatedinthefollowingindustries">
                <div className="field__label">
                  {t('Is your Business operated in the following industries?')}
                </div>

                <div className={classNames('field__wrap field__wrap-select', styles.select)}>
                  <TextField
                    select
                    margin="none"
                    variant="outlined"
                    fullWidth
                    value={values.business_following_industries}
                    onChange={setTextField('business_following_industries')}
                  >
                    {BUSINESS_FOLLOWING_INDUSTRIES.map(industry => (
                      <MenuItem key={industry} value={industry}>{t(industry)}</MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
            </>
          )}

          <div className="field easy-onboarding__company-pleasechooseyourcorrectsiccodeforyourbusiness">
            <div className="field__label">
              {t('Please choose your correct SIC Code for your Business')}
            </div>

            <div className={classNames('field__wrap field__wrap-select')}>
              {/* <TextField
                select
                margin="none"
                variant="outlined"
                fullWidth
                value={values.sic_code}
                onChange={setTextField('sic_code')}
              >
                {SIC_CODES.map(code => (
                  <MenuItem key={code.SICCode} value={code.SICCode}>{`${code.SICCode} - ${code.SICDescription}`}</MenuItem>
                ))}
              </TextField> */}

              <Autocomplete
                id="combo-box"
                options={SIC_CODES}
                getOptionLabel={(option) => `${option.SICCode} - ${option.SICDescription}`}
                getOptionSelected={(option) => `${option.SICCode} - ${option.SICDescription}`}
                classes={{
                  popper: styles.custom_popper,
                }}
                onChange={(_event, value) => {
                  if (value) {
                    setValues({
                      ...values,
                      sic_code: `${value.SICCode} - ${value.SICDescription}`
                    })
                  }
                }}
                value={values.sic_code ? SIC_CODES.find(code => `${code.SICCode} - ${code.SICDescription}` === values.sic_code) : undefined}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="none"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>
          </div>

          {!isCryptoadvisory && (
            <div className={classNames('field easy-onboarding__company-commercialregisterextract', styles.upload)}>
              <div className="file__label">
                { t('Commercial Register Extract') }
                <span className="text__red"> *</span>
              </div>
              {!values.commercialRegisterExtract && (
                <ImageUpload
                  isRequired
                  buttonText={t('Click or drop images')}
                  buttonClassName="button"
                  maxFileSize={10000000}
                  withPreview
                  singleImagePick
                  singleFile
                  onChange={setFileField('commercialRegisterExtract')}
                  accept='image/*, application/pdf'
                >
                  <div className="file__wrap_text text__center">
                    <p>
                      { t('Drag and drop the document') }
                    </p>
                  </div>
                </ImageUpload>
              )}

              {values.commercialRegisterExtract && (
                <div className={classNames('settings__top', styles.upload__result)}>
                  <div className="settings__label">
                    {t(values.commercialRegisterExtract.img.name)}
                  </div>

                  <button
                    className="button-stroke button-small settings__button"
                    onClick={removeFileFieldValue('commercialRegisterExtract')}
                  >
                    {t('Remove')}
                  </button>
                </div>
              )}
            </div>
          )}

          {publicSettings.kyb_require_organization_chart && (
            <div className={classNames('field easy-onboarding__company-corporationorganizationchartofthecompany', styles.upload)}>
              <div className="file__label">
                { t('Corporation Organization Chart of the Company') }
                <span className="text__red"> *</span>
              </div>
              {!values.corporationOrganizationChartFile && (
                <ImageUpload
                  isRequired
                  buttonText={t('Click or drop images')}
                  buttonClassName="button"
                  maxFileSize={10000000}
                  withPreview
                  singleImagePick
                  singleFile
                  onChange={setFileField('corporationOrganizationChartFile')}
                  accept='image/*, application/pdf'
                >
                  <div className="file__wrap_text text__center">
                    <p>
                      { t('Drag and drop the document') }
                    </p>
                  </div>
                </ImageUpload>
              )}

              {values.corporationOrganizationChartFile && (
                <div className={classNames('settings__top', styles.upload__result)}>
                  <div className="settings__label">
                    {values.corporationOrganizationChartFile.img.name}
                  </div>

                  <button
                    className="button-stroke button-small settings__button"
                    onClick={removeFileFieldValue('corporationOrganizationChartFile')}
                  >
                    {t('Remove')}
                  </button>
                </div>
              )}
            </div>
          )}

          {publicSettings.kyb_require_association_article && (
            <div className={classNames('field easy-onboarding__company-articlesofassociation', styles.upload)}>
              <div className="file__label">
                { t('Articles of Association') }
                <span className="text__red"> *</span>
              </div>
              {!values.articlesOfAssociationFile && (
                <ImageUpload
                  isRequired
                  buttonText={t('Click or drop images')}
                  buttonClassName="button"
                  maxFileSize={10000000}
                  withPreview
                  singleImagePick
                  singleFile
                  onChange={setFileField('articlesOfAssociationFile')}
                  accept='image/*, application/pdf'
                >
                  <div className="file__wrap_text text__center">
                    <p>
                      { t('Drag and drop the document') }
                    </p>
                  </div>
                </ImageUpload>
              )}

              {values.articlesOfAssociationFile && (
                <div className={classNames('settings__top', styles.upload__result)}>
                  <div className="settings__label">
                    {values.articlesOfAssociationFile.img.name}
                  </div>

                  <button
                    className="button-stroke button-small settings__button"
                    onClick={removeFileFieldValue('articlesOfAssociationFile')}
                  >
                    {t('Remove')}
                  </button>
                </div>
              )}
            </div>
          )}

          {publicSettings.kyb_require_last_balance_sheet && (
            <div className={classNames('field easy-onboarding__company-lastbalancesheetavailable', styles.upload)}>
              <div className="file__label">
                { t('Last balance sheet available') }
                <span className="text__red"> *</span>
              </div>
              {!values.lastBalanceSheetFile && (
                <ImageUpload
                  buttonText={t('Click or drop images')}
                  buttonClassName="button"
                  maxFileSize={10000000}
                  withPreview
                  singleImagePick
                  singleFile
                  onChange={setFileField('lastBalanceSheetFile')}
                  accept='image/*, application/pdf'
                >
                  <div className="file__wrap_text text__center">
                    <p>
                      { t('Drag and drop the document') }
                    </p>
                  </div>
                </ImageUpload>
              )}

              {values.lastBalanceSheetFile && (
                <div className={classNames('settings__top', styles.upload__result)}>
                  <div className="settings__label">
                    {values.lastBalanceSheetFile.img.name}
                  </div>

                  <button
                    className="button-stroke button-small settings__button"
                    onClick={removeFileFieldValue('lastBalanceSheetFile')}
                  >
                    {t('Remove')}
                  </button>
                </div>
              )}
            </div>
          )}

          {publicSettings.kyb_show_good_standing_certificate && (
            <div className={classNames('field easy-onboarding__company-certificateofgoodstanding', styles.upload)}>
              <div className="file__label">
                { t('Certificate of Good Standing') }
              </div>
              {!values.certificateOfGoodStandingFile && (
                <ImageUpload
                  buttonText={t('Click or drop images')}
                  buttonClassName="button"
                  maxFileSize={10000000}
                  withPreview
                  singleImagePick
                  singleFile
                  onChange={setFileField('certificateOfGoodStandingFile')}
                  accept='image/*, application/pdf'
                >
                  <div className="file__wrap_text text__center">
                    <p>
                      { t('Drag and drop the document') }
                    </p>
                  </div>
                </ImageUpload>
              )}

              {values.certificateOfGoodStandingFile && (
                <div className={classNames('settings__top', styles.upload__result)}>
                  <div className="settings__label">
                    {values.certificateOfGoodStandingFile.img.name}
                  </div>

                  <button
                    className="button-stroke button-small settings__button"
                    onClick={removeFileFieldValue('certificateOfGoodStandingFile')}
                  >
                    {t('Remove')}
                  </button>
                </div>
              )}
            </div>
          )}

          {publicSettings.kyb_require_shareholder_list && (
            <div className={classNames('field easy-onboarding__company-shareholderlist', styles.upload)}>
              <div className="file__label">
                { t('Shareholder List') }
                <span className="text__red"> *</span>
              </div>
              {!values.shareholderListFile && (
                <ImageUpload
                  isRequired
                  buttonText={t('Click or drop images')}
                  buttonClassName="button"
                  maxFileSize={10000000}
                  withPreview
                  singleImagePick
                  singleFile
                  onChange={setFileField('shareholderListFile')}
                  accept='image/*, application/pdf'
                >
                  <div className="file__wrap_text text__center">
                    <p>
                      { t('Drag and drop the document') }
                    </p>
                  </div>
                </ImageUpload>
              )}

              {values.shareholderListFile && (
                <div className={classNames('settings__top', styles.upload__result)}>
                  <div className="settings__label">
                    {values.shareholderListFile.img.name}
                  </div>

                  <button
                    className="button-stroke button-small settings__button"
                    onClick={removeFileFieldValue('shareholderListFile')}
                  >
                    {t('Remove')}
                  </button>
                </div>
              )}
            </div>
          )}

          {publicSettings.kyc_prefer_currency && (
            <div className="field field-radio__material easy-onboarding__company-mypreferredpaymentcurrency">
              <div className="field__label">
                {t('My preferred payment currency')}
              </div>

              <div className={
                classNames(
                  'field__wrap field__wrap-radio field__wrap-radio_text_gray',
                  styles.field__wrap
                )
              }>
                <RadioGroup
                  row
                  value={values.currency}
                  onChange={(event) => {
                    setTextField('currency')(event);
                    setInvertTokenForm({...invertTokenForm, amount: tokenToAnything(event.target.value, values.amount, exchangeRates)});
                  }}
                >
                  {[...CRYPTO_CURRENCIES, ...FIAT_CURRENCIES]
                    .filter((name) => publicSettings.accepted_currencies[name])
                    .map((name) => (
                      <FormControlLabel
                        key={name}
                        label={t(name.toUpperCase())}
                        value={name}
                        control={<Radio color="primary" />}
                      />
                    ))}
                </RadioGroup>
              </div>
            </div>
          )}

          {publicSettings.token_type !== TOKEN_TYPES.NONE && (
            <>
              {publicSettings.enable_swap_token && tokenSwap && (
                <div className="field easy-onboarding__company-tokenamount">
                  <div className="field__label">
                    {publicSettings && publicSettings.old_token_symbol
                      ? `${publicSettings.old_token_symbol} amount`
                      : 'Old token amount'
                    }
                  </div>
                  <div className="field__wrap">
                    <InputThemeLite
                      value={tokenSwap.oldTokenAmount}
                      disabled
                      fullWidth
                    />
                  </div>
                </div>
              )}

              {!invertTokenForm.invert && (
                <>
                  <InputThemeField
                    classWrapper="field easy-onboarding__company-tokennumber"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={publicSettings.enable_swap_token && tokenSwap
                      ? `${publicSettings.token_symbol} amount`
                      : t('Tokens')}
                    postFix={
                      <button
                        className="button button-xs"
                        onClick={() => setInvertTokenForm({...invertTokenForm, invert: !invertTokenForm.invert})}
                      >
                        Swap
                      </button>
                    }
                    type="number"
                    disabled={publicSettings.enable_swap_token && tokenSwap}
                    value={values.amount}
                    onChange={setAmountField}
                    helperText={
                      <>
                        {renderEquivalentValue(values.amount, values.currency || publicSettings.base_currency)}
                        <br />
                        {renderHelperMinimumText()}
                      </>
                    }
                  />
                </>
              )}

              {invertTokenForm.invert && (
                <>
                  <InputThemeField
                    classWrapper="field easy-onboarding__company-tokennumber"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={(values.currency || publicSettings.base_currency).toUpperCase()}
                    postFix={
                      <button
                        className="button button-xs"
                        onClick={() => setInvertTokenForm({...invertTokenForm, invert: !invertTokenForm.invert})}
                      >
                        Swap
                      </button>
                    }
                    type="number"
                    disabled={publicSettings.enable_swap_token && tokenSwap}
                    value={invertTokenForm.amount}
                    onChange={({ target: { value } }) => {
                      const rate = tokenToAnything(values.currency || publicSettings.base_currency, 1, exchangeRates, true);
                      let amountToToken = value / rate;
                      let invertAmount = value

                      if (Math.round(amountToToken) > publicSettings.maximum_investment_amount) {
                        amountToToken = publicSettings.maximum_investment_amount
                        invertAmount = tokenToAnything(values.currency || publicSettings.base_currency, amountToToken, exchangeRates)
                      }

                      setValues({ ...values, amount: Math.round(amountToToken)})
                      setAmountValue(Math.round(amountToToken))
                      setInvertTokenForm({...invertTokenForm, amount: invertAmount})
                    }}
                    helperText={
                      <>
                        {`=${values.amount} Token`}
                        <br />
                        {renderHelperMinimumText()}
                      </>
                    }
                  />
                </>
              )}
            </>
          )}

          {publicSettings.token_type === TOKEN_TYPES.NONE && publicSettings.require_exact_investment_amount_on_none && (
            <div className="field easy-onboarding__company-exactinvestmentamount">
              <InputThemeField
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                required
                label={t('Exact investment amount')}
                type="number"
                value={values.exactIntendedInvestmentAmount}
                onChange={setExactInvestAmountField}
                helperText={`amount in ${baseCurrency.toUpperCase()}`}
              />
            </div>
          )}

          {publicSettings.kyc_intended_investment && (
            <div className="field easy-onboarding__company-intendedinvestment">
              <div className="field__label">
                {t('Intended investment')}
              </div>

              <div className={classNames('field__wrap field__wrap-select', styles.select)}>
                <TextField
                  select
                  margin="none"
                  variant="outlined"
                  fullWidth
                  value={values.intended_investment}
                  onChange={setTextField('intended_investment')}
                >
                  {intendedInvestmentOptions.map(option => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          )}

          {publicSettings.kyc_intended_investment && isRenderSourceOfFunds() && (
            <InputThemeField
              classWrapper="field easy-onboarding__company-sourceoffunds"
              classLabel="field__label"
              classInput="field__input"
              label={
                <div className="card__head">
                  <div className="card__title card__title-has_icon">
                    { t('Source Of Funds') }
                    <span className="text__red"> *</span>
                    {" "}
                    <PopupState popupId="declaration-relevant" variant="popover" >
                      {(popupState) => (
                        <>
                          <span {...bindTrigger(popupState)} className="actions__popover-trigger">
                            <InfoIcon
                              className={classNames('info')}
                              name='info'
                              size="16"
                            />
                          </span>

                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                            className="actions__popover"
                          >
                            <div className="actions__body actions__body_l actions__body-text">
                              <div className="tips__action">
                                <p>
                                  {t('Where does the funds used for this transaction come from?')}
                                </p>
                              </div>
                            </div>
                          </Popover>
                        </>
                      )}
                    </PopupState>
                  </div>
                </div>
              }
              value={values.sourceOfFunds}
              variant="outlined"

              onChange={setTextField('sourceOfFunds')}
            />
          )}

          <KycFormSupportedSourceOfFund
            tokenToBaseCurrency={tokenToBaseCurrency}
            publicSettings={publicSettings}
            onDropSupportedSourceOfFundFiles={(img, imgURI) => {
              const newImage = {
                img: img.length > 0 ? img[0] : null,
                imgURI: imgURI.length > 0 ? imgURI[0] : "",
              }
              setValues({
                ...values,
                supportedSourceOfFundUploadFiles: [...values.supportedSourceOfFundUploadFiles, newImage]
              })
            }}
            supportedSourceOfFundFiles={values.supportedSourceOfFundUploadFiles}
            removeDropSupportedSourceOfFundFiles={(index) => {
              const newSupportedSourceOfFundFiles = [...values.supportedSourceOfFundUploadFiles]
              const removedEle = remove(newSupportedSourceOfFundFiles, (file, indx) => {
                return indx === index
              })

              if (removedEle) {
                setValues({...values, supportedSourceOfFundUploadFiles: newSupportedSourceOfFundFiles})
              }
            }}
            intendedInvestment={values.intended_investment}
          />

          {publicSettings.kyc_show_receiving_wallet && (
            <div className="field easy-onboarding__company-wallet">
              <div className="field__label">
                {t('Wallet')}

                <span className="text__red"> *</span>
              </div>

              <div className={classNames('field__wrap field__wrap-select', styles.select)}>
                <TextField
                  select
                  margin="none"
                  variant="outlined"
                  fullWidth
                  value={values.wallet}
                  onChange={setTextField('wallet')}
                >
                  {kycAcceptedWallets.map(wallet => (
                    <MenuItem key={wallet} value={wallet}>
                      {t(walletLabelTextMapping[wallet])}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          )}

          {publicSettings.kyc_show_receiving_wallet && (
            <>
              {!publicSettings.force_generate_wallet && (
                <InputThemeField
                  classWrapper="field easy-onboarding__company-receivingwalletaddress"
                  classLabel="field__label"
                  classInput="field__input"
                  required={publicSettings.enable_cashlink || publicSettings.kyc_require_receiving_wallet}
                  label={
                    <>
                      {t('Receiving Wallet Address')}
                      {' '}
                      {(publicSettings || {}).source_of_address_for_sending_tokens_example_path && (
                        <>
                          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                          <a
                            href={getExternalFileUrl(
                              publicSettings.source_of_address_for_sending_tokens_example_path
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t('Show Example')}
                          </a>
                        </>
                      )}
                    </>
                  }
                  value={values.tokenAddress}
                  onChange={setTextField('tokenAddress')}
                  helperText={(
                    <>
                      {(publicSettings && !publicSettings.hide_wallet_format_note && t(helperTextForWalletCompliant(values.wallet))) || undefined}

                    </>
                  )}
                />
              )}

              {publicSettings && publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION && (
                <WalletRequest
                  type={!values.wallet && kycAcceptedWallets().includes('eth') ? 'eth' : values.wallet}
                  generatedWallet={values.generatedWallet}
                  onComplete={setGeneratedWallet}
                  isKycFlow={true}
                />
              )}
              <br />

              {publicSettings && publicSettings.show_verify_wallet ?
                (values.tokenAddress && values.verified_wallet === values.tokenAddress.toLowerCase()) ?
                  <span className="text-green">{t('This address have been verified.')}</span> :
                  <button
                    type="button"
                    className="button"
                    onClick={confirmWalletAndSend}
                    disabled={disableSubmitVerify}
                  >
                    { t('Verify this wallet') }
                  </button>
                : ''
              }
            </>
          )}

          {isCrypto(values.currency) && (
            <>
              {publicSettings.add_ether_wallet_later_option && (
                <div className="create__line easy-onboarding__company-addwalletlater">
                  <div className="create__info">
                    {t('I will add my wallet later')}
                  </div>

                  <label className="switch">
                    <input
                      type="checkbox"
                      className="switch__input"
                      value="isAddEtherWalletLater"
                      checked={values.isAddEtherWalletLater}
                      onChange={({target: { checked }}) => setValues({...values, isAddEtherWalletLater: checked})}
                    />
                    <div className="switch__inner">
                      <div className="switch__box">{' '}</div>
                    </div>
                  </label>
                </div>
              )}

              <div className="field easy-onboarding__company-sendingwallet">
                <div className="field__label">
                  {t('Sending Wallet')}

                  <span className="text__red"> *</span>
                </div>

                <div className={classNames('field__wrap field__wrap-select', styles.select)}>
                  <TextField
                    select
                    margin="none"
                    variant="outlined"
                    fullWidth
                    value={values.sending_wallet}
                    onChange={setTextField('sending_wallet')}
                    required={!values.isAddEtherWalletLater}
                  >
                    {getSendingWalletTypeOptions(values.currency).map((option) => (
                      <MenuItem key={option[0]} value={option[0]}>
                        {t(option[1])}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>

              <InputThemeField
                classWrapper="field easy-onboarding__company-sendingwalletaddress"
                classLabel="field__label"
                classInput="field__input"
                required={!values.isAddEtherWalletLater}
                label={
                  <>
                    {t('Sending Wallet Address')}
                    {' '}
                    {(publicSettings || {}).source_of_address_for_sending_tokens_example_path && (
                      <>
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                        <a
                          href={getExternalFileUrl(
                            publicSettings.source_of_address_for_sending_tokens_example_path
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('Show Example')}
                        </a>
                      </>
                    )}
                  </>
                }
                value={values.tokenSendingAddress}
                onChange={setTextField('tokenSendingAddress')}
                helperText={(publicSettings && !publicSettings.hide_wallet_format_note && t(helperTextForWalletCompliant(values.wallet))) || undefined}
              />
            </>
          )}

          {values.referral_code &&
            publicSettings &&
            publicSettings.kyc_referal_code && (
              <InputThemeField
                classWrapper="field easy-onboarding__company-referralcode"
                classLabel="field__label"
                classInput="field__input"
                label={ t('Referral Code') }
                value={values.referral_code}
                onChange={() => {}}
                disabled
              />
            )
          }

        </div>

        <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
          <button className="button" onClick={goToNextStep}>{t('Continue')}</button>
        </div>
      </div>
    </div>
  )
}

export default CardForm
