import React, { useState, useEffect, useCallback } from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import omit from 'lodash/omit'
import { toaster, isFiat } from '../../utils'
import { useTranslation } from 'react-i18next'

import CompanyStep1 from './CompanyStep1'
import CompanyStep2 from './CompanyStep2'
import CompanyStep3 from './CompanyStep3'
import CompanyStep4 from './CompanyStep4'
import CompanyStep5 from './CompanyStep5'
import { Redirect, useRouteMatch } from 'react-router-dom'
import { COMPANY_DOCUMENT_TYPE_ENUM } from '../../schemas/kycVerification'
import { REGISTRATION_COMPANY_CUSTOMER, UPDATE_COMPANY_CUSTOMER } from '../../queriesAndMutations'

const KycCompanyVerificationForm = ({
  publicSettings,
  userData,
  kycFormStepNumber,
  setKycFormStepNumber,
  oldData,
}) => {
  const { t } = useTranslation();
  const updateMode = !!oldData
  const match = useRouteMatch(`${window.location.pathname}${window.location.search}`)
  const is_gto_sales = userData.is_gtoswiss || userData.is_internal_sales
  const [registerCompanyCustomer, registerCompanyCustomerData] = useMutation(REGISTRATION_COMPANY_CUSTOMER)
  const [updateCompanyCustomer, updateCompanyCustomerData] = useMutation(UPDATE_COMPANY_CUSTOMER)
  const { loading, error, data } = updateMode ? updateCompanyCustomerData : registerCompanyCustomerData
  const currentHost = window.location.host;
  const isQdev = currentHost === 'kyc.qdev.li' || currentHost === 'qdevelopment-dev.investhub.io' || currentHost === 'localhost:3000';

  const [checkboxValues, setCheckboxValues] = useState({
    IActOnMyOwnAccount: false,
    clientStatement: false,
    confirmationTerms1: false,
    declarationOfConsent1: false,
    declarationOfConsent2: false,
    declarationOfConsent3: false,

    declarationOfConsent4: false,
    declarationOfConsent5: false,
    declarationOfConsent6: false,
    declarationOfConsent7: false,
    declarationOfConsent8: false,

    authorizedRepresentative: false,
    beneficiaOwner: false,
  })

  const getIntendedInvestmentOptions = useCallback(() => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return []
    }

    return Object.values(publicSettings.kyc_levels).filter((level) => {
      if (isQdev && level.min_invest_amount > 25000) {
        return false;
      }
      return !level.enable || level.enable.enable_lvl;
    })
    .map((item, index) => {
      const unit = publicSettings.kyc_use_base_currency ? (publicSettings.base_currency.toUpperCase() || 'ETH') : 'CHF';

      if (item.enable && item.enable.enable_open_end) {
        return `> ${item.min_invest_amount} ${unit}`;
      }

      return `${item.min_invest_amount} - ${item.max_invest_amount} ${unit}`;
    })
  }, [publicSettings, isQdev]);
  const intendedInvestmentOptions = getIntendedInvestmentOptions()

  const [values, setValues] = useState({
    documentType: COMPANY_DOCUMENT_TYPE_ENUM[Object.keys(COMPANY_DOCUMENT_TYPE_ENUM)[0]].value,
    currency: (publicSettings || {}).base_currency || "eth",

    // Step 1
    email: userData.email,
    companyName: '',
    directorFormType: '',
    companyRegisterNumber: '',
    legalForm: '',
    dateOfIncorporation: '2014-08-18',
    dateOfEntryInCommercialRegister: '2014-08-18',
    placeOfCommercialRegister: '',
    companyShareCapital: '',
    companyPlace: '',
    companyAddress: '',
    companyCountry: '',
    companyPostalCode: '',
    descriptionRegisterExtractBusinessActivity: '',
    sourceOfIncome: '',
    hasNotRelatedSourceIncome: false,
    notRelatedSourceIncomeDescription: '',
    investmentPurpose: '',
    grossRevenue: 'Up to 5.000 CHF',
    descriptionOfFunds: '',
    amount: "0",
    exactIntendedInvestmentAmount: 0,
    intended_investment: '',
    sourceOfFunds: '',
    wallet: 'eth',
    tokenAddress: '',
    tokenSendingAddress: '',
    supportedSourceOfFundUploadFiles: [],
    referral_code: match.params.referalCode || localStorage.getItem("referalCode") || userData.referral_code || '',
    business_countries: '',
    business_following_industries: '',
    sic_code: '',
    verified_wallet: '',

    // Step 3
    directors: [],

    // Step 3
    proofOfResidenceType: 'BANK_STATEMENT',
    proofOfResidenceUploadFile: null,

    // Step 4
    uboList: [],

    // Step 5
    personType: 'NOT_POLITICAL_PERSON',
    duties: '',
    relationshipToPEP: '',

    // will be omitted
    directorAsUbo: null,

  })

  useEffect(() => {
    if (publicSettings && publicSettings !== {}) {
      const intendedInvestmentOptions = getIntendedInvestmentOptions();

      let directorAsUbo;

      if (oldData && oldData.customerCompanyUbo.length && oldData.customerCompanyDirector.length) {
        for (let i = 0; i < oldData.customerCompanyUbo.length; i+=1) {
          const ubo = oldData.customerCompanyUbo[i];
          const directorIndex = oldData.customerCompanyDirector.findIndex(director => (director.email === ubo.email));
          if (directorIndex >= 0) {
            directorAsUbo = ubo;
            break;
          }
        }
      }
      setValues({
        ...values,
        // deprecated
        documentType: COMPANY_DOCUMENT_TYPE_ENUM[Object.keys(COMPANY_DOCUMENT_TYPE_ENUM)[0]].value,
        currency: (oldData && oldData.currency) ? oldData.currency : ((publicSettings || {}).base_currency || 'chf'),

        // Step 1
        email: (oldData && oldData.email) ? oldData.email : userData.email,
        companyName: (oldData && oldData.companyName) ? oldData.companyName : '',
        directorFormType: (oldData && oldData.directorFormType) ? oldData.directorFormType : '',
        companyRegisterNumber: (oldData && oldData.companyRegisterNumber) ? oldData.companyRegisterNumber : '',
        legalForm: (oldData && oldData.legalForm) ? oldData.legalForm : '',
        legalFormOther: (oldData && oldData.legalFormOther) ? oldData.legalFormOther : '',
        dateOfIncorporation: (oldData && oldData.dateOfIncorporation) ? oldData.dateOfIncorporation : '2014-08-18',
        dateOfEntryInCommercialRegister: (oldData && oldData.dateOfEntryInCommercialRegister) ? oldData.dateOfEntryInCommercialRegister : '2014-08-18',
        placeOfCommercialRegister: (oldData && oldData.placeOfCommercialRegister) ? oldData.placeOfCommercialRegister : '',
        companyShareCapital: (oldData && oldData.companyShareCapital) ? oldData.companyShareCapital : '',
        companyPlace: (oldData && oldData.companyPlace) ? oldData.companyPlace : '',
        companyAddress: (oldData && oldData.companyAddress) ? oldData.companyAddress : '',
        companyCountry: (oldData && oldData.companyCountry) ? oldData.companyCountry : '',
        companyPostalCode: (oldData && oldData.companyPostalCode) ? oldData.companyPostalCode : '',
        descriptionRegisterExtractBusinessActivity: (oldData && oldData.descriptionRegisterExtractBusinessActivity) ? oldData.descriptionRegisterExtractBusinessActivity : '',
        sourceOfIncome: (oldData && oldData.sourceOfIncome) ? oldData.sourceOfIncome : '',
        hasNotRelatedSourceIncome: (oldData && oldData.hasNotRelatedSourceIncome) ? oldData.hasNotRelatedSourceIncome : false,
        notRelatedSourceIncomeDescription: (oldData && oldData.notRelatedSourceIncomeDescription) ? oldData.notRelatedSourceIncomeDescription : '',
        investmentPurpose: (oldData && oldData.investmentPurpose) ? oldData.investmentPurpose : '',
        grossRevenue: (oldData && oldData.grossRevenue) ? oldData.grossRevenue : 'Up to 5.000 CHF',
        descriptionOfFunds: (oldData && oldData.sourceOfFunds) ? oldData.sourceOfFunds : '',
        business_countries: (oldData && oldData.business_countries) ? oldData.business_countries : '',
        business_following_industries: (oldData && oldData.business_following_industries) ? oldData.business_following_industries : '',
        sic_code: (oldData && oldData.sic_code) ? oldData.sic_code : '',
        amount: (oldData && oldData.amount)
          ? `${oldData.amount}`
          : (is_gto_sales
            ? String(userData.token_invest_amount || 0)
            : "0"
          ),
        exactIntendedInvestmentAmount: (oldData && oldData.exactIntendedInvestmentAmount) ? oldData.exactIntendedInvestmentAmount : 0,
        intended_investment: (oldData && oldData.intended_investment) ? oldData.intended_investment : intendedInvestmentOptions[0],
        sourceOfFunds: (oldData && oldData.sourceOfFunds) ? oldData.sourceOfFunds : '',
        wallet: (oldData && oldData.wallet) ? oldData.wallet : (publicSettings.kyc_accepted_wallets ? Object.entries(publicSettings.kyc_accepted_wallets).find(entry => entry[1]) : ['eth'])[0],
        sending_wallet: (oldData && oldData.sending_wallet) ? oldData.sending_wallet : (publicSettings.kyc_accepted_wallets ? Object.entries(publicSettings.kyc_accepted_wallets).find(entry => entry[1]) : ['eth'])[0],
        tokenAddress: (oldData && oldData.tokenAddress)
          ? oldData.tokenAddress
          : (userData && userData.wallet_address
            ? userData.wallet_address
            : ''
          ),
        verified_wallet: oldData && oldData.verified_wallet,
        isAddEtherWalletLater: false,
        tokenSendingAddress: (oldData && oldData.tokenSendingAddress)
          ? oldData.tokenSendingAddress
          :(userData && userData.wallet_sending_address
            ? userData.wallet_sending_address
            : ''
          ),

        // Step 2
        directors: (oldData && oldData.customerCompanyDirector.length) ? oldData.customerCompanyDirector.map(director => ({
          firstName: director.firstName,
          lastName: director.lastName,
          email: director.email,
          signatureRight: director.signatureRight,
          status: director.status,
          personType: director.personType,
          duties: director.duties,
          relationshipToPEP: director.relationshipToPEP,
          willSignForm: director.willSignForm,
          userStatus: director.user.is_active ? [
            'EMAIL_VERIFIED',
            director.status !== 'VIDEO_IDENT' ? `DIRECTOR_PROFILE_${director.status}` : 'DIRECTOR_PROFILE_SUBMITTED',
            'VIDEO_IDENT_' + (director.onfidoApplicant && director.onfidoApplicant.onfidoChecks.length > 0 ? 'PASSED' : 'NOT_STARTED'),
          ] : ['EMAIL_NOT_VERIFIED'],
        })) : [
          {
            firstName: userData.first_name,
            lastName: userData.last_name,
            email: userData.email,
            signatureRight: 'SINGLE',
            status: 'pending',
            personType: '',
            duties: '',
            relationshipToPEP: '',
            willSignForm: false,
            userStatus: ['EMAIL_VERIFIED', oldData ? 'KYC_REOPEN' : 'KYC_NOT_SUBMITTED', 'VIDEO_IDENT_NOT_STARTED'],
          },
        ],

        // Step 3
        proofOfResidenceType: (oldData && oldData.proofOfResidenceType) ? oldData.proofOfResidenceType : 'BANK_STATEMENT',
        proofOfResidenceUploadFile: null,

        // Step 4
        uboList: (oldData && oldData.customerCompanyUbo.length)
          ? (oldData.customerCompanyUbo.map(ubo => ({
            firstName: ubo.firstName,
            lastName: ubo.lastName,
            email: ubo.email,
            trueCertifiedCopyType: ubo.trueCertifiedCopyType,
            trueCertifiedCopyFile: null,
            status: ubo.status,
            ownership: ubo.ownership,
            birthDate: ubo.birthDate,
            personType: ubo.personType,
            duties: ubo.duties,
            relationshipToPEP: ubo.relationshipToPEP,
          })).filter(ubo => (ubo.email !== (directorAsUbo ? directorAsUbo.email : null))))
          : [],

        // Step 5
        personType: oldData ? oldData.personType : 'NOT_POLITICAL_PERSON',
        duties: oldData ? oldData.duties : '',
        relationshipToPEP: oldData ? oldData.relationshipToPEP : '',

        // will be omitted
        directorAsUbo: directorAsUbo
          ? ({
              firstName: directorAsUbo.firstName,
              lastName: directorAsUbo.lastName,
              email: directorAsUbo.email,
              trueCertifiedCopyType: directorAsUbo.trueCertifiedCopyType,
              trueCertifiedCopyFile: null,
              status: directorAsUbo.status,
              ownership : directorAsUbo.ownership,
              birthDate: directorAsUbo.birthDate,
              personType: directorAsUbo.personType,
              duties: directorAsUbo.duties,
              relationshipToPEP: directorAsUbo.relationshipToPEP,
            }) : null
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicSettings, userData, oldData, is_gto_sales])

  const submitForm = () => {
    if (!values.directors.length) {
      toaster.error('Director list must not be empty')
      return
    }

    if (!values.directorAsUbo && publicSettings.kyb_require_ubo) {
      toaster.error('You must select a director for UBO.')
      return
    }

    const getSubmitFile = data => (data ? data.img : null)
    const sendInput = {
      ...omit(values, [
        'directorAsUbo',
        'generatedWallet',
        ...(updateMode ? ['referral_code'] : []),
      ]),
      corporationOrganizationChartFile: getSubmitFile(values.corporationOrganizationChartFile),
      commercialRegisterExtract: getSubmitFile(values.commercialRegisterExtract),
      articlesOfAssociationFile: getSubmitFile(values.articlesOfAssociationFile),
      lastBalanceSheetFile: getSubmitFile(values.lastBalanceSheetFile),
      certificateOfGoodStandingFile: getSubmitFile(values.certificateOfGoodStandingFile),
      shareholderListFile: getSubmitFile(values.shareholderListFile),
      proofOfResidenceUploadFile: getSubmitFile(values.proofOfResidenceUploadFile),
      supportedSourceOfFundUploadFiles: values.supportedSourceOfFundUploadFiles.map(getSubmitFile),
      isAddEtherWalletLater: values.isAddEtherWalletLater || isFiat(values.currency),

      exactIntendedInvestmentAmount: +values.exactIntendedInvestmentAmount,

      directors: values.directors.map(director => ({
        firstName: director.firstName,
        lastName: director.lastName,
        email: director.email,
        signatureRight: director.signatureRight,
        personType: director.personType,
        duties: director.duties,
        relationshipToPEP: director.relationshipToPEP,
        willSignForm: director.willSignForm,
      })),
      uboList: [
        ...(publicSettings.kyb_require_ubo ? [{
          firstName: values.directorAsUbo.firstName,
          lastName: values.directorAsUbo.lastName,
          email: values.directorAsUbo.email,
          trueCertifiedCopyType: values.directorAsUbo.trueCertifiedCopyType,
          trueCertifiedCopyFile: getSubmitFile(values.directorAsUbo.trueCertifiedCopyFile),
          ownership: +values.directorAsUbo.ownership,
          birthDate: values.directorAsUbo.birthDate,
          personType: values.directorAsUbo.personType,
          duties: values.directorAsUbo.duties,
          relationshipToPEP: values.directorAsUbo.relationshipToPEP,
        }] : []),
        ...values.uboList.map(ubo => ({
          firstName: ubo.firstName,
          lastName: ubo.lastName,
          email: ubo.email,
          trueCertifiedCopyType: ubo.trueCertifiedCopyType,
          trueCertifiedCopyFile: getSubmitFile(ubo.trueCertifiedCopyFile),
          ownership: +ubo.ownership,
          birthDate: ubo.birthDate,
          personType: ubo.personType,
          duties: ubo.duties,
          relationshipToPEP: ubo.relationshipToPEP,
        }))
      ]
    };
    if (updateMode) {
      updateCompanyCustomer({
        variables: {
          id: oldData.id,
          input: sendInput,
        }
      }).catch(() => {})
    } else {
      registerCompanyCustomer({ variables: { input: sendInput } }).catch(() => {})
    }
  }

  if (data) {
    const urlRedirect = `/application-success/company/${
      Object.values(data)[0].id
    }`;
    return <Redirect to={urlRedirect || "/profile"} />;
  }
  const goToStep = (step) => {
    const formWrapper = document.getElementById('form_wrapper');

    if (formWrapper) {
      window.scrollTo(0, formWrapper.offsetTop);
    }

    setKycFormStepNumber(step);
  }

  return (
    <div id="form_wrapper">
      <div className="h3 nospace__bottom">{ t('Company Account') }</div>
      <div className="shop__info">{ t('To comply with regulation each participant will have to go through indentity verification (KYC/AML) to prevent fraud causes. Please, complete our fast and secure verification process to participate in our token sale.') }</div>
      <div className="page__title">{' '}</div>

      <div className="card">
        <div className="kyc__stepper kyc__stepper_32">
          <Stepper activeStep={kycFormStepNumber}>
            <Step key={0}>
                <StepLabel>{t('Company Details')}</StepLabel>
              </Step>

              <Step key={1}>
                <StepLabel>{t('Authorized signatories')}</StepLabel>
              </Step>

              {publicSettings.kyb_require_utility_bill && (
                <Step key={2}>
                  <StepLabel>{t('Proof of residence')}</StepLabel>
                </Step>
              )}

              {publicSettings.kyb_require_ubo && (
                <Step key={3 - (!publicSettings.kyb_require_utility_bill)}>
                  <StepLabel>{t('UBO')}</StepLabel>
                </Step>
              )}

              <Step key={4 - (!publicSettings.kyb_require_ubo) - (!publicSettings.kyb_require_utility_bill)}>
                <StepLabel>{t('Legal Disclaimers')}</StepLabel>
              </Step>
          </Stepper>
        </div>
      </div>

      <div className="card">
        {/*STEP 1*/}
        { kycFormStepNumber === 0 && (
          <CompanyStep1
            publicSettings={publicSettings}
            intendedInvestmentOptions={intendedInvestmentOptions}
            values={values}
            setValues={setValues}
            toNextStep={() => {goToStep(1)}}
            errors={error}
            oldData={oldData}
          />
        )}

        {/*STEP 2*/}
        { kycFormStepNumber === 1 && (
          <CompanyStep2
            values={values}
            setValues={setValues}
            toPrevStep={() => {goToStep(0)}}
            toNextStep={() => {goToStep(2)}}
          />
        )}

        {/*STEP 3*/}
        { kycFormStepNumber === 2 && publicSettings.kyb_require_utility_bill && (
          <CompanyStep3
            values={values}
            setValues={setValues}
            toPrevStep={() => {goToStep(1)}}
            toNextStep={() => {goToStep(3)}}
            errors={error}
            oldData={oldData}
            publicSettings={publicSettings}
          />
        )}

        {/*STEP 4*/}
        { kycFormStepNumber === (3 - (!publicSettings.kyb_require_utility_bill)) && publicSettings.kyb_require_ubo && (
          <CompanyStep4
            values={values}
            setValues={setValues}
            toPrevStep={() => { goToStep(2 - (!publicSettings.kyb_require_utility_bill)) }}
            toNextStep={() => { goToStep(4 - (!publicSettings.kyb_require_utility_bill)) }}
          />
        )}

        {/*STEP 5*/}
        { kycFormStepNumber === (4 - (!publicSettings.kyb_require_ubo) - (!publicSettings.kyb_require_utility_bill)) && (
          <CompanyStep5
            publicSettings={publicSettings}
            values={values}
            setValues={setValues}
            checkboxValues={checkboxValues}
            setCheckboxValues={setCheckboxValues}
            toPrevStep={() => { goToStep(3 - (!publicSettings.kyb_require_ubo) - (!publicSettings.kyb_require_utility_bill)) }}
            submitForm={submitForm}
            loading={loading}
          />
        )}
      </div>
    </div>
  )
}

KycCompanyVerificationForm.propTypes = {
  publicSettings: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  kycFormStepNumber: PropTypes.number,
  intendedInvestmentOptions: PropTypes.array.isRequired,
};

export default KycCompanyVerificationForm
