import React from 'react'

const TableRow = ({
  // eslint-disable-next-line react/prop-types
  children,
  typeRow = "products__row",
  propertyName,
}) => {
  return (
    <>
      {typeRow === "transactions__row" ? (
        <div className="transactions__row" key={propertyName}>
          {children}
        </div>
      ) : (
        <div className="products__row" key={propertyName}>
          {children}
        </div>
      )}
    </>
  );
}

export default TableRow;
