import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import SwitchCheckboxTheme from '../SwitchCheckbox'

const CompanyStep5DisclaimerLiquidation = ({
  publicSettings,
  checkboxValues,
  setCheckboxValues,
  prospectusOrIssuing,
  setEnableSubmit,
  values,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const enable = [
      ...(publicSettings.kyc_client_statement ? ['clientStatement'] : []),
      'declarationOfConsent1',
      'declarationOfConsent2',
      'declarationOfConsent3',
      'declarationOfConsent4',
    ].reduce((all, key) => all && checkboxValues[key], true);

    setEnableSubmit(enable);
    // eslint-disable-next-line
  }, [checkboxValues]);

  const changeCheckbox = (field) => ({target: {checked}}) => {
    setCheckboxValues({...checkboxValues, [field]: checked})
  }

  return (
    <>
      {publicSettings.kyc_client_statement && (
        <div className="settings__item">
          <div className="title-primary settings__title">
            {t('Client Statement')}
          </div>

          <div className="field">
            <div className="field__wrap">
              <SwitchCheckboxTheme
                onChange={changeCheckbox('clientStatement')}
                defaultChecked={checkboxValues.clientStatement}
                value="clientStatement"
                switchText={`I confirm that I am the beneficial owner of the Claim of the ${publicSettings.token_name} Tokens`}
                isRemoveLine
                isGrayText
                isLeftSwitch
              />
            </div>
          </div>
        </div>
      )}

      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Declaration of Consent')}
        </div>

        <div className="field">
          <div className="field__wrap">
            <SwitchCheckboxTheme
              onChange={changeCheckbox('declarationOfConsent1')}
              defaultChecked={checkboxValues.declarationOfConsent1}
              value="declarationOfConsent1"
              isRemoveLine
              isGrayText
              isLeftSwitch
              switchText={(
                <>
                  I Hereby agree that I will receive the compensation,
                  once the Lawyer approved the change of claim from you
                  {` as ${values.companyName}`}
                  {` to ${publicSettings.company.name}`}
                </>
              )}
            />

            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              onChange={changeCheckbox(
                "declarationOfConsent2",
                checkboxValues,
                setCheckboxValues
              )}
              defaultChecked={checkboxValues.declarationOfConsent2}
              value
              switchText={(
                <>
                  I Hereby Consent To My Personal Data Being Processed By {publicSettings.company.name}
                  And All Companies Affiliated With It, As Well As The Persons And Entities Involved
                  In The Management And Supervision Of My Investment (In Particular
                  {publicSettings.company.name}’s Business, Banks, Tax Advisors, Auditors) Being Stored
                  In Computer Equipment And Be Used. The Data Collected May Also Be Used For The Purposes Of
                  Advertising By Email And Post, Regardless Of A Contractual Relationship.
                  Please See The Declaration Of Consent.
                </>
              )}
            />

            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              onChange={changeCheckbox(
                "declarationOfConsent3",
                checkboxValues,
                setCheckboxValues
              )}
              defaultChecked={checkboxValues.declarationOfConsent3}
              value
              switchText={(
                <>
                  I Hereby Declare That I Have Been Provided With The Privacy Policy Of {publicSettings.company.name}
                </>
              )}
            />

            <SwitchCheckboxTheme
              isGrayText
              isLeftSwitch
              isRemoveLine
              onChange={changeCheckbox(
                "declarationOfConsent4",
                checkboxValues,
                setCheckboxValues
              )}
              defaultChecked={checkboxValues.declarationOfConsent4}
              value
              switchText={(
                <>
                  I Hereby Confirm That The Declarations Made To The Best Of My Knowledge And Belief
                  Are Correct And Complete. Any Changes To The Aforementioned Circumstances Will Be
                  Communicated To {publicSettings.company.name} Without Delay In Written Form And Must Be
                  Forwarded An Updated Self-Assessment Within 30 Days Upon Request.
                </>
              )}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CompanyStep5DisclaimerLiquidation
