import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Web3 from 'web3'
import classNames from 'classnames'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputThemeField from './InputThemeField'
import Dialog from '@material-ui/core/Dialog'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import TextField from '@material-ui/core/TextField'
import DialogContent from '@material-ui/core/DialogContent'
import { DialogTitle, MenuItem } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { uniq } from 'lodash'

import TablePaginationActions from './TablePagination'
import {
  CHANGE_TRANSACTION_STATUS,
  CANCEL_TRANSACTION_USER,
  APPROVED_TRANSACTION,
  UPDATE_ALLOCATE_TRANSACTION_HASH,
  CREATE_INTEREST_PAYMENT_TRANSACTION,
  CREATE_HINT_GIVER_TRANSACTION,
  MARK_CAPITAL_INCREASE,
} from '../queriesAndMutations'
import { TRANSACTIONS_STATUS_TYPES, PAYMENT_TYPES, TRANSACTIONS_TYPES } from '../constants/transaction'
import { USER_KYC_STATUS_TYPES, USER_RIGHT_TYPES } from '../constants/user'
import { TOKEN_TYPES } from '../constants/settings'
import { isProduction } from '../utils/app'
import { formatDate } from '../utils/table'
import { toaster } from '../utils/toaster'
import { getQueryVars } from '../utils/url'
import { usePublicSettings } from '../myHooks/useSettings'
import { useExchangeRates, useMe } from '../myHooks/index'
import AdminAddPaymentModal from './AdminAddPaymentModal'
import {
  mandatoryKyc,
  hasUserEnoughRights,
} from '../utils/me'
import { tokenToEth, ethToFiat } from '../utils/rate'
import { TRANSACTION } from '../constants/riskAssessmentLookup'
import metamaskService from '../services/metamask'
import contractAbi from '../constants/contractAbi'

import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import { useTranslation } from 'react-i18next'
import Icon from './Icon'
import AdminExportTransactions from './AdminCsvDialogs/AdminExportTransactions'
import TableRowTheme from './TableRow'
import TableColumnTheme from './TableColumn'
import moment from 'moment'
import ChangeTokenAmountTransactionModal from './ChangeTokenAmountTransactionModal'
import InputTheme from "./InputTheme";

const useStyles = makeStyles(() => {
  const defaultStatusIcon = {
    margin: "0 8px 0 0",
    opacity: ".8",
  };

  const statusBadge = {
    padding: "6px 12px",
    border: "1px solid #000",
    borderRadius: "8%",
    textTransform: "lowercase",
  };

  return {
    table: {
      margin: "0",
    },
    tableHeader: {
      "& .MuiTableCell-head": {
        color: "#007bff",
        fontSize: "14px",
        fontWeight: "bold",
        padding: "10px",
      },
    },
    tableBody: {
      "& .MuiTableRow-root": {
        minHeight: "75px",
      },
      "& .MuiTableCell-body": {
        padding: "10px",
      },
    },
    circularProgressWrapper: {
      display: "flex",
      justifyContent: "center",
    },
    activeStatusBadge: {
      textTransform: "capitalize",
      padding: "6px 12px",
      border: "1px solid #009f65",
      borderRadius: "8%",
    },
    disabledStatusBadge: {
      textTransform: "lowercase",
      padding: "6px 12px",
      border: "1px solid #ea0b0b",
      borderRadius: "8%",
    },
    actionBtnPaper: {
      zIndex: 10,
      position: "absolute",
      "&>div": {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        "&>svg": {
          marginRight: "5px",
        },
        padding: "8px 10px",
      },
    },
    actionMenuOption: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "&>svg": {
        marginLeft: "5px",
      },
    },
    txNum: {
      display: "flex",
      alignItems: "center",
    },
    cellText: {
      opacity: ".8",
      fontWeight: "bold",
      fontSize: "14px",
    },
    cellSubText: {
      opacity: ".6",
      fontSize: "12px",
    },
    successIcon: {
      ...defaultStatusIcon,
      color: "#009f65",
    },
    pendingIcon: {
      ...defaultStatusIcon,
      color: "#ffc100",
    },
    errorIcon: {
      ...defaultStatusIcon,
      color: "#f00",
    },
    idleIcon: {
      ...defaultStatusIcon,
      color: "#000",
    },
    buttonPay: {
      color: "#fff",
      fontSize: "11px",
      padding: "5px 15px",
      margin: "8px",
      textTransform: "capitalize",
      backgroundColor: "#007bff",
      "&:hover": {
        backgroundColor: "#253992",
      },
      "& svg": {
        margin: "0 0 0 8px",
      },
    },
    buttonDelete: {
      borderRadius: "4px",
      color: "#ff6868",
      backgroundColor: "#ffd8d8",
      transition: ".3s",
      "&:hover": {
        transition: ".3s",
        color: "#fff",
        backgroundColor: "#ff6868",
      },
    },
    buttonInfo: {
      color: "#495463",
      backgroundColor: "#e6effb",
      borderRadius: "4px",
      transition: ".3s",
      "&:hover": {
        transition: ".3s",
        color: "#fff",
        backgroundColor: "#495463",
      },
    },
    buttonBar: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      padding: "8px 10px",
      color: "rgba(0, 0, 0, 0.87)",
    },
    actionIcon: {
      marginRight: "5px",
    },
    idleStatusBadge: statusBadge,
    pendingStatusBadge: {
      ...statusBadge,
      borderColor: "#ffc100",
    },
    rejectedStatusBadge: {
      ...statusBadge,
      borderColor: "#ea0b0b",
    },
    controlBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    searchField: {
      margin: "8px",
    },
    buttonsBar: {
      display: "flex",
      alignItems: "center",
    },
    settingsPepper: {
      right: "46px",
      padding: "10px 12px",
      position: "absolute",
      zIndex: 10,
      "& span": {
        textTransform: "lowercase",
      },
    },
  };
});

const ConfirmApprovedTransactionModal = ({
  transaction,
  isOpenConfirmTransacModal = false,
  setIsOpenConfirmTransacModal,
  refetch,
}) => {
  const { t } = useTranslation();
  const { data: { exchangeRates } = {} } = useExchangeRates();
  const [approvedTransaction] = useMutation(APPROVED_TRANSACTION);

  const [transactionRiskLevel, setTransactionRiskLevel] = React.useState(
    TRANSACTION[0].id
  );

  const [businessProfileTheSame, setBusinessProfielTheSame] =
    React.useState("false");

  const [explainText, setTextExplain] = React.useState("");
  const [approvedToken, setApprovedToken] = React.useState(transaction.token_amount || "");

  const [isDisabledConfirmTransactionBtn, setIsDisabledConfirmTransactionBtn] =
    React.useState(false);

  const handleConfirmTransaction = async () => {
    setIsDisabledConfirmTransactionBtn(true);
    try {
      await approvedTransaction({
        variables: {
          input: {
            transactionId: transaction.id,
            transactionRiskLevel: transactionRiskLevel,
            businessProfileTheSame: businessProfileTheSame === "true",
            explainText: explainText,
            approvedToken
          },
        },
      });

      if (window.dataLayer) {
        window.dataLayer.push({
          event: "approve",
          transactionId: transaction.id,
          transactionTotal: ethToFiat("eur", tokenToEth(transaction.token_amount, exchangeRates), exchangeRates),
        });
      }

      setIsOpenConfirmTransacModal(false);
      setIsDisabledConfirmTransactionBtn(false);
      refetch();
    } catch (e) {}
  };

  return (
    <Dialog
      open={isOpenConfirmTransacModal}
      onClose={() => {
        setIsOpenConfirmTransacModal(false);
      }}
      aria-labelledby="form-dialog-title"
    >
      <div className="popup js-popup animation visible popup__kyc-medium">
        <div onClick={() => {setIsOpenConfirmTransacModal(false);}} className="popup__overlay js-popup-overlay">{' '}</div>

        <div className="popup__wrap js-popup-wrap">
          <div className="description">
            <div className="title-red description__title">{t('Confirm Approved Transaction')}</div>

            <div className="settings__fieldset description__row flex__row flex__wrap">
              <div className="w-full">
                <div className="field__label">
                  {`Name: ${transaction.user.first_name} ${transaction.user.last_name}`}
                </div>
              </div>

              <div className="field w-full">
                <div className="field__wrap field__wrap-select">
                  <InputThemeField
                    classWrapper="field w-full"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={t('Approved Token')}
                    value={approvedToken}
                    onChange={(e) => {
                      setApprovedToken(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="field w-full">
                <div className="field__label">
                  {t('Where did the Transaction come from?')}
                </div>

                <div className="field__wrap field__wrap-select">
                  <TextField
                    select
                    value={transactionRiskLevel}
                    onChange={(e) => {
                      setTransactionRiskLevel(e.target.value);
                    }}
                    fullWidth
                  >
                    {TRANSACTION.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>

              <div className="field field__radio-kyc-style-1 field__radio-kyc-style-1_1 w-full">
                <div className="field__label">
                  {t('Was business profile the same?')}
                </div>

                <div className="field__wrap checkbox__wrapper field__wrap d__flex flex__row flex__wrap">
                  <RadioGroup
                    value={businessProfileTheSame}
                    onChange={({ target: { value } }) => {
                      setBusinessProfielTheSame(value);
                    }}
                  >
                    <FormControlLabel
                      label={t('Yes')}
                      value="true"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      label={t('No')}
                      value="false"
                      control={<Radio />}
                    />
                  </RadioGroup>
                </div>
              </div>

              {businessProfileTheSame === "false" && (
                <InputThemeField
                  classWrapper="field w-full"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  label={`${t('Explain why different')}`}
                  value={explainText}
                  onChange={(e) => {
                    setTextExplain(e.target.value);
                  }}
                />
              )}

              <div className="field">
                <button
                  className="button-stroke"
                  onClick={handleConfirmTransaction}
                  disabled={isDisabledConfirmTransactionBtn}
                >
                  {t('Confirm Transaction')}
                </button>
              </div>
            </div>
          </div>

          <button
            className="popup__close js-popup-close"
            onClick={() => {setIsOpenConfirmTransacModal(false);}}
          >
            <Icon
              className={classNames('close', 'icon-24')}
              name="close"
              size="24"
            />
          </button>
        </div>
      </div>
    </Dialog>
  );
};

const TransactionListActions = ({
  transaction,
  loading,
  onChangeTransactionStatus = () => {},
  onAllocateToken = () => {},
  onCancelTransaction = () => {},
  isAdmin = false,
  refetch,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedTransactionId, setActionBtnOpen] = React.useState(null);
  const [transferingToken, setTransferingToken] = React.useState(false);
  const [openModalWarning, setOpenModalWarning] = React.useState(false);
  const [openModalAddPayment, setOpenModalAddPayment] = React.useState(false);
  const [openModalChangeTokenAmount, setOpenModalChangeTokenAmount] = React.useState(false);

  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { me } = {} } = useMe();
  const is_gto_sales =
    transaction.user.is_gtoswiss || transaction.user.is_internal_sales;

  const [isOpenConfirmTransacModal, setIsOpenConfirmTransacModal] =
    React.useState(false);

  const changeModalWarningDialogState = (state) => () => {
    setOpenModalWarning(state);
  };

  const onActionBtnClick = (clickedTransactionId) => () => {
    if (selectedTransactionId === clickedTransactionId) {
      setActionBtnOpen(null);
    } else {
      setActionBtnOpen(clickedTransactionId);
    }
  };

  const onActionBtnClickAway = () => {
    setActionBtnOpen(false);
  };

  const renderDialogWarning = () => (
    <Dialog
      open={openModalWarning}
      onClose={changeModalWarningDialogState(false)}
    >
      <DialogTitle>Warning!</DialogTitle>
      <DialogContent>KYC is not approved for this user</DialogContent>
    </Dialog>
  );

  const onCloseAddPayment = () => {
    setOpenModalAddPayment(false);
  };

  const onOpenAddPayment = () => () => {
    setOpenModalAddPayment(true);
  };

  const renderAddPayment = () => (
    <>
      <div onClick={onOpenAddPayment()}>
        <AccountBalanceIcon />
        Add payment
      </div>
    </>
  );

  // const sendContractTransactionRequest = async (transaction) => {
  //   if (!publicSettings.enable_smart_contract) {
  //     toaster.error('Smart contract has not been enabled')

  //     return
  //   }

  //   if ((!transaction.user.wallet_address || transaction.user.wallet_type.toLowerCase() !== 'eth') && !transaction.user.eth_receiving_wallet) {
  //     toaster.error('User wallet not found')

  //     return
  //   }

  //   const address = transaction.user.eth_receiving_wallet || transaction.user.wallet_address

  //   if (!Web3.utils.isAddress(address)) {
  //     return
  //   }

  //   if (metamaskService.isMetamaskNotAvailable()) {
  //     toaster.error('You need to install Metamask to use this feature')

  //     return
  //   }

  //   await metamaskService.requestAccounts()

  //   const web3 = new Web3(window.ethereum)
  //   const account = metamaskService.getCurrentAddress()
  //   const contractAddress = publicSettings.smart_contract_address
  //   const contract = new web3.eth.Contract(
  //     publicSettings.smart_contract_type === 'crowdsale_contract'
  //       ? contractAbi.crowdsale
  //       : contractAbi.token,
  //     contractAddress
  //   );

  //   contract.methods.registerPayment(address, Math.ceil(+transaction.currency_amount * Math.pow(10, 18))).send({
  //     from: account,
  //     value: 0
  //   })
  // }

  const renderAllocateButton = (transaction) => {
    if (!transaction.capitalIncrease) {
      return null
    }

    if (
      !isAdmin ||
      transaction.status !== TRANSACTIONS_STATUS_TYPES.APPROVED ||
      transaction.allocate_tx_hash ||
      !publicSettings.enable_smart_contract ||
      !publicSettings.smart_contract_address
    ) {
      return null
    }
    if (
      publicSettings.smart_contract_type === 'crowdsale_contract' &&
      transaction.payment_type === 'CRYPTO' &&
      ['eth', 'dai'].includes(transaction.currency)
    ) {
      return null
    }

    return (
      <button
        className="actions__option"
        onClick={() => {
          setTransferingToken(true)

          onAllocateToken().finally(() => {
            setTransferingToken(false)
          })
        }}
      >
        {
          transferingToken ? (
            <>
              <CircularProgress />
              {' '}
              Please confirm and wait
            </>
          ) : (
            <>
              <Icon
                className={classNames('payment')}
                name='payment'
                size="24"
              />{" "}
              {t('Allocate token')}
            </>
          )
        }
      </button>
    )
  }

  const renderChangeStatusBtns = () => {
    switch (transaction.status) {
      case TRANSACTIONS_STATUS_TYPES.IN_PROGRESS:
        return (
          <>
            {transaction.currency === "eth" ? (
              // если владелец транзакции не заполнил kyc и она не откланена и kyc обязательна к заполнению, то выводим
              // предупреждение
              <>
                {/**
                <button
                  className="actions__option"
                  onClick={async () => {
                    await sendContractTransactionRequest(transaction)
                  }}
                >
                  <Icon
                    className={classNames('upload')}
                    name='upload'
                    size="24"
                  />{" "}
                  Register Payment
                </button>
                */}
                <button
                  className="actions__option"
                  onClick={
                    transaction.user.kyc_status !==
                      USER_KYC_STATUS_TYPES.PASSED &&
                    transaction.user.kyc_status !==
                      USER_KYC_STATUS_TYPES.REJECTED &&
                    mandatoryKyc(
                      is_gto_sales,
                      publicSettings.accepted_mandatory_kyc || {}
                    )
                      ? changeModalWarningDialogState(true)
                      : onChangeTransactionStatus(
                          transaction.id,
                          TRANSACTIONS_STATUS_TYPES.CONTRACTED,
                          transaction.user.kyc_status,
                          transaction
                        )
                  }
                >
                  <Icon
                    className={classNames('check-all')}
                    name='check-all'
                    size="24"
                  />{" "}
                  { t('Mark as Contracted') }
                </button>
              </>
            ) : (
              // если владелец транзакции не заполнил kyc и kyc обязательна к заполнению, то выводим
              // предупреждение
              <button
                className="actions__option"
                onClick={
                  transaction.user.kyc_status !==
                    USER_KYC_STATUS_TYPES.PASSED &&
                  mandatoryKyc(
                    is_gto_sales,
                    publicSettings.accepted_mandatory_kyc || {}
                  )
                    ? () => {
                        onActionBtnClickAway();
                        changeModalWarningDialogState(true)();
                      }
                    : () => {
                        onActionBtnClickAway();
                        if (publicSettings.enable_cashlink && !transaction.user.wallet_address) {
                          toaster.error('User has not provided the receiving wallet');
                        } else {
                          setIsOpenConfirmTransacModal(true);
                        }
                      }
                }
              >
                <Icon
                  className={classNames('check-all')}
                  name='check-all'
                  size="24"
                />{" "}
                { t('Approve') }
              </button>
            )}
            <button
              className="actions__option"
              onClick={
                !isAdmin &&
                // если владелец транзакции не заполнил kyc и она не откланена и kyc обязательна к заполнению и это не админ, то выводим
                // предупреждение
                transaction.user.kyc_status !== USER_KYC_STATUS_TYPES.PASSED &&
                transaction.user.kyc_status !==
                  USER_KYC_STATUS_TYPES.REJECTED &&
                mandatoryKyc(
                  is_gto_sales,
                  publicSettings.accepted_mandatory_kyc || {}
                )
                  ? changeModalWarningDialogState(true)
                  : onChangeTransactionStatus(
                      transaction.id,
                      TRANSACTIONS_STATUS_TYPES.REJECTED,
                      transaction.user.kyc_status,
                      transaction
                    )
              }
            >
              <Icon
                className={classNames('close')}
                name='close'
                size="24"
              />{" "}
              {t('Reject')}
            </button>
          </>
        );
      case TRANSACTIONS_STATUS_TYPES.CONTRACTED:
        if (transaction.currency === "eth") {
          return (
            <button
              className="actions__option"
              onClick={
                transaction.user.kyc_status !==
                  USER_KYC_STATUS_TYPES.PASSED &&
                // если владелец транзакции не заполнил kyc и kyc обязательна к заполнению, то выводим
                // предупреждение
                mandatoryKyc(
                  is_gto_sales,
                  publicSettings.accepted_mandatory_kyc || {}
                )
                  ? changeModalWarningDialogState(true)
                  : onChangeTransactionStatus(
                      transaction.id,
                      TRANSACTIONS_STATUS_TYPES.APPROVED,
                      transaction.user.kyc_status,
                      transaction
                    )
              }
            >
              <Icon
                className={classNames('check-all')}
                name='check-all'
                size="24"
              />{" "}
              {t('Approve')}
            </button>
          );
        }
        break;
      default:
    }

    return null;
  };

  const renderCancelTransactionBtn = () => {
    switch (transaction.status) {
      case TRANSACTIONS_STATUS_TYPES.IN_PROGRESS:
        if (transaction.type !== TRANSACTIONS_TYPES.REDEMPTION) {
          return (
            <>
              <button
                className="actions__option"
                onClick={onCancelTransaction(
                  transaction.id,
                  TRANSACTIONS_STATUS_TYPES.APPROVED
                )}
              >
                <Icon
                  className={classNames('close')}
                  name='close'
                  size="24"
                />{" "}
                {t('Cancel transaction')}
              </button>
            </>
          );
        } else return null
      default:
    }

    return null;
  };

  const renderBasicActions = () => (
    <NavLink
      className="actions__option"
      to={
        isAdmin
          ? `/admin/transactions/${transaction.id}`
          : `/transactions/${transaction.id}`
      }
    >
      <Icon
        className={classNames('link')}
        name='link'
        size="24"
      />{" "}
      {t('Details')}
    </NavLink>
  );

  const renderChangeTokenAmountButton = () => {
    if (!isAdmin) {
      return null
    }

    return (
      <button
        className="actions__option"
        onClick={() => {
          setOpenModalChangeTokenAmount(true)
        }}
      >
        <Icon
          className={classNames('edit')}
          name='edit'
          size="24"
        />{" "}
        {t('Change Token Amount')}
      </button>
    )
  }

  return (
    <>
      {openModalWarning ? renderDialogWarning() : ""}
      {openModalAddPayment && is_gto_sales && (
        <AdminAddPaymentModal
          dialogOpen={openModalAddPayment}
          onCloseDialog={onCloseAddPayment}
          userId={transaction.user.id}
          transactionId={transaction.id}
        />
      )}
      <ClickAwayListener onClickAway={onActionBtnClickAway}>
        <div className={`actions actions__kyc ${!!selectedTransactionId || selectedTransactionId === 0 ? "active" : ""}`}>
          {loading ? (
            <div className={classes.circularProgressWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <button onClick={onActionBtnClick(transaction.id)} className="actions__button">
              <Icon
                className={classNames('more-horizontal')}
                name='more-horizontal'
                size="24"
              />{" "}
            </button>
          )}
          <div className="actions__body">
            {isAdmin && me && me.rights !== USER_RIGHT_TYPES.ACCOUNTING &&
              hasUserEnoughRights(me.rights, USER_RIGHT_TYPES.COMPLIANCE_OFFICER) && renderChangeStatusBtns()}
            {isAdmin && me && me.rights !== USER_RIGHT_TYPES.ACCOUNTING &&
              hasUserEnoughRights(me.rights, USER_RIGHT_TYPES.ADMIN) &&
              is_gto_sales &&
              renderAddPayment()}

            {me && me.rights !== USER_RIGHT_TYPES.ACCOUNTING &&
              hasUserEnoughRights(me.rights, USER_RIGHT_TYPES.COMPLIANCE_OFFICER) && renderAllocateButton(transaction)}
            {me && me.rights !== USER_RIGHT_TYPES.ACCOUNTING &&
              hasUserEnoughRights(me.rights, USER_RIGHT_TYPES.COMPLIANCE_OFFICER) && renderChangeTokenAmountButton()}
            {renderBasicActions()}
            {me.rights !== USER_RIGHT_TYPES.ACCOUNTING && isAdmin === false && renderCancelTransactionBtn()}
          </div>
        </div>
      </ClickAwayListener>
      <ConfirmApprovedTransactionModal
        transaction={transaction}
        isOpenConfirmTransacModal={isOpenConfirmTransacModal}
        setIsOpenConfirmTransacModal={setIsOpenConfirmTransacModal}
        refetch={refetch}
      />

      <ChangeTokenAmountTransactionModal
        transaction={transaction}
        openModalChangeTokenAmount={openModalChangeTokenAmount}
        setOpenModalChangeTokenAmount={setOpenModalChangeTokenAmount}
        refetch={refetch}
      />
    </>
  );
};

TransactionListActions.propTypes = {
  transaction: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onChangeTransactionStatus: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  refetch: PropTypes.func,
};

const TransactionList = ({ query, isAdmin, filteredStatus }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const queryParam = getQueryVars();
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { me } = {} } = useMe();
  const [updateAllocateTransactionHash] = useMutation(UPDATE_ALLOCATE_TRANSACTION_HASH);
  const [createInterestPaymentTransaction, createInterestPaymentTransactionData] = useMutation(CREATE_INTEREST_PAYMENT_TRANSACTION);
  const [createHintGiverTransaction, createHintGiverTransactionData] = useMutation(CREATE_HINT_GIVER_TRANSACTION);
  const [markCapitalIncrease, markCapitalIncreaseData] = useMutation(MARK_CAPITAL_INCREASE);

  const [reqOptions, setReqOptions] = useState({
    search: queryParam.search || '',
    statuses: [],
    paymentTypes: [],
  });
  const [transferingToken, setTransferingToken] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const [isSelectAll, setIsSelectAll] = React.useState(false);
  const [selectedTransactionIds, setSelectedTransactionIds] = React.useState([]);

  const { data: { exchangeRates } = {} } = useExchangeRates();

  const [isDialogDownloadOpen, setDialogDownloadOpen] = useState(false);
  const [isDialogHintGiverOpen, setDialogHintGiverOpen] = useState(false);
  const [onChainTokens, setOnChainTokens] = useState([])
  const [isRegulatorReporting, setRegulatorReporting] = React.useState(false)

  const [hintGiverAmount, setHintGiverAmount] = useState({
    amount: ''
  });

  const {
    data: {
      [isAdmin ? "transactionsAdmin" : "transactions"]: transactionList,
    } = {},
    error,
    loading,
    refetch,
  } = useQuery(query, {
    variables: {
      input: {
        ...reqOptions,
        page,
        pageSize,
      },
    },
    fetchPolicy: "network-only",
  });

  const getOnChainToken = async (address) => {
    try {
      await metamaskService.requestAccounts()

      const account = metamaskService.getCurrentAddress()
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(contractAbi.crowdsale, metamaskService.formatAddress(publicSettings.smart_contract_address));

      const tokenAmount = await contract.methods.balanceOf(metamaskService.formatAddress(address)).call()

      return { address, onChainAmount: tokenAmount };
    } catch (error) {
      console.log(error)
      return {}
    }
  }

  useEffect(() => {
    const getOnChainTokens = async (transactions) => {
      const promiseList = []

      const walletAddressList = uniq(transactions.map(transaction => transaction.user.wallet_address || transaction.user.eth_receiving_wallet))
      const oldWalletAddressList = uniq(transactions.filter(trans => trans.user.old_wallet_address).map(transaction => transaction.user.old_wallet_address))

      const addresses = [...walletAddressList, ...oldWalletAddressList]
      addresses.forEach(address => {
        promiseList.push(getOnChainToken(address))
      });

      const onChainList = await Promise.all(promiseList);

      setOnChainTokens(onChainList)
    }

    if (publicSettings.smart_contract_address && transactionList && transactionList.objects.length) {
      getOnChainTokens(transactionList.objects)
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [publicSettings, transactionList])

  const onChainTokensList = [...onChainTokens]

  const [changeTransactionStatus, { error: errorChangeStatus }] = useMutation(
    CHANGE_TRANSACTION_STATUS
  );

  if (errorChangeStatus) {
    toaster.error(`${errorChangeStatus.message}`);
  }

  const onSettingsClickAway = () => {
    setSettingsOpen(false);
  };

  const onSettingsClick = () => {
    setSettingsOpen((prev) => !prev);
  };

  const isTransactionCheckable = (transaction) => {
    const disabled = (transaction.status !== TRANSACTIONS_STATUS_TYPES.APPROVED && transaction.status !== TRANSACTIONS_STATUS_TYPES.CONTRACTED) || transaction.type === TRANSACTIONS_TYPES.REDEMPTION

    return !disabled;
  };

  const onClickIsSelectAll = () => {
    const selectAll = !isSelectAll;
    setIsSelectAll(!isSelectAll);

    if (selectAll) {
      setSelectedTransactionIds(
        transactionList.objects
          .filter(transaction => isTransactionCheckable(transaction))
          .map(transaction => transaction.id)
      )
    } else {
      setSelectedTransactionIds([])
    }
  }

  const onChangeFilterStatuses = (status) => () => {
    const newStatuses = reqOptions.statuses.includes(status)
      ? reqOptions.statuses.filter((v) => v !== status)
      : [...reqOptions.statuses, status];

    if (filteredStatus) {
      filteredStatus(newStatuses);
    }

    setSettingsOpen(false)
    setReqOptions({ ...reqOptions, statuses: newStatuses });
  };

  const onChangeFilterPaymentTypes = (type) => () => {
    const newTypes = reqOptions.paymentTypes.includes(type)
      ? reqOptions.paymentTypes.filter((v) => v !== type)
      : [...reqOptions.paymentTypes, type];

      setSettingsOpen(false)
    setReqOptions({ ...reqOptions, paymentTypes: newTypes });
  };

  const tokenToEur = (amount) => {
    return ethToFiat("eur", tokenToEth(amount, exchangeRates), exchangeRates);
  };

  const onChangeTransactionStatus =
    (id, status, kyc_status, transaction) => async () => {
      let is_gto_sales =
        transaction.user.is_gtoswiss || transaction.user.is_internal_sales;
      if (
        isAdmin ||
        kyc_status === USER_KYC_STATUS_TYPES.PASSED ||
        kyc_status === USER_KYC_STATUS_TYPES.REJECTED ||
        !mandatoryKyc(is_gto_sales, publicSettings.accepted_mandatory_kyc || {})
      ) {
        try {
          await changeTransactionStatus({
            variables: {
              id,
              status,
            },
          });

          if (TRANSACTIONS_STATUS_TYPES.APPROVED === status && window.dataLayer) {
            window.dataLayer.push({
              event: "approve",
              transactionId: id,
              transactionTotal: tokenToEur(transaction.token_amount),
            });
          }
          refetch();
        } catch (e) {}
      }
    };

  const [cancelTransactionUser] = useMutation(CANCEL_TRANSACTION_USER);

  const onCancelTransaction = (id) => async () => {
    cancelTransactionUser({
      variables: {
        id,
      },
    }).then(() => {
      refetch();
    }).catch(() => {});
  };

  const onChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onChangePageSize = (event) => {
    if (parseInt(event.target.value, 10)) {
      setPageSize(parseInt(event.target.value, 10));
      setPage(0);
    } else {
      setPageSize(1);
      setPage(0);
    }
  };

  const convertRate = (
    token,
    toConvert = "eth_to_chf",
    discountPercent = 0
  ) => {
    const tokenToETH = token * ((exchangeRates || {}).token_to_eth || 1);

    return (
      tokenToETH *
      ((exchangeRates || {})[toConvert] || 1) *
      ((100 - discountPercent) / 100)
    );
  };

  const onChangeReqOptions = (name) => (event) => {
    switch (name) {
      case "search":
        if (event.target.value.length >= 50) {
          return false;
        }
        break;
      default:
    }
    setPage(0);
    return setReqOptions({ ...reqOptions, [name]: event.target.value });
  };

  const renderTextStatus = (status) => {
    const statusStyles = {
      [TRANSACTIONS_STATUS_TYPES.REJECTED]: "status-red-dark products__status",
      [TRANSACTIONS_STATUS_TYPES.IDLE]: "status-idle products__status",
      [TRANSACTIONS_STATUS_TYPES.PENDING]: "status-pending-dark products__status",
      [TRANSACTIONS_STATUS_TYPES.IN_PROGRESS]: "status-pending-dark products__status",
      [TRANSACTIONS_STATUS_TYPES.APPROVED]: "status-green-dark products__status",
      [TRANSACTIONS_STATUS_TYPES.CONTRACTED]: "status-green-dark products__status",
    };

    return <span className={statusStyles[status]}>{t(status.toLowerCase())}</span>;
  };

  const renderAllocateTokenStatus = (transaction) => {
    let isAllocatedToken = transaction && transaction.allocate_tx_hash && transaction.allocate_tx_hash.length > 0;
    isAllocatedToken = isAllocatedToken || (transaction && transaction.tx_hash && transaction.tx_hash.length > 0);
    const allocatedClass = isAllocatedToken ? "status-green-dark products__status" : "status-red-dark products__status";

    return <span className={allocatedClass}>{isAllocatedToken ? "Allocated" : null}</span>;
  };

  const renderStatus = (status) => {
    switch (status) {
      case TRANSACTIONS_STATUS_TYPES.APPROVED:
        return (
          <div className={classNames('customer__avatar status-green d__flex flex__justify_center flex__align_center')}>
            <Icon
              className={classNames('check')}
              name='check'
              size="24"
            />{" "}
          </div>
        );
      case TRANSACTIONS_STATUS_TYPES.CONTRACTED:
        return (
          <span className={classNames('customer__avatar status-green d__flex flex__justify_center flex__align_center')}>
            <Icon
              className={classNames('check')}
              name='check'
              size="24"
            />{" "}
          </span>
        );
      case TRANSACTIONS_STATUS_TYPES.IN_PROGRESS:
        return (
          <span className={classNames('customer__avatar status-green d__flex flex__justify_center flex__align_center')}>
            <Icon
              className={classNames('clock')}
              name='clock'
              size="24"
            />{" "}
          </span>
        );
      case TRANSACTIONS_STATUS_TYPES.PENDING:
        return (
          <span className={classNames('customer__avatar status-green d__flex flex__justify_center flex__align_center')}>
            <Icon
              className={classNames('close')}
              name='close'
              size="24"
            />{" "}
          </span>
        );
      case TRANSACTIONS_STATUS_TYPES.REJECTED:
        return (
          <span className={classNames('customer__avatar status-red d__flex flex__justify_center flex__align_center')}>
            <Icon
              className={classNames('close')}
              name='close'
              size="24"
            />{" "}
          </span>
        );
      default:
        return (
          <span className={classNames('customer__avatar status-idle d__flex flex__justify_center flex__align_center')}>
            <Icon
              className={classNames('help')}
              name='help'
              size="24"
            />{" "}
          </span>
        );
    }
  };

  const renderTxNum = (transaction) => (
    <div className="customer__item customer__item_normal customer__item-transaction">
      {renderStatus(transaction.status)}
      <div className="customer__description">
        <div className="header__subtitle">
          TNX
          {transaction.id}
        </div>
        <div className="customer__login">
          {formatDate(transaction.created_at)}
        </div>
      </div>
    </div>
  );

  const renderSettingsBtn = () => {
    if (loading) {
      return (
        <div className={classes.circularProgressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <ClickAwayListener onClickAway={onSettingsClickAway}>
        <div className={`filters filters__kyc ${settingsOpen ? "active" : ""}`}>
          <button
            className="button-square-stroke button-small filters__head"
            onClick={onSettingsClick}
          >
            <Icon
              className={classNames('filter')}
              name='filter'
              size="24"
            />{" "}
          </button>

          <div className="filters__body">
            <div className="filters__top">
              <div className="title-red filters__title">{ t('Filters') }</div>

              <button className="filters__close" onClick={onSettingsClick}>
                <Icon
                  className={classNames('close')}
                  name='close'
                  size="24"
                />{" "}
              </button>
            </div>

            <div className="filters__group">
              <div className="filters__item">
                <div className="filters__label">{ t('Transaction Status') }</div>

                <div className="filters__list">
                  {Object.values(TRANSACTIONS_STATUS_TYPES).map((v) => (
                    <label key={v} className="checkbox checkbox_reverse">
                      <input
                        type="checkbox"
                        className="checkbox__input"
                        checked={reqOptions.statuses.includes(v)}
                        onChange={onChangeFilterStatuses(v)}
                        value={v}
                      />
                      <div className="checkbox__inner">
                        <div className="checkbox__tick">{' '}</div>
                        <div className="checkbox__text">{v}</div>
                      </div>
                    </label>
                  ))}
                </div>
              </div>
              <div className="filters__item">
                <div className="filters__label">{ t('Payment Type') }</div>

                <div className="filters__list">
                  {Object.values(PAYMENT_TYPES).map((v) => (
                    <label key={v} className="checkbox checkbox_reverse">
                      <input
                        type="checkbox"
                        className="checkbox__input"
                        checked={reqOptions.paymentTypes.includes(v)}
                        onChange={onChangeFilterPaymentTypes(v)}
                        value={v}
                      />
                      <div className="checkbox__inner">
                        <div className="checkbox__tick">{' '}</div>
                        <div className="checkbox__text">{v}</div>
                      </div>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="filters__overlay" onClick={onSettingsClick}>{' '}</div>
        </div>
      </ClickAwayListener>
    );
  };

  const isShowMultipleSelect = isAdmin &&
    publicSettings &&
    // (publicSettings.enable_smart_contract || publicSettings.enable_cashlink) &&
    me && me.rights !== USER_RIGHT_TYPES.ACCOUNTING &&
    hasUserEnoughRights(
      me.rights,
      USER_RIGHT_TYPES.COMPLIANCE_OFFICER
    );

  const toggleMultipleSelectTransaction = (id) => () => {
    const idIndex = selectedTransactionIds.indexOf(id)

    if (idIndex >= 0) {
      setSelectedTransactionIds(selectedTransactionIds.filter(tId => tId !== id))
    } else {
      setSelectedTransactionIds([
        ...selectedTransactionIds,
        id,
      ])
    }
  }

  const allocateToken = async (transactions) => {
    // Break if metamask not available
    if (metamaskService.isMetamaskNotAvailable()) {
      toaster.error('You need to install Metamask to use this feature')

      return
    }

    // Break if metamask not available
    if (transactions.length === 0) {
      toaster.error('No transaction has been selected')

      return
    }

    // Break if smart contract is not enable
    if (!publicSettings.enable_smart_contract) {
      toaster.error('Smart contract has not been enabled')

      return
    }

    if (!['mainnet', 'polygon', 'xinfin'].includes(publicSettings.smart_contract_network)) {
      toaster.error('Smart contract network is not supported')

      return
    }

    // Break if user is trying to do multiple allocation on token contract
    // if (publicSettings.smart_contract_type === 'token_contract' && transactions.length > 1) {
    //   toaster.error('Token contract does not support for multiple allocation')

    //   return
    // }

    try {
      await Promise.all(transactions.map((transaction) => {
        return new Promise((resolve, reject) => {
          if (transaction.status !== TRANSACTIONS_STATUS_TYPES.APPROVED) {
            toaster.error(`Transaction TNX${transaction.id} has not been approved`)
            reject()

            return
          }

          if ((!transaction.user.wallet_address || !['eth', 'usdt', 'xdc', 'polygon', 'dai'].includes(transaction.user.wallet_type.toLowerCase())) && !transaction.user.eth_receiving_wallet) {
            toaster.error(`User ${transaction.user.email} doesn't have ETH or XDC wallet`)
            reject()

            return
          }

          const address = metamaskService.formatAddress(transaction.user.wallet_address || transaction.user.eth_receiving_wallet)

          if (!Web3.utils.isAddress(address)) {
            toaster.error(`User ${transaction.user.email}'s wallet is invalid`)
            reject()

            return
          }

          resolve()
        })
      }))
    } catch (error) {
      return
    }

    try {
      await metamaskService.requestAccounts()

      let network

      switch (publicSettings.smart_contract_network) {
      case 'mainnet':
        network = isProduction ? 'mainnet' : 'sepolia'

        break

      case 'polygon':
        network = isProduction ? 'polygon' : 'mumbai'

        break

      case 'xinfin':
        network = isProduction ? 'xinfin' : 'apothem'

        break

      default:
        toaster.error('Network not supported');

        return
      }

      await metamaskService.requireNetwork(network, () => {
        window.location.reload()
      })

      const web3 = new Web3(window.ethereum)
      const account = metamaskService.getCurrentAddress()
      const contractAddress = metamaskService.formatAddress(publicSettings.smart_contract_address)
      const contract = new web3.eth.Contract(
        publicSettings.smart_contract_type === 'crowdsale_contract'
          ? contractAbi.crowdsale
          : contractAbi.token,
         contractAddress
      );
      const decimal = publicSettings.smart_contract_type === 'crowdsale_contract'
        ? +(await metamaskService.getContractDecimal(await metamaskService.getTokenAddressOfCrowldsaleContract(contractAddress)))
        : +(await metamaskService.getContractDecimal(contractAddress))

      if (publicSettings.smart_contract_type === 'token_contract') {
        for (const transaction of transactions) { /* eslint-disable-line no-restricted-syntax */
          /* eslint-disable no-await-in-loop */

          const allocateAmount = Math.ceil(+(transaction.approved_token || transaction.token_amount) * (10 ** decimal))

          const data = await contract.methods
            .mint(
              metamaskService.formatAddress(transaction.user.wallet_address || transaction.user.eth_receiving_wallet),
              BigInt(allocateAmount).toString()
            ).send({
              from: account,
              value: 0
            });

          await updateAllocateTransactionHash({
            variables: {
              ids: [transaction.id],
              hash: data.transactionHash,
              network,
            }
          })
          /* eslint-enable no-await-in-loop */
        }

        refetch()
        setSelectedTransactionIds([])
      } else if (publicSettings.smart_contract_type === 'crowdsale_contract') {
        const data = await contract.methods.nonEthPurchaseMulti(
          transactions.map(transaction => metamaskService.formatAddress(transaction.user.wallet_address || transaction.user.eth_receiving_wallet)),
          transactions.map(transaction => BigInt(Math.ceil(+(transaction.approved_token || transaction.token_amount))).toString())
        ).send({
          from: account,
          value: 0
        })

        await updateAllocateTransactionHash({
          variables: {
            ids: transactions.map(transaction => transaction.id),
            hash: data.transactionHash,
            network,
          }
        })

        refetch()
        setSelectedTransactionIds([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const allocateTokenForOneTransaction = (transaction) => async () => {
    await allocateToken([transaction])
  }

  const allocateTokenForMultipleTransaction = async () => {
    await allocateToken(transactionList.objects.filter(transaction => selectedTransactionIds.includes(transaction.id)))
  }

  const onCreateInterestPaymentTransaction = async () => {
    try {
      const transactions = transactionList.objects.filter(transaction => selectedTransactionIds.includes(transaction.id))
      await createInterestPaymentTransaction({
        variables: {
          ids: transactions.map(transaction => transaction.id),
        }
      })
      refetch();
      setSelectedTransactionIds([]);
      setPage(0);

      toaster.success('Create interest payment transaction successfully');
    } catch (error) {
      console.log(error)
    }
  }

  const onCreateHintGiverTransaction = async () => {
    try {
      const transactions = transactionList.objects.filter(transaction => selectedTransactionIds.includes(transaction.id))
      await createHintGiverTransaction({
        variables: {
          ids: transactions.map(transaction => transaction.id),
          amount: hintGiverAmount.amount,
        }
      })

      refetch();
      setSelectedTransactionIds([]);
      setPage(0);
      setDialogHintGiverOpen(false);
      setHintGiverAmount({
        amount: ''
      });

      toaster.success('Create hint giver transaction successfully');
    } catch (error) {
      console.log(error)
    }
  }

  const onMarkCapitalIncrease = async () => {
    try {
      const transactions = transactionList.objects.filter(transaction => selectedTransactionIds.includes(transaction.id))
      await markCapitalIncrease({
        variables: {
          ids: transactions.map(transaction => transaction.id),
        }
      })
      refetch();
      setSelectedTransactionIds([]);

      toaster.success('Mark Capital Increase successfully');
    } catch (error) {
      console.log(error)
    }
  }

  const formatPaymentRef = (transaction) =>
    (transaction.investhub_ref ? `INV${transaction.investhub_ref}-${transaction.payment_ref}` : `TNX${transaction.id}-${transaction.payment_ref}`);

  if (error) {
    return <div>{ t('Some error') }</div>;
  }

  if (transactionList && exchangeRates) {
    const emptyRows =
      pageSize -
      Math.min(pageSize, transactionList.meta.total - page * pageSize);

    const transactionListGroup = []
    const transactionListObject = [...transactionList.objects]

    const emailList = transactionListObject.map(transaction => transaction.user.email)
    const emailListUniq = [...new Set(emailList)]

    emailListUniq.forEach(email => {
      const trans = transactionListObject.find(trans => trans.user.email === email);

      transactionListGroup.push({
        user: trans.user,
        objects: transactionListObject.filter(trans => trans.user.email === email)
      })
    })

    console.log('transactionListGroup', transactionListGroup)

    return (
      <>
        <Dialog
          open={isDialogDownloadOpen}
          onClose={() => {
            setDialogDownloadOpen(false);
          }}
        >
          <div className="popup js-popup animation visible">
            <div onClick={() => { setDialogDownloadOpen(false);}} className="popup__overlay js-popup-overlay">{' '}</div>

            <div className="popup__wrap js-popup-wrap">
              <div className="description">
                <div className="title-primary description__title">
                  { t('Download CSV File') }
                </div>

                <AdminExportTransactions
                  filteredStatus={filteredStatus}
                  isRegulatorReporting={isRegulatorReporting}
                  onClose={() => {
                    setDialogDownloadOpen(false);
                  }}
                />
              </div>

              <button
                className="popup__close js-popup-close"
                onClick={() => { setDialogDownloadOpen(false);}}
              >
                <Icon
                  className={classNames('close', 'icon-24')}
                  name="close"
                  size="24"
                />
              </button>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={isDialogHintGiverOpen}
          onClose={() => {
            setDialogHintGiverOpen(false);
          }}
        >
          <div className="popup js-popup animation visible">
            <div onClick={() => { setDialogHintGiverOpen(false);}} className="popup__overlay js-popup-overlay">{' '}</div>

            <div className="popup__wrap js-popup-wrap">
              <div className="description">
                <div className="title-primary description__title">
                  { t('Hint giver token amount') }
                </div>

                <div className="settings__item">
                  <div className="settings__fieldset">
                    <InputTheme
                      classWrapper="field"
                      classLabel="field__label"
                      classInput="field__input"
                      propertyName="amount"
                      label={t('Token Amount')}
                      state={hintGiverAmount}
                      setState={setHintGiverAmount}
                      loading={createInterestPaymentTransactionData && createInterestPaymentTransactionData.loading}
                    />

                    <div className="field">
                      <div className="field__wrap">
                        <button className="button" type="button" onClick={onCreateHintGiverTransaction}>
                          { t('Submit') }
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                className="popup__close js-popup-close"
                onClick={() => { setDialogHintGiverOpen(false);}}
              >
                <Icon
                  className={classNames('close', 'icon-24')}
                  name="close"
                  size="24"
                />
              </button>
            </div>
          </div>
        </Dialog>

        <div className="settings__list w-full">
          <div className="settings__item">
            <div className="w-full">
              <div className="create__group">
                {hasUserEnoughRights(
                  me.rights,
                  USER_RIGHT_TYPES.COMPLIANCE_OFFICER
                ) && isAdmin && me && me.rights !== USER_RIGHT_TYPES.ACCOUNTING && (
                  <>
                    <div className="field">
                      <div className="field__label">
                        {t('Downloads')}
                      </div>

                      <div className="field__wrap">
                        <button
                          className="button-stroke w-full"
                          type="button"
                          onClick={() => {
                            setRegulatorReporting(false);
                            setDialogDownloadOpen(true);
                          }}
                        >
                          {t('Download as CSV')}
                        </button>
                      </div>
                    </div>

                    <div className="field">
                      <div className="field__wrap">
                        <button
                          className="button-stroke w-full"
                          type="button"
                          onClick={() => {
                            setRegulatorReporting(true);
                            setDialogDownloadOpen(true);
                          }}
                        >
                          {t('Regulator Reporting Transactions CSV')}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <br/>

        <div className="products__head">
          <div className="title-primary products__title">
            { t('Transactions') }
          </div>

          <form className="form">
            <input
              type="search"
              className="form__input"
              placeholder={t('Type in search')}
              value={reqOptions.search}
              onChange={onChangeReqOptions("search")}
            />
            <button className="form__button">
              <Icon
                className={classNames('searchh')}
                name='searchh'
                size="24"
              />{" "}
            </button>
          </form>

          <div className="products__nav">
            {
              publicSettings &&
              hasUserEnoughRights(
                me.rights,
                USER_RIGHT_TYPES.COMPLIANCE_OFFICER
              ) && isAdmin && me && me.rights !== USER_RIGHT_TYPES.ACCOUNTING && (
                <>
                  {createInterestPaymentTransactionData && createInterestPaymentTransactionData.loading && (<CircularProgress />)}
                  <button
                    onClick={() => setDialogHintGiverOpen(true)}
                    className={`button-small mr__8 ${selectedTransactionIds.length === 0 || (createInterestPaymentTransactionData && createInterestPaymentTransactionData.loading) ? 'button-stroke disabled' : ''}`}
                    disabled={
                      selectedTransactionIds.length === 0 ||
                      (createInterestPaymentTransactionData && createInterestPaymentTransactionData.loading)
                    }
                  >
                    { t('Create hint giver transaction') }
                  </button>
                </>
              )
            }

            {
              publicSettings &&
              publicSettings.enable_smart_contract &&
              publicSettings.token_type === TOKEN_TYPES.BOND &&
              hasUserEnoughRights(
                me.rights,
                USER_RIGHT_TYPES.COMPLIANCE_OFFICER
              ) && isAdmin && me && me.rights !== USER_RIGHT_TYPES.ACCOUNTING && (
                <>
                  {createInterestPaymentTransactionData && createInterestPaymentTransactionData.loading && (<CircularProgress />)}
                  <button
                    onClick={onCreateInterestPaymentTransaction}
                    className={`button-small mr__8 ${selectedTransactionIds.length === 0 || (createInterestPaymentTransactionData && createInterestPaymentTransactionData.loading) ? 'button-stroke disabled' : ''}`}
                    disabled={
                      transactionList.objects.filter(transaction => (
                        transaction.type !== TRANSACTIONS_TYPES.INTEREST_PAYMENT &&
                        selectedTransactionIds.includes(transaction.id)
                      )).length === 0 ||
                      (createInterestPaymentTransactionData && createInterestPaymentTransactionData.loading)
                    }
                  >
                    { t('Create interest payment transaction') }
                  </button>
                </>
              )
            }

            {
              publicSettings &&
              publicSettings.token_type === TOKEN_TYPES.EQUITY &&
              // (publicSettings.enable_smart_contract || publicSettings.enable_cashlink) &&
              hasUserEnoughRights(
                me.rights,
                USER_RIGHT_TYPES.COMPLIANCE_OFFICER
              ) && isAdmin && me && me.rights !== USER_RIGHT_TYPES.ACCOUNTING && (
                <>
                  {markCapitalIncreaseData && markCapitalIncreaseData.loading && (<CircularProgress />)}
                  <button
                    onClick={onMarkCapitalIncrease}
                    className={`button-small mr__8 ${selectedTransactionIds.length === 0 || (markCapitalIncreaseData && markCapitalIncreaseData.loading) ? 'button-stroke disabled' : ''}`}
                    disabled={
                      transactionList.objects.filter(transaction => (
                        !transaction.capitalIncrease &&
                        selectedTransactionIds.includes(transaction.id)
                      )).length === 0 ||
                      (markCapitalIncreaseData && markCapitalIncreaseData.loading)
                    }
                  >
                    { t('Mark as Capital Increase') }
                  </button>
                </>
              )
            }

            {
              publicSettings &&
              publicSettings.enable_smart_contract &&
              hasUserEnoughRights(
                me.rights,
                USER_RIGHT_TYPES.COMPLIANCE_OFFICER
              ) && isAdmin && me && me.rights !== USER_RIGHT_TYPES.ACCOUNTING && (
                <>
                  {transferingToken && (<CircularProgress />)}
                  <button
                    onClick={() => {
                      setTransferingToken(true)
                      allocateTokenForMultipleTransaction().finally(() => {
                        setTransferingToken(false)
                      })
                    }}
                    className={`button-small mr__8 ${selectedTransactionIds.length === 0 || transferingToken ? 'button-stroke disabled' : ''}`}
                    disabled={
                      transactionList.objects.filter(transaction => (
                        !transaction.allocate_tx_hash && !(
                          transaction.payment_type === PAYMENT_TYPES.CRYPTO &&
                          ['eth', 'dai'].includes(transaction.currency)) &&
                        selectedTransactionIds.includes(transaction.id)
                      )).length === 0 ||
                      (
                        publicSettings.token_type === TOKEN_TYPES.EQUITY &&
                        transactionList.objects.filter(transaction => (
                          transaction.capitalIncrease &&
                          selectedTransactionIds.includes(transaction.id)
                        )).length === 0
                      ) ||
                      transferingToken
                    }
                  >
                    {
                      transferingToken ? 'Please confirm and wait' : t('Allocate token')
                    }
                  </button>
                </>
              )
            }

            {renderSettingsBtn()}
          </div>
        </div>

        <div className="transactions__wrapper overflow-x__auto">
          <div className="transactions__table kyc__table_transactions">
            <TableRowTheme typeRow="transactions__row">

              {isShowMultipleSelect && (
                <TableColumnTheme typeColumn="transactions__col" isFirstColumn>
                  <label
                    className="checkbox"
                    title="Select all"
                  >
                    <input
                      type="checkbox"
                      className="checkbox__input"
                      checked={isSelectAll}
                      onChange={onClickIsSelectAll}
                    />
                    <div className="checkbox__inner">
                      <div className="checkbox__tick">{' '}</div>
                    </div>
                  </label>
                </TableColumnTheme>
              )}

              <TableColumnTheme typeColumn="transactions__col" isFirstColumn={!isShowMultipleSelect}>
                { t('Tranx No') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col" isFirstColumn={!isShowMultipleSelect}>
                { t('Payment Reference') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                { t('Approved Tokens') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                { t('Tokens') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                { t('Amount') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                { t('Discount') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                { t('Wallet') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                { t('From') }
              </TableColumnTheme>

              {(publicSettings || {}).show_source_signup && (
                <TableColumnTheme typeColumn="transactions__col">
                  { t('Source') }
                </TableColumnTheme>
              )}

              {publicSettings.token_type === TOKEN_TYPES.BOND && (
                <TableColumnTheme typeColumn="transactions__col">
                  { t('Interest Issued At') }
                </TableColumnTheme>
              )}

              {publicSettings.token_type === TOKEN_TYPES.EQUITY && (
                <TableColumnTheme typeColumn="transactions__col">
                  { t('Capital Increase') }
                </TableColumnTheme>
              )}

              <TableColumnTheme typeColumn="transactions__col">
                { t('Token Off Chain') }
              </TableColumnTheme>

              {isAdmin && (<TableColumnTheme typeColumn="transactions__col">
                { t('Token On Chain') }
              </TableColumnTheme>)}

              <TableColumnTheme typeColumn="transactions__col">
                {' '}
              </TableColumnTheme>
            </TableRowTheme>

            {transactionListGroup.map(group => {
              const user = group.user;
              const onChain = onChainTokensList.find(token => token.address === (user.wallet_address || user.eth_receiving_wallet))
              const onChainForOldAddress = onChainTokensList.find(token => token.address === user.old_wallet_address)
              const totalOnChainAmount = +((onChain || {}).onChainAmount || 0) + +((onChainForOldAddress || {}).onChainAmount || 0)

              return (
                <>
                  { group.objects.length > 1 && (
                    <TableRowTheme typeRow="transactions__row" key={user.id} propertyName={user.id}>

                      {isShowMultipleSelect && (
                        <div className="transactions__col"/>
                      )}

                      <div className="transactions__col"/>

                      <div className="transactions__col"/>

                      <div className="transactions__col vert__middle"/>

                      <div className="transactions__col vert__middle"/>

                      <div className="transactions__col vert__middle"/>

                      <div className="transactions__col vert__middle"/>

                      <div className="transactions__col vert__middle">
                        <div className="transactions__label">{ t('Wallet') }</div>

                        <div className="shorten__text">
                          {user.wallet_address
                            ? `${user.wallet_address || ""} ${(
                              user.wallet_type || ""
                            ).toUpperCase()}`
                            : `${user.eth_receiving_wallet} ETH`
                          }
                        </div>

                        {onChainForOldAddress && onChainForOldAddress.onChainAmount > 0 && (
                          <div className="shorten__text">
                            {`${user.old_wallet_address || ""} ${(user.wallet_type || "").toUpperCase()}`}
                          </div>
                        )}

                      </div>

                      <div className="transactions__col vert__middle">
                        <div className="transactions__label">{ t('From') }</div>

                        <div className="transactions__content_wrapper">
                          <div className="transactions__product">
                            {user.email}
                          </div>
                        </div>
                      </div>

                      {(publicSettings || {}).show_source_signup && (
                        <div className="transactions__col vert__middle">
                          <div className="transactions__label">{t('Source')}</div>
                          {user.is_gtoswiss
                            ? "GTOwiss.com"
                            : user.is_internal_sales
                            ? "InternalSales"
                            : user.referral_code || "Organic"}
                        </div>
                      )}

                      {publicSettings.token_type === TOKEN_TYPES.BOND && (
                        <div className="transactions__col vert__middle"/>
                      )}

                      {publicSettings.token_type === TOKEN_TYPES.EQUITY && (
                        <div className="transactions__col vert__middle"/>
                      )}

                      <div className="transactions__col vert__middle text-center"/>

                      {isAdmin && (<div className="transactions__col vert__middle text-center">
                        <div className="transactions__label">{t('Token On Chain')}</div>
                        <p className="text-center">
                          { `${totalOnChainAmount} ${publicSettings && publicSettings.token_symbol}` }
                        </p>
                      </div>)}

                      <div className="transactions__col transactions__col-action vert__middle"/>
                    </TableRowTheme>
                  )}

                  { group.objects.map(transaction => {
                    return (
                      <TableRowTheme typeRow="transactions__row" key={transaction.id} propertyName={transaction.id}>

                        {isShowMultipleSelect && (
                          <div className="transactions__col">
                            <label
                              className="checkbox"
                              title={transaction.allocate_tx_hash
                                ? 'Token has been allocated'
                                : (
                                  transaction.payment_type === PAYMENT_TYPES.CRYPTO && ['eth', 'dai'].includes(transaction.currency)
                                    ? 'Token can be automatically allocated when transfer completed'
                                    : undefined
                                )}
                            >
                              <input
                                type="checkbox"
                                className="checkbox__input"
                                checked={transaction.type === TRANSACTIONS_TYPES.REDEMPTION ? false : selectedTransactionIds.includes(transaction.id)}
                                onChange={toggleMultipleSelectTransaction(transaction.id)}
                                value={transaction.id}
                                disabled={transaction.type === TRANSACTIONS_TYPES.REDEMPTION ? true : !isTransactionCheckable(transaction)}
                              />
                              <div className="checkbox__inner">
                                <div className="checkbox__tick">{' '}</div>
                              </div>
                            </label>
                          </div>
                        )}

                        <div className="transactions__col">
                          <div className="transactions__label">{ t('Tranx No') }</div>
                          {renderTxNum(transaction)}
                        </div>

                        <div className="transactions__col">
                          <div className="transactions__label">{ t('Payment Reference') }</div>
                          {transaction.payment_ref && formatPaymentRef(transaction)}
                        </div>

                        <div className="transactions__col vert__middle">
                          <div className="transactions__label">{ t('Approved Tokens') }</div>
                          {transaction.status === 'APPROVED' ? +transaction.approved_token || +transaction.token_amount : '' }
                          {' '}
                          {transaction.status === 'APPROVED' && publicSettings && publicSettings.token_symbol}
                        </div>

                        <div className="transactions__col vert__middle">
                          <div className="transactions__label">{ t('Tokens') }</div>
                          {+transaction.token_amount}
                          {' '}
                          {publicSettings && publicSettings.token_symbol}
                        </div>

                        <div className="transactions__col vert__middle">
                          <div className="transactions__label">{ t('Amount') }</div>
                          <div className="transactions__content_wrapper">
                            {(+transaction.currency_amount).toFixed(2)}
                            {' '}
                            {transaction.currency}
                            <br />
                            ({transaction.payment_type})
                          </div>
                        </div>

                        <div className="transactions__col vert__middle">
                          <div className="transactions__label">
                            { t('Discount') }
                          </div>
                          {transaction.discount_percent ? `${transaction.discount_percent}%` : ''}
                        </div>

                        <div className="transactions__col vert__middle">
                          <div className="transactions__label">{ t('Wallet') }</div>

                          <div className="shorten__text">
                            {transaction.user.wallet_address
                              ? `${transaction.user.wallet_address || ""} ${(
                                transaction.user.wallet_type || ""
                              ).toUpperCase()}`
                              : `${transaction.user.eth_receiving_wallet} ETH`
                            }
                          </div>

                          {group.objects.length === 1 && onChainForOldAddress && onChainForOldAddress.onChainAmount > 0 && (
                            <div className="shorten__text">
                              {`${user.old_wallet_address || ""} ${(user.wallet_type || "").toUpperCase()}`}
                            </div>
                          )}
                        </div>

                        <div className="transactions__col vert__middle">
                          <div className="transactions__label">{ t('From') }</div>

                          <div className="transactions__content_wrapper">
                            <div className="transactions__product">
                              {transaction.user.email}
                            </div>

                            <div className="transactions__invoice vert__middle">
                              {formatDate(transaction.created_at)}
                            </div>
                          </div>
                        </div>

                        {(publicSettings || {}).show_source_signup && (
                          <div className="transactions__col vert__middle">
                            <div className="transactions__label">{t('Source')}</div>
                            {transaction.user.is_gtoswiss
                              ? "GTOwiss.com"
                              : transaction.user.is_internal_sales
                              ? "InternalSales"
                              : transaction.user.referral_code || "Organic"}
                          </div>
                        )}

                        {publicSettings.token_type === TOKEN_TYPES.BOND && (
                          <div className="transactions__col vert__middle">
                            <div className="transactions__label">{t('Interest Issued At')}</div>
                            {
                              transaction.interestTransactions.length ?
                                moment(transaction.interestTransactions[0].issued_date).format('YYYY-MM-DD') :
                                ''
                            }
                          </div>
                        )}

                        {publicSettings.token_type === TOKEN_TYPES.EQUITY && (
                          <div className="transactions__col vert__middle">
                            <div className="transactions__label">{t('Capital Increase')}</div>
                            {transaction.capitalIncrease ? 'TRUE' : 'FALSE'}
                            {transaction.capitalIncrease && (
                              <p>
                                {
                                  transaction.capitalIncrease.commercial_register_url ?
                                  <a href={transaction.capitalIncrease.commercial_register_url} target="_blank" rel="noopener noreferrer">
                                    {moment(transaction.capitalIncrease.date).format('YYYY-MM-DD')}
                                  </a> :
                                  moment(transaction.capitalIncrease.date).format('YYYY-MM-DD')
                                }
                              </p>
                            )}
                          </div>
                        )}

                        <div className="transactions__col vert__middle text-center">
                          <div className="transactions__label">{t('Token Off Chain')}</div>
                          {renderTextStatus(transaction.status)}
                          <p className="text-center">
                            {+transaction.approved_token || +transaction.token_amount}{' '}
                            {publicSettings && publicSettings.token_symbol}
                          </p>
                        </div>

                        {isAdmin && (<div className="transactions__col vert__middle text-center">
                          <div className="transactions__label">{t('Token On Chain')}</div>
                          {renderAllocateTokenStatus(transaction)}
                          { group.objects.length === 1 && (
                            <p className="text-center">
                              { `${totalOnChainAmount} ${publicSettings && publicSettings.token_symbol}` }
                            </p>
                          )}

                        </div>)}

                        <div className="transactions__col transactions__col-action vert__middle">
                          <TransactionListActions
                            isAdmin={isAdmin}
                            transaction={transaction}
                            loading={loading}
                            onChangeTransactionStatus={onChangeTransactionStatus}
                            onCancelTransaction={onCancelTransaction}
                            refetch={refetch}
                            onAllocateToken={allocateTokenForOneTransaction(transaction)}
                          />
                        </div>

                      </TableRowTheme>
                    )
                  })}
                </>
              )
            })}

            {emptyRows > 0 && (
              <div className="transactions__row">
                <div className="transactions__col transactions__col-full">
                  {' '}
                </div>
              </div>
            )}
          </div>
        </div>
        <TablePaginationActions
          count={transactionList.meta.total}
          page={page}
          rowsPerPage={pageSize}
          onChangePage={onChangePage}
          onChangeRows={onChangePageSize}
          rowsPerPageOptions={[1, 5, 10, 25, 100]}
        />
      </>
    );
  }

  return (
    <div className={classes.circularProgressWrapper}>
      <CircularProgress />
    </div>
  );
};

TransactionList.propTypes = {};

export default TransactionList;
