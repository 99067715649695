import React from "react";

// Components
import SearchBar from "../SearchBar";
import UserBar from "../UserBar";
import NotificationBar from "../NotificationBar";
import AdditionalBar from "../AdditionalBar";
import MessageBar from "../MessageBar";

const AppHeader = ({
  // from HOCs
  location,
}) => {
  return (
    <header className="header">
      <button className="header__burger">{' '}</button>
      <SearchBar />

      <div className="header__control">
        <AdditionalBar />

        <MessageBar />

        <NotificationBar />

        <UserBar />
      </div>
    </header>
  )
}

export default AppHeader;
