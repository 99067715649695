import React from 'react'
import { usePublicSettings } from '../myHooks/useSettings'
import { Redirect } from 'react-router'
import { useTranslation } from 'react-i18next'
import SidebarOuter from '../components/SidebarOuter'
import { isIE } from "react-device-detect"
import NotSupportViewer from '../components/NotSupportViewer'
import '../styles/app.min.css'
import '../styles/style.css'

const TanganyTerms = () => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { t } = useTranslation();

  if (publicSettings.token_type === "UTILITY") {
    return <Redirect to="/" />
  }

  if (isIE) {
    return <NotSupportViewer />;
  }

  return (
    <div className="page pt-0">
      <SidebarOuter />

      <div className="page__inner">
        <div className="page__container">
          <div className="product__wrapper product__wrapper_v2">
            <div className="product__details">
              <div className="product__overview">
                <div className="product__title h4">
                  {t('General Terms and Conditions (“GTC”) for Custody of Crypto Assets')}
                </div>

                <div className="product__row">
                  <div className="product__col pr__0">
                    <div className="product__content">
                    <>
                      <p>Status December 2021</p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>A) General Information</strong>
                      </p>
                      <p>Tangany GmbH, Agnes-Pockels-Bogen 1</p>
                      <p>
                        80992 Munich, Germany,{" "}
                        <a href="mailto:info@tangany.com,">info@tangany.com,</a> ("Tangany" or
                        "Crypto Custodian") offers its Users a Wallet for the custody of crypto
                        assets in the sense of Section 1 paragraph 1a sentence 2 number 6 German
                        Banking Act ("KWG"). Tangany is a crypto custodian supervised by the Federal
                        Financial Supervisory Authority ("BaFin") and has a licence for
                        crypto-custody business in accordance with Section 1 (1a) sentence 2 no. 6
                        KWG. Platform providers and issuers of crypto assets, where Tangany Users
                        make use of various separate services (such as trading in crypto assets),
                        use the Tangany Wallet. Such platform providers and issuers do not belong to
                        Tangany. The relationship between Tangany and its Users as well as the users
                        and the platform providers or issuers must be strictly distinguished.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>B) Scope of this GTC</strong>
                      </p>
                      <p>
                        The GTCs exclusively regulate the contractual relationship between Tangany
                        and its Users.
                      </p>
                      <p>
                        Tangany is the provider of the Wallet, crypto custodian and contractual
                        partner to its Users.
                      </p>
                      <p>These GTCs are the sole contractual basis for the use of the Wallet.</p>
                      <p>
                        These GTCs do not regulate the use of any platforms or services on the
                        platform, such as the subscription of offers of crypto assets on any
                        platforms. Such services are provided by the respective platform operator or
                        issuer and have no connection with Tangany. Registration on such a platform
                        is exclusively based on separate terms and conditions of the platform
                        operator or issuer. The platform operator may have access to the customer
                        account and the Wallet under certain circumstances. Tangany is never liable
                        for the platform operator or other third parties. A user is not obliged to
                        use Tangany's Wallet for the crypto assets acquired by him on the platform.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>C) Contracting and Opening of Account</strong>
                      </p>
                      <p>
                        The contract is concluded and an account opened for the use of the Wallet
                        after registration of the User by Tangany.
                      </p>
                      <p>
                        The registration is carried out by instructions from Tangany and is
                        necessary for the opening of a customer account.
                      </p>
                      <p>
                        After registration the User receives his customer account. The customer
                        account enables the Uer to access the Wallet and the services which Tangany
                        offers with the Wallet.
                      </p>
                      <p>
                        After successful registration Tangany provides the User with access to his
                        customer account and thus to the services of the Wallet.
                      </p>
                      <p>
                        The opening of a customer account can be refused by Tangany if there is no
                        successful registration.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>D) Registration of the User</strong>
                      </p>
                      <p>
                        As a crypto custodian Tangany has to identify its Users and is obliged to
                        verify their identities. This is done within the scope of the registration
                        of the users.
                      </p>
                      <p>
                        Within the scope of the registration Tangany requires an official
                        identification document from the user, which contains a photograph and which
                        fulfils the passport and identification requirements in Germany (e.g. an
                        official identity card, German driving licence or a passport). If the user
                        is a company, further documents (e.g. extract from the commercial register)
                        are required for registration.
                      </p>
                      <p>
                        Registration of the user already takes place by communicating his personal
                        information ("identity"), which he provides on the platform of the third
                        party. The user's identity is then verified ("identity verification").
                        Identity verification is carried out by means of a video identification
                        procedure, for which Tangany uses a service provider.
                      </p>
                      <p>
                        The user is contacted by the service provider and receives further
                        instructions for the successful completion of the video identification
                        procedure. The user will be informed by the service provider immediately
                        after successful completion of the identification.
                      </p>
                      <p>
                        The User must inform Tangany for the duration of the business relationship
                        immediately of any change of name, address and e-mail address. This is done
                        via the platform of the third party.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>E) Using the Wallet</strong>
                      </p>
                      <p>
                        After successful registration the User has access to the wallet and can use
                        it. Tangany establishes access to the Wallet for the User. Access is via the
                        customer account by using the customer data (username and password). With
                        access to the wallet, every User gets an overview of his/her crypto assets
                        stored by Tangany.
                      </p>
                      <p>
                        Tangany's wallet only displays crypto assets which the user has acquired via
                        third party platforms and which Tangany holds in custody for the User. Other
                        crypto assets of the user which Tangany does not keep are not in the Tangany
                        wallet and are not displayed to the user via the customer account.
                      </p>
                      <p>
                        Further functions of the wallet (exemplary list; these depend on the
                        platform operator or third party):
                      </p>
                      <ul>
                        <li>The User can view the public address (public key) of his/her wallet</li>
                        <li>
                          the User can view past transactions, including the addresses of the sender
                          and recipient of the transaction, time stamp and transaction ID
                        </li>
                        <li>
                          depending on the wallet, the User can initiate transactions (this is a
                          function on the Blockchain and not a service of Tangany)
                        </li>
                      </ul>
                      <p>Tangany only provides crypto custody services to its Users.</p>
                      <p>&nbsp;</p>
                      <p>
                        Tangany is responsible for ensuring that the information provided to the
                        user is correctly reproduced as it is visible on the blockchain. However,
                        Tangany is not responsible for the correctness or up-to-dateness of the
                        entries on the blockchain, as these are outside Tangany's
                      </p>
                      <p>
                        sphere of influence. Tangany does not provide any investment advice and is
                        not involved in the trading of crypto assets.
                      </p>
                      <p>
                        Insofar as legally necessary (for important reasons, in particular for
                        supervisory and regulatory reasons) Tangany is entitled to suspend its
                        services in whole or in part.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>F) Costs and fees für Using the Wallet</strong>
                      </p>
                      <p>
                        The opening and use of the wallet is basically free of charge for the User.
                      </p>
                      <p>
                        The platform operator pays a separate fee to Tangany, for which the User of
                        Tangany is not further charged.
                      </p>
                      <p>
                        The User must pay any costs for using the Internet directly to his Internet
                        service provider. The same applies to any costs for the transmission of
                        crypto assets within the blockchain ("network fee"), which the User or the
                        platform operator must pay to the network provider.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>G) Rules for Using the Wallet</strong>
                      </p>
                      <p>
                        The following rules apply for successful account opening and further use:
                      </p>
                      <ul>
                        <li>The User must be at least 18 years old</li>
                        <li>The User has its permanent residence in Germany</li>
                        <li>
                          The user must act in his/her own name and may not open or maintain an
                          account for other persons
                        </li>
                        <li>The User must use his/her own e-mail address</li>
                        <li>The User must accept the GTC in the currently valid form</li>
                        <li>
                          The User must complete the registration and have updates made if necessary
                          (if changes occur in his person, in the legal situation or from external
                          circumstances)
                        </li>
                        <li>
                          The use of the wallet must not violate German and/or international law
                          (which are directly applicable to Tangany or the user)
                        </li>
                        <li>
                          The User may not program applications that interact with the Wallet unless
                          Tangany has given its explicit written consent
                        </li>
                        <li>
                          The User may not use spider, robot or crawling programs or other technical
                          and/or automated solutions to access or extract data from the Wallet
                        </li>
                      </ul>
                      <p>
                        Tangany or third parties do not have permissionless access to the crypto
                        assets of the Users.
                      </p>
                      <p>
                        The wallet may only be used by the User itself. The User shall keep her/his
                        access data secret and safe and not grant access to any third party. If the
                        User learns that someone has unauthorized access to his wallet he/she must
                        inform Tangany immediately. The User must log out of his user account after
                        each use in order to prevent access by third parties.
                      </p>
                      <p>
                        The User is responsible for the proper taxation of her/his activities with
                        crypto assets. Tangany will not become active here.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>H) Availability of the Wallet</strong>
                      </p>
                      <p>Tangany operates the wallet with the greatest possible availability.</p>
                      <p>
                        However, there is no guaranteed availability of the Wallet. For example, the
                        wallet may not be
                        <br />
                        available due to maintenance, further development or other events beyond
                        Tangany's control, e.g. the interruption of power and/or data connections or
                        a restriction in the use of the platform.
                      </p>
                      <p>
                        Tangany reserves the right to discontinue, restrict or change the wallet or
                        individual components of the wallet temporarily - for example for technical
                        or legal reasons. Tangany will inform the User and the respective platform
                        operator in advance of any forthcoming changes.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>I) Account Structure and Insolvency</strong>
                      </p>
                      <p>
                        The platform provider may choose between two types of wallets for the
                        custody of crypto assets: wallets managed in accordance with the single user
                        account segregation ("Single User Accounts") and the omnibus user account
                        segregation ("Omnibus User Accounts"). The Single User Account is used to
                        hold the crypto assets of a single User. Consequently, the User's crypto
                        assets are kept separate from the crypto assets of the other Users and of
                        Tangany's own holdings. The Omnibus User Account is used for joint custody
                        of multiple Users' crypto assets. However, Tangany does not hold its own
                        assets in Omnibus User Accounts.
                      </p>
                      <p>
                        <br />
                        Subject to applicable local insolvency law, Users' legal title to crypto
                        assets held directly in wallets by Tangany on behalf of Users should not be
                        affected by an insolvency of Tangany, regardless of whether the crypto
                        assets are held in Single User Accounts or Omnibus User Accounts. In
                        practice, the allocation of crypto assets in the event of insolvency depends
                        on a number of factors.
                      </p>
                      <p>
                        Tangany holds the crypto assets in custody on behalf of the Users, who are
                        entitled to ownership of these crypto assets by law. This applies both in
                        the case of Single User Accounts and Omnibus User Accounts. However, User
                        claims differ depending on the custody on Single User Accounts and Omnibus
                        User Accounts. In the case of a Single-User Account, each User has a claim
                        to all crypto assets held in the Single User Account. Because crypto assets
                        of different Users are held in common in a single account, in the case of an
                        Omnibus User Account, each User is generally&nbsp; deemed to have a claim to
                        all crypto assets held in the Omnibus User Account pro
                        <br />
                        rata to its crypto asset holdings. Subject to applicable local law,
                        Tangany's records on the Blockchain will serve as evidence of Tangany's
                        Users' entitlement to the crypto assets. The reliability of this evidence
                        would be particularly important in the event of insolvency. Tangany
                        undertakes to distinguish crypto assets held in custody for one User from
                        crypto assets held in custody for another User and its own crypto assets.
                      </p>
                      <p>
                        <br />
                        In the event of insolvency, the User can separate his/her crypto assets from
                        the custody and have them transferred to one of his/her other wallets for a
                        fee. This applies both to custody on Single User Accounts and to custody on
                        Omnibus User Accounts.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>K) Limitation of Liability</strong>
                      </p>
                      <p>
                        Tangany has unlimited liability for intent and gross negligence and for any
                        culpable injury to life, body or health.
                      </p>
                      <p>
                        Otherwise, Tangany is not liable for lack of economic success, loss of
                        profit and indirect damages in the event of a slightly negligent breach of
                        its essential contractual obligations. An essential contractual obligation
                        in this sense is an obligation, the fulfilment of which makes the
                        implementation of the contract possible in the first place and on the
                        fulfilment of which the user may therefore regularly rely.
                      </p>
                      <p>
                        The liability for slightly negligent breaches of essential contractual
                        obligations is limited to the typical and foreseeable damage at the time of
                        conclusion of the contract.
                      </p>
                      <p>
                        The liability of Tangany in case of data loss is limited to the costs that
                        would have been incurred even if the user had carried out a proper data
                        backup to restore the data. This limitation does not apply to data
                        protection violations against the basic data protection regulation.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>J) Indemnification and Compensation</strong>
                      </p>
                      <p>
                        The User indemnifies Tangany from any claims of third parties to which
                        Tangany is exposed due to a breach of contract or unauthorised action by the
                        User, even if these are based on simple negligence.
                      </p>
                      <p>
                        The User must compensate Tangany for any damages that Tangany incurs due to
                        a breach of contract or unauthorised action by the User, even if these are
                        based on simple negligence.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>K) Duration and Termination</strong>
                      </p>
                      <p>
                        The use of the wallet and the services of Tangany are granted to the user
                        for an indefinite period.
                      </p>
                      <p>
                        The User and Tangany can terminate the contractual relationship with the
                        other contractual party at any time with a notice period of three (3)
                        months. The termination must be made in text form (for example by e-mail or
                        letter).
                      </p>
                      <p>
                        Tangany can terminate the contract immediately within 14 days if there is an
                        important reason.
                      </p>
                      <p>Important reasons are in particular:</p>
                      <ul>
                        <li>
                          ongoing operational disruptions due to force majeure that are beyond
                          Tangany's control (such as natural disasters, fire or power failures for
                          which Tangany is not responsible)
                        </li>
                        <li>
                          repeated, serious breaches of the User's obligations (see "G. Rules for
                          Using the Wallet")
                        </li>
                        <li>
                          once a platform operator or third party (see “B. Scope of this GTC”)
                          terminates the relationship with Tangy and Tangany is not able to provide
                          its services to client
                        </li>
                      </ul>
                      <p>&nbsp;</p>
                      <p>
                        The contractual relationship with Tangany ends automatically if the
                        contractual relationship between the User and the platform operator or third
                        parties (see "B. Scope of these GTC") ends. The User must inform Tangany of
                        this immediately. Tangany will notify the User of the end of the contractual
                        relationship if possible.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>
                          L) Deletion of Data and of the Private Key after Termination
                        </strong>
                      </p>
                      <p>
                        After termination of the contractual relationship, Tangany will delete
                        certain data and information in accordance with legal requirements:
                      </p>
                      <ul>
                        <li>
                          Tangany deletes all data and information of the User as far as this is
                          legally permitted and technically possible (certain data on the block
                          chain cannot be changed technically)
                        </li>
                        <li>
                          The wallet is in the process of being deleted for 3 months after deletion.
                          <br />
                          Tangany can access it in urgent cases (e.g. if the user has accidentally
                          received a transaction). After 3 months, after termination of the
                          contract, no further access is possible.
                        </li>
                        <li>
                          Tangany deletes (destroys) the private key, which belongs to the wallet
                          (the public key, on the other hand, is not deleted/destroyed because it is
                          on the blockchain and therefore unchangeable)
                        </li>
                      </ul>
                      <p>
                        Once the private key has been deleted/destroyed, nobody can access the
                        wallet. If the User still has crypto assets in the wallet at the time of
                        termination, he must transfer these to another wallet before the private key
                        is deleted/destroyed. In the case of ordinary termination, the user has the
                        agreed notice period and 14 days if there is an important reason to transfer
                        all assets to a new private key.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>M) No Storage of these GTC</strong>
                      </p>
                      <p>
                        These GTC are not saved by Tangany. Every user can download the current
                        version of these GTC as a PDF file and
                      </p>
                      <p>
                        The contractual relations between the parties
                        <br />
                        shall be governed by the law of the Federal
                        <br />
                        Republic of Germany to the exclusion of the
                        <br />
                        uniform UN Convention on Contracts for the
                        <br />
                        International Sale of Goods (CISG).
                      </p>
                      <p>
                        <strong>N) Data Privacy and Secrecy</strong>
                      </p>
                      <p>
                        The collection, use and processing of user data takes place exclusively
                        within the framework of Tangany's privacy policy, available on its website.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>O) Amendments to the GTC</strong>
                      </p>
                      <p>
                        Amendments to these GTC shall be offered to the User in text form (e.g. by
                        e-mail or letter) no later than two months before the proposed date of their
                        entry into force.
                      </p>
                      <p>
                        The User can either accept or reject the changes before the proposed date of
                        their entry into force. The User shall be deemed to have given his consent
                        unless he has notified his rejection before the proposed date of entry into
                        force of the amendments. Tangany will specifically draw the user's attention
                        to this approval effect in its offer.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>P) Miscellaneous</strong>
                      </p>
                      <p>
                        The contractual relations between the parties shall be governed by the law
                        of the Federal Republic of Germany to the exclusion of the uniform UN
                        Convention on Contracts for the International Sale of Goods (CISG).
                      </p>
                      <p>
                        If the user does not have a general place of jurisdiction in Germany or in
                        another EU member state, or if he is a merchant or a legal entity under
                        public law, or if he moves his permanent residence abroad after these
                        General Terms and Conditions for Consumers have come into effect, or if his
                        place of residence or usual abode is unknown at the time the action is
                        filed, the exclusive place of jurisdiction for all disputes arising from
                        this contract is the registered office of Tangany.
                      </p>
                      <p>
                        The user can use the platform for online dispute resolution of the European
                        Commission,{" "}
                        <a href="http://www.ec.europa.eu/consumers/odr">
                          http://www.ec.europa.eu/consumers/odr
                        </a>{" "}
                        Tangany is not obliged and not willing to participate in a dispute
                        resolution procedure before a consumer arbitration board.
                      </p>
                      <p>
                        The German language version of these GTCs takes precedence. The non-binding
                        English translation of these GTC does not supersede the German language
                        version.
                      </p>
                      <p>
                        Should individual provisions of these GTC be invalid or unenforceable, this
                        shall not affect the legal validity of the remaining GTC.
                      </p>
                      <p>&nbsp;</p>
                    </>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TanganyTerms;
