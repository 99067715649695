import React from 'react'
import { useTranslation } from 'react-i18next'
import OnfidoReports from './OnfidoReports'

const OnfidoApplicantAllReportCheck = ({
  applicant,
  calculatedRiskAssessment,
  transactionsUserList
}) => {
  const { t } = useTranslation()

  const completedChecks = (
    applicant
    && applicant.onfidoChecks
    && applicant.onfidoChecks.sort((a, b) => a.id - b.id).filter(check => check.status === 'complete')
    ) || []

  return (
    <>
      {completedChecks.length > 0 && completedChecks.map((completedCheck) => {
        let transaction;
        if (transactionsUserList && transactionsUserList.objects.length) {
          const completedCheckDate = new Date(completedCheck.created_at);

          transaction = transactionsUserList.objects.find(transaction => {
            const transactionDate = new Date(transaction.created_at);
            return Math.abs(completedCheckDate - transactionDate) < 60*1000;
          })
        }
        return (
          <div key={completedCheck.id} className="card">
            {completedCheck && completedCheck.check_id}
            {transaction &&
              <span>
                {t('TransID')}:
                {' '}
                {`TNX${transaction.id}`}
              </span>
            }
            <div className="card__head">
              <div className="title-clear card__title">
                {t('Onfido Reports')}:
              </div>
            </div>

            <OnfidoReports
              reports={completedCheck.onfidoReports}
              calculatedRiskAssessment={calculatedRiskAssessment}
            />
          </div>
        )
      })}
    </>
  )
}

export default OnfidoApplicantAllReportCheck
