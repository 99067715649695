import React from 'react'
import { Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { CircularProgress } from '@material-ui/core'
import {
  GET_INDIVIDUAL_LIVENESS_CHECK_SERVICE_INFO,
} from '../queriesAndMutations'

const LivenessCheckPortal = () => {
  const { data: { getIndividualLivenessCheckServiceInfo } = {} } = useQuery(GET_INDIVIDUAL_LIVENESS_CHECK_SERVICE_INFO, {
    variables: {
      createNewIfNotFound: true,
    }
  })

  if (!getIndividualLivenessCheckServiceInfo) {
    return (
      <div className="text-center">
        <CircularProgress />
      </div>
    )
  }

  if (getIndividualLivenessCheckServiceInfo.serviceName === 'onfido') {
    return <Redirect to="/onfido-ident" />
  }

  if (getIndividualLivenessCheckServiceInfo.serviceName === 'idnow') {
    return <Redirect to="/idnow-ident" />
  }

  if (getIndividualLivenessCheckServiceInfo.serviceName === 'internal_liveness_check') {
    return <Redirect to={`/internal-liveness-check/${getIndividualLivenessCheckServiceInfo.livenessCheckObj.uuid}`} />
  }

  if (getIndividualLivenessCheckServiceInfo.serviceName === 'complycube') {
    return <Redirect to="/complycube-ident" />
  }

  return "";
}

export default LivenessCheckPortal
