import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import TokenAmount from './TokenAmount';
import InvestmentAmount from './InvestmentAmount';

const Amount = () => {
  const { t } = useTranslation();
  const navigation = ["Token amount", "Investment amount"];
  const [activeTab, setActiveTab] = useState(navigation[0]);

  return (
    <div className="products card js-tabs">
      <div className="products__head">
        <div className="title-primary products__title">
          {t('Amounts')}
        </div>

        <div className="products__nav">
          {navigation.map((x, index) => (
            <button
              className={`products__link js-tabs-link ${x === activeTab ? "active" : ""}`}
              key={index}
              onClick={() => setActiveTab(x)}
            >
              {x}
            </button>
          ))}
        </div>
      </div>

      <div className="products__container">
        {activeTab === navigation[0] && <TokenAmount />}

        {activeTab === navigation[1] && <InvestmentAmount />}
      </div>
    </div>
  );
}

export default Amount;
