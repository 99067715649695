import React from 'react'
import { Link } from 'react-router-dom'
import { usePublicSettings } from '../../myHooks/useSettings'
import { getFileUrl } from "../../utils";

const LogoLoginRegister = () => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();

  return (
    <Link className="entry__logo entry__logo-kyc" to="/">
      <img
        src={publicSettings && getFileUrl(publicSettings.logo_path)}
        alt="logo"
        className="some-icon"
      />
    </Link>
  )
}

export default LogoLoginRegister;
