import React, { useState } from 'react'
import cn from 'classnames'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { useTranslation } from 'react-i18next'
import Icon from '../Icon'

const MessageBar = () => {
  const { t } = useTranslation();

  const [values, setValues] = useState({
    isPopupMessageActive: false,
  });

  const onPopupMessageClick = () => {
    setValues({...values, isPopupMessageActive: !values.isPopupMessageActive });
  }

  const onPopupMessageAwayClick = () => {
    setValues({ ...values, isPopupMessageActive: false });
  }
  return (
    <>
      <ClickAwayListener onClickAway={onPopupMessageAwayClick}>
        <div className={`header__item header__item_messages ${values.isPopupMessageActive ? "active" : ""}`}>
          <button
            className="header__head"
            onClick={onPopupMessageClick}
          >
            <Icon className={cn('message', 'icon-24')} name="message" size="24" />
          </button>

          <div className="header__body">
            <div className="header__top">
              <div className="header__title">{ t('Message') }</div>

              <div className="actions actions__small" style={{display: "none"}}>
                <button className="actions__button">
                  <Icon className={cn('more-horizontal', 'icon-24')} name="more-horizontal" size="24" />
                </button>
              </div>
            </div>

            <div className="header__list">{' '}</div>
            <a href="/messages" className="button header__button">
              {t('View in message center')}
            </a>
          </div>
        </div>
      </ClickAwayListener>
    </>
  );
}

export default MessageBar;
