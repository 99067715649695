import React, { useEffect } from "react"
import { useTranslation } from 'react-i18next';
import SwitchCheckboxTheme from '../SwitchCheckbox'

const CompanyStep5DisclaimerCaag = ({
  publicSettings,
  checkboxValues,
  setCheckboxValues,
  prospectusOrIssuing,
  setEnableSubmit
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    let listConfirm = [
      'pep_beneficial_owner',
      'authorizedRepresentative',
      'beneficiaOwner',
    ];

    if (publicSettings.kyb_skip_confirm_beneficial_ownership) {
      listConfirm = ['authorizedRepresentative'];
    }

    const enable = listConfirm.reduce((all, key) => all && checkboxValues[key], true);

    setEnableSubmit(enable);
    // eslint-disable-next-line
  }, [checkboxValues]);

  const changeCheckbox = (field) => ({target: {checked}}) => {
    setCheckboxValues({...checkboxValues, [field]: checked})
  }

  return (
    <>
      <div className="settings__item">
        <div className="title-primary settings__title">
          {t('Authorized Representative')}
        </div>

        <div className="settings__fieldset">
          <div className="field">
            <div className="field__wrap">
              <SwitchCheckboxTheme
                onChange={changeCheckbox('authorizedRepresentative')}
                defaultChecked={checkboxValues.authorizedRepresentative}
                value="authorizedRepresentative"
                switchText={`
                  I confirm that I am an authorized representative of the company onboarding for the token sale.
                `}
                isRemoveLine
                isGrayText
                isLeftSwitch
              />
            </div>
          </div>
        </div>
      </div>

      { !publicSettings.kyb_skip_confirm_beneficial_ownership && (
        <div className="settings__item">
          <div className="title-primary settings__title">
            {t('Beneficial Ownership')}
          </div>

          <div className="field">
            <div className="field__wrap">
              <SwitchCheckboxTheme
                onChange={changeCheckbox('beneficiaOwner')}
                defaultChecked={checkboxValues.beneficiaOwner}
                value="beneficiaOwner"
                switchText={`
                  I confirm that the funds used for the investment solely belong to the company onboarding
                  for the token sale and that the company does not act on behalf of others re.
                  No third party funds are used.
                `}
                isRemoveLine
                isGrayText
                isLeftSwitch
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CompanyStep5DisclaimerCaag
