import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import CircularProgress from '@material-ui/core/CircularProgress'
import { TOKEN_TYPES } from "../../constants/settings";
import ContributeForm from './ContributeForm'
import TokenBalanceContribute from '../../components/TokenBalanceContribute'
import WalletAddress from '../../components/profile/WalletAddress'
import { usePublicSettings } from '../../myHooks/useSettings'
import { useTranslation } from 'react-i18next'
import Icon from '../../components/Icon'

const Contribute = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data: { publicSettings = {} } = {}, loading: loadingPublicSetting } = usePublicSettings();
  const { t } = useTranslation();

  if (loadingPublicSetting) {
    return (
      <CircularProgress />
    );
  }

  if (publicSettings.token_type === TOKEN_TYPES.LIQUIDATION) {
    return (
      <p>
        {t('This feature is not support for Liquidation token. Redirect back to dashboard.')}
        {' '}
        <NavLink to="/dashboard">
          {t('Back to dashboard')}
        </NavLink>
      </p>
    );
  }

  const ButtonMailTo = ({ mailto, label}) => {
    return (
      <NavLink
        to="#"
        onClick={(e) => {
          window.location = mailto;
          e.preventDefault();
        }}>
        {label}
      </NavLink>
    );
  };

  const ButtonTelTo = ({ tel, label}) => {
    return (
      <NavLink
        to="#"
        onClick={(e) => {
          window.locatin = tel;
          e.preventDefault();
        }}>
        {label}
      </NavLink>
    );
  };

  return (
    <>
      <div className="page__title h3">{ t('Token Balance & My Contribution') }</div>

      <div className="page__row">
        <div className="page__col">
          <ContributeForm />
        </div>

        <div className="page__col">
          <TokenBalanceContribute />

          <div className="card">
            <div className="card__head card__head-kyc-1">
              <div className="title-primary card__title">
                { t('Do you need help?') }
              </div>

              <div className="refund__content card__head-content">
                { t('If you need help, please don’t hesitate to reach us out at') }:
              </div>
            </div>

            <div className="refund">
              <div className="refund__list">

                {publicSettings &&
                  publicSettings.company &&
                  publicSettings.company.email && (
                    <div className="refund__item refund__item-middle_content">
                      <div className="refund__icon">
                        <Icon
                          className={cn('mail')}
                          name='mail'
                          size="24"
                        />{" "}
                      </div>

                      <div className="refund__content refund__content-link">
                        <ButtonMailTo
                          label={publicSettings.company.email}
                          mailto={"mailto:" + publicSettings.company.email}
                        />
                      </div>
                    </div>
                  )}

                {publicSettings &&
                  publicSettings.company &&
                  publicSettings.company.phoneNumber && (
                    <div className="refund__item refund__item-middle-content">
                      <div className="refund__icon">
                        <Icon
                          className={cn('phone')}
                          name='phone'
                          size="24"
                        />{" "}
                      </div>

                      <div className="refund__content refund__content-link">
                        <ButtonTelTo
                          label={publicSettings.company.phoneNumber}
                          tel={"tel:" + publicSettings.company.phoneNumber}
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`popup js-popup popup__kyc-medium ${
          dialogOpen ? "animation visible" : ""
        }`}
      >
        <div className="popup__overlay js-popup-overlay">{' '}</div>

        <div className="popup__wrapp js-popup-wrap">
          <div className="description">
            <div className="title-primary description__title">{ t('My Wallet Address') }</div>

            <div className="description__row flex__row flex__wrap">
              <WalletAddress />
            </div>
          </div>

          <button
              className="popup__close js-popup-close"
              onClick={() => setDialogOpen(false)}
            >
              <Icon
                className={cn('close', 'icon-24')}
                name="close"
                size="24"
              />
            </button>
        </div>
      </div>
    </>
  );
};

export default Contribute;
