import React, { useState, useEffect, useCallback } from 'react'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import JoyRide from 'react-joyride'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'

import InputThemeField from '../../../components/InputThemeField'
import SwitchCheckbox from '../../../components/SwitchCheckbox'
import { useTranslation } from 'react-i18next'
import DropdownMaterial from '../../../components/DropdownMaterial'
import RadioTheme from '../../../components/Radio'
import WalletRequest from '../../../components/WalletRequest'
import { getSendingWalletTypeOptions } from '../../../constants/walletLabelTextMapping'
import { USER_KYC_STATUS_TYPES } from '../../../constants/user'
import metamaskService from '../../../services/metamask'
import Icon from "../../../components/Icon"

import { MenuItem } from '@material-ui/core'
import { useMe, usePublicSettings, useExchangeRates } from '../../../myHooks'
import { PROSPECTUS_OR_ISSUING, CRYPTO_CURRENCIES, FIAT_CURRENCIES, TOKEN_TYPES } from '../../../constants/settings'
import {
  GET_MY_TOKEN_SWAP,
  GET_MY_PERSONAL_DISCOUNT,
  CHECK_WALLET_COMPENSATION,
} from '../../../queriesAndMutations'
import {
  numberWithCommas,
  ethToFiat,
  tokenToEth,
  isCrypto,
  tokenToAnything,
  toaster,
} from '../../../utils'
import moment from 'moment'

export const walletLabelTextMapping = {
  'eth': 'ETH',
  'ixo_id': 'ixo Account DID',
  'cosmos': 'ixo Account Address',
  'btc': 'BTC',
  'other': 'Others',
  'xdc': 'XDC',
  'polygon': 'Polygon',
  'dai': 'DAI',
  'usdt': 'USDT',
  'evm_compatible_wallet': 'EVM Compatible Wallet',
}

const useStyles = makeStyles(() => ({
  swapBtn: {
    height: '24px',
    marginLeft: '10px',
  }
}));

const CardForm = ({
  values,
  setValues,
  goToNextStep,
  goToPrevStep,
  skipThisStep,
}) => {
  const { t } = useTranslation()
  const classes = useStyles();

  const {
    data: { me: userData } = {},
    loading,
  } = useMe()
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  const { data: { me } = {} } = useMe()

  const { data: { exchangeRates: baseExchangeRate } = {} } = useExchangeRates()
  const { data: { getMyTokenSwap: tokenSwap } = {} } = useQuery(GET_MY_TOKEN_SWAP)
  const [exactIntendedInvestmentAmountValue, setExactIntendedInvestmentAmountValue] = useState(values.exactIntendedInvestmentAmount)
  const { data: { getMyPersonalDiscount: myPersonalDiscount } = {} } = useQuery(GET_MY_PERSONAL_DISCOUNT)
  const [checkWalletCompensation, checkWalletCompensationStatus] = useLazyQuery(CHECK_WALLET_COMPENSATION)
  const [amountValue, setAmountValue] = useState(+values.amount)
  const [disableSubmitVerify, setDisableSubmitVerify] = useState(false)

  const currentHost = window.location.host;
  const isQdev = currentHost === 'kyc.qdev.li' || currentHost === 'qdevelopment-dev.investhub.io' || currentHost === 'localhost:3000';
  const [invertTokenForm, setInvertTokenForm] = useState({
    invert: isQdev,
    amount: 0,
  })

  const tourBoardingDetailWallet = [
    {
      target: '.easy-onboarding__amountwallet .easy-onboarding__individual-tokenamount',
      content: t('Here you can easily specify the token amount you would like to subscribe. Please note the minimum amount.'),
      disableBeacon: true,
      hideCloseButton: true,
      spotlightClicks: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
    {
      target: '.easy-onboarding__amountwallet .easy-onboarding__invidividual-preferedpaymentcurrency',
      content: t('Please choose here your currency how you want to pay your investment.'),
      disableBeacon: true,
      hideCloseButton: true,
      spotlightClicks: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
    {
      target: '.easy-onboarding__amountwallet .easy-onboarding__individual-selectwallet',
      content: t('Your tokens will be issued on the Polygon Blockchain, the Polygon Blockchain is secure, stable and the transaction costs are cheaper than on the Ethereum Blockchain for example. That\'s why Calidris tokens are issued on the Polygon Blockchain.'),
      disableBeacon: true,
      hideCloseButton: true,
      spotlightClicks: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
    {
      target: '.easy-onboarding__amountwallet .easy-onboarding__individual-receivingwallet',
      content: t('Here you can easily create your Polygon Wallet with one click, for this we use a BaFin regulated company Names Tangany.'),
      disableBeacon: true,
      hideCloseButton: true,
      spotlightClicks: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },
    {
      target: '.easy-onboarding__amountwallet .settings__button .button',
      content: t('If you have filled in all the fields and created your wallet, you can go to the next step here.'),
      disableBeacon: true,
      hideCloseButton: true,
      showSkipButton: true,
      locale: {
        back: t('Back'),
        close: t('Next'),
        skip: t('Skip'),
      }
    },

  ]

  const exchangeRates = publicSettings && publicSettings.enable_personal_discount && myPersonalDiscount
    ? myPersonalDiscount
    : baseExchangeRate

  const getBaseCurrency = () => {
    if (publicSettings && publicSettings.kyc_use_base_currency && publicSettings.base_currency) {
      return publicSettings.base_currency
    }

    return 'chf'
  }
  const baseCurrency = getBaseCurrency()
  const isShowReceivingWallet = (
    publicSettings && (
      publicSettings.enable_cashlink ||
      publicSettings.kyc_show_receiving_wallet ||
      publicSettings.kyc_require_receiving_wallet
    )
  ) || false
  const isShowSendingWallet = (
    values.currency &&
    isCrypto(values.currency)
  ) || false
  const isShowAddEtherWalletLater = (
    publicSettings &&
    publicSettings.add_ether_wallet_later_option
  ) || false
  const isShowingPreferCurrency = (
    publicSettings &&
    publicSettings.kyc_prefer_currency
  ) || false
  const isShowIntendedInvestment = (
    publicSettings &&
    publicSettings.kyc_intended_investment &&
    publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION
  ) || false
  const isShowOldTokenAmount = (
    publicSettings &&
    publicSettings.token_type !== TOKEN_TYPES.NONE &&
    publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION &&
    publicSettings.enable_swap_token &&
    tokenSwap
  ) || false
  const isShowTokenAmount = (
    publicSettings &&
    publicSettings.token_type !== TOKEN_TYPES.NONE
  ) || false
  const isShowExactInvestmentAmount = (
    publicSettings &&
    publicSettings.token_type === TOKEN_TYPES.NONE &&
    publicSettings.require_exact_investment_amount_on_none
  ) || false
  const isShowWalletType = isShowReceivingWallet || isShowSendingWallet || false

  const getIntendedInvestmentOptions = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return []
    }

    return Object.values(publicSettings.kyc_levels).filter((level) => {
      if (isQdev && level.min_invest_amount > 25000) {
        return false;
      }
      return !level.enable || level.enable.enable_lvl;
    }).map((level) => {
      if (level.enable && level.enable.enable_open_end) {
        return `> ${level.min_invest_amount} ${baseCurrency.toUpperCase()}`
      }
      return `${level.min_invest_amount} - ${level.max_invest_amount} ${baseCurrency.toUpperCase()}`
    })
  }

  const getWalletOptions = () => {
    if (!publicSettings || !publicSettings.kyc_accepted_wallets) {
      return []
    }

    return Object.keys(publicSettings.kyc_accepted_wallets).filter((wallet) => publicSettings.kyc_accepted_wallets[wallet])
  }

  const getCurrencyOptions = () => {
    if (!publicSettings) {
      return [...FIAT_CURRENCIES, ...CRYPTO_CURRENCIES]
    }

    return [...FIAT_CURRENCIES, ...CRYPTO_CURRENCIES].filter((currency) => publicSettings.accepted_currencies[currency])
  }

  const formatIntendedInvestment = (numberStr) => {
    return numberStr.replace(/\d+/g, (str) => numberWithCommas(str, 0))
  }
  const kycAcceptedWallets = useCallback(() => (
    publicSettings.kyc_accepted_wallets
      ? Object.entries(
        publicSettings.kyc_accepted_wallets
      ).filter((ele) => ele[1])
        .map((ele) => ele[0])
      : []
  ), [publicSettings])

  const setGeneratedWallet = (generatedWallet) => {
    setValues({
      ...values,
      generatedWallet,
      wallet: !values.wallet && kycAcceptedWallets().includes('eth') ? 'eth' : values.wallet,
      tokenAddress: generatedWallet,
    })
  }

  const confirmWalletAndSend = async () => {
    setDisableSubmitVerify(true);
    const message = 'Please sign this message to confirm the ownership of provided wallet '

    try {
      await metamaskService.requestAccounts()

      const signature = await metamaskService.signMessage(
        metamaskService.getCurrentAddress(),
        message
      )
      const signerAddress = await metamaskService.getSigner(signature, message)

      if (signerAddress === values.tokenAddress.toLowerCase()) {
        setValues({...values, verified_wallet: signerAddress})
        toaster.success('The wallet have been verified.')
      } else {
        toaster.error('The wallet you provided does not match with the current wallet on Metamask.')
      }

      setDisableSubmitVerify(false);
    } catch (error) {
      switch (error.code) {
        case 4001:
          toaster.error('You need to select at least the account with the provided address to connect')
          break
        default:
          toaster.error(error.message)
          break
      }
      setDisableSubmitVerify(false);
    }
  }

  const renderEquivalentValue = (amount, currency) => {
    if (!(+amount) || !exchangeRates) {
      return ''
    }

    return `=${numberWithCommas(invertTokenForm.amount)} ${currency.toUpperCase()}`
  }

  const textConfig = publicSettings &&
    publicSettings.token_type === "UTILITY"
      ? {
          issuer: t('Seller'),
          subscription: t('Token Purchase'),
          subscriber: t('purchaser'),
        }
      : {
          issuer: t('Issuer'),
          subscription: t('subscription'),
          subscriber: t('subscriber'),
        };

  const acceptedCurrencies =
    (publicSettings && publicSettings.accepted_currencies) || {};

  let prospectusOrIssuingDate, prospectusOrIssuing, paperLink;

  switch ((publicSettings || {}).prospectus_or_issuing) {
    case PROSPECTUS_OR_ISSUING.ISSUING:
      prospectusOrIssuing = "Issuing Guidelines";
      prospectusOrIssuingDate = moment(
        (publicSettings || {}).issuing_guidelines_date
      ).format("YYYY-MM-DD");
      paperLink = publicSettings.issuing_guidelines;
      break;
    case PROSPECTUS_OR_ISSUING.PROSPECTUS:
      prospectusOrIssuing = "Prospectus label";
      prospectusOrIssuingDate = moment((publicSettings || {}).prospectus_date).format(
        "YYYY-MM-DD"
      );
      paperLink = publicSettings.prospectus_url;
      break;
    case PROSPECTUS_OR_ISSUING.TOKEN_OFFERING_MEMORANDUM:
      prospectusOrIssuing = "Token Sale Agreement";
      prospectusOrIssuingDate = moment(
        (publicSettings || {}).memorandum
      ).format("YYYY-MM-DD");
      paperLink = publicSettings.memorandum_url;
      break;
    case PROSPECTUS_OR_ISSUING.SAFE:
      prospectusOrIssuing = "Token Safe";
      prospectusOrIssuingDate = moment(
        (publicSettings || {}).token_safe_date
      ).format("YYYY-MM-DD");
      paperLink = publicSettings.token_safe_url;
      break;
    default:
      prospectusOrIssuing = "Token Sale Agreement";
      prospectusOrIssuingDate = moment(
        (publicSettings || {}).token_sale_agreement_date
      ).format("YYYY-MM-DD");
      paperLink = publicSettings.token_sale_agreement_url;
      break;
  }

  const renderHelperMinimumText = () => {
    if (!exchangeRates) {
      return "";
    }

    const minimumInvestAmount =
      (publicSettings && publicSettings.minimum_investment_amount) || 15000;

    let text = 'The minimum CONFIG_SUBSCRIPTION amount per investor is BASE_CURRENCY MINIMUMINVESTAMOUNT or the equivalent in ';

    if (publicSettings && publicSettings.maximum_investment_amount) {
      text = 'The minimum CONFIG_SUBSCRIPTION amount per investor is BASE_CURRENCY MINIMUMINVESTAMOUNT and the maximum is BASE_CURRENCY MAXIMUMINVESTAMOUNT or the equivalent in ';
    }

    return <>
      {t(text, {
        CONFIG_SUBSCRIPTION: textConfig.subscription,
        BASE_CURRENCY: baseCurrency.toUpperCase(),
        MINIMUMINVESTAMOUNT: tokenToAnything(publicSettings.base_currency || 'chf', +minimumInvestAmount, exchangeRates),
        MAXIMUMINVESTAMOUNT: tokenToAnything(publicSettings.base_currency || 'chf', +publicSettings.maximum_investment_amount, exchangeRates),
        })}
      {' '}
      {[...CRYPTO_CURRENCIES, ...FIAT_CURRENCIES]
        .filter((name) => acceptedCurrencies[name])
        .map((name) => name.toUpperCase())
        .join("/")
      }
      {' '}
      {publicSettings && publicSettings.token_type !== "UTILITY"
        ? <>
            {t('according to the')}
            {' '}
            {paperLink ? <a href={paperLink} target="_blank" rel="noopener noreferrer" className="text__primary">{t(prospectusOrIssuing)}</a> : prospectusOrIssuing}
            {' '}
            {t('dated')}
            {' '}
            {prospectusOrIssuingDate}
          </>
        : t('according. All purchases are subject to the specific terms of the Token Purchase Agreement.')
      }
      <br />
    </>
  }

  useEffect(() => {
    if (isShowExactInvestmentAmount && publicSettings.token_type === TOKEN_TYPES.NONE) {
      const autoSelect = getIntendedInvestmentOptions().find(option => {
        const range = option.match(/\d+/g).map(n => +n)

        return range.length === 2
          ? range[1] >= exactIntendedInvestmentAmountValue
          : range[0] <= exactIntendedInvestmentAmountValue
      })

      if (autoSelect && autoSelect !== values.intended_investment) {
        setValues({
          ...values,
          intended_investment: autoSelect
        })
      }
    }
    // eslint-disable-next-line
  }, [exactIntendedInvestmentAmountValue])

  useEffect(() => {
    if (publicSettings && publicSettings.token_type === TOKEN_TYPES.LIQUIDATION && me) {
      checkWalletCompensation({
        variables: {
          walletAddress: me.wallet_address,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicSettings, me])

  useEffect(() => {
    if (
      publicSettings &&
      publicSettings.token_type === TOKEN_TYPES.LIQUIDATION &&
      checkWalletCompensationStatus.data &&
      checkWalletCompensationStatus.data.checkWalletCompensation
    ) {
      setValues({
        ...values,
        amount: `${checkWalletCompensationStatus.data.checkWalletCompensation.token}`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkWalletCompensationStatus])

  useEffect(() => {
    if (exchangeRates && publicSettings.token_type && publicSettings.token_type !== TOKEN_TYPES.NONE) {
      const amountToBaseCurrency = amountValue
        ? exchangeRates && ethToFiat(
          baseCurrency,
          tokenToEth(amountValue, exchangeRates),
          exchangeRates
        )
        : 0

      const autoSelect = getIntendedInvestmentOptions().find(option => {
        const range = option.match(/\d+/g).map(n => +n)

        return range.length === 2
          ? range[1] >= amountToBaseCurrency
          : range[0] <= amountToBaseCurrency
      })

      if (autoSelect && autoSelect !== values.intended_investment) {
        setValues({
          ...values,
          intended_investment: autoSelect
        })
      }

      if (invertTokenForm.amount === 0) {
        setInvertTokenForm({...invertTokenForm, amount: tokenToAnything(values.currency || baseCurrency, amountValue, exchangeRates)});
      }
    }
    // eslint-disable-next-line
  }, [amountValue, exchangeRates])

  useEffect(() => {
    if (exchangeRates && publicSettings.token_type && publicSettings.token_type !== TOKEN_TYPES.NONE && amountValue) {
      setInvertTokenForm({...invertTokenForm, amount: tokenToAnything(values.currency || baseCurrency, amountValue, exchangeRates)});
    }
    // eslint-disable-next-line
  }, [exchangeRates])

  useEffect(() => {
    if (publicSettings && publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION && publicSettings.enable_swap_token && tokenSwap) {
      setValues({
        ...values,
        amount: `${tokenSwap.newTokenAmount}`,
      })

      setAmountValue(tokenSwap.newTokenAmount)

      if (exchangeRates) {
        setInvertTokenForm({...invertTokenForm, amount: tokenToAnything(values.currency || baseCurrency, +tokenSwap.newTokenAmount, exchangeRates)});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicSettings, tokenSwap])

  if (
    !isShowReceivingWallet &&
    !isShowSendingWallet &&
    !isShowingPreferCurrency &&
    !isShowIntendedInvestment &&
    !isShowOldTokenAmount &&
    !isShowTokenAmount &&
    !isShowExactInvestmentAmount &&
    !isShowWalletType
  ) {
    skipThisStep()

    return null
  }

  return (
    <div className="card easy-onboarding__amountwallet">
      <div className="settings__wrapper">
        <div className="card__head card__head-kyc-1">
          <div className="title-green card__title">
            {t('Amount / Wallet')}
          </div>
          <div className="refund__content card__head-content">
            <p>{ t('Due to regulation we require more information about your Source of Wealth and Source of Funds. Alongside some more personal information regarding your residential address and Nationality.') }</p>
          </div>
        </div>

        <div className="settings__fieldset">
          <div className="settings__row flex__row flex__wrap">
            {isShowOldTokenAmount && (
                <InputThemeField
                  classWrapper="field easy-onboarding__individual-tokenamount"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  disabled
                  type="number"
                  label={publicSettings && publicSettings.old_token_symbol
                    ? `${publicSettings.old_token_symbol} ${t('amount')}`
                    : t('Old token amount')
                  }
                  value={tokenSwap.oldTokenAmount}
                  onChange={() => {}}
                />
              )
            }

            {isShowExactInvestmentAmount && (
              <InputThemeField
                classWrapper="field easy-onboarding__individual-exactinvestmentamount"
                classLabel="field__label"
                classInput="field__input"
                required
                type="number"
                label={t('Exact investment amount')}
                helperText={`${t('amount in')} ${baseCurrency}`}
                value={values.exactIntendedInvestmentAmount}
                onChange={({ target: { value } }) => {
                  setValues({ ...values, exactIntendedInvestmentAmount: +value})
                  setExactIntendedInvestmentAmountValue(+value)
                }}
              />
            )}

            {isShowTokenAmount && (
              <>
                {!invertTokenForm.invert && (
                  <InputThemeField
                    classWrapper="field easy-onboarding__individual-tokenamount"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    type="number"
                    label={publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION && publicSettings.enable_swap_token && tokenSwap
                      ? `${publicSettings.token_symbol} ${t('amount')}`
                      : t('Token amount')
                    }
                    postFix={
                      <button
                        className={classNames('button button-small', classes.swapBtn)}
                        onClick={() => setInvertTokenForm({...invertTokenForm, invert: !invertTokenForm.invert})}
                      >
                        Swap
                      </button>
                    }
                    value={values.amount}
                    onChange={({ target: { value } }) => {
                      let amount = value
                      if (amount > publicSettings.maximum_investment_amount) {
                        amount = publicSettings.maximum_investment_amount;
                      }
                      setValues({ ...values, amount: amount})
                      setAmountValue(+amount)
                      setInvertTokenForm({...invertTokenForm, amount: tokenToAnything(values.currency || baseCurrency, +amount, exchangeRates)});
                    }}
                    disabled={publicSettings.enable_swap_token && tokenSwap}
                    helperText={<>
                        {renderEquivalentValue(values.amount, values.currency || publicSettings.base_currency)}
                        <br />
                        {renderHelperMinimumText()}
                      </>}
                  />
                )}

                {invertTokenForm.invert && (
                  <InputThemeField
                    classWrapper="field easy-onboarding__individual-tokenamount"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    type="number"
                    label={(values.currency || publicSettings.base_currency).toUpperCase()}
                    postFix={
                      <button
                        className={classNames('button button-small', classes.swapBtn)}
                        onClick={() => setInvertTokenForm({...invertTokenForm, invert: !invertTokenForm.invert})}
                      >
                        Swap
                      </button>
                    }
                    value={invertTokenForm.amount}
                    onChange={({ target: { value } }) => {
                      const rate = tokenToAnything(values.currency || publicSettings.base_currency, 1, exchangeRates, true);
                      let amountToToken = value / rate;
                      let invertAmount = value

                      if (Math.floor(amountToToken) > publicSettings.maximum_investment_amount) {
                        amountToToken = publicSettings.maximum_investment_amount
                        invertAmount = tokenToAnything(values.currency || publicSettings.base_currency, amountToToken, exchangeRates)
                      }

                      setValues({ ...values, amount: Math.floor(amountToToken)})
                      setAmountValue(Math.floor(amountToToken))
                      setInvertTokenForm({...invertTokenForm, amount: invertAmount})
                    }}
                    disabled={publicSettings.enable_swap_token && tokenSwap}
                    helperText={<>
                        {`=${values.amount} Token`}
                        <br />
                        {renderHelperMinimumText()}
                      </>}
                  />
                )}
              </>
            )}

            {isShowIntendedInvestment && (
              <DropdownMaterial
                label={t('Intended investment')}
                classWrapper="easy-onboarding__individual-intendendinvestment"
                isRequired
                state={values}
                setState={setValues}
                propertyName="intended_investment"
                isBackgroundWhite
              >
                {getIntendedInvestmentOptions().map((option) => (
                  <MenuItem key={option} value={(option || "")}>
                    {formatIntendedInvestment(option)}
                  </MenuItem>
                ))}
              </DropdownMaterial>
            )}
          </div>

          {isShowingPreferCurrency && (
            <div className="field easy-onboarding__invidividual-preferedpaymentcurrency">
              <div className="field__label">
                {t('My preferred payment currency')}
              </div>

              <div className="field__wrap d__flex flex__row flex__wrap">
                {getCurrencyOptions().map((currency) => (
                  <RadioTheme
                    key={currency}
                    value={currency}
                    radioText={currency.toUpperCase()}
                    nameGroup="preferedPayment"
                    defaultChecked={currency === values.currency}
                    isGrayText
                    isRadioGroupHorizontal
                    onChange={() => {
                      setValues({ ...values, currency })
                      setInvertTokenForm({...invertTokenForm, amount: tokenToAnything(currency, amountValue, exchangeRates)});
                    }}
                  />
                ))}
              </div>
            </div>
          )}

          {isShowWalletType && isShowReceivingWallet && (
            <div className="settings__row flex__row flex__wrap">
              <DropdownMaterial
                label={t('Select a Wallet Address type')}
                classWrapper="easy-onboarding__individual-selectwallet"
                isRequired={isShowReceivingWallet && (publicSettings.enable_cashlink || publicSettings.kyc_require_receiving_wallet)}
                state={values}
                setState={setValues}
                propertyName="wallet"
                isBackgroundWhite
              >
                {getWalletOptions().map((option) => (
                  <MenuItem key={option} value={(option || "")}>
                    {t(walletLabelTextMapping[option])}
                  </MenuItem>
                ))}
              </DropdownMaterial>
            </div>
          )}

          {isShowReceivingWallet && (
            <>
              {!publicSettings.force_generate_wallet && (
                <InputThemeField
                  classWrapper="field easy-onboarding__individual-receivingwallet"
                  classLabel="field__label"
                  classInput="field__input"
                  label={t('Receiving wallet address')}
                  postFix={isQdev ? (
                    <>
                      <span
                        data-tip={t('Please create a new and empty wallet for the purpose of receiving Q tokens. If you want to pay using cryptocurrency, you can use the same wallet. In that case, please fund it with Crypto tokens (USDC, etc) and ETH (for gas costs) withdrawn from an Exchange and not from a private wallet used for other purposes in Defi / Staking / NFT etc.')}
                        data-for="tokenAddress"
                        className="tooltip"
                      >
                      <Icon
                        className={classNames('info')}
                        name='info'
                        size="16"
                      />
                      </span>
                      <ReactTooltip id="tokenAddress" place="bottom" padding="24px" className="popover-tooltip"/>
                    </>
                  ) : ''}
                  value={values.tokenAddress}
                  required={publicSettings && publicSettings.kyc_require_receiving_wallet}
                  onChange={({ target: { value } }) => setValues({ ...values, tokenAddress: value })}
                />
              )}

              <WalletRequest
                type={!values.wallet && kycAcceptedWallets().includes('eth') ? 'eth' : values.wallet}
                generatedWallet={values.generatedWallet}
                onComplete={setGeneratedWallet}
                isKycFlow={true}
              />
              <br />

              {publicSettings && publicSettings.show_verify_wallet ?
                (values.tokenAddress && values.verified_wallet === values.tokenAddress.toLowerCase()) ?
                  <span className="text-green">{t('This address have been verified.')}</span> :
                  <button
                    type="button"
                    className="button"
                    onClick={confirmWalletAndSend}
                    disabled={disableSubmitVerify}
                  >
                    { t('Verify this wallet') }
                  </button>
                : ''
              }
            </>
          )}

          {isShowSendingWallet && isShowAddEtherWalletLater && (
            <div className="field">
              <SwitchCheckbox
                value="isAddEtherWalletLater"
                onChange={({ target: { checked } }) => setValues({ ...values, isAddEtherWalletLater: checked })}
                switchText={t('I will add my Sending Wallet address later through my profile')}
                defaultChecked={values.isAddEtherWalletLater}
                isRemoveLine
                isLeftSwitch
              />
            </div>
          )}

          {isShowSendingWallet && (
            <div className="settings__row flex__row flex__wrap">
              <DropdownMaterial
                classWrapper="easy-onboarding__individual-walletaddresstpe"
                label={t('Select a Wallet Address type')}
                isRequired={!values.isAddEtherWalletLater}
                state={values}
                setState={setValues}
                propertyName="sending_wallet"
                isBackgroundWhite
              >
                {getSendingWalletTypeOptions(values.currency).map((option) => (
                  <MenuItem key={option[0]} value={option[0]}>
                    {option[1]}
                  </MenuItem>
                ))}
              </DropdownMaterial>
            </div>
          )}

          {isShowSendingWallet && (
            <InputThemeField
              classWrapper="field easy-onboarding__individual-sendingwalletaddress"
              classLabel="field__label"
              classInput="field__input"
              label={t('Sending wallet address')}
              value={values.tokenSendingAddress}
              required={!values.isAddEtherWalletLater}
              disabled={values.isAddEtherWalletLater}
              onChange={({ target: { value } }) => setValues({ ...values, tokenSendingAddress: value })}
            />
          )}

          {values.referal_code && (
            <InputThemeField
              classWrapper="field easy-onboarding__individual-referralcode"
              classLabel="field__label"
              classInput="field__input"
              label={t('Referral Code')}
              value={values.referal_code}
              disabled
              onChange={({ target: { value } }) => setValues({ ...values, referal_code: value })}
            />
          )}
        </div>

        <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
          <button className="button-stroke" onClick={goToPrevStep}>{t('Previous')}</button>
          <button className="button" onClick={goToNextStep}>{t('Continue')}</button>
        </div>
      </div>

      {userData.rights === 'COMMON' && (
        <>
          {userData.kyc_status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED && (
            publicSettings.enable_product_tour && !values.skip_product_tour && (
              <JoyRide steps={tourBoardingDetailWallet} callback={({ status }) => {
                if (status === 'skipped') {
                  setValues({...values, skip_product_tour: true})
                }
              }} />
            )
          )}
        </>
      )}
    </div>
  )
}

export default CardForm
