import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import {
  CircularProgress,
} from '@material-ui/core'

import Icon from '../../components/Icon'

const SpotCheckStatistic = ({
  data,
  loading,
}) => {
  const { t } = useTranslation()

  return (
    <div className="total card">
      <div className="card__head">
        <div className="title-primary card__title">
          { t('Overview') }
        </div>
      </div>

      <div className="income">
        <div className="income__list admin_amount_statistics flex__row flex__wrap">
          <div className="income__item">
            <div className="income__icon" style={{ backgroundColor: "#B5E4CA"}}>
              <Icon
                className={classNames('activity')}
                name='activity'
                size="24"
              />{" "}
            </div>

            <div className="income__line">
              <div className="income__details">
                <div className="income__label">
                  { t('Total spotcheck request') }
                </div>

                <div className="income__counter">
                  { loading && <CircularProgress /> }
                  { data && data.total }
                </div>
              </div>
            </div>
          </div>

          <div className="income__item">
            <div className="income__icon" style={{ backgroundColor: "#CABDFF"}}>
              <Icon
                className={classNames('check-all')}
                name='check-all'
                size="24"
              />{" "}
            </div>

            <div className="income__line">
              <div className="income__details">
                <div className="income__label">
                  { t('Checked request') }
                </div>

                <div className="income__counter">
                  { loading && <CircularProgress /> }
                  { data && data.checked }
                </div>
              </div>
            </div>
          </div>

          <div className="income__item">
            <div className="income__icon" style={{ backgroundColor: "#B1E5FC"}}>
              <Icon
                className={classNames('clock')}
                name='clock'
                size="24"
              />{" "}
            </div>

            <div className="income__line">
              <div className="income__details">
                <div className="income__label">
                  { t('Pending request') }
                </div>

                <div className="income__counter">
                  { loading && <CircularProgress /> }
                  { data && data.pending }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpotCheckStatistic
