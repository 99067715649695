import React from 'react'
import Icon from '../../../components/Icon'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

const SidebarNav = () => {
  const { t } = useTranslation();
  return (
    <div className="create__preview js-preview">
      <div className="card">
        <div className="card__head">
          <div className="title-blue card__title">{t('Navigation')}</div>
        </div>

        <div className="settings__fieldset">
          <div className="header__nav">
            <div className="header__link color">
              <Icon
                className={classNames('profile-circle')}
                name='profile-circle'
                size="24"
              />{" "}
              { t('Personal Details') }
            </div>
          </div>

          <div className="header__nav">
            <div to="#" className="header__link">
              <Icon
                className={classNames('mail')}
                name='mail'
                size="24"
              />{" "}
              { t('Subscription') }
            </div>

            <div className="sidebar__body">
              <div className="sidebar__link color">{ t('ID / DOB') }</div>

              <div className="sidebar__link color">{ t('Amount / Wallet') }</div>

              <div className="sidebar__link color">{ t('Education') }</div>

              <div className="sidebar__link color">{ t('SOF / SOW') }</div>

              <div className="sidebar__link color">{ t('Address / Nationality') }</div>
            </div>
          </div>

          <div className="header__link color">
            <Icon
              className={classNames('ticket')}
              name='ticket'
              size="24"
            />{" "}
            { t('Disclaimers') }
          </div>

          <div className="header__nav">
            <div className="header__link">
              <Icon
                className={classNames('check-all')}
                name='check-all'
                size="24"
              />{" "}
              { t('Liveness Check Onboarding Navigation') }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SidebarNav;
