import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  CUSTOMER_COMPANY_UBO,
  UPDATE_UBO_COMPANY_CUSTOMER
} from '../queriesAndMutations'
import { extractMsgFromErrByPropertyName } from '../utils'
import moment from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';

import DateFnsUtils from '@date-io/date-fns/build/index'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslation } from 'react-i18next';
import InputThemeField from '../components/InputThemeField'

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
  },
}))

const KycUboProfile = ({ match }) => {
  const { t } = useTranslation();
  const classes = useStyles()
  const { data, error: loadError } = useQuery(CUSTOMER_COMPANY_UBO, {
    variables: {
      id: match.params.uboId,
    }
  })

  const [updateUboCompanyCustomer, updateUboCompanyCustomerData] = useMutation(UPDATE_UBO_COMPANY_CUSTOMER)
  const { loading, error: errors, data: updateData } = updateUboCompanyCustomerData

  const [values, setValues] = useState({
    email: '',
    firstName: '',
    lastName: '',
    middleName: '',
    birthDate: '2000-01-01',
  })

  const [companyName, setCompanyName] = useState('')

  const saveUbo = () => {
    updateUboCompanyCustomer({
      variables: {
        id: match.params.uboId,
        input: values
      }
    }).catch(() => {})
  }

  const getDateValue = (date) => moment(date).format('YYYY-MM-DD')

  useEffect(() => {
    if (data && data.customerCompanyUbo) {
      if (data.customerCompanyUbo.status === 'VIDEO_IDENT') {
        window.location.href = window.location.origin + `/ubo-profile/${match.params.uboId}/video-ident`
        return;
      }

      if (data.customerCompanyUbo.status !== 'NOT_SUBMITTED' && data.customerCompanyUbo.status !== 'REOPEN') {
        window.location.href = window.location.origin
      }

      setValues(Object.keys(values).reduce((all, key) => ({
        ...all,
        [key]: data.customerCompanyUbo[key] || (key === 'birthDate' ? moment().format('YYYY-MM-DD') : ''),
      }), {}))

      setCompanyName(data.customerCompanyUbo.customerCompany.companyName);
    }
  // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    if (loadError) {
      window.location.href = window.location.origin;
    }
  }, [loadError]);

  if (updateData) {
    window.location.href = `${window.location.origin}`;
    return <></>
  }

  const minBirthDate = moment().subtract(18, 'years');

  return (
    <>
      <div className="h3 nospace__bottom">{`Hi ${values.firstName} ${values.lastName}`}</div>
      <div className="shop__info">
        {`${t('You were added as a UBO of COMPANY_NAME.', {
            COMPANY_NAME: companyName
          })} ${t('Please verify yourself so COMPANY_NAME can complete the onboarding.', {
            COMPANY_NAME: companyName
          })}`
        }
      </div>
      <div className="page__title">{' '}</div>

      <div className="card">
        <div className="settings__wrapper">
          <div className="settings__list">
            <div className="settings__item">
              <div className="title-primary settings__title">
                {t('Your personal information required for identification')}
              </div>

              <div className="settings__fieldset">
                <div className="settings__row flex__row flex__wrap have__fields have__space">
                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    label={t('Email Address')}
                    value={values.email}
                    disabled
                    onChange={({target: { value }}) => setValues({...values, email: value})}
                    error={!!extractMsgFromErrByPropertyName(errors, 'email')}
                    helperText={extractMsgFromErrByPropertyName(errors, 'email').join('. ')}
                  />

                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    label={t('Your name')}
                    value={values.firstName}

                    onChange={({target: { value }}) => setValues({...values, firstName: value})}

                    error={!!extractMsgFromErrByPropertyName(errors, 'firstName')}
                    helperText={extractMsgFromErrByPropertyName(errors, 'firstName').join('. ')}
                  />

                  <InputThemeField
                    required
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    label={t('Your surname')}
                    value={values.lastName}

                    onChange={({target: { value }}) => setValues({...values, lastName: value})}

                    error={!!extractMsgFromErrByPropertyName(errors, 'lastName')}
                    helperText={extractMsgFromErrByPropertyName(errors, 'lastName').join('. ')}
                  />

                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    label={t('Your middle name')}
                    value={values.middleName}

                    onChange={({target: { value }}) => setValues({...values, middleName: value})}

                    error={!!extractMsgFromErrByPropertyName(errors, 'middleName')}
                    helperText={extractMsgFromErrByPropertyName(errors, 'middleName').join('. ')}
                  />

                  <div className="field">
                    <div className="field__label">
                      {t('Date of Birth')}
                      <span className="text__red"> *</span>
                    </div>

                    <div className="field__wrap field__wrap-date">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          maxDate={minBirthDate.toDate()}
                          disableToolbar
                          variant="inline"
                          format="dd.MM.yyyy"
                          required
                          margin="normal"
                          value={values.birthDate}
                          onChange={value => setValues({...values, birthDate: getDateValue(value)})}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          openTo="year"
                          views={["year", "month", "date"]}
                          id="date-picker-date-of-birth"
                          invalidDateMessage={t('Invalid Date Format')}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
              { loading ? (
                <div className={classes.circularProgressWrapper}>
                  <CircularProgress />
                </div>
              ) : (
                <button className="button" onClick={saveUbo}>
                  {t('Next')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default KycUboProfile
