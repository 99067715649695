import gql from "graphql-tag";

export const UPDATE_COMMENT_KYC_LIST = gql`
  query updateCommentKyc($input: UpdateCommentKycInput) {
    updateCommentKyc(input: $input) {
      meta {
        page
        pageSize
        totalPages
        total
      }
      objects {
        id
        status_type_before
        status_type_after
        comment
        user_id
        kyc_individual_id
        kyc_company_id
        transaction_id
      }
    }
  }
`;

export const GET_LOG_EVENT = gql`
  query getLogEvent($input: LogEventQueryInput) {
    getLogEvent(input: $input) {
      total
      data {
        id
        status_type_before
        status_type_after
        comment
        user_id
        kyc_individual_id
        kyc_company_id
        director_id
        transaction_id
        log_type
        from_ip
        created_at
        user {
          id
          email
          first_name
          last_name
        }
      }
    }
  }
`;

export const GET_OWN_KYC_LOG_EVENT = gql`
  query getOwnKycLogEvent {
    getOwnKycLogEvent {
      id
      status_type_before
      status_type_after
      comment
      user_id
      kyc_individual_id
      kyc_company_id
      transaction_id
      log_type
      from_ip
      created_at
      user {
        id
        email
        first_name
        last_name
      }
    }
  }
`;

export const GET_OWN_KYB_LOG_EVENT = gql`
  query getOwnKybLogEvent {
    getOwnKybLogEvent {
      id
      status_type_before
      status_type_after
      comment
      user_id
      kyc_individual_id
      kyc_company_id
      transaction_id
      director_id
      log_type
      from_ip
      created_at
      user {
        id
        email
        first_name
        last_name
      }
      customerCompanyDirector {
        id
        email
      }
    }
  }
`;
