import gql from 'graphql-tag';

export const CHANGE_USER_STATUS = gql`
    mutation changeUserStatus($input: ChangeUserStatusInput) {
        changeUserStatus(input: $input){
            id
            email
            name
            is_active
            lastLogin
            status
            rights
        }
    }
`;

export const CHANGE_USER_RIGHTS = gql`
    mutation changeUserRights($input: ChangeUserRightsInput) {
        changeUserRights(input: $input){
            id
            email
            name
            is_active
            lastLogin
            status
            rights
        }
    }
`;

export const CHANGE_CUSTOMER_COMPANY_STATUS = gql`
    mutation changeCustomerCompanyStatus($input: ChangeCustomerCompanyStatusInput) {
        changeCustomerCompanyStatus(input: $input){
            id
            email
            companyName
            companyAddress
            companyRegisterNumber
            taxNumber
            taxOffice
            descriptionRegisterExtractBusinessActivity
            authorizedPersonals
            documentType
            wallet
            tokenAddress
            personType
            duties
            relationshipToPEP
            amount
            status
            created_at
            updated_at
        }
    }
`;

export const CHANGE_CUSTOMER_STATUS = gql`
    mutation changeCustomerStatus($input: ChangeCustomerStatusInput) {
        changeCustomerStatus(input: $input) {
            id
            email
            phoneNumber
            first_name
            last_name
            uniqueId
            descriptionOfFunds
            taxNumber
            taxOffice
            birthDate
            placeOfBirth
            nationality
            nationality_two
            nationality_three
            countryOfResidence
            residentialAddress
            city
            postalCode
            wallet
            tokenAddress
            personType
            duties
            relationshipToPEP
            documentType
            amount
            status
            created_at
            updated_at
        }
    }
`;

export const REOPEN_KYCS_FORM = gql`
    mutation reopenKycsForm($input: ReopenKycsFormInput) {
        reopenKycsForm(input: $input) {
            success
            message
        }
    }
`

export const CHANGE_APPROVED_TO_WHITELISTED_CUSTOMER_STATUS = gql`
    mutation changeApprovedToWhitelistedCustomerStatus($amount: Int, $type: String) {
        changeApprovedToWhitelistedCustomerStatus(amount: $amount, type: $type)
    }
`;

export const ADMIN_USER_LIST_CSV = gql`
    mutation userListCsv($two_fa_token: String) {
        userListCsv(two_fa_token: $two_fa_token)
    }
`

export const ADMIN_KYC_LIST_CSV = gql`
    mutation kycListCsv($type: String, $two_fa_token: String, $is_pythagoras: Boolean, $is_bank: Boolean) {
        kycListCsv(type: $type, two_fa_token: $two_fa_token, is_pythagoras: $is_pythagoras, is_bank: $is_bank)
    }
`

export const ADMIN_TRANSACTION_LIST_CSV = gql`
    mutation transactionListCsv($two_fa_token: String, $filtered_status: [String], $isRegulatorReporting: Boolean) {
        transactionListCsv(two_fa_token: $two_fa_token, filtered_status: $filtered_status, isRegulatorReporting: $isRegulatorReporting)
    }
`

export const ADMIN_CAPITAL_INCREASE_TRANSACTION_LIST_CSV = gql`
    mutation capitalIncreaseTransactionListCsv($two_fa_token: String, $filtered_status: [String], $capital_increase_id: String) {
        capitalIncreaseTransactionListCsv(two_fa_token: $two_fa_token, filtered_status: $filtered_status, capital_increase_id: $capital_increase_id)
    }
`

export const UPLOAD_NEW_PHOTO = gql`
    mutation uploadNewPhoto($input: UploadNewPhotoInput) {
        uploadNewPhoto(input: $input)
    }
`

export const TXT_FILE_CONTRACTED = gql`
    mutation txtFileContracted($twoFaToken: String) {
        txtFileContracted(twoFaToken: $twoFaToken)
    }
`

export const TXT_FILE_DISTRIBUTION = gql`
    mutation txtFileDistribution($twoFaToken: String, $month: String) {
      txtFileDistribution(twoFaToken: $twoFaToken, month: $month)
    }
`

export const UPDATE_DIRECTOR_STATUS = gql`
    mutation updateDirectorStatus($id: ID!, $status: String, $comment: String) {
        updateDirectorStatus(id: $id, status: $status, comment: $comment)
    }
`

export const EXPORT_ELECTRONIC_ID_DATA = gql`
    mutation exportElectronicIdData($id: [ID]) {
        exportElectronicIdData(id: $id)
    }
`

export const UPDATE_ALL_RESOURCES = gql`
    mutation updateAllResources($type: String, $two_fa_token: String) {
        updateAllResources(type: $type, two_fa_token: $two_fa_token)
    }
`

export const DELETE_CHECK_RESULT = gql`
    mutation deleteCheckResult($input: DeleteCheckResultInput) {
        deleteCheckResult(input: $input)
    }
`
export const OVERIDE_WATCHLIST_ENHANCED = gql`
    mutation overideWatchlistEnhanced($kycId: ID!, $result: String!, $comment: String, $type: String!) {
        overideWatchlistEnhanced(kycId: $kycId, result: $result, comment: $comment, type: $type)
    }
`
