import gql from 'graphql-tag'

export const ADD_PERSONAL_DISCOUNT = gql`
  mutation addPersonalDiscount($input: AddPersonalDiscountInput!) {
    addPersonalDiscount(input: $input)
  }
`;

export const IMPORT_PERSONAL_DISCOUNT_CSV = gql`
  mutation importPersonalDiscountCsv($input: ImportPersonalDiscountCsvInput!) {
    importPersonalDiscountCsv(input: $input)
  }
`;

export const DELETE_PERSONAL_DISCOUNT = gql`
  mutation deletePersonalDiscount($ids: [Int!]!) {
    deletePersonalDiscount(ids: $ids)
  }
`;
