import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { useMe } from '../../myHooks'
import PersonalData from './PersonalData/PersonalData'
import Password from './Password'
import Banking from './Banking'
import TwoFactorAuth from './TwoFactorAuth'
import DisableTwoFactorAuth from './DisableTwoFactorAuth'
import { useTranslation } from 'react-i18next'
import { usePublicSettings } from '../../myHooks/useSettings'
import { hasUserEnoughRights } from '../../utils'
import ModalInnerMaterialDialog from '../../components/ModalInnerMaterialDialog'

const Profile = () => {
  const { t } = useTranslation();
  const {
    data: { me: userData },
    refetch: refetchMe,
  } = useMe();
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const [values, setValues] = useState({
    tab: "PERSONAL_DATA",
    ethAddressDialogOpened: false,
    twoFADialogOpened: false,
    disableTwoFADialogOpened: false,
  });

  const changeTwoFADialogState = (state) => () =>
    setValues({
      ...values,
      twoFADialogOpened: state,
    });

  const changeDisableTwoFADialogState = (state) => () =>
    setValues({
      ...values,
      disableTwoFADialogOpened: state,
    });

  const changeTab = (tabName) => () =>
    setValues({
      ...values,
      tab: tabName,
    });

  const renderTabs = () => (
    <ul className="settings__menu">
      <button
        className={`settings__link ${
          values.tab === "PERSONAL_DATA" ? "active" : ""
        }`}
        onClick={changeTab("PERSONAL_DATA")}
      >
        { t('Personal Data') }
      </button>

      <button
        className={`settings__link ${values.tab === "PASSWORD" ? "active" : ""}`}
        onClick={changeTab("PASSWORD")}
      >
        { t('Password') }
      </button>

      <button
        className={`settings__link ${values.tab === "BANKING" ? "active" : ""}`}
        onClick={changeTab("BANKING")}
      >
      { t('Banking') }
      </button>
    </ul>
  );

  const renderHorizontalTabs = () => (
    <div className="products__nav tablet-show">
      <button
        className={`products__link js-tabs-link ${
          values.tab === "PERSONAL_DATA" ? "active" : ""
        }`}
        onClick={changeTab("PERSONAL_DATA")}
      >
        { t('Personal Data') }
      </button>

      <button
        className={`products__link js-tabs-link ${values.tab === "PASSWORD" ? "active" : ""}`}
        onClick={changeTab("PASSWORD")}
      >
        { t('Password') }
      </button>

      <button
        className={`products__link js-tabs-link ${values.tab === "BANKING" ? "active" : ""}`}
        onClick={changeTab("BANKING")}
      >
      { t('Banking') }
      </button>
    </div>
  );

  return (
    <>
      <div className="page__title h3">{ t('Profile') }</div>

      <div className="page__row settings">
        {renderTabs()}

        <div className="settings__wrapper settings__wrapper_2">
          <div className="products__head products__head_profile tablet-show">
            {renderHorizontalTabs()}
          </div>
          {values.tab === "PERSONAL_DATA" && (
            <div className="tab-pane fade show active" id="personal-data">
              <PersonalData />
            </div>
          )}

          {values.tab === "PASSWORD" && (
            <div className="tab-pane fade" id="password">
              <Password />
            </div>
          )}

          {values.tab === "BANKING" && (
            <div className="tab-pane fade" id="banking">
              <Banking userData={userData} refetch={refetchMe} />
            </div>
          )}
        </div>
      </div>

      {
        (
          (publicSettings && !publicSettings.disable_2fa_security) ||
          hasUserEnoughRights(userData.rights, 'COMPLIANCE_OFFICER') ||
          userData.is_2fa_enabled
        ) && (
          <div className="card">
            <div className="title-primary settings__title have_status">
              {t('Two-Factor Verification')}
              {' '}
              {userData.is_2fa_enabled ? (
                <span className="status-primary products__status">
                  { t('Enabled') }
                </span>
              ) : (
                <span className="status-red products__status">
                  { t('Disabled') }
                </span>
              )}
            </div>

            <div className="refund__content">
              <p>{ t('Two-factor authentication is a method used for protection of your web account. When it is activated you are required to enter not only your password, but also an activation code. This code will be issueed to you by a mobile application. Even if anyone would find out your password 2FA would still protect your account from unauthorized access. Security is our top priority at all times.') }</p>
            </div>
            <span className="space space__32">{' '}</span>
            {!userData.is_2fa_enabled ? (
              <button
                type="button"
                onClick={changeTwoFADialogState(true)}
                className="button"
              >
                { t('Enable 2FA') }
              </button>
            ) : (
              <button
                type="button"
                onClick={changeDisableTwoFADialogState(true)}
                className="button button-danger"
              >
                { t('Disable 2FA') }
              </button>
            )}
          </div>
        )
      }

      <Dialog
        open={values.twoFADialogOpened}
        onClose={changeTwoFADialogState(false)}
        maxWidth="lg"
        classes={{
          root: 'kyc-dialog'
        }}
      >
        <ModalInnerMaterialDialog modalSize="popup__kyc-medium" onClickClose={changeTwoFADialogState(false)}>
          <div className="description__row flex__row flex__wrap">
            <TwoFactorAuth onClose={changeTwoFADialogState(false)} />
          </div>
        </ModalInnerMaterialDialog>
      </Dialog>

      <Dialog
        open={values.disableTwoFADialogOpened}
        onClose={changeDisableTwoFADialogState(false)}
        maxWidth="lg"
        classes={{
          root: 'kyc-dialog'
        }}
      >
        <ModalInnerMaterialDialog modalSize="popup__kyc-medium" onClickClose={changeDisableTwoFADialogState(false)}>
          <div className="description__row flex__row flex__wrap">
            <DisableTwoFactorAuth
              onClose={changeDisableTwoFADialogState(false)}
            />
          </div>

        </ModalInnerMaterialDialog>
      </Dialog>
    </>
  );
};

export default Profile;
