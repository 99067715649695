import React from 'react'
import { Link } from 'react-router-dom'
import { usePublicSettings } from '../../myHooks'
import LoginRegisterHeader from '../../components/HeaderLoginRegister'
import LoginRegisterHeaderRwd from '../../components/HeaderLoginRegisterRwd'
import LoginRegisterLogo from '../../components/LogoLoginRegister'
import RegisterForm from '../../components/RegisterForm'
import { useTranslation } from 'react-i18next'

const Register = () => {
  const { t } = useTranslation();
  const { data: { publicSettings = {} } = {} } = usePublicSettings()

  return (
    <>
      <LoginRegisterHeader>
        <span>
          { t('Already have an account?') }{'  '}<Link to="/login"> { t('Sign in instead')}</Link>
        </span>
      </LoginRegisterHeader>

      <LoginRegisterHeaderRwd>
        <div className="actions__option actions__option_normal d__block">
          <span>
            { t('Already have an account?') }{'  '}<Link to="/login"> { t('Sign in instead')}</Link>
          </span>
        </div>
      </LoginRegisterHeaderRwd>

      <div className="entry entry_loginregister">
        <div className="entry__wrapper entry__wrapper-kyc">
          <LoginRegisterLogo />

          {
            publicSettings && publicSettings.disable_registration
            ? (
              <p>{t('Website is currently on maintenance. Please come back later.')}</p>
            ) : (
              <RegisterForm />
            )
          }
        </div>
      </div>
    </>
  );
};

export default Register;
