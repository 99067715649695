import gql from 'graphql-tag';

export const REGISTRATION_INDIVIDUAL_CUSTOMER = gql`
    mutation registrationIndividualCustomer($input: CustomerIndividualInput!) {
        registrationIndividualCustomer(input: $input) {
            id
            email
            phoneNumber
            first_name
            last_name
            middle_name
            uniqueId
            descriptionOfFunds
            descriptionOfWealth
            taxNumber
            taxOffice
            birthDate
            placeOfBirth
            currency
            nationality
            nationality_two
            nationality_three
            countryOfResidence
            residentialAddress
            city
            postalCode
            wallet
            tokenAddress
            sending_wallet
            tokenSendingAddress
            personType
            duties
            relationshipToPEP
            documentType
            amount
            intended_investment
            status
            source_of_funds
            source_of_funds_other
            source_of_wealth
            source_of_wealth_other
            created_at
            updated_at
            occupation
            yearly_salary
            education_level
            education_level_other
            occupation_other
            position
            position_other
            website
            verified_wallet
        }
    }
`;

export const UPDATE_INDIVIDUAL_CUSTOMER = gql`
    mutation updateIndividualCustomer($id: ID!, $input: CustomerIndividualUpdateInput!) {
        updateIndividualCustomer(id: $id, input: $input) {
            id
            email
            phoneNumber
            first_name
            last_name
            middle_name
            uniqueId
            descriptionOfFunds
            descriptionOfWealth
            taxNumber
            taxOffice
            birthDate
            placeOfBirth
            currency
            nationality
            nationality_two
            nationality_three
            countryOfResidence
            residentialAddress
            city
            postalCode
            wallet
            tokenAddress
            sending_wallet
            tokenSendingAddress
            personType
            duties
            relationshipToPEP
            documentType
            amount
            intended_investment
            status
            source_of_funds
            source_of_funds_other
            source_of_wealth
            source_of_wealth_other
            created_at
            updated_at
            education_level
            education_level_other
            occupation_other
            position
            position_other
            website
            verified_wallet
        }
    }
`;

export const MANUAL_UPLOAD_DOCUMENT = gql`
    mutation manualUploadDocument($id: ID!, $input: ManualUploadDocumentInput!) {
        manualUploadDocument(id: $id, input: $input) {
            id
        }
    }
`;

export const UPDATE_COMPANY_CUSTOMER = gql`
    mutation updateCompanyCustomer($id: ID!, $input: CustomerCompanyUpdateInput!) {
        updateCompanyCustomer(id: $id, input: $input) {
            id
        }
    }
`;

export const REGISTRATION_COMPANY_CUSTOMER = gql`
    mutation registrationCompanyCustomer($input: CustomerCompanyInput!) {
        registrationCompanyCustomer(input: $input) {
            id
        }
    }
`;

export const REMOVE_INDIVIDUAL_CUSTOMER = gql`
    mutation removeIndividualCustomer($id: ID!) {
        removeIndividualCustomer(id: $id) {
            success
            message
        }
    }
`;

export const REMOVE_COMPANY_CUSTOMER = gql`
    mutation removeCompanyCustomer($id: ID!) {
        removeCompanyCustomer(id: $id) {
            success
            message
        }
    }
`;

export const UPDATE_DIRECTOR_COMPANY_CUSTOMER = gql`
    mutation updateCompanyCustomerDirector($id: ID!, $input: CustomerCompanyDirectorInput!) {
        updateCompanyCustomerDirector(id: $id, input: $input) {
            email
            phone
            firstName
            lastName
            middleName
            taxNumber
            uniqueId
            birthDate
            sourceOfFunds
            placeOfBirth
            nationality
            nationality_two
            nationality_three
            countryOfResidence
            residentialAddress
            city
            postalCode
            education_level
            education_level_other
            occupation_other
            employer_company_name
            position
            position_other
            website
        }
    }
`;

export const UPDATE_UBO_COMPANY_CUSTOMER = gql`
    mutation updateCompanyCustomerUbo($id: ID!, $input: CustomerCompanyUboInput!) {
        updateCompanyCustomerUbo(id: $id, input: $input) {
            email
            firstName
            lastName
            middleName
            birthDate
        }
    }
`;

export const ADMIN_UPDATE_CUSTOMER_INDIVIDUAL = gql`
    mutation adminUpdateCustomerIndividual($id: ID!, $input: AdminUpdateCustomerIndividualInput!) {
        adminUpdateCustomerIndividual(id: $id, input: $input)
    }
`;

export const UPDATE_CREDITOR_NUMBER = gql`
    mutation updateCreditorNumber($customerId: ID!, $creditorNumber: String!) {
        updateCreditorNumber(customerId: $customerId, creditorNumber: $creditorNumber)
    }
`;

export const TRIGGER_TOKENSWAP_SIGNNOW = gql`
    mutation triggerTokenSwapSignnow($customerId: ID!, $customerType: String!) {
        triggerTokenSwapSignnow(customerId: $customerId, customerType: $customerType)
    }
`;

export const TRIGGER_TOKENSALE_SIGNNOW = gql`
    mutation triggerTokenSaleSignnow($customerId: ID!, $customerType: String!) {
        triggerTokenSaleSignnow(customerId: $customerId, customerType: $customerType)
    }
`;
