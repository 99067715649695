// eslint-disable-next-line import/prefer-default-export
export const TRANSACTION_INFO_SCHEME = {
  type: {
    label: 'Transaction Type',
  },
  payment_type: {
    label: 'Payment Getway',
  },
  // deposit_from: {
  //   label: 'Deposit From',
  // },
  // hash: {
  //   label: 'Tx Hash',
  // },
  // deposit_to: {
  //   label: 'Deposit To',
  // },
  // details: {
  //   label: 'Details',
  // },
}
