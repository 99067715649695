import React from 'react'
import moment from 'moment'
import { SUPPORTED_SOURCE_OF_FUND_DOCUMENTS } from '../queriesAndMutations/uploadQuery'
import { useQuery } from '@apollo/react-hooks'
import { getFileUrl, getExternalFileUrl } from '../utils'
import { useTranslation } from 'react-i18next'
import Icon from './Icon'
import classNames from 'classnames'

const SupportedSourceOfFundDocuments = ({ userId }) => {
  const { t } = useTranslation();
  const { data: { supportedSourceOfFundDocuments = [] } = {} } =
    useQuery(SUPPORTED_SOURCE_OF_FUND_DOCUMENTS, {
      variables: { userId: userId },
    });

  const customeGetUploadUrl = (upload) => getExternalFileUrl(upload.storage_key);

  return (
    <>
      {supportedSourceOfFundDocuments && supportedSourceOfFundDocuments.length > 0 && (
        <div className="field">
          <div className="field__label">{ t('Suported Source Of Funds') }</div>
          <div className="field__wrap shop shop__products">
            {supportedSourceOfFundDocuments &&
              supportedSourceOfFundDocuments.map((upload, index) => {
                const uploader = upload.uploader || upload.user;
                const upload_by = `${uploader.first_name} ${uploader.last_name}`;

                return (
                  <div key={`${upload.storage_key}_${index}`} className="summary summary__document summary-default summary__fullwidth">
                    <div className="summary__preview">
                      <div className="summary__preview_aspect_ratio">
                        <embed
                          src={getFileUrl(upload.storage_key)}
                          height="400px"
                          width='auto'
                          type="application/pdf"
                        />
                      </div>

                      <div className="summary__control summary__control-has-text">
                        <div className="summary__buttons">
                          <a
                            className="summary__button"
                            href={customeGetUploadUrl(upload)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Icon
                              className={classNames('link')}
                              name='link'
                              size="24"
                            />{" "}
                          </a>
                        </div>
                        <div className="summary__control-texts">
                          <div className="summary__control-text">
                            {`${t('Upload by')}: ${upload_by}`}
                          </div>
                          <div className="summary__control-text">
                            {`${t('Upload at')}: ${moment(parseInt(upload.created_at)).format()}`}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="sumary__line">
                      <div className="summary__title">
                        {upload.storage_key}
                      </div>
                    </div>
                  </div>)
                }
              )}
          </div>
        </div>
      )}
    </>
  );
};

export default SupportedSourceOfFundDocuments;
