import gql from 'graphql-tag'

export const CUSTOMER_INDIVIDUAL = gql`
  query customerIndividual($id: ID!) {
    customerIndividual(id: $id) {
      id
      email
      phoneNumber
      first_name
      last_name
      middle_name
      currency
      uniqueId
      descriptionOfFunds
      descriptionOfWealth
      taxNumber
      taxOffice
      birthDate
      placeOfBirth
      nationality
      nationality_two
      nationality_three
      countryOfResidence
      residentialAddress
      city
      postalCode
      wallet
      tokenAddress
      sending_wallet
      tokenSendingAddress
      personType
      duties
      relationshipToPEP
      documentType
      amount
      status
      source_of_funds
      source_of_funds_other
      source_of_wealth
      source_of_wealth_other
      created_at
      updated_at
      mainDocumentUpload {
        id
        storage_key
        preview_storage_key
        mime_type
        original_name
        is_public
      }
      photoWithMeUpload {
        id
        storage_key
        preview_storage_key
        mime_type
        original_name
        is_public
      }
      backSideDocumentUpload {
        id
        storage_key
        preview_storage_key
        mime_type
        original_name
        is_public
      }
      proofOfResidenceUpload {
        id
        storage_key
        preview_storage_key
        mime_type
        original_name
        is_public
      }
      occupation
      industry
      industryOther
      yearly_salary
      employer_company_name
      education_level
      education_level_other
      occupation_other
      position
      position_other
      website
      intended_investment
      net_investable_assets
      what_will_you_use
      what_will_you_use_other
      exactIntendedInvestmentAmount
      verified_wallet
      master_kyc_id
      auto_approve_by_sync
      livenessCheckApplicant {
        id
        uuid
        status
      }
    }
  }
`;

export const CUSTOMER_COMPANY = gql`
  query customerCompany($id: ID!) {
    customerCompany(id: $id) {
      id
      email
      companyName
      companyAddress
      companyRegisterNumber
      taxNumber
      taxOffice
      descriptionRegisterExtractBusinessActivity
      documentType
      wallet
      tokenAddress
      sending_wallet
      tokenSendingAddress
      personType
      duties
      relationshipToPEP
      amount
      intended_investment
      currency
      sourceOfFunds
      business_countries
      business_following_industries
      sic_code
      customerCompanyUbo {
        email
        firstName
        lastName
        ownership
        trueCertifiedCopyType
        birthDate
        personType
        duties
        relationshipToPEP
      }
      proofOfResidenceType
      customerCompanyDirector {
        email
        phone
        firstName
        lastName
        signatureRight
        willSignForm
        personType
        duties
        relationshipToPEP
        status
        onfidoApplicant {
          onfidoChecks {
            status
          }
        }
        user {
          id
          email
          is_active
          kyc_status
          video_ident_status
        }
      }
      grossRevenue
      investmentPurpose
      hasNotRelatedSourceIncome
      notRelatedSourceIncomeDescription
      sourceOfIncome
      companyPostalCode
      companyCountry
      companyPlace
      companyShareCapital
      placeOfCommercialRegister
      dateOfEntryInCommercialRegister
      dateOfIncorporation
      legalForm
      legalFormOther
      exactIntendedInvestmentAmount
      directorFormType
      status
      verified_wallet
    }
  }
`;

export const CUSTOMER_COMPANY_DIRECTOR = gql`
  query customerCompanyDirector($id: ID!) {
    customerCompanyDirector(id: $id) {
      id
      email
      phone
      firstName
      lastName
      middleName
      taxNumber
      uniqueId
      birthDate
      occupation
      industry
      industryOther
      sourceOfFunds
      placeOfBirth
      nationality
      nationality_two
      nationality_three
      countryOfResidence
      residentialAddress
      city
      postalCode
      status
      industry
      industryOther
      education_level
      education_level_other
      occupation_other
      employer_company_name
      position
      position_other
      website
      customerCompany {
        id
        companyName
      }
      idnowIdent {
        id
        transactionNumber
        identCode
        directorId
        status
        result
        reason
      }
    }
  }
`;

export const CUSTOMER_COMPANY_UBO = gql`
  query customerCompanyUbo($id: ID!) {
    customerCompanyUbo(id: $id) {
      id
      email
      firstName
      lastName
      middleName
      status
      customerCompany {
        id
        companyName
      }
    }
  }
`;

export const GET_REFERRAL_CODE_LIST = gql`
  query getReferralCodeList {
    getReferralCodeList
  }
`

export const GET_USER_DIRECTOR_LIST = gql`
  query getUserDirectorList {
    getUserDirectorList{
      id
      email
      firstName
      lastName
      status
      allDocumentESignature
      willSignForm
      onfidoApplicant {
        onfidoChecks {
          status
        }
      }
      complycubeClient {
        complycubeChecks {
          status
        }
      }
      idnowIdent {
        status
      }
      livenessCheckApplicant {
        status
      }
    }
  }
`
export const GET_USER_UBO_LIST = gql`
  query getUserUboList {
    getUserUboList{
      id
      email
      firstName
      lastName
      status
      onfidoApplicant {
        onfidoChecks {
          onfidoReports {
            result
          }
        }
      }
    }
  }
`

export const ADMIN_GET_CUSTOMER_COMPANY = gql`
  query adminGetCustomerCompany($id: ID!) {
    adminGetCustomerCompany(id: $id) {
      id
      email
      companyName
      companyAddress
      companyRegisterNumber
      companyCountry
      kompanyMapping {
        kompanyId
        data
        user {
          email
        }
      }
    }
  }
`
