import React from "react";
import ClassNames from "classnames";
import Icon from "./Icon";
// import Tooltip from "./Tooltip";

const CustomInput = ({
  // eslint-disable-next-line react/prop-types
  propertyName,
  key,
  classWrapper,
  classLabel,
  classInput,
  icon,
  label = "",
  value,
  onChange,
  type = "text",
  name = "",
  placeholder,
  required,
  disabled,
  error = [],
  loading = false,
  select = false,
  helperText,
  endInput,
  postFix,
} = {}) => {
  return (
    <div className={ClassNames(
      classWrapper,
      { 'field_icon': icon}
      )}
      key={key}
    >
      {label && (
        <div className={ClassNames(
          classLabel
        )}>
          {label}{" "}

          {required && (
            <span className="text__red"> *</span>
          )}

          {postFix && (
            postFix
          )}
        </div>
      )}

      <div className={ClassNames('field__wrap', endInput ? 'd__flex' : '')}>
        <input
          className={ClassNames(classInput)}
          value={value}
          onChange={onChange}
          type={type || "text"}
          name={name}
          placeholder={placeholder}
          disabled={disabled || loading}
        />

        {icon && (
          <div className="field__icon">
            <Icon className={icon} name={icon} size="24" />{" "}
          </div>
        )}
        {endInput && (
          <>
            &nbsp;
            <div className="field__postfix">
              {endInput}
            </div>
          </>
        )}
      </div>

      {helperText && (
        <div className="field__helper">
          {helperText}
        </div>
      )}
      {error && error.length > 0 && error.map(err => (
        <p className="text__red" key={err}>{err}</p>
      ))}
    </div>
  );
};

CustomInput.propTypes = {

}

export { CustomInput as default };
