import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  Stepper,
  Step,
  StepLabel,
  TextField,
  MenuItem,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns/build/index'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import PhoneInput from 'react-phone-input-2'
import { useTranslation } from 'react-i18next'
import 'react-phone-input-2/lib/style.css'

import {
  CUSTOMER_COMPANY_DIRECTOR,
  UPDATE_DIRECTOR_COMPANY_CUSTOMER
} from '../queriesAndMutations'
import { usePublicSettings } from '../myHooks'
import { extractMsgFromErrByPropertyName, getCountryList, getNationalityList } from '../utils'
import { SOURCE_OF_FUNDS_STANDARD_SCHEMAS, SOURCE_OF_FUNDS_PUBLIC_SCHEMAS } from '../schemas/kycVerification'
import InputThemeField from '../components/InputThemeField'
import {
  COUNTRY_LIST,
  INDUSTRY_OPTIONS,
  RESTRICTED_NATIONALITY,
  RESTRICTED_COUNTRY_LIST,
  OCCUPATION_OPTIONS,
  EDUCATION_OPTIONS,
  POSITION_OPTIONS,
 } from '../constants/customer'
import DropdownMaterial from '../components/DropdownMaterial'
import Icon from '../components/Icon'

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
  },
}))

const KycDirectorProfile = ({ match }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  const { data, error: loadError } = useQuery(CUSTOMER_COMPANY_DIRECTOR, {
    variables: {
      id: match.params.directorId,
    }
  })

  const isShowingOccupation = (publicSettings && publicSettings.kyc_occupation) || false
  const isShowingIndustry = (publicSettings && publicSettings.kyc_require_industry) || false
  const isShowingEducationLevel = (publicSettings && publicSettings.kyc_education_level) || false;
  const isShowingEmployerCompanyName = (publicSettings && publicSettings.kyc_employer_company_name) || false;

  const [updateDirectorCompanyCustomer, updateDirectorCompanyCustomerData] = useMutation(UPDATE_DIRECTOR_COMPANY_CUSTOMER)
  const { loading, error: errors, data: updateData } = updateDirectorCompanyCustomerData
  const isCryptoadvisory = window.location.host === 'kyc.cryptoadvisory.li' || window.location.host === 'dev-kyc.cryptoadvisory.li';
  const countryList = getCountryList(publicSettings, isCryptoadvisory);
  const nationalityList = getNationalityList(publicSettings, isCryptoadvisory);

  const [showNationalityTwo, setShowNationalityTwo] = useState(false);
  const [showNationalityThree, setShowNationalityThree] = useState(false);
  const [values, setValues] = useState({
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    middleName: '',
    taxNumber: '',
    uniqueId: '',
    birthDate: '2000-01-01',
    occupation: '',
    industry: '',
    industryOther: '',
    sourceOfFunds: '',
    placeOfBirth: '',
    nationality: '',
    nationality_two: '',
    nationality_three: '',
    countryOfResidence: '',
    residentialAddress: '',
    city: '',
    postalCode: '',
    education_level: '',
    education_level_other: '',
    occupation_other: '',
    employer_company_name: '',
    position: '',
    position_other: '',
    website: '',
  })

  const [companyName, setCompanyName] = useState('')

  const saveDirector = () => {
    updateDirectorCompanyCustomer({
      variables: {
        id: match.params.directorId,
        input: values
      }
    }).catch(() => {})
  }

  const getDateValue = (date) => moment(date).format('YYYY-MM-DD')

  useEffect(() => {
    if (data && data.customerCompanyDirector) {
      if (data.customerCompanyDirector.status === 'VIDEO_IDENT') {
        window.location.href = window.location.origin + `/director-profile/${match.params.directorId}/video-ident`
        return;
      }

      if (data.customerCompanyDirector.status !== 'NOT_SUBMITTED' && data.customerCompanyDirector.status !== 'REOPEN') {
        window.location.href = window.location.origin
      }

      setValues(Object.keys(values).reduce((all, key) => ({
        ...all,
        [key]: data.customerCompanyDirector[key] || (key === 'birthDate' ? moment('2000-01-01').format('YYYY-MM-DD') : ''),
      }), {}))

      setCompanyName(data.customerCompanyDirector.customerCompany.companyName);
    }
  // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    if (loadError) {
      window.location.href = window.location.origin;
    }
  }, [loadError]);

  if (updateData) {
    window.location.href = `${window.location.origin}/director-profile/${match.params.directorId}/video-ident`;
    return <></>
  }

  const minBirthDate = moment().subtract(18, 'years');

  const defaultSourceOfFundsSchema = publicSettings && publicSettings.public_sale_sof_sow ? SOURCE_OF_FUNDS_PUBLIC_SCHEMAS : SOURCE_OF_FUNDS_STANDARD_SCHEMAS;
  let sourceOfFundsSchema = SOURCE_OF_FUNDS_STANDARD_SCHEMAS;
  if (values.sourceOfFunds && Object.prototype.hasOwnProperty.call(defaultSourceOfFundsSchema, values.sourceOfFunds.toLocaleLowerCase())) {
    sourceOfFundsSchema = defaultSourceOfFundsSchema;
  } else {
    sourceOfFundsSchema = publicSettings && publicSettings.public_sale_sof_sow ? SOURCE_OF_FUNDS_STANDARD_SCHEMAS : SOURCE_OF_FUNDS_PUBLIC_SCHEMAS;
  }

  return (
    <>
      <div className="h3 nospace__bottom">{`Hi ${values.firstName} ${values.lastName}`}</div>
      <div className="shop__info">
        {t('You were added as a Director of COMPANY_NAME. Please verify yourself so COMPANY_NAME can complete the onboarding', {COMPANY_NAME: companyName})}
      </div>
      <div className="page__title">{' '}</div>

      <div className="card">
        <div className="kyc__stepper kyc__stepper_32">
          <Stepper activeStep={0}>
            <Step key={0}>
              <StepLabel>{t('Personal Details')}</StepLabel>
            </Step>

            <Step key={1}>
              <StepLabel>{t('Liveness Check')}</StepLabel>
            </Step>
          </Stepper>
        </div>
      </div>

      <div className="card">
        <div className="settings__wrapper">
          <div className="settings__list">
            <div className="settings__item">
              <div className="title-primary settings__title">
                {t('Your personal information required for identification')}
              </div>

              <div className="settings__fieldset">
                <div className="settings__row flex__row flex__wrap have__fields have__space">
                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    label={t('Email Address')}
                    value={values.email}
                    disabled
                    onChange={({target: { value }}) => setValues({...values, email: value})}
                    error={!!extractMsgFromErrByPropertyName(errors, 'email')}
                    helperText={extractMsgFromErrByPropertyName(errors, 'email').join('. ')}
                  />

                  {publicSettings && publicSettings.kyc_phone_number && (
                    <div className="field">
                      <div className="field__label">
                        {t('Phone number')}
                        <span className="text__red"> *</span>
                      </div>

                      <div className="field__wrap field__wrap-phone">
                        <PhoneInput
                          defaultCountry="us"
                          onChange={(value) => setValues({...values, phone: value})}
                          fullWidth
                          value={values.phone}
                        />
                        <FormHelperText error={!!extractMsgFromErrByPropertyName(errors, 'phone')}>
                          {extractMsgFromErrByPropertyName(errors, 'phone').join('. ')}
                        </FormHelperText>
                      </div>
                    </div>
                  )}

                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    label={t('Your name')}
                    value={values.firstName}

                    onChange={({target: { value }}) => setValues({...values, firstName: value})}

                    error={!!extractMsgFromErrByPropertyName(errors, 'firstName')}
                    helperText={extractMsgFromErrByPropertyName(errors, 'firstName').join('. ')}
                  />

                  <InputThemeField
                    required
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    label={t('Your surname')}
                    value={values.lastName}

                    onChange={({target: { value }}) => setValues({...values, lastName: value})}

                    error={!!extractMsgFromErrByPropertyName(errors, 'lastName')}
                    helperText={extractMsgFromErrByPropertyName(errors, 'lastName').join('. ')}
                  />

                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    label={t('Your middle name')}
                    value={values.middleName}

                    onChange={({target: { value }}) => setValues({...values, middleName: value})}

                    error={!!extractMsgFromErrByPropertyName(errors, 'middleName')}
                    helperText={extractMsgFromErrByPropertyName(errors, 'middleName').join('. ')}
                  />

                  {publicSettings && (
                      publicSettings.kyc_levels[1].acceptedFieldIndividualFiat.taxNumber ||
                      publicSettings.kyc_levels[1].acceptedFieldIndividualCrypto.taxNumber
                    ) && (
                      <InputThemeField
                        classWrapper="field"
                        classLabel="field__label"
                        classInput="field__input"
                        required
                        label={t('Tax Number')}
                        value={values.taxNumber}

                        onChange={({target: { value }}) => setValues({...values, taxNumber: value})}

                        error={!!extractMsgFromErrByPropertyName(errors, 'taxNumber')}
                        helperText={extractMsgFromErrByPropertyName(errors, 'taxNumber').join('. ')}
                      />
                    )
                  }

                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={t('Identification Number (Passport, National ID Card)')}
                    value={values.uniqueId}

                    onChange={({target: { value }}) => setValues({...values, uniqueId: value})}

                    error={!!extractMsgFromErrByPropertyName(errors, 'uniqueId')}
                    helperText={extractMsgFromErrByPropertyName(errors, 'uniqueId').join('. ')}
                  />

                  <div className="field">
                    <div className="field__label">
                      {t('Date of Birth')}
                      <span className="text__red"> *</span>
                    </div>

                    <div className="field__wrap field__wrap-date">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          maxDate={minBirthDate.toDate()}
                          disableToolbar
                          variant="inline"
                          format="dd.MM.yyyy"
                          required
                          margin="normal"
                          value={values.birthDate}
                          onChange={value => setValues({...values, birthDate: getDateValue(value)})}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          openTo="year"
                          views={["year", "month", "date"]}
                          id="date-picker-date-of-birth"
                          invalidDateMessage={t('Invalid Date Format')}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>

                  {isShowingEducationLevel && (
                    <>
                      <DropdownMaterial
                        label={t('Educational Qualifications')}
                        isRequired
                        state={values}
                        setState={setValues}
                        propertyName="education_level"
                        isBackgroundWhite
                      >
                        {EDUCATION_OPTIONS.map((option) => (
                          <MenuItem key={option} value={option}>
                            {t(option)}
                          </MenuItem>
                        ))}
                      </DropdownMaterial>

                      {values.education_level === 'Other' && (
                        <InputThemeField
                          classWrapper="field"
                          classLabel="field__label"
                          classInput="field__input"
                          required
                          label={t('Describe details of educational qualifications')}
                          value={values.education_level_other}
                          onChange={({ target: { value } }) => setValues({ ...values, education_level_other: value})}
                        />
                      )}
                    </>
                  )}

                  {isShowingOccupation && (
                    <>
                      <DropdownMaterial
                        label={t('Occupation')}
                        isRequired
                        state={values}
                        setState={setValues}
                        propertyName="occupation"
                        isBackgroundWhite
                      >
                        {OCCUPATION_OPTIONS.map((option) => (
                          <MenuItem key={option} value={option}>
                            {t(option)}
                          </MenuItem>
                        ))}
                      </DropdownMaterial>

                      {values.occupation === 'Other' && (
                        <InputThemeField
                          classWrapper="field"
                          classLabel="field__label"
                          classInput="field__input"
                          required
                          label={t('Describe details of occupation')}
                          value={values.occupation_other}
                          onChange={({ target: { value } }) => setValues({ ...values, occupation_other: value})}
                        />
                      )}
                    </>
                  )}

                  {isShowingIndustry && (
                    <>
                      <div className="field">
                        <div className="field__label">
                          {t('Industry')}
                          <span className="text__red"> *</span>
                        </div>

                        <div className="field__wrap field__wrap-select">
                          <TextField
                            required
                            select
                            value={values.industry}

                            onChange={({target: { value }}) => setValues({...values, industry: value})}

                            error={!!extractMsgFromErrByPropertyName(errors, 'industry')}
                            helperText={extractMsgFromErrByPropertyName(errors, 'industry').join('. ')}
                          >
                            {INDUSTRY_OPTIONS.map(option => (
                              <MenuItem key={option} value={option}>
                                {t(option)}
                                {option === t('Other') && t(' (please describe)')}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </div>

                      {values.industry === 'Other' && (
                        <InputThemeField
                          classWrapper="field"
                          classLabel="field__label"
                          classInput="field__input"
                          required
                          label={t('Describe details of industry')}
                          value={values.industryOther}

                          onChange={({target: { value }}) => setValues({...values, industryOther: value})}

                          error={!!extractMsgFromErrByPropertyName(errors, 'industryOther')}
                          helperText={extractMsgFromErrByPropertyName(errors, 'industryOther').join('. ')}
                        />
                      )}
                    </>
                  )}

                  {isShowingEmployerCompanyName && (
                    <InputThemeField
                      classWrapper="field"
                      classLabel="field__label"
                      classInput="field__input"
                      required
                      label={t('Company name')}
                      value={values.employer_company_name}
                      onChange={({ target: { value } }) => setValues({ ...values, employer_company_name: value})}
                    />
                  )}

                  {!!publicSettings && publicSettings.kyc_show_position && (
                    <>
                      <DropdownMaterial
                        label={t('Position')}
                        isRequired
                        state={values}
                        setState={setValues}
                        propertyName="position"
                        isBackgroundWhite
                      >
                        {POSITION_OPTIONS.map((option) => (
                          <MenuItem key={option} value={option}>
                            {t(option)}
                          </MenuItem>
                        ))}
                      </DropdownMaterial>

                      {values.position === 'Other' && (
                        <InputThemeField
                          classWrapper="field"
                          classLabel="field__label"
                          classInput="field__input"
                          required
                          label={t('Describe details of position')}
                          value={values.position_other}
                          onChange={({ target: { value } }) => setValues({ ...values, position_other: value})}
                        />
                      )}
                    </>
                  )}

                  {!!publicSettings && publicSettings.kyc_show_website && (
                    <InputThemeField
                      classWrapper="field"
                      classLabel="field__label"
                      classInput="field__input"
                      required={false}
                      label={t('Website')}
                      value={values.website}
                      onChange={({ target: { value } }) => setValues({ ...values, website: value})}
                    />
                  )}
                </div>

                {publicSettings && (
                    publicSettings.kyc_levels[1].acceptedFieldIndividualFiat.descriptionOfFunds ||
                    publicSettings.kyc_levels[1].acceptedFieldIndividualCrypto.descriptionOfFunds
                  ) && (
                    <div className="settings__row settings__row-fullwidth">
                      <div className="field">
                        <div className="field__label">
                          {t('Source of your fund')}
                          <span className="text__red"> *</span>
                        </div>

                        <div className="field__wrap field__wrap-select">
                          <TextField
                            required
                            select
                            value={values.sourceOfFunds}

                            onChange={({target: { value }}) => setValues({...values, sourceOfFunds: value})}

                            error={!!extractMsgFromErrByPropertyName(errors, 'sourceOfFunds')}
                            helperText={extractMsgFromErrByPropertyName(errors, 'sourceOfFunds').join('. ')}
                          >
                            {Object.entries(sourceOfFundsSchema).map(source => (
                              <MenuItem key={source[0]} value={source[0]}>{t(source[1].label)}</MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>

              <div className="title-primary settings__title">
                {t('Your Address')}
              </div>

              <div className="settings__fieldset">

                <div className="settings__row flex__row flex__wrap have__fields have__space">
                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={t('Place Of Birth')}

                    value={values.placeOfBirth}
                    onChange={({target: { value }}) => setValues({...values, placeOfBirth: value})}
                    error={!!extractMsgFromErrByPropertyName(errors, 'placeOfBirth')}
                    helperText={extractMsgFromErrByPropertyName(errors, 'placeOfBirth').join('. ')}
                  />

                  <div className="field">
                    <div className="field__label">
                      {t('Nationality')}
                      <span className="text__red"> *</span>
                    </div>

                    <div className="field__wrap field__wrap-select">
                      <TextField
                        select
                        value={values.nationality}

                        onChange={({target: { value }}) => setValues({...values, nationality: value})}

                        error={!!extractMsgFromErrByPropertyName(errors, 'nationality')}
                        helperText={extractMsgFromErrByPropertyName(errors, 'nationality').join('. ')}
                      >
                        {nationalityList.map(country => (
                          <MenuItem key={country} value={country}>{t(country)}</MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>

                  { !showNationalityTwo &&
                    <button
                      className="button-stroke w-full text__center"
                      type="button"
                      onClick={() => setShowNationalityTwo(true)}
                    >
                      <Icon
                        name='check'
                        size="24"
                      />{" "}
                      {t('Add new nationality')}
                    </button>
                  }

                  { showNationalityTwo &&
                    <div className="field">
                      <div className="field__label">
                        {t('Add more Nationality')}
                      </div>

                      <div className="field__wrap field__wrap-select">
                        <TextField
                          select
                          value={values.nationality_two}

                          onChange={({target: { value }}) => setValues({...values, nationality_two: value})}

                          error={!!extractMsgFromErrByPropertyName(errors, 'nationality_two')}
                          helperText={extractMsgFromErrByPropertyName(errors, 'nationality_two').join('. ')}
                        >
                          {nationalityList.map(country => (
                            <MenuItem key={country} value={country}>{t(country)}</MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                  }

                  { showNationalityTwo && !showNationalityThree &&
                    <button
                      className="button-stroke w-full text__center"
                      type="button"
                      onClick={() => setShowNationalityThree(true)}
                    >
                      <Icon
                        name='check'
                        size="24"
                      />{" "}
                      {t('Add new nationality')}
                    </button>
                  }

                  { showNationalityThree &&
                    <div className="field">
                      <div className="field__label">
                        {t('Add more Nationality')}
                      </div>

                      <div className="field__wrap field__wrap-select">
                        <TextField
                          select
                          value={values.nationality_three}

                          onChange={({target: { value }}) => setValues({...values, nationality_three: value})}

                          error={!!extractMsgFromErrByPropertyName(errors, 'nationality_three')}
                          helperText={extractMsgFromErrByPropertyName(errors, 'nationality_three').join('. ')}
                        >
                          {nationalityList.map(country => (
                            <MenuItem key={country} value={country}>{t(country)}</MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                  }

                  <div className="field">
                    <div className="field__label">
                      {t('Country of Residence')}
                      <span className="text__red"> *</span>
                    </div>

                    <div className="field__wrap field__wrap-select">
                      <TextField
                        required
                        select
                        value={values.countryOfResidence}

                        onChange={({target: { value }}) => setValues({...values, countryOfResidence: value})}

                        error={!!extractMsgFromErrByPropertyName(errors, 'countryOfResidence')}
                        helperText={extractMsgFromErrByPropertyName(errors, 'countryOfResidence').join('. ')}
                      >
                        {countryList.map(country => (
                          <MenuItem key={country} value={country}>{t(country)}</MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>

                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={t('Residential Address')}

                    value={values.residentialAddress}
                    onChange={({target: { value }}) => setValues({...values, residentialAddress: value})}
                    error={!!extractMsgFromErrByPropertyName(errors, 'residentialAddress')}
                    helperText={extractMsgFromErrByPropertyName(errors, 'residentialAddress').join('. ')}
                  />

                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={t('City')}
                    value={values.city}
                    onChange={({target: { value }}) => setValues({...values, city: value})}
                    error={!!extractMsgFromErrByPropertyName(errors, 'city')}
                    helperText={extractMsgFromErrByPropertyName(errors, 'city').join('. ')}
                  />

                  <InputThemeField
                    classWrapper="field"
                    classLabel="field__label"
                    classInput="field__input"
                    required
                    label={t('Postal Code')}
                    value={values.postalCode}

                    onChange={({target: { value }}) => setValues({...values, postalCode: value})}

                    error={!!extractMsgFromErrByPropertyName(errors, 'postalCode')}
                    helperText={extractMsgFromErrByPropertyName(errors, 'postalCode').join('. ')}
                  />
                </div>
              </div>
            </div>

            <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
              { loading ? (
                <div className={classes.circularProgressWrapper}>
                  <CircularProgress />
                </div>
              ) : (
                <button className="button" onClick={saveDirector}>
                  {t('Next')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default KycDirectorProfile
