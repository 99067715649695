import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import WalletAddress from "./profile/WalletAddress";
import { useTranslation } from 'react-i18next';
import Icon from "./Icon";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  editWallet: {
    color: "#1c65c9",
    margin: 0,
    cursor: "pointer",
  },
  dialog: {
    "& .MuiDialog-paper": {
      padding: "10px 0 26px 0",
    },
  },
  paper: {
    minWidth: "200px",
  },
}));

const SendWallet = ({
  userData,
  publicSettings,
  dialogOpen,
  onOpenDialog,
  onCloseDialog,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const shortFormatEthAddress = (ethAddress) =>
    `${ethAddress.slice(0, 8)}....${ethAddress.slice(
      ethAddress.length - 8,
      ethAddress.length
    )}`;

  const walletAddress =
    userData && (userData.wallet_sending_address);

  return (
    <div className="card">
      <div className="card__head">
        <div className="title-primary card__title">
          { t('Sending Address') }
        </div>
      </div>

      <div className="popular">
        <div className="popular__list">
          {walletAddress ? (
            <div className="popular__item pt__0 popular__item-normal">
              <div className="popular__title">
                {shortFormatEthAddress(walletAddress)}
              </div>
            </div>
          ) : (
            <div className="popular__item pt__0 popular__item-normal">
              <div className="tips__action">
                { t('You don\'t have any wallet yet') }
              </div>
            </div>
          )}
        </div>

        {walletAddress ? (
          <button
            onClick={onOpenDialog}
            className="button-stroke popular__button"
          >
            { t('Edit') }
          </button>
        ) : (
          <button
            onClick={onOpenDialog}
            className="button-stroke popular__button"
          >
            { t('Add') }
          </button>
        )}
      </div>

      <Dialog
        className={classes.dialog}
        open={dialogOpen}
        onClose={onCloseDialog}
      >
        <div className="popup js-popup animation visible popup__kyc-medium">
          <div
            onClick={onCloseDialog}
            className="popup__overlay js-popup-overlay"
          >
            {' '}
          </div>

          <div className="popup__wrap js-popup-wrap">
            <div className="description">
              <div className="title-primary description__title">
                { t('Wallet Address') }
              </div>

              <div className="description__row flex__row flex__wrap">
                <WalletAddress />
              </div>
            </div>

            <button
              className="popup__close js-popup-close"
              onClick={onCloseDialog}
            >
              <Icon
                className={classNames('close', 'icon-24')}
                name="close"
                size="24"
              />
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

SendWallet.propTypes = {
  dialogOpen: PropTypes.bool,
  userData: PropTypes.object.isRequired,
  publicSettings: PropTypes.object,
  onOpenDialog: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
};

export default SendWallet;
