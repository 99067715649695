import React from 'react'
import { NavLink } from 'react-router-dom'
import RegisterForm from '../../components/RegisterForm'
import LoginRegisterHeader from '../../components/HeaderLoginRegister'
import LoginRegisterHeaderRwd from '../../components/HeaderLoginRegisterRwd'
import { useTranslation } from 'react-i18next'
import LoginRegisterLogo from '../../components/LogoLoginRegister'

const RegisterSuccess = () => {
  const { t } = useTranslation();

  return (
    <>
      <LoginRegisterHeader>
        <NavLink to="/">{ t('Back to login page') }</NavLink>
      </LoginRegisterHeader>

      <LoginRegisterHeaderRwd>
        <div className="actions__option actions__option_normal d__blocko">
          <NavLink to="/">{ t('Back to login page') }</NavLink>
        </div>
      </LoginRegisterHeaderRwd>

      <div className="entry entry_loginregister">
        <div className="entry__wrapper entry__wrapper-kyc">
          <LoginRegisterLogo />

          <RegisterForm />
        </div>
      </div>
    </>
  );
};

export default RegisterSuccess;
