import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { ADMIN_USER_LIST_CSV } from '../../queriesAndMutations'
import InputTheme from '../InputTheme';
import { useTranslation } from 'react-i18next'

const AdminExportUsers = ({ onClose }) => {
  const [value, setValue] = useState({
    stepNumber: 0,
    two_fa_token: '',
  })

  const [queryUserList, { loading, error, data: { userListCsv } = {} }] = useMutation(ADMIN_USER_LIST_CSV)
  const changeStep = (step) => setValue({
    ...value,
    stepNumber: step,
  })

  const csvData = userListCsv && encodeURI(`data:text/csv;charset=utf-8,${userListCsv}`)
  const { t } = useTranslation()

  const onSubmit = async (e) => {
    e.preventDefault()
    queryUserList({ variables: { two_fa_token: value.two_fa_token } }).then(() => {
      changeStep(1)
    }).catch(() => {})
  }

  const renderFirstStep = () => (
    <>
      <form onSubmit={onSubmit} className="settings__item">
        <div className="settings__fieldset">
          <InputTheme
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            propertyName="two_fa_token"
            label={t('Token')}
            state={value}
            setState={setValue}
            error={error}
            loading={loading}
          />
        </div>
      </form>

      <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
        <button className="button" type="submit">{ t('Next') }</button>
      </div>
    </>
  )

  const renderSuccess = () => (
    <>
      <div className="settings__item">{' '}</div>
      <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
        <a
          href={csvData}
          onClick={onClose}
          download="user_list.csv"
          className="button"
        >
          { t('Click to Download') }
        </a>
      </div>
    </>
  )

  return (
    <div className="settings__wrapper">
      <div className="space space__16">{' '}</div>
      <div className="kyc__stepper kyc__stepper_32">
        <Stepper activeStep={value.stepNumber}>
          <Step key={0}>
            <StepLabel>{ t('2FA token') }</StepLabel>
          </Step>
          <Step key={1}>
            <StepLabel>{ t('Download') }</StepLabel>
          </Step>
        </Stepper>
      </div>
      <div className="settings__list">
        {value.stepNumber === 0 && renderFirstStep()}
        {value.stepNumber === 1 && renderSuccess()}
      </div>
    </div>
  )
}

export default AdminExportUsers
