import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import QRCode from 'react-qr-code'
import { useQuery, useMutation } from '@apollo/react-hooks'

import {
  CircularProgress
} from '@material-ui/core'

import {
  GET_INTERNAL_LIVENESS_CHECK_APPLICANT,
  GENERATE_INTERNAL_LIVENESS_CHECK_REMOTE_LINK,
} from '../../queriesAndMutations'

const LivenessCheckQrCode = ({ applicantUuid }) => {
  const {
    data: { getInternalLivenessCheckApplicant: applicant } = {},
    loading,
    error,
    stopPolling,
  } = useQuery(GET_INTERNAL_LIVENESS_CHECK_APPLICANT, {
    variables: {
      applicant_uuid: applicantUuid
    },
    pollInterval: 3000,
  })
  const [
    generateInternalLivenessCheckRemoteLink,
    generateInternalLivenessCheckRemoteLinkStatus,
  ] = useMutation(GENERATE_INTERNAL_LIVENESS_CHECK_REMOTE_LINK)
  const [accessToken, setAccessToken] = useState('');

  // Stop polling on error
  useEffect(() => {
    if (error) {
      stopPolling()
    }
  }, [error, stopPolling])

  // Redirect on applicant completed
  useEffect(() => {
    if (applicant && applicant.status === 'SUBMITTED') {
      stopPolling()
      setTimeout(() => {
        window.location.href = window.location.origin
      }, 3000)
    }
  }, [applicant, stopPolling])

  // Request for remote link
  useEffect(() => {
    if (applicant && applicant.status === 'NOT_STARTED' && accessToken === '') {
      generateInternalLivenessCheckRemoteLink({
        variables: {
          applicant_uuid: applicantUuid,
        }
      }).then(({ data: { generateInternalLivenessCheckRemoteLink: url }}) => {
        setAccessToken(url);
      })
    }
  }, [applicant, accessToken, applicantUuid, generateInternalLivenessCheckRemoteLink])

  return (
    <div className="component component-internal-liveness-check-qr-code">
      {loading && (
        <>
          <CircularProgress />
          <p>Checking applicant...</p>
        </>
      )}
      {!loading && !applicant && (
        <div className="text-center text-danger">
          <p>Applicant not found</p>
        </div>
      )}
      {generateInternalLivenessCheckRemoteLinkStatus.loading && (
        <>
          <CircularProgress />
          <p>Requesting access link...</p>
        </>
      )}
      {applicant && applicant.status === 'NOT_STARTED' && (
        <div className="text-center">
          {accessToken && (
            <>
              <p>Use your phone to scan the QR code below and continue the process on your phone.</p>
              <QRCode value={`${window.location.origin}/internal-liveness-check-form/${accessToken}`} />
            </>
          )}
        </div>
      )}
      {applicant && applicant.status === 'SUBMITTED' && (
        <div className="text-center">
          Liveness check has been completed
        </div>
      )}
    </div>
  )
}

LivenessCheckQrCode.propTypes = {
  applicantUuid: PropTypes.string
}

export default LivenessCheckQrCode
