import React from 'react'
import Icon from '../../../components/Icon'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

const SidebarNav = ({
  goToStep,
}) => {
  const { t } = useTranslation();
  return (
    <div className="create__preview js-preview">
      <div className="card">
        <div className="card__head">
          <div className="title-primary card__title">{t('Navigation')}</div>
        </div>

        <div className="settings__fieldset">
          <div className="header__nav">
            <button className="header__link color" onClick={() => goToStep(0)}>
              <Icon
                className={classNames('profile-circle')}
                name='profile-circle'
                size="24"
              />{" "}
              { t('Personal Details') }
            </button>
          </div>

          <div className="header__nav">
            <button to="#" className="header__link" onClick={() => goToStep(1)}>
              <Icon
                className={classNames('mail')}
                name='mail'
                size="24"
              />{" "}
              { t('Subscription') }
            </button>

            <div className="sidebar__body">
              <button className="sidebar__link color" onClick={() => goToStep(1)}>{ t('ID / DOB') }</button>

              <button className="sidebar__link color" onClick={() => goToStep(2)}>{ t('Amount / Wallet') }</button>

              <button className="sidebar__link" onClick={() => goToStep(3)}>{ t('Education') }</button>

              <button className="sidebar__link" onClick={() => goToStep(4)}>{ t('SOF / SOW') }</button>

              <button className="sidebar__link" onClick={() => goToStep(5)}>{ t('Address / Nationality') }</button>
            </div>
          </div>

          <button className="header__link" onClick={() => goToStep(6)}>
            <Icon
              className={classNames('ticket')}
              name='ticket'
              size="24"
            />{" "}
            { t('Disclaimers') }
          </button>

          <div className="header__nav">
            <div className="header__link">
              <Icon
                className={classNames('check-all')}
                name='check-all'
                size="24"
              />{" "}
              { t('Liveness Check Onboarding Navigation') }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SidebarNav;
