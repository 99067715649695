import React from 'react'
import classNames from 'classnames'
import Icon from '../../Icon'

const Suggestion = ({item}) => {
  return (
    <a href={item.url} className="search__suggestion">
      <div className="search__icon">
        <Icon
          className={classNames('icon-24', item.icon)}
          name={item.icon}
          size="24"
        />
      </div>

      <div className="search__details">
        <div className="search__content">
          {item.content}
        </div>
        <div className="search__title">
          {item.title}
        </div>
      </div>

      <div className="search__arrow">
        <Icon
          className={classNames('icon-24', 'arrow-next')}
          name="arrow-next"
          size="24"
        />
      </div>
    </a>
  );
}

export default Suggestion;
