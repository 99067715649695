import React, { useState } from "react"
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'
import reduce from 'lodash/reduce'
import { CircularProgress, TextField, MenuItem } from '@material-ui/core'
import Icon from '../../../components/Icon'

const availableLanguageOptions = [
  { value: 'en', label: 'English' },
  { value: 'de', label: 'German'},
  { value: 'it', label: 'Italy' }
]

const SETS = {
  site_locales: {
    default: '',
  },
  default_locale: {
    default: '',
  },
};

const AdminSettingsSiteLanguages = ({
  settings,
  loading,
  error,
  onUpdate,
  editingAllowed,
  refetch
}) => {
  const { t } = useTranslation();

  const [values, setValues] = useState({
    ...reduce(
      SETS,
      (memo, value, key) => {
        // eslint-disable-next-line no-param-reassign
        memo[key] = settings[key] !== null ? settings[key] : SETS[key].default

        return memo
      },
      {}
    ),
  })

  const [checkedLanguage, setCheckedLanguage] = useState((values.site_locales || '').split(',').filter(Boolean).map(x => String(x).trim()))
  console.log("checkedLanguage", checkedLanguage)
  const handleCheckLanguage = (e) => {
    let updateListLanguage = [...checkedLanguage]
    if (e.target.checked) {
      updateListLanguage = [...checkedLanguage, e.target.value]
    } else {
      updateListLanguage.splice(checkedLanguage.indexOf(e.target.value), 1)
    }
    setCheckedLanguage(updateListLanguage);
  }

  const handleChangeDefaultLanguage = (event) => {
    let newValue = event.target.value

    setValues({ ...values, default_locale: newValue });
  };

  const handleSubmit = () => {
    const newValues = {...values, site_locales: checkedLanguage.join(',')}
    onUpdate(newValues)
  }

  return (
    <>
      <div className="settings__item">
        <div className="title-primary settings__title">{t('Site Languages')}</div>

        <div className="settings__fieldset">
          <div className="field field__checkbox-kyc field__checkbox-group_vert field__checkbox-kyc-gray">
            <div className="field__label">
              {t('Choose Languages')}
              <span
                className="tooltip"
                data-tip={t('Make optional language on the site for user')}
                data-for="choose_languages"
              >
                <Icon
                  className={classNames('info')}
                  name="info"
                  size="16"
                />
              </span>
              <ReactTooltip id="choose_languages" place="bottom" padding="24px" className="popover-tooltip" />
            </div>

            <div className="field__wrap d__flex flex__row flex__wrap">
              {availableLanguageOptions.map((option) => (
                <label className="checkbox" key={option.value}>
                  <input
                    type="checkbox"
                    className="checkbox__input"
                    value={option.value}
                    checked={checkedLanguage.includes(option.value)}
                    onChange={handleCheckLanguage}
                  />
                  <span className="checkbox__inner">
                    <span className="checkbox__tick">&nbsp;</span>
                    <span className="checkbox__text">{t(option.label)}</span>
                  </span>
                </label>
              ))}
            </div>
          </div>

          {checkedLanguage.length >= 1 && (
            <div className="field">
              <div className="field__label">
                {t('Set Default Language')}
              </div>

              <div className="field__wrap field__wrap-select">
                <TextField
                  select
                  onChange={handleChangeDefaultLanguage}
                  value={values.default_locale}
                >
                  {availableLanguageOptions.filter(lang => checkedLanguage.includes(lang.value)).map(language => (
                    <MenuItem key={language.value} value={language.value} style={{textTransform: "capitalize"}}>{t(language.label)}</MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          )}

          {loading ? (
            <div className="settings__item">
              <CircularProgress />
            </div>
          ) : (
            <button
              disabled={!editingAllowed}
              className="button settings__button"
              onClick={handleSubmit}
            >
              {t('Update')}
            </button>
          )}

        </div>
      </div>
    </>
  )
}

export default AdminSettingsSiteLanguages
