import React, { useState } from 'react'
import cn from 'classnames'
import { useMutation } from '@apollo/react-hooks/lib/index'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import { PUSH_EXCHANGE_RATES } from '../queriesAndMutations'
import Input from './Input'
import { toaster } from '../utils/toaster'
import { useMe } from '../myHooks'
import { useTranslation } from 'react-i18next'
import Icon from './Icon'
import ModalInnerMaterialDialog from './ModalInnerMaterialDialog'
import InputTheme from './InputTheme'

const AdminExchangeRatesForm = ({ exchangeRates, refetch, editingAllowed }) => {
  const ratesNames = ['token_to_eth', 'eth_to_usd', 'eth_to_eur', 'eth_to_chf', 'eth_to_usdt', 'eth_to_xdc', 'eth_to_polygon', 'eth_to_dai']
  const { data: { me = {} } = {} } = useMe()
  const [pushRates, { error, loading }] = useMutation(PUSH_EXCHANGE_RATES)
  const [twoFaDialogOpened, setTwoFaDialogOpened] = useState(false)
  const changeTwoFADialogState = (state) => () => setTwoFaDialogOpened(state)
  const { t } = useTranslation();

  const [values, setValues] = useState({
    ...(ratesNames.reduce((accumulator, value) => ({
      ...accumulator,
      [value]: String((exchangeRates || {})[value] || 0),
    }), {
      two_fa_token: '',
    })),
  })

  const onUpdate = () => {
    if (me.is_2fa_enabled === false) {
      toaster.error('Please, enable 2fa first')
    }
    setTwoFaDialogOpened(true)
  }

  const onSend = async () => {
    pushRates({ variables: { input: values } }).then(() => {
      setTwoFaDialogOpened(false)
      setValues({
        ...values,
        two_fa_token: '',
      })
      refetch()
    }).catch(() => {})
  }

  return (
    <>
      <Dialog open={twoFaDialogOpened} onClose={changeTwoFADialogState(false)}>
        <ModalInnerMaterialDialog onClickClose={changeTwoFADialogState(false)} modalTitle={t('Action')} modalTitleClassColor="title-primary">
          <InputTheme
            propertyName="two_fa_token"
            classWrapper="field"
            classLabel="field__label"
            classInput="field__input"
            label="2FA token"
            state={values}
            setState={setValues}
            error={error}
            loading={loading}
          />
          <div className="space space__32">{' '}</div>
          <button className="button-stroke button-small" onClick={onSend}>
            { t('Update') }
          </button>
        </ModalInnerMaterialDialog>
      </Dialog>

      <div className="card">
        <div className="card__head">
          <div className="title-primary card__title">{ t('Overview') }</div>
        </div>

        <div className="stock">
          <div className="stock__list exchange__list exchange__list_column_3 flex__row flex__wrap">
            {ratesNames.map((rateName) => (
              <div className="stock__item" key={rateName}>
                <div className="stock__icon">
                  <Icon
                    className={cn('activity')}
                    name='activity'
                    size="24"
                  />{" "}
                </div>

                <div className="stock_line">
                  <div className="stock__details">
                    <div className="stock__category">
                      {rateName}
                    </div>

                    <div className="stock__counter stock__exchange">
                      <Input
                        propertyName={rateName}
                        type="number"
                        state={values}
                        setState={setValues}
                        error={error}
                        disabled={!editingAllowed}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {
            loading
              ? <div className="stock__exchange_footer"><CircularProgress /></div>
              : (
                <>
                  <div className="stock__exchange_footer d__flex flex__row flex__wrap flex__justify_end">
                    <button
                      className="button button-small"
                      disabled={!editingAllowed}
                      onClick={onUpdate}
                    >
                      { t('Update') }
                    </button>
                  </div>

                  <div className="tablet-show">
                    <div className="space space__32">{' '}</div>
                  </div>
                </>
              )
          }
        </div>
      </div>
    </>
  )
}

export default AdminExchangeRatesForm
