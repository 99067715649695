import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMe } from '../myHooks'
import CardInformation from './InformationCard'

const AlertUpdateDirector = () => {
  const { data: { me } = {} } = useMe()

  const { t } = useTranslation();

  return (
    <div className="card p__0">
      {me && me.customerCompanyDirector && me.customerCompanyDirector.map(director => {
        if (director.status !== 'REOPEN') {
          return null
        }

        return (
          <CardInformation
            key={director.id}
            color="card__information-red"
            title={t('Your director profile needs attention!')}
          >
            <>
              { t('Here is the information what you need to change') }: {director.reopenComment}.
              <br />
              {" "}
              <br />
              <NavLink to={`/director-profile/${director.id}`} className="button-stroke button-stroke__white">
                { t('Click here to update')}
              </NavLink>
            </>
          </CardInformation>
        )
      })}
    </div>
  );
};

export default AlertUpdateDirector;
