// eslint-disable-next-line import/prefer-default-export
export const COUNTRY_BY_ALPHA_2_CODE = {
  'Afghanistan': 'AF',
  'Aland Islands': 'AX',
  'Albania': 'AL',
  'Algeria': 'DZ',
  'American Samoa': 'AS',
  'Andorra': 'AD',
  'Angola': 'AO',
  'Anguilla': 'AI',
  'Antarctica': 'AQ',
  'Antigua And Barbuda': 'AG',
  'Argentina': 'AR',
  'Armenia': 'AM',
  'Aruba': 'AW',
  'Australia': 'AU',
  'Austria': 'AT',
  'Azerbaijan': 'AZ',
  'Bahamas': 'BS',
  'Bahrain': 'BH',
  'Bangladesh': 'BD',
  'Barbados': 'BB',
  'Belarus': 'BY',
  'Belgium': 'BE',
  'Belize': 'BZ',
  'Benin': 'BJ',
  'Bermuda': 'BM',
  'Bhutan': 'BT',
  'Bolivia': 'BO',
  'Bosnia And Herzegovina': 'BA',
  'Botswana': 'BW',
  'Bouvet Island': 'BV',
  'Brazil': 'BR',
  'British Indian Ocean Territory': 'IO',
  'Brunei Darussalam': 'BN',
  'Bulgaria': 'BG',
  'Burkina Faso': 'BF',
  'Burundi': 'BI',
  'Cambodia': 'KH',
  'Cameroon': 'CM',
  'Canada': 'CA',
  'Cape Verde': 'CV',
  'Cayman Islands': 'KY',
  'Central African Republic': 'CF',
  'Chad': 'TD',
  'Chile': 'CL',
  'China': 'CN',
  'Christmas Island': 'CX',
  'Cocos (Keeling) Islands': 'CC',
  'Colombia': 'CO',
  'Comoros': 'KM',
  'Congo': 'CG',
  'Congo, Democratic Republic': 'CD',
  'Cook Islands': 'CK',
  'Costa Rica': 'CR',
  'Cote D\'Ivoire': 'CI',
  'Croatia': 'HR',
  'Cuba': 'CU',
  'Cyprus': 'CY',
  'Czech Republic': 'CZ',
  'Denmark': 'DK',
  'Djibouti': 'DJ',
  'Dominica': 'DM',
  'Dominican Republic': 'DO',
  'Ecuador': 'EC',
  'Egypt': 'EG',
  'El Salvador': 'SV',
  'Equatorial Guinea': 'GQ',
  'Eritrea': 'ER',
  'Estonia': 'EE',
  'Ethiopia': 'ET',
  'Falkland Islands (Malvinas)': 'FK',
  'Faroe Islands': 'FO',
  'Fiji': 'FJ',
  'Finland': 'FI',
  'France': 'FR',
  'French Guiana': 'GF',
  'French Polynesia': 'PF',
  'French Southern Territories': 'TF',
  'Gabon': 'GA',
  'Gambia': 'GM',
  'Georgia': 'GE',
  'Germany': 'DE',
  'Ghana': 'GH',
  'Gibraltar': 'GI',
  'Greece': 'GR',
  'Greenland': 'GL',
  'Grenada': 'GD',
  'Guadeloupe': 'GP',
  'Guam': 'GU',
  'Guatemala': 'GT',
  'Guernsey': 'GG',
  'Guinea': 'GN',
  'Guinea-Bissau': 'GW',
  'Guyana': 'GY',
  'Haiti': 'HT',
  'Heard Island & Mcdonald Islands': 'HM',
  'Holy See (Vatican City State)': 'VA',
  'Honduras': 'HN',
  'Hong Kong': 'HK',
  'Hungary': 'HU',
  'Iceland': 'IS',
  'India': 'IN',
  'Indonesia': 'ID',
  'Iran, Islamic Republic Of': 'IR',
  'Iraq': 'IQ',
  'Ireland': 'IE',
  'Isle Of Man': 'IM',
  'Israel': 'IL',
  'Italy': 'IT',
  'Jamaica': 'JM',
  'Japan': 'JP',
  'Jersey': 'JE',
  'Jordan': 'JO',
  'Kazakhstan': 'KZ',
  'Kenya': 'KE',
  'Kiribati': 'KI',
  'Korea': 'KR',
  'Kuwait': 'KW',
  'Kyrgyzstan': 'KG',
  'Lao People\'s Democratic Republic': 'LA',
  'Latvia': 'LV',
  'Lebanon': 'LB',
  'Lesotho': 'LS',
  'Liberia': 'LR',
  'Libyan Arab Jamahiriya': 'LY',
  'Liechtenstein': 'LI',
  'Lithuania': 'LT',
  'Luxembourg': 'LU',
  'Macao': 'MO',
  'Macedonia': 'MK',
  'Madagascar': 'MG',
  'Malawi': 'MW',
  'Malaysia': 'MY',
  'Maldives': 'MV',
  'Mali': 'ML',
  'Malta': 'MT',
  'Marshall Islands': 'MH',
  'Martinique': 'MQ',
  'Mauritania': 'MR',
  'Mauritius': 'MU',
  'Mayotte': 'YT',
  'Mexico': 'MX',
  'Micronesia, Federated States Of': 'FM',
  'Moldova': 'MD',
  'Monaco': 'MC',
  'Mongolia': 'MN',
  'Montenegro': 'ME',
  'Montserrat': 'MS',
  'Morocco': 'MA',
  'Mozambique': 'MZ',
  'Myanmar': 'MM',
  'Namibia': 'NA',
  'Nauru': 'NR',
  'Nepal': 'NP',
  'Netherlands': 'NL',
  'Netherlands Antilles': 'AN',
  'New Caledonia': 'NC',
  'New Zealand': 'NZ',
  'Nicaragua': 'NI',
  'Niger': 'NE',
  'Nigeria': 'NG',
  'Niue': 'NU',
  'Norfolk Island': 'NF',
  'Northern Mariana Islands': 'MP',
  'Norway': 'NO',
  'Oman': 'OM',
  'Pakistan': 'PK',
  'Palau': 'PW',
  'Palestinian Territory, Occupied': 'PS',
  'Panama': 'PA',
  'Papua New Guinea': 'PG',
  'Paraguay': 'PY',
  'Peru': 'PE',
  'Philippines': 'PH',
  'Pitcairn': 'PN',
  'Poland': 'PL',
  'Portugal': 'PT',
  'Puerto Rico': 'PR',
  'Qatar': 'QA',
  'Reunion': 'RE',
  'Romania': 'RO',
  'Russian Federation': 'RU',
  'Rwanda': 'RW',
  'Saint Barthelemy': 'BL',
  'Saint Helena': 'SH',
  'Saint Kitts And Nevis': 'KN',
  'Saint Lucia': 'LC',
  'Saint Martin': 'MF',
  'Saint Pierre And Miquelon': 'PM',
  'Saint Vincent And Grenadines': 'VC',
  'Samoa': 'WS',
  'San Marino': 'SM',
  'Sao Tome And Principe': 'ST',
  'Saudi Arabia': 'SA',
  'Senegal': 'SN',
  'Serbia': 'RS',
  'Seychelles': 'SC',
  'Sierra Leone': 'SL',
  'Singapore': 'SG',
  'Slovakia': 'SK',
  'Slovenia': 'SI',
  'Solomon Islands': 'SB',
  'Somalia': 'SO',
  'South Africa': 'ZA',
  'South Georgia And Sandwich Isl.': 'GS',
  'Spain': 'ES',
  'Sri Lanka': 'LK',
  'Sudan': 'SD',
  'Suriname': 'SR',
  'Svalbard And Jan Mayen': 'SJ',
  'Swaziland': 'SZ',
  'Sweden': 'SE',
  'Switzerland': 'CH',
  'Syrian Arab Republic': 'SY',
  'Taiwan': 'TW',
  'Tajikistan': 'TJ',
  'Tanzania': 'TZ',
  'Thailand': 'TH',
  'Timor-Leste': 'TL',
  'Togo': 'TG',
  'Tokelau': 'TK',
  'Tonga': 'TO',
  'Trinidad And Tobago': 'TT',
  'Tunisia': 'TN',
  'Turkey': 'TR',
  'Turkmenistan': 'TM',
  'Turks And Caicos Islands': 'TC',
  'Tuvalu': 'TV',
  'Uganda': 'UG',
  'Ukraine': 'UA',
  'United Arab Emirates': 'AE',
  'United Kingdom': 'GB',
  'United States': 'US',
  'United States Outlying Islands': 'UM',
  'Uruguay': 'UY',
  'Uzbekistan': 'UZ',
  'Vanuatu': 'VU',
  'Venezuela': 'VE',
  'Viet Nam': 'VN',
  'Virgin Islands, British': 'VG',
  'Virgin Islands, U.S.': 'VI',
  'Wallis And Futuna': 'WF',
  'Western Sahara': 'EH',
  'Yemen': 'YE',
  'Zambia': 'ZM',
  'Zimbabwe': 'ZW',
}

export const COUNTRY_BY_ALPHA_3_CODE = {
  Aruba: 'ABW',
  Afghanistan: 'AFG',
  Angola: 'AGO',
  Anguilla: 'AIA',
  'Åland Islands': 'ALA',
  Albania: 'ALB',
  Andorra: 'AND',
  'United Arab Emirates': 'ARE',
  Argentina: 'ARG',
  Armenia: 'ARM',
  'American Samoa': 'ASM',
  Antarctica: 'ATA',
  'French Southern Territories': 'ATF',
  'Antigua and Barbuda': 'ATG',
  Australia: 'AUS',
  Austria: 'AUT',
  Azerbaijan: 'AZE',
  Burundi: 'BDI',
  Belgium: 'BEL',
  Benin: 'BEN',
  'Bonaire, Sint Eustatius and Saba': 'BES',
  'Burkina Faso': 'BFA',
  Bangladesh: 'BGD',
  Bulgaria: 'BGR',
  Bahrain: 'BHR',
  Bahamas: 'BHS',
  'Bosnia and Herzegovina': 'BIH',
  'Saint Barthélemy': 'BLM',
  Belarus: 'BLR',
  Belize: 'BLZ',
  Bermuda: 'BMU',
  Bolivia: 'BOL',
  Brazil: 'BRA',
  Barbados: 'BRB',
  'Brunei Darussalam': 'BRN',
  Bhutan: 'BTN',
  'Bouvet Island': 'BVT',
  Botswana: 'BWA',
  'Central African Republic': 'CAF',
  Canada: 'CAN',
  'Cocos (Keeling) Islands': 'CCK',
  Switzerland: 'CHE',
  Chile: 'CHL',
  China: 'CHN',
  "Côte d'Ivoire": 'CIV',
  Cameroon: 'CMR',
  'Congo, Democratic Republic of the': 'COD',
  Congo: 'COG',
  'Cook Islands': 'COK',
  Colombia: 'COL',
  Comoros: 'COM',
  'Cabo Verde': 'CPV',
  'Costa Rica': 'CRI',
  Cuba: 'CUB',
  'Curaçao': 'CUW',
  'Christmas Island': 'CXR',
  'Cayman Islands': 'CYM',
  Cyprus: 'CYP',
  Czechia: 'CZE',
  Germany: 'DEU',
  Djibouti: 'DJI',
  Dominica: 'DMA',
  Denmark: 'DNK',
  'Dominican Republic': 'DOM',
  Algeria: 'DZA',
  Ecuador: 'ECU',
  Egypt: 'EGY',
  Eritrea: 'ERI',
  'Western Sahara': 'ESH',
  Spain: 'ESP',
  Estonia: 'EST',
  Ethiopia: 'ETH',
  Finland: 'FIN',
  Fiji: 'FJI',
  'Falkland Islands Malvinas': 'FLK',
  France: 'FRA',
  'Faroe Islands': 'FRO',
  'Micronesia Federated States of': 'FSM',
  Gabon: 'GAB',
  'United Kingdom': 'GBR',
  Georgia: 'GEO',
  Guernsey: 'GGY',
  Ghana: 'GHA',
  Gibraltar: 'GIB',
  Guinea: 'GIN',
  Guadeloupe: 'GLP',
  Gambia: 'GMB',
  'Guinea-Bissau': 'GNB',
  'Equatorial Guinea': 'GNQ',
  Greece: 'GRC',
  Grenada: 'GRD',
  Greenland: 'GRL',
  Guatemala: 'GTM',
  'French Guiana': 'GUF',
  Guam: 'GUM',
  Guyana: 'GUY',
  'Hong Kong': 'HKG',
  'Heard Island and McDonald Islands': 'HMD',
  Honduras: 'HND',
  Croatia: 'HRV',
  Haiti: 'HTI',
  Hungary: 'HUN',
  Indonesia: 'IDN',
  'Isle of Man': 'IMN',
  India: 'IND',
  'British Indian Ocean Territory': 'IOT',
  Ireland: 'IRL',
  'Iran Islamic Republic of': 'IRN',
  Iraq: 'IRQ',
  Iceland: 'ISL',
  Israel: 'ISR',
  Italy: 'ITA',
  Jamaica: 'JAM',
  Jersey: 'JEY',
  Jordan: 'JOR',
  Japan: 'JPN',
  Kazakhstan: 'KAZ',
  Kenya: 'KEN',
  Kyrgyzstan: 'KGZ',
  Cambodia: 'KHM',
  Kiribati: 'KIR',
  'Saint Kitts and Nevis': 'KNA',
  'Korea, Republic of': 'KOR',
  Kuwait: 'KWT',
  'Lao Peoples Democratic Republic': 'LAO',
  Lebanon: 'LBN',
  Liberia: 'LBR',
  Libya: 'LBY',
  'Saint Lucia': 'LCA',
  Liechtenstein: 'LIE',
  'Sri Lanka': 'LKA',
  Lesotho: 'LSO',
  Lithuania: 'LTU',
  Luxembourg: 'LUX',
  Latvia: 'LVA',
  Macao: 'MAC',
  'Saint Martin': 'MAF',
  Morocco: 'MAR',
  Monaco: 'MCO',
  'Moldova, Republic of': 'MDA',
  Madagascar: 'MDG',
  Maldives: 'MDV',
  Mexico: 'MEX',
  'Marshall Islands': 'MHL',
  'North Macedonia': 'MKD',
  Mali: 'MLI',
  Malta: 'MLT',
  Myanmar: 'MMR',
  Montenegro: 'MNE',
  Mongolia: 'MNG',
  'Northern Mariana Islands': 'MNP',
  Mozambique: 'MOZ',
  Mauritania: 'MRT',
  Montserrat: 'MSR',
  Martinique: 'MTQ',
  Mauritius: 'MUS',
  Malawi: 'MWI',
  Malaysia: 'MYS',
  Mayotte: 'MYT',
  Namibia: 'NAM',
  'New Caledonia': 'NCL',
  Niger: 'NER',
  'Norfolk Island': 'NFK',
  Nigeria: 'NGA',
  Nicaragua: 'NIC',
  Niue: 'NIU',
  Netherlands: 'NLD',
  Norway: 'NOR',
  Nepal: 'NPL',
  Nauru: 'NRU',
  'New Zealand': 'NZL',
  Oman: 'OMN',
  Pakistan: 'PAK',
  Panama: 'PAN',
  Pitcairn: 'PCN',
  Peru: 'PER',
  Philippines: 'PHL',
  Palau: 'PLW',
  'Papua New Guinea': 'PNG',
  Poland: 'POL',
  'Puerto Rico': 'PRI',
  "Korea (Democratic People's Republic of)": 'PRK',
  Portugal: 'PRT',
  Paraguay: 'PRY',
  'Palestine, State of': 'PSE',
  'French Polynesia': 'PYF',
  Qatar: 'QAT',
  'Réunion': 'REU',
  Romania: 'ROU',
  'Russian Federation': 'RUS',
  Rwanda: 'RWA',
  'Saudi Arabia': 'SAU',
  Sudan: 'SDN',
  Senegal: 'SEN',
  Singapore: 'SGP',
  'South Georgia and the South Sandwich Islands': 'SGS',
  'Saint Helena, Ascension and Tristan da Cunha': 'SHN',
  'Svalbard and Jan Mayen': 'SJM',
  'Solomon Islands': 'SLB',
  'Sierra Leone': 'SLE',
  'El Salvador': 'SLV',
  'San Marino': 'SMR',
  Somalia: 'SOM',
  'Saint Pierre and Miquelon': 'SPM',
  Serbia: 'SRB',
  'South Sudan': 'SSD',
  'Sao Tome and Principe': 'STP',
  Suriname: 'SUR',
  Slovakia: 'SVK',
  Slovenia: 'SVN',
  Sweden: 'SWE',
  Eswatini: 'SWZ',
  'Sint Maarten (Dutch part)': 'SXM',
  Seychelles: 'SYC',
  'Syrian Arab Republic': 'SYR',
  'Turks and Caicos Islands': 'TCA',
  Chad: 'TCD',
  Togo: 'TGO',
  Thailand: 'THA',
  Tajikistan: 'TJK',
  Tokelau: 'TKL',
  Turkmenistan: 'TKM',
  'Timor-Leste': 'TLS',
  Tonga: 'TON',
  'Trinidad and Tobago': 'TTO',
  Tunisia: 'TUN',
  Turkey: 'TUR',
  Tuvalu: 'TUV',
  'Taiwan, Province of China': 'TWN',
  'Tanzania, United Republic of': 'TZA',
  Uganda: 'UGA',
  Ukraine: 'UKR',
  'United States Minor Outlying Islands': 'UMI',
  Uruguay: 'URY',
  'United States': 'USA',
  Uzbekistan: 'UZB',
  'Holy See': 'VAT',
  'Saint Vincent and the Grenadines': 'VCT',
  'Venezuela Bolivarian Republic of': 'VEN',
  'Virgin Islands British': 'VGB',
  'Virgin Islands US': 'VIR',
  'Viet Nam': 'VNM',
  Vanuatu: 'VUT',
  'Wallis and Futuna': 'WLF',
  Samoa: 'WSM',
  Yemen: 'YEM',
  'South Africa': 'ZAF',
  Zambia: 'ZMB',
  Zimbabwe: 'ZWE'
}
