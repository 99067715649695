import { ApolloLink } from 'apollo-link'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'
import { toaster, extractMsgFromGraphqlError } from './utils'
import { getCurrentLocalLcid } from './utils/lang'
import config from './config'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    setTimeout(() => {
      graphQLErrors.forEach((value) => {
        // eslint-disable-next-line no-console
        if (value.extensions && value.extensions.code === 'UNAUTHENTICATED') {
          return;
        }
        const gqlErrors = extractMsgFromGraphqlError(value)
        console.log('gqlErrors in apolloClient.js', gqlErrors)

        gqlErrors.forEach((error) => toaster.error(`${error}`))

        console.log('[GraphQL error]: ', value)

        if (gqlErrors && gqlErrors.length === 0) {
          toaster.error(`${(value || {}).message}`)
        }
        // toaster.error(`[GraphQL error]: ${(value || {}).message}`)
      })
    }, 100)
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`)
    toaster.error(`[Network error]: ${networkError.message}`)
  }
})

const uploadLink = createUploadLink({
  uri: config.endpoint,
})

const headersMiddleware = setContext(({ headers = {} }) => ({
  headers: {
    ...headers,
    locale: getCurrentLocalLcid() || 'en-GB',
    authorization: localStorage.getItem('jwt')
      ? localStorage.getItem('jwt')
      // eslint-disable-next-line
      : document.cookie.replace(/(?:(?:^|.*;\s*)jwt\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
    ...(document.cookie.replace(/(?:(?:^|.*;\s*)chatSession\s*=\s*([^;]*).*$)|^.*$/, "$1")
      ? { 'chat-session': document.cookie.replace(/(?:(?:^|.*;\s*)chatSession\s*=\s*([^;]*).*$)|^.*$/, "$1") }
      : {}
    ),
  },
}))

const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    headersMiddleware,
    uploadLink,
  ]),
  cache: new InMemoryCache(),
})

export default client
