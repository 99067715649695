import React from 'react'
import classNames from 'classnames'
import { getFileUrl } from '../../utils'
import { usePublicSettings } from '../../myHooks/useSettings'
import styles from './styles.module.scss'

const LoadingLogo = () => {
  const { data: {publicSettings } = {} } = usePublicSettings();
  return (
    <div className={classNames('page-loading', styles.module__page_loading)}>

      <div className={classNames('page-loading__logo', styles.module__loading_wrapper)}>
        <div className={classNames('loading__circle', styles.module__loading_circle)}>{' '}</div>
        <img
          src={getFileUrl((publicSettings || {}).logo_path)}
          alt="logo"
          className="some-icon"
        />

        <img
          src={getFileUrl((publicSettings || {}).logo_for_dark_bg_path || (publicSettings || {}).logo_path)}
          alt="logo"
          className="some-icon-dark"
        />
      </div>

    </div>
  )
}

export default LoadingLogo
