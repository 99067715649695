import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ModalInnerMaterialDialog from './ModalInnerMaterialDialog'
import InputThemeField from './InputThemeField'
import {
  CircularProgress,
  Dialog,
} from '@material-ui/core'

const TwoFactorAuthConfirmDialog = ({
  open,
  onClose,
  loading,
  onSubmit,
}) => {
  const [token, setToken] = useState('');
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <ModalInnerMaterialDialog
        onClickClose={onClose}
        modalTitle={t('2FA Confirm')}
        modalTitleClassColor="title-primary"
      >
        <div className="settings__item">
          <div className="settings__fieldset">
            <InputThemeField
              classWrapper="field"
              classLabel="field__label"
              classInput="field__input"
              label={t('2FA token')}
              value={token}
              onChange={({ target: { value } }) => setToken(value)}
            />

            {
              loading
                ? <CircularProgress />
                : (
                  <div className="field">
                    <button className="button" onClick={() => { onSubmit(token); setToken('')} }>
                      { t('Submit') }
                    </button>
                  </div>
                )
            }
          </div>
        </div>
      </ModalInnerMaterialDialog>

    </Dialog>
  )
}

TwoFactorAuthConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default TwoFactorAuthConfirmDialog
