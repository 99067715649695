import React, { useState, useEffect } from 'react'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import {
  CircularProgress
} from '@material-ui/core'

import {
  CHECK_WALLET_COMPENSATION,
  CONFIRM_LIQUIDATION_WALLET,
} from '../queriesAndMutations'
import { useMe } from '../myHooks'
import metamaskService from '../services/metamask'
import { toaster } from '../utils'

import InputTheme from './InputTheme'

const WalletValidationForm = ({
  onValidateSuccess,
}) => {
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [values, setValues] = useState({
    wallet: ''
  });
  const [checkWalletCompensation, checkWalletCompensationStatus] = useLazyQuery(CHECK_WALLET_COMPENSATION)
  const [confirmLiquidationWallet, confirmLiquidationWalletStatus] = useMutation(CONFIRM_LIQUIDATION_WALLET)
  const { data: { me } = {} } = useMe()
  const sendCheckWalletCompensationRequest = () => {
    checkWalletCompensation({
      variables: {
        walletAddress: values.wallet,
      },
    })
  }
  const confirmWalletAndSend = async () => {
    setDisableSubmit(true)
    const message = 'Please sign this message to confirm the ownership of provided wallet '

    try {
      await metamaskService.requestAccounts()

      const signature = await metamaskService.signMessage(
        metamaskService.getCurrentAddress(),
        message
      )
      const signerAddress = await metamaskService.getSigner(signature, message)

      if (signerAddress === values.wallet.toLowerCase()) {
        confirmLiquidationWallet({
          variables: {
            walletAddress: values.wallet,
          },
        }).then(({ data: { confirmLiquidationWallet: success } }) => {
          console.log(success)
          if (success) {
            onValidateSuccess()
          }
        }).catch(() => {})
      } else {
        toaster.error('The wallet you provided does not match with the current wallet on Metamask.')
      }

      setDisableSubmit(false)
    } catch (error) {
      switch (error.code) {
        case 4001:
          toaster.error('You need to select at least the account with the provided address to connect')
          break
        default:
          toaster.error(error.message)
          break
      }
      setDisableSubmit(false)
    }
  }

  useEffect(() => {
    if (me && me.wallet_address) {
      setValues({ wallet: me.wallet_address})
    }
  }, [me, setValues])

  return (
    <div className="wallet-compensation-calculator">
      <InputTheme
        classWrapper="field"
        classLabel="field__label"
        classInput="field__input"
        propertyName="wallet"
        label="ETH Wallet"
        state={values}
        setState={setValues}
      />
      {checkWalletCompensationStatus &&
        checkWalletCompensationStatus.data &&
        !confirmLiquidationWalletStatus.loading && (
        <>
          <br />
          <p>
            <strong>Compensation: </strong>
            { checkWalletCompensationStatus.data.checkWalletCompensation.compensation }
            {' USDC'}
          </p>

          {checkWalletCompensationStatus.data.checkWalletCompensation.compensation > 0 && (
            <>
              <br />
              <button
                type="button"
                className="button"
                onClick={confirmWalletAndSend}
                disabled={disableSubmit}
              >
                Use this wallet
              </button>
            </>
          )}
        </>
      )}
      {(checkWalletCompensationStatus.loading || confirmLiquidationWalletStatus.loading) && (
        <>
          <br />
          <CircularProgress />
        </>
      )}
      {!checkWalletCompensationStatus.loading && (
        !checkWalletCompensationStatus.data ||
        checkWalletCompensationStatus.data.checkWalletCompensation.compensation <= 0
      ) && (
        <>
          <br />
          <button type="button" className="button" onClick={sendCheckWalletCompensationRequest}>Check compensation</button>
        </>
      )}
    </div>
  )
}

export default WalletValidationForm
