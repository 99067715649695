import React from 'react'
import AdminKycList from '../components/AdminKycList'
import { CUSTOMER_TYPES } from '../constants/customer'

import { useTranslation } from 'react-i18next'

const AdminIndividualKyc = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page__title h3">{ t('Individual KYC') }</div>

      <AdminKycList type={CUSTOMER_TYPES.INDIVIDUAL} />
    </>
  );
};

export default AdminIndividualKyc;
