// eslint-disable-next-line import/prefer-default-export
export const getQueryVars = () => {
  const queryParam = new URLSearchParams(window.location.search);

  return Object.fromEntries(queryParam)
}

/**
 * @param {Object} data
 * @return {String} a=1&b=2
 */
export const createQueryString = (data) => {
  const searchParams = new URLSearchParams(data)

  return searchParams.toString()
}

export const getTextAvatarUrl = (name, background, color) => {
  const params = {
    name,
    background: background || '0066FF',
    color: color || 'FFFFFF',
  }

  return `https://ui-avatars.com/api/?${createQueryString(params)}`
}
