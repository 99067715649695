import React from 'react'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { usePublicSettings } from '../myHooks'
import { TOKEN_TYPES } from '../constants/settings'

import WalletValidationForm from '../components/WalletValidationForm'

const WalletValidate = () => {
  const { t } = useTranslation()
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  const getTokenType = () => {
    if (publicSettings) {
      return publicSettings.token_type
    }

    return null
  }
  const redirectToKycPage = () => {
    window.location.href = `${window.location.origin}/application`
  }

  if (getTokenType() && getTokenType() !== TOKEN_TYPES.LIQUIDATION) {
    return <Redirect to="/" />
  }

  return (
    <div className="card">
      <div className="card__head">
        <div className="title-blue card__title">{ t('Wallet Validator') }</div>
      </div>

      <WalletValidationForm
        onValidateSuccess={redirectToKycPage}
      />
    </div>
  )
}

export default WalletValidate
