import React from 'react'
import classNames from 'classnames'

const InformationCard = ({
  title,
  color,
  children,
}) => {
  return (
    <div className={classNames('card__information', color)}>
      {title && (
        <div className="h5 card__information_title">
          {title}
        </div>
      )}

      {children && (
        <div className="card__information_content">
          {children}
        </div>
      )}
    </div>
  );
}

export default InformationCard;
