import gql from 'graphql-tag'

export const GET_TOKEN_SWAP_LIST = gql`
  query getTokenSwapList($input: GetTokenSwapListInput!) {
    getTokenSwapList(input: $input) {
      meta {
        page
        pageSize
        totalPages
        total
      }
      results {
        id
        email
        oldTokenAmount
        newTokenAmount
      }
    }
  }
`;

export const GET_TOKEN_SWAP_IMPORT_LOG_LIST = gql`
  query getTokenSwapImportLogList($input: GetTokenSwapImportLogListInput!) {
    getTokenSwapImportLogList(input: $input) {
      meta {
        page
        pageSize
        totalPages
        total
      }
      results {
        id
        source
        status
        detail
        created_at
        user {
          id
          email
        }
      }
    }
  }
`;

export const GET_MY_TOKEN_SWAP = gql`
  query getMyTokenSwap {
    getMyTokenSwap {
      id
      email
      oldTokenAmount
      newTokenAmount
    }
  }
`;
