import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { usePublicSettings } from "../../../myHooks/useSettings";
import * as languageHelper from '../../../utils/lang'
import i18n from 'i18next'

const Footer = () => {
  const { t } = useTranslation();
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { company = {} } = publicSettings || {};
  const siteLocales = ((publicSettings || {}).site_locales || '').split(',').filter(Boolean).map(x => String(x).trim())

  return (
    <div className="login entry__signin">
      <div className="login__footer">
        <div className="login__info login__info-kyc login__footer_translation">
          <span>{ t('Choose Language') }:</span>

          {Object.values(languageHelper.supportedLanguages).filter((lang) => siteLocales.includes(lang.code)).map(lang => (
            <NavLink
              key={lang.code}
              to="#"
              className={"lang-switch-btn toggle-trigger " + (languageHelper.getLocale((publicSettings || {}).default_locale) === lang.code ? 'has-active' : '')}
              onClick={() => {languageHelper.setLocale(lang.code); i18n.changeLanguage(lang.code)}}
            >
              { lang.label }
            </NavLink>
          ))}
        </div>

        <div className="login__info login__info-kyc login__footer_copyright">
          <span>
            &copy; {new Date().getFullYear()} {(company || {}).name}
          </span>
          {!['kyc.cryptoadvisory.li', 'dev-kyc.cryptoadvisory.li'].includes(window.location.host) && (
            <>
              <Link to="/privacy-policy" target="_blank">
                { t('Privacy Policy') }
              </Link>
              <Link to="/terms" target="_blank">
                { t('Terms')}
              </Link>
            </>
          ) }
        </div>
      </div>
    </div>
  )
}

export default Footer;
