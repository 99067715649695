import classNames from 'classnames';
import React from 'react'

const PackageItem = ({
  // eslint-disable-next-line react/prop-types
  onClick,
  classTitle,
  title,
  content,
  imgPath,
  buttonPackage,
}) => {
  return (
    <div className="packages__item packages__item-application_kyc" onClick={onClick}>
      {title && (
        <div className="packages__top">
          <div className={classNames(classTitle, 'packages__title')}>
            {title}
          </div>
        </div>
      )}

      {content && (
        <div className="packages__content">
          {content}
        </div>
      )}

      {imgPath && (
        <div className="packages__line">
          <img src={imgPath} alt={title} />
        </div>
      )}

      {buttonPackage && (
        <div className="button packages__button">
          {buttonPackage}
        </div>
      )}
    </div>
  )
}

export default PackageItem;
