import gql from 'graphql-tag';

export const GET_CUSTOMER_IDNOW_IDENT = gql`
  query getCustomerIdnowIdent($customerId: Int!) {
    getCustomerIdnowIdent(customerId: $customerId) {
      id
      transactionNumber
      identCode
      customerId
      oldCustomerId
      directorId
      oldDirectorId
      status
      result
      reason
      created_at
      idnowDocument {
        id
        identId
        storageName
      }
      idnowPicture {
        id
        identId
        storageName
      }
      idnowPdf {
        id
        identId
        storageName
      }
    }
  }
`;

export const GET_IDNOW_IDENT = gql`
  query getIdnowIdent($id: Int!) {
    getIdnowIdent(id: $id) {
      id
      transactionNumber
      identCode
      customerId
      oldCustomerId
      directorId
      oldDirectorId
      status
      result
      reason
      created_at
      idnowDocument {
        id
        identId
        storageName
      }
      idnowPicture {
        id
        identId
        storageName
      }
      idnowPdf {
        id
        identId
        storageName
      }
    }
  }
`;