import React from 'react'
import { useMe, usePublicSettings } from '../../myHooks'
import KycCustomerVideoIdentComplycube from '../../components/KycCustomerVideoIdentComplycube'

const ComplycubeIdent = () => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { me } = {} } = useMe({ fetchPolicy: 'network-only' });

  const redirectOnComplete = () => {
    window.location.href = publicSettings.force_user_till_first_transaction
      ? `${window.location.origin}/easy-onboarding/thank-you`
      : window.location.origin
  }

  return (
    <>
      {publicSettings && me && (
        <KycCustomerVideoIdentComplycube
          object={me.customer}
          onComplete={redirectOnComplete}
          forceCrossDevice={
            publicSettings.identification &&
            publicSettings.identification.ident_services.onfido &&
            publicSettings.identification.ident_services.onfido.force_cross_device
          }
        />
      )}
    </>
  );
};

export default ComplycubeIdent;
