import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import { useQuery } from "@apollo/react-hooks";
import { USER_VIDEO_IDENT_LOG_LIST } from "../queriesAndMutations";
import { useTranslation } from 'react-i18next';
import Icon from "./Icon";
import classNames from "classnames";

const AdminDialogVideoIdentLog = ({ open, onClickDialogAway, user }) => {
  const gqlResponse = useQuery(USER_VIDEO_IDENT_LOG_LIST, {
    variables: {
      input: {
        user_id: user.id,
      },
    },
  });
  const { t } = useTranslation();

  const userVideoIdentLogList =
    gqlResponse &&
    gqlResponse.data &&
    gqlResponse.data.userVideoIdentLogList &&
    gqlResponse.data.userVideoIdentLogList.objects;

    return (
    <Dialog
      fullWidth
      open={open}
      onBackdropClick={onClickDialogAway}
      onClose={onClickDialogAway}
    >
      <div className="popup js-popup animation visible popup__kyc-medium">
        <div onClick={onClickDialogAway} className="popup__overlay js-popup-overlay">{' '}</div>

        <div className="popup__wrap js-popup-wrap">
          <div className="description">
            <div className="title-purple description__title">{ t('Liveness Check Log') }</div>

            <div className="products__wrapper">
              <div className="products__table">
                <div className="products__row">

                  <div className="products__col products__col-first-clear">
                    { t('Error') }
                  </div>

                  <div className="products__col">
                    { t('Message') }
                  </div>
                </div>

                {userVideoIdentLogList &&
                  userVideoIdentLogList.map((log) => (
                    <div className="products__row" key={log.id}>
                      <div className="products__col">
                        {log.error}
                      </div>

                      <div className="products__col">
                        {log.message}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <button
            className="popup__close js-popup-close"
            onClick={onClickDialogAway}
          >
            <Icon
              className={classNames('close', 'icon-24')}
              name="close"
              size="24"
            />
          </button>
        </div>
      </div>
    </Dialog>
  );
};

AdminDialogVideoIdentLog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClickDialogAway: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default AdminDialogVideoIdentLog;
