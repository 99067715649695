import React from 'react'
import { useTranslation } from 'react-i18next'
import ActivityTableContent from '../components/UserActivity/ListContent'
import TablePaginationActions from '../components/TablePagination'

const UserActivity = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page__row kyc-activity-page__row">
        <div className="page__col kyc-activity-page__col">
          <div className="card">
            <div className="card__head">
              <div className="title-primary card__title">
                {t('Account Activities Log')}
              </div>

              <button className="button-stroke button-small">
                {t('Clear All')}
              </button>
            </div>

            <div className="tips activity kyc-activity">
              <div className="tips__info">
                {t('Here is your recent activities.')}
              </div>

              <div className="activity__wrapper">
                <div className="activity__table kyc-activity__table">
                  {/* Row Header Start */}
                  <div className="activity__row kyc-activity__row">
                    <div className="activity__col kyc-activity__col">
                      {t('Logged in (IP + Country)')}
                    </div>

                    <div className="activity__col kyc-activity__col">
                      {t('Purchased Tokens')}
                    </div>

                    <div className="activity__col kyc-activity__col">
                      {t('2FA Activated')}
                    </div>

                    <div className="activity__col kyc-activity__col">
                      {t('2FA SMS Completed')}
                    </div>

                    <div className="activity__col kyc-activity__col">
                      {t('Actions')}
                    </div>

                  </div>

                  {/* Row Body Start */}
                  <ActivityTableContent />
                </div>
              </div>
              <TablePaginationActions
                count="150"
                page={5}
                rowsPerPage={20}
                rowsPerPageOptions={[1, 5, 10, 25]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserActivity;
