import gql from "graphql-tag";

export const CREATE_CRYPTO_DOT_COM_PAYMENT = gql`
  mutation createPayment($input: CreatePaymentInput) {
    createPayment(input: $input) {
      paymentId,
      paymentUrl,
      amount,
      currency,
      status
    }
  }
`