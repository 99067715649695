import React from 'react'
import { useTranslation } from 'react-i18next'
import MaterialAccordion from '@material-ui/core/Accordion'
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary'
import MaterialAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import ImportTokenSwapForm from './ImportTokenSwapForm'
import AddTokenSwapForm from './AddTokenSwapForm'
import AdminTokenSwapList from './AdminTokenSwapList'
import AdminTokenSwapImportLogList from './AdminTokenSwapImportLogList'

const AdminTokenSwap = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page__title h3">{ t('Swap Token List')}</div>

      <div className="card">
        <MaterialAccordion className="accordion__kyc">
          <MaterialAccordionSummary
            className="accordion__kyc_head"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="attributesbh-content"
            id="attributes-header"
          >
            <div className="title-primary card__title">{ t('CSV Import') }</div>
          </MaterialAccordionSummary>
          <MaterialAccordionDetails className="accordion__kyc_body">
            <div className="w-full">
              <ImportTokenSwapForm onComplete={() => { window.location.reload() }} />
            </div>
          </MaterialAccordionDetails>
        </MaterialAccordion>
      </div>

      <div className="card">
        <MaterialAccordion className="accordion__kyc">
          <MaterialAccordionSummary
            className="accordion__kyc_head"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="attributesbh-content"
            id="attributes-header"
          >
            <div className="title-primary card__title">{ t('Add manual') }</div>
          </MaterialAccordionSummary>
          <MaterialAccordionDetails className="accordion__kyc_body">
            <div className="w-full">
              <AddTokenSwapForm onComplete={() => { window.location.reload() }} />
            </div>
          </MaterialAccordionDetails>
        </MaterialAccordion>
      </div>

      <div className="card">
        <div className="products__head">
          <div className="title-primary card__title">{ t('Swap List') }</div>
        </div>
        <AdminTokenSwapList />
      </div>

      <div className="card">
        <div className="products__head">
          <div className="title-primary card__title">{ t('Import Log') }</div>
        </div>
        <AdminTokenSwapImportLogList />
      </div>

    </>
  )
}

export default AdminTokenSwap
