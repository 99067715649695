import React from 'react'
import { useTranslation } from 'react-i18next'
import MaterialAccordion from '@material-ui/core/Accordion'
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary'
import MaterialAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CardForm from './CardForm'
import SidebarNav from './SidebarNav'

const CompanyDetails = ({
  values,
  setValues,
  goToNextStep,
  goToPrevStep,
  goToStep
}) => {
  const { t } = useTranslation();

  return (
    <div className="page__inner page__inner-onboarding">
      <div className="page__inner-onboarding-rwd">
        <MaterialAccordion className="accordion__kyc accordion__kyc_menu">
          <MaterialAccordionSummary
            className="accordion__kyc_head"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="onboarding-navbh-content"
            id="onboarding-nav-header"
          >
            <div className="title-primary card__title accordion__kyc_head_title">
              {t('Navigation')}
            </div>
          </MaterialAccordionSummary>

          <MaterialAccordionDetails className="accordion__kyc_body">
            <SidebarNav goToStep={goToStep}/>
          </MaterialAccordionDetails>
        </MaterialAccordion>
      </div>

      <div className="page__container">
        <div className="page__title h3">{t('Company Details')}</div>

        <div className="page__row">
          <div className="page__col">
            <CardForm
              values={values}
              setValues={setValues}
              goToNextStep={goToNextStep}
              goToPrevStep={goToPrevStep}
            />
          </div>

          <div className="page__col">
            <SidebarNav goToStep={goToStep} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyDetails;
