import React from 'react'
import classNames from 'classnames'
import Icon from '../Icon'

const InformationLine = ({
  icon,
  informationColor,
  text,
  isJustifyCenter
}) => {
  return (
    <div className={`refund__item refund__item-middle_content box__information ${informationColor} ${isJustifyCenter ? "flex__justify_center" : ""}`}>
      {icon && (
        <div className="refund__icon">
          <Icon
            className={classNames({icon})}
            name={icon}
            size="24"
          />{" "}
        </div>
      )}

      {text && (
        <div className="refund__content refund__content-link">
          {text}
        </div>
      )}
    </div>
  );
}

export default InformationLine;
