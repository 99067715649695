import React from 'react'

const Result = ({item}) => {
  return (
    <div className="search__item">
      <a href={item.url} className="search__link js-popup-open">
        <div className="search__preview">
          <img src={item.image} alt={item.title} />
        </div>

        <div className="search__details">
          <div className="search__content">
            {item.content}
          </div>
          <div className="search__title">
            {item.title}
          </div>
        </div>
      </a>
    </div>
  )
}

export default Result;
