import React, { useEffect } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import LineInformation from './InformationLine'
import { compose } from 'recompose'
import { useMutation } from '@apollo/react-hooks'
import queryString from 'query-string'
// import '../styles/legacy/style.scss';
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { SALES_AUTH_TOKEN } from '../queriesAndMutations'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const SalesAuthTokenComponent = ({ history }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [authToken, { data, loading, error }] = useMutation(
    SALES_AUTH_TOKEN
  );

  const query = queryString.parse(history.location.search);
  const queryAuthToken = (query || {}).auth_token;

  useEffect(() => {
    authToken({ variables: { auth_token: queryAuthToken } }).catch(() => {});
  }, [authToken, queryAuthToken]);

  if (data) {
    console.log(data)
    localStorage.removeItem('jwt');
    localStorage.setItem('jwt', data.salesAuthTokenLogin.accessToken);
    return <Redirect to="/" />;
  }

  if (error) {
    return (
      <div className='entry__fieldset'>
        <LineInformation
          informationColor="box__information-red"
          text={t('Login error')}
          icon="close"
        />
      </div>
    );
  }

  return (
    <div className='page-ath-text'>
      {loading && (
        <>
          <LineInformation
            informationColor="box__information-green"
            text={t('Login in progress')}
            icon="check"
          />
        </>
      )}
      <div className={classes.circularProgressWrapper}>
        <CircularProgress />
      </div>
    </div>
  );
};

export default compose(withRouter)(SalesAuthTokenComponent);
