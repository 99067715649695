import classNames from 'classnames'
import TextField from '@material-ui/core/TextField'
import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import MenuItem from '@material-ui/core/MenuItem'
import { calculatePagesRange } from '../utils'
import { useTranslation } from 'react-i18next'
import Icon from './Icon'

const useStyles = makeStyles(() => ({
  tablePage: {
    display: 'inline-block',
    height: '36px',
    minWidth: '36px',
    textAlign: 'center',
    lineHeight: '20px',
    padding: '8px 8px',
    borderRadius: '4px',
    background: '#e0e8f3',
    border: 'none',
    color: '#495463',
    fontWeight: '500',
    fontSize: '12px',
    margin: '0 4px',
    transition: 'color 0.5s',
  },
  tablePageActive: {
    background: '#007bff',
    color: '#FFF',
    transition: 'color 0.5s',
    '&:hover': {
      background: '#007bff',
      color: '#FFF',
    },
  },
  tablePagination: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '75px',
  },
  rowsTextField: {
    '& .MuiOutlinedInput-input': {
      padding: '8px',
    },
  },
}))

const TablePaginationActions = ({
  count, page, rowsPerPage, onChangePage, onChangeRows, rowsPerPageOptions,
}) => {
  const classes = useStyles()
  const { t } = useTranslation();

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1)
  }

  const totalPages = Math.ceil(count / rowsPerPage)
  const pagesRange = calculatePagesRange(totalPages + 1, page + 1, 6)
  const pages = Array
    .from({ length: pagesRange.end - pagesRange.start }, (_, i) => pagesRange.start + i)
    .map((v) => (
      <button
        onClick={(event) => onChangePage(event, v - 1)}
        className={classNames("products__arrow kyc__pagination-arrow", page + 1 === v ? "products__arrow-active" : '')}
        key={v}
      >
        {v}
      </button>
    ))

  return (
    <>
      <div className="products__foot d__flex flex__row flex__wrap flex__justify_between flex__align_center">
        <div className="panel__info">
          <span>
            { t('Page') }
          </span>
          {' '}
          { page + 1 }
          {' '}
          { t('of') }
          {' '}
          { totalPages }
          {' '}
          -
          {' '}
          <span>
            { t('total items') }
          </span>
          {' '}
          { count }
        </div>

        <div className="products__foot-pagination d__flex flex__row flex__wrap flex__justify_center flex__align_center">
          <button
            className="products__arrow kyc__pagination-arrow"
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
          >
            <Icon
              className={classNames('arrow-left')}
              name='arrow-left'
              size="20"
            />{" "}
          </button>

          { pages }

          <button
            className="products__arrow kyc__pagination-arrow"
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            <Icon
              className={classNames('arrow-right')}
              name='arrow-right'
              size="20"
            />{" "}
          </button>
        </div>

        {
          (rowsPerPageOptions && onChangeRows) && (
            <div className="field d__flex flex__row flex__wrap flex__justify_start flex__align_center">
              <div className="field__label">
                {t('Rows per page')}
              </div>
              <div className="field__wrap field__wrap-select">
                <TextField
                  className={classes.rowsTextField}
                  select
                  value={rowsPerPage}
                  onChange={onChangeRows}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                >
                  {rowsPerPageOptions.map((v) => (
                    <MenuItem key={v} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          )
        }
      </div>
    </>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRows: PropTypes.func,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.array,
}

export default TablePaginationActions
