import React, { useState, useEffect } from "react";
import {
  reduce,
  omit,
  pick,
} from "lodash";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputTheme from "../InputTheme";
import { ACCEPTED_CURRENCIES } from "../../constants/contribute";
import ImageUpload from '../ImageUpload'
import { getOriginalFileExtension } from '../../utils/file'
import { getFileUrl } from '../../utils'
import { useTranslation } from 'react-i18next';

const SETS = {
  account_name: {
    label: 'Account name',
  },
  account_number: {
    label: 'Account number',
  },
  bank_name: {
    label: 'Bank name',
  },
  bank_address: {
    label: 'Bank address',
  },
  routing_number: {
    label: 'Routing number',
  },
  iban: {
    label: 'IBAN',
  },
  "swift/bic": {
    label: "Swift/BIC",
  },
};

const FEE = {
  fee: {
    label: "Fee",
    helperText: "Example: 0.25%"
  },
  minimum_fee: {
    label: "Minimum Fee",
    helperText: "Example: 5%"
  },
  maximum_fee: {
    label: "Maximum Fee",
    helperText: "Example: 25%"
  },
};

const AdminSettingsBank = ({
  bankSettings,
  settings,
  loading,
  error,
  onUpdate,
  editingAllowed,
}) => {
  const { t } = useTranslation();
  const [currentCurrency, setCurrentCurrency] = useState(
    settings.base_currency
  );

  const [values, setValues] = useState({
    ...reduce(
      SETS,
      (memo, value, key) => {
        // eslint-disable-next-line no-param-reassign
        memo[key] = ((bankSettings || {})[currentCurrency] || {})[key] || "";
        return memo;
      },
      {}
    ),
    ...reduce(
      FEE,
      (memo, value, key) => {
        // eslint-disable-next-line no-param-reassign
        memo[key] = "";
        return memo;
      },
      {}
    ),
    logo_bank_path: bankSettings[currentCurrency] ? bankSettings[currentCurrency].logo_bank_path : null,
  });

  const onDropFile = (name) => (img, imgURI) => {
    let newImage = {}
    if (img.length > 0 && imgURI.length > 0) {
      newImage = {
        img: img[0],
        imgURI: imgURI[0],
      }
    }

    setValues({ ...values, [name]: newImage })
  }

  const UploadPreview = ({ path, ...rest }) => {
    if (getOriginalFileExtension(path) === 'pdf') {
      return <embed src={getFileUrl(path)} width="500" height="375" type="application/pdf" {...rest} />
    }

    return <img alt="" src={getFileUrl(path)} {...rest} />
  }

  useEffect(() => {
    setValues({
      ...reduce(
        SETS,
        (memo, value, key) => {
          // eslint-disable-next-line no-param-reassign
          memo[key] = ((bankSettings || {})[currentCurrency] || {})[key] || "";
          return memo;
        },
        {}
      ),
      ...pick(bankSettings, ['fee', 'minimum_fee', 'maximum_fee']),
      logo_bank_path: bankSettings[currentCurrency] ? bankSettings[currentCurrency].logo_bank_path : '',
    }
    );
  }, [currentCurrency, setValues, bankSettings]);

  const onUpdateBtnClick = () => {
    onUpdate({
      bank: {
        ...ACCEPTED_CURRENCIES.filter((currency) => settings.accepted_currencies[currency]).reduce((all, currency) => ({
          ...all,
          [currency]: {
            ...omit(bankSettings[currency], ['logo_bank_path']),
          }
        }), {}),
        [currentCurrency]: {
          ...omit(values, ['logo_bank_path', 'fee', 'minimum_fee', 'maximum_fee']),
          logo_bank: (values.logo_bank || {}).img,
        },
        ...pick(values, ['fee', 'minimum_fee', 'maximum_fee'])
      },
    });
  };

  return (
    <>
      <div className="settings__item">
        <div className="title-primary settings__title">{ t('Bank Account') }</div>
        <div className="settings__fieldset">
          <div className="field field-radio__material">
            <div className="field__label">{ t('Currency') }</div>

            <div className="field__wrap field__wrap-radio field__wrap-radio__text_gray">
              <RadioGroup
                aria-label="position"
                name="position"
                value={currentCurrency}
                onChange={(e) => setCurrentCurrency(e.target.value)}
                row
              >
                {ACCEPTED_CURRENCIES.filter(
                  (curr) => settings.accepted_currencies[curr]
                ).map((currency) => (
                  <FormControlLabel
                    key={currency}
                    value={currency}
                    control={<Radio color="primary" />}
                    label={currency.toUpperCase()}
                  />
                ))}
              </RadioGroup>
            </div>
          </div>

          <div className="settings__row flex__row flex__wrap have__fields">
            {Object.keys(SETS).map((item) => {
              return (
                <InputTheme
                  key={item}
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName={item}
                  label={SETS[item].label}
                  state={values}
                  setState={setValues}
                  disabled={!editingAllowed}
                  error={error}
                  loading={loading}
                  helperText={SETS[item].helperText}
                />
              );
            })}

            {Object.keys(FEE).map((item) => {
              return (
                <InputTheme
                  key={item}
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  propertyName={item}
                  label={FEE[item].label}
                  state={values}
                  setState={setValues}
                  disabled={!editingAllowed}
                  error={error}
                  loading={loading}
                  helperText={FEE[item].helperText}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="settings__item">
        <div className="title-primary settings__title">{ t('Bank Logo') }</div>

        <div className="settings__fieldset">
          {values.logo_bank_path && (
            <div className="field">
              <div className="field__label">{ t('Current bank logo') }</div>

              {values.logo_bank_path && (
              <div className="field__wrap">
                <UploadPreview path={values.logo_bank_path} alt="bank logo" />
              </div>
              )}
            </div>
          )}

          {editingAllowed && (
            <div className="field">
              <ImageUpload
                buttonText={t('Choose Bank Logo')}
                buttonClassName="button"
                onChange={onDropFile('logo_bank')}
                maxFileSize={1048576}
                withPreview
                singleImagePick
                singleFile
                defaultImages={
                  (values.logo_bank || {}).imgURI
                    ? [values.logo_bank]
                    : []
                }
              >
                <div className="file__wrap_text text__center">
                  { t('New Bank Logo') }
                  <br />
                  { t('Drag and drop the image') }
                </div>
              </ImageUpload>
            </div>
          )}
        </div>
      </div>

      {loading ? (
        <div className="settings__item">
          <CircularProgress />
        </div>
      ) : (
        <button
          disabled={!editingAllowed}
          onClick={onUpdateBtnClick}
          className="button settings__button"
        >
          { t('Update') }
        </button>
      )}
    </>
  );
};

AdminSettingsBank.propTypes = {
  bankSettings: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

export default AdminSettingsBank;
