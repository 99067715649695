import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { CircularProgress } from '@material-ui/core'
import { GET_INTERNAL_LIVENESS_CHECK_APPLICANT_FOR_DIRECTOR } from '../../queriesAndMutations'

import LivenessCheckQrCode from '../LivenessCheck/LivenessCheckQrCode'

const KycDirectorVideoIdentInternalLivenessCheck = ({ director }) => {
  const {
    data: { getInternalLivenessCheckApplicantForDirector: applicant } = {},
    loading
  } = useQuery(GET_INTERNAL_LIVENESS_CHECK_APPLICANT_FOR_DIRECTOR, {
    variables: {
      director_id: +(director.id)
    }
  })

  return (
    <div className="component component-kyc-director-video-ident-internal-liveness-check">
      { loading && (
        <div className="text-center">
          <CircularProgress />
        </div>
      )}
      {applicant && (
        <LivenessCheckQrCode applicantUuid={applicant.uuid} />
      )}
    </div>
  )
}

KycDirectorVideoIdentInternalLivenessCheck.propTypes = {
  director: PropTypes.object.isRequired,
}

export default KycDirectorVideoIdentInternalLivenessCheck
