import React from 'react'
import moment from 'moment'
import { Grid } from '@material-ui/core'

const KompanyMapping = ({
  company
}) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={4}>
          <div className="card light-border">
            <h3>Provided data</h3>
            <ul>
              <li>Company name: {company.companyName}</li>
              <li>Registration number: {company.companyRegisterNumber}</li>
              <li>Country: {company.companyCountry}</li>
            </ul>
          </div>
        </Grid>
        {company.kompanyMapping.kompanyId && (
          <Grid item md={4}>
            <div className="card light-border">
              <h3>Mapped data</h3>
              <ul>
                <li><label>Company name</label>: {company.kompanyMapping.data.name}</li>
                <li><label>ID</label>: {company.kompanyMapping.data.id}</li>
                <li><label>Registration number</label>: {company.kompanyMapping.data.registrationNumber}</li>
                <li><label>Last updated</label>: {moment(company.kompanyMapping.data.lastUpdate * 1000).format('DD-MM-YYYY')}</li>
                <li><label>Mapped by</label>: {company.kompanyMapping.user.email}</li>
              </ul>
            </div>
          </Grid>
        )}

        {!company.kompanyMapping.kompanyId && (
          <Grid item md={4}>
            <div className="card light-border">
              <p>Saved as no map</p>
            </div>
          </Grid>
        )}
      </Grid>

    </>
  )
}

export default KompanyMapping
