import gql from 'graphql-tag'

export const GET_INDIVIDUAL_LIVENESS_CHECK_SERVICE_INFO = gql`
  query getIndividualLivenessCheckServiceInfo($createNewIfNotFound: Boolean) {
    getIndividualLivenessCheckServiceInfo(createNewIfNotFound: $createNewIfNotFound)
  }
`
export const GET_DIRECTOR_LIVENESS_CHECK_SERVICE_INFO = gql`
  query getDirectorLivenessCheckServiceInfo($directorId: ID!, $createNewIfNotFound: Boolean) {
    getDirectorLivenessCheckServiceInfo(directorId: $directorId, createNewIfNotFound: $createNewIfNotFound)
  }
`

export const GET_COMPANY_LIVENESS_CHECK_SERVICE_INFO = gql`
  query getCompanyLivenessCheckServiceInfo($createNewIfNotFound: Boolean) {
    getCompanyLivenessCheckServiceInfo(createNewIfNotFound: $createNewIfNotFound)
  }
`
