import React from 'react'
import { useTranslation } from 'react-i18next'
import OnfidoReports from './OnfidoReports'
import AdminUploadPreview from './AdminUploadPreview'

const OnfidoApplicantReportCheck = ({
  applicant,
  calculatedRiskAssessment,
  transactionsUserList,
  watchlistView,
}) => {
  const { t } = useTranslation()

  const completedChecks = (
    applicant
    && applicant.onfidoChecks
    && applicant.onfidoChecks.sort((a, b) => a.id - b.id).filter(check => check.status === 'complete')
    ) || []

  let checks;

  if (watchlistView) {
    checks = completedChecks.filter(check => check.onfidoReports.find(report => report.name === 'watchlist_enhanced'))
  } else {
    checks = completedChecks.filter(check => check.onfidoReports.find(report => report.name !== 'watchlist_enhanced'))
  }

  if (!checks || !checks.length) {
    return ''
  }

  return (
    <>
      {watchlistView && completedChecks.length > 0 && (
        <div className="card">
          <div className="card__head">
            <div className="title-clear card__title">
              {t('ApplicantID')}: {' '}
            </div>
          </div>

          <div className="packages__content nospace__bottom">
            <span>
              {applicant.applicant_id}
            </span>
          </div>
        </div>
      )}

      {completedChecks.length > 0 && completedChecks.map((completedCheck) => {
        let transaction;
        if (transactionsUserList && transactionsUserList.objects.length) {
          const completedCheckDate = new Date(completedCheck.created_at);

          transaction = transactionsUserList.objects.find(transaction => {
            const transactionDate = new Date(transaction.created_at);
            return Math.abs(completedCheckDate - transactionDate) < 60*1000;
          })
        }

        let reports
        if (watchlistView) {
          reports = completedCheck.onfidoReports.filter(report => report.name === 'watchlist_enhanced')
        } else {
          reports = completedCheck.onfidoReports.filter(report => report.name !== 'watchlist_enhanced')
        }

        if (!reports || !reports.length) {
          return ""
        }

        return (
          <div key={completedCheck.id} className="card">
            { watchlistView && (
              <div className="product__line">
                <div className="product__author">
                  {t('CheckID')}:
                </div>
                {completedCheck && completedCheck.check_id}
                {transaction &&
                  <span>
                    {t('TransID')}:
                    {' '}
                    {`TNX${transaction.id}`}
                  </span>
                }
              </div>
            )}
            <div className="card__head">
              <div className="title-clear card__title">
                {t('Onfido Reports')}:
              </div>
            </div>

            <OnfidoReports
              reports={reports}
              calculatedRiskAssessment={calculatedRiskAssessment}
            />

            <div className="product__gallery" style={{ marginTop: "16px" }}>
              {watchlistView && completedCheck.onfidoCheckPdf && (
                <AdminUploadPreview
                  upload={completedCheck.onfidoCheckPdf}
                  contentType="application/pdf"
                />
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}

export default OnfidoApplicantReportCheck
