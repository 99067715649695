import React from 'react'
import { useMe, usePublicSettings } from '../../myHooks'
import KycCustomerVideoIdentOnfido from '../../components/KycCustomerVideoIdentOnfido'
// import '../../styles/onfidoIdent.scss'

const OnfidoIdent = () => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { me } = {} } = useMe({ fetchPolicy: 'network-only' });

  const redirectOnComplete = () => {
    window.location.href = publicSettings.force_user_till_first_transaction
      ? `${window.location.origin}/easy-onboarding/thank-you`
      : window.location.origin
  }

  return (
    <>
      {publicSettings && me && (
        <KycCustomerVideoIdentOnfido
          customer={me.customer}
          forceCrossDevice={publicSettings.identification.ident_services.onfido.force_cross_device || false}
          onComplete={redirectOnComplete}
        />
      )}
    </>
  );
};

export default OnfidoIdent;
