import React from 'react'
import ImageUpload from './ImageUpload'
import { useTranslation } from 'react-i18next'
import { TOKEN_TYPES } from '../constants/settings'

const KycFormSupportedSourceOfFund = ({
  tokenToBaseCurrency,
  publicSettings,
  onDropSupportedSourceOfFundFiles,
  supportedSourceOfFundFiles,
  removeDropSupportedSourceOfFundFiles,
  intendedInvestment,
}) => {
  const { t } = useTranslation();
  const baseCurrency = (publicSettings && publicSettings.base_currency) || 'chf'

  const getIntendedInvestmentOptions = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return []
    }

    return Object.values(publicSettings.kyc_levels).filter((level) => !level.enable || level.enable.enable_lvl).map((level) => {
      if (level.enable && level.enable.enable_open_end) {
        return `> ${level.min_invest_amount} ${baseCurrency.toUpperCase()}`
      }
      return `${level.min_invest_amount} - ${level.max_invest_amount} ${baseCurrency.toUpperCase()}`
    })
  }

  const isDocumentRequired = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return false
    }

    if (publicSettings.token_type === TOKEN_TYPES.NONE) {
      return getIntendedInvestmentOptions().indexOf(intendedInvestment) > 0
    }

    if ([TOKEN_TYPES.UTILITY, TOKEN_TYPES.EQUITY, TOKEN_TYPES.BOND].includes(publicSettings.token_type)) {
      const currentLevel = Object.values(publicSettings.kyc_levels).filter((item) => {
        return !item.enable || item.enable.enable_lvl;
      }).reduce((all, item) => all + (tokenToBaseCurrency >= item.min_invest_amount ? 1 : 0), 0)

      return currentLevel > 2
    }

    return false
  }

  const KycFormSupportedSourceOfFundContent = (
    <>
      <div className="editor">
        <div className="field easy-onboarding__company-uploadsupportingdocuments">
          <ImageUpload
            labelText={ t('Upload supporting documents to demonstrate the Source of Funds for this Purchase.') }
            isRequired
            buttonText={t('Click or drop images')}
            buttonClassName="button"
            onChange={onDropSupportedSourceOfFundFiles}
            maxFileSize={10000000}
            withPreview
            singleImagePick
            defaultImages={[]}
          >
            <div className="file__wrap_text text__center">
              { t('Drag and drop the document') }
              <br />
              { t('Suport: JPG, JPEG, GIF, PNG, PDF.') }
              <br />
              { t('Max: 10MB') }
            </div>
          </ImageUpload>
        </div>
        {supportedSourceOfFundFiles.map((file, index) => (
          <React.Fragment key={index}>
            <div className="space space__32">{' '}</div>
            <div className="settings__top">
              <div className="settings__label">
                {(file && file.img && file.img.name) || t('Cannot load file')}
              </div>

              <button
                className="button-stroke button-small settings__button"
                onClick={(e) => {
                  removeDropSupportedSourceOfFundFiles(index);
                }}
              >
                { t('Remove') }
              </button>
            </div>
          </React.Fragment>
        ))}
        <div className="space space__32">{' '}</div>
      </div>
    </>
  );

  if (isDocumentRequired()) {
    return KycFormSupportedSourceOfFundContent
  }

  return <></>;
};

export default KycFormSupportedSourceOfFund;
