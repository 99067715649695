import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  CircularProgress,
  Grid,
} from '@material-ui/core'

import WarningIcon from '@material-ui/icons/Warning'
import VideocamIcon from '@material-ui/icons/Videocam'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'

import LivenessCheckTakeDocument from './LivenessCheckTakeDocument'
import LivenessCheckRecordVideo from './LivenessCheckRecordVideo'

const LivenessCheck = ({
  onComplete,
  isSubmitting,
  challenge,
}) => {
  const [step, setStep] = useState(0)
  const [isRecording, setIsRecording] = useState(false)
  const [requestDevices, setRequestDevices] = useState(true)
  const [data, setData] = useState({
    document: null,
    video: null,
  })
  const [allowAccessMediaDevice, setAllowAccessMediaDevice] = useState('pending')
  const [documentType, setDocumentType] = useState()
  const submitResources = () => {
    setStep(4)
    onComplete(data)
  }

  const selectDocumentType = (docType) => () => {
    setData({
      document: null,
      video: null,
    })
    setDocumentType(docType)
    setStep(2)
  }

  if (requestDevices) {
    try {
      if (navigator.mediaDevices) {
        navigator.mediaDevices.getUserMedia({
          // video: { facingMode: { exact: "environment" } },
          video: true,
          audio: true,
        }).then((stream) => {
          setAllowAccessMediaDevice('allowed')
          setRequestDevices(false)
          stream.getTracks().forEach(track => {
            track.stop()
            stream.removeTrack(track)
          })
        }).catch((error) => {
          setAllowAccessMediaDevice('blocked')
          console.log(error)
        })
      } else {
        setRequestDevices(false)
        setAllowAccessMediaDevice('unavailable')
      }
    } catch (error) {
      alert(error)
    }
  }

  return (
    <div className="component component-liveness-check">
      {allowAccessMediaDevice === 'pending' && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p className="text-center">
              <VideocamIcon style={{fontSize: '50px'}} />
            </p>
            <p className="text-center">
              Please accept the request to access media devices.
            </p>
          </Grid>
        </Grid>
      )}
      {allowAccessMediaDevice === 'blocked' && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p className="text-center text-danger">
              <WarningIcon style={{fontSize: '50px'}} />
            </p>
            <p className="text-center text-danger">
              You need to allow us to access your camera and microphone to process the liveness check.
            </p>
          </Grid>
        </Grid>
      )}
      {allowAccessMediaDevice === 'unavailable' && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p className="text-center text-danger">
              <WarningIcon style={{fontSize: '50px'}} />
            </p>
            <p className="text-center text-danger">
              Cannot get access to media devices.
            </p>
          </Grid>
        </Grid>
      )}
      {allowAccessMediaDevice === 'allowed' && (
        <>
          {step === 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <p className="text-center">
                  <PhotoCameraIcon style={{fontSize: '50px'}} />
                </p>
                <p className="text-center">1. Take picture of your passport/national ID</p>
              </Grid>
              <Grid item xs={12} lg={4}>
                <p className="text-center">
                  <VideocamIcon style={{fontSize: '50px'}} />
                </p>
                <p className="text-center">2. Record a video following the instruction</p>
              </Grid>
              <Grid item xs={12} lg={4}>
                <p className="text-center">
                  <VerifiedUserIcon style={{fontSize: '50px'}} />
                </p>
                <p className="text-center">3. Get verified</p>
              </Grid>
              <Grid item xs={12}>
                <div className="text-center">
                  <Button variant="contained" color="primary" onClick={() => { setStep(1)}}>Start</Button>
                </div>
              </Grid>

            </Grid>
          )}
          {step === 1 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <p className="text-center">Select a document type that you will used for </p>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <button className="document-type-option" onClick={ selectDocumentType('PASSPORT') }>Passport</button>
                  <span>&nbsp;</span>
                  <button className="document-type-option" onClick={ selectDocumentType('NATIONAL_ID_CARD') }>National ID</button>
                </Box>
              </Grid>
            </Grid>
          )}
          {step === 2 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <LivenessCheckTakeDocument
                  documentType={documentType}
                  onTakePhoto={(file) => {
                    setData({
                      ...data,
                      document: file,
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {data.document && data.document.photos.front && (data.document.photos.back || documentType === 'PASSPORT') && (
                  <div className="text-center">
                    <Button variant="contained" onClick={() => { setStep(1) }}>Back</Button>
                    {' '}
                    {data.document && (
                      <Button variant="contained" onClick={() => { setStep(3) }}>Next</Button>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          )}
          {step === 3 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <LivenessCheckRecordVideo
                  onRecording={() => { setIsRecording(true) }}
                  onRecordVideo={(file) => {
                    setIsRecording(false)
                    setData({
                      ...data,
                      video: file,
                    })
                  }}
                  challenge={challenge}
                />
              </Grid>
              {!isRecording && (
                <Grid item xs={12}>
                  <div className="text-center">
                    <Button variant="contained" onClick={() => { setStep(2) }}>Back</Button>
                    {' '}
                    {data.video && (
                      <Button variant="contained" onClick={submitResources}>Next</Button>
                    )}
                  </div>
                </Grid>
              )}
            </Grid>
          )}
          {step === 4 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {isSubmitting && (
                  <div className="text-center">
                    <CircularProgress />
                    <p>Saving...</p>
                  </div>
                )}
                {!isSubmitting && (
                  <div className="text-center">
                    <p><VerifiedUserIcon style={{fontSize: '80px'}} /></p>
                    <p>Your liveness check has been recorded.</p>
                  </div>
                )}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </div>
  )
}

LivenessCheck.propTypes = {
  onComplete: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  challenge: PropTypes.array.isRequired,
}

export default LivenessCheck
