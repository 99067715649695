import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  description: {
    fontSize: '1rem',
    textAlign: 'center',
    margin: 0,
  },
  challengeArguments: {
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    margin: 0,
  }
})

const LivenessCheckChallenge = ({
  challenge
}) => {
  const classes = useStyles()
  let description
  let challengeArguments

  if (challenge.action === 'speak') {
    description = 'Speak loud'
    challengeArguments = challenge.arguments.number.join(' - ')
  }
  if (challenge.action === 'turn') {
    description = 'Turn your head to the'
    challengeArguments = challenge.arguments.direction.toUpperCase()
  }

  return (
    <div>
      <p className={classes.description}>{description}</p>
      <p className={classes.challengeArguments}>{challengeArguments}</p>
    </div>
  )
}

LivenessCheckChallenge.propTypes = {
  challenge: PropTypes.object.isRequired
}

export default LivenessCheckChallenge
