import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { pick } from 'lodash'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress'
import { usePublicSettings } from '../../../myHooks'
import { TOKEN_TYPES, PROSPECTUS_OR_ISSUING } from '../../../constants/settings'
import RadioTheme from '../../../components/Radio'
import SwitchCheckboxTheme from '../../../components/SwitchCheckbox'
import CardInformation from '../../../components/InformationCard'
import InputThemeField from '../../../components/InputThemeField'
import Icon from '../../../components/Icon'

const KYC_USER_TYPE_ENUM = {
  notPoliticalPerson: {
    value: 'NOT_POLITICAL_PERSON',
    label: 'notPoliticalPerson'
  },
  politicalPerson: {
    value: 'POLITICAL_PERSON',
    label: 'politicalPerson',
  },
  anotherPoliticalPerson: {
    value: 'ANOTHER_POLITICAL_PERSON',
    label: 'anotherPoliticalPerson',
  },
};

const KYC_CALIDRIS_USER_TYPE_ENUM = {
  notPoliticalPerson: {
    value: 'NOT_POLITICAL_PERSON',
    label: 'notPoliticalPerson'
  },
  'Are you a PEP or have you held one of the following important public office positions': {
    value: 'Are you a PEP or have you held one of the following important public office positions',
    label: 'Are you a PEP or have you held one of the following important public office positions',
  },
  'Are you an immediate family member': {
    value: 'Are you an immediate family member',
    label: 'Are you an immediate family member',
  },
  'Are you known to be a joint beneficial owner of legal entities': {
    value: 'Are you known to be a joint beneficial owner of legal entities',
    label: 'Are you known to be a joint beneficial owner of legal entities',
  },
};

const CardDisclaimer = ({
  values,
  setValues,
  goToPrevStep,
  disclaimer,
  setDisclaimer,
  submitKyc,
  loading,
}) => {
  const { t } = useTranslation()
  const { data: { publicSettings = {} } = {} } = usePublicSettings()
  let prospectusOrIssuing;
  let prospectusUrl;
  let prospectusOrIssuingDate;
  const currentHost = window.location.host;
  const isCalidrisfintech = currentHost === 'kyc.calidrisfintech.com' || currentHost === 'dev-kyc.calidrisfintech.com' || currentHost === 'localhost:3000';
  const isAwearetech = currentHost === 'kyc.awearetech.li' || currentHost === 'aweare-dev.investhub.io' || currentHost === 'localhost:3000';

  if (!publicSettings) {
    return null
  }

  const disclaimerText = {
    [TOKEN_TYPES.NONE]: {
      acknowledgementOfReceipt: 'I Confirm That I Act On My Own Account, In My Own Economic Interests And Not On Behalf Of Others.',
      clientStatement: 'I confirm that I am the beneficial owner of the invested assets.',
      confirmation: 'I Hereby Confirm That, Before Subscribing, I Have Received And Read The Token Sale Agreement Of The Issuer Promptly And In Full And In Particular That',
      declarationOfConsent4: 'I Hereby Consent To My Personal Data Being Processed By The Issuer And All Companies Affiliated With It, As Well As The Persons And Entities Involved In The Management And Supervision Of My Investment (In Particular The Issuer’s Business, Banks, Tax Advisors, Auditors) Being Stored In Computer Equipment And Be Used.',
      declarationOfConsent5: 'I Hereby Confirm That The Declarations Made To The Best Of My Knowledge And Belief Are Correct And Complete. Any Changes To The Aforementioned Circumstances Will Be Communicated To The Issuer Without Delay In Written Form And Must Be Forwarded An Updated Self-Assessment Within 30 Days Upon Request.',
      declarationOfConsent6: 'I Have Read The Aforementioned “Warnings” And Especially The “Risk Information”',
    },
    [TOKEN_TYPES.LIQUIDATION]: {
      clientStatement: `liquidationClientStatement`,
      declarationOfConsent4: `I Hereby agree that I will receive the compensation, once the Lawyer approved the change of claim from you as ${values.firstName} ${values.middleName} ${values.lastName} to ${publicSettings.company.name}`,
      declarationOfConsent5: `I Hereby Consent To My Personal Data Being Processed By ${publicSettings.company.name} And All Companies Affiliated With It, As Well As The Persons And Entities Involved In The Management And Supervision Of My Investment (In Particular ${publicSettings.company.name}’s Business, Banks, Tax Advisors, Auditors) Being Stored In Computer Equipment And Be Used. The Data Collected May Also Be Used For The Purposes Of Advertising By Email And Post, Regardless Of A Contractual Relationship.Please See The Declaration Of Consent`,
      declarationOfConsent6: `I Hereby Declare That I Have Been Provided With The Privacy Policy Of ${publicSettings.company.name}`,
      declarationOfConsent7: `I Hereby Confirm That The Declarations Made To The Best Of My Knowledge And Belief Are Correct And Complete. Any Changes To The Aforementioned Circumstances Will Be Communicated To ${publicSettings.company.name} Without Delay In Written Form And Must Be Forwarded An Updated Self-Assessment Within 30 Days Upon Request.`,
    },
    [TOKEN_TYPES.UTILITY]: {
      clientStatement: `${t('I hereby confirm that I myself am exclusively beneficially entitled to the assets for the acquisition of the tokens and that I am acting for my own account, in my own economic interest and not on behalf of others.')}`,
      declarationOfConsent4: 'utilityDeclarationOfConsent4',
      declarationOfConsent5: 'I Hereby Declare That I Have Been Provided With The Privacy Policy Of The Seller And That I Have Been Informed About The Details Of The Data Processing As Well As About My Data Protection Rights.',
    },
    [TOKEN_TYPES.EQUITY]: {
      acknowledgementOfReceipt: 'I Confirm That I Act On My Own Account, In My Own Economic Interests And Not On Behalf Of Others. Or The Beneficial Owner Is, If Different From The Subscriber',
      clientStatement: `${t('I hereby confirm that I myself am exclusively beneficially entitled to the assets for the acquisition of the tokens and that I am acting for my own account, in my own economic interest and not on behalf of others.')}`,
      declarationOfConsent1: 'equityDeclarationOfConsent1',
      declarationOfConsent2: t('I Hereby Confirm To Have Been Informed About The Right Of Withdrawal.'),
      declarationOfConsent3: 'I Hereby Declare And Confirm That, At The Same Time As The Subscription Is Accepted, I Am Requested To Transfer The Subscription Amount To The Following Special Account Of The Issuer Within The Duration Of The Offer. Please Refer To Further Instructions In Your My Account (This Account Will Be Created Once The KYC Form Has Been Successfully Submitted)',
      confirmation: 'equityConfirmation',
      declarationOfConsent4: values.referal_code === 'BlackMantaCapital'
        ? 'equityDeclarationOfConsent4_BlackMantaCapital'
        : 'equityDeclarationOfConsent4',
      declarationOfConsent5: 'equityDeclarationOfConsent5',
      declarationOfConsent6: 'I Hereby Confirm That The Declarations Made To The Best Of My Knowledge And Belief Are Correct And Complete. Any Changes To The Aforementioned Circumstances Will Be Communicated To The Issuer Without Delay In Written Form And Must Be Forwarded An Updated Self-Assessment Within 30 Days Upon Request.',
      declarationOfConsent7: 'equityDeclarationOfConsent7',
    },
    [TOKEN_TYPES.BOND]: {
      acknowledgementOfReceipt: 'I Confirm That I Act On My Own Account, In My Own Economic Interests And Not On Behalf Of Others. Or The Beneficial Owner Is, If Different From The Subscriber',
      clientStatement: `${t('I hereby confirm that I myself am exclusively beneficially entitled to the assets for the acquisition of the tokens and that I am acting for my own account, in my own economic interest and not on behalf of others.')}`,
      declarationOfConsent1: 'bondDeclarationOfConsent1',
      declarationOfConsent2: 'I Hereby Confirm To Have Been Informed About The Right Of Withdrawal.',
      declarationOfConsent3: 'I Hereby Declare And Confirm That, At The Same Time As The Subscription Is Accepted, I Am Requested To Transfer The Subscription Amount To The Following Special Account Of The Issuer Within The Duration Of The Offer. Please Refer To Further Instructions In Your My Account (This Account Will Be Created Once The KYC Form Has Been Successfully Submitted)',
      confirmation: 'bondConfirmation',
      declarationOfConsent4: values.referal_code === 'BlackMantaCapital'
        ? 'equityDeclarationOfConsent4_BlackMantaCapital'
        : 'equityDeclarationOfConsent4',
      declarationOfConsent5: 'equityDeclarationOfConsent5',
      declarationOfConsent6: 'I Hereby Confirm That The Declarations Made To The Best Of My Knowledge And Belief Are Correct And Complete. Any Changes To The Aforementioned Circumstances Will Be Communicated To The Issuer Without Delay In Written Form And Must Be Forwarded An Updated Self-Assessment Within 30 Days Upon Request.',
      declarationOfConsent7: 'equityDeclarationOfConsent7',
    },
  }

  const awearetechDisclaimerText = {
    usExportControl:
    `Due to United States of America export control and SEC regulations, this offering is not available for those with jurisdiction and/or domicile in the United States, Iran, Russia, North Korea, Belarus, Syria, and any territories thereof. If any prospective investor with jurisdiction and/or domicile in these nations or territories make an incorrect or false answer regarding jurisdiction and/or domicile outside of these nations or territories, that prospective investor understands that making incorrect/false statements may be considered felony offences including but not limited to perjury, wire fraud, and making false statements, depending on their specific local laws and circumstances.
    <br><br>
    The undersigned hereby declares that they are a bona fide resident of the country of ${values.countryOfResidence} and reside in and maintain at least one place of abode in the Domiciled Country which they recognize and intend to maintain as their permanent home. If maintaining one or more other places of abode outside of the Domiciled Country, the undersigned also declares that the Domiciled Country constitutes their predominant and principal home.
    <br><br>
    The undersigned affirms and attests to the fact that they are not domiciled in the United States of America, Russia, Iran, North Korea, Belarus, Syria, or any territories thereof.
    <br><br>
    The undersigned hereby declares that they will inform AWEARE Global AG administration through electronic means: admin@awearetech.li if at any time they intend to change their Domiciled Country to the United States, Russia, Iran, North Korea, Belarus, Syria, or any territories thereof for the express purpose of reviewing held securities as subject to repurchase.
    <br><br>
    I further certify that I understand there is a penalty for perjury; and that perjury is a felony and is punishable by fines and/or incarceration in accordance with national and/or state laws.`,
    applicableLaw: `The offer and the agreement for the purchase of AWEARE Global Tokens as well as all rights and obligations arising in connection with or from the offer and the agreement for the purchase of AWEARE Global Tokens shall be governed exclusively by Liechtenstein law, excluding the conflict of law rules and referral provisions.`,
    placeOfJurisdiction: `The courts of the Principality of Liechtenstein shall have exclusive jurisdiction for all disputes arising out of or in connection with the offer or the agreement for the purchase of AWEARE Global Tokens, including those concerning the valid conclusion, legal validity, amendment or cancellation.`,
    severability: `If at any time one or more of the provisions of the AWEARE Global Token Offer and the agreement for its purchase is or becomes unlawful, invalid, illegal or unenforceable in any respect, the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired thereby.`,
  }

  const textConfig = publicSettings.token_type === TOKEN_TYPES.UTILITY
    ? {
        issuer: 'Seller',
        subscription: 'Token Purchase',
        subscriber: 'purchaser',
      }
    : {
        issuer: 'Issuer',
        subscription: 'subscription',
        subscriber: 'subscriber',
      };

  if (!publicSettings) {
    return null
  }

  // [TOKEN_TYPES.UTILITY, TOKEN_TYPES.EQUITY, TOKEN_TYPES.BOND, TOKEN_TYPES.NONE, TOKEN_TYPES.LIQUIDATION].includes(publicSettings.token_type)
  const isShowAcknowledgementOfReceipt = [
    TOKEN_TYPES.EQUITY,
    TOKEN_TYPES.BOND,
    TOKEN_TYPES.NONE,
  ].includes(publicSettings.token_type) && publicSettings.kyc_acknowledgement_of_receipt

  const isShowClientStatement = publicSettings.kyc_client_statement

  const isShowDeclarationOfConsent1 = [
    TOKEN_TYPES.EQUITY,
    TOKEN_TYPES.BOND,
  ].includes(publicSettings.token_type)

  const isShowDeclarationOfConsent2 = [
    TOKEN_TYPES.EQUITY,
    TOKEN_TYPES.BOND,
  ].includes(publicSettings.token_type)

  const isShowDeclarationOfConsent3 = [
    TOKEN_TYPES.EQUITY,
    TOKEN_TYPES.BOND,
  ].includes(publicSettings.token_type)

  const isShowConfirmation = [
    TOKEN_TYPES.EQUITY,
    TOKEN_TYPES.BOND,
    TOKEN_TYPES.NONE,
  ].includes(publicSettings.token_type) && publicSettings.kyc_confirmations

  const isShowDeclarationOfConsent4 = [
    TOKEN_TYPES.UTILITY,
    TOKEN_TYPES.EQUITY,
    TOKEN_TYPES.BOND,
    TOKEN_TYPES.NONE,
    TOKEN_TYPES.LIQUIDATION,
  ].includes(publicSettings.token_type)
  const isShowDeclarationOfConsent5 = [
    TOKEN_TYPES.UTILITY,
    TOKEN_TYPES.EQUITY,
    TOKEN_TYPES.BOND,
    TOKEN_TYPES.NONE,
    TOKEN_TYPES.LIQUIDATION,
  ].includes(publicSettings.token_type)

  const isShowDeclarationOfConsent6 = [
    TOKEN_TYPES.EQUITY,
    TOKEN_TYPES.BOND,
    TOKEN_TYPES.NONE,
    TOKEN_TYPES.LIQUIDATION,
  ].includes(publicSettings.token_type)

  const isShowDeclarationOfConsent7 = [
    TOKEN_TYPES.EQUITY,
    TOKEN_TYPES.BOND,
    TOKEN_TYPES.LIQUIDATION,
  ].includes(publicSettings.token_type)

  const isDisableSubmitButton = () => {
    if (!publicSettings) {
      return true;
    }

    if (publicSettings.kyc_skip_legal_disclaimer) {
      return false;
    }

    let requiredFields

    switch (publicSettings.token_type) {
      case TOKEN_TYPES.UTILITY:
        requiredFields =[
          'clientStatement',
          'declarationOfConsent4',
          'declarationOfConsent5',
        ]

        break
      case TOKEN_TYPES.NONE:
        requiredFields =[
          'acknowledgementOfReceipt',
          'clientStatement',
          'confirmation',
          'declarationOfConsent4',
          'declarationOfConsent5',
          'declarationOfConsent6',
        ]

        break
      case TOKEN_TYPES.LIQUIDATION:
        requiredFields =[
          'clientStatement',
          'declarationOfConsent4',
          'declarationOfConsent5',
          'declarationOfConsent6',
          'declarationOfConsent7',
        ]

        break
      default:
        requiredFields =[
          'acknowledgementOfReceipt',
          'clientStatement',
          'declarationOfConsent1',
          'declarationOfConsent2',
          'declarationOfConsent3',
          'confirmation',
          'declarationOfConsent4',
          'declarationOfConsent5',
          'declarationOfConsent6',
          'declarationOfConsent7',
        ]

        break
    }

    if (!isShowAcknowledgementOfReceipt) {
      requiredFields = requiredFields.filter((field) => field !== 'acknowledgementOfReceipt')
    }

    if (!isShowClientStatement) {
      requiredFields = requiredFields.filter((field) => field !== 'clientStatement')
    }

    if (!isShowConfirmation) {
      requiredFields = requiredFields.filter((field) => field !== 'confirmation')
    }

    if (isAwearetech) {
      requiredFields.push("usExportControl")
      requiredFields.push('applicableLaw')
      requiredFields.push('placeOfJurisdiction')
      requiredFields.push('severability')
    }

    const isAllChecked = Object.values(pick(disclaimer, requiredFields)).reduce((all, value) => (all && value), true)

    return !isAllChecked
  }

  switch ((publicSettings || {}).prospectus_or_issuing) {
    case PROSPECTUS_OR_ISSUING.ISSUING:
      prospectusOrIssuing = 'Issuing Guidelines';
      prospectusOrIssuingDate = moment(publicSettings.issuing_guidelines).format('YYYY-MM-DD');
      break;
    case PROSPECTUS_OR_ISSUING.PROSPECTUS:
      prospectusOrIssuing = 'Prospectus';
      prospectusOrIssuingDate = moment(publicSettings.prospectus_date).format('YYYY-MM-DD');
      prospectusUrl = publicSettings.prospectus_url;
      break;
    default:
      prospectusOrIssuing = 'Token Sale Agreement';
      prospectusOrIssuingDate = moment(publicSettings.token_sale_agreement_date).format('YYYY-MM-DD');
      break;
  }

  return (
    <div className="card">
    <div className="settings__wrapper">

      {publicSettings.kyc_politically_exposed_persons && (
        <div className="settings__item">
          <div className="settings__fieldset">
            <div className="field">
              <div className="field__wrap d__flex flex__column flex__wrap">
                {Object.values(isCalidrisfintech ? KYC_CALIDRIS_USER_TYPE_ENUM : KYC_USER_TYPE_ENUM).map((item) => (
                  <RadioTheme
                    key={item.value}
                    value={item.value}
                    nameGroup="radio-buttons-disclaimer-radio"
                    isGrayText
                    isRadioGroupVertical
                    radioText={t(item.label)}
                    onChange={({ target: { value } }) => setValues({ ...values, personType: item.value })}
                    defaultChecked={values.personType === item.value}
                  />
                ))}
              </div>
            </div>
          </div>

          {values.personType !== KYC_USER_TYPE_ENUM.notPoliticalPerson.value && (
            <div className="settings__fieldset">
              <div className="settings__row flex__row flex__wrap have__fields">
                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  label={t('Exact description of the function')}
                  value={values.duties}
                  onChange={({ target: { value } }) => setValues({ ...values, duties: value })}
                />

                <InputThemeField
                  classWrapper="field"
                  classLabel="field__label"
                  classInput="field__input"
                  required
                  label={t('Relationship to PEP')}
                  value={values.relationshipToPEP}
                  onChange={({ target: { value } }) => setValues({ ...values, relationshipToPEP: value })}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {isShowAcknowledgementOfReceipt && (
        <div className="settings__item" style={{ display: "none" }}>
          <div className="card__head">
            <div className="title-primary card__title">{ t('Acknowledgement of Receipt') }</div>
          </div>

          <div className="settings__fieldset">
            <SwitchCheckboxTheme
              switchText={ t(disclaimerText[publicSettings.token_type].acknowledgementOfReceipt) }
              value="acknowledgementOfReceipt"
              onChange={({ target: { value } }) => setDisclaimer({ ...disclaimer, [value]: !disclaimer[value] })}
              isGrayText
              isLeftSwitch
              isRemoveLine
              defaultChecked={disclaimer.acknowledgementOfReceipt}
            />
          </div>
        </div>
      )}

      {isShowClientStatement && (
        <div className="settings__item">
          <div className="card__head">
            <div className="title-primary card__title card__title-has_icon">
              { t('Declarations relevant to due diligence') }
              {" "}

              <span
                data-tip={t('The intentional provision of false information is a punishable offence under the Liechtenstein Criminal Code. Any changes must be notified immediately.')}
                data-for="info-due-diligence"
              >
                <Icon
                  className={classNames('info')}
                  name='info'
                  size="16"
                />
              </span>

              <ReactTooltip id="info-due-diligence" place="bottom" padding="24px" className="popover-tooltip" />

            </div>
          </div>

          <div className="settings__fieldset">
            <SwitchCheckboxTheme
              switchText={ t(disclaimerText[publicSettings.token_type].clientStatement, {
                COMPANY: publicSettings.company && publicSettings.company.name,
                TOKEN_NAME: publicSettings.token_name
              }) }
              value="clientStatement"
              onChange={({ target: { value } }) => setDisclaimer({ ...disclaimer, [value]: !disclaimer[value] })}
              isGrayText
              isLeftSwitch
              isRemoveLine
              defaultChecked={disclaimer.clientStatement}
            />
          </div>
        </div>
      )}

      {isAwearetech && (
        <>
          <div className="settings__item">
            <div className="card__head">
              <div className="title-primary card__title card__title-has_icon">
                { t('Declaration of Domicile') }
                {" "}
              </div>
            </div>

            <div className="settings__fieldset">
              <SwitchCheckboxTheme
                switchText={awearetechDisclaimerText.usExportControl}
                value="usExportControl"
                onChange={({ target: { value } }) => setDisclaimer({ ...disclaimer, [value]: !disclaimer[value] })}
                isGrayText
                isLeftSwitch
                isRemoveLine
                defaultChecked={disclaimer.usExportControl}
              />
            </div>
          </div>

          <div className="settings__item">
            <div className="card__head">
              <div className="title-primary card__title card__title-has_icon">
                { t('Applicable Law') }
                {" "}
              </div>
            </div>

            <div className="settings__fieldset">
              <SwitchCheckboxTheme
                switchText={awearetechDisclaimerText.applicableLaw}
                value="applicableLaw"
                onChange={({ target: { value } }) => setDisclaimer({ ...disclaimer, [value]: !disclaimer[value] })}
                isGrayText
                isLeftSwitch
                isRemoveLine
                defaultChecked={disclaimer.applicableLaw}
              />
            </div>
          </div>

          <div className="settings__item">
            <div className="card__head">
              <div className="title-primary card__title card__title-has_icon">
                { t('Place of Jurisdiction') }
                {" "}
              </div>
            </div>

            <div className="settings__fieldset">
              <SwitchCheckboxTheme
                switchText={awearetechDisclaimerText.placeOfJurisdiction}
                value="placeOfJurisdiction"
                onChange={({ target: { value } }) => setDisclaimer({ ...disclaimer, [value]: !disclaimer[value] })}
                isGrayText
                isLeftSwitch
                isRemoveLine
                defaultChecked={disclaimer.placeOfJurisdiction}
              />
            </div>
          </div>

          <div className="settings__item">
            <div className="card__head">
              <div className="title-primary card__title card__title-has_icon">
                { t('Severability') }
                {" "}
              </div>
            </div>

            <div className="settings__fieldset">
              <SwitchCheckboxTheme
                switchText={awearetechDisclaimerText.severability}
                value="severability"
                onChange={({ target: { value } }) => setDisclaimer({ ...disclaimer, [value]: !disclaimer[value] })}
                isGrayText
                isLeftSwitch
                isRemoveLine
                defaultChecked={disclaimer.severability}
              />
            </div>
          </div>
        </>
      )}

      <div className="settings__fieldset">
        {values.referal_code === 'BlackMantaCapital' &&
          <SwitchCheckboxTheme
            switchText={(
              <Trans
                i18nKey={disclaimerText[publicSettings.token_type].declarationOfConsent4}
                t={t}
                components={{
                  FULL_NAME: `${values.firstName} ${values.middleName} ${values.lastName}`,
                  COMPANY: publicSettings.company.name,
                  //eslint-disable-next-line jsx-a11y/anchor-has-content
                  consentLink: <a href="/declaration-of-consent" target="_blank" rel="noopener noreferrer"> {t('Declaration of Consent')} </a>,
                  //eslint-disable-next-line jsx-a11y/anchor-has-content
                  blackMantaLink: <a href="https://blackmanta.capital/" target="_blank" rel="noopener noreferrer"> {t('and BMCP GmbH and its Affiliated Companies As The Issuers Investment Broker')} </a>,
                }}
              />
            )}
            value="declarationOfConsent4"
            onChange={({ target: { value } }) => setDisclaimer({ ...disclaimer, [value]: !disclaimer[value] })}
            isGrayText
            isLeftSwitch
            isRemoveLine
            defaultChecked={disclaimer.declarationOfConsent4}
          />
        }
      </div>

      {
        (
          isShowDeclarationOfConsent1 ||
          isShowDeclarationOfConsent2 ||
          isShowDeclarationOfConsent3
        ) && (
          <div className="settings__item" style={{ display: "none" }}>
            <div className="card__head">
              <div className="title-primary card__title">{ t('Declaration of Consent') }</div>
            </div>

            <div className="settings__fieldset">
              {isShowDeclarationOfConsent1 && (
                <SwitchCheckboxTheme
                  switchText={(
                    <Trans
                      i18nKey={disclaimerText[publicSettings.token_type].declarationOfConsent1}
                      t={t}
                      components={{
                        PROSPECTUS_OR_ISSUING: t(prospectusOrIssuing),
                        PROSPECTUS_OR_ISSUING_DATE: prospectusOrIssuingDate,
                        //eslint-disable-next-line jsx-a11y/anchor-has-content
                        prospectusLink: prospectusUrl ? <a href={prospectusUrl} target="_blank" rel="noopener noreferrer"> {t(prospectusOrIssuing)} </a> : <span />
                      }}
                    />
                  )}
                  value="declarationOfConsent1"
                  onChange={({ target: { value } }) => setDisclaimer({ ...disclaimer, [value]: !disclaimer[value] })}
                  isGrayText
                  isLeftSwitch
                  isRemoveLine
                  defaultChecked={disclaimer.declarationOfConsent1}
                />
              )}

              {isShowDeclarationOfConsent2 && (
                <SwitchCheckboxTheme
                  switchText={(
                    <Trans
                      i18nKey={disclaimerText[publicSettings.token_type].declarationOfConsent2}
                      t={t}
                      components={{
                        //eslint-disable-next-line jsx-a11y/anchor-has-content
                        rightOfWithdrawalLink: <a href="/right-of-withdrawal" target="_blank" rel="noopener noreferrer"> {t('The Right Of Withdrawal')} </a>
                      }}
                    />
                  )}
                  value="declarationOfConsent2"
                  onChange={({ target: { value } }) => setDisclaimer({ ...disclaimer, [value]: !disclaimer[value] })}
                  isGrayText
                  isLeftSwitch
                  isRemoveLine
                  defaultChecked={disclaimer.declarationOfConsent2}
                />
              )}

              {isShowDeclarationOfConsent3 && (
                <SwitchCheckboxTheme
                  switchText={ t(disclaimerText[publicSettings.token_type].declarationOfConsent3) }
                  value="declarationOfConsent3"
                  onChange={({ target: { value } }) => setDisclaimer({ ...disclaimer, [value]: !disclaimer[value] })}
                  isGrayText
                  isLeftSwitch
                  isRemoveLine
                  defaultChecked={disclaimer.declarationOfConsent3}
                />
              )}
            </div>
          </div>
        )
      }

      {isShowConfirmation && (
        <div className="settings__item" style={{ display: "none" }}>
          <div className="card__head">
            <div className="title-primary card__title">{ t('Confirmations') }</div>
          </div>

          <div className="settings__fieldset">
            <SwitchCheckboxTheme
              switchText={(
                <Trans
                  i18nKey={disclaimerText[publicSettings.token_type].confirmation}
                  t={t}
                  components={{
                    PROSPECTUS_OR_ISSUING: t(prospectusOrIssuing),
                    PROSPECTUS_OR_ISSUING_DATE: prospectusOrIssuingDate,
                    ISSUER: t(textConfig.issuer),
                    //eslint-disable-next-line jsx-a11y/anchor-has-content
                    prospectusLink: prospectusUrl ? <a href={prospectusUrl} target="_blank" rel="noopener noreferrer"> {t(prospectusOrIssuing)} </a> : <span />
                  }}
                />
              )}
              value="confirmation"
              onChange={({ target: { value } }) => setDisclaimer({ ...disclaimer, [value]: !disclaimer[value] })}
              isGrayText
              isLeftSwitch
              isRemoveLine
              defaultChecked={disclaimer.confirmation}
            />
          </div>

          <div className="field">
            <div className="space space__24">{' '}</div>
            <CardInformation
              color="card__information-primary"
              title=""
            >
              {publicSettings && (
                publicSettings.token_type === TOKEN_TYPES.NONE ? (
                  <ul>
                    <li>{t('I Accept The Subscription Applications')}</li>
                    <li>
                      {t('Especially I Certify That')}
                      <ul>
                        { (publicSettings.enable_blacklist_citizen && publicSettings.blacklist_citizen_choosen) && (
                          <li>
                            {t('Am Not A Citizen Of The CITIZEN', {CITIZEN: publicSettings.blacklist_citizen_choosen})}
                          </li>
                        )}
                        <li>{t('Do Not Hold A Permanent Residence And Work Permit For The US (Green Card), Canada Or Australia')}</li>
                        <li>{t('Have No Residence Or Principal Place Of Business In The USA, Canada Or Australia Or Their Respective Territories')}</li>
                        <li>{t('Am Not A Corporation Or Any Other Asset Organized Under The Laws Of The United States, Canada Or Australia, The Income Of Which Is Governed By The Laws Of Canada Or Australia')}</li>
                        <li>{t('Am Not On Any Of The Sanction Lists Of The European Union, The United States, Canada Or Australia')}</li>
                      </ul>
                    </li>
                    <li>{t('I Have Duly Noted The Risks And Their Potential Implications Described In The Token Sale Agreement And Hereby Accept Them')}</li>
                  </ul>
                ) : (
                  <ul>
                    <li>{t('I Accept The Subscription Applications')}</li>
                    <li>
                      <Trans
                        i18nKey="I Have Duly Noted The Sales Restrictions Stipulated In The Prospectus And Hereby Confirm That Those Restrictions Are Observed, Especially I Certify That I As A Single Natural Person Or Legal Entity"
                        t={t}
                        components={{
                          //eslint-disable-next-line jsx-a11y/anchor-has-content
                          prospectusLink: prospectusUrl ? <a href={prospectusUrl} target="_blank" rel="noopener noreferrer"> {t(prospectusOrIssuing)} </a> : <span />
                        }}
                      />
                      <ul>
                        { (publicSettings.enable_blacklist_citizen && publicSettings.blacklist_citizen_choosen) && (
                          <li>
                            {t('Am Not A Citizen Of The CITIZEN', {CITIZEN: publicSettings.blacklist_citizen_choosen})}
                          </li>
                        )}
                        <li>{t('Do Not Hold A Permanent Residence And Work Permit For The US (Green Card), Canada Or Australia')}</li>
                        <li>{t('Have No Residence Or Principal Place Of Business In The USA, Canada Or Australia Or Their Respective Territories')}</li>
                        <li>{t('Am Not A Corporation Or Any Other Asset Organized Under The Laws Of The United States, Canada Or Australia, The Income Of Which Is Governed By The Laws Of Canada Or Australia')}</li>
                        <li>{t('Am Not On Any Of The Sanction Lists Of The European Union, The United States, Canada Or Australia')}</li>
                      </ul>
                    </li>
                    <li>
                      <Trans
                        i18nKey="I Have Duly Noted The Risks And Their Potential Implications Described In The Prospectus And Hereby Accept Them"
                        t={t}
                        components={{
                          //eslint-disable-next-line jsx-a11y/anchor-has-content
                          prospectusLink: prospectusUrl ? <a href={prospectusUrl} target="_blank" rel="noopener noreferrer"> {t(prospectusOrIssuing)} </a> : <span />
                        }}
                      />
                    </li>
                  </ul>
                )
              )}
            </CardInformation>
          </div>
        </div>
      )}

      {
        (
          isShowDeclarationOfConsent4 ||
          isShowDeclarationOfConsent5 ||
          isShowDeclarationOfConsent6 ||
          isShowDeclarationOfConsent7
        ) && (
          <div className="settings__item" style={{ display: "none" }}>
            <div className="card__head">
              <div className="title-primary card__title">{ t('Declaration of Consent') }</div>
            </div>

            <div className="settings__fieldset">
              {isShowDeclarationOfConsent4 && (
                <>
                  <SwitchCheckboxTheme
                    switchText={(
                      <Trans
                        i18nKey={disclaimerText[publicSettings.token_type].declarationOfConsent4}
                        t={t}
                        components={{
                          FULL_NAME: `${values.firstName} ${values.middleName} ${values.lastName}`,
                          COMPANY: publicSettings.company.name,
                          //eslint-disable-next-line jsx-a11y/anchor-has-content
                          consentLink: <a href="/declaration-of-consent" target="_blank" rel="noopener noreferrer"> {t('Declaration of Consent')} </a>,
                          //eslint-disable-next-line jsx-a11y/anchor-has-content
                          blackMantaLink: <a href="https://blackmanta.capital/" target="_blank" rel="noopener noreferrer"> {t('and BMCP GmbH and its Affiliated Companies As The Issuers Investment Broker')} </a>,
                        }}
                      />
                    )}
                    value="declarationOfConsent4"
                    onChange={({ target: { value } }) => setDisclaimer({ ...disclaimer, [value]: !disclaimer[value] })}
                    isGrayText
                    isLeftSwitch
                    isRemoveLine
                    defaultChecked={disclaimer.declarationOfConsent4}
                  />

                  {publicSettings.token_type === TOKEN_TYPES.NONE && (
                    <div className="field">
                      <div className="space space__24">{' '}</div>
                      <CardInformation
                        color="card__information-red"
                        title={t('Warnings')}
                      >
                        <>
                          { t('noneDeclarationOfConsent4Warning', {
                            SHARES_OR_TOKENS: t(publicSettings.rename_share_to_token ? 'Tokens' : 'Shares')
                          }) }
                        </>
                      </CardInformation>
                    </div>
                  )}
                </>
              )}

              {isShowDeclarationOfConsent5 && (
                <>
                  <SwitchCheckboxTheme
                    switchText={(
                      <Trans
                        i18nKey={disclaimerText[publicSettings.token_type].declarationOfConsent5}
                        t={t}
                        components={{
                          //eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label
                          policyLink: <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" />,
                        }}
                      />
                    )}
                    value="declarationOfConsent5"
                    onChange={({ target: { value } }) => setDisclaimer({ ...disclaimer, [value]: !disclaimer[value] })}
                    isGrayText
                    isLeftSwitch
                    isRemoveLine
                    defaultChecked={disclaimer.declarationOfConsent5}
                  />
                </>
              )}

              {isShowDeclarationOfConsent6 && (
                <SwitchCheckboxTheme
                  switchText={ t(disclaimerText[publicSettings.token_type].declarationOfConsent6) }
                  value="declarationOfConsent6"
                  onChange={({ target: { value } }) => setDisclaimer({ ...disclaimer, [value]: !disclaimer[value] })}
                  isGrayText
                  isLeftSwitch
                  isRemoveLine
                  defaultChecked={disclaimer.declarationOfConsent6}
                />
              )}

              {isShowDeclarationOfConsent7 && (
                <SwitchCheckboxTheme
                  switchText={(
                    <Trans
                      i18nKey={disclaimerText[publicSettings.token_type].declarationOfConsent7}
                      t={t}
                      components={{
                        PROSPECTUS_OR_ISSUING: t(prospectusOrIssuing),
                        //eslint-disable-next-line jsx-a11y/anchor-has-content
                        prospectusLink: prospectusUrl ? <a href={prospectusUrl} target="_blank" rel="noopener noreferrer"> {t(prospectusOrIssuing)} </a> : <span />
                      }}
                    />
                  )}
                  value="declarationOfConsent7"
                  onChange={({ target: { value } }) => setDisclaimer({ ...disclaimer, [value]: !disclaimer[value] })}
                  isGrayText
                  isLeftSwitch
                  isRemoveLine
                  defaultChecked={disclaimer.declarationOfConsent7}
                />
              )}
            </div>
          </div>
        )
      }

      <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
        <button className="button-stroke" onClick={goToPrevStep}>{t('Previous')}</button>
        {loading && (
          <CircularProgress />
          )}
        {!loading && (
          <button className="button" onClick={submitKyc} disabled={isDisableSubmitButton()}>{t('Submit')}</button>
        )}
      </div>
    </div>
  </div>
  )
}

export default CardDisclaimer;
