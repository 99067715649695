import React, { useEffect, useState } from 'react'
import Web3 from 'web3'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/react-hooks'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'

import { TRANSACTION_INFO_SCHEME } from '../../schemas/transaction'
import { formatDate } from '../../utils/table'
import { toaster, isProduction, getExternalFileUrl } from '../../utils'
import contractAbi from '../../constants/contractAbi'
import {
  TRANSACTION,
  VOLT_GET_PAYMENT_DETAILS,
  CHANGE_TRANSACTION_STATUS,
  UPDATE_CROWDSALE_BUY_TOKEN_TRANSACTION_HASH,
  UPDATE_TRANSACTION,
} from '../../queriesAndMutations'
import metamaskService from '../../services/metamask'
import { usePublicSettings, useMe } from '../../myHooks'
import { TRANSACTIONS_STATUS_TYPES, PAYMENT_TYPES } from '../../constants/transaction'
import { LOOKUP_TRANSFER_ID } from '../../queriesAndMutations/sendwyreMutation'
import Icon from "../Icon"
import CrowdsaleSpendAllowance from '../CrowdsaleSpendAllowance'
import ImageUpload from '../ImageUpload'

const useStyles = makeStyles(() => ({
  mainTxInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "4px",
    border: "1px solid #d2dde9",
    padding: "10px 30px",
    "&>div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "&>span": {
        textAlign: "center",
      },
    },
  },
  info: {
    borderRadius: "4px",
    border: "1px solid #d2dde9",
    "&>li": {
      display: "flex",
    },
  },
  infoHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 30px",
    fontSize: "14px",
    fontWeight: 500,
    color: "#758698",
    width: "190px",
    textAlign: "center",
  },
  infoDes: {
    display: "flex",
    alignItems: "center",
    padding: "10px 30px",
    fontSize: "14px",
    color: "#495463",
    fontWeight: 400,
    flexGrow: 1,
    borderBottom: "1px solid #d2dde9",
    borderLeft: "1px solid #d2dde9",
    width: "calc(100% - 190px)",
    textAlign: "center",
  },
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  voltLink: {
    paddingLeft: "10px",
  },
}));

const STABLE_COIN_ENUM = {
  dai: 1,
  usdc: 2,
  eure: 3,
  usdt: 4,
  busd: 5,
}

const COIN_DECIMALS = {
  dai: 18,
  usdc: 6,
  eure: 18,
  usdt: 6,
  busd: 18,
  rate: 18,
}

const payViaMetamask = async (settings, transaction) => {
  if (metamaskService.isMetamaskNotAvailable()) {
    throw new Error('You need to install Metamask to use this feature');
  }

  if (settings.smart_contract_type === 'token_contract') {
    throw new Error('This smart contract does not support this method. Please contact admin for support');
  }

  if (!transaction.user.wallet_address) {
    throw new Error('You have not provide your wallet yet');
  }

  switch (settings.smart_contract_network) {
  case 'mainnet':
    await metamaskService.requireNetwork(isProduction ? 'mainnet' : 'sepolia')

    break

  case 'polygon':
    await metamaskService.requireNetwork(isProduction ? 'polygon' : 'mumbai')

    break

  case 'xinfin':
    await metamaskService.requireNetwork(isProduction ? 'xinfin' : 'apothem')

    break

  default:
    throw new Error('Network not supported');
  }

  await metamaskService.requestAccounts()

  const account = metamaskService.getCurrentAddress()
  const web3 = new Web3(window.ethereum);
  const contract = new web3.eth.Contract(contractAbi.crowdsale, metamaskService.formatAddress(settings.smart_contract_address));

  if (!(await contract.methods.isWhitelisted(transaction.user.wallet_address))) {
    throw new Error('Your wallet has not been whitelisted. Please wait for approval or contact admin for support');
  }

  const rate = await contract.methods.rate().call();

  // const [daiPerToken, daiPerEth] = await Promise.all([
  //   contract.methods.rate().call(),
  //   contract.methods.calculateDAIForEther(1).call(),
  // ]);

  // const daiAmount = transaction.token_amount * daiPerToken;
  const BN = Web3.utils.BN

  if (transaction.currency === 'eth') {
    const ethRate = await contract.methods.calculateEthRate().call()
    const ethRateBN = new BN(ethRate)

    const tokenAmount = new BN(Math.ceil(transaction.token_amount));
    const ethAmount = tokenAmount.mul(ethRateBN).add(new BN(1000));

    if (ethAmount > await metamaskService.getBalance(account)) {
      throw new Error('Your wallet has not had enough assets to perform the action');
    }

    const { transactionHash } = await contract.methods
      .buyTokens(metamaskService.formatAddress(transaction.user.wallet_address), 0, 0)
      .send({
        from: account,
        value: ethAmount,
      });

    return transactionHash
  } else if (Object.keys(STABLE_COIN_ENUM).includes(transaction.currency)) {
    const currencyAmount = transaction.token_amount * (rate / 10 ** COIN_DECIMALS.rate) * (10 ** COIN_DECIMALS[transaction.currency])
    let coinAddress

    switch (transaction.currency) {
      case 'dai':
        coinAddress = settings.smart_contract_dai_address
        break;
      case 'usdc':
        coinAddress = settings.smart_contract_usdc_address
        break;
      case 'usdt':
        coinAddress = settings.smart_contract_usdt_address
        break;
      case 'eure':
        coinAddress = settings.smart_contract_eure_address
        break;
      case 'busd':
        coinAddress = settings.smart_contract_busd_address
        break;
      default:
        coinAddress = null
    }

    const coinContract = new web3.eth.Contract(
      [
        {
          constant: true,
          inputs: [{ name: "account", type: "address" }],
          name: "balanceOf",
          outputs: [{ name: "", type: "uint256" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
      ],
      coinAddress
    );

    if (currencyAmount > await coinContract.methods.balanceOf(account)) {
      throw new Error('Your wallet has not had enough assets to perform the action');
    }

    const { transactionHash } = await contract.methods
      .buyTokens(metamaskService.formatAddress(transaction.user.wallet_address), BigInt(currencyAmount).toString(), `${STABLE_COIN_ENUM[transaction.currency]}`)
      .send({
        from: account,
        value: 0,
      });

    return transactionHash
  }

  return "";
}

const Transaction = ({ id, isAdmin = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data: { transaction } = {}, refetch } = useQuery(TRANSACTION, {
    variables: {
      id,
    },
    fetchPolicy: "network-only",
  });

  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { me } = {} } = useMe();

  const bankDetails =
    (publicSettings.bank || {})[(transaction || {}).currency] || {};

  const ethAddress = ((publicSettings && publicSettings.crypto) || {})
    .eth_address;

  const formatPaymentRef = (transaction) =>
    (transaction.investhub_ref ? `INV${transaction.investhub_ref}-${transaction.payment_ref}` : `TNX${transaction.id}-${transaction.payment_ref}`);

  const [getVoltPaymentDetails, { data: { voltGetPaymentDetails } = {} }] =
    useMutation(VOLT_GET_PAYMENT_DETAILS);

  const [updateCrowdsaleBuyTokenTransactionHash] =
    useMutation(UPDATE_CROWDSALE_BUY_TOKEN_TRANSACTION_HASH);

  const [updateTransaction, updateTransactionLoading] = useMutation(UPDATE_TRANSACTION);

  const [getSendwyrePaymentDetails, { data: { lookupTransferId } = {} }] =
    useMutation(LOOKUP_TRANSFER_ID);

  const [loadingVolt, setLoadingVolt] = useState(false);
  const [loadingSendwyre, setLoadingSendwyre] = useState(false);
  const [loadingBuyToken, setLoadingBuyToken] = useState(false);
  const [isApproveContract, setIsApproveContract] = useState(false);
  const [values, setValues] = useState({
    bankTransferUpload: ''
  })

  const [changeTransactionStatus] = useMutation(CHANGE_TRANSACTION_STATUS);

  const [voltPaymentUrl, setVoltPaymentUrl] = useState("");

  useEffect(() => {
    if (publicSettings && transaction !== undefined && transaction.volt_transaction_id !== null) {
      setLoadingVolt(true);

      const encodedVoltPaymentId = btoa(transaction.volt_transaction_id);

      const newVoltPaymentUrl = `${publicSettings.voltio_url.replaceAll("api.", "checkout.")}en/${encodedVoltPaymentId}`;

      setVoltPaymentUrl(newVoltPaymentUrl);

      const voltPaymentDetailsInput = {
        volt_transaction_id: transaction.volt_transaction_id,
      };

      getVoltPaymentDetails({
        variables: { input: voltPaymentDetailsInput },
      }).then((response) => {
        setLoadingVolt(false);

        if (transaction.status === TRANSACTIONS_STATUS_TYPES.IN_PROGRESS && response && response.data && response.data.voltGetPaymentDetails) {
          const voltStatus = response.data.voltGetPaymentDetails.status;

          if (voltStatus === "COMPLETED") {
            changeTransactionStatus({
              variables: {
                id: transaction.id,
                status: TRANSACTIONS_STATUS_TYPES.APPROVED,
              },
            }).then((response) => {
              refetch();
            }).catch(() => {});
          }

          if (voltStatus === "ERROR_AT_BANK") {
            changeTransactionStatus({
              variables: {
                id: transaction.id,
                status: TRANSACTIONS_STATUS_TYPES.REJECTED,
              },
            }).then((response) => {
              refetch();
            }).catch(() => {});
          }
        }
      }).catch(() => {});
    }
    if (
      transaction !== undefined &&
      transaction.sendwyre_transfer_id !== null
    ) {
      setLoadingSendwyre(true);
      getSendwyrePaymentDetails({
        variables: { input: { transferId: transaction.sendwyre_transfer_id } },
      }).then((response) => {
        setLoadingSendwyre(false);
        console.log(response);
        if (transaction.status === TRANSACTIONS_STATUS_TYPES.IN_PROGRESS && response && response.data && response.data.lookupTransferId) {
          const sendwyreStatus = response.data.lookupTransferId.status;

          if (sendwyreStatus === "COMPLETED") {
            changeTransactionStatus({
              variables: {
                id: transaction.id,
                status: TRANSACTIONS_STATUS_TYPES.APPROVED,
              },
            }).then((response) => {
              refetch();
            }).catch(() => {});
          }
        }
      }).catch(() => {});
    }

    if (transaction && transaction.currency === 'eth') {
      setIsApproveContract(true)
    }

    if (transaction && transaction.payment_type === PAYMENT_TYPES.BANK_TRANSFER) {
      setValues({...values, id: transaction.id})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  const onSubmitUpdateTransaction = async () => {
    if (!transaction) {
      toaster.error(t('Transaction not found'));
    }

    if (transaction.payment_type !== PAYMENT_TYPES.BANK_TRANSFER) {
      toaster.error(t('Transaction type is not bank transfer'));
    }

    try {
      await updateTransaction({
        variables: {
          id: transaction.id,
          bankTransferUpload: values.bankTransferUpload && values.bankTransferUpload.img,
        }
      })
      toaster.success(t('Success'))
      refetch()
    } catch (error) {
      toaster.error(error.message)
    }
  }

  const renderVoltPaymentDetails = () => (
    <div>
      {loadingVolt ? (
        <div className={classes.circularProgressWrapper}>
          <CircularProgress />
        </div>
      ) : transaction.volt_transaction_id &&
        voltGetPaymentDetails !== undefined ? (
        voltGetPaymentDetails.status
      ) : null}
    </div>
  );

  const renderSendwyrePaymentDetails = () => (
    <div>
      {loadingSendwyre ? (
        <div className={classes.circularProgressWrapper}>
          <CircularProgress />
        </div>
      ) : transaction.sendwyre_transfer_id && lookupTransferId !== undefined ? (
        lookupTransferId.status
      ) : null}
    </div>
  );

  const renderVoltPaymentUrl = () => (
    <div>
      <span>{ t('Followup to complete payment with Volt') }:</span>
      <a
        href={voltPaymentUrl}
        className={classes.voltLink}
        rel="noopener noreferrer"
        target="_blank"
      >
        { t('PAYMENT LINK') }
      </a>
    </div>
  );

  const renderCashlinkStatus = (info) => {
    if (info) {
      return (
        <div>{ info.status.toUpperCase() } { info.error && `- ${info.error}` }</div>
      )
    }

    return (
      <div>PROCESSING</div>
    )
  }

  const renderExplorerLink = (network, hash) => {
    if (hash.includes('https')) {
      return (
        <a href={hash} target="_blank" rel="noopener noreferrer">
          {hash}
        </a>
      )
    }

    switch (network) {
    case 'mainnet':
      return (
        <a href={`https://etherscan.io/tx/${hash}`} target="_blank" rel="noopener noreferrer">
          {hash}
        </a>
      )

    case 'sepolia':
      return (
        <a href={`https://sepolia.etherscan.io/tx/${hash}`} target="_blank" rel="noopener noreferrer">
          {hash}
        </a>
      )

    case 'polygon':
      return (
        <a href={`https://polygonscan.com/tx/${hash}`} target="_blank" rel="noopener noreferrer">
          {hash}
        </a>
      )

    case 'mumbai':
      return (
        <a href={`https://mumbai.polygonscan.com/tx/${hash}`} target="_blank" rel="noopener noreferrer">
          {hash}
        </a>
      )

    case 'xinfin':
      return (
        <a href={`https://explorer.xinfin.network/txs/${hash}`} target="_blank" rel="noopener noreferrer">
          {hash}
        </a>
      )

    case 'apothem':
      return (
        <a href={`https://explorer.apothem.network/txs/${hash}`} target="_blank" rel="noopener noreferrer">
          {hash}
        </a>
      )

    default:
      return `${hash} (${network})`
    }
  }

  const renderTransactionLink = (info) => {
    switch (info.contract.network) {
      case 'eth-mainnet':
        return renderExplorerLink('mainnet', info.transaction_hash)
      case 'eth-sepolia':
        return renderExplorerLink('sepolia', info.transaction_hash)
      case 'polygon-mainnet':
        return renderExplorerLink('polygon', info.transaction_hash)
      case 'polygon-testnet':
        return renderExplorerLink('mumbai', info.transaction_hash)
      case 'stellar-pubnet':
        return (
          <a href={`https://stellarchain.io/tx/${info.transaction_hash}`} target="_blank" rel="noopener noreferrer">
            {info.transaction_hash}
          </a>
        )
      case 'stellar-testnet':
        return (
          <a href={`http://testnet.stellarchain.io/tx/${info.transaction_hash}`} target="_blank" rel="noopener noreferrer">
            {info.transaction_hash}
          </a>
        )
      default:
        return info.transaction_hash
    }
  }

  const transactionNavigation = [
    {
      title: t('Information')
    },
    {
      title: t('Details'),
    },
    {
      title: t('Token Details'),
    }
  ];

  const optionsTab = [];
  transactionNavigation.map((x) => optionsTab.push(x.title))
  const [activeTransactionTab, setActiveTransactionTab] = useState(optionsTab[0]);
  const [activeIndexTab, setActiveIndexTab] = useState(0);

  const handleTransactionTabClick = (x, index) => {
    setActiveIndexTab(index);
    setActiveTransactionTab(x);
  }

  const onPayViaMetamask = (transaction) => async () => {
    setLoadingBuyToken(true)

    try {
      const txHash = await payViaMetamask(publicSettings, transaction)

      await updateCrowdsaleBuyTokenTransactionHash({
        variables: {
          id: transaction.id,
          hash: txHash,
          network: publicSettings.smart_contract_network === 'mainnet'
            ? (isProduction ? 'mainnet' : 'sepolia')
            : (
              publicSettings.smart_contract_network === 'polygon'
                ? (isProduction ? 'polygon' : 'mumbai')
                : (isProduction ? 'xinfin' : 'apothem')
              )
        }
      })

      refetch()
    } catch (error) {
      toaster.error(error.message)
    }

    setLoadingBuyToken(false)
  }

  const renderTabInformation = () => {
    return (
      <div className="products__tab js-tabs-item active">
        <div className="tips">
          <div className="tips__list tips__list-clear">
            <div className="tips__item">
              <div className="tips__icon">
                <Icon
                  className={classNames('calendar')}
                  name='calendar'
                  size="24"
                />{" "}
              </div>

              <div className="tips__detail">
                <div className="tips__title">{ t('Tranx Date') }</div>

                <div className="tips__line">
                  <div className="tips__action">
                    {formatDate(transaction.created_at)}
                  </div>
                </div>
              </div>
            </div>

            <div className="tips__item">
              <div className="tips__icon">
                <Icon
                  className={classNames('schedule')}
                  name='schedule'
                  size="24"
                />{" "}
              </div>

              <div className="tips__detail">
                <div className="tips__title">{ t('Tranx Status') }</div>

                <div className="tips__line">
                  <div className="tips__action">
                    {transaction.status}
                    {loadingBuyToken && (
                      <div>
                        <CircularProgress />
                      </div>
                    )}
                    {
                      transaction && me &&
                      transaction.user.id === me.id &&
                      !transaction.tx_hash &&
                      transaction.payment_type === PAYMENT_TYPES.CRYPTO &&
                      ['eth', ...Object.keys(STABLE_COIN_ENUM)].includes(transaction.currency) &&
                      !loadingBuyToken && (
                        <>
                          {(publicSettings.smart_contract_type === 'token_contract' || isApproveContract) && (
                            <div>
                              <button
                                className="button button-small"
                                onClick={onPayViaMetamask(transaction)}
                              >
                                Pay via Metamask
                              </button>
                            </div>
                          )}

                          {Object.keys(STABLE_COIN_ENUM).includes(transaction.currency) && (
                            <div>
                              <CrowdsaleSpendAllowance
                                minAllowance={+transaction.currency_amount}
                                currency={transaction.currency}
                                setIsApproveContract={setIsApproveContract}
                                isApproveContract={isApproveContract}
                              />
                            </div>
                          )}
                        </>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>

            {isAdmin && (
              <div className="tips__item">
                <div className="tips__icon">
                  <Icon
                    className={classNames('user')}
                    name='user'
                    size="24"
                  />{" "}
                </div>

                <div className="tips__detail">
                  <div className="tips__title">
                    { t('KYC Status') }
                  </div>

                  <div className="tips__line">
                    <div className="tips__action">
                      {transaction.user.kyc_status}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="tips__item">
              <div className="tips__icon">
                  <Icon
                    className={classNames('edit')}
                    name='edit'
                    size="24"
                  />{" "}
              </div>

              <div className="tips__detail">
                <div className="tips__title">
                  { t('Tranx Approved Note') }
                </div>

                <div className="tips__line">
                  <div className="tips__action">
                    {transaction.approved_note}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <br />

        { transaction && transaction.payment_type === PAYMENT_TYPES.BANK_TRANSFER && !transaction.bankTransferUpload && (
          <>
            <div className="field easy-onboarding__individual-notolderthan3months">
              {!values.bankTransferUpload && (
                <ImageUpload
                  labelText={
                    <span>
                      {t('Proof of bank transfer')}
                    </span>
                  }
                  isRequired
                  buttonText={t("Click or drop images")}
                  buttonClassName="button"
                  maxFileSize={10000000}
                  withPreview
                  singleImagePick
                  singleFile
                  onChange={(img, imgURI) => {
                    let newImage = {}

                    if (img.length > 0 && imgURI.length > 0) {
                      newImage = {
                        img: img[0],
                        imgURI: imgURI[0],
                      }
                    }

                    setValues({ ...values, bankTransferUpload: newImage })
                  }}
                  accept='image/*, application/pdf'
                >
                  <div className="file__wrap_text text__center">
                    <p>
                      { t('Drag and drop the document') }
                    </p>
                  </div>
                </ImageUpload>
              )}

              {values.bankTransferUpload && (
                <div className={classNames('settings__top')}>
                  <div className="settings__label">
                    {values.bankTransferUpload.img.name}
                  </div>

                  <button
                    className="button-stroke button-small settings__button"
                    onClick={() => {
                      setValues({ ...values, bankTransferUpload: null })
                    }}
                  >
                    {t('Remove')}
                  </button>
                </div>
              )}
            </div>

            <button
              className="button"
              onClick={onSubmitUpdateTransaction}
              disabled={updateTransactionLoading.loading}
            >
              {t('Submit')}
            </button>
          </>
        )}

        { transaction && transaction.bankTransferUpload &&
          <div className="summary summary__document">
            <div className="summary__preview">
              <div className="summary__preview_aspect_ratio">
                <embed
                  src={getExternalFileUrl(transaction.bankTransferUpload.storage_key)}
                  height="300px"
                  width='auto'
                  type={transaction.bankTransferUpload.mime_type}
                />
              </div>

              <div className="summary__control">
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                <a
                  className="summary__button"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={getExternalFileUrl(transaction.bankTransferUpload.storage_key)}>
                  <Icon
                    className={classNames('link')}
                    name='link'
                    size="24"
                  />{" "}
                </a>
              </div>
            </div>

            <div className="summary__line">
              <div className="summary__title">
                {t('Proof of bank transfer')}
              </div>

              <div className="hide-full">
                {transaction.bankTransferUpload.storage_key}
              </div>
            </div>
          </div>
        }
      </div>
    );
  };

  const renderTabDetails = () => {
    return (
      <div className="products__tab js-tabs-item active">
        <div className="tips">
          <div className="tips__info">{ t('Details') }</div>

          <div className="tips__list tips__list-clear">
            {transaction.cashlink_id && (
              <div className="tips__item tips__item_full">
                <div className="tips__icon">
                  <Icon
                    className={classNames('pie-chart')}
                    name='pie-chart'
                    size="24"
                  />{" "}
                </div>

                <div className="tips__detail">
                  <div className="tips__title">TX Hash</div>

                  <div className="tips__line">
                    <div className="tips__action">
                      {
                        (transaction.tx_hash && renderTransactionLink(transaction.cashlink_info)) ||
                        (transaction.cashlink_info && renderCashlinkStatus(transaction.cashlink_info)) ||
                        'PROCESSING'
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}

            {transaction.allocate_tx_hash && (
              <div className="tips__item tips__item_full">
                <div className="tips__icon">
                  <Icon
                    className={classNames('pie-chart')}
                    name='pie-chart'
                    size="24"
                  />{" "}
                </div>

                <div className="tips__detail">
                  <div className="tips__title">Allocation TX Hash</div>

                  <div className="tips__line">
                    <div className="tips__action">
                      {renderExplorerLink(transaction.allocate_network, transaction.allocate_tx_hash)}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {Object.keys(TRANSACTION_INFO_SCHEME).map((key) => {
              const { label } = TRANSACTION_INFO_SCHEME[key];
              let value = transaction[key];

              if (
                TRANSACTION_INFO_SCHEME[key].type &&
                TRANSACTION_INFO_SCHEME[key].type === "date"
              ) {
                value = new Date(value).toDateString();
              }

              if (key === "payment_type" && value === "WALLET") {
                value = `${value} - Transaction No: TNX${transaction.id}`;
                if (transaction.wallet_transaction_id) {
                  value = `${value} - Transaction No: TNX${transaction.id} - Wallet transaction id: ${transaction.wallet_transaction_id}`;
                }
              }

              return (
                <div key={key} className="tips__item">
                  <div className="tips__icon">
                    <Icon
                      className={classNames('lightning')}
                      name='lightning'
                      size="24"
                    />{" "}
                  </div>

                  <div className="tips__detail">
                    <div className="tips__title">
                      {t(label)}
                    </div>

                    <div className="tips__line">
                      <div className="tips__action">
                        {t(value)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            { transaction.payment_type === PAYMENT_TYPES.CRYPTO ? (
              <div className="tips__item">
                <div className="tips__icon">
                  <Icon
                    className={classNames('lightning')}
                    name='lightning'
                    size="24"
                  />{" "}
                </div>

                <div className="tips__detail">
                  <div className="tips__title">{ t('Payment Address') }</div>
                  <div className="tips__line">
                    <div className="tips__action">{ethAddress}</div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          {transaction.payment_type === PAYMENT_TYPES.BANK_TRANSFER ? (
            <>
              <div className="space space__24">{' '}</div>
              <div className="space space__24">{' '}</div>
              <div className="tips__info">{ t('Details Bank Transfer') }</div>
              <div className="products__wrapper">
                <div className="products__table">
                  <div className="products__row">
                    <div className="products__col products__col-first-clear vert__middle">{ t('Account Name') }</div>
                    <div className="products__col vert__middle">{ t('Account Number') }</div>
                    <div className="products__col vert__middle">{ t('Bank Name') }</div>
                    <div className="products__col vert__middle">{ t('Bank Address') }</div>
                    <div className="products__col vert__middle">{ t('Routing Number') }</div>
                    <div className="products__col vert__middle">{ t('IBAN') }</div>
                    <div className="products__col vert__middle">Swift/BIC</div>
                    <div className="products__col vert__middle">{ t('Payment Reference') }</div>
                  </div>

                  <div className="products__row">
                    <div className="products__col products__col-first-clear vert__middle">{bankDetails.account_name}</div>
                    <div className="products__col vert__middle">{bankDetails.account_number}</div>
                    <div className="products__col vert__middle">{bankDetails.bank_name}</div>
                    <div className="products__col vert__middle">{bankDetails.bank_address}</div>
                    <div className="products__col vert__middle">{bankDetails.routing_number}</div>
                    <div className="products__col vert__middle">{bankDetails.iban}</div>
                    <div className="products__col vert__middle">{bankDetails["swift/bic"]}</div>
                    <div className="products__col vert__middle">{formatPaymentRef(transaction)}</div>
                  </div>
                </div>
              </div>
            </>
          ) : null }
        </div>

      </div>
    );
  };

  const renderTabTokenDetails = () => {
    return (
      <div className="products__tab js-tabs-item active">
        <div className="tips">
          <div className="tips__info">{ t('Token Details') }</div>

          <div className="tips__list tips__list-clear">
            <div className="tips__item">
              <div className="tips__icon">
                <Icon
                  className={classNames('payment')}
                  name='payment'
                  size="24"
                />{" "}
              </div>

              <div className="tips__detail">
                <div className="tips__title">{ t('Contribution') }</div>

                <div className="tips__line">
                  <div className="tips__action">
                    {+transaction.currency_amount} {transaction.currency}
                  </div>
                </div>
              </div>
            </div>

            <div className="tips__item">
              <div className="tips__icon">
                <Icon
                  className={classNames('list')}
                  name='list'
                  size="24"
                />{" "}
              </div>

              <div className="tips__detail">
                <div className="tips__title">{ t('Tokens Added To') }</div>

                <div className="tips__line">
                  <div className="tips__action">
                    {transaction.user.email}
                  </div>
                </div>
              </div>
            </div>

            <div className="tips__item">
              <div className="tips__icon">
                <Icon
                  className={classNames('pie-chart')}
                  name='pie-chart'
                  size="24"
                />{" "}
              </div>

              <div className="tips__detail">
                <div className="tips__title">{ t('Token Total') }</div>

                <div className="tips__line">
                  <div className="tips__action">
                    {+transaction.token_amount}
                  </div>
                </div>
              </div>
            </div>
          {transaction.status === 'APPROVED' && (
            <div className="tips__item">
              <div className="tips__icon">
                <Icon
                  className={classNames('pie-chart')}
                  name='pie-chart'
                  size="24"
                />{" "}
              </div>

              <div className="tips__detail">
                <div className="tips__title">{ t('Approved Token') }</div>

                <div className="tips__line">
                  <div className="tips__action">
                    {transaction.status === 'APPROVED' ? +transaction.approved_token || +transaction.token_amount : ''}
                  </div>
                </div>
              </div>
            </div>
          )}
          </div>
        </div>
        <div className="space space__16">{' '}</div>
      </div>
    );
  };

  return transaction ? (
    <>
      <div className="products card js-tabs">
        <div className="products__head kyc__head_transactionsdetail">
          <div className="title-primary products__title">
            { t('Overview') }
          </div>

          <div className="products__nav">
            {transactionNavigation.map((x, index) => (
              <button
                key={index}
                className={`products__link js-tabs-link ${index === activeIndexTab ? "active" : ""}`}
                onClick={() => handleTransactionTabClick(x.title, index)}
              >
                {x.title}
              </button>
            ))}

            <NavLink to="/admin/transactions" className="button button-small button_back">
              <Icon
                className={classNames('arrow-left')}
                name='arrow-left'
                size="24"
              />{" "}
              <span>
                { t('Back') }
              </span>
            </NavLink>
          </div>
        </div>

        <div className="products__container">
          {activeTransactionTab === optionsTab[0] && renderTabInformation()}

          {activeTransactionTab === optionsTab[1] && renderTabDetails()}

          {activeTransactionTab === optionsTab[2] && renderTabTokenDetails()}
        </div>
      </div>

      {transaction.payment_type === PAYMENT_TYPES.VOLT ? (
        <div className="products card js-tabs">
          <div className="products__head">
            <div className="title-red products__title">
              { t('Volt Payment Details') }
            </div>
          </div>

          <div className="products__container">
            <div className="tips">
              <div className="tips__list tips__list-clear tips__list-kyc">

                <div className="tips__item">
                  <div className="tips__icon">
                    <Icon
                      className={classNames('payment')}
                      name='payment'
                      size="24"
                    />{" "}
                  </div>

                  <div className="tips__detail">
                    <div className="tips__title">{ t('Payment status') }</div>

                    <div className="tips__line">
                      <div className="tips__action">
                      {renderVoltPaymentDetails()}
                      </div>
                    </div>
                  </div>
                </div>

                {voltGetPaymentDetails && voltGetPaymentDetails.status !== 'COMPLETED' && (
                  <div className="tips__item">
                    <div className="tips__icon">
                      <Icon
                        className={classNames('link')}
                        name='link'
                        size="24"
                      />{" "}
                    </div>

                    <div className="tips__detail">
                      <div className="tips__title">{ t('Payment link') }</div>

                      <div className="tips__line">
                        <div className="tips__action">
                          {renderVoltPaymentUrl()}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      ) : null}

      {transaction.payment_type === PAYMENT_TYPES.CRYPTODOTCOM && (
        <div className="products card js-tabs">
          <div className="products__head">
            <div className="title-red products__title">{ t('Crypto Payment Details') }</div>
          </div>

          <div className="products__container">
            <div className="tips">
              <div className="tips__list tips__list-clear">
                <div className="tips__item">
                  <div className="tips__icon">
                    <Icon
                      className={classNames('payment')}
                      name='payment'
                      size="24"
                    />{" "}
                  </div>

                  <div className="tips__detail">
                    <div className="tips__title">{ t('Payment id') }</div>

                    <div className="tips__line">
                      <div className="tips__action">
                        {transaction.crypto_payment_id}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {transaction.payment_type === PAYMENT_TYPES.SENDWYRE ? (
        <div className="products card js-tabs">
          <div className="products__head">
            <div className="title-red products__title">{ t('Sendwyre Payment Details') }</div>
          </div>

          <div className="products__container">
            <div className="tips">
              <div className="tips__list tips__list-clear">

                <div className="tips__item">
                  <div className="tips__icon">
                    <Icon
                      className={classNames('payment')}
                      name='payment'
                      size="24"
                    />{" "}
                  </div>

                  <div className="tips__detail">
                    <div className="tips__title">{ t('Payment status') }</div>

                    <div className="tips__line">
                      <div className="tips__action">
                        {renderSendwyrePaymentDetails()}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tips__item">
                  <div className="tips__icon">
                    <Icon
                      className={classNames('lock')}
                      name='lock'
                      size="24"
                    />{" "}
                  </div>

                  <div className="tips__detail">
                    <div className="tips__title">{ t('Payment ID') }</div>

                    <div className="tips__line">
                      <div className="tips__action">
                        {transaction.sendwyre_transfer_id}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  ) : null;
};

Transaction.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Transaction;
export { payViaMetamask };
