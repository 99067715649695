import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import cryptoIcon from '../../media/images/crypto.com.svg'
import voltLogo from '../../media/images/logo-volt.svg'
import Icon from '../../components/Icon'
import styles from './styles.module.scss'

const ForwardingPage = () => {
  const { t } = useTranslation()

  return (
    <div className={classNames('page-forwarding"', styles.module_page_forwarding)}>
      <div className={classNames('page-forwarding__wrapper', styles.module_page_wrapper)}>
        <div className={classNames('page-forwarding__status', styles.module_page_status)}>
          <div className={classNames('users__icon page-forwarding__first', styles.module_page_circlefirst)}>
            <span>
              <img src={cryptoIcon} alt="Logo" />
            </span>
          </div>

          <div className={classNames('users__icon page-forwarding__second', styles.module_page_circlesecond)}>
            <span>
              <Icon
                className={classNames('lock')}
                name='lock'
                size="24"
              />{" "}
            </span>
          </div>

          <div className={classNames('users__icon page-forwarding__second', styles.module_page_circlethird)}>
            <span>
              <img src={voltLogo} alt="Logo volt" />
            </span>
          </div>

          <div className={classNames('forwarding__pulse', styles.module_page_pulse)}>
            <span className="point">{' '}</span>
            <span className="point">{' '}</span>
            <span className="point">{' '}</span>
            <span className="point">{' '}</span>
          </div>
        </div>

        <div className={classNames('page-forwarding__title title-forwarding', styles.module_page_title)}>
          {t('Forwarding you to Volt Mock Bank')}
        </div>
        <div className={classNames('quality__label page-forwarding__subtitle', styles.module_page_text)}>
          {t('You will need to login to your bank and approve the payment')}
        </div>
      </div>
    </div>
  )
}

export default ForwardingPage
