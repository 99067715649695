const CryptoJS = require("crypto-js");

const key = 'TUeSPaF^PWc75tEMBf!&'

const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
}

const decryptData = (ciphertext) => {
  const data = CryptoJS.AES.decrypt(ciphertext, key).toString(CryptoJS.enc.Utf8);
  return data ? JSON.parse(data) : "";
}

export const getKycData = () => {
  const data = localStorage.getItem('kycData') || '';
  return decryptData(data);
}

export const setKycData = (data) => {
  const ciphertext = encryptData(data);
  return localStorage.setItem('kycData', ciphertext);
}

export const getKybData = () => {
  const data = localStorage.getItem('kybData') || '';
  return decryptData(data);
}

export const setKybData = (data) => {
  const ciphertext = encryptData(data);
  return localStorage.setItem('kybData', ciphertext);
}
