export default {
  /**
   * Get session token from cookie
   *
   * @return {String|null}
   */
  getSessionToken() {
    const cookie = document.cookie.replace(/(?:(?:^|.*;\s*)chatSession\s*=\s*([^;]*).*$)|^.*$/, '$1')

    if (cookie) {
      return cookie
    }

    return null
  },

  /**
   * Get token session address from cookie
   *
   * @return {String|null}
   */
  getSessionAddress() {
    const cookie = document.cookie.replace(/(?:(?:^|.*;\s*)chatAddress\s*=\s*([^;]*).*$)|^.*$/, '$1')

    if (cookie) {
      return cookie
    }

    return null
  },

  /**
   * Save session token to cookie
   *
   * @param {String} address
   * @param {String} token
   */
  saveSessionToken(address, token) {
    document.cookie = `chatSession=${token}; Max-Age=3600`
    document.cookie = `chatAddress=${address}; Max-Age=3600`
  },

  /**
   * Extend session cookie lifetime for 1 hour more from current moment
   */
  extendSessionTokenLifeTime() {
    const address = this.getSessionAddress()
    const token = this.getSessionToken()

    if (token) {
      this.saveSessionToken(address, token)
    }
  },

  /**
   * Remove session token from cookie
   */
  clearChatSession() {
    const passedTime = new Date(+(new Date()) - 86400000).toUTCString()
    console.log(passedTime)

    document.cookie = `chatSession=; Expires=${passedTime}`
    document.cookie = `chatAddress=; Expires=${passedTime}`
  },
}
