// eslint-disable-next-line import/prefer-default-export
export const yearlySalaryOptions = [
  "0-30’000 CHF",
  "30’000-60’000 CHF",
  "60’000-100’000 CHF",
  "100’000-150’000 CHF",
  "150’000-250’000 CHF",
  "250’000-500’000 CHF",
  "500’000-1’000’000 CHF",
  "1’000’000-2’500’000 CHF",
  "2’500’000-5’000’000 CHF",
  "Over 5’000’000 CHF",
]
