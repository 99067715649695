import gql from 'graphql-tag'

export const SAVE_AND_SEND_VERIFY_PHONE_NUMBER_SMS = gql`
  mutation saveAndSendVerifyPhoneNumberSms($phoneNumber: String!) {
    saveAndSendVerifyPhoneNumberSms(phoneNumber: $phoneNumber)
  }
`;

export const VERIFY_PHONE_NUMBER = gql`
  mutation verifyPhoneNumber($code: String!) {
    verifyPhoneNumber(code: $code)
  }
`;

export const SEND_VERIFY_PHONE_NUMBER_SMS = gql`
  mutation sendVerifyPhoneNumberSms {
    sendVerifyPhoneNumberSms
  }
`;
