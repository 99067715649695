import React from 'react'
import { useTranslation } from 'react-i18next'
import CardForm from './CardForm'
import SidebarNav from './SidebarNav'

const EasyOnBoardingDetailofBirth = ({
  values,
  setValues,
  goToNextStep,
  goToPrevStep,
  goToStep,
}) => {
  const { t } = useTranslation();

  return (
    <div className="page__inner page__inner-onboarding">
      <div className="page__container">
        <div className="page__title h3">{t('Subscription details')}</div>

        <div className="page__row">
          <div className="page__col">
            <CardForm
              values={values}
              setValues={setValues}
              goToNextStep={goToNextStep}
              goToPrevStep={goToPrevStep}
            />
          </div>

          <div className="page__col">
            <SidebarNav goToStep={goToStep} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EasyOnBoardingDetailofBirth;
