import React from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'
import { usePublicSettings } from '../myHooks'

const KycCustomerVideoIdentIdnow = ({
  customer,
  onComplete,
}) => {
  const { t } = useTranslation()
  const { data: { publicSettings = {} } = {}, loading } = usePublicSettings()

  if (!customer.idnowIdent) {
    return (
      <p className="text__red">{t('Application not found')}</p>
    )
  }

  if (loading) {
    return (
      <CircularProgress />
    )
  }

  if (customer.idnowIdent.status !== 'NOT_STARTED') {
    return (
      <p>{t('The identification has been completed.')}</p>
    )
  }

  const settings = publicSettings.identification.ident_services.idnow || {}
  const host = settings.region === 'switzerland'
    ? `https://go.online-ident.ch`
    : `https://go.idnow.de`
  const identificationUrl = `${host}/${customer.idnowIdent.identCode}`

  setTimeout(() => {
    window.location.href = identificationUrl
  }, 1000);

  return (
    <p>{t('Redirect to IDnow service...')}</p>
  )
}

export default KycCustomerVideoIdentIdnow
