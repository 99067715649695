import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MenuItem,
  CircularProgress,
} from '@material-ui/core'
import { usePublicSettings } from '../../../myHooks'
import InputThemeField from '../../../components/InputThemeField'
import DropdownMaterial from '../../../components/DropdownMaterial'
import { COUNTRY_LIST, RESTRICTED_NATIONALITY, RESTRICTED_COUNTRY_LIST } from '../../../constants/customer'
import { getCountryList, getNationalityList } from '../../../utils'

const CardForm = ({
  values,
  setValues,
  goToNextStep,
  goToPrevStep,
  submitKyc,
  loading,
}) => {
  const { t } = useTranslation();
  const [showNationalityTwo, setShowNationalityTwo] = useState(false);
  const [showNationalityThree, setShowNationalityThree] = useState(false);
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const isCryptoadvisory = window.location.host === 'kyc.cryptoadvisory.li' || window.location.host === 'dev-kyc.cryptoadvisory.li';
  const countryList = getCountryList(publicSettings, isCryptoadvisory);
  const nationalityList = getNationalityList(publicSettings, isCryptoadvisory);

  return (
    <div className="card easy-onboarding__addressnationality">
      <div className="settings__wrapper">
        <div className="card__head card__head-kyc-1">
          <div className="title-green card__title">
            {t('Address, Nationality')}
          </div>
          <div className="refund__content card__head-content">
            <p>{ t('Due to regulation we require more information about your Source of Wealth and Source of Funds. Alongside some more personal information regarding your residential address and Nationality.') }</p>
          </div>
        </div>

        <div className="settings__fieldset">
          <div className="settings__row flex__row flex__wrap have__fields">
            <InputThemeField
              classWrapper="field easy-onboarding__individual-placeofbirth"
              classLabel="field__label"
              classInput="field__input"
              required
              label={t('Place Of Birth')}
              value={values.placeOfBirth}
              onChange={({ target: { value } }) => setValues({ ...values, placeOfBirth: value })}
            />

            <DropdownMaterial
              label={ t('Nationality') }
              classWrapper="easy-onboarding__individual-nationality"
              isRequired
              state={values}
              setState={setValues}
              propertyName="nationality"
              isBackgroundWhite
              helperText={
                <>
                  { !showNationalityTwo &&
                      <>
                        <span className="help__item help__item_blue help__item-nospace_x" onClick={() => setShowNationalityTwo(true)}>
                          {t('Add new nationality')}
                        </span>
                      </>
                  }
                </>
              }
            >
              {nationalityList.map((option) => (
                <MenuItem key={option} value={(option || "")}>
                  {t(option)}
                </MenuItem>
              ))}

            </DropdownMaterial>

            { showNationalityTwo &&
              <DropdownMaterial
                classWrapper="easy-onboarding__individual-anothernationality"
                label={ t('Add another Nationality') }
                suffixLabel={
                  <>
                    <span className="help__item help__item_red help__item-nospace_x help__item-nospace_y" onClick={() => setShowNationalityTwo(false)}>
                      {t('Remove')}
                    </span>
                  </>
                }
                isRequired
                state={values}
                setState={setValues}
                propertyName="nationality_two"
                isBackgroundWhite
                helperText={
                  <>
                    { showNationalityTwo && !showNationalityThree &&
                      <>
                        <span className="help__item help__item_blue help__item-nospace_x help__item-nospace_y" onClick={() => setShowNationalityThree(true)}>
                          {t('Add new nationality')}
                        </span>
                      </>
                    }
                  </>
                }
              >
                {nationalityList.map((option) => (
                  <MenuItem key={option} value={(option || "")}>
                    {t(option)}
                  </MenuItem>
                ))}

              </DropdownMaterial>
            }

            { showNationalityThree &&
              <DropdownMaterial
                label={ t('Add another Nationality') }
                classWrapper="easy-onboarding__individual-anothernationality"
                suffixLabel={
                  <>
                    <span className="help__item help__item_red help__item-nospace_x help__item-nospace_y" onClick={() => setShowNationalityThree(false)}>
                      {t('Remove')}
                    </span>
                  </>
                }
                isRequired
                state={values}
                setState={setValues}
                propertyName="nationality_three"
                isBackgroundWhite
              >
                {nationalityList.map((option) => (
                  <MenuItem key={option} value={(option || "")}>
                    {t(option)}
                  </MenuItem>
                ))}

              </DropdownMaterial>
            }

            <DropdownMaterial
              label={ t('Country of Residence') }
              classWrapper="easy-onboarding__individual-countryofresidence"
              isRequired
              state={values}
              setState={setValues}
              propertyName="countryOfResidence"
              isBackgroundWhite
            >
              {countryList.map((option) => (
                <MenuItem key={option} value={(option || "")}>
                  {t(option)}
                </MenuItem>
              ))}
            </DropdownMaterial>

            <InputThemeField
              classWrapper="field easy-onboarding__individual-residentialaddress"
              classLabel="field__label"
              classInput="field__input"
              required
              label={t('Residential Address')}
              value={values.residentialAddress}
              onChange={({ target: { value } }) => setValues({ ...values, residentialAddress: value })}
            />

            <InputThemeField
              classWrapper="field easy-onboarding__individual-city"
              classLabel="field__label"
              classInput="field__input"
              required
              label={t('City')}
              value={values.city}
              onChange={({ target: { value } }) => setValues({ ...values, city: value })}
            />

            <InputThemeField
              classWrapper="field easy-onboarding__individual-postalcode"
              classLabel="field__label"
              classInput="field__input"
              required
              label={t('Postal Code')}
              value={values.postalCode}
              onChange={({ target: { value } }) => setValues({ ...values, postalCode: value })}
            />

          </div>
        </div>

        <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between easy-onboarding__addressnationality-buttons">
          <button className="button-stroke" onClick={goToPrevStep}>{t('Previous')}</button>
          {publicSettings &&
            (
              publicSettings.kyc_politically_exposed_persons ||
              !publicSettings.kyc_skip_legal_disclaimer
            ) && (
              <button className="button" onClick={goToNextStep}>{t('Continue')}</button>
            )
          }
          {
            publicSettings &&
            !loading &&
            !publicSettings.kyc_politically_exposed_persons &&
            publicSettings.kyc_skip_legal_disclaimer && (
              <button className="button" onClick={submitKyc}>{t('Submit')}</button>
            )
          }
          {loading && (
            <CircularProgress />
          )}
        </div>
      </div>
    </div>
  )
}

export default CardForm;
