export const RISK_PARAMETERS = [
  'COUNTRY_OF_NATIONALITY',
  'TRANSACTION',
  'COUNTRY_OF_RESIDENCE',
  'CLIENT_REPUTATION_RELATIONSHIP',
  'BENEFICIAL_OWNER',
  'SOURCE_OF_FUNDS',
  'SIZE_OF_TRANSACTION',
  'BUSINESS_COUNTRIES',
]

export const COUNTRY_OF_NATIONALITY = [
  { value: 'Afghanistan', level: 'High', score: 5 },
  { value: 'Albania', level: 'Enhanced', score: 4 },
  { value: 'Algeria', level: 'Enhanced', score: 4 },
  { value: 'American Samoa', level: 'High', score: 5 },
  { value: 'Andorra', level: 'Enhanced', score: 4 },
  { value: 'Angola', level: 'Enhanced', score: 4 },
  { value: 'Anguilla', level: 'Enhanced', score: 4 },
  { value: 'Antigua and Barbuda', level: 'Enhanced', score: 4 },
  { value: 'Argentina', level: 'High', score: 5 },
  { value: 'Armenia', level: 'Enhanced', score: 4 },
  { value: 'Aruba', level: 'Medium', score: 3 },
  { value: 'Australia', level: 'Low', score: 1 },
  { value: 'Austria', level: 'Low', score: 1 },
  { value: 'Azerbaijan', level: 'Enhanced', score: 4 },
  { value: 'Bahamas (the)', level: 'High', score: 5 },
  { value: 'Bahamas', level: 'High', score: 5 },
  { value: 'Bahrain', level: 'Enhanced', score: 4 },
  { value: 'Bangladesh', level: 'Enhanced', score: 4 },
  { value: 'Barbados', level: 'High', score: 5 },
  { value: 'Belarus', level: 'High', score: 5 },
  { value: 'Belgium', level: 'Low', score: 1 },
  { value: 'Belize', level: 'Medium', score: 3 },
  { value: 'Benin', level: 'Enhanced', score: 4 },
  { value: 'Bermuda', level: 'Medium', score: 3 },
  { value: 'Bhutan', level: 'Low', score: 1 },
  { value: 'Bolivia, Plurinational State of', level: 'Enhanced', score: 4 },
  { value: 'Bolivia', level: 'Enhanced', score: 4 },
  { value: 'Bosnia and Herzegovina', level: 'High', score: 5 },
  { value: 'Botswana', level: 'High', score: 5 },
  { value: 'Brazil', level: 'Enhanced', score: 4 },
  { value: 'British Indian Ocean Territory (the)', level: 'Low', score: 1 },
  { value: 'British Indian Ocean Territory', level: 'Low', score: 1 },
  { value: 'Brunei Darussalam', level: 'Low', score: 1 },
  { value: 'Bulgaria', level: 'Medium', score: 3 },
  { value: 'Burkina Faso', level: 'High', score: 5 },
  { value: 'Burundi', level: 'High', score: 5 },
  { value: 'Cabo Verde', level: 'Medium', score: 3 },
  { value: 'Cambodia', level: 'High', score: 5 },
  { value: 'Cameroon', level: 'Enhanced', score: 4 },
  { value: 'Canada', level: 'Low', score: 1 },
  { value: 'Cayman Islands (the)', level: 'High', score: 5 },
  { value: 'Cayman Islands', level: 'High', score: 5 },
  { value: 'Central African Republic (the)', level: 'High', score: 5 },
  { value: 'Central African Republic', level: 'High', score: 5 },
  { value: 'Chad', level: 'Enhanced', score: 4 },
  { value: 'Chile', level: 'Low', score: 1 },
  { value: 'China', level: 'High', score: 5 },
  { value: 'Colombia', level: 'High', score: 5 },
  { value: 'Comoros', level: 'Enhanced', score: 4 },
  { value: 'Congo', level: 'High', score: 5 },
  { value: 'Congo (the Democratic Republic of the)', level: 'High', score: 5 },
  { value: 'Congo The Democratic Republic of', level: 'High', score: 5 },
  { value: 'Cook Islands (the)', level: 'Medium', score: 3 },
  { value: 'Cook Islands', level: 'Medium', score: 3 },
  { value: 'Costa Rica', level: 'Medium', score: 3 },
  { value: "Côte d'Ivoire", level: 'High', score: 5 },
  { value: "Cote d'Ivoire", level: 'High', score: 5 },
  { value: 'Croatia', level: 'Low', score: 1 },
  { value: 'Cuba', level: 'High', score: 5 },
  { value: 'Curaçao', level: 'Medium', score: 3 },
  { value: 'Curacao', level: 'Medium', score: 3 },
  { value: 'Cyprus', level: 'Medium', score: 3 },
  { value: 'Czech Republic (the)', level: 'Low', score: 1 },
  { value: 'Czech Republic', level: 'Low', score: 1 },
  { value: 'Denmark', level: 'Low', score: 1 },
  { value: 'Djibouti', level: 'Enhanced', score: 4 },
  { value: 'Dominica', level: 'High', score: 5 },
  { value: 'Dominican Republic (the)', level: 'Enhanced', score: 4 },
  { value: 'Dominican Republic', level: 'Enhanced', score: 4 },
  { value: 'Ecuador', level: 'Enhanced', score: 4 },
  { value: 'Egypt', level: 'High', score: 5 },
  { value: 'El Salvador', level: 'Enhanced', score: 4 },
  { value: 'Equatorial Guinea', level: 'Enhanced', score: 4 },
  { value: 'Eritrea', level: 'High', score: 5 },
  { value: 'Estonia', level: 'Low', score: 1 },
  { value: 'Ethiopia', level: 'High', score: 5 },
  { value: 'Falkland Islands (the) [Malvinas]', level: 'Low', score: 1 },
  { value: 'Falkland Islands Malvinas', level: 'Low', score: 1 },
  { value: 'Faroe Islands (the)', level: 'Low', score: 1 },
  { value: 'Faroe Islands', level: 'Low', score: 1 },
  { value: 'Fiji', level: 'High', score: 5 },
  { value: 'Finland', level: 'Low', score: 1 },
  { value: 'France', level: 'Low', score: 1 },
  { value: 'French Guiana', level: 'Low', score: 1 },
  { value: 'French Polynesia', level: 'Low', score: 1 },
  { value: 'French Southern Territories (the)', level: 'Low', score: 1 },
  { value: 'French Southern Territories', level: 'Low', score: 1 },
  { value: 'Gabon', level: 'Enhanced', score: 4 },
  { value: 'Gambia (The)', level: 'Enhanced', score: 4 },
  { value: 'Gambia', level: 'Enhanced', score: 4 },
  { value: 'Georgia', level: 'Low', score: 1 },
  { value: 'Germany', level: 'Low', score: 1 },
  { value: 'Ghana', level: 'High', score: 5 },
  { value: 'Gibraltar', level: 'Medium', score: 2 },
  { value: 'Greece', level: 'Low', score: 1 },
  { value: 'Greenland', level: 'Low', score: 1 },
  { value: 'Grenada', level: 'Medium', score: 2 },
  { value: 'Guadeloupe', level: 'Low', score: 1 },
  { value: 'Guam', level: 'Medium', score: 5 },
  { value: 'Guatemala', level: 'Enhanced', score: 4 },
  { value: 'Guernsey', level: 'Medium', score: 2 },
  { value: 'Guinea', level: 'High', score: 5 },
  { value: 'Guinea-Bissau', level: 'High', score: 5 },
  { value: 'Guyana', level: 'High', score: 5 },
  { value: 'Haiti', level: 'Enhanced', score: 4 },
  { value: 'Holy See (the) [Vatican City State]', level: 'Low', score: 1 },
  { value: 'Holy See Vatican City State', level: 'Low', score: 1 },
  { value: 'Honduras', level: 'Enhanced', score: 4 },
  { value: 'Hong Kong', level: 'Low', score: 1 },
  { value: 'Hungary', level: 'Low', score: 1 },
  { value: 'Iceland', level: 'High', score: 5 },
  { value: 'India', level: 'High', score: 5 },
  { value: 'Indonesia', level: 'Enhanced', score: 4 },
  { value: 'Iran (the Islamic Republic of)', level: 'High', score: 5 },
  { value: 'Iran Islamic Republic of', level: 'High', score: 5 },
  { value: 'Iraq', level: 'High', score: 5 },
  { value: 'Ireland', level: 'Low', score: 1 },
  { value: 'Isle of Man', level: 'Medium', score: 3 },
  { value: 'Israel', level: 'Low', score: 1 },
  { value: 'Italy', level: 'Low', score: 1 },
  { value: 'Jamaica', level: 'High', score: 5 },
  { value: 'Japan', level: 'Low', score: 1 },
  { value: 'Jersey', level: 'Medium', score: 2 },
  { value: 'Jordan', level: 'Enhanced', score: 4 },
  { value: 'Kamerun', level: 'High', score: 5 },
  { value: 'Kazakhstan', level: 'Enhanced', score: 4 },
  { value: 'Kenya', level: 'Enhanced', score: 4 },
  { value: 'Kiribati', level: 'Medium', score: 2 },
  { value: "Korea (the Democratic People's Republic of)", level: 'High', score: 5 },
  { value: 'Korea (the Republic of)', level: 'Medium', score: 2 },
  { value: 'Korea (South)', level: 'Medium', score: 2 },
  { value: 'Kosovo', level: 'Enhanced', score: 4 },
  { value: 'Kuwait', level: 'Enhanced', score: 4 },
  { value: 'Kyrgyzstan', level: 'Enhanced', score: 4 },
  { value: "Laos People's Democratic Republic (the)", level: 'High', score: 5 },
  { value: 'Lao Peoples Democratic Republic of', level: 'High', score: 5 },
  { value: 'Latvia', level: 'Medium', score: 3 },
  { value: 'Lebanon', level: 'High', score: 5 },
  { value: 'Lesotho', level: 'Enhanced', score: 4 },
  { value: 'Liberia', level: 'Enhanced', score: 4 },
  { value: 'Libya', level: 'High', score: 5 },
  { value: 'Libyan Arab Jamahiriya', level: 'High', score: 5 },
  { value: 'Liechtenstein', level: 'Low', score: 1 },
  { value: 'Lithuania', level: 'Low', score: 1 },
  { value: 'Luxembourg', level: 'Medium', score: 2 },
  { value: 'Macau', level: 'Medium', score: 3 },
  { value: 'Macao', level: 'Medium', score: 3 },
  { value: 'North Macedonia', level: 'Enhanced', score: 4 },
  { value: 'Macedonia The former Yugoslav Republic of', level: 'Enhanced', score: 4 },
  { value: 'Madagascar', level: 'Enhanced', score: 4 },
  { value: 'Malawi', level: 'Enhanced', score: 4 },
  { value: 'Malaysia', level: 'Medium', score: 3 },
  { value: 'Maldives', level: 'High', score: 5 },
  { value: 'Mali', level: 'High', score: 5 },
  { value: 'Malta', level: 'Medium', score: 2 },
  { value: 'Marshall Islands (the)', level: 'Medium', score: 3 },
  { value: 'Marshall Islands', level: 'Medium', score: 3 },
  { value: 'Martinique', level: 'Medium', score: 3 },
  { value: 'Mauritania', level: 'Enhanced', score: 4 },
  { value: 'Mauritius', level: 'High', score: 5 },
  { value: 'Mayotte', level: 'Medium', score: 2 },
  { value: 'Mexico', level: 'Enhanced', score: 4 },
  { value: 'Micronesia (the Federated States of)', level: 'Medium', score: 3 },
  { value: 'Micronesia Federated States of', level: 'Medium', score: 3 },
  { value: 'Moldova (the Republic of)', level: 'Enhanced', score: 4 },
  { value: 'Monaco', level: 'Medium', score: 3 },
  { value: 'Mongolia', level: 'High', score: 5 },
  { value: 'Montenegro', level: 'Enhanced', score: 4 },
  { value: 'Morocco', level: 'Enhanced', score: 4 },
  { value: 'Mozambique', level: 'Enhanced', score: 4 },
  { value: 'Myanmar', level: 'High', score: 5 },
  { value: 'Namibia', level: 'Medium', score: 2 },
  { value: 'Nauru', level: 'Medium', score: 3 },
  { value: 'Nepal', level: 'Enhanced', score: 4 },
  { value: 'Netherlands (the)', level: 'Low', score: 1 },
  { value: 'Netherlands', level: 'Low', score: 1 },
  { value: 'New Caledonia', level: 'Low', score: 1 },
  { value: 'New Zealand', level: 'Low', score: 1 },
  { value: 'Nicaragua', level: 'High', score: 5 },
  { value: 'Niger (the)', level: 'Enhanced', score: 4 },
  { value: 'Niger', level: 'Enhanced', score: 4 },
  { value: 'Nigeria', level: 'High', score: 5 },
  { value: 'Niue', level: 'Medium', score: 3 },
  { value: 'Norway', level: 'Low', score: 1 },
  { value: 'Oman', level: 'High', score: 5 },
  { value: 'Pakistan', level: 'High', score: 5 },
  { value: 'Palau', level: 'High', score: 5 },
  { value: 'Palestine, State of', level: 'High', score: 5 },
  { value: 'Palestinian Occupied Territory', level: 'High', score: 5 },
  { value: 'Panama', level: 'High', score: 5 },
  { value: 'Papua New Guinea', level: 'Enhanced', score: 4 },
  { value: 'Paraguay', level: 'Enhanced', score: 4 },
  { value: 'Peru', level: 'Enhanced', score: 4 },
  { value: 'Philippines (the)', level: 'High', score: 5 },
  { value: 'Philippines', level: 'High', score: 5 },
  { value: 'Poland', level: 'Low', score: 1 },
  { value: 'Portugal', level: 'Low', score: 1 },
  { value: 'Puerto Rico', level: 'High', score: 5 },
  { value: 'Qatar', level: 'Low', score: 1 },
  { value: 'Réunion', level: 'Low', score: 1 },
  { value: 'Reunion', level: 'Low', score: 1 },
  { value: 'Romania', level: 'Medium', score: 2 },
  { value: 'Russian Federation (the)', level: 'Enhanced', score: 4 },
  { value: 'Russian Federation', level: 'Enhanced', score: 4 },
  { value: 'Rwanda', level: 'Low', score: 1 },
  { value: 'Saint Barthélemy', level: 'Low', score: 1 },
  { value: 'Saint Helena, Ascension and Tristan da Cunha', level: 'Low', score: 1 },
  { value: 'Saint Helena Ascension and Tristan da Cunha', level: 'Low', score: 1 },
  { value: 'Saint Kitts and Nevis', level: 'Medium', score: 3 },
  { value: 'Saint Lucia', level: 'Medium', score: 3 },
  { value: 'Saint Vincent and the Grenadines', level: 'Medium', score: 3 },
  { value: 'Samoa', level: 'High', score: 5 },
  { value: 'San Marino', level: 'Low', score: 1 },
  { value: 'Sao Tome and Principe', level: 'Medium', score: 3 },
  { value: 'Saudi Arabia', level: 'High', score: 5 },
  { value: 'Senegal', level: 'High', score: 5 },
  { value: 'Serbia', level: 'High', score: 5 },
  { value: 'Seychelles', level: 'High', score: 5 },
  { value: 'Sierra Leone', level: 'Enhanced', score: 4 },
  { value: 'Singapore', level: 'Medium', score: 2 },
  { value: 'Sint Maarten (Dutch part)', level: 'Medium', score: 3 },
  { value: 'Sint Maartin', level: 'Medium', score: 3 },
  { value: 'Slovakia', level: 'Medium', score: 2 },
  { value: 'Slovenia', level: 'Low', score: 1 },
  { value: 'Somalia', level: 'High', score: 5 },
  { value: 'South Africa', level: 'Enhanced', score: 4 },
  { value: 'South Sudan', level: 'High', score: 5 },
  { value: 'Spain', level: 'Low', score: 1 },
  { value: 'Sri Lanka', level: 'High', score: 5 },
  { value: 'Sudan (the)', level: 'High', score: 5 },
  { value: 'Sudan', level: 'High', score: 5 },
  { value: 'Suriname', level: 'Enhanced', score: 4 },
  { value: 'Sweden', level: 'Low', score: 1 },
  { value: 'Switzerland', level: 'Low', score: 1 },
  { value: 'Syrian Arab Republic (the)', level: 'High', score: 5 },
  { value: 'Syrian Arab Republic', level: 'High', score: 5 },
  { value: 'Taiwan (Province of China)', level: 'Low', score: 1 },
  { value: 'Taiwan', level: 'Low', score: 1 },
  { value: 'Tajikistan', level: 'Enhanced', score: 4 },
  { value: 'Tanzania, United Republic of', level: 'Enhanced', score: 4 },
  { value: 'Tanzania United Republic of', level: 'Enhanced', score: 4 },
  { value: 'Thailand', level: 'High', score: 5 },
  { value: 'Timor-Leste', level: 'Enhanced', score: 4 },
  { value: 'Timor Leste', level: 'Enhanced', score: 4 },
  { value: 'Togo', level: 'Enhanced', score: 4 },
  { value: 'Trinidad and Tobago', level: 'High', score: 5 },
  { value: 'Tunisia', level: 'High', score: 5 },
  { value: 'Turkey', level: 'High', score: 5 },
  { value: 'Turkmenistan', level: 'Enhanced', score: 4 },
  { value: 'Turks and Caicos Islands (the)', level: 'Medium', score: 3 },
  { value: 'Turks and Caicos Islands', level: 'Medium', score: 3 },
  { value: 'Uganda', level: 'High', score: 5 },
  { value: 'Ukraine', level: 'High', score: 5 },
  { value: 'United Arab Emirates (the)', level: 'Medium', score: 2 },
  { value: 'United Arab Emirates', level: 'Medium', score: 2 },
  { value: 'United Kingdom (the)', level: 'Low', score: 1 },
  { value: 'United Kingdom', level: 'Low', score: 1 },
  { value: 'United States (the)', level: 'Low', score: 1 },
  { value: 'United States', level: 'Low', score: 1 },
  { value: 'Uruguay', level: 'Low', score: 1 },
  { value: 'Uzbekistan', level: 'Enhanced', score: 4 },
  { value: 'Vanuatu', level: 'High', score: 5 },
  { value: 'Venezuela, Bolivarian Republic of ', level: 'High', score: 5 },
  { value: 'Venezuela Bolivarian Republic of', level: 'High', score: 5 },
  { value: 'Vietnam', level: 'Enhanced', score: 4 },
  { value: 'Virgin Islands (British)', level: 'Medium', score: 3 },
  { value: 'Virgin Islands British', level: 'Medium', score: 3 },
  { value: 'Virgin Islands (U.S.)', level: 'High', score: 5 },
  { value: 'Virgin Islands US', level: 'High', score: 5 },
  { value: 'Yemen', level: 'High', score: 5 },
  { value: 'Zambia', level: 'Enhanced', score: 4 },
  { value: 'Zimbabwe', level: 'High', score: 5 },
]

export const TRANSACTION = [
  { id: 1, description: 'Transfer from a bank situated in the EEA', level: 'Low', score: 1 },
  { id: 2, description: 'Bank transfer from outside EEA', level: 'Medium', score: 3 },
  { id: 3, description: 'Crypto payment, normal risk score, amount below 5000 CHF', level: 'Medium', score: 3 },
  { id: 4, description: 'Crypto payment, normal risk score, amount 5000 CHF or more', level: 'Enhanced', score: 4 },
  { id: 5, description: 'Crypto payment, high risk score', level: 'High', score: 5 },
  { id: 6, description: 'Transaction carried out in a manner that favours anonymity', level: 'High', score: 5, },
  { id: 7, description: 'Transaction inconsistent with business profile', level: 'High', score: 5, },
  { id: 8, description: 'Indicator of higher risk, ML/TF, organized crime hit', level: 'High', score: 5, },
]

export const COUNTRY_OF_RESIDENCE = [
  { value: 'Afghanistan', level: 'High', score: 5 },
  { value: 'Albania', level: 'High', score: 5 },
  { value: 'Algeria', level: 'Enhanced', score: 4 },
  { value: 'American Samoa', level: 'High', score: 5 },
  { value: 'Andorra', level: 'Enhanced', score: 4 },
  { value: 'Angola', level: 'Enhanced', score: 4 },
  { value: 'Anguilla', level: 'Enhanced', score: 4 },
  { value: 'Antigua and Barbuda', level: 'Enhanced', score: 4 },
  { value: 'Argentina', level: 'High', score: 5 },
  { value: 'Armenia', level: 'Enhanced', score: 4 },
  { value: 'Aruba', level: 'Medium', score: 3 },
  { value: 'Australia', level: 'Low', score: 1 },
  { value: 'Austria', level: 'Low', score: 1 },
  { value: 'Azerbaijan', level: 'Enhanced', score: 4 },
  { value: 'Bahamas (the)', level: 'High', score: 5 },
  { value: 'Bahamas', level: 'High', score: 5 },
  { value: 'Bahrain', level: 'Enhanced', score: 4 },
  { value: 'Bangladesh', level: 'Enhanced', score: 4 },
  { value: 'Barbados', level: 'High', score: 5 },
  { value: 'Belarus', level: 'High', score: 5 },
  { value: 'Belgium', level: 'Low', score: 1 },
  { value: 'Belize', level: 'Medium', score: 3 },
  { value: 'Benin', level: 'Enhanced', score: 4 },
  { value: 'Bermuda', level: 'Medium', score: 3 },
  { value: 'Bhutan', level: 'Low', score: 1 },
  { value: 'Bolivia, Plurinational State of', level: 'Enhanced', score: 4 },
  { value: 'Bolivia', level: 'Enhanced', score: 4 },
  { value: 'Bosnia and Herzegovina', level: 'High', score: 5 },
  { value: 'Botswana', level: 'High', score: 5 },
  { value: 'Brazil', level: 'Enhanced', score: 4 },
  { value: 'British Indian Ocean Territory (the)', level: 'Low', score: 1 },
  { value: 'British Indian Ocean Territory', level: 'Low', score: 1 },
  { value: 'Brunei Darussalam', level: 'Low', score: 1 },
  { value: 'Bulgaria', level: 'Medium', score: 3 },
  { value: 'Burkina Faso', level: 'High', score: 5 },
  { value: 'Burundi', level: 'High', score: 5 },
  { value: 'Cabo Verde', level: 'Medium', score: 3 },
  { value: 'Cambodia', level: 'High', score: 5 },
  { value: 'Cameroon', level: 'Enhanced', score: 4 },
  { value: 'Canada', level: 'Low', score: 1 },
  { value: 'Cayman Islands (the)', level: 'High', score: 5 },
  { value: 'Cayman Islands', level: 'High', score: 5 },
  { value: 'Central African Republic (the)', level: 'High', score: 5 },
  { value: 'Central African Republic', level: 'High', score: 5 },
  { value: 'Chad', level: 'Enhanced', score: 4 },
  { value: 'Chile', level: 'Low', score: 1 },
  { value: 'China', level: 'High', score: 5 },
  { value: 'Colombia', level: 'High', score: 5 },
  { value: 'Comoros', level: 'Enhanced', score: 4 },
  { value: 'Congo', level: 'High', score: 5 },
  { value: 'Congo (the Democratic Republic of the)', level: 'High', score: 5 },
  { value: 'Congo The Democratic Republic of', level: 'High', score: 5 },
  { value: 'Cook Islands (the)', level: 'Medium', score: 3 },
  { value: 'Cook Islands', level: 'Medium', score: 3 },
  { value: 'Costa Rica', level: 'Medium', score: 3 },
  { value: "Côte d'Ivoire", level: 'High', score: 5 },
  { value: "Cote d'Ivoire", level: 'High', score: 5 },
  { value: 'Croatia', level: 'Low', score: 1 },
  { value: 'Cuba', level: 'High', score: 5 },
  { value: 'Curaçao', level: 'Medium', score: 3 },
  { value: 'Curacao', level: 'Medium', score: 3 },
  { value: 'Cyprus', level: 'Medium', score: 3 },
  { value: 'Czech Republic (the)', level: 'Low', score: 1 },
  { value: 'Czech Republic', level: 'Low', score: 1 },
  { value: 'Denmark', level: 'Low', score: 1 },
  { value: 'Djibouti', level: 'Enhanced', score: 4 },
  { value: 'Dominica', level: 'High', score: 5 },
  { value: 'Dominican Republic (the)', level: 'Enhanced', score: 4 },
  { value: 'Dominican Republic', level: 'Enhanced', score: 4 },
  { value: 'Ecuador', level: 'Enhanced', score: 4 },
  { value: 'Egypt', level: 'High', score: 5 },
  { value: 'El Salvador', level: 'Enhanced', score: 4 },
  { value: 'Equatorial Guinea', level: 'Enhanced', score: 4 },
  { value: 'Eritrea', level: 'High', score: 5 },
  { value: 'Estonia', level: 'Low', score: 1 },
  { value: 'Ethiopia', level: 'High', score: 5 },
  { value: 'Falkland Islands (the) [Malvinas]', level: 'Low', score: 1 },
  { value: 'Falkland Islands Malvinas', level: 'Low', score: 1 },
  { value: 'Faroe Islands (the)', level: 'Low', score: 1 },
  { value: 'Faroe Islands', level: 'Low', score: 1 },
  { value: 'Fiji', level: 'High', score: 5 },
  { value: 'Finland', level: 'Low', score: 1 },
  { value: 'France', level: 'Low', score: 1 },
  { value: 'French Guiana', level: 'Low', score: 1 },
  { value: 'French Polynesia', level: 'Low', score: 1 },
  { value: 'French Southern Territories (the)', level: 'Low', score: 1 },
  { value: 'French Southern Territories', level: 'Low', score: 1 },
  { value: 'Gabon', level: 'Enhanced', score: 4 },
  { value: 'Gambia (The)', level: 'Enhanced', score: 4 },
  { value: 'Gambia', level: 'Enhanced', score: 4 },
  { value: 'Georgia', level: 'Low', score: 1 },
  { value: 'Germany', level: 'Low', score: 1 },
  { value: 'Ghana', level: 'High', score: 5 },
  { value: 'Gibraltar', level: 'Medium', score: 2 },
  { value: 'Greece', level: 'Low', score: 1 },
  { value: 'Greenland', level: 'Low', score: 1 },
  { value: 'Grenada', level: 'Medium', score: 2 },
  { value: 'Guadeloupe', level: 'Low', score: 1 },
  { value: 'Guam', level: 'Medium', score: 5 },
  { value: 'Guatemala', level: 'Enhanced', score: 4 },
  { value: 'Guernsey', level: 'Medium', score: 2 },
  { value: 'Guinea', level: 'High', score: 5 },
  { value: 'Guinea-Bissau', level: 'High', score: 5 },
  { value: 'Guyana', level: 'High', score: 5 },
  { value: 'Haiti', level: 'Enhanced', score: 4 },
  { value: 'Holy See (the) [Vatican City State]', level: 'Low', score: 1 },
  { value: 'Holy See Vatican City State', level: 'Low', score: 1 },
  { value: 'Honduras', level: 'Enhanced', score: 4 },
  { value: 'Hong Kong', level: 'Low', score: 1 },
  { value: 'Hungary', level: 'Low', score: 1 },
  { value: 'Iceland', level: 'High', score: 5 },
  { value: 'India', level: 'High', score: 5 },
  { value: 'Indonesia', level: 'Enhanced', score: 4 },
  { value: 'Iran (the Islamic Republic of)', level: 'High', score: 5 },
  { value: 'Iran Islamic Republic of', level: 'High', score: 5 },
  { value: 'Iraq', level: 'High', score: 5 },
  { value: 'Ireland', level: 'Low', score: 1 },
  { value: 'Isle of Man', level: 'Medium', score: 3 },
  { value: 'Israel', level: 'Low', score: 1 },
  { value: 'Italy', level: 'Low', score: 1 },
  { value: 'Jamaica', level: 'High', score: 5 },
  { value: 'Japan', level: 'Low', score: 1 },
  { value: 'Jersey', level: 'Medium', score: 2 },
  { value: 'Jordan', level: 'Enhanced', score: 4 },
  { value: 'Kamerun', level: 'High', score: 5 },
  { value: 'Kazakhstan', level: 'Enhanced', score: 4 },
  { value: 'Kenya', level: 'Enhanced', score: 4 },
  { value: 'Kiribati', level: 'Medium', score: 2 },
  { value: "Korea (the Democratic People's Republic of)", level: 'High', score: 5 },
  { value: 'Korea (the Republic of)', level: 'Medium', score: 2 },
  { value: 'Korea (South)', level: 'Medium', score: 2 },
  { value: 'Kosovo', level: 'Enhanced', score: 5 },
  { value: 'Kuwait', level: 'Enhanced', score: 4 },
  { value: 'Kyrgyzstan', level: 'Enhanced', score: 4 },
  { value: "Laos People's Democratic Republic (the)", level: 'High', score: 5 },
  { value: 'Lao Peoples Democratic Republic of', level: 'High', score: 5 },
  { value: 'Latvia', level: 'Medium', score: 3 },
  { value: 'Lebanon', level: 'High', score: 5 },
  { value: 'Lesotho', level: 'Enhanced', score: 4 },
  { value: 'Liberia', level: 'Enhanced', score: 4 },
  { value: 'Libya', level: 'High', score: 5 },
  { value: 'Libyan Arab Jamahiriya', level: 'High', score: 5 },
  { value: 'Liechtenstein', level: 'Low', score: 1 },
  { value: 'Lithuania', level: 'Low', score: 1 },
  { value: 'Luxembourg', level: 'Medium', score: 2 },
  { value: 'Macau', level: 'Medium', score: 3 },
  { value: 'Macao', level: 'Medium', score: 3 },
  { value: 'North Macedonia', level: 'Enhanced', score: 4 },
  { value: 'Macedonia The former Yugoslav Republic of', level: 'Enhanced', score: 4 },
  { value: 'Madagascar', level: 'Enhanced', score: 4 },
  { value: 'Malawi', level: 'Enhanced', score: 4 },
  { value: 'Malaysia', level: 'Medium', score: 3 },
  { value: 'Maldives', level: 'High', score: 5 },
  { value: 'Mali', level: 'High', score: 5 },
  { value: 'Malta', level: 'Medium', score: 2 },
  { value: 'Marshall Islands (the)', level: 'Medium', score: 3 },
  { value: 'Marshall Islands', level: 'Medium', score: 3 },
  { value: 'Martinique', level: 'Medium', score: 3 },
  { value: 'Mauritania', level: 'Enhanced', score: 4 },
  { value: 'Mauritius', level: 'High', score: 5 },
  { value: 'Mayotte', level: 'Medium', score: 2 },
  { value: 'Mexico', level: 'Enhanced', score: 4 },
  { value: 'Micronesia (the Federated States of)', level: 'Medium', score: 3 },
  { value: 'Micronesia Federated States of', level: 'Medium', score: 3 },
  { value: 'Moldova (the Republic of)', level: 'Enhanced', score: 4 },
  { value: 'Monaco', level: 'Medium', score: 3 },
  { value: 'Mongolia', level: 'High', score: 5 },
  { value: 'Montenegro', level: 'Enhanced', score: 4 },
  { value: 'Morocco', level: 'Enhanced', score: 4 },
  { value: 'Mozambique', level: 'Enhanced', score: 4 },
  { value: 'Myanmar', level: 'High', score: 5 },
  { value: 'Namibia', level: 'Medium', score: 2 },
  { value: 'Nauru', level: 'Medium', score: 3 },
  { value: 'Nepal', level: 'Enhanced', score: 4 },
  { value: 'Netherlands (the)', level: 'Low', score: 1 },
  { value: 'Netherlands', level: 'Low', score: 1 },
  { value: 'New Caledonia', level: 'Low', score: 1 },
  { value: 'New Zealand', level: 'Low', score: 1 },
  { value: 'Nicaragua', level: 'High', score: 5 },
  { value: 'Niger (the)', level: 'Enhanced', score: 4 },
  { value: 'Niger', level: 'Enhanced', score: 4 },
  { value: 'Nigeria', level: 'High', score: 5 },
  { value: 'Niue', level: 'Medium', score: 3 },
  { value: 'Norway', level: 'Low', score: 1 },
  { value: 'Oman', level: 'High', score: 5 },
  { value: 'Pakistan', level: 'High', score: 5 },
  { value: 'Palau', level: 'High', score: 5 },
  { value: 'Palestine, State of', level: 'High', score: 5 },
  { value: 'Palestinian Occupied Territory', level: 'High', score: 5 },
  { value: 'Panama', level: 'High', score: 5 },
  { value: 'Papua New Guinea', level: 'Enhanced', score: 4 },
  { value: 'Paraguay', level: 'Enhanced', score: 4 },
  { value: 'Peru', level: 'Enhanced', score: 4 },
  { value: 'Philippines (the)', level: 'High', score: 5 },
  { value: 'Philippines', level: 'High', score: 5 },
  { value: 'Poland', level: 'Low', score: 1 },
  { value: 'Portugal', level: 'Low', score: 1 },
  { value: 'Puerto Rico', level: 'High', score: 5 },
  { value: 'Qatar', level: 'Low', score: 1 },
  { value: 'Réunion', level: 'Low', score: 1 },
  { value: 'Reunion', level: 'Low', score: 1 },
  { value: 'Romania', level: 'Medium', score: 2 },
  { value: 'Russian Federation (the)', level: 'Enhanced', score: 4 },
  { value: 'Russian Federation', level: 'Enhanced', score: 4 },
  { value: 'Rwanda', level: 'Low', score: 1 },
  { value: 'Saint Barthélemy', level: 'Low', score: 1 },
  { value: 'Saint Helena, Ascension and Tristan da Cunha', level: 'Low', score: 1 },
  { value: 'Saint Helena Ascension and Tristan da Cunha', level: 'Low', score: 1 },
  { value: 'Saint Kitts and Nevis', level: 'Medium', score: 3 },
  { value: 'Saint Lucia', level: 'Medium', score: 3 },
  { value: 'Saint Vincent and the Grenadines', level: 'Medium', score: 3 },
  { value: 'Samoa', level: 'High', score: 5 },
  { value: 'San Marino', level: 'Low', score: 1 },
  { value: 'Sao Tome and Principe', level: 'Medium', score: 3 },
  { value: 'Saudi Arabia', level: 'High', score: 5 },
  { value: 'Senegal', level: 'High', score: 5 },
  { value: 'Serbia', level: 'High', score: 5 },
  { value: 'Seychelles', level: 'High', score: 5 },
  { value: 'Sierra Leone', level: 'Enhanced', score: 4 },
  { value: 'Singapore', level: 'Medium', score: 2 },
  { value: 'Sint Maarten (Dutch part)', level: 'Medium', score: 3 },
  { value: 'Sint Maartin', level: 'Medium', score: 3 },
  { value: 'Slovakia', level: 'Medium', score: 2 },
  { value: 'Slovenia', level: 'Low', score: 1 },
  { value: 'Somalia', level: 'High', score: 5 },
  { value: 'South Africa', level: 'Enhanced', score: 4 },
  { value: 'South Sudan', level: 'High', score: 5 },
  { value: 'Spain', level: 'Low', score: 1 },
  { value: 'Sri Lanka', level: 'High', score: 5 },
  { value: 'Sudan (the)', level: 'High', score: 5 },
  { value: 'Sudan', level: 'High', score: 5 },
  { value: 'Suriname', level: 'Enhanced', score: 4 },
  { value: 'Sweden', level: 'Low', score: 1 },
  { value: 'Switzerland', level: 'Low', score: 1 },
  { value: 'Syrian Arab Republic (the)', level: 'High', score: 5 },
  { value: 'Syrian Arab Republic', level: 'High', score: 5 },
  { value: 'Taiwan (Province of China)', level: 'Low', score: 1 },
  { value: 'Taiwan', level: 'Low', score: 1 },
  { value: 'Tajikistan', level: 'Enhanced', score: 4 },
  { value: 'Tanzania, United Republic of', level: 'Enhanced', score: 4 },
  { value: 'Tanzania United Republic of', level: 'Enhanced', score: 4 },
  { value: 'Thailand', level: 'High', score: 5 },
  { value: 'Timor-Leste', level: 'Enhanced', score: 4 },
  { value: 'Timor Leste', level: 'Enhanced', score: 4 },
  { value: 'Togo', level: 'Enhanced', score: 4 },
  { value: 'Trinidad and Tobago', level: 'High', score: 5 },
  { value: 'Tunisia', level: 'High', score: 5 },
  { value: 'Turkey', level: 'High', score: 5 },
  { value: 'Turkmenistan', level: 'Enhanced', score: 4 },
  { value: 'Turks and Caicos Islands (the)', level: 'Medium', score: 3 },
  { value: 'Turks and Caicos Islands', level: 'Medium', score: 3 },
  { value: 'Uganda', level: 'High', score: 5 },
  { value: 'Ukraine', level: 'High', score: 5 },
  { value: 'United Arab Emirates (the)', level: 'Medium', score: 2 },
  { value: 'United Arab Emirates', level: 'Medium', score: 2 },
  { value: 'United Kingdom (the)', level: 'Low', score: 1 },
  { value: 'United Kingdom', level: 'Low', score: 1 },
  { value: 'United States (the)', level: 'Low', score: 1 },
  { value: 'United States', level: 'Low', score: 1 },
  { value: 'Uruguay', level: 'Low', score: 1 },
  { value: 'Uzbekistan', level: 'Enhanced', score: 4 },
  { value: 'Vanuatu', level: 'High', score: 5 },
  { value: 'Venezuela, Bolivarian Republic of ', level: 'High', score: 5 },
  { value: 'Venezuela Bolivarian Republic of', level: 'High', score: 5 },
  { value: 'Vietnam', level: 'Enhanced', score: 4 },
  { value: 'Virgin Islands (British)', level: 'Medium', score: 3 },
  { value: 'Virgin Islands British', level: 'Medium', score: 3 },
  { value: 'Virgin Islands (U.S.)', level: 'High', score: 5 },
  { value: 'Virgin Islands US', level: 'High', score: 5 },
  { value: 'Yemen', level: 'High', score: 5 },
  { value: 'Zambia', level: 'Enhanced', score: 4 },
  { value: 'Zimbabwe', level: 'High', score: 5 },
]

export const CLIENT_REPUTATION_RELATIONSHIP = [
  { description: 'Publicly known person, no PEP, no sanctions list, no adverse information', level: 'Low', score: 1 },
  { description: 'Long-standing business relationship, client seen in person, no PEP, no sanctions list, no adverse information', level: 'Low', score: 1 },
  { description: 'Relatively new business relationship, client recommended by a respected client or partner, no PEP, no sanctions list, no adverse information', level: 'Medium', score: 2 },
  { description: 'Relatively new business relationship, no PEP, no sanctions list, no adverse information', level: 'Medium', score: 3 },
  { description: 'Client never seen in person, onboarded by means of secure e-identification', level: 'Medium', score: 3 },
  { description: 'Business relationship takes place in unusual circumstances', level: 'Enhanced', score: 4 },
  { description: "Client applied to a program 'residence by investment' in the EU", level: 'High', score: 5 },
  { description: 'PEP/adverse media/sanctions list hit', level: 'High', score: 5 },
]

export const BENEFICIAL_OWNER = [
  { description: 'No PEP, no sanctions list, no adverse information, no high-risk jurisdiction', level: 'Low', score: 1 },
  { description: 'PEP', level: 'High', score: 5 },
  { description: 'Sanctions list', level: 'High', score: 5 },
  { description: 'Adverse media information', level: 'High', score: 5 },
  { description: 'High-risk jurisdiction', level: 'High', score: 5 },
]

export const SOURCE_OF_FUNDS = [
  { id: 1, description: 'Salary/business income', level: 'Low', score: 1 },
  { id: 2, description: 'Income from operations on public stock exchange or other regulated entity', level: 'Low', score: 1 },
  { id: 3, description: 'Gift or inheritance', level: 'Medium', score: 3 },
  { id: 4, description: 'Income from crypto or other blockchain transactions', level: 'Enhanced', score: 4 },
  { id: 5, description: 'Indicator of higher risk, ML/TF, organized crime hit', level: 'High', score: 5 },
]

export const SIZE_OF_TRANSACTION = [
  {
    description: 'Low-value transaction, 0-5000 CHF',
    intended_text_map: ['<5,000 CHF', '< 5000 CHF'],
    range: [0, 5000],
    currency: 'CHF',
    level: 'Low',
    score: 1,
  },
  {
    description: 'Medium-value transaction, 5001-25000 CHF',
    intended_text_map: ['5,000 - 25,000 CHF', '5K - 25K CHF'],
    range: [5001, 25000],
    currency: 'CHF',
    level: 'Medium',
    score: 3,
  },
  {
    description: 'High-value transaction, 25001 CHF or more',
    intended_text_map: ['25,000 - 100,000 CHF', '25K - 100K CHF'],
    range: [25001, 100000],
    currency: 'CHF',
    level: 'Enhanced',
    score: 4,
  },
]
