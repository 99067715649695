import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Icon from './Icon'

const useStyles = makeStyles({
  high_risk: {
    color: "#ea0b0b",
  },
  medium_risk: {
    color: "#009f65",
  },
  enhanced_risk: {
    color: "#009f65",
  },
})

const ReportView = ({ breakdown, breakdownCompare }) => {
  return (
    <>
      {breakdown &&
        breakdown !== null &&
        Object.keys(breakdown).map((item, index) => {
          return (
            <React.Fragment key={item}>
              <div className="summary" key={index}>
                <ul>
                  <li className="comments__favorite summary__onfido">
                    {breakdown[`${item}`].result !== "clear" ? (
                      <>
                        <Icon
                          className={classNames("close-circle")}
                          name="close-circle"
                          size="32"
                        />
                        <span style={{display: "none"}}>
                          {breakdown[`${item}`].result &&
                            breakdown[`${item}`].result
                          }
                        </span>
                      </>
                    ) : (
                      <>
                        <Icon
                          className={classNames('check-circle')}
                          name='check-circle'
                          size="32"
                        />
                        <span style={{display: "none"}}>
                          {breakdown[`${item}`].result &&
                            breakdown[`${item}`].result
                          }
                        </span>
                      </>
                    )}
                    {' '}
                    {item}

                    {breakdown[`${item}`].breakdown && (
                      <>
                        <ul className="drafts__child">
                          <ReportView
                            breakdown={breakdown[`${item}`].breakdown}
                            breakdownCompare={
                              breakdownCompare &&
                              breakdownCompare[`${item}`] &&
                              breakdownCompare[`${item}`].breakdown
                              ? breakdownCompare[`${item}`].breakdown
                              : undefined
                            }
                          />
                        </ul>
                      </>
                    )}
                  </li>
                </ul>
              </div>
            </React.Fragment>
          );
        })}
    </>
  );
};

const OnfidoReports = ({
  reports,
  calculatedRiskAssessment,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const riskLabelHelper = (level) => {
    level = level || "";
    return (
      <p className={classes[`${level.toLowerCase()}_risk`]}>
        {`${level}`} { t('risk') }
      </p>
    );
  };

  return (
    <div className="field__wrap drafts">
      <div className="drafts__list">
        {reports.length > 0 &&
          reports.map((report, index) => {
            return (
              <div key={index} className="summary">
                <ul>
                  <li className="comments__favorite summary__onfido">
                    {report.result !== "clear" ? (
                      <>
                        <Icon
                          className={classNames("close-circle")}
                          name="close-circle"
                          size="32"
                        />
                        <span className="status-red-dark">
                          {report.result && report.result.toUpperCase()}
                        </span>
                        <span>{", "}</span>
                        <span className="status-red-dark">
                          {report.sub_result && report.sub_result.toUpperCase()}
                        </span>
                      </>
                    ) : (
                      <>
                        {report.result && (
                          <Icon
                            className={classNames('check-circle')}
                            name='check-circle'
                            size="32"
                          />
                        )}
                      </>
                    )}

                    {report.name === "watchlist_enhanced" &&
                      calculatedRiskAssessment &&
                      calculatedRiskAssessment.benificial_owner &&
                      <>
                        {
                          riskLabelHelper(
                            calculatedRiskAssessment.benificial_owner.level
                          )
                        }
                      </>
                    }

                    {report.name === "facial_similarity_video"
                      ?
                        <>
                          {t('Facial similarity video')}
                        </>
                      : report.name === "watchlist_enhanced"
                      ?
                        <>
                          {t('Watchlist enhanced')}
                        </>
                      :
                        <>
                          {report.name}
                        </>
                    }
                  </li>
                </ul>
              </div>
            );
          })}

        {reports.length > 0 &&
          reports.map((report, index) => {
            return (
              <ReportView breakdown={report.breakdown} key={index} />
            )
          })}
      </div>
    </div>
  );
};

export default OnfidoReports;
export { ReportView };
