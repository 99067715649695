import chf from './chf.png';
import eur from './eur.png';
import usd from './usd.png';
import eth from './eth.png';
import usdc from './usdc.svg';

export default {
  chf,
  eur,
  usd,
  eth,
  usdc
}
