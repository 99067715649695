import React from 'react'

import {
  Box,
  Button,
  Container,
} from '@material-ui/core'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Box display="flex" justifyContent="center" alignItems="center" className="text-center" style={{minHeight: '100vh'}}>
            <div>
              <h2>Opps! We didn't expected that</h2>
              <p>Please try again and let us know if it happen again.</p>
              <div>
                <Button variant="outlined" color="primary" onClick={() => { window.location.reload() }}>Refresh site</Button>
              </div>
            </div>
          </Box>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary
