import React, { useState } from "react";
import propTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { ADMIN_TRANSACTION_LIST_CSV, ADMIN_CAPITAL_INCREASE_TRANSACTION_LIST_CSV } from "../../queriesAndMutations";
import InputTheme from "../InputTheme";
import { useTranslation } from 'react-i18next';

const AdminExportTransactions = ({ filteredStatus, onClose, isRegulatorReporting, capitalIncreaseId }) => {
  const [value, setValue] = useState({
    stepNumber: 0,
    two_fa_token: "",
  });

  const [
    queryTransactionList,
    { loading, error, data: { transactionListCsv } = {} },
  ] = useMutation(ADMIN_TRANSACTION_LIST_CSV);

  const [
    queryCapitalIncreaseTransactionListCsv,
    { loading: capitalIncreaseLoading, error: capitalIncreaseError, data: { capitalIncreaseTransactionListCsv } = {} },
  ] = useMutation(ADMIN_CAPITAL_INCREASE_TRANSACTION_LIST_CSV);

  const changeStep = (step) =>
    setValue({
      ...value,
      stepNumber: step,
    });

  const csvData =
    (transactionListCsv || capitalIncreaseTransactionListCsv) &&
    URL.createObjectURL(new Blob([transactionListCsv || capitalIncreaseTransactionListCsv], { type: "text/csv" }));

  console.log("filteredStatus");
  console.log(filteredStatus);
  const { t } = useTranslation();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (capitalIncreaseId) {
      queryCapitalIncreaseTransactionListCsv({
        variables: {
          two_fa_token: value.two_fa_token,
          filtered_status: filteredStatus || [],
          capital_increase_id: capitalIncreaseId,
        },
      }).then(() => {
        changeStep(1);
      }).catch(() => {});

      return
    }

    queryTransactionList({
      variables: {
        two_fa_token: value.two_fa_token,
        filtered_status: filteredStatus || [],
        isRegulatorReporting: isRegulatorReporting,
        capital_increase_id: capitalIncreaseId,
      },
    }).then(() => {
      changeStep(1);
    }).catch(() => {});
  };

  const renderFirstStep = () => (
    <div className="settings__item">
      <div className="card__head card__head-kyc-1 nospace__bottom">
        <div className="refund__content card__head-content">
          {t('Please enter your 2fa token')}
          <div className="space space__8">{' '}</div>
        </div>
      </div>

      <form onSubmit={onSubmit} className="settings__fieldset">
        <InputTheme
          classWrapper="field"
          classLabel="field__label"
          classInput="field__input"

          propertyName="two_fa_token"
          label={t('Token')}

          state={value}
          setState={setValue}
          error={error || capitalIncreaseError}
          loading={loading || capitalIncreaseLoading}
        />

        <div className="field">
          <div className="field__wrap">
            <button className="button" type="submit">
              { t('Next') }
            </button>
          </div>
        </div>
      </form>
    </div>
  );

  const renderSuccess = () => (
    <div className="settings__item">
      <div className="settings__fieldset">
        <div className="field">
          <a
            href={csvData}
            onClick={onClose}
            download="transaction_list.csv"
            className="button"
          >
            { t('Click to Download') }
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <div className="settings__wrapper">
      <div className="space space__16">{' '}</div>
      <div className="kyc__stepper kyc__stepper_32">
        <Stepper activeStep={value.stepNumber}>
          <Step key={0}>
            <StepLabel>{ t('2FA token') }</StepLabel>
          </Step>
          <Step key={1}>
            <StepLabel>{ t('Download') }</StepLabel>
          </Step>
        </Stepper>
      </div>

      <div className="settings__list">
        {value.stepNumber === 0 && renderFirstStep()}
        {value.stepNumber === 1 && renderSuccess()}
      </div>
    </div>
  );
};

AdminExportTransactions.propTypes = {
  type: propTypes.string
};

export default AdminExportTransactions;
