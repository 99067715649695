import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Link, withRouter } from 'react-router-dom'
import { getFileUrl } from '../../utils'
import { usePublicSettings } from '../../myHooks'
import Icon from '../Icon'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import SearchBar from '../SearchBar'

const AppSidebarOuter = ({
  // from HOCs
  location,
}) => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { t } = useTranslation();

  const [sidebarValues, setSidebarValues] = useState({
    isSidebarActive: false
  });

  const onSidebarActive = () => {
    setSidebarValues({...sidebarValues, isSidebarActive: !sidebarValues.isSidebarActive });
  }

  const [sidebarVisibleValues, setSidebarVisibleValues] = useState({
    isSidebarVisibleActive: false
  });

  const onSidebarVisibleActive = () => {
    setSidebarVisibleValues({...sidebarVisibleValues, isSidebarVisibleActive: !sidebarVisibleValues.isSidebarVisibleActive });
  }

  const renderSidebarMainNavigation = () => {
    return (
      <>
        <Link
          className="sidebar__item"
          to="/data-center"
        >
          <Icon
            className={classNames('lightning', 'icon-24')}
            name="lightning"
            size="24"
          />
          { t('Data Center') }
        </Link>

        <Link
          className="sidebar__item"
          to="/privacy-policy"
        >
          <Icon
            className={classNames('promotion', 'icon-24')}
            name="promotion"
            size="24"
          />
          { t('Privacy Policy') }
        </Link>

        {publicSettings.token_type !== "UTILITY" ? (
          <Link
            className="sidebar__item"
            to="/purchase-agreement"
          >
            <Icon
              className={classNames('ticket', 'icon-24')}
              name="ticket"
              size="24"
            />
            { t('Purchase Agreement') }
          </Link>
        ) : ""}

        <Link
          className="sidebar__item"
          to="/right-of-withdrawal"
        >
          <Icon
            className={classNames('link', 'icon-24')}
            name="link"
            size="24"
          />
          { t('Right of Withdrawal') }
        </Link>
      </>
    )
  }

  const renderSidebarFooterNavigation = () => {
    return (
      <>
        <div className="sidebar__help sidebar__help_copyright">
          <div className="icon sidebar__help_copyright_icon">
            &copy;
          </div>
          &copy; {new Date().getFullYear()} {(publicSettings.company || {}).name}.
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`sidebar ${sidebarValues.isSidebarActive ? "active" : ""} ${sidebarVisibleValues.isSidebarVisibleActive ? "visible" : ""}`}>
        <button className="sidebar__close" onClick={onSidebarVisibleActive}>
          <Icon
            className={classNames('close', 'icon-24')}
            name="close"
            size="24"
          />
        </button>

        <Link className="sidebar__logo sidebar__logo-kyc" to="/">
          <img
            src={getFileUrl((publicSettings || {}).logo_path)}
            alt="logo"
            className="some-icon"
          />
        </Link>

        <div className="sidebar__menu">
          { renderSidebarMainNavigation() }
        </div>
        <button className="sidebar__toggle" onClick={onSidebarActive}>
          <Icon
            className={classNames('arrow-right', 'icon-24')}
            name="arrow-right"
            size="24"
          />
          <Icon
            className={classNames('close', 'icon-24')}
            name="close"
            size="24"
          />
        </button>

        <div className="sidebar__foot">
          { renderSidebarFooterNavigation() }
        </div>
        <div className="overlay">{' '}</div>
      </div>
      <div
        className={`overlay ${sidebarValues.isSidebarActive ? "active" : ""}`}
        onClick={onSidebarActive}
      >
        {' '}
      </div>
    </>
  );
};

AppSidebarOuter.propTypes = {
  // from HOCs
  location: PropTypes.object,
};

export default compose(withRouter)(AppSidebarOuter);
