import gql from 'graphql-tag';

export const CONFIRM_LIQUIDATION_WALLET = gql`
  mutation confirmLiquidationWallet($walletAddress: String!) {
    confirmLiquidationWallet(walletAddress: $walletAddress)
  }
`

export const SAVE_PAYOUT = gql`
  mutation savePayout($payoutTxHash: String!, $transactionIds: [ID]!, $userId: ID!) {
    savePayout(payoutTxHash: $payoutTxHash, transactionIds: $transactionIds, userId: $userId)
  }
`
export const SAVE_PAYOUT_FOR_KYC = gql`
  mutation savePayoutForKyc($kycId: ID!, $payoutTxHash: String!) {
    savePayoutForKyc(kycId: $kycId, payoutTxHash: $payoutTxHash)
  }
`
export const ADD_LIQUIDATION_PROOF = gql`
  mutation addLiquidationProof($input: AddLiquidationProofInput!) {
    addLiquidationProof(input: $input)
  }
`
