import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const TransferClaimGuide = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="h3 nospace__bottom">{ t('Transfer claim guide') }</div>
      <div className="page__title">{' '}</div>
      <div className="card">
        <p>
          Please place your sell order for 0.00000290 BTC at
          {' '}
          <a href="https://yobit.net/en/trade/EVN/BTC" target="_blank" rel="noopener noreferrer">https://yobit.net/en/trade/EVN/BTC</a>.
        </p>
        <p>
          Alternatively you can also place the sell order at
          {' '}
          <a href="https://yobit.net/en/trade/EVN/ETH" target="_blank" rel="noopener noreferrer">https://yobit.net/en/trade/EVN/ETH</a>
          {' '}
          in case you wish to receive Ethereum instead of Bitcoin.
        </p>
        <p>You can securely deposit your Envion tokens via Metamask on the Yobit Exchange.</p>

        <NavLink to="/transfer-claim" className="button-stroke" activeClassName="">Back</NavLink>
      </div>
    </>
  )
}

export default TransferClaimGuide
