import React from 'react'
import { usePublicSettings } from '../../../../myHooks/useSettings'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Icon from '../../../Icon'
import classNames from 'classnames'

const Menu = () => {
  const { t } = useTranslation();
  const { data: { publicSettingsFooter = {} } = {} } = usePublicSettings();
  return (
    <div className="help__menu">
      <Link
        className="help__link"
        to="/data-center"
      >
        <Icon
          className={classNames('pie-chart', 'icon-24')}
          name="pie-chart"
          size="24"
        />
        {t('Data Center')}
      </Link>

      <Link
        className="help__link"
        to="/privacy-policy"
      >
        <Icon
          className={classNames('promotion', 'icon-24')}
          name="promotion"
          size="24"
        />
        {t('Privacy Policy')}
      </Link>

      {publicSettingsFooter.token_type === "UTILITY" ? (
        <Link
          className="sidebar__help"
          to="/purchase-agreement"
        >
          <Icon
            className={classNames('ticket', 'icon-24')}
            name="ticket"
            size="24"
          />
          {t('Purchase Agreement')}
        </Link>
      ) : (
        <Link
          className="sidebar__help"
          to="/right-of-withdrawal"
        >
          <Icon
            className={classNames('link', 'icon-24')}
            name="link"
            size="24"
          />
          {t('Right of Withdrawal')}
        </Link>
      )}
    </div>
  );
}

export default Menu;
