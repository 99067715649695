import React, { useState, useEffect, useCallback } from 'react'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'

import {
  GET_CHAT_DIALOG,
  REQUEST_ACCESS_TOKEN,
} from '../../queriesAndMutations'
import { toaster } from '../../utils'
import metamaskService from '../../services/metamask'
import messageService from '../../services/message'
import MessageViewer from '../../components/MessageViewer'

import '../../styles/chatbox.css'

const useStyles = makeStyles({
  message: {
    marginBottom: '20px',
  },
  message__header: {
    marginBottom: '5px',
  },
  message__owner: {
    fontWeight: 'bold',
  },
  message__timestamp: {
    color: '#898989',
    fontStyle: 'italic',
  },
  message__content: {
    display: 'inline-block',
    backgroundColor: '#F4F4F4',
    padding: '15px 25px',
    border: '1px solid #DBDBDB',
    borderRadius: '8px',
  },
})

const MessagePage = () => {
  const classes = useStyles()
  const [account, setAccount] = useState(metamaskService.isMetamaskAvailable() ? metamaskService.getCurrentAddress() : '')

  const [getChatDialog, getChatDialogStatus] = useLazyQuery(GET_CHAT_DIALOG)
  const [requestAccessToken] = useMutation(REQUEST_ACCESS_TOKEN)
  const { t } = useTranslation()

  const requestSessionToken = useCallback(async (address) => {
    const message = 'Sign this message to register chat session'
    const signature = await metamaskService.signMessage(address, message)
      .catch((error) => { toaster.error(error.message) })

    if (signature) {
      requestAccessToken({
        variables: {
          wallet: address,
          message,
          signature,
        }
      }).then(({ data: { requestAccessToken: token}}) => {
        messageService.saveSessionToken(address.toLowerCase(), token)
        window.location.reload()
      }).catch(() => {})
    } else {
      throw new Error('You have rejected verifying you wallet')
    }
  }, [requestAccessToken])

  const connectWallet = async () => {
    try {
      await metamaskService.requestAccounts().catch((error) => { toaster.error(error.message) })

      const currentAddress = metamaskService.getCurrentAddress()

      setAccount(currentAddress)
    } catch (error) {}
  }

  useEffect(() => {
    let timeoutId;

    const bindAddressOnConnected = () => {
      timeoutId = setTimeout(() => {
        setAccount(metamaskService.getCurrentAddress())
      }, 200)
    }

    if (metamaskService.isMetamaskAvailable()) {
      window.ethereum.on('connect', bindAddressOnConnected);
    }

    return () => {
      clearTimeout(timeoutId);
    }
  }, [])

  useEffect(() => {
    const onAccountChangeHandle = (accounts) => {
      if (accounts.length > 0) {
        requestSessionToken(accounts[0]).catch((error) => {
          console.log(error)
          window.location.reload()
        })
      } else {
        messageService.clearChatSession()
        window.location.reload()
      }
    }

    if (!account) {
      return () => metamaskService.removeOnAccountChangeHandle(onAccountChangeHandle)
    }

    const address = messageService.getSessionAddress()

    if (address && address !== account.toLowerCase()) {
      messageService.clearChatSession()
      requestSessionToken(account).catch(() => {
        window.location.reload()
      })

      return () => metamaskService.removeOnAccountChangeHandle(onAccountChangeHandle)
    }

    const token = messageService.getSessionToken()

    if (!token) {
      requestSessionToken(account).catch(() => {})
    } else {
      messageService.extendSessionTokenLifeTime()
      getChatDialog()
      metamaskService.addOnAccountChangeHandle(onAccountChangeHandle)
    }

    return () => metamaskService.removeOnAccountChangeHandle(onAccountChangeHandle)
  }, [account, getChatDialog, requestSessionToken])

  if (metamaskService.isMetamaskNotAvailable()) {
    return (
      <div className="product__wrapper product__wrapper_v2">
        <div className="product__details">
          <p>You need to install Metamask to use this feature</p>
        </div>
      </div>
    )
  }

  if (!account) {
    return (
      <div className="product__wrapper product__wrapper_v2">
        <div className="product__details">
          <button className="button button-small" onClick={connectWallet}>
            {t('Connect wallet to use this feature')}
          </button>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="product__title h4">{t('Messages')}</div>
      <div className="create__card card">
          <p>Address: {account || <em>No wallet connected</em>}</p>
      </div>
      <div className="create__card card">
        <div className="chat-box">
          <div className="chat-dialog">
            {getChatDialogStatus.loading && (
              <CircularProgress />
            )}
            {getChatDialogStatus.data &&
              getChatDialogStatus.data.getChatDialog.length > 0 &&
              getChatDialogStatus.data.getChatDialog.map(message => (
                <div className={classes.message} key={message.id}>
                  <div className={classes.message__header}>
                    <span className={classes.message__owner}>
                      {message.from}
                    </span>
                    {' - '}
                    <span className={classes.message__timestamp}>
                      {moment(message.created_at).format('D/M/Y H:m A')}
                    </span>
                  </div>
                  <div className={classes.message__content}>
                    <MessageViewer message={message.message} />
                  </div>
                </div>
              ))
            }

            {getChatDialogStatus.data &&
              getChatDialogStatus.data.getChatDialog.length <= 0 && (
                <p>No message.</p>
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default MessagePage
