import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Dialog, CircularProgress } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { useCookies } from 'react-cookie'
import cn from 'classnames'
import {
  logout,
  isFeatureEnabled,
  getTextAvatarUrl,
  toaster,
  isProduction,
  tokenToEth
} from '../../utils'
import { useMe, useExchangeRates } from '../../myHooks'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { usePublicSettings } from '../../myHooks/useSettings'
import {
  ME_INVEST_IN_BASE_CURRENCY,
  GET_AVAILABLE_FEATURES,
  GET_AUTH_TENANT_LIST,
  SET_USER_LOCALE,
  SYNC_USER,
} from '../../queriesAndMutations'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import * as languageHelper from '../../utils/lang'
import Icon from '../Icon'
import ModalInnerMaterialDialog from '../ModalInnerMaterialDialog'

const UserBar = ({
  // from HOCs
  location,
}) => {
  const { t } = useTranslation();

  const [openSwitchInvestub, setOpenSwitchInvesthub] = useState(false)

  const {
    data: { meInvestInBaseCurrency = {} } = {},
    loading: loadingMeInvestInBaseCurrency,
  } = useQuery(ME_INVEST_IN_BASE_CURRENCY);
  const { data: { exchangeRates } = {}, loading: loadingExchangeRates } =
    useExchangeRates();
  const { data: { getAuthTenantList = [] } = {} } = useQuery(GET_AUTH_TENANT_LIST);

  const tokenBalanceMock = {
    value: !loadingMeInvestInBaseCurrency
      ? meInvestInBaseCurrency.approved_invest
      : "0",
    currency: "TWZ",
    contribution: [
      {
        value:
          !loadingExchangeRates &&
          !loadingMeInvestInBaseCurrency &&
          exchangeRates
            ? tokenToEth(meInvestInBaseCurrency.approved_invest, exchangeRates)
            : "~",
        currency: "ETH",
      },
    ],
  };
  const [values, setValues] = useState({
    isMenuActive: false,
  });
  const [languageValues, setLanguageValues] = useState({
    isLanguageActive: false,
  })
  const [investhubValues, setInvesthubValues] = useState({
    isInvesthubActive: false,
  })
  const [agreeInvesthubAccess, setagreeInvesthubAccess] = useState(false)
  const { data: { me: userData } = {} } = useMe();
  const [, setCookie] = useCookies(["jwt"]);
  const { data: { getAvailableFeatures: availableFeatures } = {} } = useQuery(GET_AVAILABLE_FEATURES);
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const [setUserLocale] = useMutation(SET_USER_LOCALE)
  const [syncUser, syncUserStatus] = useMutation(SYNC_USER);
  const siteLocales = ((publicSettings || {}).site_locales || '').split(',').filter(Boolean).map(x => String(x).trim())
  const currentHost = window.location.host;
  const isEnercom = currentHost === 'kyc.enercom.io' || currentHost === 'dev-kyc.enercom.io' || currentHost === 'localhost:3000';
  const isMasterTenant = currentHost === 'kyc.investhub.io' || currentHost === 'kyc-dev.investhub.io' || currentHost === 'localhost:3001'

  const onUserLogoClick = () => {
    setValues({ ...values, isMenuActive: !values.isMenuActive });
  };

  const onUserLogoAwayClick = () => {
    setValues({ ...values, isMenuActive: false });
  };

  const onLanguageClick = () => {
    setLanguageValues({ ...languageValues, isLanguageActive: !languageValues.isLanguageActive });
  };

  const onInvesthubClick = () => {
    setInvesthubValues({ ...investhubValues, isInvesthubActive: !investhubValues.isInvesthubActive });
  };

  const onSyncData = (redirectMasterTenant, redirectPath) => {
    syncUser({ variables: { syncMasterTenant: redirectMasterTenant } }).then((response) => {
      const data = response.data.syncUser;

      if (data.success) {
        const encodedRedirectPath = redirectPath ? encodeURIComponent(redirectPath) : 'personalised_opportunities'
        const redirectUrl = `${process.env.REACT_APP_DAO_HOST}/sso/auth?token=${data.token}&host=${window.location.host}&redirect_master_tenant=${redirectMasterTenant}&redirect_path=${encodedRedirectPath}`;
        window.location.href = redirectUrl;
      } else {
        toaster.error('Sync User failed');
      }
    }).catch((error) => {
      console.log(error);
      toaster.error('Sync User failed');
    })
  }

  const originSource = userData && userData.origin_source
    ? userData.origin_source.includes("http")
    ? userData.origin_source
    : `https://${userData.origin_source}`
    : null

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      {userData && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <ClickAwayListener onClickAway={onUserLogoAwayClick}>
          <div
            className={`header__item header__item_user ${
              values.isMenuActive ? "active" : ""
            }`}
          >
            <button
              className="header__head"
              onClick={onUserLogoClick}
            >
              <img
                src={getTextAvatarUrl(`${userData.first_name} ${userData.last_name}`)}
                alt={`${userData.first_name} ${userData.last_name} avatar`}
              />
            </button>

            <div className="header__body">
              <div className="header__nav">
                {/* eslint-disable-next-line */}
                <NavLink
                  to="/profile"
                  className="header__link header__link-block"
                >
                <Icon
                  className={cn('profile-circle', 'icon-24')}
                  name="profile-circle"
                  size="24"
                />
                  { t('My profile') }
                </NavLink>

                {!(publicSettings && publicSettings.token_type === 'NONE') && (
                  <>
                    {/* eslint-disable-next-line */}
                    <NavLink
                      to="/transactions"
                      className="header__link header__link-block"
                    >
                      <Icon
                        className={cn('payment', 'icon-24')}
                        name="payment"
                        size="24"
                      />
                      { t('Transactions') }
                    </NavLink>
                  </>
                )}
              </div>

              {publicSettings && !publicSettings.hide_token_balance && (
                <div className="header__nav">
                  {/* eslint-disable-next-line */}
                  <a
                    className="header__link header__link-balance">
                      <span className="balance__title">{ t('Token balance') }</span>
                      <span className="balance__status">
                        {tokenBalanceMock.value}{" "}
                        <small>
                          {publicSettings && publicSettings.token_symbol}
                        </small>
                      </span>
                    </a>
                </div>
              )}

              {((getAuthTenantList && getAuthTenantList.length > 0) || isMasterTenant) && (
                <div className="header__nav">
                  {/* eslint-disable-next-line */}
                  <a
                    className="header__link color header__link-block"
                    onClick={onInvesthubClick}
                  >
                    Investhub
                  </a>
                </div>
              )}

              {(userData.is_internal_sales || userData.is_gtoswiss) && (
                <div className="header__nav">
                  {/* eslint-disable-next-line */}
                  <a
                    className="header__link color header__link-block"
                    href={originSource || (isProduction ? "https://member.wecom.ae/" : "https://dev-member.wecom.ae/")}
                  >
                    {userData.origin_source ? `${t('Back to')} ${userData.origin_source}` : t('Back to Referrer')}
                  </a>
                </div>
              )}

              { isEnercom && !userData.is_sync_master_tenant && publicSettings.allow_sync_kyc_data_investhub && (
                <div className="header__nav">
                  {/* eslint-disable-next-line */}
                  <a
                    href="#"
                    className="header__link color header__link-block"
                    onClick={() => setOpenSwitchInvesthub(true)}
                  >
                    {t('Sync Data To Investhub')}
                  </a>
                </div>
              )}

              {isFeatureEnabled('MULTI_LANGUAGE', availableFeatures) && (
                <div className="header__nav">
                  {/* eslint-disable-next-line */}
                  <a
                    className="header__link color header__link-block"
                    onClick={onLanguageClick}
                  >
                    <span className="icon-flag">
                      <img
                        src={languageHelper.getCurrentFlagIcon((publicSettings || {}).default_locale)}
                        alt={languageHelper.getCurrentLocalName((publicSettings || {}).default_locale)}
                        className="icon-flag__img"
                      />
                    </span>
                    {languageHelper.getCurrentLocalName((publicSettings || {}).default_locale)}
                  </a>
                </div>
              )}
              <div className="header__nav">
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                <a
                  className="header__link header__link-block"
                  onClick={() => {
                    setCookie("jwt", "", { path: "/" });
                    logout()();
                  }}
                >
                  { t('Logout') }
                </a>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      )}

      <div
        className={`popup js-popup modal popup__kyc-medium ${
          languageValues.isLanguageActive ? "animation visible" : ""
        }`}
      >
        <div onClick={onLanguageClick} className="popup__overlay js-popup-overlay">{' '}</div>

        <div className="popup__wrap js-popup-wrap">
          <div className="description">
            <div className="title-primary description__title">{ t('Change Languages') }</div>
            <div className="description__row flag__row popular">
              <div className="popular__list change-tenant__list">
                {Object.values(languageHelper.supportedLanguages).filter((lang) => siteLocales.includes(lang.code)).map(lang => (
                  <div
                    className="popular__item change-tenant__item change-language__item"
                    key={lang.code}
                    onClick={() => {
                      languageHelper.setLocale(lang.code)
                      i18n.changeLanguage(lang.code)
                      setUserLocale({ variables: { locale: lang.code } })
                      setLanguageValues(false)
                    }}
                  >
                    <div className="users__item">
                      <div className="users__avatar">
                        <img src={lang.flag} alt={lang.label} />
                      </div>

                      <div className="users__title">
                        {lang.label}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <button
            className="popup__close js-popup-close"
            onClick={onLanguageClick}
          >
            <Icon
              className={cn('close', 'icon-24')}
              name="close"
              size="24"
            />
          </button>
        </div>
      </div>

      <div
        className={`popup js-popup modal popup__kyc-medium ${
          investhubValues.isInvesthubActive ? "animation visible" : ""
        }`}
      >
        <div onClick={onInvesthubClick} className="popup__overlay js-popup-overlay">{' '}</div>

        <div className="popup__wrap js-popup-wrap">
          <div className="description">
            <div className="title-primary description__title">{ t('Change Tenant') }</div>
            <div className="description__row flag__row popular">
              <div className="popular__list change-tenant__list">
              {getAuthTenantList && getAuthTenantList.map(tenant => (
                <div
                  className="popular__item change-tenant__item"
                  key={tenant.host}
                >
                  {tenant.host !== window.location.host && (
                    <button
                      className="users__item"
                      onClick={() => onSyncData(false, `sso/request?redirect=${decodeURIComponent(`https://${tenant.host}/sso/auth`)}`)}
                    >
                      <div className="users__avatar">
                        <img src={tenant.logoUrl} alt={tenant.name} />
                      </div>

                      <div className="users__title">
                        {tenant.name}
                      </div>
                    </button>
                  )}
                  {tenant.host === window.location.host && (
                    <div className="users__item">
                      <div className="users__avatar">
                        <img src={tenant.logoUrl} alt={tenant.name} />
                      </div>

                      <div className="users__title">
                        {tenant.name}
                      </div>
                    </div>
                  )}
                </div>
              ))}
              </div>
              <button
                onClick={() => onSyncData(false)}
                className="button-stroke popular__button"
              >{t('Back to Investhub')}</button>
            </div>
          </div>

          <button
            className="popup__close js-popup-close"
            onClick={onInvesthubClick}
          >
            <Icon
              className={cn('close', 'icon-24')}
              name="close"
              size="24"
            />
          </button>
        </div>
      </div>

      <Dialog
        open={openSwitchInvestub}
        onClose={() => setOpenSwitchInvesthub(false)}
        className="investhub-modal investhub-modal__m"
      >
        <ModalInnerMaterialDialog
          modalSize="popup__kyc-medium"
          modalTitle={t('Move KYC data')}
          onClickClose={() => setOpenSwitchInvesthub(false)}
          modalTitleClassColor="title-primary"
        >
          <div className="space space__8">{' '}</div>
          <div className="settings__wrapper">
            <div className="settings__list">
              <div className="settings__item">
                <div className="settings__fieldset">
                  <div className="create__line">
                    <div className="create__info">
                      { t('I agree that COMPANYNAME can access my KYC Data.', {
                        COMPANYNAME: publicSettings.master_tenant_company ? publicSettings.master_tenant_company.name : ''
                      }) }
                    </div>

                    <label className="switch">
                      <input
                        type="checkbox"
                        className="switch__input"
                        value="allow-access-companyname"
                        checked={agreeInvesthubAccess}
                        onChange={() => setagreeInvesthubAccess(!agreeInvesthubAccess)}
                      />
                      <div className="switch__inner">
                        <div className="switch__box">{' '}</div>
                      </div>
                    </label>
                  </div>
                </div>

                <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
                  {syncUserStatus.loading ? (
                    <CircularProgress />
                  ) : (
                    <button
                      onClick={() => onSyncData(true)}
                      className="button"
                      type="button"
                      disabled={!agreeInvesthubAccess}
                    >
                      {t("Submit")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalInnerMaterialDialog>
      </Dialog>
    </>
  );
}

export default UserBar;
