// eslint-disable-next-line import/prefer-default-export
export const CUSTOMER_STATUS_TYPES = {
  REJECTED: 'REJECTED',
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  WHITELISTED: 'WHITELISTED',
  CONTRACTED: 'CONTRACTED',
  KYC_LVL_CHANGE: 'KYC_LVL_CHANGE',
  REOPEN: 'REOPEN',
  AUTO_APPROVED: 'AUTO_APPROVED',
  INACTIVE: 'INACTIVE',
}

export const CUSTOMER_TYPES = {
  INDIVIDUAL: 'INDIVIDUAL',
  COMPANY: 'COMPANY',
}

export const SOURCE_OF_FUNDS = {
  SAVINGS: 'SAVINGS',
  SALE_OF_INVESTMENTS: 'SALE_OF_INVESTMENTS',
  SALE_OF_PROPERTY: 'SALE_OF_PROPERTY',
  LOAN: 'LOAN',
  INHERITANCE: 'INHERITANCE',
  MATURITY_OR_SURRENDER_OF_LIFE_ASSURANCE_POLICY: 'MATURITY_OR_SURRENDER_OF_LIFE_ASSURANCE_POLICY',
  INSURANCE_CLAIMS: 'INSURANCE_CLAIMS',
  DIVIDENDS_OR_PROFITS_FROM_COMPANY: 'DIVIDENDS_OR_PROFITS_FROM_COMPANY',
  DIVORCE: 'DIVORCE',
  OTHER_COURT_AWARD: 'OTHER_COURT_AWARD',
  LOTTERY: 'LOTTERY',
  GAMBLING_WIN: 'GAMBLING_WIN',
  GIFT: 'GIFT',
  OTHER: 'OTHER',
}

export const SOURCE_OF_WEALTH = {
  SAVINGS: 'SAVINGS',
  SALE_OF_INVESTMENTS: 'SALE_OF_INVESTMENTS',
  SALE_OF_PROPERTY: 'SALE_OF_PROPERTY',
  LOAN: 'LOAN',
  INHERITANCE: 'INHERITANCE',
  MATURITY_OR_SURRENDER_OF_LIFE_ASSURANCE_POLICY: 'MATURITY_OR_SURRENDER_OF_LIFE_ASSURANCE_POLICY',
  INSURANCE_CLAIMS: 'INSURANCE_CLAIMS',
  DIVIDENDS_OR_PROFITS_FROM_COMPANY: 'DIVIDENDS_OR_PROFITS_FROM_COMPANY',
  DIVORCE: 'DIVORCE',
  OTHER_COURT_AWARD: 'OTHER_COURT_AWARD',
  LOTTERY: 'LOTTERY',
  GAMBLING_WIN: 'GAMBLING_WIN',
  GIFT: 'GIFT',
  OTHER: 'OTHER',
}

export const CUSTOMER_COMPANY_PROOF_OF_RESIDENCE = {
  BANK_STATEMENT: 'Bank Statement',
  UTILITY_BILL: 'Utility Bill',
  REGISTRATION_CONFIRMATION: 'Registration confirmation',
}

export const CUSTOMER_DOCUMENT_NAME_TYPES = {
  PASSPORT: 'Passport',
  NATIONAL_ID_CARD: 'National id card',
}

export const DOCUMENT_TYPES = {
  INDIVIDUAL_FORM: 'INDIVIDUAL_FORM',
  KYB_SINGLE_FORM: 'KYB_SINGLE_FORM',
  KYB_COLLECTIVE_FORM: 'KYB_COLLECTIVE_FORM',
  TOKEN_SWAP_FORM: 'TOKEN_SWAP_FORM',
  TOKEN_SALE_AGREEMENT: 'TOKEN_SALE_AGREEMENT',
  LIQUIDATION_FORM: 'LIQUIDATION_FORM',
  TOKEN_SAFE: 'TOKEN_SAFE',
}

export const INTENDED_INVESTMENT = [
  "<5,000 CHF",
  "5,000 - 25,000 CHF",
  "25,000 - 100,000 CHF",
  "100,000 - 1 million CHF (detailed documentary evidence is mandatorily required)",
  ">1 million CHF (detailed documentary evidence is mandatorily required)",
];

export const COUNTRY_LIST = [
  'Afghanistan',
  'Aland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bonaire Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos Keeling Islands',
  'Colombia',
  'Comoros',
  'Congo',
  'Congo The Democratic Republic of',
  'Cook Islands',
  'Costa Rica',
  'Cote d\'Ivoire',
  'Croatia',
  'Cuba',
  'Curacao',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands Malvinas',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See Vatican City State',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran Islamic Republic of',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Korea (South)',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Lao Peoples Democratic Republic of',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libyan Arab Jamahiriya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Macedonia The former Yugoslav Republic of',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia Federated States of',
  'Moldova Republic of',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Occupied Territory',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'Saint Helena Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maartin',
  'Slovakia',
  'Slovenia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania United Republic of',
  'Thailand',
  'Timor Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela Bolivarian Republic of',
  'Vietnam',
  'Virgin Islands British',
  'Virgin Islands US',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]

export const RESTRICTED_COUNTRY_LIST = [
  "Afghanistan",
  "Barbados",
  "Belarus",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "Colombia",
  "Congo The Democratic Republic of",
  "Egypt",
  "Guinea",
  "Guinea-Bissau",
  "Haiti",
  "India",
  "Iran Islamic Republic of",
  "Iraq",
  "Jamaica",
  "Jordan",
  "Kenya",
  "Korea The Democratic People's Republic of",
  "Lebanon",
  "Libya",
  "Mali",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Pakistan",
  "Panama",
  "Philippines",
  "Russian Federation",
  "Senegal",
  "Somalia",
  "South Sudan",
  "Sudan",
  "Syrian Arab Republic",
  "Trinidad and Tobago",
  "Tunisia",
  "Uganda",
  "Ukraine",
  "Vanuatu",
  "Venezuela Bolivarian Republic of",
  "Yemen",
  "Zimbabwe",
]

export const RESTRICTED_NATIONALITY = [
  'Russian Federation',
  'Belarus',
]

export const HIGH_AND_PROHIBIT_RISK_COUNTRIES = [
  'AM.Jungf.Ins.',
  'Aequat.Guinea',
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Minor',
  'American Samoa',
  'Angola',
  'Anguilla',
  'Antigua',
  'Argentine',
  'Armenia',
  'Aruba',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belize',
  'Benin',
  'Bermuda',
  'Bolivia',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Virgin Islands',
  'Burkina Faso',
  'Burundi',
  'Byanmar',
  'Cambodia',
  'Cameroon',
  'Cape Verde.',
  'Central Africa',
  'Chile',
  'China',
  'Cocos',
  'Colombia',
  'Comoros',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  'Cuba',
  'Curacao',
  'Dem. Republic of Congo',
  'Djibouti',
  'Dominic. Rep.',
  'Dominica',
  'East Timor',
  'Egypt',
  'Engl Ind.',
  'Equador',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Ins.',
  'Faroe Islands',
  'Fiji Ins. Gabon',
  'Gambia',
  'Georgia',
  'Ghana',
  'Gibraltar',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Haiti',
  'Heard&McDon.',
  'Honduras India',
  'Ins.',
  'Iran',
  'Iraq',
  'Island Man',
  'Islands',
  'Ivory Coast',
  'Jamaica',
  'Jersey Jordan',
  'Kayman Islands',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Latvia Liberia',
  'Lebanon',
  'Lesotho',
  'Libya',
  'Lithuania',
  'Lower Antilles',
  'Macau',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Maldives',
  'Mali',
  'Malta',
  'Marian.',
  'Marshall Ins.',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia Nauru',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'North Korea',
  'Northern',
  'Oman',
  'Pakistan',
  'Panama',
  'Qatar',
  'Rus. Federat.',
  'Senegal',
  'Somalia',
  'South Sudan',
  'St. Eustatius & Saba',
  'Sudan',
  'Syria',
  'Trinidad Tob.',
  'Tunisia',
  'USA',
  'Uganda',
  'Ukraine',
  'Vanuatu',
  'Venezuela',
  'Yemen',
  'Zimbabwe',
]

export const BUSINESS_FOLLOWING_INDUSTRIES = [
  'None',
  'Currency Exchange-Money Transfer (Remittance) business',
  'Casinos-Gambling-Gaming',
  'Calling cards and VoIP providers',
  'Pharmaceuticals and online drug providers',
  'Tobacco/E-cigarettes/Cannabis/CBD products',
  'Telemarketings sales',
  'Adult entertainment and dating services',
  'Precious Metals',
  'Import/export companies with high monthly transaction volume',
  'Military-Firearms',
  'Oil-raw materials',
]

export const INDUSTRY_OPTIONS = [
  'Accommodation and Food Services',
  'Accounting and Tax Services',
  'Agriculture',
  'Art & Leisure',
  'Automotive or Related',
  'Chemicals & Resources',
  'E-Commerce',
  'Energy & Environment',
  'Charitable/Non Profit Organization',
  'Construction',
  'Transport & Logistics',
  'Advertising & Marketing',
  'Consumer Goods & FMCG (Fast Moving Consumer Goods)',
  'Blockchain Technology Services',
  'Crypto Mining or Trading',
  'Education & Science',
  'Financial Services or Insurance',
  'Media (music, video games, book market, TV & film)',
  'Metal & Electronics',
  'Gambling',
  'IT & Telecommunications',
  'Health & Social Services',
  'Law Firm or Legal Services',
  'Law Enforcement or Public Safety',
  'Luxury Goods (Production, Manufacturing, Transportation, Dealing, or Retail)',
  'Military',
  'Pharmaceutical Industry & Pharmaceutical Products',
  'Precious Metals and Stones (Mining, Transportation, Dealing, or Retail)',
  'Professional Service Provider & Consulting',
  'Public Administration',
  'Real Estate',
  'Sports & Fitness',
  'Logistics & Transport',
  'Retail Store (NO Luxury Goods)',
  'Wholesale Trade (NO Luxury Goods) ',
  'Other',
]

export const NET_INVESTABLE_ASSETS_OPTIONS = [
  'Up to 5.000 CHF',
  '5001 CHF - 25.000 CHF',
  '25001 CHF - 100.000 CHF',
  '100.001 CHF - 500.000 CHF',
  '500.001 CHF - 1 Mio CHF',
  '> 1 Mio - 5 Mio',
  '> 5 Mio',
]

export const CUSTOMER_COMPANY_PROOF_OF_RESIDENCE_HELPER_TEXT = {
  UTILITY_BILL: `Utility bill showing the name and residential address. For example an invoice from a utility company, eg. electricity, natural gas, water, waste, internet phone etc.`,
  BANK_STATEMENT: `Bank statements showing the name and residential address.`,
  REGISTRATION_CONFIRMATION: 'This is usually issued from your local municipality.'
}

export const EDUCATION_OPTIONS = [
  'No formal education',
  'primary education',
  'Secondary education or high school',
  'Work-related qualification',
  'Bachelor\'s degree',
  'Master\'s degree',
  'Doctorate or higher',
  'Other',
]

export const OCCUPATION_OPTIONS = [
  'Full-Time',
  'Part-Time',
  'Self-employed',
  'Freelance',
  'Contract',
  'Internship',
  'Apprenticeship',
  'Seasonal',
  'Other',
]

export const POSITION_OPTIONS = [
  'Entry-level employee (intern, Secretary, Assistant…)',
  'Executive employee (Officer, Manager ...)',
  'Management employee (General Manager, VP, CEO, Director…)',
  'Member of the Board',
  'Owner',
  'Other',
]

export const COMPANY_LEGAL_FORMS =[
  'Stock Corporation (ex. AG)',
  'Limited Liability Company (ex. GmbH)',
  'Foundation (ex. Stiftung)',
  'Sole proprietorship',
  'General Partnership (ex. OG)',
  'Limited Partnership (ex. KG)',
  'Association (ex. Verein)',
  'Other',
]
